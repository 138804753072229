import { Spinner } from 'app/common/loading-spinner/loadingSpinner.component';
import { IRawUserData } from 'app/user/interfaces';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { compose, shouldUpdate } from 'recompose';
import { formatPrice } from 'utils/normalize-price-amount';

import americanExpressImage from '../../../images/american_express.png';
import cbImage from '../../../images/cb.png';
import directDebit from '../../../images/direct-debit-1-logo-png-transparent.png';
import masterCardImage from '../../../images/MasterCard (Inverted).png';
import singleEuro from '../../../images/Single_Euro_Payments_Area_logo.svg.png';
import sepa from '../../../images/virement-bancaire-logo.png';
import visaImage from '../../../images/Visa (Inverted).png';
import { IWithSelectedTracker, withSelectedTracker } from '../../../utils/with-selected-tracker';
import { BackLink } from '../../common/back-link/back-link.component';
import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { ReactComponent as DashIcon } from '../../icons/dash.svg';
import { PageContainer } from '../../layout/page-container/page-container.component';
import { SimpleHeaderWithLogout } from '../../layout/simple-header/simple-header.component';
import { withActiveTrackerResolving } from '../../map/resolvers/active-tracker-resolver';
import { INotification } from '../../notifications/interfaces';
import { trackersRoute } from '../../routing/routes';
import { Dispatch, IRootState } from '../../store/store';
import { PaymentCode } from '../../subscription-process/interfaces';
import { PaymentRedirectionUrls } from '../../subscription-process/payment-redirection-routes';
import {
    redirectToPaymentGate
} from '../../subscription-process/services/order-subscription.service';
import { HipayStep } from '../../subscription-process/steps/hipay-step/hipay-step.component';
import { IProcessPayment } from '../../subscription-process/steps/hipay-step/interfaces';
import { Theme } from '../../trackers/interfaces';
import { ITracker, ITrackerSubscriptionPreview } from '../../trackers/interfaces-api';
import { ISubscriptionRedirection } from '../interfaces-api';
import { makeGetFirstActiveSubscription } from '../selectors/user-subscriptions.selectors';
import styles from './change-payment-mean.module.scss';

const hipayFormID = 'hipay-hostedfields-form';

interface IStateProps {
    activeSubscription: ITrackerSubscriptionPreview | null | undefined;
    id: number | null;
    activeTheme: Theme | null;
    user: IRawUserData | null;
}
interface IOuterProps {
    isFromAccountPage?: boolean
}

interface IActions {
    checkEligibilityToChangeMean: (subscriptionId: number) => Promise<any>;
    doChangeMean: (subscriptionId: number) => Promise<any>;
    onCreditCardChange: (paymentPayload: IProcessPayment) => any;
    onBankAccountChange: (subscriptionId: number) => any;
    showNotification: (n: INotification) => unknown;
}

export interface IChangePaymentMeanProps
    extends IStateProps,
    IActions,
    IOuterProps,
    IWithSelectedTracker { }

export const ChangePaymentMeanPage = ({
    activeSubscription,
    activeTheme,
    id,
    isFromAccountPage,
    user,
    checkEligibilityToChangeMean,
    doChangeMean,
    onCreditCardChange,
    onBankAccountChange,
    showNotification,
}: IChangePaymentMeanProps) => {
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [displayHipayForm, setDisplayHipayForm] = useState(false);
    const [canChangeMean, setCanChangeMean] = useState(false);
    const [currentSubscription, setCurrentSubscription] = useState(
        activeSubscription,
    );
    const [customerWantsToChangeMean, setCustomerWantsToChangeMean] = useState(
        false,
    );
    const [customerWantsToUpdateCB, setCustomerWantsToUpdateCB] = useState(
        false,
    );
    let trackerId = id ? id : activeSubscription ? activeSubscription.trackers[0] : null
    const changePaymentMeanT = useTranslation('changePaymentMean').t as any;
    const subscriptionPaymentT = useTranslation('subscriptionPayment').t as any;
    const transferPageT = useTranslation('transferPage').t;
    const commonActions = useTranslation('commonActions').t;
    const paymentsT = useTranslation('payments').t;

    const formatDebitAmount = () => {
        if (activeSubscription) {
            return formatPrice(1, activeSubscription.currency);
        }
        return '1,00€';
    };

    const isGocardless = user?.default_payment_mean.payment_mean === PaymentCode.GOCARDLESS

    // Check if current subscription is eligible to change mean
    useEffect(() => {
        if (currentSubscription) {
            checkEligibilityToChangeMean(currentSubscription.id).then((value) => {
                setCanChangeMean(value);
                setLoading(false);
            });
        }
    }, []); // Only called once.

    if (!currentSubscription) {
        return <Link to={trackersRoute + '/' + trackerId} />;
    }
    /* Send a GA event on ChangeCreditCard Button click*/
    const GoogleAnalyticsOnCardChange = () => {
        return ReactGA.event({
            action: 'new-payment-method',
            category: 'button-new-payment-method',
            label: 'click-on-button-new-payment-method',
        });
    };

    const renderBackButton = () => {
        return (
            <div className={styles.return}>
                <Button
                    secondary
                    iconLeft={<DashIcon className={styles.backIcon} />}
                    onClick={() => {

                        if (customerWantsToChangeMean) {
                            return setCustomerWantsToChangeMean(false);
                        }
                        if (isFromAccountPage && customerWantsToUpdateCB) {
                            return setCustomerWantsToUpdateCB(false);
                        }
                        return history(trackersRoute);
                    }}
                >

                    {commonActions('BACK')}
                </Button>
            </div>
        );
    };

    const redirectToChangeCardSuccess = () => {
        if (trackerId) {
            return history(
                trackersRoute +
                '/' +
                `${trackerId}` +
                PaymentRedirectionUrls.CHANGE_CARD_SUCCESS,
            );
        }
        return history(trackersRoute)
    };

    const maybeRenderButtonChoiceDependingOnCurrentPaymentMeant = () => {
        if (!isGocardless) {
            return (
                <div style={{ marginLeft: '0' }} className={cx(styles.container, styles.reverse)}>
                    <Button
                        className={styles.update}
                        onClick={() => {
                            setCustomerWantsToUpdateCB(true);
                        }}
                    >
                        {changePaymentMeanT('UPDATE_BANK_CARD')}
                    </Button>
                    {canChangeMean ? (
                        <Button
                            className={styles.buttonModify}
                            onClick={() => setCustomerWantsToChangeMean(true)}
                        >
                            {changePaymentMeanT('GO_TO_SEPA')}
                        </Button>
                    ) : null}
                </div>
            );
        }
        return (
            <div className={cx(styles.container, styles.reverse)}>
                <Button
                    className={styles.update}
                    onClick={handleBankAccountSubmit}
                >
                    {getBankTransferSubTitle()}
                </Button>
                <Button
                    className={styles.buttonModify}
                    onClick={() => {
                        setCustomerWantsToChangeMean(true);
                        setCustomerWantsToUpdateCB(true);
                    }}
                >
                    {changePaymentMeanT('ADD_BANK_CARD')}
                </Button>
            </div>
        );
    };

    const renderChoiceBetweenCBAndLevy = () => (
        <PageContainer>
            <div className={styles.sidesPadding3rem}>
                <div>
                    <Headline className={styles.headline}>
                        {changePaymentMeanT('MODIFY')}
                    </Headline>
                </div>
                <div className={cx([styles.container])}>
                    <div className={cx([styles.description])}>
                        <p>
                            <>{changePaymentMeanT('CURRENT_MEAN')}</>
                        </p>
                        <p>
                            <>
                                {!isGocardless
                                    ? changePaymentMeanT('CURRENT_CHOICE_CB')
                                    : changePaymentMeanT('CURRENT_CHOICE_IBAN')}
                            </>
                        </p>
                    </div>
                </div>
                {maybeRenderButtonChoiceDependingOnCurrentPaymentMeant()}
            </div>
        </PageContainer>
    );

    const renderUpdateCB = () => (
        <PageContainer>
            <div className={styles.sidesPadding3rem}>

                <div className={styles.sidesPadding3rem}>
                    <div>
                        <Headline className={styles.headline}>
                            {changePaymentMeanT('MODIFY')}
                        </Headline>
                    </div>

                    <div className={styles.container}>
                        <div className={styles.description}>
                            <p>{changePaymentMeanT('UPDATE_BANK_CARD')}</p>
                            <p>
                                {changePaymentMeanT('CAPTATION', {
                                    amount: formatDebitAmount(),
                                })}
                            </p>
                            <p>{changePaymentMeanT('CAPTATION_EXPLANATION1')}</p>
                            <p>{changePaymentMeanT('CAPTATION_EXPLANATION2')}</p>
                            <p>{changePaymentMeanT('CAPTATION_EXPLANATION3')}</p>
                        </div>
                        <div className={styles['available-pm']}>
                            <p>{changePaymentMeanT('CARD_AUTHORIZED')}</p>
                            <img src={cbImage} alt={paymentsT('CARD.NAME')} />
                            <img src={visaImage} alt="Visa" />
                            <img src={masterCardImage} alt="Mastercard" />
                            <img src={americanExpressImage} alt="American Express" />
                        </div>
                    </div>

                    <div className={cx(styles.container, styles.reverse)}>
                        {renderBackButton()}
                        <Button
                            className={styles.update}
                            onClick={() => {
                                GoogleAnalyticsOnCardChange();
                                if (
                                    canChangeMean &&
                                    customerWantsToChangeMean &&
                                    isGocardless
                                ) {
                                    doChangeMean(currentSubscription!.id).then(
                                        (newSubscription) => {
                                            setCurrentSubscription(newSubscription);
                                        },
                                    );
                                }
                                setDisplayHipayForm(true);
                            }}
                        >
                            {changePaymentMeanT('UPDATE_BANK_CARD')}
                        </Button>
                    </div>
                </div>
            </div>
        </PageContainer>
    );

    /* Will display text according to a subscription paid by credit card (Hipay)*/
    const maybeRenderCardPaymentMean = () => {
        if (
            canChangeMean &&
            customerWantsToChangeMean === false &&
            customerWantsToUpdateCB === false
        ) {
            return renderChoiceBetweenCBAndLevy();
        }
        if (
            !displayHipayForm &&
            ((!canChangeMean && !isGocardless) ||
                (canChangeMean && customerWantsToUpdateCB))
        ) {
            return renderUpdateCB();
        }
        const submitMethod = onCreditCardChange;

        if (
            (canChangeMean && customerWantsToChangeMean && isGocardless) ||
            customerWantsToUpdateCB ||
            displayHipayForm
        ) {
            // When Change payment mean button is clicked, display hipay form
            return (
                <div className={styles.sidesPadding3rem}>

                    <HipayStep
                        hipayFormID={hipayFormID}
                        subscriptionId={activeSubscription!.id}
                        trackerId={trackerId}
                        title={changePaymentMeanT('UPDATE_BANK_CARD')}
                        onSubmit={submitMethod}
                        onSuccess={(onSubmitResponse: ISubscriptionRedirection) => {
                            // Redirect to external web page.
                            // if redirect_url is set and not empty
                            if (onSubmitResponse.redirect_url) {
                                return redirectToPaymentGate(
                                    onSubmitResponse.redirect_url,
                                );
                            }
                            return redirectToChangeCardSuccess();
                        }}
                        onFail={(
                            customMessage: string = subscriptionPaymentT(
                                'ERRORS.UNKNOWN',
                            ),
                        ) =>
                            showNotification({
                                content: customMessage,
                                type: 'error',
                            })
                        }
                    />
                </div>
            );
        }
    };

    const handleBankAccountSubmit = () => {
        return onBankAccountChange(currentSubscription!.id).catch(() => {
            showNotification({
                content: changePaymentMeanT('BANK_ACCOUNT_ERROR'),
                type: 'error',
            });
        });
    };

    const getBankTransferHeadline = () => {
        if (customerWantsToChangeMean) {
            return changePaymentMeanT('GO_TO_SEPA');
        }
        return changePaymentMeanT('MODIFY');
    };
    const getBankTransferSubTitle = () => {
        if (customerWantsToChangeMean) {
            return changePaymentMeanT('ADD_BANK_ACCOUNT');
        }
        return changePaymentMeanT('UPDATE_BANK_ACCOUNT');
    };
    const getBankTransferExplanation = () => {
        if (customerWantsToChangeMean) {
            return changePaymentMeanT('BANK_ACCOUNT_EXPLANATION');
        }
        return changePaymentMeanT('CAPTATION_EXPLANATION4');
    };

    const maybeRenderBankTransferPaymentMean = () => {
        if (
            (isGocardless && !canChangeMean) ||
            (customerWantsToChangeMean && !customerWantsToUpdateCB)
        ) {
            return (
                <PageContainer>
                    <div className={styles.sidesPadding3rem}>

                        <div>
                            <Headline className={styles.headline} center>
                                {getBankTransferHeadline()}
                            </Headline>
                        </div>

                        <div className={styles.container}>
                            <div className={styles.description}>
                                <p>{getBankTransferSubTitle()}</p>
                                <p>{getBankTransferExplanation()}</p>
                                <p>
                                    {changePaymentMeanT('CAPTATION_EXPLANATION3')}
                                </p>
                            </div>
                            <div className={styles['available-pm']}>
                                <p>
                                    <>{changePaymentMeanT('CARD_AUTHORIZED')}</>
                                </p>
                                <div className={styles['available-deb']}>
                                    <img src={sepa} alt="SEPA" />
                                    <img src={directDebit} alt="Direct debit" />
                                    <img src={singleEuro} alt="single euro" />
                                </div>
                            </div>
                        </div>

                        <div className={cx(styles.container, styles.reverse)}>
                            {renderBackButton()}
                            <Button
                                className={styles.update}
                                // change mean if customer is eligible (from Credit card to IBAN)
                                // Else simply change IBAN
                                onClick={() =>
                                    customerWantsToChangeMean
                                        ? doChangeMean(currentSubscription!.id).then(
                                            (newSubscription) => {
                                                setCurrentSubscription(
                                                    newSubscription,
                                                );
                                            },
                                        )
                                        :
                                        handleBankAccountSubmit()
                                }
                            >
                                {getBankTransferSubTitle()}
                            </Button>
                        </div>
                    </div>
                </PageContainer>
            );
        }
    };

    const renderLoadingOrChangeMean = () => {
        if (loading) {
            return <Spinner loading />
        }
        return (
            <>
                {maybeRenderCardPaymentMean()}
                {maybeRenderBankTransferPaymentMean()}
            </>
        )
    }

    return (
        <div>
            {isFromAccountPage ? null :
                <SimpleHeaderWithLogout
                    theme={activeTheme}
                    leftSlot={
                        <Link to={trackersRoute + '/' + id}>
                            <BackLink black collapseOnMobile>
                                {transferPageT('BACK_TO_TRACKERS')}
                            </BackLink>
                        </Link>
                    }
                />}
            {renderLoadingOrChangeMean()}
        </div>
    );
};

const makeMapState = (
    state: IRootState,
    props: IChangePaymentMeanProps,
): IStateProps => {
    const { rawTrackers } = state.userTrackers;
    // we need to have subscripton id to switch payment mean 
    let trackerInfo
    trackerInfo = rawTrackers.find(
        (tracker: ITracker) => tracker.id === props.trackerId!,
    );
    if (rawTrackers.length === 1) {
        trackerInfo = rawTrackers[0]
    }
    if (!trackerInfo) {
        const getActiveSub = makeGetFirstActiveSubscription();
        const activesub = getActiveSub(state);
        trackerInfo = rawTrackers.find(
            (tracker: ITracker) => tracker.id === activesub?.trackers[0]);
    }
    return {
        user: state.user.userData,
        activeSubscription: trackerInfo ? trackerInfo?.subscription : rawTrackers[0].subscription,
        id: props.trackerId,
        activeTheme: state.userTrackers.theme,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    onCreditCardChange: async (paymentPayload) =>
        dispatch.userSubscriptions.changeCreditCard(paymentPayload),
    onBankAccountChange: (subscriptionId: number) => {
        return dispatch.userSubscriptions.changeBankAccount(
            subscriptionId,
        ) as any;
    },
    checkEligibilityToChangeMean: async (subscriptionId: number) =>
        dispatch.userSubscriptions.isEligibleToChangeMean(subscriptionId),
    doChangeMean: async (subscriptionId: number) =>
        dispatch.userSubscriptions.changeMean(subscriptionId),
    showNotification: dispatch.notifications.showNotification,
});

export const ConnectedChangePaymentMeanPage = compose<
    IChangePaymentMeanProps,
    any
>(
    withSelectedTracker,
    withActiveTrackerResolving,
    connect(makeMapState, mapDispatch),
    shouldUpdate(
        (
            currentProps: IChangePaymentMeanProps,
            nextProps: IChangePaymentMeanProps,
        ) => {
            return (
                currentProps.activeSubscription?.id !==
                nextProps.activeSubscription?.id ||
                currentProps.activeTheme !== nextProps.activeTheme
            );
        },
    ),
)(ChangePaymentMeanPage);
