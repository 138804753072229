import { IEditableUserData, IRawUserData } from '../interfaces';

export const mapUserToEditableFields = (
    user: IRawUserData,
): IEditableUserData => ({
    city: user.city,
    country: user.country,
    email: user.mail,
    firstName: user.firstname,
    lastName: user.lastname,
    marketingAccepted: user.optin,
    phone: user.phone,
    postalCode: user.postal_code,
    street: user.address,
    preferredMetricSystem: user.preferred_metric_system,
});
