import cx from 'classnames';
import React, { HTMLAttributes, ReactNode } from 'react';

import styles from './menu.module.scss';

export interface IMenuProps extends HTMLAttributes<HTMLUListElement> {}

export const Menu = ({ children, className }: IMenuProps) => (
    <ul className={cx(styles.menuList, className)}>
        {React.Children.map(children, (child: ReactNode) => {
            if (!React.isValidElement(child)) {
                return child;
            }

            return React.cloneElement(child, {
                // @ts-ignore todo: try to somehow provide strong types for children
                className: cx(child.props.className, styles.menuItem),
            });
        })}
    </ul>
);
