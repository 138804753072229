import cx from 'classnames';
import moment from 'moment';
import React, { HTMLAttributes, useState } from 'react';
import ReactCalendar from 'react-calendar';
import onClickOutside from 'react-onclickoutside';
import { withProps } from 'recompose';

import { Button, IButtonProps } from '../../common/button/button.component';
import { Input } from '../../common/input/input.component';
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';
import { ReactComponent as ArrowIcon } from '../../icons/dash.svg';

import styles from './calendar.module.scss';

const ButtonLeft = withProps<IButtonProps, IButtonProps>({
    secondary: true,
    small: true,
    className: styles.button,
    children: <ArrowIcon className={styles['arrow-left']} />,
})(Button);

const ButtonRight = withProps<IButtonProps, IButtonProps>({
    secondary: true,
    small: true,
    className: styles.button,
    children: <ArrowIcon className={styles['arrow-right']} />,
})(Button);

const EnhancedCalendar = onClickOutside(ReactCalendar);

interface IProps extends HTMLAttributes<HTMLDivElement> {
    date: string;
    isToday: boolean;
    onNewDateSelected(date: string): unknown;
    onPrevDay(): unknown;
    onNextDay(): unknown;
}

export const Calendar = ({
    date,
    onNewDateSelected,
    onNextDay,
    onPrevDay,
    className,
    isToday,
}: IProps) => {
    const [calOpen, setCalOpen] = useState(false);

    const onInputClick = () => setCalOpen(true);
    const onCalendarBlurred = () => setCalOpen(false);

    return (
        <div className={cx(styles.root, className)}>
            <div className={styles.row}>
                <ButtonLeft onClick={onPrevDay} title="Previous day" />
                <Input
                    small
                    className={styles.input}
                    rightIcon={<CalendarIcon onClick={onInputClick} />}
                    onClick={onInputClick}
                    value={moment(date).format('DD MMMM YYYY')}
                    onChange={() => { }}
                />
                <ButtonRight
                    onClick={onNextDay}
                    disabled={isToday}
                    title="Next day"
                />
            </div>
            {calOpen && (
                <div
                    className={styles['calendar-wrapper']}
                    data-testid="calendar-picker-wrapper"
                >
                    <EnhancedCalendar
                        nextLabel={
                            <ArrowIcon className={styles['arrow-right']} />
                        }
                        prevLabel={
                            <ArrowIcon className={styles['arrow-left']} />
                        }
                        next2Label={null}
                        prev2Label={null}
                        onChange={(e: any) => {
                            onNewDateSelected(new Date(e).toISOString());
                            setCalOpen(false);
                        }}
                        className={styles['calendar-component']}
                        handleClickOutside={onCalendarBlurred}
                        maxDate={new Date()}
                        value={new Date(date)}
                    />
                </div>
            )}
        </div>
    );
};
