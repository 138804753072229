import { makeGetTrackerLiveModeInterval } from 'app/trackers/selectors/get-supports-live-mode';
import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { IWithSelectedTracker, withSelectedTracker } from '../../../../utils/with-selected-tracker';
import { Headline } from '../../../common/headline/headline.component';
import { Typography } from '../../../common/typography/typography.component';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { Dispatch, IRootState } from '../../../store/store';
import { getPassedLiveModeSeconds } from '../../services/tracker-live-mode.service';
import { ConnectedLiveModeProgress } from '../live-mode-progress/live-mode-progress.component';
import styles from './live-mode-display.module.scss';

interface IOuterProps extends HTMLAttributes<HTMLDivElement> { }

interface IActions {
    closeSuperLiveModal(): unknown;
}

interface IProps extends IOuterProps, IActions, IStateProps { }

interface IStateProps {
    remainingTime: string;
    superLiveFrequency: string;
}

export const LiveModeDisplay = ({
    className,
    remainingTime,
    superLiveFrequency,
    closeSuperLiveModal,
}: IProps) => {
    const { t } = useTranslation('superLive');

    return (
        <div className={cx(styles.card, className)}>
            <div className={styles['top-row']}>
                <Headline level={4}>{t('HEADLINE')}</Headline>
                <CrossIcon
                    role="button"
                    onClick={closeSuperLiveModal}
                    className={styles['cross-icon']}
                />
            </div>
            <div className={styles['bottom-row']}>
                <ConnectedLiveModeProgress />
                <div>
                    <Typography noMargin bold>
                        {t('TIME', { frequency: superLiveFrequency})}
                    </Typography>
                    <Typography noMargin>
                        {t('ACTIVE_TEXT', { time: remainingTime })}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export const ConnectedLiveModeDisplay = compose<
    IProps & IStateProps & IWithSelectedTracker,
    IOuterProps
>(
    withSelectedTracker,
    connect(
        (state: IRootState, props: IWithSelectedTracker) => {

            const secondsPassed =
                getPassedLiveModeSeconds(props.trackerId!) || 0;
            const ttl = 60 * 5;

            let superLiveInterval = '10';
            if (props.trackerId) {
                const getActiveTrackerSuperLiveInterval = makeGetTrackerLiveModeInterval(
                    props.trackerId,
                )
                superLiveInterval = getActiveTrackerSuperLiveInterval(state)
            }

            return {
                remainingTime: ToMMSS(ttl - secondsPassed),
                superLiveFrequency: superLiveInterval
            };
        },
        (dispatch: Dispatch): IActions => ({
            closeSuperLiveModal() {
                return dispatch.trackerLiveMode.setDisplayModalOpen(false);
            },
        }),
    ),
)(LiveModeDisplay);

export const ToMMSS = (timer: number) => {
    const allSecs = Math.floor(timer);
    const minutes = Math.floor(allSecs / 60);
    const seconds = allSecs - minutes * 60;

    let returnedString = '';
    if (minutes < 10) {
        returnedString += '0';
    }
    returnedString += minutes + ':';
    if (seconds < 10) {
        returnedString += '0';
    }
    return returnedString + seconds;
};
