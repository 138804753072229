import { ITrackerOnMap } from '../trackers/interfaces';
import { ITrackerEvent } from '../trackers/interfaces-api';

export interface IViewport {
    center: any;
    zoom: number;
}

export enum LeafletLayers {
    GRAY = 'GRAY',
    SATELLITE = 'SATELLITE',
    STREET = 'STREET',
}
export enum GoogleLayers {
    GOOGLE_BIKING = 'GOOGLE_BIKING',
    GOOGLE_SATELLITE = 'GOOGLE_SATELLITE',
    GOOGLE_STREET = 'GOOGLE_STREET',
    GOOGLE_TERRAIN = 'GOOGLE_TERRAIN',
}

export enum MapLayerType {
    GRAY = 'GRAY',
    SATELLITE = 'SATELLITE',
    STREET = 'STREET',
    GOOGLE_BIKING = 'GOOGLE_BIKING',
    GOOGLE_SATELLITE = 'GOOGLE_SATELLITE',
    GOOGLE_STREET = 'GOOGLE_STREET',
    GOOGLE_TERRAIN = 'GOOGLE_TERRAIN',
}

/**
 * Mapper between MapLayerType and name of different map type from Google JS API
 */
export const MapLayerTypeToGoogleMapType = new Map<MapLayerType, string>([
    [MapLayerType.GOOGLE_STREET, 'roadmap'],
    [MapLayerType.GOOGLE_BIKING, 'roadmap'],
    [MapLayerType.GOOGLE_SATELLITE, 'satellite'],
    [MapLayerType.GOOGLE_TERRAIN, 'terrain'],
]);

/**
 * Weenect Marketing team use a shared xlsx file which defines Google analytics events.
 * Please refer to that file before modifying this.
 */
const AnalyticsLayerMapper = new Map<MapLayerType, string>([
    [MapLayerType.STREET, 'classic'],
    [MapLayerType.GRAY, 'blackandwhite'],
    [MapLayerType.SATELLITE, 'satellite'],
    // TODO ADD MapLayerType.GOOGLE
]);

export interface IOuterMapProps {
    events: ITrackerEvent[];
    trackersOnMap: ITrackerOnMap[] | null;
    shouldDisplayHeatMap: boolean;
    displayEventTooltip(
        event: ITrackerEvent,
        tracker: ITrackerOnMap | null,
    ): JSX.Element | undefined;
    onTrackerSelected(id: number): any;
}

export { AnalyticsLayerMapper };
