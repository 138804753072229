import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { compose, pure } from 'recompose';

import { ConnectedGeofenceMapMarker } from '../../../geofences/geofence-map-marker/geofence-map-marker.component';
import { IGeofenceMarker } from '../../../geofences/interfaces';
import { makeGetEditableGeofenceId } from '../../../geofences/selectors/geofences-edit.selectors';
import { makeGetGeofencesToDisplay } from '../../../geofences/selectors/geofences.selectors';
import { drawVectors } from '../../../history/activity-path/components/activity-path-google.component';
import {
    getMarkerOpacity,
    getVectors,
} from '../../../history/activity-path/utils';
import { Dispatch, IRootState } from '../../../store/store';
import { ITrackerOnMap } from '../../../trackers/interfaces';
import { ITrackerEvent } from '../../../trackers/interfaces-api';
import { makeGetSelectedTrackerToMapModel } from '../../../trackers/selectors/get-selected-tracker-model';
import { IWifiZoneMarker } from '../../../wifi-zones/interfaces';
import { makeGetWifiZonesToDisplay } from '../../../wifi-zones/selectors/wifi-zones.selectors';
import { ConnectedWifiZoneMapMarker } from '../../../wifi-zones/wifi-zones-map-marker/wifi-zones-map-marker.component';
import {
    IOuterMapProps,
    IViewport,
    MapLayerType,
    MapLayerTypeToGoogleMapType,
} from '../../interfaces';
import { ConnectedMapMarker } from '../map-marker/map-marker.component';
import { ConnectedTrackerMarker } from '../tracker-marker/tracker-marker.component';
import { TrackerOnMap } from '../trackers-on-map/trackers-on-map.component';

interface IStateProps {
    activeTracker: ITrackerOnMap | null;
    editableId: number | null;
    geofenceMarkers: IGeofenceMarker[];
    wifiZonesMarkers: IWifiZoneMarker[];
    selectedLayer: MapLayerType;
    viewport: IViewport;
}

interface IActions {
    onViewportManuallyChanged: (viewport: IViewport) => any;
    updateEditablePosition: (position: { lat: number; lng: number }) => any;
}

interface IGoogleMapProps extends IOuterMapProps, IStateProps, IActions { }


const SimpleMap = React.memo(({
    activeTracker,
    editableId,
    events,
    geofenceMarkers,
    wifiZonesMarkers,
    selectedLayer,
    shouldDisplayHeatMap,
    trackersOnMap,
    viewport,
    onTrackerSelected,
    displayEventTooltip,
    onViewportManuallyChanged,
    updateEditablePosition,
}: IGoogleMapProps) => {
    // State var used to store map objects. Only used in this component.
    const [mapConfidenceCircles, setMapConfidenceCircles] = useState<
        Array<any>
    >([]);
    const [mapVectors, setMapVectors] = useState<any[]>([]);
    const [draggable, setDraggable] = useState<boolean>(true);
    const [bikingLayer, setBikingLayer] = useState<any>(null);
    const [googleMapAPI, setGoogleMapAPI] = useState<any>(null);
    const [googleMap, setGoogleMap] = useState<any>(null);
    const [viewportState, setViewport] = useState<
        any | undefined
    >(viewport.center);
    const { pathname } = useLocation()

    let lastEvent: ITrackerEvent | null = null;
    if (events.length > 0) {
        lastEvent = events[events.length - 1];
    }

    /**
     * Remove confidenceCircles from map when no trackers are passed to it.
     * For example when activityPath is enabled (on history page).
     */
    useEffect(() => {
        if (
            activeTracker &&
            googleMapAPI &&
            googleMap &&
            mapConfidenceCircles
        ) {
            mapConfidenceCircles.forEach((circle) => {
                if (circle.id !== activeTracker.id) {
                    // check circle id on map before removing and adding a new one
                    circle.setMap(null);
                    handleApiLoaded(googleMap, googleMapAPI, trackersOnMap);
                } else {
                    return null;
                }
            });
        }
    }, [activeTracker]);

    useEffect(() => {
        //useEffect to handle zoom on items 
        if (googleMapAPI) {
            let bounds = new googleMapAPI.LatLngBounds();
            if (trackersOnMap?.length === 1) {
                return setViewport([trackersOnMap[0]?.position[0], trackersOnMap[0]?.position[1]])
            }
            if (trackersOnMap && trackersOnMap?.length > 1) {
                trackersOnMap.map((el) => {
                    let myLatLng = new googleMapAPI.LatLng(el.position[0], el.position[1]);
                    bounds.extend(myLatLng);
                })
                return googleMap.fitBounds(bounds);
            }
            if (pathname.includes('history') && events.length > 1) {
                events.map((event) => {
                    let myLatLng = new googleMapAPI.LatLng(event.latitude, event.longitude);
                    bounds.extend(myLatLng);
                })
                return googleMap.fitBounds(bounds);
            }
            return setViewport([viewport.center])
        }
    }, [trackersOnMap, googleMapAPI, events])

    /**
   * Draw vectors when list of events change.
   * Always remove previous drawings before each re-render. (as vectors are drawn by Google API = not dynamic)
   * We check Google-map API and Google-map map objects are well set before draw.
   */
    const removeAndRedrawVector = () => {
        const vectors = getVectors(events);
        if (!shouldDisplayHeatMap && vectors && events) {
            setMapVectors(drawVectors(vectors, googleMapAPI, googleMap, mapVectors));
            handleApiLoaded(googleMap, googleMapAPI, trackersOnMap);
        }
    }
    useEffect(() => {
        mapVectors.map((vectors) => {
            vectors.setMap(googleMap)
        })
    }, [mapVectors])

    useEffect(() => {
        if (googleMap) {
            removeAndRedrawVector()
        }
    }, [events, shouldDisplayHeatMap]);

    /**
     * Use Google-Map API to reset or set the biking layer.
     */
    useEffect(() => {
        if (selectedLayer !== MapLayerType.GOOGLE_BIKING && bikingLayer) {
            bikingLayer.setMap(null);
            setBikingLayer(null);
        }
        if (
            selectedLayer === MapLayerType.GOOGLE_BIKING &&
            googleMap &&
            googleMapAPI
        ) {
            const layer = new googleMapAPI.BicyclingLayer();
            setBikingLayer(layer);
            layer.setMap(googleMap);
        }
    }, [selectedLayer, googleMapAPI, googleMap]);

    /**
     * Default view of every connected tracker of the account on the map.
     *
     * @param trackersOnMap List of tracker objects to display on the map.
     */
    const maybeRenderTrackersOnMap = (
        listOfTrackersOnMap: ITrackerOnMap[] | null,
    ) => {
        if (listOfTrackersOnMap) {
            return listOfTrackersOnMap?.map((trackerOnMap) => (
                <TrackerOnMap
                    // google-map-react required fields
                    lat={trackerOnMap?.position[0]}
                    lng={trackerOnMap?.position[1]}
                    // TrackerOnGoogleMap component fields
                    trackerOnMap={trackerOnMap}
                    onTrackerSelected={() =>
                        onTrackerSelected(trackerOnMap?.id)
                    } // TODO
                    key={trackerOnMap?.id}
                />
            ));
        }
    };

    /**
     * Equivalent to drawMarkers() fn from activity-path-leaflet-component.
     * Loop on events, and draw a pin marker at every position fetched.
     */
    const drawMarkers = () => {
        if (!shouldDisplayHeatMap && useLocation().pathname.includes('history') && events.length) {
            return events.slice(0, -1).map((event, i) => {
                const eventPos = [event.latitude, event.longitude] as [
                    number,
                    number,
                ];
                const tooltipChild = displayEventTooltip(event, activeTracker);
                return (
                    <ConnectedMapMarker
                        opacity={getMarkerOpacity(i, events)}
                        key={event.id}
                        position={eventPos}
                        lat={eventPos[0]}
                        lng={eventPos[1]}
                        tooltipChild={tooltipChild}
                    />
                );
            });
        }
    };

    /**
     * Equivalent of maybeDrawTrackerMarker fn from activity-path-leaflet.component.
     * Draw the tracker marker along with new positions
     * received from fetched ones or during the 'play history' feature.
     */
    const maybeDrawTrackerMarker = () => {
        if (activeTracker && lastEvent) {
            const lastPos = [lastEvent.latitude, lastEvent.longitude] as [
                number,
                number,
            ];
            return (
                <ConnectedTrackerMarker
                    iconUrl={activeTracker.urlPicture}
                    // lat & lng are google-map-react required props
                    lat={lastPos[0]}
                    lng={lastPos[1]}
                    position={lastPos}
                    iconName={activeTracker.icon}
                    trackerType={activeTracker.type}
                    trackerColor={activeTracker.color}
                    validGps={true}
                />
            );
        }
    };

    const drawGeofences = () =>
        geofenceMarkers.map((geofence: IGeofenceMarker) => (
            <ConnectedGeofenceMapMarker
                lat={geofence.position.lat}
                lng={geofence.position.lng}
                key={geofence.geofenceId || '0'}
                position={geofence.position}
                radius={geofence.radius}
                editable={editableId === geofence.geofenceId}
            />
        ));

    const drawWifiZones = () =>
        wifiZonesMarkers.map((wifiZone: IWifiZoneMarker) => (
            <ConnectedWifiZoneMapMarker
                id={wifiZone.id!}
                lat={wifiZone.position.lat}
                lng={wifiZone.position.lng}
                display={wifiZone.displayed}
                key={wifiZone.id || '0'}
                position={wifiZone.position}
                radius={wifiZone.radius}
                editable={editableId === wifiZone.id}
            />
        ));

    const maybeRenderHeatMap = () => {
        if (shouldDisplayHeatMap) {
            const data = events.map((event) => ({
                lat: event.latitude,
                lng: event.longitude,
            }));
            return {
                positions: data,
                options: {
                    radius: 20,
                    opacity: 1,
                },
            };
        }
        return {
            positions: [],
            options: {
                radius: 20,
                opacity: 1,
            },
        };
    };
    const heatMapData = maybeRenderHeatMap();

    const handleApiLoaded = (
        map: any,
        maps: any,
        listOfTrackersOnMap: ITrackerOnMap[] | null,
    ) => {
        // Save google-map API maps object and current google map instance in state.
        if (!googleMap && !googleMapAPI) {
            // do not set those if we already have
            setGoogleMapAPI(maps);
            setGoogleMap(map);
        }

        if (listOfTrackersOnMap) {
            // When tracker has no position it seems like listOfTrackersOnMap contains
            // 1 element which is undefined
            // TODO : Investigate why and fix it
            if (listOfTrackersOnMap[0] === undefined) {
                return;
            }

            // We save confidence circles in state to be able to remove them when desired (see useEffect)
            setMapConfidenceCircles(
                // Add confidence circle to represent geo-localization imprecision
                // TODO Adjust position of confidence circles on tracker position updates.
                // TODO Make the Circle a round object and not a polygonal weirdo.
                listOfTrackersOnMap.map(
                    (trackerOnMap) =>
                        new maps.Circle({
                            strokeColor: trackerOnMap.color,
                            strokeOpacity: 0.8,
                            strokeWeight: 1,
                            fillColor: trackerOnMap.color,
                            fillOpacity: 0.35,
                            id: trackerOnMap.id,
                            map,
                            center: {
                                lat: trackerOnMap.position[0],
                                lng: trackerOnMap.position[1],
                            },
                            radius: trackerOnMap.radius,
                        }),
                ),
            );
        }
    };

    /**
     * Remove Google Map controllers as we have implemented ours.
     */

    const createMapOptions = () => ({
        fullscreenControl: false,
        zoomControl: false,
        mapTypeId: MapLayerTypeToGoogleMapType.get(selectedLayer) || 'roadmap',
    });

    const handleMapChange = (changeValue: GoogleMapReact.ChangeEventValue) => {
        const newViewport: IViewport = {
            center: changeValue.center,
            zoom: changeValue.zoom,
        };
        onViewportManuallyChanged(newViewport);
    };

    const handleMouseDown = (childKey: any, childProps: any, mouse: any) => {
        setDraggable(false);
        updateEditablePosition({ lat: mouse.lat, lng: mouse.lng });
    };
    const handleMouseUp = (childKey: any, childProps: any, mouse: any) => {
        setDraggable(true);
    };

    return (
        // Important! Always set the container height explicitly
        <div
            style={{
                height: 'calc(100vh - 6rem)',
                width: '100%',
                position: 'relative',
            }}
        >
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: 'AIzaSyByljitxiNSSL1XnfeMT_XM63mNYE_UEgk',
                    libraries: ['visualization'],
                }}
                options={createMapOptions}
                center={viewportState}
                zoom={viewport.zoom}
                draggable={draggable}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps, trackersOnMap)
                }
                onChildMouseDown={handleMouseDown}
                onChildMouseUp={handleMouseUp}
                onChildMouseMove={handleMouseDown}
                heatmap={heatMapData}
            >
                {maybeRenderTrackersOnMap(trackersOnMap)}
                {drawMarkers()}
                {maybeDrawTrackerMarker()}
                {drawGeofences()}
                {drawWifiZones()}
            </GoogleMapReact>
        </div>
    );
});

const mapState = (state: IRootState): IStateProps => {
    const getActiveTracker = makeGetSelectedTrackerToMapModel();
    const getEditableId = makeGetEditableGeofenceId();
    const getGeofencesToDisplay = makeGetGeofencesToDisplay();
    const getWifiZonesToDisplay = makeGetWifiZonesToDisplay();

    return {
        activeTracker: getActiveTracker(state),
        editableId: getEditableId(state),
        geofenceMarkers: getGeofencesToDisplay(state),
        wifiZonesMarkers: getWifiZonesToDisplay(state),
        selectedLayer: state.map.selectedLayer,
        viewport: { center: state.map.center, zoom: state.map.zoom },
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    onViewportManuallyChanged: (viewport: IViewport) =>
        dispatch.map.setViewportByUserAction(viewport),
    updateEditablePosition: dispatch.geofenceEdit.updatePosition,
});

export const ConnectedGoogleMap = compose<IGoogleMapProps, IOuterMapProps>(
    connect(mapState, mapDispatch),
)(SimpleMap);
