const MIN_RADIUS = 50;
const MAX_RADIUS = 2000;

export const fitRadiusRange = (radius: number) =>
    radius >= MIN_RADIUS && radius <= MAX_RADIUS;

export const withRadiusRange = (radius: number) => {
    if (radius < MIN_RADIUS) {
        return MIN_RADIUS;
    } else if (radius > MAX_RADIUS) {
        return MAX_RADIUS;
    }

    return radius;
};
