import cx from 'classnames';
import { FormikBag, FormikProps, withFormik } from 'formik';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { IWithFormUtils, withFormUtils } from '../../../utils/form-helpers';

import { Button } from '../../common/button/button.component';
import { Input } from '../../common/input/input.component';

import { ReactComponent as EmailIcon } from '../../icons/mail.svg';
import { ForgotPasswordFormValidationSchema } from './forgot-password-form-validation';

import styles from './forgot-password.module.scss';

interface IFormValues {
    email: string;
}

interface IOuterProps extends HTMLAttributes<HTMLFormElement> {
    onFormSubmitted: (email: string) => Promise<any>;
}

interface IProps extends FormikProps<IFormValues>, IWithFormUtils, IOuterProps {
    className?: string;
}

export const ForgotPasswordForm = ({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    className,
    getErrorMessage,
    isFieldValid,
}: IProps) => {
    const translateForgotPassword = useTranslation('forgotPassword');
    const translateForms = useTranslation('forms');

    const forgotPasswordT = translateForgotPassword.t;
    const formsT = translateForms.t;

    return (
        <form className={cx(styles.form, className)} onSubmit={handleSubmit}>
            <Input
                id="login-form-email-input"
                className={styles.input}
                placeholder={formsT('EMAIL.PLACEHOLDER')}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                type="email"
                leftIcon={<EmailIcon />}
                errorMessage={getErrorMessage('email')}
                isValid={isFieldValid('email')}
                label={formsT('EMAIL.LABEL')}
            />
            <Button type="submit" className={styles['submit-button']} block>
                {forgotPasswordT('FORGOT_PASSWORD_BUTTON')}
            </Button>
        </form>
    );
};

export const ControlledForgotPasswordForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IFormValues>({
        mapPropsToValues: (props: IOuterProps) => ({
            email: '',
        }),
        validationSchema: ForgotPasswordFormValidationSchema,
        handleSubmit: (
            values: IFormValues,
            formikBag: FormikBag<IOuterProps, IFormValues>,
        ) => {
            formikBag.props.onFormSubmitted(values.email);
        },
    }),

    withFormUtils,
)(ForgotPasswordForm);
