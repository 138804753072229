import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../common/button/button.component';

import { Headline } from '../../common/headline/headline.component';
import { IModalProps, Modal } from '../../common/modal/modal.component';

import styles from './tracker-replace-modal.module.scss';

interface IProps extends IModalProps {
    onReplaceClick?: () => any;
    onAddClick?: () => any;
}

export const TrackerReplaceModal = ({
    onClosed,
    onReplaceClick,
    onAddClick,
}: IProps) => {
    const { t } = useTranslation(['transferPage']);

    return (
        <Modal onClosed={onClosed} className={styles['replacement-modal']}>
            <Headline
                center
                level={2}
                className={styles['replacement-modal-title']}
            >
                {t('FIRST_TITLE')}
            </Headline>
            <Button
                className={styles['replacement-modal-button']}
                onClick={onReplaceClick}
                secondary
            >
                {t('REPLACE_TRACKER')}
            </Button>
            <Button
                className={styles['replacement-modal-button']}
                onClick={onAddClick}
            >
                {t('ADD_TRACKER')}
            </Button>
        </Modal>
    );
};
