import cx from 'classnames';
import { FormikBag, FormikProps, withFormik } from 'formik';
import React, { ChangeEvent, HTMLAttributes, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compose, mapProps } from 'recompose';

import { IWithFormUtils, withFormUtils } from '../../../utils/form-helpers';
import { IMetricFactors } from '../../account/interfaces';
import { Button } from '../../common/button/button.component';
import { Input } from '../../common/input/input.component';
import { Link } from '../../common/link/link.component';
import { Toggle } from '../../common/toggle/toggle.component';
import { InfoTooltip } from '../../common/tooltip/tooltip.component';
import { Typography } from '../../common/typography/typography.component';
import { MetricSystem } from '../../history/interfaces';
import { ReactComponent as IconCross } from '../../icons/cross.svg';
import { ReactComponent as IconLocation } from '../../icons/gps-on.svg';
import { ReactComponent as IconTick } from '../../icons/tick.svg';
import { Grid } from '../../layout/grid/grid.component';
import { store } from '../../store/store';
import { ConnectedGeocodeInput } from '../geofence-geocode/geofence-geocode.component';
import { IGeofence } from '../interfaces';
import { RoundButtonMinus, RoundButtonPlus } from './buttons';
import styles from './edit-geofence-form.module.scss';
import { getEditGeofenceValidationSchema } from './edit-geofence-validation.schema';
import { fitRadiusRange, withRadiusRange } from './with-radius-range';

export interface IFormValues {
    radius: number;
    name: string;
    entranceNotification: boolean;
    exitNotification: boolean;
    outdoors: boolean;
    preferredMetric: MetricSystem.YARD | MetricSystem.METERS;
}

export interface IWithLatLng {
    lat: number;
    lng: number;
}

interface IMappedProps {
    incrementRadius(): void;
    decrementRadius(): void;
    onRadiusChange(e: ChangeEvent<HTMLInputElement>): void;
}

export interface IOuterProps
    extends HTMLAttributes<HTMLFormElement>,
        IWithLatLng {
    canBeDeleted: boolean;
    geofenceData?: IGeofence;
    geofenceDuringNewCreating: boolean;
    trackerPositionEnabled: boolean;
    onDelete?(): unknown;
    onRadiusChanged(newRadius: number): any;
    onTrackerPositionTriggered(): unknown;
    onSave(values: IFormValues & IWithLatLng): Promise<unknown>;
    onCancel(): any;
}

export interface IProps
    extends FormikProps<IFormValues>,
        IOuterProps,
        IMappedProps,
        IWithFormUtils {}

const radiusTreshold = 25;

export const EditGeofenceForm = ({
    canBeDeleted,
    className,
    decrementRadius,
    geofenceDuringNewCreating,
    getErrorMessage,
    handleBlur,
    handleChange,
    handleSubmit,
    incrementRadius,
    isFieldValid,
    lat,
    lng,
    onCancel,
    onDelete,
    onRadiusChange,
    onRadiusChanged,
    onTrackerPositionTriggered,
    setFieldValue,
    trackerPositionEnabled,
    values,
}: IProps) => {
    const { t } = useTranslation(['geofences', 'commonActions']);
    /**
     * Listen on radius change and lock if doens't fit
     */
    useEffect(() => {
        if (!fitRadiusRange(values.radius)) {
            setFieldValue('radius', withRadiusRange(values.radius));
        } else {
            onRadiusChanged(values.radius);
        }
    }, [values.radius, onRadiusChanged, setFieldValue]);

    const maybeRenderDeleteButton = () => {
        if (canBeDeleted) {
            return (
                <Link
                    className={styles.deleteButton}
                    onClick={(e) => {
                        e.preventDefault();
                        if (onDelete) {
                            onDelete();
                        }
                    }}
                >
                    {t('EDIT.DELETE_GEOFENCE')}
                </Link>
            );
        }
    };

    /* We don't allow to modify position of existing geofences by clicking on map
    so only display this text on new creation */

    const maybeRenderPointOnMapText = () => {
        if (geofenceDuringNewCreating) {
            return (
                <Typography size14 gray>
                    {t('EDIT.POINT_ON_THE_MAP_TEXT')}
                </Typography>
            );
        }
    };

    return (
        <form
            className={cx(className)}
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            <div className={styles.locationSection}>
                <Typography withMarginBottom size14>
                    {t('EDIT.LOCATION')}
                </Typography>

                <ConnectedGeocodeInput />

                <Typography gray withMarginBottom size14>
                    {lat.toFixed(6)}, {lng.toFixed(6)}
                </Typography>
                <Grid>
                    <Button
                        onClick={onTrackerPositionTriggered}
                        secondary
                        small
                        disabled={!trackerPositionEnabled}
                        iconLeft={<IconLocation />}
                    >
                        {t('EDIT.TRACKER_LAST_POSITION_BUTTON')}
                    </Button>

                    {maybeRenderPointOnMapText()}
                </Grid>
            </div>
            <div className={styles.radiusSection}>
                <Typography size14 withMarginBottom>
                    {t('EDIT.RADIUS')}
                </Typography>
                <div className={styles.grid}>
                    <RoundButtonMinus onClick={decrementRadius} />
                    <Input
                        className={styles.radiusInput}
                        onChange={onRadiusChange}
                        name="radius"
                        value={values.radius}
                        small
                        onBlur={handleBlur}
                        nativeFieldClass={styles.radiusNativeField}
                        rightIcon={
                            <Typography
                                noMargin
                                className={styles.radiusInputSuffix}
                            >
                                {values.preferredMetric}
                            </Typography>
                        }
                    />
                    <RoundButtonPlus onClick={incrementRadius} />
                </div>
            </div>
            <div className={styles.nameSection}>
                <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    name="name"
                    label={t('EDIT.NAME_INPUT.LABEL')}
                    small
                    errorMessage={getErrorMessage('name')}
                    isValid={isFieldValid('name')}
                />
            </div>
            <div>
                <Toggle
                    checked={values.entranceNotification}
                    name="entranceNotification"
                    onChange={handleChange}
                    className={styles.toggle}
                >
                    {t('EDIT.ENTRANCE_NOTIFICATION')}
                </Toggle>
                <Toggle
                    checked={values.exitNotification}
                    name="exitNotification"
                    onChange={handleChange}
                    className={styles.toggle}
                >
                    {t('EDIT.EXIT_NOTIFICATION')}
                </Toggle>
                <Toggle
                    checked={values.outdoors}
                    name="outdoors"
                    onChange={handleChange}
                    className={styles.toggle}
                >
                    {t('EDIT.OUTDOORS')}
                    <InfoTooltip
                        className={styles.tooltip}
                        content={t('EDIT.OUTDOORS_TOOLTIP')}
                    />
                </Toggle>
            </div>
            <div className={styles.buttons}>
                <Button
                    iconLeft={<IconTick />}
                    small
                    type="submit"
                    className={styles.saveButton}
                >
                    {t('commonActions:SAVE')}
                </Button>
                <Button
                    secondary
                    iconLeft={<IconCross />}
                    small
                    onClick={onCancel}
                >
                    {t('commonActions:CANCEL')}
                </Button>
                {maybeRenderDeleteButton()}
            </div>
        </form>
    );
};

export const ControlledEditGeofenceForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IFormValues>({
        handleSubmit(
            values: IFormValues,
            formikBag: FormikBag<IOuterProps, IFormValues>,
        ) {
            if (values.preferredMetric === MetricSystem.YARD) {
                values.radius = parseFloat(
                    (values.radius / IMetricFactors.M_TO_YARD).toFixed(2),
                );
            }
            formikBag.props.onSave({
                ...values,
                lat: formikBag.props.lat,
                lng: formikBag.props.lng,
            });
        },
        mapPropsToValues: (props) => {
            const userData = store.getState().user.userData;
            let preferredMetric = MetricSystem.METERS;
            if (props.geofenceData) {
                if (userData) {
                    if (
                        userData.preferred_metric_system === MetricSystem.MILES
                    ) {
                        preferredMetric = MetricSystem.YARD;
                        props.geofenceData.radius = parseFloat(
                            (
                                props.geofenceData.radius *
                                IMetricFactors.M_TO_YARD
                            ).toFixed(2),
                        );
                    }
                }
                return {
                    name: props.geofenceData.name,
                    entranceNotification:
                        props.geofenceData.entranceNotification,
                    exitNotification: props.geofenceData.exitNotification,
                    outdoors: props.geofenceData.outdoors,
                    radius: props.geofenceData.radius,
                    preferredMetric,
                };
            }
            if (
                userData &&
                userData.preferred_metric_system === MetricSystem.MILES
            ) {
                preferredMetric = MetricSystem.YARD;
            }

            return {
                name: '',
                entranceNotification: true,
                exitNotification: true,
                outdoors: false,
                radius: 100,
                preferredMetric,
            };
        },
        validationSchema: () => getEditGeofenceValidationSchema(),
    }),
    mapProps(
        (props: FormikProps<IFormValues>): IMappedProps => ({
            ...props,
            decrementRadius() {
                props.setFieldValue(
                    'radius',
                    props.values.radius - radiusTreshold,
                );
            },
            incrementRadius() {
                props.setFieldValue(
                    'radius',
                    props.values.radius + radiusTreshold,
                );
            },
            onRadiusChange(e: ChangeEvent<HTMLInputElement>) {
                const newVal = parseInt(e.target.value, 10);
                props.setFieldValue('radius', newVal);
            },
        }),
    ),
    withFormUtils,
)(EditGeofenceForm);
