import React, { HTMLAttributes } from 'react';
import { Trans } from 'react-i18next';
import { getGlobalT } from '../../../../i18n/setup-translations';

import { Typography } from '../../../common/typography/typography.component';
import { ITrackerEvent } from '../../../trackers/interfaces-api';
import { ActivityItem } from './activity-item.component';

const t = getGlobalT();

const handledEventTypes = [
    'ALM-A',
    'ALM-BAT',
    'ALM-DSW',
    'ALM-F',
    'ALM-G',
    'ALM-GEO-I',
    'ALM-GEO-O',
    'ALM-H',
    'ALM-OFF',
    'ALM-ON',
    'CMD-C',
    'CMD-M',
    'CMD-R',
    'CMD-V3',
    'CMD-V30',
    'CMD-W',
    'CMD-WBT',
    'CMD-WMAC',
];

export const translationMapping = (event: ITrackerEvent) => {
    switch (event.type) {
        case 'ALM-A':
            return (
                <Typography gray noMargin>
                    {t('activity:EVENTS.SOS')}
                </Typography>
            );
        case 'ALM-BAT':
            const battery = event.battery;
            return (
                <Trans ns="activity" i18nKey="EVENTS.BATTERY">
                    <Typography tag="span" gray noMargin>
                        <> Low battery - {{ battery }}% remaining</>
                    </Typography>
                </Trans>
            );
        case 'ALM-F':
        case 'ALM-G':
            return (
                <Typography gray noMargin>
                    {t('activity:EVENTS.ALERT')}
                </Typography>
            );
        case 'ALM-GEO-I':
            return (
                <Typography gray noMargin>
                    {t('activity:EVENTS.ENTERS_GEOFENCE')}
                    <Typography tag="span" noMargin>
                        {event.geofence_name}
                    </Typography>
                </Typography>
            );
        case 'ALM-GEO-O':
            return (
                <Typography gray noMargin>
                    {t('activity:EVENTS.EXIT_GEOFENCE')}{' '}
                    <Typography tag="span" noMargin>
                        {event.geofence_name}
                    </Typography>
                </Typography>
            );
        case 'ALM-H':
        case 'ALM-OFF':
            return (
                <Typography gray noMargin>
                    {t('activity:EVENTS.TRACKER_OFF')}
                </Typography>
            );
        case 'ALM-ON':
            return (
                <Typography gray noMargin>
                    {t('activity:EVENTS.TRACKER_ON')}
                </Typography>
            );
        case 'CMD-C':
            return (
                <Typography gray noMargin>
                    {t('activity:EVENTS.CALL_END')}
                </Typography>
            );
        case 'CMD-M':
            return (
                <Typography gray noMargin>
                    {t('activity:EVENTS.FREQ_CHANGE')}
                </Typography>
            );
        case 'CMD-R':
            return (
                <Typography gray noMargin>
                    {t('activity:EVENTS.RING')}
                </Typography>
            );
        case 'CMD-V3':
        case 'CMD-V30':
            return (
                <Typography gray noMargin>
                    {t('activity:EVENTS.CALL_REQ')}
                </Typography>
            );
        case 'CMD-W':
            return (
                <Typography gray noMargin>
                    {t('activity:EVENTS.VIBRATE')}
                </Typography>
            );
        default:
            return null;
    }
};

export const createActivityEvent = (
    event: ITrackerEvent,
    props?: HTMLAttributes<HTMLDivElement>,
) => {
    if (handledEventTypes.indexOf(event.type) < 0) {
        return;
    }

    if (event.type === 'ALM-A') {
        return (
            <ActivityItem
                data={event}
                eventContent={translationMapping(event)}
                shouldRenderPosition
                {...props}
            />
        );
    }
    return (
        <ActivityItem
            data={event}
            eventContent={translationMapping(event)}
            {...props}
        />
    );
};
