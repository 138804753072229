import { IOptionOffer } from 'app/subscription-offers/interfaces';
import { SubscriptionOptionType } from 'app/user-subscriptions/interfaces-api';
import { IRawUserData } from 'app/user/interfaces';
import { IAccountOptionOffers, IAccountOptions } from 'app/user/interfaces-api';
import {
    makeGetAccountOptions,
    makeGetAccountOptionsOffers,
} from 'app/user/selectors/account-option-selector';
import { putAccountOption } from 'app/user/user.service';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { config as appConfig } from '../../../../config';
import { colors } from '../../../../styles/colors';
import { Button } from '../../../common/button/button.component';
import { Headline } from '../../../common/headline/headline.component';
import { ReactComponent as LoadingGif } from '../../../icons/loading.svg';
import { INotification } from '../../../notifications/interfaces';
import { Dispatch, IRootState } from '../../../store/store';
import { createGetUserLanguage } from '../../../user/user-selectors';
import styles from './hipay-step.module.scss';
import {
    buildProcessPayment,
    IHipayTokenizeResponse,
    IProcessPayment,
} from './interfaces';

interface IStateProps {
    selectedAccountOptions: SubscriptionOptionType[];
    accountOptionOffers: IAccountOptionOffers[] | undefined;
    currentUserSite: string | undefined;
    canSubmit: boolean;
    isLoading: boolean;
    language: string;
}
interface IAction {
    fetchTracker: (trackerId: number) => Promise<any>;
    fetchSubscription: (subscriptionId: number) => Promise<any>;
    setCanSubmit: (isValid: boolean) => any;
    setIsLoading: (isLoading: boolean) => any;
    showNotification: (n: INotification) => unknown;
    refreshTrackerList: () => any;
}

interface IOuterProps {
    isFunnel?: boolean;
    hipayFormID: string;
    subscriptionId: number;
    trackerId: number | null;
    title: string;
    createSubscription?: () => any;
    onSuccess: (onSubmitResponse: any) => any;
    onFail: (customMessage?: string) => any;
    onSubmit: (paymentPayload: IProcessPayment) => Promise<any>;
}
interface IProps extends IStateProps, IAction, IOuterProps { }

/**
 * This component receives the Hipay hosted fields.
 * What is important is the ID of the main div {hipayFormID}
 * It uses the Hipay SDK lib inserted in index.html.
 * Doc about it here : https://support.hipay.com/hc/fr/articles/115005181065-HiPay-Enterprise-Int%C3%A9gration-Hosted-Fields
 */
export const HipayStepRaw = ({
    selectedAccountOptions,
    accountOptionOffers,
    currentUserSite,
    isFunnel,
    canSubmit,
    hipayFormID,
    isLoading,
    title,
}: IProps) => {
    /* Display a loading svg instead of the submit button
     * when a request is running
     */
    const { t } = useTranslation(['HipayPayments']);
    const maybeRenderSubmitButton = () => {
        if (isLoading) {
            return <LoadingGif className={styles.loading} />;
        }
        return (
            <Button
                id="hipay-submit-button"
                type="submit"
                disabled={!canSubmit}
                style={{ marginTop: '3rem' }}
                outlined
                curvy
                className={styles.submit}
            >
                {title}
            </Button>
        );
    };
    const maybeRenderFunnelFormVersion = () => {
        if (!isFunnel) {
            return (
                <div className={styles.container}>
                    <Headline center className={styles.headline}>
                        {title}
                    </Headline>
                    <form id="hipay-form" className={styles['hipay-form']}>
                        {/* The whole form will be inserted here */}
                        <div id={hipayFormID} />

                        {maybeRenderSubmitButton()}

                        <div id="hipay-error-message" />
                    </form>
                </div>
            );
        }
        return (
            <div className={styles['container-funnel']}>
                <form id="hipay-form">
                    <label className={styles['funnel-label']}>
                        {t('FULLNAME')}
                    </label>
                    <div
                        className={styles['hosted-field']}
                        id="hipay-card-holder"
                    />

                    <label className={styles['funnel-label']}>
                        {t('CARDNUMBER')}
                    </label>
                    <div
                        className={styles['hosted-field']}
                        id="hipay-card-number"
                    />
                    <div className={styles['expiry-cvc-container']}>
                        <div className={styles['expiry-container']}>
                            <label className={styles['funnel-label']}>
                                {t('EXPIRYDATE')}
                            </label>
                            <div
                                className={styles['hosted-field']}
                                id="hipay-expiry-date"
                            />
                        </div>
                        <div className={styles['cvc-container']}>
                            <label className={styles['funnel-label']}>
                                CVC
                            </label>
                            <div
                                className={styles['hosted-field']}
                                id="hipay-cvc"
                            />
                        </div>
                    </div>

                    <div id="hipay-error-message" />
                </form>
            </div>
        );
    };
    return <div>{maybeRenderFunnelFormVersion()}</div>;
};

const mapState = (state: IRootState, props: any): IStateProps => {
    const accountOptionTemplate = makeGetAccountOptionsOffers();
    const accountOption = makeGetAccountOptions();
    const getLanguage = createGetUserLanguage();

    return {
        selectedAccountOptions:
            state.subscriptionProcess.choices.selectedAccountOptions,
        accountOptionOffers: accountOptionTemplate(state),
        currentUserSite: state.user.userData?.site,
        canSubmit: state.hipayStep.canSubmit,
        isLoading: state.hipayStep.isLoading,
        language: getLanguage(state).toUpperCase(),
    };
};

const mapDispatch = (dispatch: Dispatch): IAction => ({
    fetchSubscription: async (subscriptionId: number) =>
        dispatch.userSubscriptions.fetchSubscriptionDetails(subscriptionId),
    fetchTracker: async (trackerId: number) =>
        dispatch.userTrackers.fetchSingleTracker({ trackerId }),
    setCanSubmit: (payload) => dispatch.hipayStep.setIsValid(payload),
    setIsLoading: (payload) => dispatch.hipayStep.setIsLoading(payload),
    showNotification: dispatch.notifications?.showNotification,
    refreshTrackerList: () =>
        dispatch.userTrackers.fetchTrackers({ deepFetch: true }),
});

export const HipayStep = compose<IProps, IOuterProps>(
    connect(mapState, mapDispatch),
    lifecycle<IProps, IOuterProps>({
        /* Use of componentDidMount as we want DOM to be fully loaded
           to insert Hipay form in the div element */
        componentDidMount() {
            const INFUNNEL = 'in funnel';
            const NOTINFUNNEL = 'not in funnel';
            const theme = document.documentElement.getAttribute('data-theme');
            let hipayUsername = appConfig.HIPAY_USERNAME;
            let hipayPassword = appConfig.HIPAY_PASSWORD;
            // We use a different Hipay account for American customers.
            if (this.props.currentUserSite === 'weenect_us') {
                hipayUsername = appConfig.HIPAY_USA_USERNAME;
                hipayPassword = appConfig.HIPAY_USA_PASSWORD;
            }
            // @ts-ignore Needed as Hipay lib has no type definition (index.d.ts file missing)
            const hipay = HiPay({
                username: hipayUsername,
                password: hipayPassword,
                environment: appConfig.HIPAY_ENVIRONMENT,
                lang: this.props.language.toLocaleLowerCase(),
            });
            const hipayOptionsType = {
                [INFUNNEL]: {
                    styles: {
                        base: {
                            // default field styling
                            color: colors.colorFlamingo,
                            iconColor:
                                colors.colorFlamingo,
                            caretColor: colors.colorFlamingo,
                        },
                        valid: {
                            color: colors.colorChristi,
                        },
                        invalid: {
                            color: colors.colorRedOrange,
                        },
                    },
                    multi_use: true,
                    fields: {
                        cardHolder: {
                            selector: 'hipay-card-holder', // card holder div id
                            uppercase: true,
                        },
                        cardNumber: {
                            selector: 'hipay-card-number', // card number div id
                        },
                        expiryDate: {
                            selector: 'hipay-expiry-date', // expiry date div id
                        },
                        cvc: {
                            selector: 'hipay-cvc', // cvc div id
                        },
                    },
                },
                [NOTINFUNNEL]: {
                    template: 'auto',
                    selector: this.props.hipayFormID,
                    styles: {
                        base: {
                            // default field styling
                            color: colors.colorFlamingo,
                            iconColor: colors.colorFlamingo,
                            caretColor: colors.colorFlamingo,
                        },
                        valid: {
                            color: colors.colorChristi,
                        },
                        invalid: {
                            color: colors.colorRedOrange,
                        },
                    },
                    /*
                           property 'multi_use' allows to use the same payment token for
                           Subscription AND Options
                        */
                    multi_use: true,
                    fields: {
                        cardHolder: {
                            uppercase: true,
                        },
                        cvc: {
                            helpButton: true,
                        },
                    },
                },
            };

            const getOptions = () => {
                if (!this.props.isFunnel) {
                    return hipayOptionsType[NOTINFUNNEL];
                }

                return hipayOptionsType[INFUNNEL];
            };

            const cardInstance = hipay.create('card', getOptions());

            /* Disable submit button depending on form validity */
            cardInstance.on('change', (response: any) => {
                if (!response.valid) {
                    this.props.setCanSubmit(false);
                } else {
                    this.props.setCanSubmit(true);
                }
            });
            const createSubscriptionIfNeeded = () => {
                if (this.props.isFunnel) {
                    return this.props.createSubscription!()
                        .catch((error: any) => {
                            if (error.status >= 400) {
                                return error.json();
                            }
                        })
                        .then((errorResponse: any) => {
                            if (errorResponse) {
                                this.props.setIsLoading(false);
                                this.props.onFail(errorResponse.message);
                                return false;
                            }
                            return true;
                        });
                }
                return true;
            };
            /* Get the form */
            const cardForm = document.getElementById('hipay-form');
            if (cardForm) {
                /* Add event listener on the submit button when clicked */
                cardForm.addEventListener('submit', async (event) => {
                    event.preventDefault();
                    this.props.setIsLoading(true);

                    if (
                        this.props.currentUserSite &&
                        this.props.selectedAccountOptions.includes(
                            SubscriptionOptionType.PREMIUMPACK,
                        )
                    ) {
                        putAccountOption({
                            account_options: [
                                SubscriptionOptionType.PREMIUMPACK,
                            ],
                            site: this.props.currentUserSite,
                        });
                    }

                    const isOk = await createSubscriptionIfNeeded();
                    if (!isOk) {
                        // Don't go further in API calls if we had an
                        // error during subscription creation.
                        return;
                    }

                    /* Tokenize the card information when the submit button is clicked */
                    cardInstance.getPaymentData().then(
                        (response: IHipayTokenizeResponse) => {
                            const paymentPayload = buildProcessPayment(
                                response,
                                this.props.language.toLocaleLowerCase(),
                                this.props.subscriptionId!,
                            );
                            /* Send token to the server to process payment and handle response */
                            this.props
                                .onSubmit(paymentPayload)
                                .then((resp: Response) => {
                                    // Fetch the updated subscription from API and update its state object,
                                    // then redirect to tracker route.
                                    const promises = Promise.all([
                                        this.props.fetchSubscription(
                                            this.props.subscriptionId!,
                                        ),
                                        this.props.fetchTracker(
                                            this.props.trackerId!,
                                        ),
                                    ]);
                                    promises.then(() => {
                                        this.props.setIsLoading(false);
                                        cardInstance.destroy(); // Necessary to remove hipay instance to clean HTML
                                        this.props
                                            .refreshTrackerList()
                                            .then(() => {
                                                this.props.onSuccess(resp); // Call the success method
                                            });
                                    });
                                })
                                .catch(() => {
                                    this.props.setIsLoading(false);
                                    this.props.onFail();
                                });
                        },
                        (errors: any) => {
                            /* Display first error */
                            document.getElementById(
                                'hipay-error-message',
                            )!.innerHTML = errors[0].error;
                        },
                    );
                });
            }
        },
    }),
)(HipayStepRaw);
