import { IRootState } from '../../store/store';

export const trackersStore = (state: IRootState) => state.userTrackers;

export const trackersFromApi = (state: IRootState) =>
    state.userTrackers.rawTrackers;

export const newPositions = (state: IRootState) =>
    state.userTrackers.newPositions;

export const activeTrackerId = (state: IRootState): number | null =>
    state.userTrackers.activeTracker;

export const getIsRefreshRunning = (state: IRootState): boolean =>
    state.userTrackers.watchedPositions.length > 0;
