import React from 'react';
import { useLocation } from 'react-router';

import { ConnectedLanguageChange } from './language-change/language-change.component';
import { ConnectedPasswordChange } from './password-change/password-change.component';
import { ConnectedUserPayments } from './user-payments/user-payments.component';
import { ConnectedUserProfile } from './user-profile/user-profile.component';
import { ConnectedUserPreferences } from './user-preferences/user-preferences.component';
import { getGlobalT } from '../../i18n/setup-translations';

const t = getGlobalT()
export const routesObj = [
    {
        path: '/account/profile', component: <ConnectedUserProfile />,
    },
    {
        path: '/account/preferences', component: <ConnectedUserPreferences />,
    },
    {
        path: '/account/language', component: <ConnectedLanguageChange />,
    },
    {
        path: '/account/payments', component: <ConnectedUserPayments />,
    },
    {
        path: '/account/password', component: <ConnectedPasswordChange />,
    },
];


export const AccountRoutes = () => {
    const param = useLocation()
    const children = routesObj.filter(f => f.path == param.pathname)
    return (
        <React.Fragment>
            {children[0].component}
        </React.Fragment>
    );
}
