import { ModelConfig } from '@rematch/core';
import { createSelector } from 'reselect';
import { IRootState } from '../store/store';

export interface ILayoutStore {
    mobileTrackersOpen: boolean;
    isMobileRedirect: boolean;
}

export const layoutStore: ModelConfig<ILayoutStore> = {
    state: {
        mobileTrackersOpen: false,
        isMobileRedirect: false,
    },
    reducers: {
        setMobileTrackers: (
            state: ILayoutStore,
            payload: boolean,
        ): ILayoutStore => ({ ...state, mobileTrackersOpen: payload }),
        setMobileRedirect: (
            state: ILayoutStore,
            payload: boolean,
        ): ILayoutStore => ({ ...state, isMobileRedirect: payload }),
    },
    effects: (dispatch: any) => ({
        async toggleMobileTrackersMenu(open: boolean) {
            this.setMobileTrackers(open);
        },
        async isMobileRedirect(isMobileRedirect: boolean) {
            this.setMobileRedirect(isMobileRedirect);
        },
    }),
};

const mobileMenuOpen = (state: IRootState): boolean =>
    state.layout.mobileTrackersOpen;

const mobileDisplayHeader = (state: IRootState): boolean =>
    state.layout.isMobileRedirect;

export const getMobileTrackersMenuOpen = createSelector(
    mobileMenuOpen,
    (open): boolean => open,
);

export const getMobileDisplayHeader = createSelector(
    mobileDisplayHeader,
    (isMobileRedirect): boolean => isMobileRedirect,
);
