import { mapRawOptionToView } from 'app/subscription-offers/mappers/map-offer-options';
import { createSelector } from 'reselect';
import { IRootState } from '../../store/store';
import { SubscriptionOptionType } from '../../user-subscriptions/interfaces-api';

export const getRawSubscriptionVariants = (store: IRootState) =>
    store.subscriptionOffers.variants;

export const getUserData = (store: IRootState) => store.user.userData;
export const getAccountOptionTemplate = (store: IRootState) =>
    store.user.userData?.account_options;

export const makeGetAccountOptionPremiumPack = () => {
    const getAccountOptions = makeGetAccountOptions();
    return createSelector(getAccountOptions, (accountOptions) => {
        return accountOptions?.find(
            (option) =>
                option &&
                option.code.includes(SubscriptionOptionType.PREMIUMPACK),
        );
    });
};

export const makeGetAccountOptions = () => {
    return createSelector(getUserData, (user) => {
        if (user && user.account_options && user.account_options.length) {
            return user.account_options;
        }
        return [];
    });
};
export const verifyIfUserHasPaymentIssue = () => {
    return createSelector(getUserData, (user) => {
        if (user && user.default_payment_mean) {
            if (user.default_payment_mean.has_card_expired || user.default_payment_mean.count_option_payment_error !== 0 && user?.default_payment_mean.count_option_payment_error !== undefined
                || user.default_payment_mean.count_subscription_payment_error !== 0 && user?.default_payment_mean.count_subscription_payment_error !== undefined
                || user.default_payment_mean.has_insufficient_funds) {
                return true
            }
            return false
        }
        return false
    });
};
export const makeGetAccountOptionsOffers = () => {
    return createSelector(getUserData, (user) => {
        if (user && user.account_option_offers) {
            return user.account_option_offers;
        }
    });
};

export const makeGetAccountOptionsOffersForView = () => {
    const getOffers = makeGetAccountOptionsOffers();
    return createSelector(getOffers, (accountOptionOffers) => {
        if (accountOptionOffers) {
            return accountOptionOffers.map(mapRawOptionToView);
        }
        return [];
    });
};

export const makeOptionOffersPremiumPack = () => {
    const getAccountOptionOffers = makeGetAccountOptionsOffers();
    return createSelector(getAccountOptionOffers, (accountOptionOffers) => {
        return accountOptionOffers?.find(
            (optionOffer) =>
                optionOffer &&
                optionOffer.code.includes(SubscriptionOptionType.PREMIUMPACK),
        );
    });
};
