import { get } from 'lodash';
import { ITracker } from '../interfaces-api';

/**
 * After registration of the tracker, there is some time required until it's fully active.
 * This can be fetched from API and mapped.
 */
export const trackerToIsActivating = (tracker: ITracker) => {
    const hasFirstConnected = Boolean(tracker.first_connection_date);
    const online = get(tracker, 'position[0].is_online', false);
    const hasSubscription = Boolean(tracker.subscription);
    const isSubscriptionActive =
        get(tracker, 'subscription.status') === 'active';

    return (
        !hasFirstConnected && !online && hasSubscription && isSubscriptionActive
    );
};
