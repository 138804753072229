import get from 'lodash/get';

import { ModelConfig } from '@rematch/core';

import { makeGetMapViewportCenter } from '../map/selectors';
import { makeGetSelectedTrackerModel } from '../trackers/selectors/get-selected-tracker-model';
import { IWifiZone } from './interfaces';
import { makeGetAllUserWifiZones } from './selectors/wifi-zones.selectors';
import {
    ITriggerDeepSleepDTO,
    triggerDeepSleep,
    updateWifiZone,
} from './services/wifi-zones.service';

export interface IWifiZoneEditStore {
    position: {
        lat: number;
        lng: number;
    };
    radius: number;
    id: number | null;
    duringNewCreating: boolean;
}

const defaultState: IWifiZoneEditStore = {
    position: {
        lat: 0,
        lng: 0,
    },
    radius: 100,
    duringNewCreating: false,
    id: null,
};

export const wifiZoneEditStore: ModelConfig<IWifiZoneEditStore> = {
    state: defaultState,
    effects: (dispatch: any) => ({
        async centerOnActiveTracker(payload, models) {
            const getActiveTracker = makeGetSelectedTrackerModel();

            const activeTracker = getActiveTracker(models);
            const lat = get(activeTracker, 'position[0].latitude');
            const lng = get(activeTracker, 'position[0].longitude');

            this.updatePosition({
                lat,
                lng,
            });
        },
        async centerOnMapViewport(payload, models) {
            const getMapCenter = makeGetMapViewportCenter();
            const center = getMapCenter(models);

            if (Array.isArray(center)) {
                this.updatePosition({
                    lat: center[0],
                    lng: center[1],
                });

                return;
            }

            this.updatePosition({
                lat: center.lat,
                lng: center.lng,
            });
        },
        async setWifiZoneDuringEdit(id: number | null, models) {
            this.resetState();
            if (id === null) {
                this.setEditableItd(null);
                return;
            }

            const getAllWifiZones = makeGetAllUserWifiZones();
            const allWifiZones = getAllWifiZones(models);
            const editableWifiZone = allWifiZones.find((g) => g.id === id)!;

            this.setEditableItd(id);
        },
        async onTriggerWifiEdit(payload: {
            trackerId: number;
            wifi: IWifiZone;
        }) {
            if (payload.wifi) {
                // edit with api call
                return updateWifiZone(payload.wifi, payload.wifi.id).then(
                    (newData) => {
                        newData.displayed = payload.wifi.displayed;
                        return dispatch.wifiZones._editWifiZone({
                            trackerId: payload.trackerId,
                            wifiZone: newData,
                        });
                    },
                );
            }
        },
        async onTriggerDeepSleep(payload: {
            trackerId: number;
            dto: ITriggerDeepSleepDTO;
        }) {
            triggerDeepSleep(payload.trackerId, payload.dto).then(
                (res: ITriggerDeepSleepDTO) => {
                    dispatch.userTrackers.updateTrackerWithDeepSleep({
                        trackerId: payload.trackerId,
                        deepSleep: res.enable_deep_sleep_wifi,
                    });
                },
            );
        },
    }),
    reducers: {
        updatePosition: (state, position: { lat: number; lng: number }) => ({
            ...state,
            position,
        }),
        setEditableItd: (state, id: number | null) => ({
            ...state,
            id,
        }),
        resetState: (state) => ({ ...defaultState }),
    },
};
