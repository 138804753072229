import { apiService } from '../../api/api.service';
import { ITracker } from '../../trackers/interfaces-api';

export const assignNewDevice = (
    trackerId: number,
    imei: string | number,
): Promise<ITracker> => {
    const resolveUrl = () => `/mytracker/${trackerId}/imei`;

    return apiService
        .fetch(resolveUrl(), {
            method: 'PUT',
            body: JSON.stringify({
                imei,
            }),
        })
        .then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                return resp.json();
            }
            throw resp.json();
        });
};
