export const FR = {
  AactivityOnboarding: {
    SUMMARY: {
      ACTIVITY_TEXT: 'Fréquence de sortie'
    }
  },
  HipayPayments: {
    CARDNUMBER: 'Numéro de carte',
    EXPIRYDATE: 'Date d\'expiration',
    FULLNAME: 'Nom complet',
    GOCARDLESS_LINK: 'Payer par prélèvement bancaire'
  },
  Slider: {
    GPS_INTERVAL: 'Intervalle GPS',
    GPS_POSITION_REPORT_ENABLED: 'Remontée des positions GPS activée',
    TRACKER_INTERVAL_TEXT_WARNING: 'En désactivant la remontée automatique des positions GPS vous augmentez la durée de vie de la batterie. Vous pouvez demander une nouvelle position manuellement, mais les zones de sécurité et l\'historique ne fonctionnent plus. '
  },
  TypeSwitch: {
    ANIMAL_SUBTITLE: 'Choisissez l’animal pour lequel vous avez acheté ce traceur :',
    CAT_TYPE: 'Traceur chat',
    DOG_TYPE: 'Traceur chien',
    FAMILY_SUBTITLE: 'Pour qui avez-vous acheté ce traceur ?',
    KID_TYPE: 'Un enfant',
    SILVER_TYPE: 'Un senior',
    TITLE: 'Pour qui est le traceur ?'
  },
  account: {
    LANGUAGE: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Un problème est survenu',
        UPDATE_SUCCESS: 'Langue mise à jour avec succès'
      }
    },
    NAV: {
      LANGUAGE: 'Langue',
      PASSWORD: 'Mot de passe',
      PAYMENTS: 'Paiement et facturation',
      USER_PREFERENCES: 'Préférences',
      USER_PROFILE: 'Profil utilisateur'
    },
    PASSWORD: {
      HEADLINE: 'Changement de mot de passe',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Un problème est survenu',
        UPDATE_ERROR_WRONG_PASSWORD: 'Vous avez renseigné le mauvais ancien mot de passe',
        UPDATE_SUCCESS: 'Mot de passe mis à jour avec succès'
      }
    },
    PAYMENTS: {
      HISTORY_HEADLINE: 'Historique des paiements',
      MAIN_HEADLINE: 'Facturation'
    },
    USER_PREFERENCES: {
      EMAIL_PREFERENCES_HEADLINE: 'Préférences des e-mails',
      EMAIL_PREFERENCES_USER_INFO: 'Les préférences seront appliquées à l’adresse e-mail {{user_email}}',
      MAIN_HEADLINE: 'Préférences',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Un problème est survenu lors de l\'enregistrement des préférences',
        UPDATE_SUCCESS: 'Préférences mises à jour avec succès'
      },
      METRIC_PREFERENCES_DESCRIPTION: 'Choisissez l’unité de distance utilisée pour votre traceur.',
      METRIC_PREFERENCES_HEADLINE: 'Préférence du système métrique :'
    },
    USER_PROFILE: {
      BASIC_INFO_HEADLINE: 'Informations de base',
      BILLING_HEADLINE: 'Adresse de facturation',
      MAIN_HEADLINE: 'Profil utilisateur',
      MARKETING_AGREEMENT_TEXT: 'J\'accepte de recevoir des offres et promotions des partenaires de Weenect',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Un problème est survenu',
        UPDATE_SUCCESS: 'Informations mises à jour avec succès'
      },
      METRIC_KM: 'Kilomètres',
      METRIC_MILES: 'Miles',
      PREFERRED_METRIC: 'Système métrique à utiliser :'
    }
  },
  activity: {
    AVERAGE_SPEED: 'Vitesse moyenne',
    CONTROLS: {
      PAUSE_PLAYING_TITLE: 'Suspendre le film de l\'historique',
      RESUME_PLAYING_TITLE: 'Relancer le film de l\'historique',
      START_PLAYING: 'Play',
      START_PLAYING_TITLE: 'Lancer le film de l\'historique',
      STOP_PLAYING: 'Stop',
      STOP_PLAYING_TITLE: 'Arrêter le film de l\'historique',
      TOGGLE_HEATMAP: 'Thermocarte',
      TOGGLE_HEATMAP_TITLE: 'Afficher la thermocarte',
      TOGGLE_PATH: 'Tracé des positions',
      TOGGLE_PATH_TITLE: 'Afficher le tracé des positions'
    },
    DETAIL_TITLE: 'Détail de l\'activité',
    EMPTY_LIST: 'Pas d\'alertes à afficher. Modifiez la période sélectionnée ou vérifiez que le traceur est bien allumé.',
    EVENTS: {
      ALERT: 'Bouton latéral actionné',
      BATTERY: 'Batterie faible - {{battery}}% restant',
      CALL_END: 'Appel SOS fini',
      CALL_REQ: 'Appel SOS demandé',
      ENTERS_GEOFENCE: 'Entrée dans la zone',
      EXIT_GEOFENCE: 'Sortie de la zone',
      FLASH_REQ: 'Flash demandé',
      FREQ_CHANGE: 'Intervalle de temps modifié',
      RING: 'Sonnerie effectuée',
      RING_REQ: 'Sonnerie demandée',
      SOS: 'Alerte SOS',
      TRACKER_OFF: 'Traceur éteint',
      TRACKER_ON: 'Traceur allumé',
      VIBRATE: 'Vibration effectuée',
      VIBRATE_REQ: 'Vibrations demandées'
    },
    FEEDBACKTEXT: {
      FIRST: '{{name}} a eu une activité plus forte {{currentPeriod}} par rapport a {{previousPeriod}}',
      SECOND: '{{name}} a eu une activité moins forte {{currentPeriod}} par rapport {{previousPeriod}}'
    },
    HISTORY_TITLE: 'Historique des notifications',
    LAST_TIME_PERIOD: 'Montrer les dernières',
    LOADING: 'Chargement...',
    MAXIMUM_SPEED: 'Vitesse maximale',
    OBJECTIVE: {
      CONTENT: 'Fixez un objectif d\'activité intense à atteindre chaque jour.',
      DAILY_REACHED: '{{name}} a atteint son objectif journalier !',
      HELPTEXT: 'Seule l\'activité intense est prise en compte dans la mesure de l\'objectif quotidien. Comme une course ou une session de jeux par exemple.',
      RECOMENDED_VALUE: 'Objectif recommandé : 1h',
      SUBTEXT: 'Notre algorithme enregistre chaque mouvement de votre traceur, puis classe les résultats par niveau d\'intensité. Nous déterminons ainsi s\'il s\'agit d\'une activité intense, d\'un moment d\'éveil ou d\'un temps de repos.',
      TITLE: 'Objectif quotidien'
    },
    ONBOARDING: {
      FIRST_SUBTEXT: 'Cet outil va vous permettre de connaître l’activité journalière de votre compagnon.',
      SECOND_SUBTEXT: 'Combien de temps a-t-il dormi ? Combien de pas a-t-il fait aujourd’hui ?',
      START: 'Commencer !',
      THIRD_SUBTEXT: 'Vous pourrez même vous fixer un objectif en fonction de la race de votre animal, afin que celui-ci ait l’activité journalière dont il a besoin.',
      TITLE: 'Bienvenue dans le suivi d’activité Weenect !'
    },
    PROGRESSBAR: {
      TITLE: 'Objectif quotidien'
    },
    SELECT_DAY: 'Choisir un jour à afficher',
    SELECT_TIME: 'Utilisez le curseur pour sélectionner la période à afficher',
    SUMMARY: {
      ACTIVITY: 'Intense',
      AWAKENING: 'Modéré',
      DATEINTERVAL: 'Du {{dayFrom}} au {{dayTo}} {{month}}',
      DAY_INDEX: 'j',
      DISTRIBUTIONTEXT: 'Rapport détaillé',
      HOUR_INDEX: 'h',
      LASTWEEK: 'Derniers 7 jours',
      MENU_TITLE: 'Rapport d’activité',
      MIN_INDEX: 'min',
      MODIFY_PERIOD: 'Changer la période',
      PREVIOUS_DAY: 'au jour précédent',
      PREVIOUS_MONTH: 'au mois précédent',
      PREVIOUS_WEEK: 'a la semaine précédente',
      REGISTERED_ACTIVITY: 'pas',
      REPORT: 'Voir le rapport ',
      REST: 'Faible',
      SUBTEXT: 'Encore {{time}} d\'activité intense avant d\'atteindre l\'objectif de {{name}}.',
      TEXT_OBJECTIVE_COMPLETED: '{{name}} a atteint son objectif journalier !',
      TEXT_OBJECTIVE_COMPLETED_TREAT: 'Félicitations, {{name}} a rempli son objectif ! Cela mérite peut-être une petite friandise ?',
      TEXT_OBJECTIVE_NONE: '{{name}} n’a pas encore de données pour aujourd’hui.',
      TEXT_OBJECTIVE_NONE_VERIFY_TRACKER: '{{name}} n’a pas encore enregistré de mouvements. Vérifiez que votre traceur est bien allumé et la batterie chargée !',
      TITTLE: 'Progression de {{name}}',
      TODAY: 'Aujourd’hui,',
      TOTAL: 'Total',
      YESTERDAY: 'Hier'
    },
    TIME_ELAPSED: 'Durée',
    TOTAL_DISTANCE: 'Distance totale'
  },
  activityGeneral: {
    BEHAVIOR: 'Activité ',
    STANDARD_MORPHOLOGY: 'Morphologie ',
    STERILIZED: 'Stérilisé'
  },
  activityOnboarding: {
    COMMON: {
      STEP: 'Etape',
      TRACKER_PROFIL: 'Profil du traceur\n'
    },
    MONTH: {
      APRIL: 'Avril',
      AUGUST: 'Août',
      DECEMBER: 'Décembre',
      FEBRUARY: 'Février',
      JANUARY: 'Janvier',
      JULY: 'Juillet',
      JUNE: 'Juin',
      MARCH: 'Mars',
      MAY: 'Mai',
      NOVEMBER: 'Novembre',
      OCTOBER: 'Octobre',
      SEPTEMBER: 'Septembre'
    },
    PET_ACTIVITY: {
      FIRST_BUTTON: 'Casanier (le strict minimum)',
      FIRST_BUTTON_CAT: 'Exceptionnellement (vacances, imprévus...)',
      FIRST_BUTTON_DOG: 'Pour ses besoins uniquement',
      FOURTH_BUTTON: 'Hyperactif (2 à 3 balades par jour)',
      FOURTH_BUTTON_CAT: 'Régulièrement, de jour comme de nuit ',
      FOURTH_BUTTON_DOG: '2 à 3 balades/ jour',
      SECOND_BUTTON: 'Normal (2 à 3 balades par semaine)',
      SECOND_BUTTON_CAT: 'De temps en temps, à sa demande ',
      SECOND_BUTTON_DOG: '2 à 3 balades/semaine',
      SELECT_ACTIVITY: 'A quelle fréquence sort-il ?',
      THIRD_BUTTON: 'Énergique (une balade par jour)',
      THIRD_BUTTON_CAT: 'Régulièrement, en journée\n',
      THIRD_BUTTON_DOG: 'Une balade par jour',
      TITLE: 'Quel est son profil sportif ?'
    },
    PET_AGE: {
      FIRST_COMMON_TEXT: 'an(s) et ',
      SECOND_COMMON_TEXT: 'mois',
      TITLE: 'Quelle est sa date de naissance ?'
    },
    PET_GENDER: {
      FEMALE: 'Une femelle',
      FIRST_TITLE: 'Quel est le genre de votre animal ?',
      MALE: 'Un mâle',
      SECOND_TITLE: 'Votre animal est-il stérilisé ?'
    },
    PET_HEALTH: {
      LAST_VACINATION: 'Et son dernier vaccin ?',
      LAST_VET_VISIT: 'De quand date sa dernière visite chez le vétérinaire ?  ',
      TITLE: 'Point santé'
    },
    PET_IDENTIFICATION: {
      CHIP: 'Pucé',
      NONE: 'Mon animal n’est pas identifié',
      SELECT_IDENTIFICATION: 'Comment est-il identifié ? ',
      TATTOO: 'Tatoué'
    },
    PET_INFO: {
      GET_STARTED: 'C’est parti !',
      NEED_TO_KNOW: 'Il est important pour nous d’apprendre à connaître  {{name}} .',
      PETS_DETAILS: 'Remplissez les informations de votre animal !',
      PETS_GENDER: {
        MIXED_BREED: 'C’est un croisé'
      },
      PET_GENDER_COMMON: 'Pour commencer, votre animal est : ',
      SECOND_PETS_DETAILS: 'Renseignez les informations de votre compagnon'
    },
    PET_MORPH: {
      AVERAGE: 'Normale',
      COMMON_TEXT: 'Environ',
      FIRST_TITLE: 'Quelle est sa morphologie ?',
      HELP_TEXT: 'Comment définir la morphologie de mon animal ?\n',
      LEAN: 'Maigre',
      OBESE: 'Obèse',
      OVERWEIGHT: 'En surpoids',
      SECOND_TITLE: 'Quel est son poids ?',
      WEIGH_TEXT: 'Combien pèse votre animal ?'
    },
    PET_NAME: {
      COMMON_TEXT: 'Faisons connaissance avec votre boule de poils ! Vous pourrez ensuite modifier ces informations dans les paramètres. ',
      PLACEHOLDER: 'Nom de votre animal',
      TITLE: 'Comment s’appelle-t-il ?'
    },
    PET_RACE: {
      COMMON_TEXT: 'Je ne connais pas sa race',
      PLACEHOLDER: 'Quelle est la race de votre chien? ',
      SELECT_BREED: 'Sélectionnez sa race',
      TITLE: 'Quelle est la race de votre animal? '
    },
    PET_TYPE: {
      CAT: 'Un chat',
      DOG: 'Un chien'
    },
    SUMMARY: {
      BIRTH_DATE: 'Date de naissance',
      BREED: 'Race',
      CONDITION: 'Etat',
      DAILY_EXERCISE: 'Dépense quotidienne',
      DELETE: 'Réinitialiser le profil',
      HEALTH: 'Santé',
      IDENTIFICATION: 'Identification',
      MODAL: {
        NO: 'Non, annuler',
        SUBTEXT: 'Vous devrez ensuite recommencer à 0 le processus.',
        TITLE: 'Êtes-vous sûr de vouloir réinitialiser les informations de votre animal ?',
        YES: 'Oui je suis sûr'
      },
      MORPHOLOGY: 'Morphologie',
      NAME: 'Nom',
      RECAPTEXT: 'Voici les informations renseignées sur votre compagnon.',
      SEX: 'Sexe',
      SPECIES: 'Espèce',
      SUBMIT: 'Valider le profil',
      UNSPECIFIED: 'non renseignées',
      VALIDATION: 'Validation du profil',
      VALIDATION_TEXT: 'Veuillez valider vos informations avant de continuer. ',
      WEIGHT: 'Poids'
    }
  },
  activityTracking: {
    ACTIVITY: 'Points d\'activité',
    DAILY_CHART: 'Diagramme d\'activité',
    DAYS: 'jour',
    HOURS: 'Heures',
    INTERVAL: {
      TEXT: 'Déplacez les bords du graphique pour ajuster l\'intervalle de temps que vous souhaitez analyser.',
      TITLE: 'Sélection de l\'intervalle'
    },
    MIN: 'minutes',
    PERIOD: {
      DAY: 'Jour',
      MONTH: 'Mois',
      TITLE: 'Sélection de la période',
      WEEK: 'Semaine',
      YEAR: 'Année'
    },
    TEXT: 'Ce graphique vous indique l\'évolution de l\'activité réalisée par votre animal. La mesure de cette activité se fait sur un intervalle partant de 0 et n\'ayant pas de limite maximum. Chaque point d\'activité représente une accélération, c\'est a dire un mouvement de votre animal.'
  },
  alertCustomer: {
    BODY0: 'Une maintenance est actuellement en cours chez notre fournisseur de carte SIM.',
    BODY1: 'Pendant cette maintenance <1>le délai d\'activation des cartes SIM est allongé à 24h</1>.',
    BODY2: 'Nous sommes bien conscients de la gêne que cela représente, nous vous prions de nous excuser pour ce problème indépendant de notre volonté.\n\nPour plus d\'informations, vous pouvez contacter notre équipe à l\'adresse mail <1>family@weenect.com</1> ou par téléphone au 01.87.66.00.83.',
    FOOTER: 'L\'équipe Weenect'
  },
  breed: {
    CAT: {
      ABYSSINIAN: 'Abyssin',
      AMERICAN_BOBTAIL: 'Americain Bobtail',
      AMERICAN_CURL: 'American Curl',
      AMERICAN_SHORTHAIR: 'American Shorthair',
      AMERICAN_WIREHAIR: 'Américain à poil dur',
      BALINESE: 'Balinais',
      BENGAL: 'Bengal',
      BIRMAN: 'Chat sacré de Birmanie',
      BOMBAY: 'Bombay',
      BURMESE: 'Burmese',
      BURMILLA: 'Burmilla',
      CALIFORNIA_SPANGLED_CAT: 'California Spangled Cat',
      CHANTILLYTIFFANY: 'Tiffany',
      CHARTREUX: 'Chartreux',
      CORNISH_REX: 'Cornish Rex',
      CYMRIC: 'Cymric',
      DEVON_REX: 'Devon Rex',
      EGYPTIAN_MAU: 'Mau égyptien',
      EUROPEAN: 'Européen',
      EXOTIC_SHORTHAIR: 'Exotic Shorthair',
      HAVANA: 'Havana',
      JAPANESE_BOBTAIL: 'Bobtail japonais',
      JAVANESE: 'Javanais',
      KORAT: 'Korat',
      MAINE_COON: 'Maine Coon',
      MALAYAN: 'Malayan',
      MANX: 'Manx',
      MUNCHKIN: 'Munchkin',
      NEBELUNG: 'Nebelung',
      NORWEGIAN: 'Norvégien',
      NORWEGIAN_FOREST_CAT: 'Chat des Forêts norvégiennes',
      OCICAT: 'Ocicat',
      ORIENTAL_LONGHAIR: 'Mandarin',
      ORIENTAL_SHORTHAIR: 'Oriental',
      PERSIAN: 'Persan',
      RAGDOLL: 'Ragdoll',
      RUSSIAN_BLUE: 'Bleu russe',
      SAFARI_CAT: 'Chat Safari',
      SCOTTISH_FOLD: 'Scottish Fold',
      SELKIRK: 'Selkirk',
      SIAMESE: 'Siamois',
      SIBERIAN: 'Sibérien',
      SINGAPURA: 'Singapura',
      SNOWSHOE: 'Snowshoe',
      SOMALI: 'Somali',
      SPHYNX: 'Sphynx',
      THAI: 'Thai',
      TONKINESE: 'Tonkinois',
      TURKISH_ANGORA: 'Angora turc',
      TURKISH_VAN: 'Turc Van'
    },
    DOG: {
      AFFENPINSCHER: 'Affenpinscher',
      AFGHAN_HOUND: 'Lévrier Afghan',
      AIREDALE_TERRIER: 'Airedale Terrier',
      AKITA: 'Akita Inu',
      AKITA_INU_GREAT_JAPANESE_DOG: 'Grand Chien Japonais',
      ALASKAN_MALAMUTE: 'Malamute de l\'Alaska',
      ALPINE_DACHSBRACKE: 'Basset des Alpes',
      AMERICAN_BULLDOG: 'Bouledogue Americain',
      AMERICAN_COCKER_SPANIEL: 'Cocker Américain',
      AMERICAN_FOXHOUND: 'Foxhound Américain',
      AMERICAN_STAFFORDSHIRE_TERRIER: 'American Staffordshire Terrier',
      AMERICAN_WATER_SPANIEL: 'Chien d\'eau Américain',
      ANATOLIAN_SHEPHERD_DOG: 'Berger d\'Anatolie',
      APPENZELLER_SENNENHUND: 'Bouvier d\'Appenzell',
      ARDENNES_CATTLE_DOG: 'Bouvier des Ardennes',
      ARGENTINE_DOGO: 'Dogue Argentin',
      ARIEGEOIS: 'Ariégeois',
      ARIEGE_POINTER: 'Braque de l\'Ariège',
      ARTOIS_HOUND: 'Chien d\'Artois',
      ATLAS_SHEPHERD_DOG_AIDI: 'Chien de l\'Atlas (Aïdi - Berger de l\'Atlas)',
      AUSTRALIAN_CATTLE_DOG: 'Bouvier Australien',
      AUSTRALIAN_KELPIE: 'Berger Australien Kelpie',
      AUSTRALIAN_SHEPHERD: 'Berger Australien d\'Amérique',
      AUSTRALIAN_TERRIER: 'Terrier Australien',
      AUSTRIAN_BLACK_AND_TAN_HOUND: 'Brachet Autrichien Noir et Feu',
      AUSTRIAN_SHORTHAIRED_PINSCHER: 'Pinscher Autrichien à poil court',
      AZAWAKH: 'Azawakh',
      BALKAN_HOUND: 'Chien Courant des Balkans',
      BARBET: 'Barbet',
      BASENJI: 'Basenji',
      BASSET_ARTESIEN_NORMAND: 'Basset Artésien Normand',
      BASSET_BLEU_DE_GASCOGNE: 'Basset Bleu de Gascogne',
      BASSET_FAUVE_DE_BRETAGNE: 'Basset Fauve de Bretagne',
      BASSET_HOUND: 'Basset Hound',
      BAVARIAN_MOUNTAIN_SCENTHOUND: 'Chien rouge de Bavière',
      BEAGLE: 'Beagle',
      BEARDED_COLLIE: 'Colley Barbu',
      BEAUCERON: 'Berger de Beauce',
      BEDLINGTON_TERRIER: 'Bedlington Terrier',
      BELGIAN_GRIFFON: 'Griffon Belge',
      BELGIAN_SHEPHERD_DOG_GROENENDAEL: 'Berger Belge Groenendael',
      BELGIAN_SHEPHERD_DOG_LAEKENOIS: 'Berger Belge Laekenois',
      BELGIAN_SHEPHERD_DOG_MALINOIS: 'Berger Belge Malinois',
      BELGIAN_SHEPHERD_DOG_TERVUREN: 'Berger Belge Tervueren',
      BERGAMASCO_SHEPHERD: 'Berger de Bergame',
      BERNER_NIEDERLAUFHUND: 'Petit Chien Courant Bernois',
      BERNESE_HOUND: 'Chien Courant Bernois',
      BERNESE_MOUNTAIN_DOG: 'Bouvier Bernois',
      BICHON_FRISE: 'Bichon à poil Frisé',
      BILLY_DOG: 'Billy',
      BLACK_AND_TAN_COONHOUND: 'Coonhound noir et feu',
      BLOODHOUND_SAINTHUBERT_DOG: 'Saint Hubert',
      BLUE_PICARDY_SPANIEL: 'Epagneul Bleu Picard',
      BOHEMIAN_WIREHAIRED_POINTING_GRIFFON: 'Griffon d\'arrêt Tchèque',
      BOLOGNESE_DOG: 'Bichon Bolonais',
      BORDEAUX_MASTIFF_FRENCH_MASTIFF: 'Dogue de Bordeaux',
      BORDER_COLLIE: 'Border Collie',
      BORDER_TERRIER: 'Border Terrier',
      BORZOI: 'Barzoï (Lévrier Russe)',
      BOSNIAN_COARSEHAIRED_HOUND: 'Chien Courant de Bosnie',
      BOSTON_TERRIER: 'Terrier de Boston',
      BOURBONNAIS_POINTER: 'Braque du Bourbonnais',
      BOUVIER_DES_FLANDRES: 'Bouvier des Flandres',
      BOXER_DOG: 'Boxer',
      BRAQUE_D_AUVERGNE: 'Braque d\'Auvergne',
      BRAZILIAN_TERRIER: 'Terrier Brésilien',
      BRIARD: 'Berger de Brie',
      BRIQUET_GRIFFON_VENDEEN: 'Briquet Griffon Vendéen',
      BRITTANY_SPANIEL: 'Epagneul Breton',
      BROHOLMER: 'Broholmer',
      BRUNO_JURA_HOUND: 'Petit Chien Courant du Jura',
      BRUNO_JURA_HOUND_BRUNO_TYPE: 'Bruno du Jura type Bruno',
      BRUNO_JURA_HOUND_ST_HUBERT_TYPE: 'Bruno du Jura type Saint Hubert',
      BRUSSELS_GRIFFON: 'Griffon Bruxellois',
      BULLMASTIFF: 'Bullmastiff',
      BULL_TERRIER_ENGLISH_BULL_TERRIER: 'Bull Terrier (English Bull Terrier)',
      BURGOS_POINTER: 'Braque de Burgos',
      CAIRN_TERRIER: 'Cairn Terrier',
      CANAAN_DOG: 'Chien de Canaan',
      CANARY_ISLAND_PODENCO: 'Podenco Canario',
      CANE_CORSO: 'Cane Corso',
      CAO_DE_CASTRO_LABOREIRO: 'Cao de Castro Laboreiro',
      CAO_FILA_DE_SAO_MIGUEL: 'Cao Fila de Sao Miguel',
      CATAHOULA_LEOPARD_DOG: 'Chien Léopard Catahoula',
      CATALAN_SHEEPDOG: 'Berger Catalan',
      CAUCASIAN_SHEPHERD_DOG: 'Berger du Caucase',
      CAVALIER_KING_CHARLES_SPANIEL: 'Cavalier King Charles',
      CA_DE_BOU_PERRO_DE_PRESA: 'Perro de Presa Mallorquin',
      CENTRAL_ASIAN_SHEPHERD_DOG: 'Berger d\'Asie Centrale',
      CESKY_TERRIER: 'Terrier Tchèque',
      CHESAPEAKE_BAY_RETRIEVER: 'Retriever de la baie de Chesapeake',
      CHIHUAHUA: 'Chihuahua',
      CHINESE_CRESTED_DOG: 'Chien Chinois à Crête',
      CHOW_CHOW: 'Chow Chow',
      CIRNECO_DELL_ETNA: 'Cirneco de l\'Etna',
      CLUMBER_SPANIEL: 'Clumber',
      CONTINENTAL_TOY_SPANIEL: 'Epagneul Nain Continental',
      CORSICAN_DOG: 'Chien Corse',
      COTON_DE_TULEAR: 'Coton de Tulear',
      CROATIAN_SHEEPDOG: 'Berger de Croatie',
      CURLYCOATED_RETRIEVER: 'Retriever à poil bouclé',
      CZECHOSLOVAKIAN_WOLFDOG: 'Chien Loup Tchèque',
      DACHSHUND_LONGHAIRED: 'Teckel à poil long',
      DACHSHUND_LONGHAIRED_RABBIT: 'Teckel de Chasse au lapin (poil long)',
      DACHSHUND_SMOOTHHAIRED: 'Teckel à poil ras',
      DACHSHUND_SMOOTHHAIRED_RABBIT: 'Teckel de Chasse au lapin (poils ras)',
      DACHSHUND_WIREHAIRED: 'Teckel à poil dur',
      DACHSHUND_WIREHAIRED_RABBIT: 'Teckel de Chasse au lapin (poil dur)',
      DALMATIAN: 'Dalmatien',
      DANDIE_DINMONT_TERRIER: 'Terrier Dandie Dinmont',
      DOBERMAN_PINSCHER: 'Dobermann',
      DOGO_CANARIO: 'Dogo Canario',
      DOGUE_DE_BORDEAUX: 'Lion d\'Occitanie',
      DRENTSE_PARTRIDGE_DOG: 'Epagneul à perdrix de Drente',
      DREVER: 'Drever',
      DUNKER: 'Dunker',
      DUPUY_POINTER: 'Braque Dupuy',
      DUTCH_SHEPHERD_LONGHAIRED_VARIETY: 'Berger Hollandais à poil long',
      DUTCH_SHEPHERD_ROUGHHAIRED_VARIETY: 'Berger Hollandais à poil dur',
      DUTCH_SHEPHERD_SHORTHAIRED_VARIETY: 'Berger Hollandais à poil court',
      DUTCH_SMOUSHOND: 'Petit Chien Hollandais de Chasse',
      EAST_SIBERIAN_LAIKA: 'Laika de Sibérie Orientale',
      ENGLISH_BULLDOG: 'Bouledogue Anglais',
      ENGLISH_COCKER_SPANIEL: 'Cocker Anglais',
      ENGLISH_POINTER: 'Pointer Anglais',
      ENGLISH_SETTER: 'Setter Anglais',
      ENGLISH_SPRINGER_SPANIEL: 'Springer Spaniel Anglais',
      ENGLISH_TOY_TERRIER: 'Toy Terrier noir et feu',
      ENTLEBUCHER_MOUNTAIN_DOG: 'Bouvier de l\'Entlebuch',
      EURASIER: 'Eurasier',
      FAWN_BRITTANY_BASSET: 'Griffon Fauve de Bretagne',
      FIELD_SPANIEL: 'Field Spaniel',
      FILA_BRASILEIRO: 'Fila Brasileiro',
      FINNISH_HOUND: 'Chien Courant Finnois',
      FINNISH_LAPPHUND: 'Chien Finnois de Laponie',
      FINNISH_SPITZ: 'Spitz Finlandais',
      FLATCOATED_RETRIEVER: 'Retriever à poil plat',
      FOXHOUND_ENGLISH_FOXHOUND: 'Foxhound (Foxhound Anglais)',
      FRENCH_BULLDOG: 'Bouledogue Français',
      FRENCH_SPANIEL: 'Epagneul Français',
      FRENCH_WHITE_AND_BLACK_HOUND: 'Français Blanc et Noir',
      FRENCH_WHITE_AND_ORANGE_HOUND: 'Français Blanc et Orange',
      FRISIAN_WATER_DOG: 'Chien d\'eau Frison',
      GASCONY_POINTER: 'Braque Français type Gascogne',
      GERMAN_LONGHAIRED_POINTER: 'Chien d\'arrêt Allemand à poil long',
      GERMAN_PINSCHER: 'Pinscher Moyen',
      GERMAN_SHEPHERD: 'Berger Allemand',
      GERMAN_SHORTHAIRED_POINTER: 'Braque Allemand à poil court',
      GERMAN_SPITZ: 'Spitz Allemand (Grand)',
      GERMAN_WIREHAIRED_POINTER: 'Braque Allemand à poil dur',
      GIANT_SCHNAUZER: 'Schnauzer géant',
      GLEN_OF_IMAAL_TERRIER: 'Terrier Irlandais Glen of Imaal',
      GOLDEN_RETRIEVER: 'Golden Retriever',
      GORDON_SETTER: 'Setter Gordon',
      GOTH_SHEPHERD_DOG: 'Chien des Goths',
      GRAND_ANGLOFRANCAIS_BLANC_ET_NOIR: 'Grand Anglo Français Blanc et Noir',
      GRAND_ANGLOFRANCAIS_BLANC_ET_ORANGE: 'Grand Anglo Français Blanc et Orange',
      GRAND_ANGLOFRANCAIS_TRICOLORE: 'Grand Anglo Français tricolore',
      GRAND_BASSET_GRIFFON_VENDEEN: 'Grand Basset Griffon Vendéen',
      GRAND_BLEU_DE_GASCOGNE: 'Grand Bleu de Gascogne',
      GRAND_GASCONSAINTONGEOIS_HOUND: 'Grand Gascon Saintongeois',
      GRAND_GRIFFON_VENDEEN: 'Grand Griffon Vendéen',
      GRAND_SPANISH_HOUND: 'Chien Courant Espagnol (grand)',
      GREATER_SWISS_MOUNTAIN_DOG: 'Grand Bouvier Suisse',
      GREAT_DANE: 'Dogue Allemand',
      GREAT_PYRENEES: 'Montagne des Pyrénées',
      GREENLAND_DOG: 'Esquimau du Groënland',
      GREYHOUND: 'Greyhound',
      HALDEN_HOUND: 'Chien Courant de Halden',
      HAMILTONSTOVARE: 'Hamilton Stovare',
      HANOVERIAN_SCENTHOUND: 'Chien rouge de Hanovre',
      HANOVER_HOUND: 'Brachet Allemand à poil court',
      HARRIER: 'Beagle Harrier',
      HAVANESE_DOG: 'Bichon Havanais',
      HELLENIC_HOUND: 'Chien Courant Grec',
      HOKKAIDO: 'Hokkaido',
      HOVAWART: 'Hovawart',
      HUNGARIAN_GREYHOUND: 'Lévrier Hongrois',
      HUNGARIAN_KUVASZ: 'Kuvasz (Berger Hongrois)',
      HYGEN_HOUND: 'Chien Courant de Hygen',
      IBIZAN_HOUND_SHORT_HAIRED: 'Podenco Ibicenco de pelo corto',
      IBIZAN_HOUND_WIRE_HAIRED: 'Podenco Ibicenco de pelo duro',
      ICELANDIC_SHEEPDOG: 'Berger d\'Islande',
      IRISH_RED_AND_WHITE_SETTER: 'Setter Irlandais rouge',
      IRISH_SETTER: 'Setter Irlandais rouge et blanc',
      IRISH_TERRIER: 'Terrier Irlandais',
      IRISH_WATER_SPANIEL: 'Chien d\'eau Irlandais',
      IRISH_WOLFHOUND: 'Lévrier Irlandais',
      ISTRIAN_COARSEHAIRED_HOUND: 'Chien Courant d\'Istrie à poil dur',
      ISTRIAN_SHORTHAIRED_HOUND: 'Chien Courant d\'Istrie à poil ras',
      ITALIAN_GREYHOUND: 'Petit Lévrier Italien',
      ITALIAN_POINTING_DOG: 'Braque Italien',
      ITALIAN_SHORTHAIRED_HOUND: 'Chien Courant Italien à poil court',
      ITALIAN_SPITZ: 'Spitz Italien',
      ITALIAN_WIREHAIRED_HOUND: 'Chien Courant Italien à poil dur',
      JACK_RUSSELL_TERRIER: 'Jack Russell Terrier',
      JAGDTERRIER: 'Jagd Terrier',
      JAPANESE_SPANIEL_CHIN: 'Epagneul Japonais',
      JAPANESE_SPITZ: 'Spitz Japonais',
      JAPANESE_TERRIER: 'Terrier japonais',
      KAI_KEN: 'Kai',
      KARELIAN_BEAR_DOG: 'Chien d\'Ours de Carélie',
      KARST_SHEPHERD: 'Berger de Karst',
      KERRY_BLUE_TERRIER: 'Kerry Blue Terrier',
      KISHU_KEN: 'Kishu',
      KOMONDOR: 'Komondor',
      KOREAN_JINDO_DOG: 'Korea Jindo Dog',
      KROMFOHRLANDER: 'Kromfohrlander',
      KYILEO: 'Kyi Leo',
      LABRADOR: 'Labrador',
      LAKELAND_TERRIER: 'Lakeland Terrier',
      LANDSEER: 'Landseer',
      LAPPONIAN_HERDER: 'Berger Finnois de Laponie',
      LARGE_MUNSTERLANDER: 'Grand Munsterlander',
      LEONBERGER: 'Leonberg (Leos)',
      LHASA_APSO: 'Lhassa Apso',
      LOWCHEN_LITTLE_LION_DOG: 'Petit Chien Lion',
      LUCERNE_HOUND: 'Chien Courant Lucernois',
      LUZERNER_LAUFHUND: 'Petit Chien Courant Lucernois',
      MAJORCA_SHEPHERD_DOG: 'Berger Espagnol de Majorque',
      MALTESE_DOG: 'Bichon Maltais',
      MANCHESTER_TERRIER: 'Terrier de Manchester',
      MAREMMA_SHEEPDOG: 'Berger de Maremme et Abruzzes',
      MASTIFF_FRENCH_MASTIFF: 'Mastiff inscrit LOF',
      MEDIUM_POODLE: 'Caniche (Moyen)',
      MEXICAN_HAIRLESS_DOG: 'Chien du Mexique à peau nue',
      MINIATURE_BULL_TERRIER: 'Bull Terrier Miniature',
      MINIATURE_DACHSHUND_LONGHAIRED: 'Teckel nain à poil long',
      MINIATURE_DACHSHUND_SMOOTHHAIRED: 'Teckel nain à poil ras',
      MINIATURE_DACHSHUND_WIREHAIRED: 'Teckel nain à poil dur',
      MINIATURE_PINSCHER: 'Pinscher Nain',
      MINIATURE_POODLE: 'Caniche (Nain)',
      MINIATURE_SCHNAUZER: 'Schnauzer nain',
      MIXED_BREED_CROSSBREED: 'Croisé',
      MUDI: 'Mudi (Berger Hongrois)',
      NEAPOLITAN_MASTIFF: 'Mâtin de Naples',
      NEWFOUNDLAND: 'Terre Neuve',
      NORFOLK_TERRIER: 'Norfolk Terrier',
      NORRBOTTENSPETS: 'Spitz de Norrbotten',
      NORWEGIAN_BUHUND: 'Buhund Norvégien',
      NORWEGIAN_ELKHOUND_BLACK: 'Chien d\'Elan Norvégien noir',
      NORWEGIAN_ELKHOUND_GRAY: 'Chien d\'Elan Norvégien gris',
      NORWEGIAN_LUNDEHUND: 'Chien Norvégien de Macareux',
      NORWICH_TERRIER: 'Norwich Terrier',
      NOVA_SCOTIA_DUCK_TOLLING_RETRIEVER: 'Retriever de la nouvelle Ecosse',
      OLD_DANISH_POINTER: 'Ancien Chien d\'arrêt Danois',
      OLD_ENGLISH_SHEEPDOG: 'Bobtail',
      OTTERHOUND: 'Chien de Loutre',
      PARSON_RUSSELL_TERRIER: 'Parson Jack Russell Terrier',
      PEKINGESE: 'Pékinois',
      PERUVIAN_HAIRLESS_DOG_LARGE: 'Chien du Pérou à peau nue (grand)',
      PERUVIAN_HAIRLESS_DOG_MEDIUM: 'Chien du Pérou à peau nue (moyen)',
      PERUVIAN_HAIRLESS_DOG_SMALL: 'Chien du Pérou à peau nue (petit)',
      PETIT_BASSET_GRIFFON_VENDEEN: 'Anglo Français de Petite Vénerie',
      PETIT_BRABANCON: 'Petit Brabancon',
      PETIT_GASCON_SAINTONGEOIS: 'Petit Gascon Saintongeois',
      PHARAOH_HOUND: 'Chien du Pharaon',
      PICARDY_SHEPHERD: 'Berger Picard',
      PICARDY_SPANIEL: 'Epagneul Picard',
      POITEVIN: 'Poitevin',
      POLISH_HOUND: 'Brachet Polonais',
      POLISH_LOWLAND_SHEEPDOG: 'Berger Polonais de Plaine',
      PONTAUDEMER_SPANIEL: 'Epagneul Pont Audemer',
      PORCELAINE: 'Porcelaine',
      PORTUGUESE_PODENGO_GRANDE: 'Podengo Portugais Grande',
      PORTUGUESE_PODENGO_MEDIO: 'Podengo Portugais Medio',
      PORTUGUESE_PODENGO_PEQUENO: 'Podengo Portugais Pequeno',
      PORTUGUESE_POINTER: 'Braque Portuguais',
      PORTUGUESE_SHEEPDOG: 'Berger Portugais',
      PORTUGUESE_SHEEPDOG_LONGHAIRED: 'Chien de montagne Portugais à poil ondulé',
      PORTUGUESE_SHEEPDOG_SHORTHAIRED: 'Chien de montagne Portugais à poil court',
      PORTUGUESE_WATER_DOG: 'Chien d\'eau Portugais',
      POSAVAC_HOUND: 'Chien Courant de Posavatz',
      PRAGUE_RATTER: 'Ratier praguois',
      PUDEL_POINTER: 'Pudel Pointer',
      PUG: 'Carlin',
      PULI_HUNGARIAN_SHEPHERD: 'Puli (Berger Hongrois)',
      PUMI_HUNGARIAN_SHEPHERD: 'Pumi (Berger Hongrois)',
      PYRENEAN_MOUNTAIN_DOG: 'Mâtin des Pyrénées',
      PYRENEAN_POINTER: 'Braque Français type Pyrénées',
      PYRENEAN_SHEEPDOG_LONGFACED_VARIETY: 'Berger des Pyrénées à poil long',
      PYRENEAN_SHEEPDOG_SMOOTHFACED_VARIETY: 'Berger des Pyrénées à face rase',
      RAFEIRO_DE_ALENTEJO: 'Rafeiro de Alentejo',
      REDBONE_COONHOUND: 'Redbone Coonhound',
      REDTICK_COONHOUND: 'Redtick Coonhound',
      RHODESIAN_RIDGEBACK: 'Rhodesian Ridgeback',
      ROMAGNA_WATER_DOG: 'Chien d\'eau Romagnol',
      ROTTWEILER: 'Rottweiler',
      ROUGH_COLLIE: 'Colley à poil long',
      RUSSIAN_BLACK_TERRIER: 'Terrier Noir Russe',
      RUSSOEUROPEAN_LAIKA: 'Laika Russo Européen',
      SAARLOOSWOLFHOND: 'Chien Loup de Saarloos',
      SAINTUSUGE_SPANIEL: 'Epagneul de Saint-Usuge',
      SAINT_BERNARD: 'Saint Bernard',
      SALUKI: 'Saluki',
      SAMOYED: 'Samoyède',
      SANSHU: 'Sanshu',
      SCHAPENDOES: 'Schapendoes',
      SCHILLERSTOVARE: 'Schiller Stovare',
      SCHIPPERKE: 'Schipperke',
      SCHWEIZER_LAUFHUND: 'Chien Courant de Schwyz',
      SCOTTISH_DEERHOUND: 'Lévrier Ecossais',
      SCOTTISH_TERRIER: 'Terrier Ecossais',
      SEALYHAM_TERRIER: 'Sealyham Terrier',
      SHAR_PEI: 'Shar Peï',
      SHETLAND_SHEEPDOG: 'Berger des Shetland',
      SHIBA_INU: 'Shiba (Shiba Inu)',
      SHIH_TZU: 'Shih Tzu',
      SHIKOKU: 'Shikoku',
      SHORTHAIRED_HUNGARIAN_POINTER: 'Braque Hongrois à poil court',
      SIBERIAN_HUSKY: 'Husky Sibérien',
      SILKY_TERRIER: 'Silky Terrier (Sydney Silky)',
      SKYE_TERRIER: 'Skye Terrier',
      SLOUGHI: 'Sloughi',
      SLOVAKIAN_WIREHAIRED_POINTER: 'Braque Slovaque à poil dur',
      SLOVAK_CUVAC: 'Berger Slovaque',
      SLOVAK_HOUND: 'Chien Courant Slovaque',
      SMALAND_HOUND: 'Smalandsstovare',
      SMALL_MUNSTERLANDER: 'Petit Munsterlander',
      SMALL_SPANISH_HOUND: 'Chien Courant Espagnol (petit)',
      SMOOTH_COLLIE: 'Colley à poil court',
      SMOOTH_FOX_TERRIER_ENGLISH_FOX_TERRIER: 'Fox Terrier à poil lisse (Fox Anglais)',
      SOFTCOATED_WHEATEN_TERRIER: 'Terrier Irlandais à poil doux',
      SOUTH_RUSSIAN_OVCHARKA: 'Berger de Russie Méridionale',
      SPANISH_GREYHOUND_GALGO_ESPANOL: 'Galgo Espagnol',
      SPANISH_MASTIFF: 'Mâtin Espagnol',
      SPANISH_WATER_DOG: 'Chien d\'eau Espagnol',
      SPINONE_ITALIANO: 'Spinone Italiano',
      STABYHOUN: 'Stabyhoun (Chien d\'arrêt Frison)',
      STAFFORDSHIRE_BULL_TERRIER: 'StaffordShire Bull Terrier LOF',
      STANDARD_POODLE: 'Caniche (Grand)',
      STANDARD_SCHNAUZER: 'Schnauzer moyen',
      STICHELHAAR: 'Stichelhaar',
      STYRIAN_COARSEHAIRED_HOUND: 'Brachet de Styrie à poil dur',
      ST_GERMAIN_POINTING_DOG: 'Braque Saint Germain',
      SUSSEX_SPANIEL: 'Sussex Spaniel',
      SWEDISH_ELKHOUND: 'Chien d\'Elan Suédois',
      SWEDISH_LAPPHUND: 'Chien Suédois de Laponie',
      TATRA_MOUNTAIN_SHEEPDOG: 'Berger Polonais de Podhale',
      TECKEL_DE_CHASSE_AU_LAPIN_POIL_DUR: 'Chien Courant de Transylvanie',
      TIBETAN_MASTIFF: 'Dogue du Tibet',
      TIBETAN_SPANIEL: 'Epagneul du Tibet',
      TIBETAN_TERRIER: 'Terrier du Tibet',
      TOSA: 'Tosa inscrit LOF',
      TOY_POODLE: 'Caniche (Toy) (Caniche miniature)',
      TRICOLOUR_HOUND: 'Français Tricolore',
      TYROLEAN_HOUND: 'Brachet Tyrolien',
      WACHTELHUND: 'Chien d\'Oysel (Wachtelhund)',
      WEIMARANER_LONGHAIRED: 'Braque de Weimar à poil long',
      WEIMARANER_SHORTHAIRED: 'Braque de Weimar à poil court',
      WELSH_CORGI_CARDIGAN: 'Welsh Corgi Cardigan',
      WELSH_CORGI_PEMBROKE: 'Welsh Corgi Pembroke',
      WELSH_SPRINGER_SPANIEL: 'Springer Spaniel Gallois',
      WELSH_TERRIER: 'Welsh Terrier',
      WESTPHALIAN_DACHSBRACKE: 'Basset de Westphalie',
      WEST_HIGHLAND_WHITE_TERRIER: 'West Highland White Terrier',
      WEST_SIBERIAN_LAIKA: 'Laika de Sibérie Occidentale',
      WHIPPET: 'Whippet',
      WHITE_SWISS_SHEPHERD_DOG: 'Berger Blanc Suisse',
      WIREHAIRED_HUNGARIAN_POINTER: 'Braque Hongrois à poil dur',
      WIREHAIRED_POINTING_GRIFFON: 'Griffon à poil Laineux',
      WIRE_FOX_TERRIER: 'Fox Terrier à poil dur',
      YORKSHIRE_TERRIER: 'Yorkshire Terrier',
      YUGOSLAVIAN_MOUNTAIN_HOUND: 'Chien Courant Yougoslave de Montagne',
      YUGOSLAVIAN_SHEPHERD_DOG: 'Berger Yougoslave',
      YUGOSLAVIAN_TRICOLOUR_HOUND: 'Chien Courant Yougoslave Tricolore'
    }
  },
  changePaymentMean: {
    ADD_BANK_ACCOUNT: 'Ajouter un compte à débiter',
    ADD_BANK_CARD: 'Passer au paiement par carte bancaire',
    BANK_ACCOUNT_ERROR: 'Une erreur est survenue lors du changement de vos informations bancaires. Veuillez contacter le service client.',
    BANK_ACCOUNT_EXPLANATION: 'Sachez qu\'aucune de vos informations bancaires ne sont enregistrées par nos serveurs.',
    BANK_ACCOUNT_SUCCESS: 'Informations bancaires mises à jour',
    BANK_CARD_ERRORED: 'Une erreur est survenue lors de la mise à jour de votre moyen de paiement. Veuillez contacter le <1>service client</1>.',
    BANK_CARD_UPDATED: 'Carte bancaire mise à jour',
    CAPTATION: 'Afin de mettre à jour vos coordonnées bancaires, nous allons procéder à une prise d\'autorisation de {{ amount }} sur votre carte.',
    CAPTATION_EXPLANATION1: 'Ce montant ne sera pas prélevé. La transaction est immédiatement annulée.',
    CAPTATION_EXPLANATION2: 'Cette procédure nous permet de respecter les standards de paiement sur Internet et de garantir la sécurité de vos coordonnées bancaires. Aucune de ces informations ne transitera par nos serveurs.',
    CAPTATION_EXPLANATION3: 'IMPORTANT : le formulaire de prélèvement portera le nom "Hareau" et non "Weenect". C\'est normal, il s\'agit du nom de notre entreprise. "Weenect" étant le nom commercial.',
    CAPTATION_EXPLANATION4: 'Changez le compte bancaire à prélever tout en gardant le même abonnement.',
    CARD_AUTHORIZED: 'Moyens de paiement autorisés :',
    CHANGE_PAYMENT_MEAN: 'Mettre à jour ma carte bancaire',
    CURRENT_CHOICE_CB: 'Vous avez choisi de payer par carte bancaire.',
    CURRENT_CHOICE_IBAN: 'Vous avez choisi de payer par IBAN',
    CURRENT_MEAN: 'Mode de paiement actuel',
    CURRENT_MEAN_PHRASE: 'Vous avez choisi de payer par carte bancaire',
    CURRENT_MEAN_PHRASE_GOCARDLESS: 'Vous avez choisi de payer par prélèvement bancaire ',
    DEFAULT_MEAN: 'Moyen de paiement par défaut :',
    GO_TO_SEPA: 'Passer au prélèvement bancaire',
    MODIFY: 'Modifier mon moyen de paiement',
    TITLE: 'Moyen de paiement',
    UPDATE_BANK_ACCOUNT: 'Mettre à jour mon IBAN',
    UPDATE_BANK_CARD: 'Mettre à jour ma carte bancaire',
    USED_PAYMENT_EXPLANATION: 'Ce mode de paiement est utilisé pour payer vos abonnements et vos options.'
  },
  commonActions: {
    ACTIVATE: 'Activer',
    BACK: 'Retour',
    CANCEL: 'Annuler',
    CAN_BE_CANCELED: 'L\'annulation est possible à tout moment.',
    CHANGE: 'Enregistrer',
    CONFIGURE: 'Configurer',
    DEACTIVATE: 'Désactiver',
    GO_MAP: 'Aller à la carte',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Un problème est survenu'
    },
    NEXT: 'Suivant',
    NO: 'Non',
    OK: 'Ok',
    REGISTER: 'Activer',
    SAVE: 'Enregistrer',
    SELECT_ALL: 'Tout sélectionner',
    SEND: 'Envoyer',
    SUBSCRIBE: 'Je souscris',
    THEN: 'puis ',
    UNSELECT_ALL: 'Tout désélectionner',
    UPDATE: 'Enregistrer',
    VALIDATE: 'Valider',
    YES: 'Oui'
  },
  creditCardAlert: {
    AUTO_RENEWAL_ADVICE: 'Si vous souhaitez continuer à utiliser votre traceur, veuillez ajouter un nouveau moyen de paiement dès maintenant.',
    AUTO_RENEWAL_NOTICE: 'Attention ! Votre abonnement arrive bientôt à échéance et votre carte bancaire a expiré.'
  },
  emailPreferences: {
    EMAIL_LISTS: {
      COMPANY_NEWS: 'Actualités de l\'entreprise',
      NEW_FEATURES: 'Nouvelles fonctionnalités',
      OFFERS: 'Offres promotionnelles',
      SERVICE_STATUS: 'Pannes de service et mises à jour',
      SURVEYS_AND_TESTS: 'Enquêtes et Beta tests',
      TUTORIALS: 'Tutoriels'
    },
    EMAIL_LISTS_HEADER: 'Liste des emails',
    EMAIL_LISTS_INFO: {
      SERVICE_STATUS: 'Nécessaire au bon fonctionnement du service.'
    },
    LANGUAGE_PREFERENCES_DESCRIPTION: 'Choisissez la langue dans laquelle vous souhaitez recevoir les e-mails :',
    PREFERENCES_DECRIPTION: 'Choisissez le type de mails que vous souhaitez recevoir. Nous vous enverrons uniquement ceux correspondant à vos choix. '
  },
  error: {
    TITLE: 'Oups un problème est survenu '
  },
  errors: {
    DEFAULT: 'Champ invalide',
    FIELD_REQUIRED: 'Ce champ est requis',
    GENERAL: 'Une erreur est survenue, veuillez réessayer',
    IMEI: 'Renseignez un numéro IMEI valide',
    MAIL_FORMAT: 'Ce format d\'email est invalide',
    MAX_LENGTH: 'Le champ peut contenir ${max} caractères maximum',
    PASSWORD_FORMAT: 'Votre mot de passe doit avoir au minimum un chiffre, une majuscule et une minuscule.',
    PHONE_FORMAT: 'Le numéro de téléphone doit inclure le préfixe international',
    PROMO_CODE: 'Code promo non valable'
  },
  forgotPassword: {
    FORGOT_PASSWORD_BUTTON: 'Recevoir un lien mot de passe oublié',
    FORGOT_PASSWORD_CANCEL: 'Retour à la connexion',
    FORGOT_PASSWORD_ERRORS: {
      UNKNOWN: 'Une erreur est survenue. Merci de contacter notre service d\'aide.'
    },
    FORGOT_PASSWORD_EXPIRED: 'Le lien de réinitialisation de mot de passe a expiré.',
    FORGOT_PASSWORD_RETRY: 'Demander un nouveau lien mot de passe oublié',
    FORGOT_PASSWORD_SUCCESS: 'S\'il y a un compte associé à l\'adresse {{mail}}, vous recevrez un email avec un lien pour réinitialiser votre mot de passe.'
  },
  forms: {
    CITY: {
      LABEL: 'Ville',
      PLACEHOLDER: 'Votre ville'
    },
    CONFIRM_PASSWORD: {
      LABEL: 'Confirmation mot de passe',
      PLACEHOLDER: 'Confirmer'
    },
    COUNTRY: {
      LABEL: 'Pays'
    },
    EMAIL: {
      LABEL: 'Adresse email',
      PLACEHOLDER: 'votre@email.com'
    },
    FIRST_NAME: {
      LABEL: 'Prénom',
      PLACEHOLDER: 'Votre prénom'
    },
    LANGUAGE: {
      LABEL: 'Langue'
    },
    LAST_NAME: {
      LABEL: 'Nom',
      PLACEHOLDER: 'Votre nom'
    },
    NEW_PASSWORD: {
      LABEL: 'Nouveau mot de passe',
      PLACEHOLDER: 'Votre nouveau mot de passe'
    },
    OLD_PASSWORD: {
      LABEL: 'Ancien mot de passe',
      PLACEHOLDER: 'Votre ancien mot de passe'
    },
    PASSWORD: {
      LABEL: 'Mot de passe',
      PLACEHOLDER: 'Mot de passe',
      VALIDATION: 'Votre mot de passe doit faire au moins 8 caractères.',
      WARNING: 'Attention, "azertyuiop" et "123456789" ne sont PAS des mots de passe forts.\nTestez la sécurité de votre mot de passe ici : <1>https://howsecureismypassword.net</1>'
    },
    PHONE: {
      LABEL: 'Téléphone',
      PLACEHOLDER: '+33600000000'
    },
    POSTAL_CODE: {
      LABEL: 'Code postal',
      PLACEHOLDER: 'Votre code postal',
      STREET_NUMBER: 'N° et voie'
    },
    STREET: {
      LABEL: 'Adresse',
      PLACEHOLDER: 'Numéro et nom de rue'
    }
  },
  geofences: {
    CREATE: {
      HEADLINE: 'Créer une zone'
    },
    EDIT: {
      DELETE_GEOFENCE: 'Supprimer la zone',
      ENTRANCE_NOTIFICATION: 'Notification d\'entrée',
      EXIT_NOTIFICATION: 'Notification de sortie',
      GEOCODE_LABEL: 'Utiliser une adresse',
      GEOCODE_PLACEHOLDER: 'Tapez l\'adresse ici',
      LOCATION: 'Position du centre de la zone',
      NAME_INPUT: {
        LABEL: 'Nom de la zone',
        PLACRHOLDER: 'Renseignez le nom de la zone'
      },
      OUTDOORS: 'Zone extérieure (parc, forêt)',
      OUTDOORS_TOOLTIP: 'Le GPS étant plus précis en extérieur, les règles pour le déclenchement d\'une notification d\'entrée ou de sortie sont différentes entre une zone intérieure et une zone extérieure.',
      POINT_ON_THE_MAP_TEXT: 'ou cliquez directement sur la carte',
      RADIUS: 'Taille de la zone (rayon en mètre)',
      TRACKER_LAST_POSITION_BUTTON: 'Utiliser le position du traceur',
      UNKNOWN_ADDRESS: 'Adresse inconnue'
    }
  },
  header: {
    ALL_TRACKERS: 'Tous les traceurs',
    AVAILABLE_PLURAL: 'disponible(s)',
    NAV: {
      ACCOUNT: 'Compte',
      INSURANCE: 'Assurance',
      LOGOUT: 'Déconnexion',
      SUPPORT: 'Assistance',
      TRACKERS: 'Carte'
    },
    SEARCHBAR_RESULT: 'traceurs trouvés',
    SEARCHBAR_TITLE: 'Rechercher un traceur :',
    TRACKERS: 'traceur(s)',
    WHITE_LABEL: {
      IN_PARTERNSHIP_WITH: 'En partenariat avec'
    }
  },
  imeiInfo: {
    HINT: 'Vous trouverez le numéro IMEI au dos de votre traceur.',
    IMAGE_ALT: 'Photo du traceur',
    TITLE: 'Numéro IMEI'
  },
  login: {
    FORGOT_PASSWORD: 'Mot de passe oublié ?',
    LOGIN_ERRORS: {
      UNKNOWN: 'La connexion a échoué'
    },
    SIGN_IN_BUTTON: 'Connexion',
    SIGN_IN_HEADLINE: 'Connexion',
    SIGN_UP_BUTTON: 'Créer un compte',
    SIGN_UP_HEADLINE: 'Vous n\'avez pas encore de compte ?',
    TERMS_LINK: 'Conditions d\'utilisation',
    TERMS_LINK_URL: 'https://www.weenect.com/common/pdf/fr/cgv-weenect.pdf'
  },
  manageSubscription: {
    CANCEL: {
      DESCRIBE_YOUR_PROBLEMS: 'Pouvez-vous préciser la raison de votre désabonnement ?',
      GIVE_US_DETAILS: 'Raison de votre désabonnement.',
      REASONS: {
        AFTER_SALES_SERVICE: 'Mauvaise expérience avec le Service client',
        BATTERY: 'Autonomie de la batterie insuffisante',
        LOST_BROKEN: 'Je l\'ai perdu / cassé',
        MOBILE_APPLICATION: 'L’application ne fonctionne pas',
        NOT_SATISFIED: 'Je ne suis pas satisfait de Weenect',
        NO_USE: 'Je n\'en ai plus l\'utilité',
        TOO_EXPENSIVE: 'Je trouve l\'abonnement trop cher',
        TRACKER_FORMAT: 'Format du traceur à revoir (taille et poids)',
        WILL_REACTIVATE_LATER: 'Je réactiverai l\'abonnement plus tard'
      },
      SAD_TO_SEE_YOU_LEAVE: 'Nous sommes tristes de vous voir partir.',
      WHY_DO_YOU_RESIGN: 'Pour quelle raison souhaitez-vous annuler votre abonnement ?'
    },
    CANCEL_EXPIRE: 'Le renouvellement automatique a été annulé. L\'abonnement expirera le {{date}}.',
    CANCEL_QUESTION: 'Vous ne souhaitez pas poursuivre l\'abonnement ?',
    CANCEL_RENEWAL: 'Annuler le renouvellement automatique',
    CANCEL_RENEWAL_BIS: 'Résilier l\'abonnement',
    CANT_RESIGN_UNDER_EXTENDED_NOTICE: 'Vous souhaitez vous désabonner ou simplement désactiver le renouvellement automatique de votre abonnement ?\nAppelez-nous au 01 87 66 98 71 de 9h30 à 16h du lundi au vendredi.',
    CANT_RESIGN_UNDER_NOTICE: 'Vous ne pouvez pas résilier votre abonnement pendant les 30 derniers jours. Veuillez contacter le service client.',
    CANT_RESIGN_UNTIL: 'La résiliation de votre abonnement prendra effet à la fin de votre engagement, soit le {{date}}.',
    CANT_RESIGN_WHILE_COMMITED: 'Vous êtes engagés sur 3 mois, la résiliation de votre abonnement ne prendra effet qu\'à la fin de la période en cours, soit le {{date}}',
    CURRENT_PLAN: 'Abonnement actuel',
    HEADLINE: 'Gérer l\'abonnement',
    MESSAGES: {
      UPDATE_SUCCESS: 'Abonnement mis à jour avec succès.'
    },
    RENEW_SUBSCRIPTION: 'Renouveler l\'abonnement',
    RESIGN_DATE: 'La résiliation de votre abonnement prendra effet à la fin de la période en cours, soit le {{date}}',
    RETURN_TO_TRACKERS: 'Retour à la carte'
  },
  map: {
    NO_GPS_MARKER_TITLE: 'GPS invalide',
    POSITION: 'Position'
  },
  mapControls: {
    MAP_TYPE: 'Type de carte',
    REFRESH_POSITION: 'Rafraîchir la position',
    SUPER_LIVE: 'Super live',
    ZOOM_IN: 'Zoom +',
    ZOOM_OUT: 'Zoom -'
  },
  mapLayers: {
    CLASSIC_MAPS: 'Cartes standard ',
    DONT_SUBSCRIBE: 'Continuer avec les cartes standard',
    FOREST_PATH: 'Chemins et sentiers',
    GOOGLE_STREET: 'Google Map',
    GRAY: 'Noir et blanc',
    PLAN: 'Plan',
    PREMIUM_MAPS: 'Cartes premium',
    PREMIUM_SUBSCRIBE: 'Souscrire à l’option Cartes Premium',
    PREMIUM_WARNING_MSG: '<1>Oups, vous n’avez pas souscrit à l’option cartes Premium pour le moment !</1>\n\nCette option vous permettra de bénéficier de cartes plus complètes et mises à jour régulièrement afin de mieux observer les déplacements de votre traceur.<br />Les versions Satellite HD, Carte des reliefs, ou encore Chemins de forêt vous permettront de repérer votre traceur en un coup d’oeil.\n\nSouscrivez à cette option pour seulement {{mapOptionPrice}} /mois !',
    RELIEF: 'Relief',
    SATELLITE: 'Satellite',
    STREET: 'Classique',
    UNLOCK_MAPS: 'Débloquez les cartes additionnelles en cliquant dessus !'
  },
  misc: {
    LANGUAGE: {
      DE: 'Allemand',
      EN: 'Anglais',
      ES: 'Espagnol',
      FR: 'Français',
      IT: 'Italien',
      NL: 'Néerlandais'
    },
    NO_DATE: 'Aucune position connue',
    ROTATE_DEVICE: 'Weenect a été conçu pour être utilisé en mode portrait. Veuillez pivoter votre appareil pour profiter au mieux de nos services.',
    locale: 'fr-FR'
  },
  onboarding: {
    ACTIVATING_HINT: 'Durée moyenne d\'activation de 5 minutes',
    ACTIVATING_INDICATOR: 'En cours d\'activation',
    GREET: {
      BUTTON: 'Mon traceur est allumé',
      COMMON_TEXT: 'Pour bien commencer nous vous recommandons de suivre le tutoriel suivant. Il vous aidera dans la prise en main de votre traceur.\n\nDébutons avec la mise en route de votre traceur. Suivez les indications ci-dessous afin de l\'allumer :',
      MAIN_TITLE: 'Informations de contact',
      RED_TEXT: 'Il faut que le traceur soit en extérieur pour qu\'il puisse obtenir une position GPS'
    },
    MAP_INTERACTIONS: {
      COMMON_TEXT: {
        PET: 'De haut en bas ces boutons vous permettent de faire sonner ou vibrer votre traceur. Ces fonctionnalités vous permettent d\'éduquer votre animal au rappel.',
        SILVER: 'De haut en bas ces boutons vous permettent de faire sonner ou vibrer votre traceur. '
      },
      TITLE: 'Les boutons d\'interaction avec le traceur'
    },
    MAP_INTERVAL: {
      COMMON_TEXT: 'Ce bouton vous permet de choisir à quelle fréquence vous souhaitez recevoir une nouvelle position GPS pour votre traceur. Attention, plus l\'intervalle est court et moins l\'autonomie du traceur sera élevée.',
      TITLE: 'L\'intervalle de temps entre chaque position GPS'
    },
    MAP_OPTIONS: {
      COMMON_TEXT: 'Ces boutons vous permettent de mofidier le type de carte que vous souhaitez afficher ainsi que de zoomer ou dézoomer.',
      TITLE: 'Les boutons de réglage de la carte'
    },
    MAP_UPDATE: {
      COMMON_TEXT: 'Ce bouton vous permet d\'actualiser la position de votre traceur (pour obtenir une nouvelle position votre traceur doit être connecté au serveur et avoir un signal GPS valide).',
      TITLE: 'Le bouton d’actualisation de la position'
    },
    MODAL: {
      BUTTON_HINT: 'Appuyez sur le bouton pendant 3 secondes jusqu\'à ce que le traceur vibre.',
      HEADLINE: 'Allumez votre traceur en extérieur ou sur le rebord d\'une fenêtre',
      IMAGE_ALT: 'Photo du traceur',
      MAP_HINT: 'Une fois allumé, le traceur s\'affichera sur la carte dans un délai de 5 minutes en moyenne.'
    },
    REMOTE_ASSISTANCE: {
      ASSISTANCE: {
        COMMON_TEXT: 'Si personne ne réagit dans ce délai l\'alerte est transmise à un opérateur'
      },
      EXPLANATION: 'L\'opérateur prend en charge l\'appel et répond au besoin du porteur du traceur. Selon la situation, il peut <1>contacter des proches ou appeler les secours</1>.',
      PRICE: 'L\'option est facturée à 9,99€ par mois et peut être désactivée à tout moment.',
      SOS: {
        COMMON_TEXT: 'Votre proche appuie sur le bouton SOS de son traceur.'
      },
      TIME: {
        COMMON_TEXT: 'Vous êtes prévenus et avez 30 secondes pour réagir à l\'alerte.'
      },
      TITLE: 'Découvrez notre nouvelle option : la téléassistance ',
      USE_CASE: 'Cette option permet de mettre le porteur du traceur en contact avec un plateau de téléassistance spécialisé, disponible 7j/7 et 24h/24.'
    },
    SIDE_PAGES: {
      COMMON_TEXT: {
        PET: 'Dans le volet de gauche, vous pouvez retrouver toutes les pages de gestion et d’utilisation de votre traceur. L’onglet Historique vous permet de consulter les déplacements de votre animal et l’onglet Suivi d’activité de connaître ses dépenses d’énergie. L’onglet Zones vous permet de configurer celles-ci, l’onglet Paramètres contient le profil de votre animal et l’onglet Abonnement vous permet de gérer votre abonnement ainsi que vos options très facilement.',
        SILVER: 'Dans le volet de gauche, vous pouvez retrouver toutes les pages de gestion et d’utilisation de votre traceur. L’onglet Historique vous permet de consulter les déplacements de votre proche et l’onglet Zones vous permet de configurer des périmètres de sécurité.\n L’onglet Paramètres vous permet de configurer le numéro à appeler en cas de SOS ainsi que les messages associés à chaque bouton latéral du traceur. Enfin, l\'onglet Abonnement vous permet de gérer votre abonnement ainsi que vos options très facilement.'
      },
      TITLE: 'Pages additionnelles'
    },
    SKIP: 'Passer le tutoriel',
    SUPERLIVE: {
      COMMON_TEXT: 'Ce bouton vous permet de déclencher le mode "superlive" : une nouvelle position toutes les 10 secondes pendant 5 minutes.',
      TITLE: 'Le bouton "superlive"'
    },
    TUTORIAL_START: {
      BUTTON: 'Poursuivre le tutoriel',
      COMMON_TEXT: 'Super, votre traceur est maintenant prêt à l\'emploi. Continuons avec un rapide tour d\'horizon de votre espace personnel My Weenect.'
    },
    WAITING_GPS_POSITION: 'Recherche d\'une position GPS',
    WAITING_GPS_POSITION_HINT: 'Allumez votre traceur en extérieur'
  },
  optionCardExpired: {
    PROPOSAL: 'Veuillez mettre à jour votre carte bancaire pour continuer de profiter de nos services.',
    UPDATE_CARD: 'Mettre à jour maintenant',
    WARNING: '<1>Attention !</1> Vous ne pouvez pas souscrire à cette option car <1>votre carte bancaire a expiré</1>.'
  },
  payment: {
    ADD_NEW_PAYMENT_REGULARISE: 'Veuillez enregistrer un nouveau moyen de paiement ou régulariser votre situation.',
    EXPIRACY_CREDIT_CARD_TEXT: 'Veuillez ajouter un nouveau moyen de paiement pour régulariser votre situation.',
    NO_PAYMENT_MEAN_YET: 'Aucun moyen de paiement pour l\'instant.',
    NO_PAYMENT_YET: 'Aucun paiement pour le moment.',
    UPDATE_PAYMENT_REGULARISE: 'Si vous souhaitez continuer à utiliser votre traceur, veuillez le mettre à jour ou en changer dès maintenant.',
    WARNING_ERROR: 'Il semble qu’il y ait un problème avec votre moyen de paiement. \n',
    WARNING_EXPIRACY_CREDIT_CARD: 'Attention, votre carte bancaire a expiré.',
    WARNING_NOT_ENOUGH_FOUNDS: 'Attention, il semble qu’il n’y ait pas les fonds suffisants sur votre compte.\n'
  },
  payments: {
    CARD: {
      ACCEPTED: '(CB, VISA, Mastercard, American Express)',
      DESCRIPTION: '30 jours satisfait ou remboursé',
      NAME: 'Carte de crédit'
    },
    STATUS: {
      CANCELED: 'Annulé',
      ERROR: 'En échec',
      NEW: 'En cours de traitement',
      PAYMENT_ERROR: 'Échec de paiement',
      PAYMENT_ERROR_EXPLAIN_TEXT: 'Il y a eu une erreur lors de votre paiement. Veuillez réessayer dans quelques instants ou contactez',
      PAYMENT_RETRY: ' Réessayer',
      PAYMENT_SAV: 'le service client .',
      PAYMENT_SUCCESS: 'Paiement réussi !',
      PAYMENT_SUCCESS_TEXT: 'Votre paiement a bien été pris en compte. Vous pouvez\nmaintenant commencer votre expérience Weenect !',
      PAYMENT_SUCCESS_TEXT_APP: 'Votre paiement a bien été pris en compte. Vous pouvez\nmaintenant retourner sur l\'application.',
      PENDING: 'En cours de traitement',
      REFUNDED: 'Remboursé',
      SUCCESS: 'Confirmé'
    },
    TRANSFER: {
      DESCRIPTION: '15 jours supplémentaires pour changer d\'avis, soit 30 jours satisfait ou remboursé',
      NAME: 'Prélèvement bancaire'
    }
  },
  periods: {
    ALT: {
      MONTH: '1 mois',
      YEAR: '1 an',
      YEAR_plural: '{{count}} ans'
    },
    BASE: {
      MONTH: 'mois',
      YEAR: 'an'
    }
  },
  registerSummaryTable: {
    BILLED_MONTH: 'Le paiement est mensuel',
    BILLED_NOW: 'Facturé immédiatement',
    BILLED_THREE_YEARS: 'Le paiement se fait une fois tous les trois ans',
    BILLED_TWO_YEARS: 'Facturé une fois tous les deux ans',
    BILLED_YEAR: 'Le paiement se fait une fois par an',
    HINT_OPTIONS_PRICE: 'Les options, d\'un montant de {{amount}}, seront facturées séparément de l\'abonnement d\'ici deux heures.',
    OPTION_UPGRADE: 'Ajouter l\'option {{name}} pour seulement {{price}} / {{period}}',
    PAY_HINT: 'Cliquez sur le bouton "Payer et activer" et payez {{subscriptionPrice}} pour l\'abonnement.',
    SUBSCRIPTION_UPGRADE: 'Changez pour un abonnement 2 ans et économisez {{amount}} chaque année.',
    TOTAL: 'Total TTC'
  },
  registerTracker: {
    BUY_LINK: 'Vous n\'avez pas encore de traceur Weenect ? <1>Vous pouvez en acheter un ici</1>',
    ERRORS: {
      UNKNOWN: 'Un problème est survenu lors de l\'activation de votre traceur, veuillez réessayer'
    },
    HEADLINE: 'Activer un nouveau traceur',
    IMEI_INPUT: {
      LABEL: 'Numéro IMEI',
      PLACEHOLDER: 'Vous trouverez le numéro IMEI au dos de votre traceur.'
    },
    NAME_INPUT: {
      LABEL: 'Nom du traceur',
      PLACEHOLDER: 'Nom du traceur'
    }
  },
  remoteAssistance: {
    BASE: 'Téléassistance 7j/7 24h/24',
    FEATURES: 'Après une pression du bouton SOS, sans réponse de votre part pendant 30 secondes, l’alerte SOS et l’appel vocal sont automatiquement transmis, 7j/7 et 24h/24, vers notre centrale de téléassistance. Nos agents sont habilités à contacter les secours comme la police ou le SAMU.',
    FORM: {
      ADDRESS_INPUT: {
        LABEL: 'Adresse',
        PLACEHOLDER: 'Nom et numéro de voie'
      },
      BACK: 'Annuler',
      CITY_INPUT: {
        LABEL: 'Ville',
        PLACEHOLDER: ''
      },
      EXTRA_PHONE: {
        LABEL: 'Téléphone secondaire (optionnel)',
        PLACEHOLDER: ''
      },
      HEADLINE: 'Inscription à la téléassistance',
      NAME_INPUT: {
        LABEL: 'Nom complet',
        PLACEHOLDER: 'Prénom NOM'
      },
      PHONE: {
        LABEL: 'Téléphone',
        PLACEHOLDER: ''
      },
      POSTAL_CODE_INPUT: {
        LABEL: 'Code postal',
        PLACEHOLDER: ''
      },
      SAVE: 'Confirmer',
      TERMS: 'J\'ai lu et j\'accepte les <1>conditions d\'utilisation</1> du service',
      TEXT: 'Renseignez les informations de votre proche (le porteur du traceur). Elles permettront à nos agents de lui venir en aide en cas d\'alerte SOS.'
    },
    PENDING: {
      WAITING_FOR_CALL_TEST: 'Formulaire d\'inscription validé. En attente de l\'appel de test.\nVous devez maintenant effectuer un appel de test afin de finaliser l\'activation de l\'option. Référez-vous à l\'email qui vous a été adressé pour connaître la procédure à suivre.',
      WAITING_FOR_RESIGN: 'Désactivation de l\'option en cours.\nVotre demande de désactivation de l\'option a bien été reçue. Nos agents s\'occupent actuellement des formalités administratives. La désactivation sera effective d\'ici peu.',
      WAITING_FOR_VALIDATION: 'Formulaire d\'inscription en cours de validation. \nLes informations que vous avez renseignées lors de votre souscription à l\'option sont actuellement vérifiées par nos agents. Dès que ces informations seront validées nous pourrons passer à la dernière étape d\'activation de l\'option : un appel de test devra être effectué afin de vérifier que tout fonctionne correctement.'
    },
    USER_PHONE: {
      TEXT: 'Renseignez vos informations. Elles permettront à nos agents de vous contacter en cas d\'alerte SOS.'
    }
  },
  signup: {
    ALREADY_MEMBER_TEXT: 'Déjà utilisateur ?',
    CREATE_ACCOUNT_HEADLINE: 'Créer un compte',
    LOGIN_TEXT: 'Me connecter',
    MARKETING_AGREEMENT_TEXT: 'J\'accepte de recevoir des offres et promotions des partenaires de Weenect',
    RETRY: 'Réessayer',
    SIGNUP_ERRORS: {
      EMAIL_ALREADY_EXISTS: 'Cette adresse mail est déjà associée à un compte',
      POPIN_EMAIL_ALREADY_EXIST: ' <1>Oups</1>, cette adresse email semble déjà être utilisée.',
      UNKNOWN: 'La connexion a échoué, réessayez'
    },
    SIGN_IN_TEXT: 'Connexion',
    SUBMIT_BUTTON: 'Créer mon compte',
    TERMS_AGREEMENT_TEXT: 'J\'accepte <1>les conditions d\'utilisation</1> du service'
  },
  simInfo: {
    TITLE: 'Numéro SIM'
  },
  subOptions: {
    CANCEL: {
      EXPIRATION_DATE: 'Cette option sera toujours active jusqu\'au {{date}}',
      HEADLINE: 'Souhaitez-vous annuler l\'option {{option}} ?'
    },
    MAP: {
      BASE: 'Pack multi-cartes',
      FEATURES: 'Bénéficiez des cartes les plus complètes et récentes : satellite HD et mis à jour très régulièrement, reliefs, chemins de forêt et sentiers.',
      TITLE: 'Cartes premium :'
    },
    PREMIUM: {
      BASE: 'Pack Premium',
      BUTTON: {
        FREEMONTH: 'Essayer gratuitement pendant 1 mois !'
      },
      DEACTIVATE: 'En désactivant cette option vous perdrez vos avantages premium.',
      FEATURE: {
        CHAT: {
          BASE: 'Chat en ligne',
          TEXT: 'Discutez en direct avec un conseiller Weenect, depuis votre application. Service disponible 7j/7, de 9h à 18h.',
          TEXT2: 'Profitez d\'avantages exclusifs (chat en ligne, alertes SMS, cartes HD) au sein de l\'application.'
        },
        SMS: {
          TEXT: 'Recevez toutes les alertes par SMS (crédit de 40 SMS par mois), en plus des notifications pushs et mails déjà incluses dans l\'abonnement.'
        }
      },
      FREEMONTH: {
        BASE: 'Premier mois offert !',
        BASE2: 'OFFERT'
      },
      SUBTITLE: 'Le Pack Premium comprend les features suivantes : ',
      SUB_LEGAL: 'Cette adhésion sera renouvelée automatiquement chaque mois, via le moyen de paiment choisi lors de la prise de votre abonnement. Vous pouvez annuler l’adhésion à tout moment via votre espace personnel.',
      TEXT_INFO_PRICE: 'Puis {{price}}/mois après le premier mois.',
      TITLE_DISCOVER: 'Découvrez vos avantages exclusifs : ',
      TITLE_POPUP: 'Devenez membre Premium !'
    },
    SMS_PACK: {
      ALT_FEATURES: 'Recevez toutes les alertes par SMS (20 SMS disponibles par mois).',
      BASE: 'Pack SMS',
      FEATURES: 'Recevez toutes les alertes par SMS (20 SMS disponibles par mois). Notez que sans cette option vous recevrez bien les alertes par pushs (application) et mails.'
    },
    UNDEFINED: {
      PAYMENTS: 'Paiement pour l\'option'
    },
    WARRANTY: {
      ALT_FEATURES: {
        one: 'Obtenez gratuitement un nouveau traceur en cas de casse, perte ou vol de votre traceur actuel.',
        other: 'Payez par carte de débit avec annulation possible à tout moment',
        two: 'Votre traceur remplacé en 48H en cas de perte, casse ou vol, sans conditions.'
      },
      BASE: 'Garantie multi-risques',
      EXPLAINER: 'La garantie standard couvre uniquement les problèmes de fabrication du traceur.',
      FEATURES: 'Obtenez gratuitement un traceur de remplacement en cas de casse, perte ou vol de votre traceur actuel ; aucune justification n’est demandée. La garantie standard couvre uniquement les problèmes de fabrication du traceur.'
    }
  },
  subscription: {
    COMMON_TEXT: {
      MONTH: '/ mois',
      OR: 'Soit'
    },
    FEATURES: {
      APP_ACCESS: 'Accès à l\'application et au service de localisation en illimité',
      REFOUND: '15 jours d\'essai : satisfait ou remboursé',
      SAV: 'Service client disponible 6/7 jours. \nRéponse garantie sous 24h.',
      WARRANTY: 'Garantie constructeur 24 mois'
    },
    INFO_CGU: 'Pour bénéficier des abonnements, vous devez saisir des informations bancaires valides et accepter',
    INFO_CGU_BUTTON: 'les conditions générales d’utilisation du service.',
    INFO_OPTION: 'Vous pouvez désélectionner une option en cliquant dessus.',
    MONEY_BACK: 'Tous les abonnements sont facturés immédiatement mais peuvent être annulés dans un délai de 15 jours, avec un remboursement intégral du montant payé. ',
    PAYMENT_INFO: 'Garantie satisfait ou remboursé : 15 jours pour essayer votre traceur',
    PAYMENT_SUBHEADLINE: 'Réglez votre abonnement pour activer votre traceur.',
    PAYMENT_TITLE: 'Choisissez votre moyen de paiement',
    PAYMENT_TITLE2: 'Enregistrez votre moyen de paiement',
    PRICE_MONTH_SAVED: 'soit  {{price}} / mois',
    SUMMARY: 'Récapitulatif'
  },
  subscriptionAlert: {
    ACTIVATE_AUTO_RENEWAL: 'Réactiver le renouvellement automatique',
    AUTO_RENEWAL_ADVICE: 'Nous vous conseillons de réactiver le renouvellement automatique si vous souhaitez prolonger votre abonnement',
    AUTO_RENEWAL_NOTICE: '<1>Attention !</1> Votre abonnement pour le traceur {{trackerName}} arrive à sa fin et le renouvellement automatique est désactivé.',
    CONTINUE_BUTTON: 'Continuer',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Un problème est survenu',
      UPDATE_SUCCESS: 'Mis à jour avec succès'
    },
    REMIND_LATER: 'Me rappeler plus tard',
    SUPPORT_TEXT: 'Si vous avez des questions ou que vous pensez que c\'est une erreur, contactez notre <1>service client</1>.',
    TRIAL_CASE: {
      SUBSCRIBE_BUTTON: 'Prendre un abonnement',
      TRIAL_IS_OVER: 'Votre période d\'essai est terminée',
      TRIAL_IS_OVER_ADVICE: 'Nous espérons que votre essai aura été concluant ! Pour continuer à utiliser le produit, il vous faut désormais prendre un abonnement.'
    }
  },
  subscriptionOptionsSelection: {
    CANCEL_WARNING_MODAL_HEADLINE: 'Êtes-vous sûr de vouloir désactiver cette option ?',
    CANCEL_WARNING_SMS: 'En désactivant cette option vous ne recevrez pas les notifications des alertes par SMS.',
    CANCEL_WARNING_WARRANTY: 'En désactivant cette option vous perdrez la protection supplémentaire apportée par la garantie multi-risques.',
    EXPLANATION: 'Le paiement des options est mensuel.',
    EXPLANATION2: 'Et l\'annulation est possible à tout moment.',
    EXPLANATION3: 'Aucun paiement ne sera effectué.',
    HINT: 'Vous pouvez activer ou désactiver une option à tout moment',
    MAIN_HEADLINE: 'Choisissez vos options',
    SUBMIT_BUTTON: 'Confirmer'
  },
  subscriptionPayment: {
    ERRORS: {
      UNKNOWN: 'Un problème est survenu lors du paiement, veuillez réessayer'
    },
    GIFT_CARD: {
      EXPLANATION: 'Entrez le code écrit au dos de la carte fournie dans votre pack traceur.',
      I_HAVE_ONE: 'J\'ai une carte cadeau',
      NEED_HELP: 'Besoin d\'aide au sujet des cartes cadeaux ?',
      TITLE: 'Utilisez votre cadeau',
      USE: 'Utiliser'
    },
    HEADLINE: 'Récapitulatif avant paiement',
    PROMOCODE_TEXT: 'J\'ai un code promo :',
    PROMO_CODE: 'Code promo',
    PROMO_CODE_GIFT_CARD: 'Carte cadeau',
    SATISFIED_OR_REFUNDED: 'Garantie satisfait ou remboursé',
    SATISFIED_OR_REFUNDED_DESCRIPTION: '30 jours pour l\'essayer sans risque, si vous n\'êtes pas satisfait nous vous remboursons.',
    SELECT_PAYMENT_MEAN: 'Sélectionnez une méthode de paiement avant de payer et activer votre abonnement.',
    SUBMIT_BUTTON: 'Régler l\'abonnement',
    SUBMIT_BUTTON2: 'Activer le traceur',
    SUMMARY: 'Rappel de vos choix'
  },
  subscriptionProcess: {
    GIFT_CARD_EXPLANATION: 'Renseignez votre méthode de paiement préférée pour activer gratuitement votre traceur.',
    GIFT_CARD_EXPLANATION2: 'Cette information est nécessaire pour des raisons légales liées à l\'utilisation des cartes SIM.',
    RETURN_TO_APP: 'Retourner sur l\'application ',
    RETURN_TO_MAP: 'Retourner sur la carte',
    RETURN_TO_TRACKERS: 'Retour à la carte',
    STEPS: [
      'Traceur',
      'Abonnement',
      'Options',
      'Paiement'
    ]
  },
  subscriptionSelection: {
    BEST_OFFER: 'MEILLEURE OFFRE',
    CANCELABLE_AFTER_THREE_MONTHS: 'Annulation possible à tout moment après les trois premiers mois',
    CANCEL_ANYTIME: 'Annulation possible à tout moment',
    COMMITMENT_TEXT: 'Résiliation possible à tout moment à l’issue de la période minimale d\'engagement de 3 mois.',
    FEATURES: '\n[ \'Accès à l\'application et au service de localisation en illimité \',\n  \'Service client disponible 6/7 jours.Réponse garantie sous 24h.\',\n  \'15 jours d\'essai : satisfait ou remboursé\',\n  \'Garantie constructeur 24 mois\'\n]',
    FEATURES_HEADLINE: 'Tous les abonnements incluent :',
    HEADLINE: 'Choisissez votre abonnement',
    MIN_THREE_MONTHS: 'Engagement minimum de trois mois',
    MONEY_BACK: 'Tous les abonnements sont facturés immédiatement mais peuvent être annulés dans un délai de 30 jours, avec remboursement intégral du montant payé.',
    PAYMENT_MEANS: 'Paiement par prélèvement bancaire ou carte de crédit',
    REFUNDED_INFO: 'Nous vous remboursons l\'abonnement sans condition.',
    REFUNDED_TEXT: 'Satisfait ou remboursé 30 jours.',
    SIM_CARD_WARNING: 'Une carte SIM est directement intégrée dans votre traceur. Ceci explique qu’il soit nécessaire de souscrire un abonnement (afin de couvrir les frais de service).',
    SUBMIT_BUTTON: 'Confirmer',
    XMAS_OFFER: {
      BODY: 'Néanmoins nous sommes dans l\'obligation de vous faire choisir votre futur abonnement dès maintenant (par mesure de sécurité, le produit étant équipé d\'une carte SIM). Vous ne serez pas prélevé avant la fin des 6 mois gratuits et pourrez annuler cet abonnement à tout moment jusqu\'à un mois avant sa date de début effectif.',
      TITLE: 'Votre traceur est pré-chargé avec 6 mois d\'abonnement offerts par votre proche'
    },
    features: [
      '30 jours satisfait ou remboursé',
      'Localisation GPS illimitée',
      'Notifications par pushs (application) et mails',
      '10 minutes d\'appel vocal par mois'
    ]
  },
  subscriptions: {
    BASE: 'Abonnement',
    CANCEL_MODAL: {
      HEADLINE: 'Voulez-vous annuler le renouvellement automatique de votre abonnement ?',
      HINT: 'Votre abonnement sera toujours actif jusqu\'au {{date}} mais il ne sera pas renouvelé automatiquement. Après cette date vous ne serez plus en mesure de localiser votre traceur.'
    },
    CHANGE_PLAN: {
      ADD_OPTIONS: 'Ajouter une option',
      EXPLANATION: 'Le changement d\'abonnement sera effectif à la fin de la période en cours.',
      HEADLINE: 'Changer d\'abonnement ?'
    },
    DETAILS: {
      ACTIVATE_BUTTON: {
        LABEL: 'Activer l\'abonnement'
      },
      ACTIVE_CASE: {
        MANAGE_BUTTON: 'Gérer l\'abonnement',
        RENEWAL_INFO: 'Votre abonnement sera renouvelé automatiquement le {{date}} au prix de {{price}}'
      },
      CANCELED: {
        EXPIRED_MAIN: 'Votre abonnement a expiré. Réactivez l\'abonnement pour pouvoir localiser votre traceur.',
        EXPIRED_SEC: 'Expiré',
        UNCANCEL_BUTTON_LABEL: 'Réactiver le renouvellement automatique de l\'abonnement',
        WILL_EXPIRE_DAYS: 'Expire dans {{count}} jour',
        WILL_EXPIRE_DAYS_plural: 'Expire dans {{count}} jours',
        WILL_EXPIRE_MAIN: 'Le renouvellement automatique de l\'abonnement a été annulé. Il expirera le {{date}}.'
      },
      CANCEL_RESILLIATION: 'Annuler la demande de résiliation',
      NEW_CASE: {
        INFO_TEXT: 'Nous rencontrons un problème avec votre paiement. Veuillez réessayer ou changer de moyen de paiement.',
        PAY_BUTTON: 'Payer et activer'
      },
      NO_SUB: {
        INFO_TEXT: 'Activez l\'abonnement pour commencer à utiliser votre traceur.'
      },
      RESILLIATION_ASKED: 'Résiliation demandée',
      SUBSCRIBE_BUTTON: {
        LABEL: 'Prendre un abonnement'
      },
      SUPPORT_TEXT: 'Vous rencontrez des problèmes dans l\'activation de votre traceur ? Contactez l\'<1>assistance</1>.',
      TRIAL_PERIOD_CASE: {
        END_INFO: 'Votre période d\'essai prendra fin le {{ date }}'
      }
    },
    OFFERS: {
      SAVING_PER_YEAR: 'économisez {{amount}} / an'
    },
    OFFERS_TEXT: {
      MOST_POPULAR: 'La plus populaire',
      ONE_MONTH: {
        INFO: 'Engagement minimum de 3 mois',
        TITLE: 'Formule mensuelle'
      },
      ONE_YEAR: {
        INFO: 'Période d\'essai allongée de 15 jours : 30 jours au total',
        TITLE: 'Formule 1 an'
      },
      THREE_YEAR: {
        INFO: '1 an de garantie constructeur en plus : 3 ans au total \n',
        INFO2: 'Garantie constructeur de 3 ans (au lieu de deux)',
        TITLE: 'Formule 3 ans'
      }
    },
    OVERVIEW: {
      OPTIONS: 'Détails des options',
      REG_DETAILS: 'Détails du traceur',
      REPLACE_TRACKER_BUTTON: {
        LABEL: 'Remplacer le traceur'
      },
      SUB_DETAILS: 'Détails de l\'abonnement'
    },
    PLAN_NAMES: {
      FALLBACK: 'Abonnement',
      ONE_MONTH: 'Abonnement mensuel',
      ONE_YEAR: 'Abonnement prépayé 1 an',
      THREE_YEARS: 'Abonnement prépayé 3 ans',
      TRIAL_PERIOD: 'Période d\'essaie',
      TWO_YEARS: 'Abonnement 2 ans'
    },
    STATUS: {
      ACTIVE: 'Souscrit',
      CANCELED: 'Annulé',
      INACTIVE: 'Non-souscrit',
      NEW: 'Actif',
      PENDING: 'En attente'
    },
    TRACKER_DETAILS: {
      REGISTRATION_DATE: 'Date d\'activation',
      WARRANTY: 'Garantie standard'
    }
  },
  superLive: {
    ACTIVE_TEXT: 'Le mode superlive est activé',
    HEADLINE: 'Mode Super live',
    MESSAGES: {
      SUCCESS_ACTIVATED: 'Mode Super live activé avec succès - Une position toutes les {{frequency}} secondes pendant 5 minutes'
    },
    TIME: 'Une position toutes les {{frequency}} secondes'
  },
  support: {
    FORM: {
      MESSAGE: {
        LABEL: 'Votre message*',
        PLACEHOLDER: ''
      },
      PROBLEM: {
        AVAILABLE_OPTIONS: 'Options disponibles',
        LABEL: 'Type de problème rencontré*'
      },
      SUBJECT: {
        LABEL: 'Sujet de votre demande*',
        PLACEHOLDER: ''
      },
      TRACKER: {
        LABEL: 'Traceur concerné par votre demande',
        PLACEHOLDER: 'Sélectionnez le traceur'
      }
    },
    HEADLINE: 'Assistance',
    HELP_CENTER: 'Centre d’aide',
    HELP_TEXT: 'Besoin d\'aide ? Rendez-vous dans notre centre d\'aide pour trouver la réponse à votre question. Vous pouvez aussi nous contacter en utilisant le formulaire ci-dessous ; notre équipe vous répondra dans un délai de 24 heures ouvrées.',
    MAIN_TEXT: 'Notre centre d\'aide regroupe tous les articles pouvant apporter une réponse à vos questions.',
    MESSAGES: {
      SUBMIT_ERROR: 'Une erreur est survenue, veuillez réessayer',
      SUBMIT_SUCCESS: 'Message envoyé avec succès'
    },
    PROBLEM_TYPES: {
      CHARGE: 'Mon traceur ne se charge pas normalement',
      DOWNLOAD_APP: 'Je ne peux pas télécharger l\'application',
      GPS_INVALID: 'Mon signal GPS est indiqué invalide',
      GPS_PRECISION: 'La position GPS de mon traceur est incorrecte',
      LEDS_FIXED: 'Les deux leds de mon traceur restent fixes',
      NO_GEOFENCE_ALERTS: 'Je reçois de fausses alertes d\'entrée ou de sortie de zone',
      NO_NOTIFICATIONS: 'Je ne reçois pas les notifications',
      OTHER: 'Autre',
      SIGN_IN: 'Je n\'arrive pas à me connecter à mon espace personnel',
      SUBSCRIPTION: 'J\'ai un problème avec mon abonnement',
      TRACKERS_PARAMS: 'Je ne peux pas paramétrer mon traceur',
      TRACKER_NOT_CONNECTED: 'Mon traceur est indiqué non-connecté'
    },
    SUBMITTED_TICKET: 'Nous avons bien reçu votre demande, merci.\nUn email de confirmation vous a été envoyé (pensez à vérifier vos courriers indésirables).',
    SUB_TEXT: 'Si toutefois ces explications ne suffisaient pas, vous pouvez également contacter un membre de notre équipe SAV, qui vous répondra dans un délai de 24 heures ouvrées.',
    TITLE: 'Besoin d\'aide ?',
    URGENT_NUMBER: 'Si votre demande est urgente, vous pouvez contacter notre service client au \n+33 187 660 083 de 10h à 12h et de 14h à 17h du Lundi au Vendredi.'
  },
  trackerLeftPanel: {
    CALL: 'Appeler',
    CTR_ONLY_SECONDS: 'Il vous reste {{SECONDS}} seconde(s) d\'appel téléphonique pour le mois en cours.',
    CTR_WITH_MINUTES: 'Il vous reste {{MINUTES}} minute(s) et {{SECONDS}} seconde(s) d\'appel téléphonique pour le mois en cours.',
    FLASH: 'Lampe torche',
    GOOD_GPS_ACCURACY: 'Bonne précision GPS',
    LAST_POSITION: 'Dernière position connue :',
    MEDIUM_GPS_ACCURACY: 'Position GPS moyenne',
    OFFLINE: 'Traceur déconnecté',
    POOR_GPS_ACCURACY: 'Mauvaise précision GPS',
    RING: 'Sonnerie',
    SMS_AVAILABLE: 'SMS disponibles : {{SMS_count}}',
    VIBRATE: 'Vibreur'
  },
  trackerSettings: {
    BUTTONS: {
      LABELS: {
        BUTTON: 'Bouton'
      },
      PLACEHOLDER_BUTTON: 'Saisissez le message envoyé par le bouton {{button_number}}',
      TEXT: 'Configurez le message assigné à chaque bouton de votre traceur. Vous recevrez ce message si le bouton est pressé.'
    },
    CONTACTS: {
      ADD_EMAIL_BUTTON: 'Ajouter une adresse email',
      ADD_PHONE_BUTTON: 'Ajouter un numéro de téléphone',
      EMAILS_LABEL: 'Adresses email pour les notifications par email',
      MAIL: {
        PLACEHOLDER: 'votre@email.com'
      },
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Un problème est survenu, veuillez réessayer.',
        UPDATE_SUCCESS: 'Contacts mis à jour avec succès'
      },
      NUMBERS_LABEL: 'Numéros de téléphone pour les notifications par SMS',
      PHONE: {
        PLACEHOLDER: '+33600000000'
      }
    },
    DISPLAY: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Un problème est survenu',
        UPDATE_SUCCESS: 'Paramètres mis à jour avec succès'
      },
      MODAL: {
        HEADLINE: 'Sélectionnez l\'icône',
        TEXT: 'Vous pouvez sélectionner l\'icône que vous souhaitez dans la liste ci-dessous. Elle représentera votre traceur sur la carte.'
      },
      TRACKER_ICON: {
        EDIT_TEXT: 'Changer l\'icône',
        LABEL: 'Icône du traceur'
      },
      TRACKER_NAME: {
        LABEL: 'Nom du traceur',
        PLACEHOLDER: 'Nom qui s\'affichera sur la carte'
      },
      TRACKER_TYPE: {
        CHANGE: 'Changer le type',
        MODIFIED_SUCCESS: 'Type de traceur modifié avec succès'
      }
    },
    GPS_FREQUENCY: {
      BATTERY_USAGE: 'Autonomie moyenne de la batterie :',
      BATTERY_USAGE_LEVELS: {
        HIGH: 'Élevée',
        LOW: 'Faible',
        MEDIUM: 'Moyenne'
      },
      GEOFENCE_NOTIFICATIONS: 'Délai moyen de réactivité des alertes de zone :',
      HINT: 'Une fréquence plus rapide réduit l\'autonomie de la batterie mais améliore la réactivité des alertes d\'entrée et de sortie de zone. A l\'inverse, une fréquence plus lente améliore l\'autonomie de la batterie mais réduit la réactivité des alertes d\'entrée et de sortie de zone.',
      TEXT: 'Choisissez l\'intervalle de temps entre chaque position GPS'
    },
    NOTIFICATION_SETTINGS: {
      APP: 'Application',
      BATTERY: {
        HINT: 'Envoie une notification à tous les contacts lorsque le traceur atteint un niveau de batterie faible.',
        TITLE: 'Batterie'
      },
      BUTTONS: {
        HINT: 'Envoie une notification à tous les contacts dès que l\'un des boutons latéraux est pressé. Le message configuré pour ce bouton sera inclus dans la notification.',
        TITLE: 'Boutons latéraux'
      },
      GEOFENCES: {
        HINT: 'Envoie une notification à tous les contacts si le traceur entre ou sort d\'une zone paramétrée.',
        TITLE: 'Zones de sécurité'
      },
      MAIL: 'Mail',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Un problème est survenu, veuillez réessayer',
        UPDATE_SUCCESS: 'Les paramètres de notifications ont été mis à jour avec succès'
      },
      SMS: 'SMS',
      SOS: {
        HINT: 'Envoie une notification à tous les contacts en cas de pression du bouton SOS.',
        TITLE: 'Appel SOS'
      },
      WARNING: {
        SMS: {
          LINK: 'Cliquez ici pour l\'activer.',
          TEXT: 'Attention, si vous souhaitez recevoir les alertes par SMS il vous faut activer l\'option correspondante.'
        }
      }
    },
    SECTION_TITLES: {
      BUTTONS: 'Boutons',
      CONTACTS: 'Contacts pour les notifications',
      DISPLAY: 'Général',
      GPS_FREQUENCY: 'Intervalle de temps entre chaque position GPS',
      NOTIFICATION_SETTINGS: 'Types de notifications pour chaque alerte',
      SOS: 'Numéro SOS',
      TRAINING: 'Appeler'
    },
    TRAINING_SOS: {
      DIRECT_CALL: 'Appeler directement ce numéro en cas d\'alerte SOS.',
      INPUT_PLACEHOLDER: '+33600000000',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Un problème est survenu, veuillez réessayer',
        UPDATE_SUCCESS: 'Numéro de téléphone mis à jour avec succès'
      },
      SOS_TEXT: 'Numéro de téléphone qui recevra les appels en cas d\'alerte SOS',
      TRAINING_TEXT: 'Renseignez le numéro de téléphone qui sera appelé par défaut par le traceur en cas d\'utilisation de la fonction téléphone depuis l\'application. Ce numéro est éditable à chaque demande d\'appel depuis l\'application.'
    }
  },
  trackers: {
    ADD_TRACKER_BUTTON: {
      LABEL: 'Ajouter un traceur'
    },
    EXPIRATION: {
      EXPIRED: 'L\'abonnement de {{tracker}} a expiré',
      WILL_EXPIRE: 'L\'abonnement de {{tracker}} va expirer dans {{days}} jours'
    },
    LINKS: {
      ACTIVITY: 'Suivi d\'activité',
      GEOFENCES: 'Zones',
      HISTORY: 'Historique',
      SETTINGS: 'Paramètres',
      SUBSCRIPTION: 'Abonnement'
    }
  },
  trackersPositionRefresh: {
    MESSAGES: {
      NOT_REFRESHED: 'Votre traceur n\'a pas réussi à acquérir de nouvelles positions GPS',
      REFRESHING: 'Veuillez patienter, une demande d\'actualisation est en cours',
      SUCCESS: 'Positions GPS des traceurs mises à jour avec succès'
    }
  },
  transferPage: {
    ADD_TRACKER: 'Je souhaite ajouter un nouveau traceur à mon compte',
    BACK_TO_TRACKERS: 'Retour à la carte',
    FIRST_TITLE: 'Que souhaitez-vous faire exactement ?',
    FROM_HEADLINE: 'Traceur à remplacer :',
    HEADLINE: 'Remplacer un traceur',
    IMEI_LABEL: 'Numéro IMEI',
    INFO: 'L\'abonnement actuel, ainsi que l\'historique des positions GPS et les paramètres, seront transférés sur le nouveau traceur après le remplacement.',
    MESSAGES: {
      ERROR_UNKNOWN: 'Un problème est survenu, veuillez réessayer',
      SUCCESS: 'Nouveau traceur assigné avec succès',
      WRONG_TYPE: 'Le traceur avec ce numéro IMEI ne correspond pas au type de votre ancien traceur. Contactez notre service client.'
    },
    NAME_LABEL: 'Nom du traceur',
    PURCHASE: 'Vous n\'avez pas encore de traceur Weenect ? <1>Vous pouvez en acheter un ici</1>',
    REPLACE_BUTTON: {
      LABEL: 'Remplacer'
    },
    REPLACE_TRACKER: 'Je souhaite remplacer un traceur déjà existant',
    TO_HEADLINE: 'Nouveau traceur :'
  },
  webappDisconnection: {
    BUG: 'Il semblerait que votre traceur ait du mal à se connecter au réseau.',
    MANUAL: 'Vous avez éteint votre traceur. Appuyez sur le bouton ON pour le rallumer.',
    NO_BATTERY: 'Your tracker has switched off because it ran out of battery power.',
    NO_SUB: 'Vous n’avez pas d’abonnement pour ce traceur. Veuillez en prendre un en allant sur la page'
  },
  wifiZones: {
    AIRPLANE_TEXT: 'Attention : En mode avion, tant que votre traceur se trouve dans la zone Economie d\'énergie, vous ne pouvez plus faire sonner, vibrer ou rafraîchir manuellement sa position.',
    DEEP_SLEEP_TITLE: 'Mode avion',
    DISPLAY_BUTTON: 'Afficher la zone sur la carte',
    DISPLAY_TITLE: 'Notifications et affichage',
    IN_AIRPLANE: '- en mode avion',
    IN_OUT_TEXT: 'Entrées et sorties de zone : ',
    LAST_POS_IN_WIFI: 'Dans la zone d\'économie d\'énergie depuis {{date}} ',
    MAIL_NOTIF: 'Notifications par e-mail',
    NOTIF_TEXT: 'Recevez une notification pour chaque entrée ou sortie de zone. ',
    POPIN: {
      AIRPLANE: {
        BUTTON_VALIDATE: 'Sortir du mode avion',
        CONTENT: 'Vous ne pouvez plus communiquer avec votre traceur tant qu’il est dans la zone d\'économie d\'énergie. Si vous souhaitez retrouver toutes les fonctionnalités,&nbsp;<strong>désactivez le mode avion et sortez le traceur de la zone</strong>.',
        TITLE: 'Votre traceur est en mode avion dans la zone d\'économie d\'énergie {{wifiName}}.'
      },
      BUTTON_CLOSE: 'Le faire plus tard',
      BUTTON_VALIDATE: 'Désactiver la zone',
      CONTENT: 'Dans cette zone <strong>la remontée automatique des positions GPS de votre traceur est désactivée </strong>, vous pouvez cependant le faire sonner pour le retrouver.<strong> Vous pouvez également désactiver la zone Economie d\'énergie</strong>.',
      TITLE: 'Votre traceur est dans la zone d\'économie d’énergie {{wifiName}}'
    },
    PUSH_NOTIF: 'Notifications push',
    SMS_NOTIF: 'Notifications par SMS',
    TITLE: 'Zone d\'économie d\'énergie',
    ZONE_ACTIVATION: 'Activer la zone'
  }
}
