import React from 'react';

import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { Button, IButtonProps } from './button.component';

type Props = IButtonProps;

export const AddButton = ({ ...props }: Props) => {
    return <Button iconLeft={<PlusIcon />} {...props} />;
};
