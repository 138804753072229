import { apiService } from '../../api/api.service';
import { ISubscriptionOffersResponse } from '../interfaces-api';

/**
 * Fetch available variants - array of plans with options and payments included
 */
export const fetchSubscriptionVariants = (
    site?: string | null,
): Promise<ISubscriptionOffersResponse> => {
    let url;
    if (!site) {
        url = '/subscriptionoffer?site=weenect';
    } else {
        url = `/subscriptionoffer?${site}`;
    }

    return apiService.fetch(url).then((resp) => resp.json());
};
