/**
 * 1:1 mapping from ./colors.scss
 *
 * Names generated with
 * http://chir.ag/projects/name-that-color/
 */
export const colors = {
    colorAlto: '#d9d9d9',
    colorButtonDisabled: '#586066',
    colorAtlantis: '#99ca3b',
    colorAzure: '#385e9d',
    colorBridesmaid: '#fef1ee',
    colorChristi: '#39b015',
    colorCodGray: '#0d0d0d',
    colorDoveGray: '#737373',
    colorFlamingo: '#fc4c02',
    colorGoldTips: '#eab313',
    colorGrenadier: '#cc4101',
    colorOsloGray: '#898d8e',
    colorPictonBlue: '#58c4ea',
    colorRedOrange: '#ff4733',
    colorSilverChalice: '#a6a6a6',
    colorTawnyPort: '#6b2c3f',
    colorWhite: '#ffffff',
    colorWildSand: '#f4f4f4',
};
