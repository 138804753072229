import cx from 'classnames';
import React from 'react';
import { LinkProps, NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as NotificationWarningIcon } from '../../../../images/warning_icon.svg';

import styles from './header-nav-link.module.scss';

export interface IHeaderNavLinkProps extends LinkProps {
    external?: boolean;
    to: string;
    hasWarningIcon?: boolean;
}

export const HeaderNavLink = ({
    children,
    hasWarningIcon,
    to,
    className,
    external,
    ...props
}: IHeaderNavLinkProps) => {
    const { pathname } = useLocation()
    const setActiveLink = () => {
        if (to.includes('trackers') && pathname.includes('trackers')) {
            return styles.active
        }
        if (to === pathname) {
            return styles.active
        }
        else if (to.includes('account') && pathname.includes('account')) {
            return styles.active
        }
        return ''
    }
    if (external) {
        return (
            <a
                href={to}
                rel="noopener noreferrer"
                target="_blank"
                className={cx(styles['header-nav-link'], setActiveLink())}
                {...props}
            >
                {children}
            </a>
        );
    }
    return (
        <>
            <NavLink
                className={cx(styles['header-nav-link'], setActiveLink())}
                to={to}
                {...props}
            >
                <div className={styles.flexGrid}>
                    <div>{children}</div> <div style={{ marginLeft: '1rem', paddingTop: '0.5rem' }}>{hasWarningIcon ? <NotificationWarningIcon /> : null}</div>
                </div>
            </NavLink>
        </>

    );
};
