import { parsePhoneFormat } from 'utils/parsePhoneFormat';
import { apiService } from '../../api/api.service';
import { ITracker } from '../../trackers/interfaces-api';
import { TrackerIcon } from '../../trackers/tracker-icons';
import { INotificationSettingsDTO, sosMode } from '../interfaces';
import { mapSecondsToMode } from '../mappers/seconds-to-mode';

export const updateTrackerRefreshMode = (
    trackerId: number,
    secondsPeriods: number,
) => {
    const getUrl = () => `/mytracker/${trackerId}/mode`;

    return apiService.fetch(getUrl(), {
        method: 'POST',
        body: JSON.stringify({
            mode: mapSecondsToMode(secondsPeriods),
        }),
    });
};

export const updateTrackerNameAndIcon = (
    trackerId: number,
    { name, icon, color }: { name: string; icon: TrackerIcon; color: string },
) => {
    const getUrl = () => `/mytracker/${trackerId}`;

    let trackerColor: string | null = color.replace('#', '');
    // Handle case when customer never set the tracker icon (so color is null)
    // In this case, color is the string '#null'. So we set it to the js null value.
    if (trackerColor === 'null') {
        trackerColor = null;
    }

    return apiService
        .fetch(getUrl(), {
            method: 'PUT',
            body: JSON.stringify({
                name,
                icon,
                color: trackerColor,
            }),
        })
        .then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                return resp.json();
            }

            return Promise.reject(resp);
        });
};

export const updateTrackerButtons = (
    trackerId: number,
    newButton1: string,
    newButton2: string,
    newButton3: string,
) => {
    const getUrl = () => `/mytracker/${trackerId}/buttons`;

    return apiService
        .fetch(getUrl(), {
            method: 'POST',
            body: JSON.stringify({
                button1: newButton1,
                button2: newButton2,
                button3: newButton3,
            }),
        })
        .then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                return resp;
            }

            return Promise.reject(resp);
        });
};

export const updateTrackerContacts = (
    trackerId: number,
    { mails, numbers }: { mails: string[]; numbers: string[] },
) => {
    const getUrl = () => `/mytracker/${trackerId}/contacts`;
    let numbersFormatedForApi: string[] = []
    numbers.map((number: string) => {
        numbersFormatedForApi.push(parsePhoneFormat(number))
    })
    return apiService
        .fetch(getUrl(), {
            method: 'POST',
            body: JSON.stringify({
                mail_contacts: mails,
                sms_contacts: numbersFormatedForApi ? numbersFormatedForApi : null,
            }),
        })
        .then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                return resp;
            }

            return Promise.reject(resp);
        });
};

export const updateSosSettings = (
    trackerId: number,
    { newNumber, mode }: { newNumber: string; mode?: sosMode },
) => {
    const getUrl = () => `/mytracker/${trackerId}`;

    const getBody = () => {
        const body = {
            sos_phone: parsePhoneFormat(newNumber),
        };

        if (mode) {
            (body as any).sos_mode = mode;
        }

        return JSON.stringify(body);
    };

    return apiService
        .fetch(getUrl(), {
            method: 'PUT',
            body: getBody(),
        })
        .then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                return resp.json();
            }

            return Promise.reject(resp);
        });
};

export const putTracker = (id: number, newType: string): Promise<ITracker> => {
    const putUrl = () => `/mytracker/${id}`;

    return apiService.fetch(putUrl(), {
        method: 'PUT',
        body: JSON.stringify({
            type: newType,
        }),
    });
};

export const updateNotificationsSettings = (
    trackerId: number,
    dto: INotificationSettingsDTO,
) => {
    const getUrl = () => `/mytracker/${trackerId}`;

    return apiService
        .fetch(getUrl(), {
            method: 'PUT',
            body: JSON.stringify(dto),
        })
        .then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                return resp.json();
            }

            return Promise.reject(resp);
        });
};
