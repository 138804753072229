import { Icon } from 'leaflet';
import React from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import icon, {
    ReactComponent as EventIcon,
} from '../../../icons/event-pin.svg';
import { IRootState } from '../../../store/store';
import { MapLayerType } from '../../interfaces';
import styles from './map-marker.module.scss';

interface IStateProps {
    selectedLayer: MapLayerType;
}
interface IOuterProps {
    opacity: number;
    key: string;
    position: [number, number];
    tooltipChild: JSX.Element | undefined;
    // Below, required google-map-react properties
    lat?: number | undefined;
    lng?: number | undefined;
    zIndex?: boolean;
}
interface IMapMarkerProps extends IStateProps, IOuterProps {}

/**
 * A MapMarker is an abstract component which automatically select the component to use,
 * depending on the selected layer, and draw a marker on the map.
 * A map marker has a tooltip.
 */
export const MapMarker = ({
    selectedLayer,
    opacity,
    key,
    zIndex,
    position,
    tooltipChild,
}: IMapMarkerProps) => {
    /**
     * ========== GOOGLE (custom components) ==========
     */

    /**
     * Custom tooltip component
     */
    const renderTooltip = () => (
        <div className={styles['event-tooltip']}>{tooltipChild}</div>
    );

    /**
     * Create custom icon component from event-icon
     */

    const renderGoogleMapMarker = () => (
        <div className={styles['custom-marker']}>
            <EventIcon style={{ opacity }} className={styles['event-icon']} />
            {renderTooltip()}
        </div>
    );

    /**
     * ========== LEAFLET ==========
     */

    /**
     * Create Leaflet Icon object from event-icon
     */
    const getActivityMarkerIcon = (): Icon => {
        return new Icon({
            iconUrl: icon,
            iconSize: [20, 20],
        });
    };

    /**
     * Create Leaflet marker and tooltip linked to it.
     */
    const renderLeafletMapMarker = () => (
        <Marker
            icon={getActivityMarkerIcon()}
            opacity={opacity}
            key={key}
            position={position}
            riseOnHover={true}
        >
            <Tooltip className={styles['marker-tooltip']}>
                {tooltipChild}
            </Tooltip>
        </Marker>
    );

    /**
     * Select which marker to render depending on selected layer.
     */
    if (selectedLayer.startsWith('GOOGLE')) {
        return renderGoogleMapMarker();
    }
    return renderLeafletMapMarker();
};

const mapState = (state: IRootState): IStateProps => {
    return {
        selectedLayer: state.map.selectedLayer,
    };
};

export const ConnectedMapMarker = compose<IMapMarkerProps, IOuterProps>(
    connect(mapState, null),
)(MapMarker);
