import { SubscriptionVariantCode } from '../../subscription-offers/interfaces';
import { IRawSubscriptionVariantOffer } from '../../subscription-offers/interfaces-api';
import { bankTransferAvailableCountries } from '../bank-transfer-available-countries';
import { IPaymentType } from '../interfaces';
import { getBankTransferPayment, getCardPayment } from '../payments-types';

/**
 * Returns list of available payments, based on user country and offer types
 */
export const mapOffersToPayments = (
    variants: IRawSubscriptionVariantOffer[],
    selectedSubscriptionCode: SubscriptionVariantCode | null,
    country: string,
    isResolvingSubscription: boolean,
    canChangePlan: boolean,
): IPaymentType[] => {
    const bankAvailableInUserCountry = bankTransferAvailableCountries.includes(
        country,
    );

    const selectedVariant = variants.find(
        (variant) => variant.code === selectedSubscriptionCode,
    );

    if (!selectedVariant) {
        return [];
    }

    const bankTransferAvailableInOffer = selectedVariant.has_levy;
    const creditCardAvailableInOffer = selectedVariant.has_cb;

    const availablePayments: IPaymentType[] = [];

    if (
        bankTransferAvailableInOffer &&
        bankAvailableInUserCountry &&
        /**
         * For now resolving a subscription
         * is only available for Hipay (Credit Card)
         */
        !isResolvingSubscription &&
        /**
         * Expired subscription can only change plan using Hipay
         */
        canChangePlan
    ) {
        availablePayments.push(getBankTransferPayment());
    }

    if (
        creditCardAvailableInOffer ||
        /**
         * Fallback condition to allow user to pay with card,
         * even is offer has disabled credit card and user's country
         * doesn't support bank transfer.
         * This should be done in backend, so this condition should be temporary.
         */
        (bankTransferAvailableInOffer && !bankAvailableInUserCountry)
    ) {
        availablePayments.push(getCardPayment());
    }

    return availablePayments;
};
