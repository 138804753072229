import cx from 'classnames';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { LeafletContext, withLeaflet } from 'react-leaflet';

import {
    formatPrice,
    normalizePriceAmount,
} from '../../../../utils/normalize-price-amount';
import { Button } from '../../../common/button/button.component';
import { Modal } from '../../../common/modal/modal.component';
import { PremiumPopup } from '../../../common/popup-premium';
import { Typography } from '../../../common/typography/typography.component';
import { INotification } from '../../../notifications/interfaces';
import { Dispatch, IRootState } from '../../../store/store';
import { ConnectedLiveModeProgress } from '../../../tracker-live-mode/components/live-mode-progress/live-mode-progress.component';
import { makeGetIsLiveModeEnabled } from '../../../tracker-live-mode/tracker-live-mode.store';
import { ConnectedFrequencySlider } from '../../../tracker-settings/frequency-slider/frequency-slider.component';
import { ConnectedTrackerActions } from '../../../trackers/components/tracker-actions/tracker-actions.component';
import { ITrackerOnSidebar } from '../../../trackers/interfaces';
import { makeGetFrequencyModeAvailable } from '../../../trackers/selectors/get-frequency-mode-available';
import { getIsAnyTrackerOnline } from '../../../trackers/selectors/get-is-any-tracker-online';
import { makeGetIsTrackerOnline } from '../../../trackers/selectors/get-is-tracker-online';
import {
    makeGetTrackerLiveModeInterval,
    makeGetTrackerSupportsLiveMode,
} from '../../../trackers/selectors/get-supports-live-mode';
import { makeGetTrackerOnSidebar } from '../../../trackers/selectors/get-tracker-on-sidebar';
import { getIsRefreshRunning } from '../../../trackers/selectors/selectors';
import { SubscriptionOptionType } from '../../../user-subscriptions/interfaces-api';
import { IRawUserData } from '../../../user/interfaces';
import {
    IAccountOptionOffers,
    IAccountOptions,
} from '../../../user/interfaces-api';
import {
    makeGetAccountOptionPremiumPack,
    makeGetAccountOptionsOffers,
} from '../../../user/selectors/account-option-selector';
import { putAccountOption } from '../../../user/user.service';
import { IWifiZone } from '../../../wifi-zones/interfaces';
import { IRawWifiZone } from '../../../wifi-zones/interfaces-api';
import { makeGetUserWifiZonesForTracker } from '../../../wifi-zones/selectors/wifi-zones.selectors';
import { GoogleLayers, MapLayerType } from '../../interfaces';
import { LayerSelect } from '../layer-select/layer-select.component';
import { MapFloatingButton } from '../map-floating-button/map-floating-button.component';
import {
    MapFloatingButtonLayers,
    MapFloatingButtonRefresh,
    MapFloatingButtonZoomIn,
    MapFloatingButtonZoomOut,
} from './map-controls-buttons';
import styles from './map-controls.module.scss';

interface IActions {
    setUserData: (user: IRawUserData) => unknown;
    onToggleWifi: (payload: any) => Promise<any>;
    onToggleDeepSleep: (
        trackerId: number,
        enableDeepSleep: boolean,
    ) => Promise<any>;
    clearNotifications: () => unknown;
    onZoomIn: () => unknown;
    onZoomOut: () => unknown;
    openLiveModeModal: () => unknown;
    refreshPositions: () => Promise<unknown>;
    setSelectedMapLayer: (layer: MapLayerType) => unknown;
    showNotification: (notification: INotification) => unknown;
    startLiveMode: (trackerId: number) => Promise<unknown>;
    subscribeMapOptionOfFirstActiveSub: () => Promise<unknown>;
}

interface IDeferredActions {
    onLiveModeClick: () => unknown;
}

interface IState {
    currentZoomLevel: number;
    accountOffers: IAccountOptionOffers[] | null | undefined;
    frequencyAvailable: boolean | '' | null | undefined;
    hasMapOptionRunning: boolean;
    liveModeEnabled: boolean;
    liveModeSupported: boolean;
    liveModeFrequency: string | null;
    userID: number | undefined;
    refreshAvailable: boolean;
    pendingLayer: MapLayerType;
    selectedMapLayer: MapLayerType;
    tracker: ITrackerOnSidebar | null;
    activeWifiZone: IWifiZone | undefined;
    user: IRawUserData | null;
}
interface IRefreshProps {
    isRefreshCalled: boolean;
}
interface IOuterProps extends HTMLAttributes<HTMLDivElement> {}

interface ILeafletProps {
    leaflet: LeafletContext;
}

interface IProps
    extends IActions,
        IDeferredActions,
        IOuterProps,
        IRefreshProps,
        ILeafletProps,
        IState {}

export const MapControls = ({
    accountOffers,
    currentZoomLevel,
    className,
    frequencyAvailable,
    onToggleDeepSleep,
    onToggleWifi,
    hasMapOptionRunning,
    isRefreshCalled,
    liveModeEnabled,
    liveModeSupported,
    liveModeFrequency,
    refreshAvailable,
    selectedMapLayer,
    tracker,
    activeWifiZone,
    user,
    clearNotifications,
    onZoomIn,
    onZoomOut,
    openLiveModeModal,
    refreshPositions,
    setUserData,
    setSelectedMapLayer,
    showNotification,
    startLiveMode,
}: IProps) => {
    const [layerControlsVisible, toggleLayerControls] = useState(false);
    const [displayPremiumMapWarning, setDisplayPremiumMapWarning] =
        useState(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const [pendingLayer, setPendingLayer] = useState(MapLayerType.STREET);
    const { t } = useTranslation('wifiZones');
    // Hook to reset layer to classic maps when customer have chosen a premium map
    // but the option is now expired.
    useEffect(() => {
        if (!hasMapOptionRunning) {
            setSelectedMapLayer(MapLayerType.STREET);
        }
    }, [hasMapOptionRunning, setSelectedMapLayer]);

    const onRefreshClick = () => {
        refreshPositions().then(async () => {
            await clearNotifications();
            await showNotification({
                content: t('trackersPositionRefresh:MESSAGES.REFRESHING'),
                type: 'success',
            });
        });
    };

    const onLiveModeClick = () => {
        if (liveModeEnabled) {
            openLiveModeModal();
        } else {
            startLiveMode(tracker!.id).then(() => {
                showNotification({
                    type: 'success',
                    content: t('superLive:MESSAGES.SUCCESS_ACTIVATED', {
                        frequency: liveModeFrequency,
                    }),
                });
            });
        }
    };
    const mayRenderDeepSleepModal = () => {
        if (modalOpen && tracker?.isInDeepSleep && activeWifiZone) {
            // inside wifi and in deep sleep
            return (
                <Modal onClosed={() => setModalOpen(false)}>
                    <div className={styles.textContainer}>
                        <Typography size16 bold className={styles.title}>
                            {t('POPIN.AIRPLANE.TITLE', {
                                wifiName: activeWifiZone?.name,
                            })}
                        </Typography>
                        <Trans
                            ns={'wifiZones'}
                            i18nKey="POPIN.AIRPLANE.CONTENT"
                        >
                            <Typography>
                                {t('POPIN.AIRPLANE.CONTENT')}
                            </Typography>
                        </Trans>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            className={styles.button}
                            onClick={() => {
                                setModalOpen(false);
                                onToggleDeepSleep(tracker.id, false);
                            }}
                        >
                            {t('POPIN.AIRPLANE.BUTTON_VALIDATE')}
                        </Button>
                        <Button
                            className={styles.buttonCancel}
                            onClick={() => setModalOpen(false)}
                        >
                            {t('POPIN.BUTTON_CLOSE')}
                        </Button>
                    </div>
                </Modal>
            );
        }
        if (modalOpen) {
            // inside wifi activated
            return (
                <Modal onClosed={() => setModalOpen(false)}>
                    <div className={styles.textContainer}>
                        <Typography size16 bold>
                            {t('POPIN.TITLE', {
                                wifiName: activeWifiZone?.name,
                            })}
                        </Typography>
                        <Trans ns={'wifiZones'} i18nKey="POPIN.CONTENT">
                            <Typography>{t('POPIN.CONTENT')}</Typography>
                        </Trans>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            className={styles.button}
                            onClick={() => {
                                setModalOpen(false);
                                onToggleWifi(activeWifiZone);
                            }}
                        >
                            {t('POPIN.BUTTON_VALIDATE')}
                        </Button>
                        <Button
                            className={styles.buttonCancel}
                            onClick={() => setModalOpen(false)}
                        >
                            {' '}
                            {t('POPIN.BUTTON_CLOSE')}
                        </Button>
                    </div>
                </Modal>
            );
        }
    };
    /**
     * Decide either to modify viewport state (map.store.ts)
     * or to zoom the leaflet map
     * depending if one tracker is selected or not,
     */
    const onZoomInClick = () => {
        return onZoomIn();
        // return leaflet.map?.setZoom(leaflet.map.getZoom() + 1);
    };

    const onZoomOutClick = () => {
        return onZoomOut();
        // return leaflet.map?.setZoom(leaflet.map.getZoom() - 1);
    };

    const maybeRenderLiveModeButton = () => {
        if (liveModeSupported) {
            return (
                <MapFloatingButton
                    description={t('mapControls:SUPER_LIVE')}
                    className={styles['live-mode-button']}
                    disabled={isRefreshCalled}
                    isLockedDuToWifi={
                        activeWifiZone?.is_active || tracker?.isInDeepSleep
                    }
                    onClick={() =>
                        activeWifiZone?.is_active || tracker?.isInDeepSleep
                            ? setModalOpen(true)
                            : onLiveModeClick()
                    }
                >
                    <ConnectedLiveModeProgress />
                </MapFloatingButton>
            );
        }
    };

    const maybeRenderTrackerActions = () => {
        if (tracker && tracker.online) {
            return (
                <ConnectedTrackerActions
                    disabled={tracker.isInDeepSleep}
                    activeWifi={activeWifiZone?.is_active}
                    openWifiPopup={() => setModalOpen(true)}
                    trackerId={tracker.id}
                    className={styles['actions-list']}
                    hasCall={tracker.hasCall}
                    hasRing={tracker.hasRing}
                    hasVibrate={tracker.hasVibrate}
                    hasFlash={tracker.hasFlash}
                />
            );
        }
    };

    const maybeRenderFrequencySlider = () => {
        if (frequencyAvailable && tracker) {
            return (
                <ConnectedFrequencySlider
                    trackerId={tracker.id}
                    className={styles.slider}
                    hasPreview={true}
                />
            );
        }
    };
    const layerSelected = () => {
        return (
            setDisplayPremiumMapWarning(false),
            setSelectedMapLayer(pendingLayer)
        );
    };
    /**
     * Display a warning pop-up when a customer choose a premium map
     * when not having subscribed to that option.
     */
    const maybeRenderPremiumMapWarning = () => {
        if (displayPremiumMapWarning && accountOffers) {
            if (accountOffers) {
                return (
                    <PremiumPopup
                    price={formatPrice(
                        normalizePriceAmount(
                            accountOffers[0].price_offer.fr.amount,
                        ),
                        accountOffers[0].price_offer.fr.currency,
                    )}
                        onClosed={() => setDisplayPremiumMapWarning(false)}
                        onClick={() => {
                            if (user) {
                                putAccountOption({
                                    account_options: [
                                        SubscriptionOptionType.PREMIUMPACK,
                                    ],
                                    site: user.site,
                                }).then((res: IAccountOptions[]) => {
                                    Object.assign(user.account_options, res);
                                    setDisplayPremiumMapWarning(false);
                                    return setUserData(user);
                                });
                            }
                        }}
                    />
                );
            }
        }
    };

    /**
     * When a layer is selected, if it is a premium layer,
     * check customer has a premium_map option running.
     * If no, will trigger a warning popup.
     * Else apply the layer.
     * @param layer Selected layer chosen bupdateTrackery customer click.
     */
    const maybeSetSelectedMapLayer = (layer: MapLayerType) => {
        const GoogleLayersValues = Object.values(GoogleLayers) as string[];

        if (
            GoogleLayersValues.includes(layer as string) &&
            !hasMapOptionRunning
        ) {
            setPendingLayer(layer);
            return setDisplayPremiumMapWarning(true);
        }

        toggleLayerControls(false);
        return setSelectedMapLayer(layer);
    };

    const renderLayerSelect = () => {
        return (
            <LayerSelect
                className={styles.layerSelect}
                hasMapOptionRunning={hasMapOptionRunning || false} // Here hasMapOptionRunning can be null
                selectedMapLayer={selectedMapLayer}
                onLayerChange={(layer) => {
                    maybeSetSelectedMapLayer(layer);
                }}
            />
        );
    };
    return (
        <React.Fragment>
            {maybeRenderPremiumMapWarning()}

            <div className={cx(styles['map-controls'], className)}>
                <div className={styles['group-buttons']}>
                    <div className={styles['map-layer-button']}>
                        <MapFloatingButtonLayers
                            description={t('mapControls:MAP_TYPE')}
                            onClick={(e) => {
                                toggleLayerControls(!layerControlsVisible);
                            }}
                        />
                    </div>
                    <MapFloatingButtonZoomIn
                        description={t('mapControls:ZOOM_IN')}
                        disabled={currentZoomLevel >= 22}
                        onClick={onZoomInClick}
                    />
                    <MapFloatingButtonZoomOut
                        description={t('mapControls:ZOOM_OUT')}
                        onClick={onZoomOutClick}
                    />
                </div>

                <div className={styles['group-buttons']}>
                    {refreshAvailable && (
                        <MapFloatingButtonRefresh
                            description={t('mapControls:REFRESH_POSITION')}
                            isLockedDuToWifi={
                                activeWifiZone?.is_active ||
                                tracker?.isInDeepSleep
                            }
                            onClick={() =>
                                activeWifiZone?.is_active ||
                                tracker?.isInDeepSleep
                                    ? setModalOpen(true)
                                    : onRefreshClick()
                            }
                            disabled={isRefreshCalled}
                            className={isRefreshCalled ? styles.refreshing : ''}
                        />
                    )}
                    {maybeRenderLiveModeButton()}
                </div>

                {/* Ring - Vibrate - Call */}
                {maybeRenderTrackerActions()}
                {mayRenderDeepSleepModal()}
                {layerControlsVisible && renderLayerSelect()}
                {maybeRenderFrequencySlider()}
            </div>
        </React.Fragment>
    );
};

const mapDispatch = (dispatch: Dispatch, state: IState): IActions => ({
    onToggleDeepSleep: async (trackerId: number, enableDeepSleep: boolean) => {
        const dto = {
            enable_deep_sleep_wifi: enableDeepSleep,
        };
        return dispatch.wifiZonesEdit.onTriggerDeepSleep({
            trackerId,
            dto,
        });
    },
    onToggleWifi: async (wifiZone: IRawWifiZone) => {
        wifiZone.is_active = false;
        return dispatch.wifiZonesEdit.onTriggerWifiEdit({
            trackerId: wifiZone.tracker_id,
            wifi: wifiZone,
            id: state.activeWifiZone?.id,
        });
    },
    onZoomIn: dispatch.map.incrementZoom as any,
    onZoomOut: dispatch.map.decrementZoom as any,
    // Save layer in localStorage so customer won't have to change it again on new connections
    setSelectedMapLayer: dispatch.map.setLayerAndSaveIt as any,
    clearNotifications: dispatch.notifications.clearNotifications,
    openLiveModeModal: dispatch.trackerLiveMode.toggleDisplayModalOpen,
    refreshPositions: async () => dispatch.userTrackers.forceRefreshPositions(),
    showNotification: dispatch.notifications.showNotification,
    startLiveMode: async (trackerId: number) =>
        dispatch.trackerLiveMode.startLiveMode(trackerId),
    subscribeMapOptionOfFirstActiveSub: async () =>
        dispatch.userSubscriptions.subscribeMapOptionOfFirstActiveSub(),
    setUserData: async (data: IRawUserData) => {
        dispatch.user.setUserData(data);
        return data;
    },
});

const mapState = (state: IRootState): IState => {
    const getActiveTrackerOnSidebar = makeGetTrackerOnSidebar();
    const tracker = getActiveTrackerOnSidebar(state);
    const getAccountOptionOffers = makeGetAccountOptionsOffers();
    const getPremiumPackOption = makeGetAccountOptionPremiumPack();
    const hasOptionRunning = getPremiumPackOption(state)?.is_running;

    const getSingleTrackerWifiZones = makeGetUserWifiZonesForTracker(
        tracker?.id!,
    );
    const selectedTrackerWifiZones = getSingleTrackerWifiZones(state);
    const activeWifiZone = selectedTrackerWifiZones.find(
        (wifi: IWifiZone) => wifi.id === tracker?.isInWifiZone,
    );
    if (!tracker) {
        return {
            userID: state.user.userData?.id,
            pendingLayer: state.map.selectedLayer,
            frequencyAvailable: false,
            hasMapOptionRunning: hasOptionRunning || false,
            liveModeEnabled: false,
            liveModeSupported: false,
            liveModeFrequency: null,
            refreshAvailable: getIsAnyTrackerOnline(state),
            selectedMapLayer: state.map.selectedLayer,
            tracker: null,
            currentZoomLevel: state.map.zoom,
            activeWifiZone: undefined,
            accountOffers: getAccountOptionOffers(state),
            user: state.user.userData,
        };
    }

    const getActiveTrackerFrequencyAvailable = makeGetFrequencyModeAvailable(
        tracker.id,
    );
    const getActiveTrackerSuperLiveInterval = makeGetTrackerLiveModeInterval(
        tracker.id,
    );
    const getIsTrackerOnline = makeGetIsTrackerOnline(tracker.id);
    const getLiveModeSupported = makeGetTrackerSupportsLiveMode(tracker.id);
    const getLiveModeEnabled = makeGetIsLiveModeEnabled(tracker.id);
    return {
        userID: state.user.userData?.id,
        pendingLayer: state.map.selectedLayer,
        currentZoomLevel: state.map.zoom,
        frequencyAvailable: getActiveTrackerFrequencyAvailable(state),
        hasMapOptionRunning: getPremiumPackOption(state)?.is_running || false,
        liveModeEnabled: getLiveModeEnabled(state),
        liveModeSupported:
            getLiveModeSupported(state) && getIsTrackerOnline(state),
        liveModeFrequency: getActiveTrackerSuperLiveInterval(state),
        refreshAvailable: getIsTrackerOnline(state),
        selectedMapLayer: state.map.selectedLayer,
        tracker,
        activeWifiZone,
        accountOffers: getAccountOptionOffers(state),
        user: state.user.userData,
    };
};

export const ConnectedMapControls = compose<IProps, IOuterProps>(
    withLeaflet,
    connect(mapState, mapDispatch),
    connect((state: IRootState, props: IState & IOuterProps) => {
        const isRefreshRunning = getIsRefreshRunning(state);
        return {
            isRefreshCalled: isRefreshRunning,
        };
    }),
    pure,
)(MapControls);
