import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { useTranslation } from 'react-i18next';

import zoomPlugin from 'chartjs-plugin-zoom';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { getWeek } from '../../../../utils/convertDateUtc';
import { IAggregateData, Period } from '../../interface';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
);

const ctx = document.getElementById('canvas');

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
        y: {
            beginAtZero: true,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        plugins: {
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true, // SET SCROOL ZOOM TO TRUE
                    },
                    mode: 'xy',
                    speed: 100,
                },
                pan: {
                    enabled: true,
                    mode: 'xy',
                    speed: 100,
                },
            },
        },
        options: {
            lineAt: 0.5,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            min: 0,
                        },
                    },
                ],
            },
        },
    },
};

interface IOuterProps {
    aggregateData: IAggregateData | null;
    period: Period;
    loading: boolean;
}

export const ChartComponent = ({
    aggregateData,
    period,
    loading,
}: IOuterProps) => {
    const { t } = useTranslation('activity');
    const labelsDay: string[] = [];
    let labelsWeek: string[] = [];
    let labelsMonth: any[] = [];
    const result = getWeek(aggregateData?.activity[0].server_date!);
    aggregateData?.activity.map((value, index) => {
        const startDate: any = new Date(value.server_date);
        if (period === Period.DAY) {
            const serverDate = value.server_date.toString();
            // here we slice the server_date to extrade the hours returned by the api
            // ex of string received : 2022-12-01T00:00:00+00:00
            // we start the slicing at T + 1  and stop it 4 letter after
            const slicingIndexStart = serverDate.indexOf('T') + 1;
            const slicingIndexStop = serverDate.indexOf('T') + 3;
            const hours = serverDate.slice(slicingIndexStart, slicingIndexStop);

            labelsDay.push(hours + t('SUMMARY.HOUR_INDEX'));
        }
        const date = startDate.toLocaleString('en-us', { weekday: 'long' });
        if (period === Period.MONTH) {
            labelsWeek = [];
            labelsMonth.push(result + index);
        }
        const month = startDate.getMonth() + 1;
        if (period === Period.WEEK) {
            labelsMonth = [];
            labelsWeek.push(startDate.getDate() + '/' + month);
        }
    });
    let labels = labelsDay;
    if (period === Period.DAY) {
        labels = labelsDay;
    }
    if (period === Period.WEEK) {
        labels = labelsWeek;
    }
    if (period === Period.MONTH) {
        labels = labelsMonth;
    }
    let data;
    if (loading) {
        data = null;
    }
    data = {
        labels,
        datasets: [
            {
                data: aggregateData
                    ? aggregateData.activity.map(
                          (activity) => activity.activity,
                      )
                    : null,
                backgroundColor: '#9949FF',
                barThickness: 5,
                borderRadius: 100,
            },
        ],
    };

    return (
        <>
            <Bar options={options} data={data} />
        </>
    );
};
