import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '../../common/button/button.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link } from '../../common/link/link.component';
import { Typography } from '../../common/typography/typography.component';
import { INotification } from '../../notifications/interfaces';
import { Dispatch } from '../../store/store';
import { uncancelSubscription } from '../subscription.service';
import styles from './subscription-alert-modal.module.scss';

export interface IActions {
    showNotification(notification: INotification): unknown;
    fetchSubscriptionDetails(subscriptionId: number): Promise<any>;
}

export interface IProps {
    trackerName: string;
    subscriptionId: number | null;
    onConfirm: () => any;
    onAbort: () => any;
}

export type ISubscriptionAlertModal = IProps & IActions;

export const SubscriptionAlertModal = ({
    trackerName,
    subscriptionId,
    onConfirm,
    onAbort,
    showNotification,
    fetchSubscriptionDetails,
}: ISubscriptionAlertModal) => {
    const { t } = useTranslation('subscriptionAlert');
    if (!subscriptionId) {
        return null;
    }

    const onUncancelSubscriptionSuccess = () => {
        showNotification({
            content: t('MESSAGES.UPDATE_SUCCESS'),
            type: 'success',
        });
        onConfirm();
        return fetchSubscriptionDetails(subscriptionId);
    };

    return (
        <div className={styles.container}>
            <Typography className={styles.text}>
                <Trans ns="subscriptionAlert" i18nKey="AUTO_RENEWAL_NOTICE">
                    <>
                        DOES_NOT_WORK_WITHOUT_THIS_TEXT <strong>Attention !</strong>{' '}
                        Votre abonnement pour le traceur {{ trackerName }} arrive à
                        sa fin et le renouvellement automatique est désactivé.
                    </>
                </Trans>

            </Typography>

            <Typography className={styles.text}>
                <>{t('AUTO_RENEWAL_ADVICE')}</>
            </Typography>

            <div className={styles.buttons}>
                <Button
                    className={styles.button}
                    primary
                    onClick={() =>
                        uncancelSubscription(subscriptionId).then(
                            onUncancelSubscriptionSuccess,
                        )
                    }
                >
                    <>{t('ACTIVATE_AUTO_RENEWAL')}</>
                </Button>
            </div>

            <hr className={styles.text} />

            <Typography className={styles.text}>
                <Trans ns="subscriptionAlert" i18nKey="SUPPORT_TEXT">
                    Si vous avez des questions ou que vous pensez que c'est une
                    erreur, contactez notre
                    <Link
                        href="https://weenect.zendesk.com/hc/fr"
                        target="_blank"
                    >
                        service client
                    </Link>
                </Trans>
            </Typography>

            <div className={styles.buttons}>
                <Button className={styles.button} secondary onClick={onAbort}>
                    <>{t('CONTINUE_BUTTON')}</>
                </Button>
            </div>
        </div>
    );
};

const mapDispatch = (dispatch: Dispatch, props: IProps): IActions => ({
    showNotification: dispatch.notifications.showNotification,
    fetchSubscriptionDetails: async () =>
        dispatch.userSubscriptions.fetchSubscriptionDetails(
            props.subscriptionId,
        ),
});

export const ConnectedSubscriptionAlertModal = compose<
    ISubscriptionAlertModal,
    IProps
>(
    connect(
        null,
        mapDispatch,
    ),
)(SubscriptionAlertModal);
