import { createSelector } from 'reselect';

import { ITrackerOnSidebar } from '../interfaces';
import { ITracker } from '../interfaces-api';
import { mapTrackersToSidebar } from '../mappers/trackers-to-sidebar';
import { activeTrackerId, trackersFromApi } from './selectors';

export const makeGetTrackerOnSidebar = () =>
    createSelector(
        activeTrackerId,
        trackersFromApi,
        (
            trackerId: number | null,
            trackers: ITracker[],
        ): ITrackerOnSidebar | null => {
            const tracker = trackers.find((t) => t.id === trackerId);

            if (tracker) {
                return mapTrackersToSidebar([tracker])[0];
            }
            return null;
        },
    );
