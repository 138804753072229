import { IGeofenceEditStore } from '../geofence-edit.store';
import { IGeofence, IGeofenceMarker } from '../interfaces';
import { GeofenceMode, IRawGeofence } from '../interfaces-api';

const isExitNotificationEnabled = (mode: number) =>
    mode === GeofenceMode.EXIT_NOTIFICATIONS ||
    mode === GeofenceMode.ALL_NOTIFICATIONS;
const isEnterNotificationEnabled = (mode: number) =>
    mode === GeofenceMode.ENTER_NOTIFICATIONS ||
    mode === GeofenceMode.ALL_NOTIFICATIONS;

export const mapGeofenceApiToClient = (geofence: IRawGeofence): IGeofence => {
    return {
        geofenceId: geofence.id,
        address: geofence.address,
        position: {
            lat: geofence.latitude,
            lng: geofence.longitude,
        },
        outdoors: geofence.is_outside,
        name: geofence.name,
        active: geofence.active,
        radius: geofence.distance,
        exitNotification: isExitNotificationEnabled(geofence.mode),
        entranceNotification: isEnterNotificationEnabled(geofence.mode),
        trackerId: geofence.tracker_id,
    };
};

export const mapGeofenceApiToMarker = (
    geofence: IRawGeofence,
): IGeofenceMarker => {
    return {
        geofenceId: geofence.id,
        radius: geofence.distance,
        position: {
            lat: geofence.latitude,
            lng: geofence.longitude,
        },
    };
};
export const mapGeofenceEditToMarker = (
    geofence: IGeofenceEditStore,
): IGeofenceMarker => {
    return {
        geofenceId: geofence.geofenceId,
        radius: geofence.radius,
        position: geofence.position,
    };
};
