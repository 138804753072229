import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DashIcon } from '../../icons/dash.svg';

import styles from './back-button.module.scss';
import { Button, IButtonProps } from './button.component';

type Props = IButtonProps;

export const BackButton = ({ ...props }: Props) => {
    const { t } = useTranslation('commonActions');

    return (
        <Button
            iconLeft={<DashIcon className={styles.backIcon} />}
            secondary
            children={props.children || t('BACK')}
            {...props}
        />
    );
};
