export const formatTimeInterval = (seconds: number) => {
    if (typeof seconds === 'undefined') {
        return '-';
    }
    if (seconds == 0) {
        return 'Off';
    }

    if (seconds < 60) {
        return `${seconds}S`;
    }
    return `${seconds / 60}Min`;
};
