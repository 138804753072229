import { set } from 'lodash';

import { ModelConfig } from '@rematch/core';
import { Dispatch } from 'redux';

import { IRootState } from '../store/store';
import { ITracker } from '../trackers/interfaces-api';
import { IWifiZone } from './interfaces';
import { IRawWifiZone } from './interfaces-api';
import { makeGetAllUserWifiZones } from './selectors/wifi-zones.selectors';
import { deleteWifiZone } from './services/wifi-zones.service';

export interface IWifiZonesStore {
    userWifiZonesByTrackerId: {
        [trackerId: number]: {
            [wifiZoneId: number]: IRawWifiZone;
        };
    };
    /**
     * Store information if wifiZones have been at least once fetched.
     */
    dataFetchedByTrackerId: {
        [trackerId: number]: boolean;
    };
}
/**
 * Model storing existing user wifiZones
 */
export const wifiZoneStore: ModelConfig<IWifiZonesStore> = {
    state: {
        userWifiZonesByTrackerId: {},
        dataFetchedByTrackerId: {},
    },
    effects: (dispatch: any) => ({
        async saveWifiZones(trackerId: number, state: IRootState) {

            this._setDataFetchedForTracker(trackerId);
            const { rawTrackers } = state.userTrackers;
            rawTrackers.map((tracker: ITracker) => {
                const { wifi_zones, id } = tracker;
                if (wifi_zones !== undefined) {
                    this._setWifiZonesToTracker({
                        trackerId: id,
                        wifiZones: wifi_zones,
                    });
                }
            });
        },
        async deleteWifiZone(wifiZoneId: number, models: any) {
            const getAllWifiZones = makeGetAllUserWifiZones();
            const wifiZones: IWifiZone[] = getAllWifiZones(models);
            const { userTrackers } = models;
            const wifiZone = wifiZones.find((g) => g.id === wifiZoneId);
            if (!wifiZone) {
                return;
            }
            return deleteWifiZone(wifiZoneId).then((resp) => {
                if (resp.status === 204) {
                    dispatch.wifiZones._deleteWifiZone({
                        trackerId: userTrackers.activeTracker,
                        wifiZoneId,
                    });
                }
            });
        },
    }),
    reducers: {
        _setWifiZonesToTracker: (
            state: IWifiZonesStore,
            {
                trackerId,
                wifiZones,
            }: { trackerId: number; wifiZones: IRawWifiZone[] },
        ): IWifiZonesStore => {
            const newState = { ...state };
            newState.userWifiZonesByTrackerId[trackerId] = {};
            wifiZones.map((wifiZone) => {
                wifiZone.displayed = true;
                if (wifiZone?.id) {
                    set(
                        newState,
                        `userWifiZonesByTrackerId[${trackerId}][${wifiZone.id}]`,
                        wifiZone,
                    );
                }
            });
            return newState;
        },
        _setWifiZone: (
            state: IWifiZonesStore,
            {
                trackerId,
                wifiZone,
            }: { trackerId: number; wifiZone: IRawWifiZone },
        ) => {
            const newState = { ...state };
            if (!newState.userWifiZonesByTrackerId[trackerId]) {
                newState.userWifiZonesByTrackerId[trackerId] = {};
            }
            set(
                newState,
                `userWifiZonesByTrackerId[${trackerId}][${wifiZone.id}]`,
                wifiZone,
            );
            return newState;
        },
        _editWifiZone: (
            state: IWifiZonesStore,
            {
                trackerId,
                wifiZone,
            }: { trackerId: number; wifiZone: IRawWifiZone },
        ) => {
            const newState = { ...state };
            if (!newState.userWifiZonesByTrackerId[trackerId]) {
                newState.userWifiZonesByTrackerId[trackerId] = {};
            }
            if (newState.userWifiZonesByTrackerId[trackerId]) {
                newState.userWifiZonesByTrackerId[trackerId][
                    wifiZone.id
                ] = wifiZone;
            }
            return newState;
        },
        _deleteWifiZone: (
            state: IWifiZonesStore,
            {
                trackerId,
                wifiZoneId,
            }: { trackerId: number; wifiZoneId: number },
        ) => {
            const newState = { ...state };
            if (newState.userWifiZonesByTrackerId[trackerId]) {
                delete newState.userWifiZonesByTrackerId[trackerId][wifiZoneId];
            }
            return newState;
        },
        _setDataFetchedForTracker: (state: IWifiZonesStore, trackerId: number) => {
            const newState = { ...state };
            newState.dataFetchedByTrackerId[trackerId] = true;
            return newState;
        },
    },
};
