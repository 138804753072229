import { getGlobalT } from '../../../../i18n/setup-translations';
import { ReactComponent as GlobeIcon } from '../../../icons/globe.svg';
import { ReactComponent as LockIcon } from '../../../icons/lock.svg';
import { ReactComponent as PaymentIcon } from '../../../icons/payment.svg';
import { ReactComponent as UserIcon } from '../../../icons/user-profile.svg';
import { ReactComponent as PreferenceIcon } from '../../../icons/preference.svg';
export const routes = {
    default: '/account',
    profile: '/account/profile',
    preferences: '/account/preferences',
    language: '/account/language',
    payments: '/account/payments',
    password: '/account/password',
};
export const getLinks = () => {
    const t = getGlobalT();
    return [
        {
            path: routes.profile,
            icon: UserIcon,
            label: t('account:NAV.USER_PROFILE'),
        },
        {
            path: routes.preferences,
            icon: PreferenceIcon,
            label: t('account:NAV.USER_PREFERENCES'),
        },
        {
            path: routes.password,
            icon: LockIcon,
            label: t('account:NAV.PASSWORD'),
        },
        {
            path: routes.language,
            icon: GlobeIcon,
            label: t('account:NAV.LANGUAGE'),
        },
        {
            path: routes.payments,
            icon: PaymentIcon,
            label: t('account:NAV.PAYMENTS'),
        },
    ];
};
