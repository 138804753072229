import * as yup from 'yup';
import { getGlobalT } from '../../../i18n/setup-translations';

import { isInternationalPhone } from '../../../utils/phone-validator';
import { IUserProfileFormValues } from './user-profile.component';

export const getUserProfileValidationSchema = () => {
    const t = getGlobalT();

    return yup.object<IUserProfileFormValues>({
        city: yup.string(),
        postalCode: yup.string().transform((v) => (v === null ? '' : v)),
        street: yup.string(),
        phone: yup
            .string()
            .transform((v) => (v === null ? '' : v))
            .test('phone', t('errors:PHONE_FORMAT'), isInternationalPhone),
        email: yup
            .string()
            .email()
            .required(),
        lastName: yup.string().required(),
        firstName: yup.string().required(),
        marketingAccepted: yup.bool(),
        country: yup.string(),
        preferredMetricSystem: yup.string(),
    });
};
