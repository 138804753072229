import useMedia from 'use-media';

/**
 * References /styles/rwd.scss
 * Uses React Hooks
 * TODO: Make automatic mapping from css
 */

export const useIsTabletOrSmaller = () => {
    return useMedia({
        maxWidth: '768px',
    });
};

export const useIsPhone = () => {
    return useMedia({
        maxWidth: '440px',
    });
};
