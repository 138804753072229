import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { compose } from 'recompose';

import { Dispatch, IRootState } from '../../store/store';
import { makeGetAllTrackersIds } from '../../trackers/selectors/get-all-tracker-ids';
import { makeGetIsSingleTrackerWithDroppedSubscription } from '../../trackers/selectors/get-is-single-tracker-with-dropped-subscription';
import { makeGetSubscriptionIdFromTracker } from '../../trackers/selectors/get-subscription-id-from-tracker';

import { IRawUserSubscription } from '../../user-subscriptions/interfaces-api';
import { makeGetSubscriptionDetailsFromTracker } from '../../user-subscriptions/selectors/user-subscriptions.selectors';

interface IStateProps {
    isSingleTrackerDroppedSubscription: boolean;
    singleDroppedSubscriptionModel: IRawUserSubscription | null;
    connectedSubscriptionId: number | null;
    trackerId: any;
}

interface IActions {
    prefillSubscriptionChoices: (sub: IRawUserSubscription) => Promise<unknown>;
    fetchSubscription: (id: number) => Promise<unknown>;
}

interface IHOCProps extends IStateProps, IActions { }

/**
 * This HOC checks if user has only one tracker BUT empty subscription,
 * so it will redirect to finish the subscription.
 */
export const singleTrackerDroppedSubGuardRaw = (
    Component: ComponentType<any>,
) => ({
    singleDroppedSubscriptionModel,
    isSingleTrackerDroppedSubscription,
    trackerId,
    prefillSubscriptionChoices,
    fetchSubscription,
    connectedSubscriptionId,
    ...props
}: IHOCProps) => {
        const history = useNavigate();
        useEffect(() => {
            if (
                isSingleTrackerDroppedSubscription &&
                singleDroppedSubscriptionModel
            ) {
                prefillSubscriptionChoices(singleDroppedSubscriptionModel).then(
                    () => {
                        return history(`/add/payment/${trackerId}`);
                    },
                );
                return;
            } else if (
                isSingleTrackerDroppedSubscription &&
                connectedSubscriptionId
            ) {
                fetchSubscription(connectedSubscriptionId);
            }
        }, [
            isSingleTrackerDroppedSubscription,
            singleDroppedSubscriptionModel,
            fetchSubscription,
            connectedSubscriptionId,
            prefillSubscriptionChoices,
            useLocation().pathname
            ,
        ]);

        return <Component {...props} />;
    };

const mapState = (state: IRootState, props: any): IStateProps => {
    const getIsSingleWithDroppedSub = makeGetIsSingleTrackerWithDroppedSubscription();
    const getAllTrackerIds = makeGetAllTrackersIds();
    const theTrackerId = getAllTrackerIds(state)[0]; // Always will be single
    const isSingle = getIsSingleWithDroppedSub(state);

    if (!isSingle) {
        return {
            isSingleTrackerDroppedSubscription: false,
            singleDroppedSubscriptionModel: null,
            connectedSubscriptionId: null,
            trackerId: theTrackerId,
        };
    }

    const getSubscriptionByTrackerId = makeGetSubscriptionDetailsFromTracker(
        theTrackerId,
        false,
    );

    const getSubscriptionId = makeGetSubscriptionIdFromTracker(theTrackerId);

    return {
        trackerId: theTrackerId,
        isSingleTrackerDroppedSubscription: isSingle,
        singleDroppedSubscriptionModel: getSubscriptionByTrackerId(
            state,
        ) as IRawUserSubscription, // Think about conditional return type
        connectedSubscriptionId: getSubscriptionId(state),
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    prefillSubscriptionChoices: async (sub) =>
        dispatch.subscriptionProcess.setChoicesFromSubscription(sub),
    fetchSubscription: async (id: number) =>
        dispatch.userSubscriptions.fetchSubscriptionDetails(id),
});

const connectedSingleTrackerDroppedSubGuard = compose(
    connect(mapState, mapDispatch),
    singleTrackerDroppedSubGuardRaw,
);

export { connectedSingleTrackerDroppedSubGuard as singleTrackerDroppedSubGuard };
