import { createSelector } from 'reselect';

import { IRegistrationDetails } from '../../user-subscriptions/interfaces';
import { ITracker } from '../interfaces-api';
import { mapTrackerToRegistrationDetails } from '../mappers/tracker-to-registration-details';
import { makeGetSelectedTrackerModel } from './get-selected-tracker-model';

export const makeGetTrackerRegistrationDetails = () => {
    const getActiveTrackerModel = makeGetSelectedTrackerModel();

    return createSelector(
        getActiveTrackerModel,
        (tracker: ITracker | null): IRegistrationDetails | null => {
            if (tracker) {
                return mapTrackerToRegistrationDetails(tracker);
            }

            return null;
        },
    );
};
