import moment from 'moment';
import { createSelector } from 'reselect';

import { IRootState } from '../store/store';
import { ITrackerEvent } from '../trackers/interfaces-api';
import { newPositions } from '../trackers/selectors/selectors';

import { numberOfElementsToShow } from './history-position-playing.store';
import { ITrackerSpeedDistanceActivity } from './interfaces';
import { filterByDate } from './mappers/filter-events-by-date';
import { filterEventsOnList } from './mappers/filter-events-on-list';
import { filterEventsOnMap } from './mappers/filter-events-on-map';

const getHistoryModel = (state: IRootState) => state.history;
const getPlayingModel = (state: IRootState) => state.historyPositionPlaying;

export const getIsHeatmapVisible = createSelector(
    getHistoryModel,
    (state) => state.heatmapMode,
);
export const getIsActivityVisible = createSelector(
    getHistoryModel,
    (state) => state.pathMode,
);

export const makeGetEventsOnMap = (trackerId: number | null) =>
    createSelector(
        getHistoryModel,
        getIsDateToday,
        getTodayPeriodValue,
        (model, isToday, timePeriod) => {
            if (!trackerId) {
                return [];
            }
            const events = model.eventsByTrackerId[trackerId];

            if (!events) {
                return [];
            }
            return events
                .filter(filterEventsOnMap)
                .filter((event) =>
                    filterByDate(event, isToday, model, timePeriod),
                )
                .sort((a, b) => (a.date_tracker > b.date_tracker ? 1 : -1));
        },
    );

export const getIsDateToday = createSelector(getHistoryModel, (model) =>
    moment(model.selectedDate).isSame(moment(new Date()), 'd'),
);

export const getTodayPeriodValue = createSelector(
    getHistoryModel,
    (state) => state.todayPeriod,
);

/*
    This function filters and sorts the events to be displayed
    in the "notifications history" collapsible panel.
*/
export const makeGetEventsOnList = (trackerId: number) =>
    createSelector(
        getHistoryModel,
        getIsDateToday,
        getTodayPeriodValue,
        (model, isToday, timePeriod) => {
            const events = model.eventsByTrackerId[trackerId];

            if (!events) {
                return [];
            }

            const filteredEvents: ITrackerEvent[] = events
                // Get only matching events
                .filter(filterEventsOnList)
                // Handle filtering by current date (today) controls
                .filter((event) =>
                    filterByDate(event, isToday, model, timePeriod),
                );

            // Sort the remaining events according to their `date_server`
            // field, so they appear in reverse chronological order in the UI.
            const sortedEvents: ITrackerEvent[] = filteredEvents.sort((a, b) =>
                a.date_server < b.date_server ? 1 : -1,
            );

            return sortedEvents;
        },
    );

export const makeGetSosEvents = (trackerId: number) => {
    const getListEvents = makeGetEventsOnMap(trackerId);

    return createSelector(getListEvents, (events) =>
        events.filter((e) => e.type === 'ALM-A'),
    );
};

export const getSelectedDate = createSelector(
    getHistoryModel,
    (model) => model.selectedDate,
);

export const getIsPlayingRunning = createSelector(
    getPlayingModel,
    (model) => model.running,
);

export const getIsPaused = createSelector(
    getPlayingModel,
    (model) => model.paused,
);

export const getCurrentlyPlayingIndex = createSelector(
    getPlayingModel,
    (model) => model.currentEventIndex,
);

/**
 * Show n last elements of tracker's "path"
 */
export const makeGetActivityPlayingItems = (trackerId: number) => {
    const getEvents = makeGetEventsOnMap(trackerId);

    return createSelector(
        getEvents,
        getCurrentlyPlayingIndex,
        (events, index) => {
            let eventsToReturn;
            /**
             * First show one-by-one
             */

            if (index < numberOfElementsToShow) {
                eventsToReturn = events.slice(0, index);
            } else {
                /**
                 * Then show always fixed number
                 */
                eventsToReturn = events.slice(
                    index - numberOfElementsToShow,
                    index,
                );
            }

            return eventsToReturn;
        },
    );
};

/**
 * Return last position fetched for one tracker since the app has started.
 */
export const makeGetActivityLastFetchedEvents = (trackerId: number) => {
    return createSelector(newPositions, (listOfNewPos) => {
        let eventsToReturn: ITrackerEvent[] = [];

        const trackerNewPositions = listOfNewPos.find((newPosition) => {
            return newPosition.trackerId === trackerId;
        });

        if (trackerNewPositions) {
            eventsToReturn = trackerNewPositions.positions;
        }

        return eventsToReturn;
    });
};

export const getCurrentlyPlayedEventTime = (trackerId: number) => {
    const getEvents = makeGetEventsOnMap(trackerId);

    return createSelector(
        getEvents,
        getCurrentlyPlayingIndex,
        (events, index) => {
            const event = events[index];

            if (!event || !event.date_tracker) {
                return '-';
            }

            return moment(event.date_tracker).format('H:mm');
        },
    );
};

export const getTrackerDistanceSpeedActivityParams = createSelector(
    getHistoryModel,
    (model): ITrackerSpeedDistanceActivity | null => {
        if (!model.metaParams) {
            return null;
        }

        return {
            averageSpeed: model.metaParams.averageSpeed,
            maxSpeed: model.metaParams.maxSpeed,
            totalDistance: model.metaParams.totalDistance,
            distanceMetric: model.metaParams.distanceMetric,
            metricSystem: model.metaParams.metricSystem,
        };
    },
);
