import { ModelConfig } from '@rematch/core';
import { set } from 'lodash';
import { ITracker } from '../trackers/interfaces-api';
import { makeGetAllTrackersIds } from '../trackers/selectors/get-all-tracker-ids';
import { IGeofence } from './interfaces';
import { IRawGeofence } from './interfaces-api';
import { mapClientGeofenceToApiDTO } from './mappers/client-to-dto';
import { makeGetAllUserGeofences } from './selectors/geofences.selectors';
import {
    createGeofence,
    deleteGeofence,
    IGeofenceDTO,
    updateGeofence,
} from './services/geofences.service';

export interface IGeofencesStore {
    userGeofencesByTrackerId: {
        [trackerId: number]: {
            [geofenceId: number]: IRawGeofence;
        };
    };
    /**
     * Store information if geofences have been at least once fetched.
     * This is because not fetched data AND no geofences created will have
     * the same model
     */
    dataFetchedByTrackerId: {
        [trackerId: number]: boolean;
    };
}

/**
 * Model storing existing user geofences
 */
export const geofencesStore: ModelConfig<IGeofencesStore> = {
    state: {
        userGeofencesByTrackerId: {},
        dataFetchedByTrackerId: {},
    },
    effects: (dispatch: any) => ({
        async saveGeofences(trackerId: number, state: any) {
            this._setDataFetchedForTracker(trackerId);
            const { rawTrackers } = state.userTrackers;
            const trackerInfo = rawTrackers.find(
                (tracker: ITracker) => tracker.id === trackerId,
            );

            if (trackerInfo.id === trackerId) {
                return this._setGeofencesToTracker({
                    trackerId,
                    geofences: trackerInfo.zones,
                });
            }
        },
        async fetchAllGeofences(payload: any, models) {
            const getTrackersIds = makeGetAllTrackersIds();
            const trackersIds: number[] = getTrackersIds(models);

            return Promise.all(
                trackersIds.map((id) => dispatch.geofences.saveGeofences(id)),
            );
        },
        async createGeofence({
            trackerId,
            geofenceDTO,
        }: {
            trackerId: number;
            geofenceDTO: IGeofenceDTO;
        }): Promise<any> {
            return createGeofence(trackerId, geofenceDTO).then(
                (resp: IRawGeofence) => {
                    this._setGeofence({
                        trackerId,
                        geofence: resp,
                    });
                    dispatch.userTrackers.updateGeofence({
                        trackerId,
                        method: 'add',
                        geofence: resp,
                    });

                    return resp;
                },
            );
        },
        async updateEditableGeofencePosition(position: {
            lat: number;
            lng: number;
        }) {
            this._updateEditableGeofencePosition(position);
        },
        async updateGeofenceActiveState(geofenceId: number, models: any) {
            const getAllGeofences = makeGetAllUserGeofences();
            const geofences: IGeofence[] = getAllGeofences(models);
            const geofence = geofences.find((g) => g.geofenceId === geofenceId);
            if (!geofence) {
                return;
            }

            const geofenceToEdit: IGeofence = {
                ...geofence,
                active: !geofence.active,
            };

            return updateGeofence(
                geofenceToEdit.trackerId,
                geofenceId,
                mapClientGeofenceToApiDTO(geofenceToEdit),
            ).then((resp) => {
                this._setGeofence({
                    trackerId: geofenceToEdit.trackerId,
                    geofence: resp,
                });
            });
        },
        async deleteGeofence(geofenceId: number, models: any) {
            const getAllGeofences = makeGetAllUserGeofences();
            const geofences: IGeofence[] = getAllGeofences(models);
            const rawTrackers = models.userTrackers.rawTrackers;
            const { userTrackers } = models;
            const geofence = geofences.find((g) => g.geofenceId === geofenceId);
            if (!geofence) {
                return;
            }
            return deleteGeofence(geofence.trackerId, geofenceId).then(
                (resp) => {
                    dispatch.geofenceEdit.cancelCreatingNewGeofence();
                    rawTrackers.map((tracker: ITracker) => {
                        if (tracker.id === userTrackers.activeTracker) {
                            if (tracker.zones !== null) {
                                tracker.zones.map((zone) => {
                                    if (zone.id === geofenceId) {
                                        dispatch.userTrackers.updateGeofence({
                                            trackerId: tracker.id,
                                            method: 'remove',
                                            geofence: zone,
                                        });
                                    }
                                });
                            }
                            dispatch.geofences.saveGeofences(tracker.id);
                        }
                    });
                },
            );
        },
    }),
    reducers: {
        _toggleGeofenceActiveState: (state, payload: boolean) => ({
            ...state,
            newGeofenceActive: payload,
        }),
        _setGeofencesToTracker: (
            state: IGeofencesStore,
            {
                trackerId,
                geofences,
            }: { trackerId: number; geofences: IRawGeofence[] },
        ): IGeofencesStore => {
            const newState = { ...state };
            newState.userGeofencesByTrackerId[trackerId] = {};

            geofences.forEach((geofence) => {
                set(
                    newState,
                    `userGeofencesByTrackerId[${trackerId}][${geofence.id}]`,
                    geofence,
                );
            });

            return newState;
        },
        _setGeofence: (
            state: IGeofencesStore,
            {
                trackerId,
                geofence,
            }: { trackerId: number; geofence: IRawGeofence },
        ) => {
            const newState = { ...state };

            if (!newState.userGeofencesByTrackerId[trackerId]) {
                newState.userGeofencesByTrackerId[trackerId] = {};
            }

            set(
                newState,
                `userGeofencesByTrackerId[${trackerId}][${geofence.id}]`,
                geofence,
            );

            return newState;
        },
        _updateEditableGeofencePosition: (state, position) => ({
            ...state,
            editableGeofencePosition: position,
        }),
        _setDataFetchedForTracker: (state, trackerId: number) => {
            const newState = { ...state };
            newState.dataFetchedByTrackerId[trackerId] = true;

            return newState;
        },
    },
};
