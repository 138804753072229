export enum ICancelReason {
    NO_USE = 'NO_USE',
    LOST_BROKEN = 'LOST_BROKEN',
    NOT_SATISFIED = 'NOT_SATISFIED',
    TOO_EXPENSIVE = 'TOO_EXPENSIVE',
    WILL_REACTIVATE_LATER = 'WILL_REACTIVATE_LATER',
    OTHER = 'OTHER',
}

export enum IOtherCancelReason {
    BAD_PRECISION = 'BAD_PRECISION',
    BATTERY = 'BATTERY',
    AFTER_SALES_SERVICE = 'AFTER_SALES_SERVICE',
    TRACKER_FORMAT = 'TRACKER_FORMAT',
    MOBILE_APPLICATION = 'MOBILE_APPLICATION',
}

export const otherCancelReasonTranslation = new Map<IOtherCancelReason, string>(
    [
        [
            IOtherCancelReason.BAD_PRECISION,
            'trackerLeftPanel:POOR_GPS_ACCURACY',
        ],
        [
            IOtherCancelReason.BATTERY,
            'manageSubscription:CANCEL.REASONS.BATTERY',
        ],
        [
            IOtherCancelReason.AFTER_SALES_SERVICE,
            'manageSubscription:CANCEL.REASONS.AFTER_SALES_SERVICE',
        ],
        [
            IOtherCancelReason.TRACKER_FORMAT,
            'manageSubscription:CANCEL.REASONS.TRACKER_FORMAT',
        ],
        [
            IOtherCancelReason.MOBILE_APPLICATION,
            'manageSubscription:CANCEL.REASONS.MOBILE_APPLICATION',
        ],
    ],
);

export const cancelReasonTranslation = new Map<ICancelReason, string>([
    [ICancelReason.NO_USE, 'manageSubscription:CANCEL.REASONS.NO_USE'],
    [
        ICancelReason.LOST_BROKEN,
        'manageSubscription:CANCEL.REASONS.LOST_BROKEN',
    ],
    [
        ICancelReason.NOT_SATISFIED,
        'manageSubscription:CANCEL.REASONS.NOT_SATISFIED',
    ],
    [
        ICancelReason.TOO_EXPENSIVE,
        'manageSubscription:CANCEL.REASONS.TOO_EXPENSIVE',
    ],
    [
        ICancelReason.WILL_REACTIVATE_LATER,
        'manageSubscription:CANCEL.REASONS.WILL_REACTIVATE_LATER',
    ],
    [ICancelReason.OTHER, 'support:PROBLEM_TYPES.OTHER'],
]);
