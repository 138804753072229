import { apiService } from '../../api/api.service';

/**
 * Fetch available variants - array of plans with options and payments included
 */
export const fetchSubscriptionCanChangePlan = (subscriptionId: number) => {
    const url = `/mysubscription/${subscriptionId}/change-plan`;

    return apiService.fetch(url).then((resp) => {
        if (resp.status >= 200 && resp.status < 400) {
            return resp;
        }
        throw resp;
    });
};

export const postSubscriptionChangePlan = (
    subscriptionId: number,
    userSite: string,
    newCode: string,
) => {
    const url = `/mysubscription/${subscriptionId}/change-plan`;
    return apiService
        .fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                site: userSite,
                code: newCode,
            }),
        })
        .then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                return resp;
            }
            throw resp;
        })
        .catch((resp) => {
            throw resp;
        });
};
