import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Headline } from '../../common/headline/headline.component';
import { Dispatch, IRootState } from '../../store/store';
import { makeGetSelectedTrackerPosition } from '../../trackers/selectors/get-selected-tracker-position';
import {
    ControlledEditGeofenceForm,
    IFormValues,
    IWithLatLng,
} from '../edit-geofence-form/edit-geofence-form.component';
import { IGeofence } from '../interfaces';
import { mapClientGeofenceToApiDTO } from '../mappers/client-to-dto';
import { makeGetEditableGeofencePosition } from '../selectors/geofences-edit.selectors';
import { getReverseGeocode } from '../services/geofences.service';

import styles from './create-geofence.module.scss';

export interface IStateProps {
    geofenceDuringNewCreating: boolean;
    lat: number;
    lng: number;
    trackerPosition: {
        lat: number;
        lng: number;
    } | null;
}

export interface IActions {
    onTrackerPositionTriggered: () => any;
    onSave: (values: IFormValues & IWithLatLng) => any;
    onCancel: () => any;
    onRadiusChanged: (newRadius: number) => any;
}

export interface IOuterProps extends HTMLAttributes<HTMLFormElement> {
    trackerId: number;
}

export interface IProps extends IStateProps, IOuterProps, IActions, IGeofence {}

export const CreateGeofence = ({
    className,
    geofenceDuringNewCreating,
    lat,
    lng,
    onCancel,
    onRadiusChanged,
    onSave,
    onTrackerPositionTriggered,
    trackerPosition,
}: IProps) => {
    const { t } = useTranslation('geofences');

    return (
        <div className={cx(styles.container, className)}>
            <div className={styles['headline-section']}>
                <Headline level={4}>{t('CREATE.HEADLINE')}</Headline>
            </div>
            <ControlledEditGeofenceForm
                canBeDeleted={false}
                className={styles.content}
                geofenceDuringNewCreating={geofenceDuringNewCreating}
                lat={lat}
                lng={lng}
                onCancel={onCancel}
                onRadiusChanged={onRadiusChanged}
                onSave={onSave}
                onTrackerPositionTriggered={onTrackerPositionTriggered}
                trackerPositionEnabled={Boolean(trackerPosition)}
            />
        </div>
    );
};

const makeMapState = (state: IRootState): IStateProps => {
    const getEditableGeofencePosition = makeGetEditableGeofencePosition();
    const getTrackerPosition = makeGetSelectedTrackerPosition();

    const position = getEditableGeofencePosition(state);

    return {
        geofenceDuringNewCreating: state.geofenceEdit.duringNewCreating,
        lat: position.lat,
        lng: position.lng,
        trackerPosition: getTrackerPosition(state),
    };
};

const mapDispatch = (dispatch: Dispatch, props: IProps): IActions => ({
    onTrackerPositionTriggered: dispatch.geofenceEdit.centerOnActiveTracker,
    onCancel: () => dispatch.geofenceEdit.cancelCreatingNewGeofence(),
    onSave: async (values: IFormValues & IWithLatLng) => {
        const geocodedAddress = await getReverseGeocode(values.lat, values.lng);

        const dto = mapClientGeofenceToApiDTO({
            exitNotification: values.exitNotification,
            entranceNotification: values.entranceNotification,
            name: values.name,
            position: {
                lat: values.lat,
                lng: values.lng,
            },
            address: geocodedAddress,
            outdoors: values.outdoors,
            radius: values.radius,
            active: true,
        });

        dispatch.geofences
            .createGeofence({
                trackerId: props.trackerId,
                geofenceDTO: dto,
            })
            // @ts-ignore
            .then(() => {
                dispatch.geofenceEdit.cancelCreatingNewGeofence(false);
            });
    },
    onRadiusChanged: dispatch.geofenceEdit.updateRadius,
});

export const ConnectedCreateGeofence = compose<IProps, IOuterProps>(
    connect(makeMapState, mapDispatch),
)(CreateGeofence);
