import { ModelConfig } from '@rematch/core';
import { createSelector } from 'reselect';
import { updateAppLanguage } from '../../i18n/setup-translations';
import { IRootState } from '../store/store';
import { getUserCountryCode } from '../user/get-user-country';
import { getLanguageFromCountryCode } from './get-language-from-country-code';

interface IModelStore {
    /**
     * Language get from geolocation, injected by server
     */
    geoLanguage: string;
    /**
     * Language selected by user, if not logged (login page, signup page).
     * In case of logged user, this will be omitted and user settings will be used
     */
    selectedLanguage: string;
    /**
     * Flags if was detected at least once.
     */
    detected: boolean;
}

export const languageStore: ModelConfig<IModelStore> = {
    state: {
        geoLanguage: 'en',
        selectedLanguage: navigator.language.substring(0, 2),
        detected: false,
    },
    reducers: {
        _updateSelectedLanguage: (state, lng) => ({
            ...state,
            selectedLanguage: lng,
        }),
        _updateGeoLanguage: (state, lng) => ({
            ...state,
            geoLanguage: lng,
            detected: true,
        }),
        _updateAllLanguages: (state, lng) => ({
            ...state,
            geoLanguage: lng,
            selectedLanguage: lng,
            detected: true,
        }),
    },
    effects: (dispatch) => ({
        /**
         * Changes app language and updates local state of selects
         */
        async updateLanguage({ languageCode }: { languageCode: string }) {
            updateAppLanguage(languageCode).then(() => {
                this._updateSelectedLanguage(languageCode);
            });
        },
        /**
         * Uses server provided country code to get language.
         * Set geo language immutable values and initial value of select in login & signup page
         */
        async detectLanguageFromServer() {
            const country = getUserCountryCode();
            const lang = getLanguageFromCountryCode(
                country || navigator.language.substring(0, 2),
            );
            updateAppLanguage(lang).then(() => {
                this._updateSelectedLanguage(lang);
            });
        },
    }),
};

const getLanguageStore = (state: IRootState) => state.language;

export const getSelectedLanguage = createSelector(
    getLanguageStore,
    (state) => state.selectedLanguage,
);
