import { createSelector } from 'reselect';
import { IRootState } from '../store/store';
import { IMapStore } from './map.store';

const mapModel = (state: IRootState): IMapStore => state.map;

export const makeGetMapViewportCenter = () => {
    return createSelector(
        mapModel,
        (model: IMapStore) => {
            return model.center;
        },
    );
};
