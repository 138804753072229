import React from 'react';
import { withProps } from 'recompose';

import { Button, IButtonProps } from '../../common/button/button.component';

import { ReactComponent as PauseIcon } from '../../icons/pause.svg';
import { ReactComponent as PlayIcon } from '../../icons/play.svg';
import { ReactComponent as StopIcon } from '../../icons/stop.svg';

export const StopPlayingButton = withProps<IButtonProps, IButtonProps>({
    small: true,
    noWrap: true,
    iconLeft: <StopIcon />,
})(Button);

export const StartPlayingButton = withProps<IButtonProps, IButtonProps>({
    small: true,
    noWrap: true,
    iconLeft: <PlayIcon />,
})(Button);

export const ResumePlayingButton = withProps<IButtonProps, IButtonProps>({
    small: true,
    children: <PlayIcon />,
    secondary: true,
    round: true,
})(Button);

export const PausePlayingButton = withProps<IButtonProps, IButtonProps>({
    small: true,
    children: <PauseIcon />,
    secondary: true,
    round: true,
})(Button);
