import cx from 'classnames';
import React, { HTMLAttributes } from 'react';

import { getGlobalT } from '../../../../i18n/setup-translations';
import { IAccuracyData } from '../../interfaces';
import { TrackerAccuracy } from '../tracker-accuracy/tracker-accuracy.component';

import styles from './tracker-details.module.scss';

/**
 * Take formatted address and date
 */
export interface ITrackerDetailsProps extends HTMLAttributes<HTMLDivElement> {
    lastPositionAddress: string;
    lastSeenTime: string;
    deepSleep: boolean;
    isInWifiZone: number;
    accuracyData: IAccuracyData | null;
}

export const TrackerDetails = ({
    lastPositionAddress,
    lastSeenTime,
    isInWifiZone,
    deepSleep,
    className,
    accuracyData,
    ...props
}: ITrackerDetailsProps) => {
    const t = getGlobalT();
    const maybeRenderAccuracy = () => {
        if (accuracyData) {
            return (
                <span className={styles.attribute}>
                    <TrackerAccuracy
                        accuracyFormatted={accuracyData.accuracyDescriptive}
                        accuracy={accuracyData.accuracyRadius}
                    />
                </span>
            );
        }
    };

    return (
        <div className={cx(styles['tracker-details'], className)} {...props}>
            <span className={styles.label}>
                {t('trackerLeftPanel:LAST_POSITION')}
            </span>
            <span className={styles.attribute}>
                {' '}
                {isInWifiZone !== 0 && typeof isInWifiZone === 'number'
                    ? `${t('wifiZones:LAST_POS_IN_WIFI', {
                          date: lastSeenTime,
                      })}`
                    : lastSeenTime}{' '}
                {deepSleep ? t('wifiZones:IN_AIRPLANE') : null}{' '}
            </span>
            {maybeRenderAccuracy()}
        </div>
    );
};
