import React from 'react';
import {
    GetHandleProps,
    Handles,
    Rail,
    Slider as RCSlider,
    SliderItem,
    Tracks,
} from 'react-compound-slider';

import styles from './progress.module.scss';

interface IProps {
    value: number;
    max: number; // Represent the amount of positions retrieved from API
    onChange(values: [number]): unknown;
}

function SliderRail({ getRailProps }: any) {
    return <div className={styles.rail} {...getRailProps()} />;
}
const Handle = ({
    handle: { id, value, percent },
    getHandleProps,
}: {
    handle: SliderItem;
    getHandleProps: GetHandleProps;
}) => {
    const { onKeyDown, onMouseDown, onTouchStart } = getHandleProps(id);

    return (
        <div
            style={{
                left: `${percent}%`,
            }}
            className={styles.handle}
            onKeyDown={onKeyDown}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
        />
    );
};
function Track({ source, target, getTrackProps, disabled }: any) {
    return (
        <div
            className={styles.track}
            style={{
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()}
        />
    );
}

export const Progress = ({ value, max, onChange }: IProps) => (
    <RCSlider
        className={styles.slider}
        mode={1}
        values={[value]}
        domain={[0, max]}
        step={1}
        onUpdate={(v) => onChange(v as any)}
    >
        <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>

        <Handles>
            {({ handles, getHandleProps }) => (
                <React.Fragment>
                    {handles.map((handle) => (
                        <Handle
                            key={handle.id}
                            handle={handle}
                            getHandleProps={getHandleProps}
                        />
                    ))}
                </React.Fragment>
            )}
        </Handles>

        <Tracks left={true} right={false}>
            {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                    {tracks.map(({ id, source, target }) => (
                        <Track
                            key={id}
                            source={source}
                            target={target}
                            getTrackProps={getTrackProps}
                        />
                    ))}
                </div>
            )}
        </Tracks>
    </RCSlider>
);
