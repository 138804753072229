import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { compose } from 'recompose';

import { getGlobalT } from '../../../i18n/setup-translations';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../utils/with-selected-tracker';
import { BackLink } from '../../common/back-link/back-link.component';
import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { Input } from '../../common/input/input.component';
import { Typography } from '../../common/typography/typography.component';
import { PageContainer } from '../../layout/page-container/page-container.component';
import { SimpleHeaderWithLogout } from '../../layout/simple-header/simple-header.component';
import { notCancelableSubscriptionGuard } from '../../manage-subscription/guards/not-cancelable-subscription-guard';
import { withActiveTrackerResolving } from '../../map/resolvers/active-tracker-resolver';
import { Dispatch, IRootState } from '../../store/store';
import { Theme } from '../../trackers/interfaces';
import { ITracker } from '../../trackers/interfaces-api';
import { makeGetTrackerModel } from '../../trackers/selectors/get-tracker-model';
import {
    cancelReasonTranslation,
    ICancelReason,
    IOtherCancelReason,
    otherCancelReasonTranslation,
} from '../interfaces';
import styles from './cancel-subscription-page.module.scss';

interface IActions {
    doCancelSubscription: (
        subscriptionId: number,
        cancelReason?: string,
        cancelExplanation?: string,
    ) => Promise<any>;
    doUpdateCancelReason: (
        cancelReason: ICancelReason | IOtherCancelReason | null,
    ) => Promise<any>;
    doUpdateCancelExplanation: (cancelExplanation: string) => Promise<any>;
    clearNotifications: () => unknown;
}

interface IStateProps {
    currentTracker: ITracker;
    activeTheme: Theme | null;
    currentCancelReason: ICancelReason | IOtherCancelReason | null;
    currentCancelExplanation: string;
    isMobileRedirect: boolean;
}

interface IOuterProps {
    id: string;
}

interface ICancelSubscriptionPageProps
    extends IOuterProps,
    IStateProps,
    IActions,
    IWithSelectedTracker { }
export const CancelSubscriptionPage = ({
    currentTracker,
    currentCancelReason,
    currentCancelExplanation,
    isMobileRedirect,
    clearNotifications,
    doCancelSubscription,
    activeTheme,
    doUpdateCancelReason,
    doUpdateCancelExplanation,
}: ICancelSubscriptionPageProps) => {
    const commonT = useTranslation('commonActions').t;
    const manageSubscriptionT = useTranslation('manageSubscription').t;
    const t = getGlobalT();
    const match = useLocation()
    const history = useNavigate()
    // State var which updates when user clicks on the 'next' button having cancelReasonSelected == ICancelReason.OTH
    const [stepToShow, setStepToShow] = useState(0);

    const getTrackersLink = () => match.pathname.replace('cancel', 'manage');
    const getSubscriptionLink = () =>
        match.pathname.replace('cancel', 'subscription');
    const onGoBack = () => {
        // Always reset cancel reason and cancel explanation

        const promiseList = [
            doUpdateCancelExplanation(''),
            doUpdateCancelReason(null),
        ];
        Promise.all(promiseList).then(() => {
            if (stepToShow !== 0) {
                return setStepToShow(0);
            }
            history(getTrackersLink());
        });
    };

    const onGoNext = () => {
        if (!currentCancelReason || !currentTracker.subscription) {
            return;
        }
        if (currentCancelReason === ICancelReason.NOT_SATISFIED) {
            return setStepToShow(1);
        }
        if (currentCancelReason === ICancelReason.OTHER && stepToShow !== 2) {
            return setStepToShow(2);
        }
        return doCancelSubscription(
            currentTracker.subscription.id,
            currentCancelReason,
            currentCancelExplanation,
        ).then(() => {
            activeTheme === Theme.BIOGARAN
                ? history(getTrackersLink())
                : history(getSubscriptionLink());
        });
    };

    const getNextText = () => {
        if (
            (currentCancelReason === ICancelReason.OTHER && stepToShow !== 2) ||
            (currentCancelReason === ICancelReason.NOT_SATISFIED &&
                stepToShow !== 1)
        ) {
            return commonT('NEXT');
        }
        return manageSubscriptionT('CANCEL_RENEWAL_BIS');
    };

    const shouldDisableSubmit = () => {
        if (
            (currentCancelReason === ICancelReason.OTHER &&
                stepToShow === 2 &&
                currentCancelExplanation === '') ||
            (currentCancelReason === ICancelReason.NOT_SATISFIED &&
                stepToShow === 1) ||
            !currentCancelReason
        ) {
            return true;
        }
        return false;
    };

    const maybeRenderReasons = () => {
        if (stepToShow === 0) {
            return (
                <React.Fragment>
                    <Typography
                        className={cx(styles['form-title'], {
                            [styles.highlighted]: !currentCancelReason,
                        })}
                    >
                        {manageSubscriptionT('CANCEL.WHY_DO_YOU_RESIGN')}
                    </Typography>

                    <div className={styles.reasons}>
                        {Object.entries(ICancelReason).map((key) => {
                            if (
                                key[0] === 'NOT_SATISFIED' &&
                                activeTheme === Theme.BIOGARAN
                            ) {
                                return (
                                    <Button
                                        onClick={() =>
                                            doUpdateCancelReason(key[1])
                                        }
                                        primary={key[1] === currentCancelReason}
                                        secondary={
                                            key[1] !== currentCancelReason
                                        }
                                        key={key[1]}
                                        outlined
                                        className={styles.button}
                                    >
                                        Le service Bioggy ne répond pas à mes
                                        attentes
                                    </Button>
                                );
                            }
                            return (
                                <Button
                                    onClick={() => doUpdateCancelReason(key[1])}
                                    primary={key[1] === currentCancelReason}
                                    secondary={key[1] !== currentCancelReason}
                                    key={key[1]}
                                    outlined
                                    className={styles.button}
                                >
                                    {t(
                                        cancelReasonTranslation.get(key[1]) ||
                                        'manageSubscription:CANCEL.REASONS.OTHER', // Fallback
                                    )}
                                </Button>
                            );
                        })}
                    </div>
                </React.Fragment>
            );
        }
    };
    const maybeRenderOtherReasons = () => {
        if (stepToShow === 1) {
            return (
                <React.Fragment>
                    <Typography className={styles['form-title']}>
                        {manageSubscriptionT('CANCEL.DESCRIBE_YOUR_PROBLEMS')}
                    </Typography>

                    <div className={styles.reasons}>
                        {Object.entries(IOtherCancelReason).map((key) => {
                            return (
                                <Button
                                    onClick={() => doUpdateCancelReason(key[1])}
                                    primary={key[1] === currentCancelReason}
                                    secondary={key[1] !== currentCancelReason}
                                    key={key[1]}
                                    outlined
                                    className={styles.button}
                                >
                                    {t(
                                        otherCancelReasonTranslation.get(
                                            key[1],
                                        ) ||
                                        'manageSubscription:CANCEL.REASONS.OTHER', // Fallback
                                    )}
                                </Button>
                            );
                        })}
                    </div>
                </React.Fragment>
            );
        }
    };

    const maybeRenderReasonInput = () => {
        if (stepToShow === 2) {
            return (
                <React.Fragment>
                    <Typography className={styles['form-title']}>
                        {manageSubscriptionT('CANCEL.DESCRIBE_YOUR_PROBLEMS')}
                    </Typography>
                    <Input
                        textArea
                        placeholder={manageSubscriptionT(
                            'CANCEL.GIVE_US_DETAILS',
                        )}
                        nativeFieldClass={styles.textarea}
                        onChange={onExplanationUpdate}
                    />
                </React.Fragment>
            );
        }
    };

    const onExplanationUpdate = (event: React.FormEvent<HTMLInputElement>) => {
        const userInput = event.currentTarget.value;
        return doUpdateCancelExplanation(userInput);
    };

    return (
        <div>
            {
                isMobileRedirect ? null : (
                    <SimpleHeaderWithLogout
                        theme={activeTheme}
                        leftSlot={
                            <BackLink onClick={onGoBack} black>
                                {commonT('BACK')}
                            </BackLink>
                        }
                    />
                )
            }
            <PageContainer>
                <Headline className={styles.headline} center>
                    {manageSubscriptionT('CANCEL_RENEWAL_BIS')}
                </Headline>
                <Typography>
                    {manageSubscriptionT('CANCEL.SAD_TO_SEE_YOU_LEAVE')}
                </Typography>

                {maybeRenderReasons()}
                {maybeRenderOtherReasons()}
                {maybeRenderReasonInput()}

                <div className={styles['footer-buttons']}>
                    <Button onClick={onGoBack} secondary outlined curvy>
                        {commonT('BACK')}
                    </Button>
                    <Button
                        onClick={onGoNext}
                        disabled={shouldDisableSubmit()}
                        className={styles['submit-button']}
                        outlined
                        curvy
                    >
                        {getNextText()}
                    </Button>
                </div>
            </PageContainer>
        </div>
    );

};

const makeMapState = (
    state: IRootState,
    props: IWithSelectedTracker,
): IStateProps => {
    const getTrackerDetails = makeGetTrackerModel(props.trackerId!);
    const tracker: ITracker = getTrackerDetails(state) as ITracker;
    return {
        currentTracker: tracker,
        currentCancelReason: state.cancelSubscription.cancelReason,
        currentCancelExplanation: state.cancelSubscription.cancelExplanation,
        activeTheme: state.userTrackers.theme,
        isMobileRedirect: state.layout.isMobileRedirect,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    doCancelSubscription: async (
        subscriptionId: number,
        cancelReason?: string,
        cancelExplanation?: string,
    ) =>
        dispatch.userSubscriptions.cancelSubscription({
            subscriptionId,
            cancelReason,
            cancelExplanation,
        }),
    doUpdateCancelReason: async (
        cancelReason: ICancelReason | IOtherCancelReason,
    ) => dispatch.cancelSubscription.updateCancelReason(cancelReason),
    doUpdateCancelExplanation: async (cancelExplanation: string) =>
        dispatch.cancelSubscription.updateCancelExplanation(cancelExplanation),
    clearNotifications: dispatch.notifications.clearNotifications,
});

export const ConnectedCancelSubscriptionPage = compose(
    withSelectedTracker,
    withActiveTrackerResolving,
    connect(makeMapState, mapDispatch),
    notCancelableSubscriptionGuard,
)(CancelSubscriptionPage);
