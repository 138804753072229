import { ModelConfig } from '@rematch/core';

interface IUserLocationStore {
    detected: boolean;
    lat: number | null;
    lng: number | null;
}

export const userLocationStore: ModelConfig<IUserLocationStore> = {
    state: {
        detected: false,
        lat: null,
        lng: null,
    },
    reducers: {
        setLatLng: (state, payload: { lat: number; lng: number }) => ({
            ...state,
            lat: payload.lat,
            lng: payload.lng,
            detected: true,
        }),
    },
    effects: (dispatch) => ({
        checkLocation() {
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    this.setLatLng({
                        lat: pos.coords.latitude,
                        lng: pos.coords.longitude,
                    });

                    navigator.geolocation.watchPosition((watchedPos) => {
                        this.setLatLng({
                            lat: watchedPos.coords.latitude,
                            lng: watchedPos.coords.longitude,
                        });
                    });
                },
                (err) => {
                    console.error(err);
                },
                {
                    enableHighAccuracy: true,
                },
            );
        },
    }),
};
