import { ITrackerOnMap, TrackerType } from '../interfaces';
import { ITracker } from '../interfaces-api';
import { resolveTrackerColor, TrackerIcon } from '../tracker-icons';
import { hasActiveGPS, hasPosition } from './tracker-mappers-utils';

export const mapTrackersToMap = (trackers: ITracker[]): ITrackerOnMap[] =>
    trackers.filter(hasPosition).map((tracker) => {
        const position = tracker.position[0];

        return {
            id: tracker.id,
            color: resolveTrackerColor(tracker.color, tracker.type),
            position: [position.latitude, position.longitude],
            name: tracker.name,
            radius: position.radius,
            icon: tracker.icon as TrackerIcon,
            urlPicture: tracker.url_picture as string,
            type: tracker.type as TrackerType,
            validGps: hasActiveGPS(tracker),
        };
    });
