import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { config } from '../../../../config';
import { Headline } from '../../../common/headline/headline.component';
import { Link } from '../../../common/link/link.component';
import { Modal } from '../../../common/modal/modal.component';
import { PageContainer } from '../../../layout/page-container/page-container.component';
import { ImeiInfoModal } from '../../../trackers/components/imei-info-modal/imei-info-modal.component';
import {
    ControlledRegisterForm,
    IFormValues,
} from '../../components/register-tracker-form/register-tracker-form.component';
import styles from './register-tracker-step.module.scss';

export interface IRegisterTrackerOuterProps {
    onSubmit: (values: IFormValues) => any | Promise<any>;
}

export interface IRegisterTrackerStepProps
    extends IRegisterTrackerOuterProps { }

export const RegisterTrackerStep = ({
    onSubmit,
}: IRegisterTrackerStepProps) => {
    const [imeiModalOpen, setImeiModalOpen] = useState(false);
    const { t } = useTranslation('registerTracker');

    const onImeiInfoClicked = () => {
        setImeiModalOpen(true);
    };

    const maybeRenderImeiModal = () => {
        if (imeiModalOpen) {
            return (
                <Modal onClosed={() => setImeiModalOpen(false)}>
                    <ImeiInfoModal
                        onAcceptClicked={() => setImeiModalOpen(false)}
                    />
                </Modal>
            );
        }
    };

    return (
        <PageContainer className={styles.container}>
            <div className={styles['form-container']}>
                <Headline className={styles.headline} center>
                    {t('HEADLINE')}
                </Headline>
                <ControlledRegisterForm
                    onImeiInfoClick={onImeiInfoClicked}
                    onSubmit={onSubmit}
                />
                <p className={styles.subtext}>
                    <Trans i18nKey="registerTracker:BUY_LINK">
                        Don't have a Weenect Tracker yet?
                        <Link href={config.PRODUCT_PAGE_DEFAULT}>
                            Here you can buy one
                        </Link>
                    </Trans>
                </p>
                {maybeRenderImeiModal()}
            </div>
        </PageContainer>
    );
};
