import { apiService } from '../api/api.service';
import { IEvolution, ISummaryData } from './interface';

const activitySummary = (trackerId: number, startDate: any, endDate: any) =>
    `/activity/${trackerId}/summary?start_date=${startDate}&end_date=${endDate}`;

const activityAggregate = (
    trackerId: number,
    startDate: any,
    endDate: any,
    periodUnit: any,
    periodValue: any,
    timezone: any,
) =>
    `/activity/${trackerId}/aggregate?start_date=${startDate}&end_date=${endDate}&period_unit=${periodUnit}&period_value=${periodValue}&timezone=${timezone}`;

export const getActivitySummaryApi = async (
    startDate: string,
    endDate: string,
    trackerId: number,
): Promise<ISummaryData> => {
    try {
        const response = await apiService.fetch(
            activitySummary(trackerId, startDate, endDate),
            {
                method: 'GET',
            },
            {
                mapHeaders: (headers) => {
                    headers.delete('Content-Type');
                    return headers;
                },
            },
        );
        if (response.status !== 200) {
            throw Error('Error');
        }
        return response.json();
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getEvolution = async (
    startDate: string,
    endDate: string,
    trackerId: number,
): Promise<IEvolution> => {
    try {
        const response = await apiService.fetch(
            `/activity/${trackerId}/evolution?start_date=${startDate}&end_date=${endDate}`,
            {
                method: 'GET',
            },
            {
                mapHeaders: (headers) => {
                    headers.delete('Content-Type');
                    return headers;
                },
            },
        );
        if (response.status !== 200) {
            throw Error('Error');
        }
        return response.json();
    } catch (err) {
        return Promise.reject(err);
    }
};

export const postObjective = async (
    trackerId: number,
    objective: number[] | number,
): Promise<any> => {
    try {
        const response = await apiService.fetch(
            `/activity/${trackerId}/objective`,
            {
                method: 'POST',
                body: JSON.stringify({
                    objective,
                }),
            },
            {
                mapHeaders: (headers) => {
                    return headers;
                },
            },
        );
        if (response.status !== 204) {
            throw Error('Error');
        }
    } catch (err) {
        return Promise.reject(err);
    }
};
export const getActivityAgregateApi = async (
    startDate: string,
    endDate: string,
    trackerId: number,
    periodUnit: any,
    periodValue: any,
    timezone: any,
): Promise<ISummaryData> => {
    try {
        const response = await apiService.fetch(
            activityAggregate(
                trackerId,
                startDate,
                endDate,
                periodUnit,
                periodValue,
                timezone,
            ),
            {
                method: 'GET',
            },

            {
                mapHeaders: (headers) => {
                    headers.delete('Content-Type');
                    return headers;
                },
            },
        );
        if (response.status !== 200) {
            throw Error('Error');
        }
        return response.json();
    } catch (err) {
        return Promise.reject(err);
    }
};
