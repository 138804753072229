import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { compose } from 'recompose';

import { Grid } from '@material-ui/core';
import { Button } from '../../common/button/button.component';
import { Modal } from '../../common/modal/modal.component';
import { ReactComponent as DashIcon } from '../../icons/dash.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { INotification } from '../../notifications/interfaces';
import { Dispatch } from '../../store/store';
import styles from '../animal-info.module.scss';
import {
    ActivityText,
    AnimalFormStep,
    IFormValidationInterface,
    MonthTranslated,
} from '../interfaces';

interface IOuterProps {
    formValidation: IFormValidationInterface;
    setFormValidation: any;
    nextStep?: any;
    editMode?: boolean;
    animalInfo: any;
    handleSubmit?: any;
    handleRemove?: any;
}

interface IActions {
    showNotification(n: INotification): unknown;
}

interface IProps extends IOuterProps, IActions {}

const AnimalMorphText = [
    {
        text: 'PET_MORPH.LEAN',
        label: 'skinny',
    },
    {
        text: 'PET_MORPH.AVERAGE',
        label: 'standard',
    },
    {
        text: 'PET_MORPH.OVERWEIGHT',
        label: 'overweight',
    },
    {
        text: 'PET_MORPH.OBESE',
        label: 'obese',
    },
];

const SummaryComponentRaw = ({
    animalInfo,
    formValidation,
    nextStep,
    setFormValidation,
    editMode,
    handleSubmit,
    handleRemove,
    showNotification,
    ...props
}: IProps) => {
    const { t } = useTranslation([
        'activityOnboarding',
        'activityTracking',
        'activityGeneral',
        'commonActions',
    ]);
    const [modalProfileDelete, setModalProfileDelete] = useState<boolean>(
        false,
    );
    // if (formValidation.birth_date && typeof formValidation.birth_date === 'string') {
    //     setFormValidation((form: IFormValidationInterface) => ({
    //         ...form,
    //         birth_date: new Date(formValidation?.birth_date),
    //     }));
    // }
    const renderIcon = () => {
        if (editMode) {
            return <EditIcon className={styles.iconOrange} />;
        }
        return <DashIcon className={styles.dashForwardIcon} />;
    };
    return (
        <React.Fragment>
            <div className={editMode ? '' : styles.container}>
                <form onSubmit={() => handleSubmit()}>
                    <p className={styles.margin1}>
                        {editMode
                            ? t('SUMMARY.RECAPTEXT')
                            : t('SUMMARY.VALIDATION_TEXT')}
                    </p>
                    <div className={styles.collapse}>
                        <div
                            className={styles.collapseContainer}
                            onClick={() => {
                                nextStep(AnimalFormStep.TYPE_NAME);
                            }}
                        >
                            <div>
                                <p className={styles.subText}>
                                    {t(`SUMMARY.SPECIES`)}
                                </p>
                                <p className={styles.textInfo}>
                                    {formValidation.species
                                        ? t(
                                                `PET_TYPE.${formValidation.species.toUpperCase()}`,
                                            )
                                        : t('SUMMARY.UNSPECIFIED')}
                                </p>
                            </div>
                            <div>{renderIcon()}</div>
                        </div>
                    </div>
                    <div className={styles.collapse}>
                        <div
                            className={styles.collapseContainer}
                            onClick={() => nextStep(AnimalFormStep.TYPE_NAME)}
                        >
                            <div>
                                <p className={styles.subText}>
                                    {t('SUMMARY.NAME')}
                                </p>
                                <p className={styles.textInfo}>
                                    {formValidation.name}
                                </p>
                            </div>
                            <div>{renderIcon()}</div>
                        </div>
                    </div>{' '}
                    <div className={styles.collapse}>
                        <div
                            className={styles.collapseContainer}
                            onClick={() => nextStep(AnimalFormStep.BIRTH_SEXE)}
                        >
                            <div>
                                <p className={styles.subText}>
                                    {t('SUMMARY.BIRTH_DATE')}
                                </p>
                                {formValidation.birth_date &&
                                typeof formValidation.birth_date !==
                                    'string' ? (
                                    <p className={styles.textInfo}>
                                        {MonthTranslated.map((el, i) => {
                                            if (
                                                i
                                                    .toString()
                                                    .padStart(2, '0') ===
                                                formValidation
                                                    .birth_date!.getMonth()
                                                    .toString()
                                                    .padStart(2, '0')
                                            ) {
                                                return t(el);
                                            }
                                        })}
                                        /
                                        {formValidation.birth_date!.getFullYear()}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div>{renderIcon()}</div>
                        </div>
                    </div>
                    <div className={styles.collapse}>
                        <div
                            className={styles.collapseContainer}
                            onClick={() => nextStep(AnimalFormStep.BIRTH_SEXE)}
                        >
                            <div>
                                <p className={styles.subText}>
                                    {t('SUMMARY.SEX')}
                                </p>
                                <p className={styles.textInfo}>
                                    {formValidation.sex
                                        ? t(
                                                `PET_GENDER.${formValidation.sex.toUpperCase()}`,
                                            )
                                        : t('SUMMARY.UNSPECIFIED')}
                                </p>
                            </div>
                            <div>{renderIcon()}</div>
                        </div>
                    </div>
                    <div className={styles.collapse}>
                        <div
                            className={styles.collapseContainer}
                            onClick={() => nextStep(AnimalFormStep.RACE)}
                        >
                            <div>
                                <p className={styles.subText}>
                                    {t('SUMMARY.BREED')}
                                </p>
                                <p className={styles.textInfo}>
                                    {formValidation.breed
                                        ? formValidation.breed
                                        : t('SUMMARY.UNSPECIFIED')}
                                </p>
                            </div>
                            <div>{renderIcon()}</div>
                        </div>
                    </div>{' '}
                    <div className={styles.collapse}>
                        <div
                            className={styles.collapseContainer}
                            onClick={() => nextStep(AnimalFormStep.RACE)}
                        >
                            <div>
                                <p className={styles.subText}>
                                    {t('SUMMARY.IDENTIFICATION')}
                                </p>
                                <p className={styles.textInfo}>
                                    {formValidation.identification
                                        ? t(
                                                `PET_IDENTIFICATION.${formValidation.identification.toUpperCase()}`,
                                            )
                                        : t('SUMMARY.UNSPECIFIED')}
                                </p>
                            </div>
                            <div>{renderIcon()}</div>
                        </div>
                    </div>
                    <div className={styles.collapse}>
                        <div
                            className={styles.collapseContainer}
                            onClick={() => nextStep(AnimalFormStep.BIRTH_SEXE)}
                        >
                            <div>
                                <p className={styles.subText}>
                                    {t('SUMMARY.WEIGHT')}
                                </p>
                                <p className={styles.textInfo}>
                                    {formValidation.weight
                                        ? formValidation.weight + 'kg'
                                        : t('SUMMARY.UNSPECIFIED')}
                                </p>
                            </div>
                            <div>{renderIcon()}</div>
                        </div>
                    </div>
                    <div className={styles.collapse}>
                        <div
                            className={styles.collapseContainer}
                            onClick={() => nextStep(AnimalFormStep.ACTIVITY)}
                        >
                            <div>
                                <p className={styles.subText}>
                                    {t('SUMMARY.DAILY_EXERCISE')}
                                </p>
                                <p className={styles.textInfo}>
                                    {formValidation.activity_level !== null
                                        ? t(
                                                `${
                                                    ActivityText.find(
                                                        (el) =>
                                                            el.value ===
                                                            formValidation.activity_level,
                                                    )?.text
                                                }${formValidation.species?.toLocaleUpperCase()}`,
                                            )
                                        : t('SUMMARY.UNSPECIFIED')}
                                </p>
                            </div>
                            <div>{renderIcon()}</div>
                        </div>
                    </div>
                    <div className={styles.collapse}>
                        <div
                            className={styles.collapseContainer}
                            onClick={() => nextStep(AnimalFormStep.HEALTH)}
                        >
                            <div>
                                <p className={styles.subText}>
                                    {t('SUMMARY.HEALTH')}
                                </p>
                                {formValidation.is_sterilized ? (
                                    <p className={styles.textInfo}>
                                        {t('activityGeneral:STERILIZED')}
                                    </p>
                                ) : (
                                    <p className={styles.textInfo}>
                                        {t('commonActions:NO')}{' '}
                                        {t('activityGeneral:STERILIZED')}
                                    </p>
                                )}
                            </div>
                            <div>{renderIcon()}</div>
                        </div>
                    </div>
                    <div className={styles.collapse}>
                        <div
                            className={styles.collapseContainer}
                            onClick={() => nextStep(AnimalFormStep.HEALTH)}
                        >
                            <div>
                                <p className={styles.subText}>
                                    {t('PET_HEALTH.LAST_VET_VISIT')}
                                </p>
                                {formValidation.last_vet_visit_date &&
                                typeof formValidation.last_vet_visit_date !==
                                    'string' ? (
                                    <p className={styles.textInfo}>
                                        {MonthTranslated.map((el, i) => {
                                            if (
                                                i
                                                    .toString()
                                                    .padStart(2, '0') ===
                                                formValidation
                                                    .last_vet_visit_date!.getMonth()
                                                    .toString()
                                                    .padStart(2, '0')
                                            ) {
                                                return t(el);
                                            }
                                        })}
                                        /{' '}
                                        {formValidation?.last_vet_visit_date!.getFullYear()}
                                    </p>
                                ) : (
                                    t('SUMMARY.UNSPECIFIED')
                                )}
                            </div>
                            <div>{renderIcon()}</div>
                        </div>
                    </div>
                    <div className={styles.collapse}>
                        <div
                            className={styles.collapseContainer}
                            onClick={() => nextStep(AnimalFormStep.HEALTH)}
                        >
                            <div>
                                <p className={styles.subText}>
                                    {t('PET_HEALTH.LAST_VACINATION')}
                                </p>
                                {formValidation.last_vaccination_date &&
                                typeof formValidation.last_vaccination_date !==
                                    'string' ? (
                                    <p className={styles.textInfo}>
                                        {MonthTranslated.map((el, i) => {
                                            if (
                                                i
                                                    .toString()
                                                    .padStart(2, '0') ===
                                                formValidation
                                                    .last_vaccination_date!.getMonth()
                                                    .toString()
                                                    .padStart(2, '0')
                                            ) {
                                                return t(el);
                                            }
                                        })}
                                        /{' '}
                                        {formValidation?.last_vaccination_date!.getFullYear()}
                                    </p>
                                ) : (
                                    t('SUMMARY.UNSPECIFIED')
                                )}
                            </div>
                            <div>{renderIcon()}</div>
                        </div>
                    </div>
                    {!editMode ? (
                        <div className={styles.buttonBlockSummary}>
                            <Grid
                                className={cx([
                                    styles.buttonWidth,
                                    styles.buttonBlockSummary,
                                ])}>
                                <Button
                                    className={cx(styles.buttonWidth)}
                                    // minimum required param to post / put
                                    disabled={
                                        formValidation.name &&
                                        formValidation.species &&
                                        formValidation.birth_date &&
                                        formValidation.identification &&
                                        formValidation.activity_level !== null &&
                                        formValidation.last_vaccination_date &&
                                        formValidation.last_vet_visit_date
                                            ? false
                                            : true
                                    }
                                    onClick={() => handleSubmit()}
                                >
                                    {t('SUMMARY.VALIDATION')}
                                </Button>
                            </Grid>
                        </div>
                    ) : (
                        <div className={styles.buttonBockDelete}>
                            <p
                                className={styles.deleteButton}
                                onClick={() => setModalProfileDelete(true)}
                            >
                                {t('SUMMARY.DELETE')}
                            </p>
                            {modalProfileDelete ? (
                                <Modal
                                    onClosed={() =>
                                        setModalProfileDelete(false)
                                    }
                                >
                                    <div>
                                        <p className={styles.title}>
                                            {t('SUMMARY.MODAL.TITLE')}
                                        </p>
                                        <div className={styles.commonText}>
                                            {t('SUMMARY.MODAL.SUBTEXT')}
                                        </div>
                                        <div
                                            className={styles.buttonBlockModal}
                                        >
                                            <Button
                                                className={styles.buttonWidth}
                                                onClick={() => handleRemove()}
                                            >
                                                {t('SUMMARY.MODAL.YES')}
                                            </Button>
                                            <Button
                                                secondary
                                                className={cx(
                                                    styles.buttonWidth,
                                                    styles.noBorder,
                                                )}
                                                onClick={() =>
                                                    setModalProfileDelete(false)
                                                }
                                            >
                                                {t('SUMMARY.MODAL.NO')}
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            ) : null}
                        </div>
                    )}
                </form>
            </div>
        </React.Fragment>
    );
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    showNotification: dispatch.notifications.showNotification,
});

export const SummaryComponent = compose<IActions, IOuterProps>(
    connect(mapDispatch),
)(SummaryComponentRaw);
