import { createSelector } from 'reselect';
import { IRootState } from '../../store/store';
import { activeTrackerId } from '../../trackers/selectors/selectors';
import { IGeofenceEditStore } from '../geofence-edit.store';
import { IGeofencesStore } from '../geofences.store';
import { IGeofence, IGeofenceMarker } from '../interfaces';
import {
    mapGeofenceApiToClient,
    mapGeofenceApiToMarker,
    mapGeofenceEditToMarker,
} from '../mappers/geofences-api-to-client';

const geofencesStore = (state: IRootState) => state.geofences;
const geofencesEditStore = (state: IRootState) => state.geofenceEdit;

export const makeGetUserGeofencesForTracker = (trackerId: number) => {
    return createSelector(
        geofencesStore,
        (state: IGeofencesStore): IGeofence[] => {
            if (!state.userGeofencesByTrackerId[trackerId]) {
                return [];
            }

            return Object.values(state.userGeofencesByTrackerId[trackerId]).map(
                mapGeofenceApiToClient,
            );
        },
    );
};

export const makeGetAllUserGeofences = () => {
    return createSelector(
        geofencesStore,
        (store: IGeofencesStore): IGeofence[] => {
            return Object.values(store.userGeofencesByTrackerId)
                .map((geofencesObj) => Object.values(geofencesObj))
                .reduce((acc, val) => acc.concat(val), [])
                .map(mapGeofenceApiToClient);
        },
    );
};

/**
 * Neat selector for geofence markers to avoid lot of conditions in components.
 * Select the editable geofence if one creation or edition is currently on going.
 * Else select every geofence of active tracker if on tracker is active
 * Else select every geofence of the account.
 */
export const makeGetGeofencesToDisplay = () => {
    return createSelector(
        activeTrackerId,
        geofencesStore,
        geofencesEditStore,
        (
            trackerId: number | null,
            geofenceStore: IGeofencesStore,
            geofenceEditStore: IGeofenceEditStore,
        ): IGeofenceMarker[] => {
            // If during edition or creation, return the editing geofence as IGeofenceEditStore
            if (
                geofenceEditStore.duringNewCreating ||
                geofenceEditStore.geofenceId
            ) {
                return [mapGeofenceEditToMarker(geofenceEditStore)];
            } else {
                // If not during edition nor creation, return geofences of the selected tracker if on is selected ...
                if (trackerId) {
                    return Object.values(geofenceStore.userGeofencesByTrackerId)
                        .map((geofencesObj) => Object.values(geofencesObj))
                        .reduce((acc, val) => acc.concat(val), [])
                        .filter((geofence) => geofence.tracker_id === trackerId)
                        .map(mapGeofenceApiToMarker);
                }
                // ... or every geofence of the account if no tracker is selected.
                return Object.values(geofenceStore.userGeofencesByTrackerId)
                    .map((geofencesObj) => Object.values(geofencesObj))
                    .reduce((acc, val) => acc.concat(val), [])
                    .map(mapGeofenceApiToMarker);
            }
        },
    );
};
export const makeGetGeofencesFetchedForTracker = (trackerId: number) =>
    createSelector(
        geofencesStore,
        //@ts-ignore
        (store): boolean => store.dataFetchedByTrackerId[trackerId] || false,
    );
