import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import { branch, compose, renderComponent } from 'recompose';

import { getGlobalT } from '../../../i18n/setup-translations';
import { Typography } from '../../common/typography/typography.component';
import { IRootState } from '../../store/store';
import { DistanceMetric, ITrackerSpeedDistanceActivity } from '../interfaces';
import {
    getIsDateToday,
    getTrackerDistanceSpeedActivityParams,
} from '../selectors';
import styles from './activity-details.module.scss';

interface IStateProps {
    data: (ITrackerSpeedDistanceActivity & { timeElapsed: string }) | null;
}

interface IOuterProps extends HTMLAttributes<HTMLDivElement> {}

interface IProps extends IStateProps, IOuterProps {}

export const ActivityDetails = ({ data, className }: IProps) => {
    const {
        averageSpeed,
        maxSpeed,
        totalDistance,
        distanceMetric,
        timeElapsed,
        metricSystem,
    } = data!;
    const t = getGlobalT();
    const selectedMetric = new Map<DistanceMetric, string>([
        [DistanceMetric.MILES, 'miles'],
        [DistanceMetric.METERS, 'm'],
        [DistanceMetric.YARDS, 'yards'],
        [DistanceMetric.KM, 'km'],
    ]);
    return (
        <div className={cx(className)}>
            <div className={styles.row}>
                <Typography className={styles.label}>
                    {t('activity:TOTAL_DISTANCE')}
                </Typography>
                <Typography className={cx(styles.value, styles.big)}>
                    {totalDistance.toFixed(2)}{' '}
                    {selectedMetric.get(distanceMetric)}
                </Typography>
            </div>
            <div className={styles.row}>
                <Typography className={styles.label}>
                    {t('activity:AVERAGE_SPEED')}
                </Typography>
                <Typography className={styles.value}>
                    {averageSpeed.toFixed(2)} {metricSystem}/h
                </Typography>
            </div>
            <div className={styles.row}>
                <Typography className={styles.label}>
                    {t('activity:MAXIMUM_SPEED')}
                </Typography>
                <Typography className={styles.value}>
                    {maxSpeed.toFixed(2)} {metricSystem}/h
                </Typography>
            </div>
            <div className={styles.row}>
                <Typography className={styles.label}>
                    {t('activity:TIME_ELAPSED')}
                </Typography>
                <Typography className={styles.value}>
                    {timeElapsed} h
                </Typography>
            </div>
        </div>
    );
};

export const ConnectedActivityDetails = compose<IProps, IOuterProps>(
    connect((state: IRootState): IStateProps | null => {
        const data = getTrackerDistanceSpeedActivityParams(state);
        if (!data) {
            return {
                data: null,
            };
        }

        const isToday = getIsDateToday(state);

        let date: string | number;

        if (isToday) {
            date = state.history.todayPeriod as number;
        } else {
            const start = state.history.pastDayHourStart;
            const end = state.history.pastDayHourEnd;

            date = end - start;
        }

        return {
            data: {
                ...data,
                timeElapsed: `${date}`,
            },
        };
    }),
    branch(
        (p: IStateProps) => !Boolean(p.data),
        renderComponent(() => null),
    ),
)(ActivityDetails);
