import download from 'downloadjs';
import { parsePhoneFormat } from 'utils/parsePhoneFormat';
import { config } from '../../../config';

import { apiService } from '../../api/api.service';
import { IEditableUserData } from '../../user/interfaces';
import { IUser } from '../../user/interfaces-api';
import {
    IOptionsPaymentsHistoryResponse,
    ISubscriptionPaymentsHistoryResponse,
} from '../interfaces-api';

export const getInvoiceUrl = (paymentId: string | number) =>
    `${config.API_HOST}/mysubscriptionpayment/${paymentId}/invoice`;

export const updatePassword = (
    oldPassword: string,
    newPassword: string,
): Promise<IUser> =>
    apiService
        .fetch('/myuser', {
            method: 'PUT',
            body: JSON.stringify({
                password: newPassword,
                current_password: oldPassword,
            }),
        })
        .then((r) => {
            if (r.status !== 200) {
                return Promise.reject(r);
            }

            return r;
        })
        .then((r) => r.json());

export const updateLanguage = (lang: string) =>
    apiService
        .fetch('/myuser', {
            method: 'PUT',
            body: JSON.stringify({
                language: lang.toLowerCase(),
            }),
        })
        .then((r) => {
            if (r.status !== 200) {
                return Promise.reject(r);
            }

            return r;
        })
        .then((r) => r.json());

export const updatePhone = (newPhone: string) =>
    apiService
        .fetch('/myuser', {
            method: 'PUT',
            body: JSON.stringify({
                phone: parsePhoneFormat(newPhone),
            }),
        })
        .then((r) => {
            if (r.status !== 200) {
                return Promise.reject(r);
            }

            return r;
        })
        .then((r) => r.json());

export const updateProfilePreference = (userData: IEditableUserData) =>
    apiService
        .fetch('/myuser', {
            method: 'PUT',
            body: JSON.stringify({
                optin: userData.marketingAccepted,
                preferred_metric_system: userData.preferredMetricSystem,
            }),
        })
        .then((resp) => {
            if (resp.status !== 200) {
                return Promise.reject(resp);
            }

            return resp.json();
        });

export const updateProfileData = (userData: IEditableUserData) =>
    apiService
        .fetch('/myuser', {
            method: 'PUT',
            body: JSON.stringify({
                mail: userData.email,
                lastname: userData.lastName,
                firstname: userData.firstName,
                phone: parsePhoneFormat(userData.phone),
                address: userData.street,
                postal_code: userData.postalCode,
                city: userData.city,
                country: userData.country,
                optin: userData.marketingAccepted,
                preferred_metric_system: userData.preferredMetricSystem,
            }),
        })
        .then((resp) => {
            if (resp.status !== 200) {
                return Promise.reject(resp);
            }

            return resp.json();
        });

export const getSubscriptionPaymentsHistory = (
    subscriptionId: number,
): Promise<ISubscriptionPaymentsHistoryResponse> => {
    const getUrl = () => `/mysubscription/${subscriptionId}/payments`;

    return apiService.fetch(getUrl()).then((r) => r.json());
};

/**
 * Payment ID can be either subscription or option id. It's not payment_id but id key
 * in option or subscription payment model.
 *
 * download.js library is required to download file with extra headers. Standard browser's
 * GET in new tab will be 401, and authorized request with JWT tokens require AJAX call.
 * After call is performed, response is converted into binary and then libarary is doing under the hood
 * hack to download it (append <a href=file>, click it, remove <a>).
 * There is still no modern way how to handle this case.
 */
export const getPaymentInvoice = (
    paymentId: number,
    type: 'subscription' | 'option',
) => {
    const getUrl = () => {
        if (type === 'subscription') {
            return `/mysubscriptionpayment/${paymentId}/invoice`;
        } else if (type === 'option') {
            return `/myoptionpayment/${paymentId}/invoice`;
        }

        throw Error('Invoice type not provided');
    };

    return apiService
        .fetch(
            getUrl(),
            {},
            {
                mapHeaders: (headers) => {
                    headers.delete('Accept');

                    return headers;
                },
            },
        )
        .then((resp) => {
            if (resp.status >= 400) {
                return Promise.reject(resp);
            }

            return resp;
        })
        .then((resp) => resp.blob())
        .then((blob) => download(blob, 'invoice.pdf', 'application/pdf'))
        .catch((err) => {
            console.log(err);
        });
};

export const getSubscriptionOptionsHistory = (
    subscriptionId: number,
): Promise<IOptionsPaymentsHistoryResponse> => {
    const getUrl = () => `/mysubscription/${subscriptionId}/options/payments`;

    return apiService.fetch(getUrl()).then((r) => r.json());
};
