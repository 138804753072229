import React from 'react';
import { withProps } from 'recompose';
import { ReactComponent as LayersIcon } from '../../../icons/layers.svg';
import { ReactComponent as MinusIcon } from '../../../icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as RefreshIcon } from '../../../icons/refresh.svg';
import {
    IMapFloatingButtonProps,
    MapFloatingButton,
} from '../map-floating-button/map-floating-button.component';

export const MapFloatingButtonRefresh = withProps<
    IMapFloatingButtonProps,
    IMapFloatingButtonProps
>({
    children: <RefreshIcon />,
})(MapFloatingButton);

export const MapFloatingButtonZoomIn = withProps<
    IMapFloatingButtonProps,
    IMapFloatingButtonProps
>({
    displayWhiteIcon: true,
    children: <PlusIcon />,
})(MapFloatingButton);

export const MapFloatingButtonZoomOut = withProps<
    IMapFloatingButtonProps,
    IMapFloatingButtonProps
>({
    displayWhiteIcon: true,
    children: <MinusIcon />,
})(MapFloatingButton);

export const MapFloatingButtonLayers = withProps<
    IMapFloatingButtonProps,
    IMapFloatingButtonProps
>({
    displayWhiteIcon: true,
    children: <LayersIcon />,
})(MapFloatingButton);
