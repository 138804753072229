/**
 * Inner steps in register process
 */
export enum newTrackerRoutes {
    REGISTER = '/register',
    SUBSCRIPTION = '/subscription/:id',
    GIFTCARD = '/gift-card/:id',
    OPTIONS = '/options/:id',
    PAYMENT = '/payment/:id',
    HIPAY = '/credit-card/:id',
}
