import { flatMap } from 'lodash';
import { createSelector } from 'reselect';

import { IRootState } from '../../store/store';
import { makeGetAllSubscriptionsDetails } from '../../user-subscriptions/selectors/user-subscriptions.selectors';
import { mapToPaymentHistoryItem } from '../mappers/to-payment-history-item';

const getRawPaymentsById = (state: IRootState) =>
    state.account.paymentsBySubscriptionId;

export const makeGetAllFormattedPaymentHistoryItems = () => {
    const getAllSubs = makeGetAllSubscriptionsDetails();

    return createSelector(
        getRawPaymentsById,
        getAllSubs,
        (payments, subs) => {
            const paymentsList = flatMap(Object.values(payments));

            const sortedPaymentList = paymentsList.sort(
                (paymentA, paymentB) => {
                    if (paymentA.created_at < paymentB.created_at) {
                        return 1;
                    }
                    if (paymentA.created_at > paymentB.created_at) {
                        return -1;
                    }
                    return 0;
                },
            );

            return sortedPaymentList.map((paymentItem) =>
                mapToPaymentHistoryItem(paymentItem, subs),
            );
        },
    );
};
