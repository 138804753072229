import { iconMap } from '../../trackers/tracker-icons';

/**
 * TODO: Add variations for different tracker types, to sort relevant icons first
 */
export const getIcons = (): Array<{ id: string; src: string }> =>
    Object.entries(iconMap).map(([iconId, iconSrc]: [string, string]) => ({
        id: iconId,
        src: iconSrc,
    }));
