import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { compose } from 'recompose';

import { Button } from '../common/button/button.component';
import { Headline } from '../common/headline/headline.component';
import { Modal } from '../common/modal/modal.component';
import { Dispatch, IRootState } from '../store/store';
import { IPopinAlert } from './interface';
import styles from './popin-alert.module.scss';

interface IStateProps {
    popinAlerts: IPopinAlert[];
}
interface IAction {
    emptyPopinAlerts: any;
}
interface IPopinAlertProps extends IStateProps, IAction { }

const PopinAlertComponent = ({
    popinAlerts,
    emptyPopinAlerts,
}: IPopinAlertProps) => {
    if (!popinAlerts.length) {
        return null;
    }
    const history = useNavigate()
    const firstAlert = popinAlerts[0];

    const goToLink = () => {
        emptyPopinAlerts();
        history(firstAlert.cta_link);
    };

    const maybeDisplayCancelText = () => {
        if (firstAlert.cancel_text) {
            return <p>{firstAlert.cancel_text}</p>;
        }
    };
    return (
        <Modal
            className={styles.popinalert}
            onClosed={() => emptyPopinAlerts()}
        >
            <Headline level={2}>{firstAlert.title}</Headline>

            <p>{firstAlert.body}</p>

            <Button onClick={() => goToLink()}>{firstAlert.cta_text}</Button>

            {maybeDisplayCancelText()}
        </Modal>
    );
};

const mapState = (state: IRootState): IStateProps => {
    return {
        popinAlerts: state.popinAlerts.PopinAlerts,
    };
};

const mapDispatch = (dispatch: Dispatch): IAction => {
    return {
        emptyPopinAlerts: dispatch.popinAlerts.emptyPopinAlerts,
    };
};

const ConnectedPopinAlerts = compose<IPopinAlertProps, {}>(
    connect(mapState, mapDispatch),
)(PopinAlertComponent);

export { ConnectedPopinAlerts as PopinAlert };
