import { apiService } from '../api/api.service';
import { IAnimal, IAnimalObject } from './interfaces';

export const getAnimalInfo = (imei: number): Promise<IAnimalObject> => {
    const getUrl = `/animal?imei=${imei}`;
    return apiService
        .fetch(
            getUrl,
            {},
            {
                mapHeaders: (headers) => {
                    headers.delete('Accept');

                    return headers;
                },
            },
        )
        .then((resp) => {
            if (resp.status === 200) {
                return resp.json();
            } else {
                throw Error('Wrong response');
            }
        });
};

export const deleteAnimalInfo = (animalId: number): Promise<IAnimal> => {
    const url = `/animal/${animalId}`;
    return apiService
        .fetch(url, {
            method: 'DELETE',
        })
        .then((resp) => {
            if (resp.status >= 200) {
                return resp;
            }
            throw Error('Wrong response');
        });
};
export const postAnimalInfo = (body: any): Promise<IAnimal> => {
    const url = `/animal`;
    return apiService
        .fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
        })
        .then((resp) => {
            if (resp.status >= 200) {
                return resp;
            }
            throw Error('Wrong response');
        });
};
export const putAnimalInfo = (
    body: any,
    animalId: number,
): Promise<IAnimal> => {
    const url = `/animal/${animalId}`;
    return apiService
        .fetch(url, {
            method: 'PUT',
            body: JSON.stringify(body),
        })
        .then((resp) => {
            if (resp.status >= 200) {
                return resp;
            }
            throw Error('Wrong response');
        });
};

export const getAnimalBreeds = (body: any): Promise<any> => {
    const url = `/animal`;
    return apiService
        .fetch(url, {
            method: 'GET',
            body: JSON.stringify(body),
        })
        .then((resp) => {
            if (resp.status === 204) {
                return resp;
            }
            throw Error('Wrong response');
        });
};
