import React from 'react';
import { Pane } from 'react-leaflet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { MapLayerType } from '../../map/interfaces';
import { Dispatch, IRootState } from '../../store/store';
import { ConnectedGeofenceMapMarker } from '../geofence-map-marker/geofence-map-marker.component';
import { IGeofenceMarker } from '../interfaces';
import { makeGetEditableGeofenceId } from '../selectors/geofences-edit.selectors';
import { makeGetGeofencesToDisplay } from '../selectors/geofences.selectors';

export interface IStateProps {
    editableId: number | null;
    geofencesToDisplay: IGeofenceMarker[];
    selectedLayer: MapLayerType;
}

export interface IActions {
    updateEditablePosition: (position: { lat: number; lng: number }) => any;
}

export interface IProps extends IStateProps, IActions {}

export const GeofencesMapMarkersList = ({
    editableId,
    geofencesToDisplay,
    selectedLayer,
    updateEditablePosition,
}: IProps) => {
    const getGeofenceMarkers = () =>
        geofencesToDisplay.map((geofence: IGeofenceMarker) => (
            <ConnectedGeofenceMapMarker
                key={geofence.geofenceId || '0'}
                position={geofence.position}
                radius={geofence.radius}
                editable={editableId === geofence.geofenceId}
                onPositionUpdated={updateEditablePosition}
            />
        ));

    const renderGeofences = () => {
        // When displaying leaflet layers
        if (
            selectedLayer === MapLayerType.GRAY ||
            selectedLayer === MapLayerType.SATELLITE ||
            selectedLayer === MapLayerType.STREET
        ) {
            return <Pane style={{ zIndex: 1200 }}>{getGeofenceMarkers()}</Pane>;
        }
        return getGeofenceMarkers();
    };

    return <React.Fragment>{renderGeofences()}</React.Fragment>;
};

const makeMapState = () => {
    const getEditableId = makeGetEditableGeofenceId();
    const getGeofencesToDisplay = makeGetGeofencesToDisplay();

    return (state: IRootState): IStateProps => ({
        editableId: getEditableId(state),
        geofencesToDisplay: getGeofencesToDisplay(state),
        selectedLayer: state.map.selectedLayer,
    });
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    updateEditablePosition: dispatch.geofenceEdit.updatePosition,
});

export const ConnectedGeofencesMapMarkersList = compose<IProps, {}>(
    connect(makeMapState, mapDispatch),
)(GeofencesMapMarkersList);
