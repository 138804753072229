import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Collapse } from '../../common/collapse/collapse.component';
import { Typography } from '../../common/typography/typography.component';
import { Dispatch, IRootState } from '../../store/store';
import { ITracker } from '../../trackers/interfaces-api';
import { ControlledEditWifiZoneForm } from '../edit-wifi-zones-form/edit-wifi-zones-form.component';
import { IWifiZone } from '../interfaces';
import { IRawWifiZone } from '../interfaces-api';
import { makeGetEditableWifiZonePosition } from '../selectors/wifi-zones.selectors';
import styles from './wifi-zones-item.module.scss';

export interface IStateProps {
    editableLat: number;
    editableLng: number;
    wifiZoneDuringNewCreating: boolean;
}

export interface IActions {
    onToggleWifi: (payload: any) => Promise<any>;
    onDelete: (id: number) => Promise<any>;
    onEditWifi: (wifi: any) => void;
}

// @ts-ignore
export interface IOuterProps extends HTMLAttributes<HTMLDivElement>, IWifiZone {
    onToggle: () => any;
    open: boolean;
    id: number;
    wifiZone: IWifiZone;
    tracker: ITracker;
}

export interface IWifiZoneItemProps
    extends IStateProps,
    IActions,
    IOuterProps { }

export const WifiZoneItem = ({
    onEditWifi,
    tracker,
    wifiZone,
    className,
    id,
    name,
    onDelete,
    onToggle,
    onToggleWifi,
    open,
}: IWifiZoneItemProps) => {
    const panel = (
        <div className={styles.panel}>
            <div>
                <Typography size14 noMargin bold className={styles.name}>
                    {name}
                </Typography>
            </div>
        </div>
    );

    const content = (
        <ControlledEditWifiZoneForm
            className={styles.form}
            onEditWifi={onEditWifi}
            onToggleWifi={onToggleWifi}
            onCancel={onToggle}
            onDelete={() => onDelete(id)}
            canBeDeleted={true}
            tracker={tracker}
            wifiZone={wifiZone}
        />
    );
    return (
        <Collapse
            className={className}
            panel={panel}
            content={content}
            expanded={open}
            onExpand={onToggle}
        />
    );
};

const mapState = (state: IRootState): IStateProps => {
    const getPositionOfEditableWifiZone = makeGetEditableWifiZonePosition();
    const getPositionOfEditableWifiZoneResult = getPositionOfEditableWifiZone(
        state,
    );
    return {
        editableLat: getPositionOfEditableWifiZoneResult.lat,
        editableLng: getPositionOfEditableWifiZoneResult.lng,
        //@ts-ignore
        wifiZoneDuringNewCreating: state.wifiZonesEdit.duringNewCreating,
    };
};

const mapDispatch = (dispatch: Dispatch, props: IOuterProps): IActions => ({
    onDelete: async (wifiZoneId: number) =>
        dispatch.wifiZones.deleteWifiZone(wifiZoneId),
    onEditWifi: (wifi: IWifiZone) => {
        return dispatch.wifiZonesEdit.onTriggerWifiEdit({
            trackerId: props.tracker.id,
            wifi,
            id: props.id,
        });
    },
    onToggleWifi: async (wifiZone: IRawWifiZone) => {
        return dispatch.wifiZonesEdit.onTriggerWifiEdit({
            trackerId: props.tracker_id,
            wifi: wifiZone,
            id: props.id,
        });
    },
});

export const ConnectedWifiZoneItem = compose<IWifiZoneItemProps, IOuterProps>(
    connect(mapState, mapDispatch),
)(WifiZoneItem);
