import { get } from 'lodash';
import { createSelector } from 'reselect';
import { trackersFromApi } from './selectors';

export const getIsAnyTrackerOnline = createSelector(
    trackersFromApi,
    (model) =>
        model.filter((tracker) => get(tracker, 'position[0].is_online', false))
            .length > 0,
);
