import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styles from './progress-bar.module.scss';

interface IOuterProps {
    completionValueInPercent: number;
    completionValue?: string;
    title: string;
    width: string;
    color?: string;
    subtitle?: string;
}

export const ProgressBar = ({
    color,
    completionValueInPercent,
    completionValue,
    subtitle,
    title,
    width,
}: IOuterProps) => {
    const mayRenderArrow = () => {
        if (completionValue) {
            return (
                <div
                    className={
                        completionValueInPercent < 15
                            ? styles.arrowContainer0
                            : styles.arrowContainer
                    }
                    style={{ width: completionValueInPercent + '%' }}
                >
                    <div className={styles.arrowUp} />
                </div>
            );
        }
    };
    const mayRenderArrowText = () => {
        if (completionValue) {
            return (
                <div className={styles.arrowTextContainer}>
                    <div
                        style={{
                            width: !completionValueInPercent
                                ? 0
                                : !isMobile
                                ? completionValueInPercent - 5 + '%'
                                : completionValueInPercent - 17 + '%',
                        }}
                    />
                    <div>{completionValue}</div>
                </div>
            );
        }
    };
    const mayRenderGaugeBar = () => {
        if (color) {
            return (
                <div
                    className={styles.progressContainer}
                    style={{ height: '1rem' }}
                >
                    <div
                        style={
                            !completionValueInPercent
                                ? {
                                      width: '0%',
                                      background: color,
                                      height: '1rem',
                                      borderRadius: '2rem',
                                  }
                                : {
                                      width: completionValueInPercent + '%',
                                      background: color,
                                      height: '1rem',
                                      borderRadius: '2rem',
                                  }
                        }
                    />
                </div>
            );
        }
        return (
            <div className={styles.progressContainer}>
                <div
                    className={
                        completionValueInPercent === 100
                            ? styles.progressBarCompleted
                            : styles.progressBarNotCompleted
                    }
                    style={{
                        width: completionValueInPercent + '%',
                    }}
                />
            </div>
        );
    };
    const mayRenderTextHeader = () => {
        if (subtitle) {
            return (
                <div className={styles.flexGrid} style={{ flexWrap: 'wrap' }}>
                    <div className={styles.commonText}>{title} </div>
                    <div />
                    <div className={styles.commonText}>{subtitle}</div>
                </div>
            );
        }
        return (
            <div className={styles.flexGrid}>
                <p className={styles.title}>{title}</p>
            </div>
        );
    };
    return (
        <>
            <div style={{ width, paddingBottom: '1rem' }}>
                {mayRenderTextHeader()}
                {mayRenderGaugeBar()}
                {mayRenderArrow()}
                {mayRenderArrowText()}
            </div>
        </>
    );
};
