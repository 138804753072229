import { flatMap } from 'lodash';

import { ModelConfig } from '@rematch/core';

import { IRootState } from '../store/store';
import { makeGetAllUserSubscriptionsIds } from '../user-subscriptions/selectors/user-subscriptions.selectors';
import { IEditableUserData, IRawUserData } from '../user/interfaces';
import {
    IOptionsPaymentsHistoryResponse,
    IRawSubscriptionPaymentHistoryItem,
    ISubscriptionPaymentsHistoryResponse,
} from './interfaces-api';
import {
    getPaymentInvoice,
    getSubscriptionOptionsHistory,
    getSubscriptionPaymentsHistory,
    updateLanguage,
    updatePassword,
    updatePhone,
    updateProfileData,
    updateProfilePreference,
} from './services/account.service';

interface IAccountModel {
    paymentsBySubscriptionId: {
        /**
         * Option payments will basically be saved under the IRawSubscriptionPaymentHistoryItem having
         * the 'code' attribute not null,
         * whereas subscription payments will have their 'code' attributes set to null
         */
        [subID: number]: IRawSubscriptionPaymentHistoryItem[];
    };
}

export const accountStore: ModelConfig<IAccountModel> = {
    state: {
        paymentsBySubscriptionId: {},
    },
    reducers: {
        setPayments: (state, payload: IRawSubscriptionPaymentHistoryItem[]) => {
            const accountStoreState = { ...state };

            payload.forEach((item) => {
                accountStoreState.paymentsBySubscriptionId[
                    item.subscription_id
                ] =
                    accountStoreState.paymentsBySubscriptionId[
                    item.subscription_id
                    ] || [];

                accountStoreState.paymentsBySubscriptionId[
                    item.subscription_id
                ].push(item);
            });

            return accountStoreState;
        },
    },
    effects: (dispatch: any) => ({
        async updatePassword(payload: {
            newPassword: string;
            oldPassword: string;
        }) {
            return updatePassword(payload.oldPassword, payload.newPassword);
        },
        async updateLanguage(language: string) {
            return updateLanguage(language).then((data: IRawUserData) => {
                dispatch.user.setUserData(data);

                return data;
            });
        },
        async updatePhone(phone: string) {
            return updatePhone(phone).then((data: IRawUserData) => {
                dispatch.user.setUserData(data);

                return data;
            });
        },
        async updateUserPreferences(userData: IEditableUserData) {
            return updateProfilePreference(userData).then((data) => {
                dispatch.user.setUserData(data);
                return data;
            });
        },
        async updateUserData(userData: IEditableUserData) {
            return updateProfileData(userData).then((data) => {
                dispatch.user.setUserData(data);
                return data;
            });
        },
        async fetchUserPayments(
            payload: { force: boolean } = { force: false },
            models: IRootState,
        ) {
            const getUserSubscriptions = makeGetAllUserSubscriptionsIds();
            const subscriptions = getUserSubscriptions(models);

            const subscriptionsPaymentsToFetch = subscriptions.filter(
                (subId: number) => {
                    if (payload.force) {
                        return subId;
                    }

                    return !Object.keys(
                        models.account.paymentsBySubscriptionId,
                    ).find((id) => parseInt(id, 10) === subId);
                },
            );

            return Promise.all(
                subscriptionsPaymentsToFetch.map((id) =>
                    Promise.all([
                        getSubscriptionPaymentsHistory(id),
                        getSubscriptionOptionsHistory(id),
                    ]),
                ),
            ).then(
                (
                    responsesTuples: Array<
                        [
                            ISubscriptionPaymentsHistoryResponse,
                            IOptionsPaymentsHistoryResponse
                        ] // 1st : Subscriptions, 2nd : Options
                    >,
                ) => {
                    const items = responsesTuples
                        /**
                         * Flatten subscription and options payments to single array
                         */
                        .map((allResponses) => flatMap(allResponses))
                        /**
                         * Flatten all payments items to single array
                         */
                        .map((resp) => flatMap(resp, (a) => a.items));

                    const flatItems = flatMap(items);

                    this.setPayments(flatItems);

                    return flatItems;
                },
            );
        },
        async downloadInvoice({
            id,
            type,
        }: {
            id: number;
            type: 'subscription' | 'option';
        }) {
            return getPaymentInvoice(id, type);
        },
    }),
};
