import { useParams } from 'react-router';
import { mapProps } from 'recompose';

export interface IWithSelectedTracker {
    trackerId: number | null;
}

export const withSelectedTracker = mapProps((
    props: any,
): IWithSelectedTracker => {
    const { id } = useParams()
    let trackerId = props.activeTrackerId; // replace all leading non-digits with nothing
    if (!trackerId) {
        // fallback
        trackerId = id ? parseInt(id, 10) : null
    }
    return {
        trackerId: trackerId ? trackerId : null,
        ...props
    };
}
)
