import cx from 'classnames';
import React, { HTMLAttributes } from 'react';

import styles from './page-container.module.scss';

type Props = HTMLAttributes<HTMLDivElement>;

export const PageContainer = ({ children, className }: Props) => (
    <div className={cx(styles.container, className)}>{children}</div>
);
