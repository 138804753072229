import { apiService } from '../api/api.service';
import { IRetailerApplyFree } from './interfaces-api';

/**
 * API call made to adjust subscription rules :
 * For B2B customers, we don't want them to be redirected to the subscription page
 * when a basic customers should be.
 *
 * @param retailerId Extracted from tracker object
 */
export const fetchRetailerApplyFree = (
    retailerId: number,
): Promise<IRetailerApplyFree> => {
    const url = `/myretailer/${retailerId}/apply-free`;

    return apiService.fetch(url, {}).then((resp) => {
        if (resp.status !== 200) {
            throw Error('Request failed');
        } else {
            return resp.json();
        }
    });
};
