import {
    init as initRematchStore,
    Middleware,
    RematchRootState,
} from '@rematch/core';

import { accountStore } from '../account/account.store';
import { activityTrackingStore } from '../activity-tracking/activity-tracking.store';
import { animalInfoStore } from '../animal-info/animal-info.store';
import { cancelSubscriptionStore } from '../cancel-subscription/cancel-subscription.store';
import { forgotPasswordStore } from '../forgot-password/forgot-password.store';
import { geofenceEditStore } from '../geofences/geofence-edit.store';
import { geofencesStore } from '../geofences/geofences.store';
import { historyPositionPlayingStore } from '../history/history-position-playing.store';
import { historyStore } from '../history/history.store';
import { languageStore } from '../language/language.store';
import { layoutStore } from '../layout/layout.store';
import { loginStore } from '../login/login.store';
import { manageSubscriptionStore } from '../manage-subscription/manage-subscription.store';
import { mapStore } from '../map/map.store';
import { notificationsStore } from '../notifications/notifications.store';
import { onboardingStore } from '../onboarding/onboarding.store';
import { popinAlertStore } from '../popin-alert/popin-alert.store';
import { retailerStore } from '../retailer/retailer.store';
import { signupStore } from '../signup/signup.store';
import { subscriptionOffersStore } from '../subscription-offers/subscription-offers.store';
import { newTrackerStore } from '../subscription-process/new-tracker.store';
import { hipayStepStore } from '../subscription-process/steps/hipay-step/hipay-step.store';
import { subscriptionProcessStore } from '../subscription-process/subscription-process.store';
import { supportStore } from '../support/support.store';
import { trackerLiveModeStore } from '../tracker-live-mode/tracker-live-mode.store';
import { trackerReplacementStore } from '../tracker-replacement/tracker-replacement.store';
import { trackersSettingsStore } from '../tracker-settings/tracker-settings.store';
import { trackersStore } from '../trackers/trackers.store';
import { userLocationStore } from '../user-location/user-location.store';
import { userSubscriptionsStore } from '../user-subscriptions/user-subscriptions.store';
import { userStore } from '../user/user.store';
import { userPrefStore } from '../user/userpref.store';
import { wifiZoneEditStore } from '../wifi-zones/wifi-zones-edit.store';
import { wifiZoneStore } from '../wifi-zones/wifi-zones.store';

const models = {
    map: mapStore, // Mapstore needs to be loaded before the others. TODO https://redmine.weenect.com/issues/5593
    account: accountStore,
    animalInfo: animalInfoStore,
    activityTracking: activityTrackingStore,
    cancelSubscription: cancelSubscriptionStore,
    forgotPassword: forgotPasswordStore,
    geofenceEdit: geofenceEditStore,
    geofences: geofencesStore,
    hipayStep: hipayStepStore,
    history: historyStore,
    historyPositionPlaying: historyPositionPlayingStore,
    language: languageStore,
    layout: layoutStore,
    login: loginStore,
    manageSubscription: manageSubscriptionStore,
    newTracker: newTrackerStore,
    notifications: notificationsStore,
    onboarding: onboardingStore,
    popinAlerts: popinAlertStore,
    retailer: retailerStore,
    signup: signupStore,
    subscriptionOffers: subscriptionOffersStore,
    subscriptionProcess: subscriptionProcessStore,
    support: supportStore,
    trackerLiveMode: trackerLiveModeStore,
    trackerReplacement: trackerReplacementStore,
    trackersSettings: trackersSettingsStore,
    user: userStore,
    userLocation: userLocationStore,
    userPref: userPrefStore,
    userSubscriptions: userSubscriptionsStore,
    userTrackers: trackersStore,
    wifiZones: wifiZoneStore,
    wifiZonesEdit: wifiZoneEditStore,
};

/**
 * Inject middlewares here if required.
 */
const middlewares: Middleware[] = [];

export const createStore = () =>
    initRematchStore({
        models,
        redux: {
            middlewares,
        },
    });

export const store = createStore();

export type Store = typeof store;
export type Dispatch = typeof store.dispatch;
export type IRootState = RematchRootState<typeof models>;
