import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router';
import { compose } from 'recompose';

import { ReactComponent as CatOnboarding } from '../../../../images/activityTracking/activity_onboarding_cat.svg';
import { ReactComponent as DogOnboarding } from '../../../../images/activityTracking/activity_onboarding_dog.svg';

import { Button } from '../../../common/button/button.component';
import { OnboardingStep } from '../../interface';

import styles from '../../activity-tracking.module.scss';

export interface INewTrackerPageProps { }

export const OnboardingActivityTrackingPage = ({ }: INewTrackerPageProps) => {
    const location = useLocation();
    const { t } = useTranslation(['activity', 'commonActions']);
    const [currentStep, setCurrentStep] = useState<OnboardingStep>(
        OnboardingStep.FIRST_STEP,
    );

    const mayRenderFirstStep = () => {
        return (
            <>
                <div>
                    <h3 className={styles.title}>{t('ONBOARDING.TITLE')}</h3>
                </div>
                <div className={styles.gridCenter}>
                    <div className={styles.icon}>
                        <DogOnboarding />
                    </div>
                </div>
                <div className={styles.gridCenter}>
                    <p className={styles.subtitle}>
                        {t('ONBOARDING.FIRST_SUBTEXT')}
                    </p>
                    <p className={styles.subtitle}>
                        {t('ONBOARDING.SECOND_SUBTEXT')}
                    </p>
                </div>
                <div className={styles.gridCenter}>
                    <Button
                        onClick={() =>
                            setCurrentStep(OnboardingStep.SECOND_STEP)
                        }
                        className={styles.button}
                    >
                        {t('commonActions:NEXT')}
                    </Button>
                </div>
            </>
        );
    };

    const mayRenderSecondStep = () => {
        return (
            <>
                <div>
                    <h3 className={styles.title}>{t('ONBOARDING.TITLE')}</h3>
                </div>
                <div className={styles.gridCenter}>
                    <div className={styles.icon}>
                        <CatOnboarding />
                    </div>
                </div>
                <div className={styles.gridCenter}>
                    <p className={styles.subtitle}>
                        {t('ONBOARDING.THIRD_SUBTEXT')}
                    </p>
                </div>
                <div className={styles.gridCenter}>
                    <Button
                        onClick={() =>
                            setCurrentStep(OnboardingStep.FIRST_STEP)
                        }
                        className={styles.button}
                    >
                        {t('ONBOARDING.START')}
                    </Button>
                </div>
            </>
        );
    };

    const mayRenderContentDependingOnStep = () => {
        if (currentStep === 0) {
            return mayRenderFirstStep();
        }
        return mayRenderSecondStep();
    };
    return (
        <>
            <React.Fragment>
                <div className={styles.container}>
                    {mayRenderContentDependingOnStep()}
                </div>
            </React.Fragment>
        </>
    );
};

export const ConnectedOnboardingActivityTrackingPage = compose<
    INewTrackerPageProps,
    {}
>()(OnboardingActivityTrackingPage);
