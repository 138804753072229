import cx from 'classnames';
import { FormikProps, withFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { IWithFormUtils, withFormUtils } from '../../../utils/form-helpers';
import { Button } from '../../common/button/button.component';
import { Input } from '../../common/input/input.component';
import { Typography } from '../../common/typography/typography.component';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as MailIcon } from '../../icons/mail.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as TickIcon } from '../../icons/tick.svg';
import { getMailsFormValidationSchema } from './mails-form-validation.schema';
import styles from './notification-contacts.module.scss';

interface IOuterProps {
    existingEmails: string[];
    onDeleted(mail: string): unknown;
    onAdded(mail: string[]): unknown;
}

interface IFormValues {
    mails: string[];
    isBeingModified: boolean[];
}

interface IProps
    extends IOuterProps,
    FormikProps<IFormValues>,
    IWithFormUtils { }

const NotificationContactsMails = ({
    initialValues,
    values,
    setFieldValue,
    handleReset,
    handleSubmit,
    handleChange,
    onDeleted,
    handleBlur,
    isFieldValid,
    getErrorMessage,
}: IProps) => {
    const { t } = useTranslation(['trackerSettings', 'commonActions']) as any;
    const [modified, setModified] = useState<boolean[]>(values.isBeingModified);

    const preventSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };
    const submitChange = (index: number) => {
        handleSubmit();
        if (getErrorMessage(`mails[${index}]`) === undefined) {
            values.isBeingModified[index] = false;
            setModified([...values.isBeingModified]);
        }
    };
    const cancelMailModification = (index: number) => {
        if (!initialValues.mails[index]) {
            const v = [...values.mails];
            v.pop();

            setFieldValue('mails', v);
        } else {
            values.isBeingModified[index] = false;
            setModified([...values.isBeingModified]);
        }
    };
    const onMailModification = (index: number) => {
        values.isBeingModified[index] = true;
        setModified([...values.isBeingModified]);
    };
    const onNewEmailRequested = () => {
        const numberOfMails = values.mails.length;

        setFieldValue(`mails[${numberOfMails}]`, '');
        onMailModification(values.mails.length);
    };

    const onEmailDeleteRequested = (index: number) => {
        /**
         * Check if deleting locally created phone without saving
         */
        if (!initialValues.mails[index]) {
            const v = [...values.mails];
            v.pop();

            setFieldValue('mails', v);
        } else {
            onDeleted(initialValues.mails[index]);
        }
    };

    const maybeRenderModified = (index: number) => {
        if (modified[index] === true) {
            return (
                <div className={styles['add-container']}>
                    <Input
                        placeholder={t('CONTACTS.MAIL.PLACEHOLDER')}
                        isModified
                        small
                        value={values.mails[index]}
                        name={`mails[${index}]`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // @ts-ignore
                        isValid={isFieldValid(`mails[${index}]`)}
                    />
                    <div className={styles['add-buttons-container']}>
                        <Button
                            onClick={() => submitChange(index)}
                            className={styles['submit-button']}
                            title="Submit"
                        >
                            {t('commonActions:VALIDATE')}
                            <TickIcon className={styles['form-svg']} />
                        </Button>
                        <Button
                            onClick={() => cancelMailModification(index)}
                            className={styles['cancel-button']}
                            title="cancel"
                        >
                            <CrossIcon className={styles['form-svg']} />
                        </Button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles['form-option-container']}>
                    <Typography className={styles['registered-info']} noMargin>
                        <MailIcon className={styles['mail-phone-picto']} />
                        {values.mails[index]}
                    </Typography>
                    <div className={styles['form-buttons-container']}>
                        <Button
                            onClick={() => onMailModification(index)}
                            className={styles['edit-button']}
                            title="Edit"
                        >
                            <EditIcon className={styles['form-svg']} />
                        </Button>
                        <Button
                            onClick={() => onEmailDeleteRequested(index)}
                            className={styles['delete-button']}
                            title="Delete"
                        >
                            <DeleteIcon className={styles['form-svg']} />
                        </Button>
                    </div>
                </div>
            );
        }
    };

    /**
     * Render only if previously open one is valid and saved
     */
    const maybeRenderNewEmailButton = () => {
        if (initialValues.mails.length === values.mails.length) {
            return (
                <Button
                    iconLeft={<PlusIcon />}
                    secondary
                    small
                    onClick={onNewEmailRequested}
                    className={styles.button}
                >
                    {t('CONTACTS.ADD_EMAIL_BUTTON')}
                </Button>
            );
        }
    };

    return (
        <form onSubmit={preventSubmit} onReset={handleReset}>
            {values.mails.map((mail: string, index) => (
                <div key={index} className={styles['input-container']}>
                    {maybeRenderModified(index)}
                </div>
            ))}
            {maybeRenderNewEmailButton()}
        </form>
    );
};

export const ControlledNotificationContactsMails = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IFormValues>({
        enableReinitialize: true,
        handleSubmit(values, formikBag) {
            formikBag.props.onAdded(values.mails);
        },
        mapPropsToValues(props) {
            return {
                mails: props.existingEmails,
                isBeingModified: props.existingEmails.map(() => false),
            };
        },
        validationSchema: () => getMailsFormValidationSchema(),
    }),
    withFormUtils,
)(NotificationContactsMails);
