import 'react-image-crop/dist/ReactCrop.css';

import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, {
    centerCrop,
    Crop,
    makeAspectCrop,
    PixelCrop,
} from 'react-image-crop';
import ClipLoader from 'react-spinners/ClipLoader';

import { ReactComponent as DownloadIcon } from '../../../images/download.svg';
import { INotification } from '../../notifications/interfaces';
import { URLPictureAttribute } from '../../trackers/interfaces';
import { fetchTracker, postPicture } from '../../trackers/trackers.service';
import { Button } from '../button/button.component';
import { IconBadge } from '../icon-badge/icon-badge.component';
import { Modal } from '../modal/modal.component';
import { canvasPreview } from './canvas-preview';
import styles from './icon-select.module.scss';
import { useDebounceEffect } from './use-debounce-effect';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: 'px',
                width: 200,
                x: 0,
                y: 0,
                height: 200,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    );
}

interface IOuterProps {
    trackerId: number;
    activeIconId: string;
    urlPicture: string | null;
    onIconSelected: any;
    showNotification: (notification: INotification) => Promise<unknown>;
}

const ImageCropperComponent = ({
    trackerId,
    activeIconId,
    showNotification,
    urlPicture,
    onIconSelected,
}: IOuterProps) => {
    const { t } = useTranslation(['commonActions', 'trackerSettings']);
    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [modal, setModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [logo, setLogo] = useState<string | null | undefined>(null);
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [aspect, setAspect] = useState<number | undefined>(1 / 1);

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined); // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }
    useEffect(() => {
        if (logo) {
            return onIconSelected;
        }
    }, [logo]);
    const handleSaveCroppedImg = () => {
        // The file's text will be printed here
        setLoading(true);
        const data = previewCanvasRef.current?.toDataURL();
        postPicture(trackerId, data).then((pictureResult: any) => {
            if (pictureResult) {
                fetchTracker(trackerId).then((res) => {
                    setLogo(res?.url_picture);
                    setModal(false);
                    showNotification({
                        content: t(
                            'trackerSettings:DISPLAY.MESSAGES.UPDATE_SUCCESS',
                        ),
                        type: 'success',
                    });
                });
            } else {
                showNotification({
                    content: t('commonActions:MESSAGES.UPDATE_ERROR_UNKNOWN'),
                    type: 'error',
                });
            }
            setLoading(false);
        });
    };

    const renderLoading = () => {
        if (loading) {
            return <ClipLoader color={'orange'} loading={true} size={50} />;
        } else {
            return (
                <Button
                    onClick={() => {
                        handleSaveCroppedImg();
                    }}
                >
                    {t('SAVE')}
                </Button>
            );
        }
    };

    const renderModal = () => {
        if (modal) {
            return (
                <Modal onClosed={() => setModal(false)}>
                    {!!imgSrc && (
                        <ReactCrop
                            crop={crop}
                            //@ts-ignore
                            onChange={(_: any, percentCrop: number) => setCrop(percentCrop)}
                            onComplete={(c: any) => setCompletedCrop(c)}
                            aspect={aspect}
                            locked
                            circularCrop
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                    <div style={{ display: 'none' }}>
                        {!!completedCrop && (
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    border: '1px solid black',
                                    objectFit: 'contain',
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                }}
                            />
                        )}
                    </div>
                    {renderLoading()}
                </Modal>
            );
        }
    };

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                );
            }
        },
        100,
        [completedCrop],
    );

    return (
        <div>
            {renderModal()}
            <div className={styles.row}>
                <label className={styles.input}>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const files = e.target.files;
                            setModal(true);
                            onSelectFile(e);
                        }}
                    />
                    <DownloadIcon
                        style={{
                            width: '4rem ',
                            borderRadius: '50%',
                            height: '4rem',
                        }}
                    />
                </label>
                {logo || urlPicture ? (
                    <IconBadge
                        className={cx(
                            styles.item,
                            activeIconId === URLPictureAttribute
                                ? [styles.active]
                                : '',
                        )}
                        icon={
                            <img
                                className={styles.iconUrl}
                                onClick={onIconSelected}
                                // @ts-ignore
                                src={logo ? logo : urlPicture}
                                alt=""
                            />
                        }
                        color={''}
                    />
                ) : null}
            </div>
        </div>
    );
};

export const ImageCropper = ImageCropperComponent;
