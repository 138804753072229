import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../utils/with-selected-tracker';
import { Dispatch, IRootState } from '../store/store';
import { TrackerType } from '../trackers/interfaces';
import { makeGetTrackerModel } from '../trackers/selectors/get-tracker-model';
import {
    getPostionDefaultModeMappingFromTrackerType,
    PositionMode,
} from './position-default-mode-mapping';

interface IStateProps {
    type: TrackerType;
}

interface IActions {
    enableHeatmap(): unknown;
    enablePath(): unknown;
}

const mapState = (
    state: IRootState,
    props: IWithSelectedTracker,
): IStateProps => {
    const getTrackerModel = makeGetTrackerModel(props.trackerId!);

    return {
        type: getTrackerModel(state)!.type,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    enableHeatmap: () => dispatch.history.activateHeatMap(),
    enablePath: () => dispatch.history.activatePathMode(),
});

export const withDefaultPositionModeResolver = compose<
    { trackerId: string },
    {}
>(
    withSelectedTracker,
    connect(mapState, mapDispatch),
    lifecycle<IActions & IStateProps, {}, {}>({
        componentDidMount() {
            const { type, enableHeatmap, enablePath } = this.props;
            const defaultMode = getPostionDefaultModeMappingFromTrackerType(
                type,
            );

            if (defaultMode === PositionMode.PATH) {
                enablePath();
            } else {
                enableHeatmap();
            }
        },
    }),
);
