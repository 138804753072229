import { get } from 'lodash';
import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useMatch } from 'react-router';
import { mapProps } from 'recompose';
import { compose } from 'redux';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../utils/with-selected-tracker';

import { Dispatch, IRootState } from '../../store/store';
import { ITracker } from '../../trackers/interfaces-api';
import { makeGetTrackerModel } from '../../trackers/selectors/get-tracker-model';
import { makeGetSubscriptionDetailsFromTracker } from '../../user-subscriptions/selectors/user-subscriptions.selectors';

interface IState {
    subscriptionFetched: boolean;
    trackerModel: ITracker;
}

interface IActions {
    fetchSubscriptionDetails: (id: number) => Promise<any>;
}

interface IMappedActions {
    fetchSubscriptionDetails: () => Promise<any>;
}

/**
 * Detect subscription route in url and fetch data if not exist yet
 */
export const withSubscriptionResolvingRaw = (
    Component: ComponentType<any>,
) => ({
    trackerId,
    subscriptionFetched,
    fetchSubscriptionDetails,
    trackerModel,
    ...props
}: IMappedActions & IState & IWithSelectedTracker) => {
        const match = useLocation()
        useEffect(() => {
            if (
                match.pathname === '/trackers/:id/subscription' &&
                !subscriptionFetched
            ) {
                fetchSubscriptionDetails();
            }
        }, [match.pathname, fetchSubscriptionDetails, subscriptionFetched]);

        return <Component {...props} />;
    };

const mapState = (state: IRootState, props: IWithSelectedTracker): IState => {
    const getSubscription = makeGetSubscriptionDetailsFromTracker(
        props.trackerId!,
    );

    const getTrackerModel = makeGetTrackerModel(props.trackerId!);

    return {
        subscriptionFetched: Boolean(getSubscription(state)),
        trackerModel: getTrackerModel(state) as ITracker,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    fetchSubscriptionDetails: async (subId: number) =>
        dispatch.userSubscriptions.fetchSubscriptionDetails(subId),
});

export const withSubscriptionResolving = compose(
    connect(mapState, mapDispatch),
    mapProps((props: IActions & IWithSelectedTracker & IState) => ({
        ...props,
        fetchSubscriptionDetails: () => {
            const subId = get(props, 'trackerModel.subscription.id');

            if (!subId) {
                return () => { };
            }

            return props.fetchSubscriptionDetails(subId);
        },
    })),
    withSelectedTracker,
    withSubscriptionResolvingRaw,
);
