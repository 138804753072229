import { makeGetSubscriptionsOffersVariants } from 'app/subscription-offers/selectors/subscription-offers-selectors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { compose } from 'recompose';

import { Button } from '../../../common/button/button.component';
import { Input } from '../../../common/input/input.component';
import { Link } from '../../../common/link/link.component';
import { ReactComponent as DashIcon } from '../../../icons/dash.svg';
import { ReactComponent as GiftCardIcon } from '../../../icons/gift-card.svg';
import { Dispatch, IRootState } from '../../../store/store';
import { ISubscriptionOffer } from '../../../subscription-offers/interfaces';
import { ITracker } from '../../../trackers/interfaces-api';
import { IVerifyPromoCodeResponse } from '../../interfaces';
import { makeGetRegisteredTracker } from '../../selectors/subscription-process-selectors';
import { RoutesSteps } from '../../subscription-process-page/subscription-process-page.component';
import styles from './gift-card-step.module.scss';

interface IActions {
    setBasketPromoCode: (promoCodeId: number, discount: number) => Promise<any>;
    setSelectedSubscriptionVariant: (
        offerId: number,
        code: string,
    ) => Promise<any>;
    verifyPromoCode: (
        promoCode: string,
        subscriptionOfferId: number,
    ) => Promise<any>;
}

interface IState {
    language: string;
    registeredTracker: ITracker | null;
    subscriptionOfferId: number;
    offers: ISubscriptionOffer[];
}

export interface IGiftCardStepProps
    extends IActions,
    IState { }

export const GiftCardStep = ({
    offers,
    registeredTracker,
    subscriptionOfferId,
    language,
    setBasketPromoCode,
    setSelectedSubscriptionVariant,
    verifyPromoCode,
}: IGiftCardStepProps) => {
    const history = useNavigate()
    const { id } = useParams()
    const errorT = useTranslation('errors').t;

    const commonT = useTranslation('commonActions').t;
    const subscriptionPaymentT = useTranslation('subscriptionPayment').t;
    const [giftCardCode, setGiftCardCode] = useState('');
    const [displayedCode, setDisplayedCode] = useState('');
    const [giftCardError, setGiftCardError] = useState(false);
    const offerURI = RoutesSteps.OFFERS + id;
    const optionsURI =
        RoutesSteps.OPTIONS + id;
    const getHelpLink = () => {
        const helpMap = {
            fr: 'https://help.weenect.com/hc/fr',
            nl: 'https://help.weenect.com/hc/en-gb',
            de: 'https://help.weenect.com/hc/de',
            en: 'https://help.weenect.com/hc/en-gb',
            es: 'https://help.weenect.com/hc/en-gb',
            it: 'https://help.weenect.com/hc/en-gb',
        };
        const matchedKeyValue = Object.entries(helpMap).find((keyValue) => {
            const key = keyValue[0];
            return key === language;
        });
        if (matchedKeyValue) {
            return matchedKeyValue[1];
        }
        // Fallback to EN
        return helpMap.en;
    };

    const onGoBack = () => {
        history(offerURI);
    };
    const giftCardCodeLength = 12;
    const giftCardSeparatorIndex = 4;

    // This useEffect is triggered each time the giftCardCode is modified.
    // It handles the logic concerning the dash ('-') auto-complete,
    // when customer fills in their gift card code.
    useEffect(() => {
        if (!giftCardCode.length) {
            return setDisplayedCode('');
        }
        const parts = giftCardCode.match(/.{1,4}/g);
        if (
            giftCardCode.length &&
            giftCardCode.length % giftCardSeparatorIndex === 0 &&
            giftCardCode.length < giftCardCodeLength
        ) {
            if (parts !== null) {
                const newValue = parts.join('-'); // returns 123-456-789
                return setDisplayedCode(newValue + '-');
            }
        }
        if (parts !== null) {
            const newValue = parts.join('-'); // returns 123-456-789
            return setDisplayedCode(newValue);
        }
    }, [giftCardCode]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // note : This forbid dash inputs by the customer
        setGiftCardCode(e.currentTarget.value.split('-').join(''));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // Needed to allow deletion of auto generated dash by the useEffect.
        if (e.keyCode === 8) {
            // backspace
            setGiftCardCode(giftCardCode.slice(0, -1));
        }
    };

    const onGiftCardSubmit = () => {
        verifyPromoCode(giftCardCode, subscriptionOfferId)
            .then((resp: IVerifyPromoCodeResponse) => {
                const offerFound = offers.find(
                    (offer) =>
                        offer.id ===
                        resp.promo_code_rule_offer.id_subscription_offer,
                );
                if (offerFound !== undefined) {
                    setSelectedSubscriptionVariant(
                        offerFound.id,
                        offerFound?.code,
                    );
                }
                setBasketPromoCode(resp.promo_code.id, resp.discount).then(() =>
                    history(optionsURI),
                );
            })
            .catch(() => setGiftCardError(true));
    };

    return (
        <div className={styles.container}>
            <span className={styles.backSpan} onClick={() => onGoBack()}>
                <div className={styles.headerBackArrow}>
                    <DashIcon className={styles.backIcon} />
                    {commonT('BACK')}
                </div>
            </span>

            <div className={styles.explanation}>
                <span className={styles.title}>
                    {subscriptionPaymentT('GIFT_CARD.TITLE')}
                </span>

                <span className={styles.light}>
                    {subscriptionPaymentT('GIFT_CARD.EXPLANATION')}
                </span>
            </div>

            <div className={styles.inputs}>
                <Input
                    nativeFieldClass={styles.giftCardInput}
                    placeholder="Ex : AA00-BB11-CC22"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    maxLength={14}
                    value={displayedCode}
                    isValid={!giftCardError}
                    errorMessage={giftCardError ? errorT('PROMO_CODE') : ''}
                />
                <Button
                    primary
                    className={styles.giftCardButton}
                    onClick={() => onGiftCardSubmit()}
                >
                    {subscriptionPaymentT('GIFT_CARD.USE')}
                </Button>
            </div>

            <div className={styles.help}>
                <Link href={getHelpLink()} target="_blank">
                    {subscriptionPaymentT('GIFT_CARD.NEED_HELP')}
                </Link>
            </div>

            <div className={styles.giftCardIcon}>
                <GiftCardIcon />
            </div>
        </div>
    );
};

const makeMapState = () => {
    const getRegisteredTracker = makeGetRegisteredTracker();
    const getSubscriptionOffersVariants = makeGetSubscriptionsOffersVariants();
    return (state: IRootState): IState => ({
        language: state.language.selectedLanguage,
        offers: getSubscriptionOffersVariants(state),
        registeredTracker: getRegisteredTracker(state),
        subscriptionOfferId:
            state.subscriptionProcess.choices.subscriptionOfferId,
    });
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    verifyPromoCode: async (promoCode: string, offerId: number) =>
        dispatch.subscriptionProcess.verifyPromoCode({
            promoCode,
            subscriptionOfferId: offerId,
            is_gift_card_code: true,
        }),
    setBasketPromoCode: async (promoCodeId: number, discount: number) => {
        dispatch.subscriptionProcess.setPromoCode({
            promoCodeId,
            discount,
            isGiftCard: true,
        });
    },
    setSelectedSubscriptionVariant: async (offerId: number, code: string) => {
        dispatch.subscriptionProcess.setSelectedSubscriptionVariant({
            subscriptionOfferCode: code,
            subscriptionOfferId: offerId,
        });
    },
});

export const ConnectedGiftCardStep = compose<IGiftCardStepProps, {}>(
    connect(makeMapState, mapDispatch),
)(GiftCardStep);
