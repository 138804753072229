import { apiService } from '../api/api.service';

const loginEndpoint = '/user/login';
const mailLoginEndpoint = '/auth/login/mail';

export interface ILoginResponse {
    access_token: string;
    expires_in: string;
    refresh_token: string;
}

export const requestLogin = async (
    username: string,
    password: string,
): Promise<ILoginResponse> => {
    try {
        const response = await apiService.fetch(loginEndpoint, {
            method: 'POST',
            body: JSON.stringify({
                username,
                password,
            }),
        });

        if (response.status !== 200) {
            throw Error('Error logging in');
        }

        return response.json();
    } catch (err) {
        return Promise.reject(err);
    }
};

export const requestMailTokenLogin = async (
    token: string,
): Promise<ILoginResponse> => {
    try {
        const response = await apiService.fetch(mailLoginEndpoint, {
            method: 'POST',
            body: JSON.stringify({
                mail_token: token,
            }),
        });
        if (response.status !== 200) {
            throw Error('Error logging in');
        }
        return response.json();
    } catch (err) {
        return Promise.reject(err);
    }
};

export const saveSession = (session: ILoginResponse, perm?: boolean) => {
    if (perm) {
        localStorage.setItem('session', JSON.stringify(session));
    } else {
        sessionStorage.setItem('session', JSON.stringify(session));
    }
};

export const getSession = (): ILoginResponse | null => {
    let session = null;

    try {
        session = JSON.parse(localStorage.getItem('session') as string);
    } catch (e) {
        console.log('No session saved in local storage');
    }

    if (!session) {
        try {
            session = JSON.parse(sessionStorage.getItem('session') as string);
        } catch (e) {
            console.log('No session saved in session storage');
        }
    }

    return session;
};

export const killSession = () => {
    localStorage.removeItem('session');
    sessionStorage.removeItem('session');
};
