import { createSelector } from 'reselect';

import { ITracker } from '../interfaces-api';
import { mapTrackersToMap } from '../mappers/trackers-to-map';
import { trackersFromApi } from './selectors';

export const makeGetTrackersOnMap = () =>
    createSelector(
        trackersFromApi,
        (trackers: ITracker[]) => mapTrackersToMap(trackers),
    );
