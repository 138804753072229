import { get } from 'lodash';
import { createSelector } from 'reselect';

import { makeGetTrackerModel } from './get-tracker-model';

export const makeGetFrequencyModeAvailable = (trackerId: number) => {
    const getTrackerModel = makeGetTrackerModel(trackerId);

    return createSelector(
        getTrackerModel,
        (tracker): boolean | '' | null | undefined => {
            return (
                tracker &&
                tracker.last_freq_mode &&
                get(tracker, 'position[0].is_online') &&
                tracker.features.includes('mode_selection')
            );
        },
    );
};
