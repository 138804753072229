import React from 'react';
import { compose } from 'recompose';

import { ConnectedNavHeader } from '../../layout/nav-header/nav-header.component';
import { AccountRoutes } from '../account-routes';
import { AccountNavigationWithRouter } from '../components/account-navigation/account-navigation.component';
import styles from './account-page.module.scss';

const AccountPage = () => {
    return (
        <div className={styles.page}>
            <ConnectedNavHeader />
            <div className={styles.wrapper}>
                <div className={styles.leftColumn}>
                    <AccountNavigationWithRouter />
                </div>
                <div className={styles.rightColumn}>
                    <AccountRoutes />
                </div>
            </div>
        </div>
    );
};

export const ConnectedAccountPage = compose()(AccountPage);
