// Order in this enum is important as we display step from 'greet' to 'sidePages'
export enum StepToShow {
    GREET = 'greet',
    TUTORIAL_START = 'tutorialStart',
    MAP_DISPLAY_SETTINGS = 'mapDisplaySettings',
    MAP_UPDATE = 'mapUpdate',
    SUPERLIVE = 'superLive',
    TRACKER_INTERACTIONS = 'trackerInteractions',
    GPS_INTERVAL = 'gpsUpdate',
    SIDE_PAGES = 'sidePages',
}
