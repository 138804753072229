import { ArcElement, Chart } from 'chart.js';
import React, { ReactElement } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { isMobile } from 'react-device-detect';

import styles from './doughnut.module.scss';

Chart.register(ArcElement);

interface IOuterProps {
    activityPercentage: number;
    awakeningPercentage: number;
    restPercentage?: number;
    helperText?: string;
    icon?: ReactElement;
}

export const DoughnutComponent = ({
    activityPercentage,
    awakeningPercentage,
    restPercentage,
    helperText,
    icon,
}: IOuterProps) => {
    const renderIcon = () => {
        if (icon) {
            return <div className={styles.icon}>{icon}</div>;
        }
    };
    const renderText = () => {
        if (helperText) {
            return (
                <div
                    className={styles.textHelper}
                    style={
                        activityPercentage === 100 && icon
                            ? { color: '#99ca3a' }
                            : { color: '' }
                    }
                >
                    {' '}
                    {helperText}
                </div>
            );
        }
    };
    const Options = {
        plugins: {
            tooltip: {
                enabled: false,
            },
        },
        cutout:
            !isMobile && !helperText
                ? '70%'
                : isMobile && helperText
                ? 85
                : '80%',
        responsive: true,
    };
    const data = {
        type: 'doughnut',
        datasets: [
            {
                weight: 0.2,
                backgroundColor: [
                    activityPercentage === 100 && icon
                        ? '#99ca3a'
                        : 'rgba(237, 110, 24, 1)',
                    restPercentage ? 'rgba(153, 73, 255, 1)' : '#E8E8E8',
                    restPercentage ? 'rgba(53, 50, 198, 1)' : '',
                ],
                data: [activityPercentage, awakeningPercentage, restPercentage],
            },
        ],
    };
    return (
        <div className={icon ? styles.container : ''}>
            <Doughnut data={data} options={Options} />
            {renderIcon()}
            {renderText()}
        </div>
    );
};
