import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/button/button.component';

import { ReactComponent as EditIcon } from '../../../icons/subscrption.svg';

import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import styles from '../subscription-details.module.scss';

export interface ISubscriptionDetailsManageButton { }

export const SubscriptionDetailsManageButton = ({
}: ISubscriptionDetailsManageButton) => {
    const { t } = useTranslation('subscriptions');
    const match = useLocation()
    const history = useNavigate()
    const onManageSubscription = () => {
        if (match.pathname.includes('/subscription')) {
            history(match.pathname.replace('/subscription', '/manage'));
        } else if (!match.pathname.includes('/manage')) {
            history((match.pathname += '/manage'));
        }
    };

    return (
        <Button
            iconLeft={<EditIcon />}
            small
            className={styles['manage-button']}
            secondary
            onClick={onManageSubscription}
        >
            <>{t('DETAILS.ACTIVE_CASE.MANAGE_BUTTON')}</>
        </Button>
    );
};

export const ConnectedSubscriptionDetailsManageButton = SubscriptionDetailsManageButton;
