import moment from 'moment';
import { apiService } from '../../api/api.service';
import { ITrackerEvent } from '../../trackers/interfaces-api';
import { DistanceMetric, MetricSystem } from '../interfaces';

interface IActivityInfo {
    positions: ITrackerEvent[];
    distance: number;
    distance_metric: DistanceMetric;
    max_speed: number;
    mean_speed: number;
    min_speed: number;
    metric_system: MetricSystem;
}

export const fetchActivityInfo = (
    trackerId: number,
    startDate: string,
    endDate: string,
    preferredMetric: string,
): Promise<IActivityInfo> =>
    apiService
        .fetch(
            `/mytracker/${trackerId}/activity/v2?metric_system=${preferredMetric}&start=${startDate}&end=${endDate}`,
        )
        .then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                return resp.json();
            }

            return Promise.reject(resp);
        })
        .then((data: IActivityInfo) => {
            const inTimeRangePositions = filterByIncludedDateTracker(
                data.positions,
                startDate,
                endDate,
            );
            return {
                ...data,
                positions: inTimeRangePositions.sort((a, b) =>
                    a.date_server < b.date_server ? 1 : -1,
                ),
            };
        });

export const fetchPositions = (
    trackerId: number,
    startDate: string,
    endDate: string,
): Promise<ITrackerEvent[]> =>
    apiService
        .fetch(
            `/mytracker/${trackerId}/position?start=${startDate}&end=${endDate}`,
        )
        .then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                return resp.json();
            }

            return Promise.reject(resp);
        })
        .then((data: ITrackerEvent[]) => {
            const inTimeRangePositions = filterByIncludedDateTracker(
                data,
                startDate,
                endDate,
            );
            return inTimeRangePositions.sort((a, b) =>
                a.date_server < b.date_server ? 1 : -1,
            );
        });

/**
 * https://redmine.weenect.com/issues/5568
 * We filter received positions when their 'date_tracker' is included in selected time range.
 */
const filterByIncludedDateTracker = (
    positions: ITrackerEvent[],
    startDate: string,
    endDate: string,
) =>
    positions.filter((position) =>
        moment(position.date_tracker).isBetween(startDate, endDate),
    );
