import cx from 'classnames';
import React, { HTMLAttributes } from 'react';

import { ReactComponent as TickIcon } from '../../icons/tick.svg';
import { Theme } from '../../trackers/interfaces';

import styles from './steps.module.scss';

export interface IStepsProps extends HTMLAttributes<HTMLDivElement> {
    activeIndex: number;
    steps: string[];
}

export const Steps = ({
    activeIndex,
    steps,
    className,
    ...props
}: IStepsProps) => {
    const renderMarkerContent = (index: number) => {

        if (index < activeIndex) {
            return <TickIcon />;
        }
        return index + 1;
    };

    const theme = document.documentElement.getAttribute('data-theme');
    if (theme === Theme.BIOGARAN) {
        const index = 2;
        steps[0] = 'Boitier';
        steps.splice(index, 1);
    }
    if (!theme) {
        steps.splice(0, 1);
    }

    return (
        <div className={cx(styles.steps, className)} {...props}>
            {steps.map((step, index) => (
                <div
                    className={
                        theme === Theme.BIOGARAN
                            ? cx(styles.biogaran, {
                                [styles.completed]: index < activeIndex,
                                [styles.active]: index === activeIndex,
                            })
                            : cx(styles.step, {
                                [styles.completed]: index < activeIndex,
                                [styles.active]: index === activeIndex,
                            })
                    }
                    key={step}
                >
                    <div className={styles.marker}>
                        {renderMarkerContent(index)}
                    </div>
                    <div
                        className={
                            theme === 'biogaran'
                                ? cx(styles.biogaranText, {
                                    [styles.active]: index === activeIndex,
                                })
                                : cx(styles.weenectText, {
                                    [styles.active]: index === activeIndex,
                                })
                        }
                    >
                        {step}
                    </div>
                </div>
            ))}
        </div>
    );
};
