import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';

import { BackLink } from '../common/back-link/back-link.component';
import { PageContainer } from '../layout/page-container/page-container.component';
import { SimpleHeaderWithLogout } from '../layout/simple-header/simple-header.component';
import { trackersRoute } from '../routing/routes';
import { Dispatch, IRootState } from '../store/store';
import { TrackerType } from '../trackers/interfaces';
import { makeGetTrackerModel } from '../trackers/selectors/get-tracker-model';
import { ConnectedFluidTypeSwitch } from './component/fluid-type-switch.component';

interface IStateProps {
    currentTrackerType: TrackerType | undefined;
    activeTracker: number | undefined;
}

interface IOuterProps { }

export interface IFluidTypeSwitchPage extends IStateProps, IOuterProps { }

export const FluidTypeSwitchPage = ({
    currentTrackerType,
    activeTracker,
}: IFluidTypeSwitchPage) => {
    const { t } = useTranslation('transferPage');
    const history = useNavigate()
    const redirectOnMap = () => {
        history(trackersRoute);
    };

    return (
        <div>
            <SimpleHeaderWithLogout
                leftSlot={
                    <RouterLink to={trackersRoute}>
                        <BackLink black collapseOnMobile>
                            {t('BACK_TO_TRACKERS')}
                        </BackLink>
                    </RouterLink>
                }
            />

            <PageContainer>
                <ConnectedFluidTypeSwitch
                    trackerToUpdate={activeTracker}
                    currentTrackerType={currentTrackerType!}
                    typeSwitchOutcome={() => redirectOnMap()}
                />
            </PageContainer>
        </div>
    );
};

const mapState = () => {
    return (state: IRootState): IStateProps => {
        const currentTracker = state.userTrackers.rawTrackers.find(
            (g) =>
                g.type === TrackerType.FAMILY || g.type === TrackerType.ANIMAL,
        )?.id;
        const getTrackerModel = makeGetTrackerModel(currentTracker!);
        if (currentTracker) {
            return {
                activeTracker: currentTracker,
                currentTrackerType: getTrackerModel(state)!.type,
            };
        }
        return {} as IStateProps;
    };
};
const mapDispatch = (dispatch: Dispatch) => ({});

export const ConnectedFluidTypeSwitchPage = compose<IFluidTypeSwitchPage, {}>(
    connect(mapState, mapDispatch),
)(FluidTypeSwitchPage);
