/**
 * https://redmine.weenect.com/issues/4985
 */
import { ITrackerEvent, TrackerEventType } from '../../trackers/interfaces-api';

const eventsOnMap: TrackerEventType[] = [
    'ALM-A',
    'ALM-BAT',
    'ALM-DSW',
    'CMD-WBT',
    'ALM-F',
    'CMD-WMAC',
    'ALM-G',
    'ALM-GEO-I',
    'ALM-GEO-O',
    'ALM-H',
    'ALM-V',
    'CMD-C',
    'CMD-F',
    'CMD-R',
    'CMD-T',
    'CMD-V3',
    'CMD-V30',
    'CMD-W',
    'CMD-X',
];

export const filterEventsOnMap = (event: ITrackerEvent) =>
    eventsOnMap.includes(event.type) &&
    event.valid_signal &&
    // https://redmine.weenect.com/issues/6040
    // Remove positions that have no coordinates set as we can't display it on map
    event.latitude !== null &&
    event.longitude !== null;
