import cx from 'classnames';
import React, { HTMLAttributes } from 'react';

import { ReactComponent as CrossIcon } from '../../icons/plus.svg';

import styles from './notification-bar.module.scss';

export interface INotificationBarProps extends HTMLAttributes<HTMLDivElement> {
    type: 'error' | 'success'; // TODO: Introduce more types when designs provide them
    onClose?: () => any;
}

/**
 * TODO: Implement some auto-closing feature (after some time)
 */
export const NotificationBar = ({
    className,
    type,
    children,
    onClose,
    ...props
}: INotificationBarProps) => {
    const maybeRenderCloseButton = () => {
        if (onClose) {
            return (
                <CrossIcon
                    role="button"
                    className={styles.cross}
                    onClick={onClose}
                />
            );
        }
    };
    return (
        <div className={cx(styles.notificationBar, styles[type], className)}>
            {children}
            {maybeRenderCloseButton()}
        </div>
    );
};
