import {
    ISubscriptionOffer,
    SubscriptionVariantCode,
} from '../../subscription-offers/interfaces';
import { IRawUserSubscription } from '../../user-subscriptions/interfaces-api';
import { IUserChoices } from '../subscription-process.store';

/**
 * Get existing subscription saved in database and retrieve subscription process
 */
export const createNewSubscriptionToUserChoices = (
    subscriptionVariants: ISubscriptionOffer[],
) =>
    function newSubscriptionToUserChoices(
        sub: IRawUserSubscription,
    ): IUserChoices {
        const mapOfferIdToSubscriptionCode = (
            id: number,
        ): SubscriptionVariantCode => {
            const variant = subscriptionVariants.find((v) => v.id === id);

            if (!variant) {
                return '36_month_1_tracker'; // Fallback
            }

            return variant.code;
        };

        return {
            trackerId: sub.trackers[0],
            selectedOptions: sub.options
                .filter((opt) => opt.activated) // Filter only activated options
                .map((activatedOpt) => activatedOpt.code), // Return SubscriptionOptionType elements
            selectedAccountOptions: sub.options
                .filter((accountOptionsOffers) => accountOptionsOffers.activated)
                .map((activatedAccountOptionsOffers) => activatedAccountOptionsOffers.code),
            selectedPaymentCode: sub.payment_mean,
            subscriptionCode: mapOfferIdToSubscriptionCode(sub.offer_id),
            subscriptionOfferId: sub.offer_id,
            promoCodeDiscount: null, // TODO change this so we retrieve promo-code from subscription
            promoCodeId: null,
            promoCodeIsGiftCard: null,
        };
    };
