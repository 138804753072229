import cx from 'classnames';
import React, { ReactNode } from 'react';
import { SliderItem } from 'react-compound-slider';
import styles from './slider.module.scss';

interface ITickProps {
    tick: SliderItem;
    count: number;
    format: (d: number) => ReactNode;
    active: boolean;
    onClick(): unknown;
}

export const Tick = ({
    tick,
    count,
    format = (v: number) => v,
    active,
    onClick,
}: ITickProps) => {
    return (
        <div
            onClick={onClick}
            className={cx(styles.value, {
                [styles.active]: active,
            })}
            style={{
                marginLeft: `${-(100 / count) / 2}%`,
                width: `${100 / count}%`,
                left: `${tick.percent}%`,
            }}
        >
            {format(tick.value)}
        </div>
    );
};
