import { createSelector } from 'reselect';

import { ITrackerOnMap } from '../interfaces';
import { makeGetTrackersOnMap } from './get-trackers-on-map';

export const makeGetTrackersPositions = () => {
    const trackersOnMap = makeGetTrackersOnMap();
    return createSelector(
        trackersOnMap,
        (trackers) =>
            trackers.map((tracker: ITrackerOnMap) => tracker.position),
    );
};
