import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../common/button/button.component';
import { Typography } from '../../../common/typography/typography.component';
import { Theme } from '../../../trackers/interfaces';
import { ISubscriptionDetails } from '../../../user-subscriptions/interfaces';
import styles from './cancel-subscription-block.module.scss';

export interface IOuterProps extends HTMLAttributes<HTMLDivElement> {
    subscription: ISubscriptionDetails;
    theme?: Theme | null;
    onCancelClicked: () => any;
}

interface ICancelSubscriptionBlockProps extends IOuterProps {}

export const CancelSubscriptionBlock = ({
    subscription,
    onCancelClicked,
    theme,
}: ICancelSubscriptionBlockProps) => {
    const manageSubscriptionT = useTranslation('manageSubscription').t;
    const maybeRenderNoticeText = () => {
        if (subscription.isUnderNoticePeriod === 'STANDARD') {
            return (
                <div>
                    <Typography className={styles['not-cancelable']}>
                        {manageSubscriptionT('CANT_RESIGN_UNDER_NOTICE')}
                    </Typography>
                </div>
            );
        }
    };

    const maybeRenderCancellation = () => {
        if (subscription.status === 'active') {
            return (
                <React.Fragment>
                    <Button
                        secondary
                        className={
                            theme === Theme.BIOGARAN
                                ? styles.buttonCancel
                                : styles.button
                        }
                        large
                        onClick={() => onCancelClicked()}
                        disabled={subscription.isUnderNoticePeriod !== 'NONE'}
                    >
                        {manageSubscriptionT('CANCEL_RENEWAL_BIS')}
                    </Button>
                    {maybeRenderNoticeText()}
                </React.Fragment>
            );
        }
    };

    return <React.Fragment>{maybeRenderCancellation()}</React.Fragment>;
};
