import { apiService } from '../../api/api.service';
import { ITracker } from '../../trackers/interfaces-api';

const endpoint = '/mytracker/register';

export const registerTracker = ({
    name,
    imei,
}: {
    name: string;
    imei: string;
}): Promise<ITracker> => {
    return apiService
        .fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify({
                name,
                imei,
                /**
                 * Timezone feature is no longer used, but backend still has this WIP.
                 * When backend removes this from necessary fields, can be removed from payload
                 */
                timezone: 'Etc/UTC',
            }),
        })
        .then(async (resp) => {
            if (resp.status === 200) {
                return resp.json();
            }

            try {
                const errorBody = await resp.json();

                return Promise.reject(errorBody);
            } catch (err) {
                return Promise.reject(err || { message: 'Unknown error' });
            }
        });
};
