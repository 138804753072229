import { Period } from '../app/activity-tracking/interface';
import { MonthTranslated } from '../app/animal-info/interfaces';
import { getGlobalT } from '../i18n/setup-translations';

export interface IResponseFormat {
    startDate: string;
    endDate: string;
    translatedPeriod: string;
}
const t = getGlobalT();

export const getWeek = (startDate: Date) => {
    const date: Date = new Date(startDate);
    const onejan: any = new Date(date.getFullYear(), 0, 1);
    const numberOfDays = Math.floor(
        ((date as any) - onejan) / (24 * 60 * 60 * 1000),
    );
    return Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
};

const lastday = (y: any, m: any) => {
    return new Date(y, m + 1, 0).getDate();
}
export const getTimezone = (date: Date) => {
    const offset = date.getTimezoneOffset();
    const o = Math.abs(offset);
    return Math.floor(o / 60);
};
// return yesteday date at midnight  and current date at midnight for api call
export const setDatesMidnightToMidnight = (
    firstDate: Date,
    period?: string,
): string[] => {
    const start = new Date(firstDate);
    const end = new Date(firstDate);
    period === Period.MONTH
    if (period === Period.MONTH) {
        start.setDate(start.getMonth() + 1)
        let day = lastday(start.getFullYear(), end.getMonth())
        start.setDate(day)
    }
    else {
        end.setDate(end.getDate() + 1)
    }
    end.setHours(0, 0, 0, 0);
    end.toString();
    const endFormated = end.toISOString();
    const startFormated = start.toISOString();
    return [startFormated, endFormated];
};
export const setDateToday = (): string[] => {
    const firstDate = new Date();
    const secondDate = new Date();
    firstDate.setHours(0, 0, 0, 0);
    firstDate.toString();
    const end = secondDate.toISOString();
    const start = firstDate.toISOString();
    return [start, end];
};

const translateMonth = (monthSelected: Date): string =>
    MonthTranslated[monthSelected.getMonth()];

const getDayTrans = (day: Date, withMonth: boolean): string | number =>
    withMonth
        ? day.getDate() + ' ' + t(`activityOnboarding:${translateMonth(day)}`)
        : day.getDate();

// funct that take minute and convert it to return day, hours , minutes value with translate
export const convertDateUtc = (
    periodSelected: Period,
    startDate: Date,
    endDate?: Date,
): IResponseFormat | null | undefined => {
    const now = new Date();
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : null;
    let apiDate;

    if (periodSelected === Period.MONTH) {
        apiDate = setDatesMidnightToMidnight(startDate, Period.MONTH);
        return {
            startDate: apiDate[1],
            endDate: apiDate[0],
            translatedPeriod:
                t(`activityOnboarding:${translateMonth(startDate)}`) +
                ' ' +
                startDate.getFullYear(),
        };
    }
    if (periodSelected === Period.WEEK && end) {
        const isDatesOnSameMonth =
            MonthTranslated[startDate.getMonth()] !==
            MonthTranslated[end.getMonth()];
        const month = !isDatesOnSameMonth
            ? t(`activityOnboarding:${translateMonth(end)}`)
            : null;

        return {
            startDate: startDate.toISOString(),
            endDate: end.toISOString(),
            translatedPeriod:
                startDate.toDateString() === now.toDateString()
                    ? t('activity:SUMMARY.LASTWEEK')
                    : t('activity:SUMMARY.DATEINTERVAL', {
                        dayFrom: getDayTrans(startDate, isDatesOnSameMonth),
                        dayTo: getDayTrans(end, isDatesOnSameMonth),
                        month,
                    }),
        };
    }
    if (startDate && periodSelected === Period.DAY) {
        // today logic (return yesterday midnight to today current hours)
        if (now.toDateString() === startDate.toDateString()) {
            apiDate = setDatesMidnightToMidnight(startDate);
            return {
                startDate: apiDate[0],
                endDate: apiDate[1],
                translatedPeriod: t('activity:SUMMARY.TODAY'),
            };
        }
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (yesterday.toDateString() === now.toDateString()) {
            apiDate = setDatesMidnightToMidnight(start);
            return {
                startDate: apiDate[0],
                endDate: apiDate[1],
                translatedPeriod: t('activity:SUMMARY.YESTERDAY'),
            };
        }
        if (startDate < now) {
            // date in the past
            apiDate = setDatesMidnightToMidnight(start);
            return {
                startDate: apiDate[0],
                endDate: apiDate[1],
                translatedPeriod:
                    startDate.getDate() +
                    ' ' +
                    t(`activityOnboarding:${translateMonth(startDate)}`),
            };
        } else {
            // not suppose to happen may retrun null or just the translated date
            return {
                startDate: now.toISOString(),
                endDate: now.toISOString(),
                translatedPeriod:
                    startDate.getDate() +
                    ' ' +
                    t(`activityOnboarding:${translateMonth(startDate)}`),
            };
        }
    }
};
