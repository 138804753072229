import { createSelector } from 'reselect';
import { ITrackerOnMap } from '../interfaces';
import { ITracker } from '../interfaces-api';
import { makeGetTrackersOnMap } from './get-trackers-on-map';
import { activeTrackerId, trackersFromApi } from './selectors';

export const makeGetSelectedTrackerToMapModel = () => {
    const getTrackerOnMap = makeGetTrackersOnMap();
    return createSelector(
        activeTrackerId,
        getTrackerOnMap,
        (
            trackerId: number | null,
            trackers: ITrackerOnMap[],
        ): ITrackerOnMap | null => {
            const tracker = trackers.find((t) => t.id === trackerId);

            if (tracker) {
                return tracker;
            }

            return null;
        },
    );
};

export const makeGetSelectedTrackerModel = () =>
    createSelector(
        activeTrackerId,
        trackersFromApi,
        (trackerId: number | null, trackers: ITracker[]): ITracker | null => {
            const tracker = trackers.find((t) => t.id === trackerId);

            if (tracker) {
                return tracker;
            }

            return null;
        },
    );
