import { ModelConfig } from '@rematch/core';
import { set, uniqBy } from 'lodash';
import moment from 'moment';

import { IRootState, store } from '../store/store';
import { ITrackerEvent } from '../trackers/interfaces-api';
import { DistanceMetric, MetricSystem, TimeInterval } from './interfaces';
import { getIsDateToday } from './selectors';
import { fetchActivityInfo, fetchPositions } from './services/history.service';

interface IMetaParamsMeters {
    totalDistance: number;
    distanceMetric: DistanceMetric;
    averageSpeed: number;
    maxSpeed: number;
    minSpeed: number;
    metricSystem: MetricSystem;
}

export interface IHistoryModel {
    pathMode: boolean;
    heatmapMode: boolean;
    eventsByTrackerId: {
        [trackerId: number]: ITrackerEvent[];
    };
    selectedDate: string; // ISO String
    /**
     * If user selected TODAY, he can also select some time period selection
     * App will fetch 24h anyway, but selectors will filter by date
     */
    todayPeriod: TimeInterval;
    pastDayHourStart: number;
    pastDayHourEnd: number;
    metaParams: IMetaParamsMeters | null;
}

export const historyStore: ModelConfig<IHistoryModel> = {
    state: {
        pathMode: false,
        heatmapMode: false,
        eventsByTrackerId: {},
        selectedDate: new Date().toISOString(),
        todayPeriod: 24,
        pastDayHourEnd: 20,
        pastDayHourStart: 8,
        metaParams: null,
    },
    reducers: {
        /**
         * Only one selected at a time
         */
        toggleMode: (state, mode: 'pathMode' | 'heatmapMode') => {
            if (mode === 'pathMode') {
                return {
                    ...state,
                    pathMode: !state.pathMode,
                    heatmapMode: false,
                };
            } else if (mode === 'heatmapMode') {
                return {
                    ...state,
                    heatmapMode: !state.heatmapMode,
                    pathMode: false,
                };
            }

            return state;
        },
        activateHeatMap: (state) => {
            return {
                ...state,
                pathMode: false,
                heatmapMode: true,
            };
        },
        activatePathMode: (state) => {
            return {
                ...state,
                pathMode: true,
                heatmapMode: false,
            };
        },
        turnOffMapEvents: (state) => ({
            ...state,
            pathMode: false,
            heatmapMode: false,
        }),
        setEvents: (
            state,
            payload: { trackerId: number; events: ITrackerEvent[] },
        ) => {
            const newState = { ...state };

            set(
                newState,
                `eventsByTrackerId.[${payload.trackerId}]`,
                payload.events,
            );

            return newState;
        },
        selectDate: (state, date: string) => ({ ...state, selectedDate: date }),
        setNextDay: (state) => ({
            ...state,
            selectedDate: moment(state.selectedDate)
                .add(1, 'day')
                .toISOString(),
        }),
        setPrevDay: (state) => ({
            ...state,
            selectedDate: moment(state.selectedDate)
                .subtract(1, 'day')
                .toISOString(),
        }),
        setTodayPeriod: (state, period: TimeInterval) => ({
            ...state,
            todayPeriod: period,
        }),
        setPrevDayTime: (state, time: { start: number; end: number }) => ({
            ...state,
            pastDayHourStart: time.start,
            pastDayHourEnd: time.end,
        }),
        setMetaParams: (state, data: IMetaParamsMeters) => ({
            ...state,
            metaParams: data,
        }),
    },
    effects: (dispatch) => ({
        async fetchEvents(
            payload: {
                trackerId: number;
            },
            state: IRootState,
        ) {
            this.setEvents({ trackerId: payload.trackerId, events: [] });

            const isToday = getIsDateToday(state);

            let start: string;
            let end: string;
            let preferredMetric: string;
            const userData = store.getState().user.userData;

            if (userData) {
                preferredMetric = userData.preferred_metric_system;
            } else {
                preferredMetric = 'km';
            }
            if (isToday) {
                end = new Date().toISOString();
                start = moment(end)
                    .subtract(state.history.todayPeriod, 'hour')
                    .toISOString();
            } else {
                start = moment(state.history.selectedDate)
                    .set({
                        hour: state.history.pastDayHourStart,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                    })
                    .toISOString();

                end = moment(state.history.selectedDate)
                    .set({
                        hour: state.history.pastDayHourEnd,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                    })
                    .toISOString();
            }

            return fetchActivityInfo(
                payload.trackerId,
                start,
                end,
                preferredMetric,
            )
                .then((info) => {
                    this.setMetaParams({
                        totalDistance: info.distance,
                        distanceMetric: info.distance_metric,
                        averageSpeed: info.mean_speed,
                        maxSpeed: info.max_speed,
                        minSpeed: info.min_speed,
                        metricSystem: info.metric_system,
                    });

                    return info.positions;
                })
                .then((mapPositions) => {
                    return fetchPositions(payload.trackerId, start, end).then(
                        (data) => {
                            const allPositions = [...mapPositions, ...data];
                            // Remove positions that are a duplicate of an other one
                            const withoutDups = uniqBy(
                                allPositions,
                                (event) => event.id,
                            );

                            this.setEvents({
                                trackerId: payload.trackerId,
                                events: withoutDups,
                            });
                        },
                    );
                });
        },
    }),
};
