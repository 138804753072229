import { parsePhoneFormat } from 'utils/parsePhoneFormat';
import { apiService } from '../api/api.service';

export interface IRegisterUserPayload {
    city: string;
    postal_code: string;
    address: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    country: string;
    language: string;
    termsAccepted: boolean;
    marketingAccepted: boolean;
}

const mapToDTO = (data: IRegisterUserPayload) => ({
    city: data.city,
    postal_code: data.postal_code,
    address: data.address,
    mail: data.email,
    password: data.password,
    phone: parsePhoneFormat(data.phone),
    lastname: data.lastName,
    firstname: data.firstName,
    country: data.country,
    site: 'weenect',
    optin: data.marketingAccepted,
    language: data.language.toLowerCase(),
});

export const registerUser = (userData: IRegisterUserPayload) => {
    return apiService
        .fetch('/user/register', {
            method: 'POST',
            body: JSON.stringify(mapToDTO(userData)),
        })
        .then((resp) => {
            return resp.json().then((body: any) => {
                if (resp.status === 400) {
                    throw body;
                }
                return body;
            });
        });
};
