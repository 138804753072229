import moment from 'moment';

import { ITrackerEvent } from '../../trackers/interfaces-api';
import { IHistoryModel } from '../history.store';
import { TimeInterval } from '../interfaces';

export const filterByDate = (
    event: ITrackerEvent,
    isToday: boolean,
    model: IHistoryModel,
    timePeriod: TimeInterval,
) => {
    if (!isToday) {
        const start = model.pastDayHourStart;
        const end = model.pastDayHourEnd;
        const selectedDate = moment(model.selectedDate).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        });
        const eventDate = moment(event.date_server);

        return eventDate.isBetween(
            selectedDate.clone().add(start, 'hour'),
            selectedDate.clone().add(end, 'hour'),
        );
    } else {
        const eventDate = moment(new Date(event.date_server));
        const today = moment(new Date());
        const todayMinusPeriod = moment(today).subtract(timePeriod, 'hour');

        return eventDate.isBetween(todayMinusPeriod, today, 'minute');
    }
};
