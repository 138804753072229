import React, { HTMLAttributes } from 'react';

import { InfoIcon } from '../../../common/info-icon/info-icon.component';

import { ReactComponent as IconBatteryStrong } from '../../../icons/battery-full.svg';
import { ReactComponent as IconBatteryLow } from '../../../icons/battery-low.svg';
import { ReactComponent as IconBatteryMedium } from '../../../icons/battery-medium.svg';

import styles from './tracker-attributes.module.scss';

export interface ITrackerAttributesSignalProps
    extends HTMLAttributes<HTMLDivElement> {
    batteryLevel: number;
}

/**
 * Ranges taken from Android/iOS mapping
 */
const strengthGood = 75;
const strengthMedium = 25;

const resolveBatteryIcon = (batteryLevel: number) => {
    if (batteryLevel > strengthGood) {
        return <IconBatteryStrong className={styles.good} />;
    } else if (batteryLevel > strengthMedium) {
        return <IconBatteryMedium className={styles.medium} />;
    } else {
        return <IconBatteryLow className={styles.bad} />;
    }
};

export const TrackerAttributeBattery = ({
    batteryLevel,
    ...props
}: ITrackerAttributesSignalProps) => (
    <InfoIcon
        icon={resolveBatteryIcon(batteryLevel)}
        label={batteryLevel + '%'}
        {...props}
    />
);
