import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { TimeInterval } from '../interfaces';

import styles from './timeframe-select.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {
    selected: TimeInterval;
    onSelected(newPeriod: TimeInterval): void;
}

export const TimeframeSelect = ({
    className,
    selected,
    onSelected,
}: IProps) => (
    <div className={cx(styles.list, className)}>
        <button
            onClick={() => onSelected(1)}
            className={cx(styles.button, {
                [styles.selected]: selected === 1,
            })}
        >
            1 h
        </button>
        <button
            onClick={() => onSelected(3)}
            className={cx(styles.button, {
                [styles.selected]: selected === 3,
            })}
        >
            3 h
        </button>
        <button
            onClick={() => onSelected(6)}
            className={cx(styles.button, {
                [styles.selected]: selected === 6,
            })}
        >
            6 h
        </button>
        <button
            onClick={() => onSelected(12)}
            className={cx(styles.button, {
                [styles.selected]: selected === 12,
            })}
        >
            12 h
        </button>
        <button
            onClick={() => onSelected(24)}
            className={cx(styles.button, {
                [styles.selected]: selected === 24,
            })}
        >
            24 h
        </button>
    </div>
);
