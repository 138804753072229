import * as yup from 'yup';
import { getGlobalT } from '../../../i18n/setup-translations';

/* Validation for confirm password form*/
export const getConfirmPasswordFormValidationSchema = () => {
    const t = getGlobalT();

    return yup.object({
        newPassword: yup
            .string()
            .required()
            .min(8, t('forms:PASSWORD.VALIDATION')),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
            .required()
            .min(8, t('forms:PASSWORD.VALIDATION')),
    });
};
