import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as CloseIcon } from '../../../../images/activityTracking/cross-icon.svg';
import { ReactComponent as DogTrophy } from '../../../../images/activityTracking/dog-competition.svg';
import { Button } from '../../../common/button/button.component';
import { ReactComponent as DashIcon } from '../../../icons/dash.svg';
import { INotification } from '../../../notifications/interfaces';
import { postObjective } from '../../services';
import styles from './objectives-page.module.scss';

const CustomSlider = withStyles({
    root: {
        height: 10,
        '&$vertical': {
            width: 8,
        },
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        // @ts-ignore
        border: ({ value }) =>
            value <= 30 ? ' 2px solid #9949FF' : '2px solid red',
        marginTop: -8,
        marginLeft: -12,
    },
    active: {},
    track: {
        // @ts-ignore
        color: ({ value }) => (value <= 30 ? '#9949FF' : 'red'),
        height: 10,
        borderRadius: 4,
    },
    rail: {
        color: '#E8E8E8',
        height: 10,
        borderRadius: 4,
    },
})(Slider);

const ValueLabelComponent = (props: any) => {
    const { children, open, value } = props;
    return (
        <Tooltip
            open={open}
            interactive
            placement="bottom"
            enterTouchDelay={0}
            title={value}
            PopperComponent={() => (
                <p style={{ width: value + '%', textAlign: 'center' }}>
                    {value}
                </p>
            )}
        >
            {children}
        </Tooltip>
    );
};

const convertValueToMin = (numberFromSlider: number) => {
    const numberFormated = numberFromSlider * 2;
    let result = numberFormated + 'min';
    if (numberFormated >= 60) {
        result =
            Math.floor(numberFormated / 60) +
            'h' +
            ('0' + (numberFormated % 60)).slice(-2);
    }
    return <p style={{ color: 'black', fontSize: '600' }}>{result}</p>;
};

interface IOuterProps {
    objective: number;
    trackerId: number;
    refreshStore: () => void;
    onClose: () => void;
    showNotification(n: INotification): unknown;
}

export interface IObectivesComponentProps extends IOuterProps {}

export const ObectivesComponent = ({
    showNotification,
    onClose,
    trackerId,
    objective,
    refreshStore,
}: IObectivesComponentProps) => {
    const [valueSlider, setValueSlider] = useState<number>(0);
    const { t } = useTranslation([
        'activity',
        'commonActions',
        'trackerSettings',
    ]);
    const validateObjective = () => {
        const value = valueSlider * 2;
        postObjective(trackerId, value).then(() => {
            showNotification({
                type: 'success',
                content: t('trackerSettings:DISPLAY.MESSAGES.UPDATE_SUCCESS'),
            });
            onClose();
            refreshStore();
        });
    };

    useEffect(() => {
        if (objective) {
            setValueSlider(objective / 2);
        }
    }, []);
    return (
        <React.Fragment>
            <div>
                <div className={styles.header}>
                    <div>
                        <DashIcon
                            onClick={onClose}
                            className={styles.dashIcon}
                        />
                    </div>
                    <div className={styles.title}>
                        {t('activity:OBJECTIVE.TITLE')}
                    </div>
                    <div>
                        <CloseIcon onClick={onClose} />
                    </div>
                </div>
                <div className={styles.objectiveContainer}>
                    <div className={styles.mainText}>
                        {t('activity:OBJECTIVE.CONTENT')}
                    </div>
                    <div>{t('activity:OBJECTIVE.SUBTEXT')}</div>
                    <div className={styles.sliderContainer}>
                        <div>
                            <div>
                                {t('activity:OBJECTIVE.RECOMENDED_VALUE')}
                            </div>
                            <div className={styles.arrowContainer}>
                                <div className={styles.arrowUp} />
                            </div>
                        </div>
                        <div>
                            <CustomSlider
                                value={valueSlider}
                                valueLabelFormat={convertValueToMin}
                                valueLabelDisplay="on"
                                ValueLabelComponent={ValueLabelComponent}
                                onChange={(e, value: any) =>
                                    setValueSlider(value as number)
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.textHelper}>
                        {t('activity:OBJECTIVE.HELPTEXT')}
                    </div>
                    {/* <div className={styles.iconContainerobj}>
                        <DogTrophy className={styles.bigIcon} />
                    </div> */}

                    <div className={styles.ButtonBlock}>
                        <Button
                            className={styles.buttonWidth}
                            onClick={validateObjective}
                        >
                            {t('commonActions:VALIDATE')}
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
