import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Collapse } from '../../common/collapse/collapse.component';
import { Toggle } from '../../common/toggle/toggle.component';
import { Typography } from '../../common/typography/typography.component';
import { Dispatch, IRootState } from '../../store/store';
import {
    ControlledEditGeofenceForm,
    IFormValues,
    IWithLatLng,
} from '../edit-geofence-form/edit-geofence-form.component';
import { IGeofence } from '../interfaces';
import { mapClientGeofenceToApiDTO } from '../mappers/client-to-dto';
import { makeGetEditableGeofencePosition } from '../selectors/geofences-edit.selectors';
import { getReverseGeocode } from '../services/geofences.service';
import styles from './geofence-item.module.scss';

export interface IStateProps {
    editableLat: number;
    editableLng: number;
    geofenceDuringNewCreating: boolean;
}

export interface IActions {
    onSave: (values: IFormValues & IWithLatLng) => any;
    onDelete: (id: number) => Promise<any>;
    onTrackerPositionTriggered: () => any;
    toggleGeofenceActive: (id: number) => Promise<any>;
    onRadiusChanged: (newRadius: number) => any;
}

export interface IOuterProps extends HTMLAttributes<HTMLDivElement>, IGeofence {
    onToggle: () => any;
    open: boolean;
}

export interface IGeofenceItemProps
    extends IStateProps,
        IActions,
        IOuterProps {}

export const GeofenceItem = ({
    active,
    address,
    className,
    editableLat,
    editableLng,
    entranceNotification,
    exitNotification,
    geofenceDuringNewCreating,
    geofenceId,
    name,
    onDelete,
    onRadiusChanged,
    onSave,
    onToggle,
    onTrackerPositionTriggered,
    open,
    outdoors,
    position,
    radius,
    toggleGeofenceActive,
    trackerId,
}: IGeofenceItemProps) => {
    const panel = (
        <div className={styles.panel}>
            <div>
                <Typography size14 noMargin bold className={styles.name}>
                    {name}
                </Typography>
                {address}
            </div>
            <Toggle
                checked={active}
                onChange={() => toggleGeofenceActive(geofenceId)}
            />
        </div>
    );

    const content = (
        <ControlledEditGeofenceForm
            trackerPositionEnabled={true /* temp */}
            onRadiusChanged={onRadiusChanged}
            className={styles.form}
            lat={editableLat}
            lng={editableLng}
            onCancel={onToggle}
            onDelete={() => onDelete(geofenceId)}
            canBeDeleted={true}
            onSave={onSave}
            onTrackerPositionTriggered={onTrackerPositionTriggered}
            geofenceData={{
                address,
                radius,
                outdoors,
                exitNotification,
                entranceNotification,
                name,
                geofenceId,
                active,
                position,
                trackerId,
            }}
            geofenceDuringNewCreating={geofenceDuringNewCreating}
        />
    );

    return (
        <Collapse
            className={className}
            panel={panel}
            content={content}
            expanded={open}
            onExpand={onToggle}
        />
    );
};

const mapState = (state: IRootState): IStateProps => {
    const getPositionOfEditableGeofence = makeGetEditableGeofencePosition();

    return {
        editableLat: getPositionOfEditableGeofence(state).lat,
        editableLng: getPositionOfEditableGeofence(state).lng,
        geofenceDuringNewCreating: state.geofenceEdit.duringNewCreating,
    };
};

const mapDispatch = (dispatch: Dispatch, props: IOuterProps): IActions => ({
    toggleGeofenceActive: async (id: number) =>
        dispatch.geofences.updateGeofenceActiveState(id),
    onRadiusChanged: dispatch.geofenceEdit.updateRadius,
    onDelete: async (geofenceId: number) =>
        dispatch.geofences.deleteGeofence(geofenceId),
    onSave: async (values: IFormValues & IWithLatLng) => {
        let newAddress = props.address;
        if (
            props.position.lat !== values.lat ||
            props.position.lng !== values.lng
        ) {
            newAddress = await getReverseGeocode(values.lat, values.lng);
        }
        const dto = mapClientGeofenceToApiDTO({
            exitNotification: values.exitNotification,
            entranceNotification: values.entranceNotification,
            name: values.name,
            position: {
                lat: values.lat,
                lng: values.lng,
            },
            address: newAddress,
            outdoors: values.outdoors,
            radius: values.radius,
            active: props.active,
        });

        return dispatch.geofenceEdit.updateGeofenceSettings({
            dto,
            trackerId: props.trackerId,
            geofenceId: props.geofenceId,
        });
    },
    onTrackerPositionTriggered: dispatch.geofenceEdit.centerOnActiveTracker,
});

export const ConnectedGeofenceItem = compose<IGeofenceItemProps, IOuterProps>(
    connect(mapState, mapDispatch),
)(GeofenceItem);
