import * as yup from 'yup';

import { availableLanguagesCodes } from '../../../i18n/available-languages';
import { getGlobalT } from '../../../i18n/setup-translations';
import { isInternationalPhone } from '../../../utils/phone-validator';

export const getSignupValidationSchema = () => {
    const t = getGlobalT();

    return yup.object({
        country: yup.string().required(),
        email: yup
            .string()
            .email()
            .required(),
        phone: yup
            .string()
            .test('phone', t('errors:PHONE_FORMAT'), isInternationalPhone),
        firstName: yup.string().required(),
        language: yup.string().oneOf(availableLanguagesCodes),
        lastName: yup.string().required(),
        marketingAccepted: yup.bool(),
        password: yup
            .string()
            .required()
            .min(8, t('forms:PASSWORD.VALIDATION')),
        termsAccepted: yup
            .bool()
            .required()
            .oneOf([true], 'Terms must be accepted'),
        address: yup
            .string()
            .required()
            .max(150),
        postal_code: yup
            .string()
            .required()
            .max(50),
        city: yup
            .string()
            .required()
            .max(100),
    });
};
