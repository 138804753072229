import { createSelector } from 'reselect';
import { trackersFromApi } from './selectors';

export const makeGetAllTrackerSubscriptionsIds = () =>
    createSelector(
        trackersFromApi,
        (trackers): number[] =>
            trackers
                .filter((t) => t.subscription)
                .map((t) => t.subscription!.id),
    );
