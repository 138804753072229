import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import bikePremiumImage from '../../../../images/map_type_bikes_prem.png';
import bikePremiumImageLock from '../../../../images/map_type_bikes_prem_locked.png';
import planPremiumImage from '../../../../images/map_type_normal_prem.png';
import planPremiumImageLock from '../../../../images/map_type_normal_prem_locked.png';
import reliefPremiumImage from '../../../../images/map_type_relief_prem.png';
import reliefPremiumImageLock from '../../../../images/map_type_relief_prem_locked.png';
import satPremiumImage from '../../../../images/map_type_sat_prem.png';
import satPremiumImageLock from '../../../../images/map_type_sat_prem_locked.png';
import streetImage from '../../../../images/plan-classic.png';
import satImage from '../../../../images/sat-classic.png';
import { Radio } from '../../../common/radio/radio.component';
import { ReactComponent as LockIcon } from '../../../icons/lock.svg';
import { MapLayerType } from '../../interfaces';
import styles from './layer-select.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {
    hasMapOptionRunning: boolean;
    selectedMapLayer: MapLayerType;
    onLayerChange(layer: MapLayerType): unknown;
}

export const LayerSelect = ({
    className,
    hasMapOptionRunning,
    selectedMapLayer,
    onLayerChange,
}: IProps) => {
    const { t } = useTranslation('mapLayers');

    const maybeRenderLockIcon = () => {
        if (!hasMapOptionRunning) {
            return <LockIcon className={styles.icon} />;
        }
    };
    const maybeRenderLockMessage = () => {
        if (!hasMapOptionRunning) {
            return <span className={styles.unlockMessage}>{t('UNLOCK_MAPS')}</span>;
        }
    };

    return (
        <div className={cx(styles.root, className)}>
            <p className={styles.title}>{t('CLASSIC_MAPS')}</p>
            <div className={styles['layers-group']}>
                <Radio
                    checked={selectedMapLayer === MapLayerType.STREET}
                    name="map-select-layer"
                    id="map_street"
                    onChange={() => onLayerChange(MapLayerType.STREET)}
                    hideRadio={true}
                    className={cx(styles.radio, {
                        [styles.checked]:
                            selectedMapLayer === MapLayerType.STREET,
                    })}
                >
                    <img src={streetImage} alt={t('PLAN')} />
                    {t('PLAN')}
                </Radio>
                <Radio
                    checked={selectedMapLayer === MapLayerType.GRAY}
                    name="map-select-layer"
                    id="map_gray"
                    onChange={() => onLayerChange(MapLayerType.GRAY)}
                    hideRadio={true}
                    className={cx(styles.radio, {
                        [styles.checked]:
                            selectedMapLayer === MapLayerType.GRAY,
                    })}
                >
                    <img
                        src={streetImage}
                        alt={t('GRAY')}
                        className={styles.gray}
                    />
                    {t('GRAY')}
                </Radio>
                <Radio
                    checked={selectedMapLayer === MapLayerType.SATELLITE}
                    name="map-select-layer"
                    id="map_satellite"
                    onChange={() => onLayerChange(MapLayerType.SATELLITE)}
                    hideRadio={true}
                    className={cx(styles.radio, {
                        [styles.checked]:
                            selectedMapLayer === MapLayerType.SATELLITE,
                    })}
                >
                    <img src={satImage} alt={t('SATELLITE')} />
                    {t('SATELLITE')}
                </Radio>
            </div>
            <p className={cx(styles.title)}>
                {t('PREMIUM_MAPS')}
                {maybeRenderLockIcon()}
                {maybeRenderLockMessage()}
            </p>
            <div className={cx(styles['layers-group'])}>
                <Radio
                    checked={selectedMapLayer === MapLayerType.GOOGLE_STREET}
                    name="map-select-layer"
                    id="map_google_street"
                    onChange={() => onLayerChange(MapLayerType.GOOGLE_STREET)}
                    hideRadio={true}
                    className={cx(styles.radio, {
                        [styles.checked]:
                            selectedMapLayer === MapLayerType.GOOGLE_STREET,
                        [styles.gray]: !hasMapOptionRunning,
                    })}
                >
                    <img
                        src={
                            !hasMapOptionRunning
                                ? planPremiumImageLock
                                : planPremiumImage
                        }
                        alt={t('PLAN')}
                    />
                    {t('PLAN')}
                </Radio>
                <Radio
                    checked={selectedMapLayer === MapLayerType.GOOGLE_SATELLITE}
                    name="map-select-layer"
                    id="map_google_satellite"
                    onChange={() =>
                        onLayerChange(MapLayerType.GOOGLE_SATELLITE)
                    }
                    hideRadio={true}
                    className={cx(styles.radio, {
                        [styles.checked]:
                            selectedMapLayer === MapLayerType.GOOGLE_SATELLITE,
                        [styles.gray]: !hasMapOptionRunning,
                    })}
                >
                    <img
                        src={
                            !hasMapOptionRunning
                                ? satPremiumImageLock
                                : satPremiumImage
                        }
                        alt={t('SATELLITE')}
                    />
                    {t('SATELLITE')}
                </Radio>
                <Radio
                    checked={selectedMapLayer === MapLayerType.GOOGLE_TERRAIN}
                    name="map-select-layer"
                    id="map_google_terrain"
                    onChange={() => onLayerChange(MapLayerType.GOOGLE_TERRAIN)}
                    hideRadio={true}
                    className={cx(styles.radio, {
                        [styles.checked]:
                            selectedMapLayer === MapLayerType.GOOGLE_TERRAIN,
                        [styles.gray]: !hasMapOptionRunning,
                    })}
                >
                    <img
                        src={
                            !hasMapOptionRunning
                                ? reliefPremiumImageLock
                                : reliefPremiumImage
                        }
                        alt={t('RELIEF')}
                    />
                    {t('RELIEF')}
                </Radio>
                <Radio
                    checked={selectedMapLayer === MapLayerType.GOOGLE_BIKING}
                    name="map-select-layer"
                    id="map_google_biking"
                    onChange={() => onLayerChange(MapLayerType.GOOGLE_BIKING)}
                    hideRadio={true}
                    className={cx(styles.radio, {
                        [styles.checked]:
                            selectedMapLayer === MapLayerType.GOOGLE_BIKING,
                        [styles.gray]: !hasMapOptionRunning,
                    })}
                >
                    <img
                        src={
                            !hasMapOptionRunning
                                ? bikePremiumImageLock
                                : bikePremiumImage
                        }
                        alt={t('FOREST_PATH')}
                    />
                    {t('FOREST_PATH')}
                </Radio>
            </div>
        </div>
    );
};
