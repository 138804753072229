import { ModelConfig } from '@rematch/core';

import { setLocale } from '../../utils/time-formatters';
import { IUser } from './interfaces-api';
import { IRawUserData } from './interfaces';
import { fetchUser } from './user.service';

/**
 * Add more fields when needed in the app
 */
export interface IUserStore {
    userData: IRawUserData | null;
}

export const userStore: ModelConfig<IUserStore> = {
    state: {
        userData: null,
    },
    reducers: {
        setUserData(state, userData: IRawUserData) {
            return { ...state, userData };
        },
    },
    effects: (dispatch: any) => ({
        async fetchUserData() {
            return fetchUser().then((user: IUser) => {
                this.setUserData(user);

                // Set app language every time user is fetch from API
                dispatch.language.updateLanguage({
                    languageCode: user.language,
                });

                // Set app time format
                setLocale(user.language);
                return user;
            });
        },
    }),
};
