import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { compose } from 'recompose';

import { trackersRouteTypeSwitch } from '../../routing/routes';
import { Dispatch, IRootState } from '../../store/store';
import { TrackerType } from '../../trackers/interfaces';
import { ITracker } from '../../trackers/interfaces-api';
import { makeGetAllTrackersIds } from '../../trackers/selectors/get-all-tracker-ids';
import { makeGetHasBeenFirstFetched } from '../../trackers/selectors/get-have-trackers-been-fetched';

interface IStateProps {
    firstFetch: boolean;
    trackers: ITracker[];
}
interface IActions {
    setSelectedTracker: (id: number) => Promise<unknown>;
}
interface IHOCProps extends IActions, IStateProps { }

const hasFluidTrackerGuard = (Component: ComponentType<any>) => ({
    firstFetch,
    trackers,
    setSelectedTracker,
    ...props
}: IHOCProps) => {
    const history = useNavigate()
    const location = useLocation()
    useEffect(() => {
        if (firstFetch) {
            for (const tracker of trackers) {
                if (
                    tracker.type === TrackerType.ANIMAL ||
                    tracker.type === TrackerType.FAMILY
                ) {
                    setSelectedTracker(tracker.id).then(() => {
                        history(trackersRouteTypeSwitch);
                    });
                }
            }
        }
    }, [firstFetch, location, history]);

    return <Component {...props} />;
};

const mapState = (state: IRootState, props: any): IStateProps => {
    const getHasBeenFirstFetched = makeGetHasBeenFirstFetched();
    return {
        trackers: state.userTrackers.rawTrackers,
        firstFetch: getHasBeenFirstFetched(state),
    };
};
const mapDispatch = (dispatch: Dispatch): IActions => ({
    setSelectedTracker: async (id: number) =>
        dispatch.subscriptionProcess.setSelectedTracker(id),
});
const connectedHasFluidTrackerGuard = compose(
    connect(mapState, mapDispatch),
    hasFluidTrackerGuard,
);

export { connectedHasFluidTrackerGuard as hasFluidTrackerGuard };
