import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { Toggle } from '../../common/toggle/toggle.component';
import { Typography } from '../../common/typography/typography.component';
import { ReactComponent as AirplaneIcon } from '../../icons/airplane-icon.svg';
import { Dispatch, IRootState } from '../../store/store';
import { ITracker } from '../../trackers/interfaces-api';
import { makeGetTrackerModel } from '../../trackers/selectors/get-tracker-model';
import styles from '../edit-wifi-zones-form/edit-wifi-zones-form.module.scss';
import { IWifiZone } from '../interfaces';
import {
    makeGetEditableWifiZoneId,
    makeGetUserWifiZonesForTracker,
} from '../selectors/wifi-zones.selectors';
import { ConnectedWifiZoneItem } from '../wifi-zones-item/wifi-zones-item.component';

export interface IStateProps {
    wifiZones: IWifiZone[];
    openId: number | null;
    tracker: ITracker;
}

export interface IActions {
    setWifiZoneToEditMode: (id: number) => any;
    closeWifiZoneEditMode: () => any;
    onToggleDeepSleep: (enableDeepSleep: boolean) => Promise<any>;
}

export interface IOuterProps {
    trackerId: number;
}

export interface IProps extends IStateProps, IActions, IOuterProps { }

const WifiZonesList = ({
    tracker,
    wifiZones,
    closeWifiZoneEditMode,
    openId,
    onToggleDeepSleep,
    setWifiZoneToEditMode,
}: IProps) => {
    const { t } = useTranslation(['wifiZones', 'commonActions']);
    const [deepSleep, setDeepSleep] = useState<boolean>(
        tracker.enable_deep_sleep_wifi,
    );
    useEffect(() => {
        if (deepSleep !== tracker.enable_deep_sleep_wifi) {
            setDeepSleep(tracker.enable_deep_sleep_wifi);
        }
    }, [tracker]);
    const toggleWifiZoneSection = (id: number) => {
        if (openId === id) {
            closeWifiZoneEditMode();
        } else {
            setWifiZoneToEditMode(id);
        }
    };
    const handleToggleDeepSleep = () => {
        onToggleDeepSleep(!deepSleep);
        setDeepSleep(!deepSleep);
    };
    return (
        <>
            <div className={styles['title-container']}>
                <AirplaneIcon className={styles['form-svg-icon']} />
                <Typography size14 bold noMargin>

                    {t('DEEP_SLEEP_TITLE')}
                </Typography>
            </div>
            <Typography size12 gray className={styles['gray-text']}>


                {t('AIRPLANE_TEXT')}
            </Typography>
            <div className={styles['wifi-toggle']}>

                <Typography noMargin>{t('DEEP_SLEEP_TITLE')}</Typography>
                <Toggle
                    wifiToggle
                    checked={deepSleep}
                    name="entranceNotification"
                    onChange={handleToggleDeepSleep}
                    className={styles.toggle}
                />
            </div>
            <div className={styles.wifiZonesList}>
                {wifiZones.map((wifiZone: IWifiZone) => (
                    <ConnectedWifiZoneItem
                        tracker={tracker}
                        wifiZone={wifiZone}
                        key={wifiZone.id}
                        className={styles.wifiZoneItem}
                        open={openId === wifiZone.id}
                        onToggle={() => toggleWifiZoneSection(wifiZone.id)}
                        {...wifiZone}
                    />
                ))}
            </div>
        </>
    );
};

const makeMapState = () => {
    return (state: IRootState, props: IOuterProps): IStateProps => {
        const getWifiZonesByTracker = makeGetUserWifiZonesForTracker(
            props.trackerId,
        );
        const getCurrentlyEditableWifiZone = makeGetEditableWifiZoneId();
        const getTrackerDetails = makeGetTrackerModel(props.trackerId);
        const tracker: ITracker = getTrackerDetails(state) as ITracker;
        return {
            wifiZones: getWifiZonesByTracker(state),
            openId: getCurrentlyEditableWifiZone(state),
            tracker,
        };
    };
};

const mapDispatch = (dispatch: Dispatch, props: IOuterProps): IActions => ({
    setWifiZoneToEditMode: dispatch.wifiZonesEdit.setWifiZoneDuringEdit,
    closeWifiZoneEditMode: () =>
        dispatch.wifiZonesEdit.setWifiZoneDuringEdit(null),
    onToggleDeepSleep: async (enableDeepSleep: boolean) => {
        const dto = {
            enable_deep_sleep_wifi: enableDeepSleep,
        };
        return dispatch.wifiZonesEdit.onTriggerDeepSleep({
            trackerId: props.trackerId,
            dto,
        });
    },
});
export const ConnectedWifiZonesList = compose<IProps, IOuterProps>(
    connect(makeMapState, mapDispatch),
    lifecycle<IActions & IStateProps, {}, {}>({
        componentDidMount() {
            const { wifiZones, setWifiZoneToEditMode } = this.props;
            if (wifiZones.length > 0) {
                setWifiZoneToEditMode(wifiZones[0].id);
            }
        },
    }),
)(WifiZonesList);
