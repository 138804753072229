import moment from 'moment';
import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Progress } from '../../common/progress/progress.component';
import { Typography } from '../../common/typography/typography.component';
import { Dispatch, IRootState } from '../../store/store';
import {
    getCurrentlyPlayingIndex,
    getIsDateToday,
    getTodayPeriodValue,
    makeGetEventsOnMap,
} from '../selectors';
import styles from './playing-progress.module.scss';

interface IStateProps {
    start: string;
    end: string;
    positionsCount: number;
    progress: number;
}
interface IActions {
    setHistoryPlayingIndex: (newIndex: number) => unknown;
}

interface IOuterProps extends HTMLAttributes<HTMLDivElement> {
    trackerId: number;
}

interface IProps extends IStateProps, IOuterProps, IActions {}

export const PlayingProgress = ({
    start,
    end,
    positionsCount,
    progress,
    setHistoryPlayingIndex,
}: IProps) => {
    return (
        <div className={styles.playingProgress}>
            <div className={styles.dates}>
                <Typography size12 gray noMargin>
                    {start}
                </Typography>
                <Typography size12 gray noMargin>
                    {end}
                </Typography>
            </div>
            <Progress
                value={progress}
                max={positionsCount}
                // Modify Index in store when user clicks on progress bar
                onChange={(newValue: [number]) => {
                    setHistoryPlayingIndex(newValue[0]);
                }}
            />
        </div>
    );
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    setHistoryPlayingIndex: (newIndex) =>
        dispatch.historyPositionPlaying.setEventIndex(newIndex),
});

export const ConnectedPlayingProgress = compose<IProps, IOuterProps>(
    connect(
        (state: IRootState, props: IOuterProps): IStateProps => {
            const getItems = makeGetEventsOnMap(props.trackerId);
            const isToday = getIsDateToday(state);
            const todayPeriod = getTodayPeriodValue(state);
            const pastStart = state.history.pastDayHourStart;
            const pastEnd = state.history.pastDayHourEnd;

            const itemsLength = getItems(state).length;

            let start: string;
            let end: string;

            if (isToday) {
                const endDate = moment(new Date());

                end = endDate.format('D.MM.YY - H:mm');
                start = moment(endDate)
                    .subtract(todayPeriod, 'h')
                    .format('D.MM.YY - H:mm');
            } else {
                const selectedDate = state.history.selectedDate;
                const theDate = moment(selectedDate).set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                });

                const startDate = moment(theDate).add(pastStart, 'h');
                const endDate = moment(theDate).add(pastEnd, 'h');

                start = startDate.format('D.MM.YY - H:mm');
                end = endDate.format('D.MM.YY - H:mm');
            }

            return {
                positionsCount: itemsLength,
                // progress will go from 0 to positionsCount
                progress: getCurrentlyPlayingIndex(state),
                start,
                end,
            };
        },
        mapDispatch,
    ),
)(PlayingProgress);
