import { submitSupportTicket } from './services/support.service';
import { ISupportTicketDto } from './support-ticket-dto';

export const supportStore = {
    state: {},
    effects: {
        async submitSupportTicket(payload: ISupportTicketDto) {
            return submitSupportTicket(payload);
        },
    },
};
