import cx from 'classnames';
import { FormikBag, FormikProps, withFormik } from 'formik';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { IWithFormUtils, withFormUtils } from '../../../utils/form-helpers';

import { Button } from '../../common/button/button.component';
import { PasswordInput } from '../../common/password-input/password-input.component';

import { getConfirmPasswordFormValidationSchema } from './confirm-password-form-validation';

import styles from './confirm-password-form.module.scss';

export interface IFormValues {
    newPassword: string;
    confirmPassword: string;
}

interface IOuterProps extends HTMLAttributes<HTMLFormElement> {
    onFormSubmitted: (values: IFormValues) => any;
}

interface IProps extends FormikProps<IFormValues>, IWithFormUtils, IOuterProps {
    className?: string;
}

export const ConfirmPasswordForm = ({
    handleSubmit,
    handleChange,
    handleBlur,
    className,
    getErrorMessage,
    isFieldValid,
}: IProps) => {
    const translateForms = useTranslation('forms');
    const translateCommonAction = useTranslation('commonActions');

    const formsT = translateForms.t;
    const commonActionT = translateCommonAction.t;

    return (
        /* NB : handleBlur and handleChange are required as they update model on user inputs */
        <form className={cx(styles.form, className)} onSubmit={handleSubmit}>
            <PasswordInput
                id="confirm-password-input"
                className={styles.control}
                label={formsT('NEW_PASSWORD.LABEL')}
                placeholder={formsT('NEW_PASSWORD.PLACEHOLDER')}
                name="newPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                errorMessage={getErrorMessage('newPassword')}
                isValid={isFieldValid('newPassword')}
            />
            <PasswordInput
                id="re-confirm-password-input"
                className={styles.control}
                label={formsT('CONFIRM_PASSWORD.LABEL')}
                placeholder={formsT('CONFIRM_PASSWORD.PLACEHOLDER')}
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                errorMessage={getErrorMessage('confirmPassword')}
                isValid={isFieldValid('confirmPassword')}
            />
            <Button type="submit" className={styles['submit-button']} block>
                {commonActionT('SAVE')}
            </Button>
        </form>
    );
};

export const ControlledConfirmPasswordForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IFormValues>({
        mapPropsToValues: () => {
            return {
                newPassword: '',
                confirmPassword: '',
            };
        },
        handleSubmit: (
            values: IFormValues,
            formikBag: FormikBag<IOuterProps, IFormValues>,
        ) => {
            formikBag.props.onFormSubmitted(values);
        },
        validationSchema: () => getConfirmPasswordFormValidationSchema(),
    }),
    withFormUtils,
)(ConfirmPasswordForm);
