import { createSelector } from 'reselect';
import { IRootState } from '../store/store';

const getUserLocationModel = (state: IRootState) => state.userLocation;

export const getIsUserLocationDetected = createSelector(
    getUserLocationModel,
    (model) => model.detected,
);

export const getUserLocation = createSelector(
    getUserLocationModel,
    (model) => ({
        lat: model.lat,
        lng: model.lng,
    }),
);
