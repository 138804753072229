import { apiService } from '../../api/api.service';

export const enableLiveMode = async (trackerId: number) => {
    const getUrl = () => `/mytracker/${trackerId}/st-mode`;

    return await apiService.fetch(getUrl(), {
        method: 'POST',
        body: JSON.stringify({
            interval: 10,
        }),
    });
};

/**
 * Since server doesn't handle counting of passed time,
 * this has to be saved locally
 */
export const saveTimestamp = (trackerId: number) => {
    localStorage.setItem(`live-mode-timestamp-${trackerId}`, `${Date.now()}`);
};

export const getTimestamp = (trackerId: number): number | null => {
    const timestamp = localStorage.getItem(`live-mode-timestamp-${trackerId}`);

    if (!timestamp) {
        return null;
    }

    return parseInt(timestamp, 10);
};

export const getPassedLiveModeSeconds = (trackerId: number): number | null => {
    const savedAt = getTimestamp(trackerId);

    if (!savedAt) {
        return null;
    }

    const diffInMs = Date.now() - savedAt;

    return diffInMs / 1000;
};
