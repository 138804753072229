import cx from 'classnames';
import React, { HTMLAttributes } from 'react';

import { ReactComponent as DashIcon } from '../../../../icons/dash.svg';

import styles from './toggle-button.module.scss';

export interface IToggleButtonProps extends HTMLAttributes<HTMLButtonElement> {
    isOpen: boolean;
}

export const ToggleButton = ({
    onClick,
    isOpen,
    className,
    ...props
}: IToggleButtonProps) => (
    <button
        onClick={onClick}
        className={cx(styles['toggle-button'], className)}
        {...props}
    >
        <DashIcon
            className={cx(styles['button-icon'], {
                [styles.open]: isOpen,
            })}
        />
    </button>
);
