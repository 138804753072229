import cx from 'classnames';
import React, { HTMLAttributes, ReactNode } from 'react';

import { ReactComponent as DashIcon } from '../../icons/dash.svg';

import styles from './menu-link.module.scss';

export interface IMenuLinkProps extends HTMLAttributes<HTMLButtonElement> {
    icon: ReactNode;
}

/**
 * Menu item indicating link with icon
 */
export const MenuLink = ({
    icon,
    children,
    className,
    ...props
}: IMenuLinkProps) => (
    <button className={cx(styles.menuLink, className)} {...props}>
        <div className={styles.iconContainer}>{icon}</div>
        <span className={styles.labelContainer}>{children}</span>
        <DashIcon className={styles.dashIcon} />
    </button>
);
