//@ts-ignore
import isSameDay from 'date-fns/isSameDay';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';

//@ts-ignore
import DateFnsUtils from '@date-io/date-fns';
import { orange } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ThemeProvider } from '@material-ui/styles';

import { convertDateUtc, getTimezone, IResponseFormat } from '../../../../utils/convertDateUtc';
import { MonthTranslated } from '../../../animal-info/interfaces';
import { Dispatch, IRootState } from '../../../store/store';
import { IAggregateData, IEvolution, ISummaryData, Period } from '../../interface';
import styles from './date-picker.module.scss';

const defaultMaterialTheme = createTheme({
    palette: {
        primary: orange,
    },
});

interface IAction {
    changeTranslatedDate: (translatedDate: string, savedDate: Date) => void;
    getEvolutionData: (
        startDate: string,
        endDate: string,
        trackerId: number,
    ) => IEvolution;
    getSummaryData: (
        startDate: string,
        endDate: string,
        trackerId: number,
    ) => ISummaryData;
    getAggregateData: (
        startDate: string,
        endDate: string,
        trackerId: number,
        periodUnit: string,
    ) => IAggregateData;
}
interface IState {
    period: Period;
    savedDate: any;
}
interface IOuterProps {
    onClose: () => void;
    trackerId: number;
}

interface IDatePickerComponentProps extends IState, IOuterProps, IAction { }

export const DatePickerComponent = ({
    period,
    savedDate,
    trackerId,
    onClose,
    getAggregateData,
    getEvolutionData,
    getSummaryData,
    changeTranslatedDate,
}: IDatePickerComponentProps) => {
    const [selectedDays, setSelectedDays] = useState([new Date(savedDate)]);
    const [monthSelected, setMonthSelected] = useState(
        new Date(savedDate).getMonth(),
    );
    const [valueSelected, setValueSelected] = useState<IResponseFormat | null>(
        null,
    );
    const { t } = useTranslation([
        'activityOnboarding',
        'activityTracking',
        'activity',
        'commonActions',
    ]);
    const returnPeriodUnit = () => {
        let periodUnit;
        switch (period) {
            case Period.DAY:
                return (periodUnit = 'hour');
            case Period.WEEK:
                return (periodUnit = 'day');
            case Period.MONTH:
                return (periodUnit = 'week');
            default:
                periodUnit = 'hour';
        }
        return periodUnit;
    };
    const updateActivityValue = () => {
        const now = new Date();
        const periodUnit = returnPeriodUnit();
        if (valueSelected) {
            getSummaryData(
                valueSelected.startDate,
                valueSelected?.endDate,
                trackerId,
            );
            getAggregateData(
                valueSelected.startDate,
                valueSelected.endDate,
                trackerId,
                periodUnit!,
            );
            getEvolutionData(
                valueSelected.startDate,
                valueSelected.endDate,
                trackerId,
            );
            return onClose();
        }
    };

    useEffect(() => {
        if (selectedDays) {
            handleDayClick(
                (selectedDays[0] as unknown) as MaterialUiPickersDate,
            );
        }
    }, [period]);

    useEffect(() => {
        const date = new Date();
        date.setMonth(monthSelected);
        const newValue = convertDateUtc(period, date);
        if (newValue && period === Period.MONTH) {
            setValueSelected(newValue);
            convertDateUtc(period, date, new Date(date.getMonth() + 1))
            return changeTranslatedDate(newValue.translatedPeriod, date);
        }
    }, [monthSelected, period]);
    const mayRenderDayWeeksPiker = () => {
        if (period === Period.WEEK || period === Period.DAY) {
            return (
                <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Calendar
                            disableFuture
                            date={selectedDays[0]}
                            onChange={(day) => handleDayClick(day)}
                            renderDay={renderDay}
                        />
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            );
        }
    };
    const mayRenderMonthPiker = () => {
        if (period === Period.MONTH) {
            return (
                <div className={styles.monthGrid}>
                    {MonthTranslated.map((month, i) =>
                        i === monthSelected ? (
                            <div
                                className={styles.monthBlockSelected}
                                key={t(month)}
                            >
                                {t(month)}
                            </div>
                        ) : (
                            <div
                                onClick={() => setMonthSelected(i)}
                                className={styles.monthBlock}
                                key={t(month)}
                            >
                                {t(month)}
                            </div>
                        ),
                    )}
                </div>
            );
        }
    };
    const handleDayClick = (day: MaterialUiPickersDate) => {
        let newValue = null;
        if (period === Period.MONTH) {
            return
        }
        if (day) {
            if (period === Period.WEEK) {
                // logic to select the past 7 days when user request weekly data
                const last7days = 6;
                const daysSorted = [new Date(day.setDate(day.getDate()))];
                for (let i = 0; i < last7days; i++) {
                    const newDate = new Date(day.setDate(day.getDate() - 1));
                    daysSorted.push(newDate);
                }
                newValue = convertDateUtc(period, daysSorted[6], daysSorted[0]);
                if (newValue) {
                    changeTranslatedDate(
                        newValue.translatedPeriod,
                        daysSorted[0],
                    );
                    setValueSelected(newValue);
                    return setSelectedDays(daysSorted);
                }
            }
            // here return the selected day only
            newValue = convertDateUtc(period, day as Date, undefined);
            if (newValue && period === Period.DAY) {
                setValueSelected(newValue);
                changeTranslatedDate(newValue.translatedPeriod, day);
                return setSelectedDays([day]);
            }
        }
    };
    const renderDay = (
        day: any,
        selectedDate: any,
        dayInCurrentMonth: any,
        dayComponent: any,
    ) => {
        const isSelected = selectedDays.some((selectedDay) =>
            isSameDay(selectedDay, day),
        );
        return React.cloneElement(dayComponent, {
            ...dayComponent.props,
            selected: isSelected,
        });
    };

    return (
        <>
            {mayRenderDayWeeksPiker()}
            {mayRenderMonthPiker()}
            <div
                className={styles.commonText}
                onClick={() => {
                    valueSelected ? updateActivityValue() : onClose();
                }}
            >
                {t('activity:SUMMARY.REPORT')}
            </div>
        </>
    );
};

const mapDispatch = (dispatch: Dispatch) => ({
    changeTranslatedDate: (dateTranslated: string, date: Date) => {
        dispatch.activityTracking.setTranslatedPeriodSelected(
            dateTranslated,
            date,
        );
    },
    getSummaryData: (startDate: string, endDate: string, trackerId: number) => {
        const data = { startDate, endDate, trackerId };
        dispatch.activityTracking.getSumaryData(data);
    },
    getEvolutionData: (
        startDate: string,
        endDate: string,
        trackerId: number,
    ) => {
        const data = { startDate, endDate, trackerId };
        dispatch.activityTracking.getEvolutionData(data);
    },
    getAggregateData: (
        startDate: string,
        endDate: string,
        trackerId: number,
        periodUnit: string,
    ) => {
        const periodValue = 1;
        const timezone = getTimezone(new Date(startDate));
        const data = {
            startDate,
            endDate,
            trackerId,
            periodUnit,
            periodValue,
            timezone,
        };
        dispatch.activityTracking.getAggregateData(data);
    },
});

const makeMapState = (state: IRootState): IState => {
    const { period, savedDate } = state.activityTracking;
    return {
        savedDate,
        period,
    };
};

export const ConnectedDatePickerComponent = compose<
    IDatePickerComponentProps,
    IOuterProps
>(
    connect(makeMapState, mapDispatch),
    mapProps(
        (props: IDatePickerComponentProps): IDatePickerComponentProps => ({
            ...props,
        }),
    ),
)(DatePickerComponent);
