import { get } from 'lodash';
import { useParams } from 'react-router';
import { createSelector } from 'reselect';

import { IRootState } from '../../store/store';
import { getRawSubscriptionVariants } from '../../subscription-offers/selectors/subscription-offers-selectors';
import { makeGetTrackerModel } from '../../trackers/selectors/get-tracker-model';
import { ISubscriptionOptionData } from '../interfaces';
import {
    IRawUserSubscription,
    SubscriptionOptionType,
} from '../interfaces-api';
import { mapSubscriptionToDetails } from '../mappers/map-subscription-to-details';
import { mapSubscriptionToOptions } from '../mappers/map-subscription-to-options';

const subscriptionsById = (state: IRootState) =>
    state.userSubscriptions.subscriptionsById;

export const makeGetSubscriptionOptions = (subscriptionId: number) => {
    return createSelector(
        subscriptionsById,
        (subscriptions): ISubscriptionOptionData[] | null => {
            if (!subscriptions[subscriptionId]) {
                return null;
            }

            return mapSubscriptionToOptions(subscriptions[subscriptionId]);
        },
    );
};

export const makeGetSubscriptionDetails = (subscriptionId: number) =>
    createSelector(
        subscriptionsById,
        getRawSubscriptionVariants,
        (subscriptions, variants) => {
            if (!subscriptions[subscriptionId]) {
                return null;
            }

            return mapSubscriptionToDetails(
                subscriptions[subscriptionId],
                variants,
            );
        },
    );

export const makeGetSubscriptionDetailsFromTracker = (
    trackerId: number,
    mapToDetails = true,
) => {
    const getTracker = makeGetTrackerModel(trackerId);
    return createSelector(
        getTracker,
        subscriptionsById,
        getRawSubscriptionVariants,
        (activeTracker, subscriptions, variants) => {
            if (!activeTracker) {
                return null;
            }

            const subscriptionId = get(activeTracker, 'subscription.id');
            if (!subscriptionId) {
                return;
            }
            if (!subscriptions[subscriptionId]) {
                return null;
            }
            if (!mapToDetails) {
                return subscriptions[subscriptionId];
            }
            return mapSubscriptionToDetails(
                subscriptions[subscriptionId],
                variants,
            );
        },
    );
};

export const makeGetAllUserSubscriptionsIds = () =>
    createSelector(subscriptionsById, (subs): number[] =>
        Object.keys(subs).map((id) => parseInt(id, 10)),
    );

export const makeGetAllSubscriptionsDetails = () => {
    return createSelector(
        subscriptionsById,
        getRawSubscriptionVariants,
        (subs, variants) => {
            const subscriptionsDetail = Object.values(subs).map(
                (subscription: IRawUserSubscription) =>
                    mapSubscriptionToDetails(subscription, variants),
            );
            return subscriptionsDetail;
        },
    );
};

export const makeGetFirstActiveSubscription = () =>
    createSelector(subscriptionsById, (subs) => {
        const firstActiveSub = Object.values(subs).find(
            (subscription) => subscription.status === 'active',
        );
        return firstActiveSub || null;
    });
