import {
    getAvailableCountries,
    ICountry,
} from '../../i18n/available-countries';

interface IExtendWindow extends Window {
    userCountry: string;
}

declare var window: IExtendWindow;

/*
    NOTE: `window.userCountry` is currently injected
    into the application by the Nginx web server:

    ```
    sub_filter '<head>' '<head><script>window.userCountry = "$geoip_country_code".toUpperCase();</script>';

    ```

    Fortunately, nobody will ever add any attributes
    to the `head` tag, so this is an excellent idea.
*/

export const getUserCountryCode = (): string => window.userCountry;

export const getUserCountryObject = (): ICountry =>
    getAvailableCountries().find((c) => c.code === getUserCountryCode()) || {
        code: 'FR',
        name: 'France',
    };
