import cx from 'classnames';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { compose } from 'recompose';

import { config as appConfig } from '../../config';
import catTurnOn from '../../images/img_cats.png';
import dogTurnOn from '../../images/img_dogs.png';
import kidsTurnOn from '../../images/img_kids.png';
import silverTurnOn from '../../images/img_silver.png';
import screen1 from '../../images/screen1.jpg';
import screen2 from '../../images/screen2.jpg';
import screen3 from '../../images/screen3.jpg';
import screen4 from '../../images/screen4.jpg';
import screen5 from '../../images/screen5.jpg';
import screen6 from '../../images/screen6.png';
import {
    detectMobileOS,
    isBrowsingFromMobile,
    MobileOS,
    redirectToAndroidMarket,
    redirectToiOSMarket,
} from '../../utils/detect-mobile-browsers';
import { Button } from '../common/button/button.component';
import { Headline } from '../common/headline/headline.component';
import { Modal } from '../common/modal/modal.component';
import { PageContainer } from '../layout/page-container/page-container.component';
import { SimpleHeaderWithLanguages } from '../layout/simple-header/simple-header.component';
import { trackersRoute } from '../routing/routes';
import { IRootState } from '../store/store';
import { TrackerType } from '../trackers/interfaces';
import { ITracker } from '../trackers/interfaces-api';
import { StepToShow } from './interfaces';
import styles from './onBoarding.module.scss';

interface IStateProps {
    trackerList: ITracker[];
    currentUserCountry: string | undefined;
}
interface IProps extends IStateProps { }

const OnBoardingPage = ({
    trackerList,
    currentUserCountry,
    ...props
}: IProps) => {
    const [stepToShow, setStepToShow] = useState(StepToShow.GREET);
    const stepToShowKeys = Object.values(StepToShow);
    const history = useNavigate()
    const { t } = useTranslation('onboarding');
    const commonAct = useTranslation('commonActions').t;
    const alertT = useTranslation('alertCustomer').t;

    const sortTrackerList = (trackerListArray: ITracker[]) => {
        trackerListArray.sort((a, b) => {
            if (moment(a.creation_date).isBefore(moment(b.creation_date))) {
                return -1;
            }
            return 1;
        });
    };
    sortTrackerList(trackerList);
    const tracker = trackerList.slice(-1)[0];

    // Check if customer is browsing from a mobile device and get the mobile device OS if so.
    const browsingFromMobile = isBrowsingFromMobile();
    let mobileOS: MobileOS | null = null;
    if (browsingFromMobile) {
        mobileOS = detectMobileOS();
    }

    /**
     * Redirect customer when they leave the onboarding.
     * If browsing from mobile device, try to redirect to the app.
     * If app is not installed, redirect to the app market of the corresponding OS.
     * (We do not handle Windows phones (only Android and iOS))
     *
     * If customer is on desktop, redirect to main page of the web-app (/trackers)
     */
    const redir = () => {
        if (browsingFromMobile) {
            // @ts-ignore
            window.location = appConfig.MOBILE_APP_UNIVERSAL_LINK;
            if (mobileOS === 'Android') {
                return setTimeout(() => {
                    redirectToAndroidMarket();
                }, 1000);
            }
        }

        return history(trackersRoute);
    };

    const shouldDisplaySkipButton = () => {
        if (stepToShow === StepToShow.TUTORIAL_START) {
            return (
                <button onClick={redir} className={styles.skipFlow}>
                    {t('SKIP')}
                </button>
            );
        }
    };

    const getNextButton = () => {
        const index = stepToShowKeys.findIndex(
            (currentKey) => (currentKey as StepToShow) === stepToShow,
        );

        if (stepToShow === StepToShow.TUTORIAL_START) {
            return (
                <Button
                    onClick={(e) => {
                        setStepToShow(stepToShowKeys[index + 1] as StepToShow);
                        e.currentTarget.blur();
                    }}
                    className={styles.nextButton}
                    small
                >
                    {t('TUTORIAL_START.BUTTON')}
                </Button>
            );
        }

        if (stepToShow === StepToShow.SIDE_PAGES) {
            return (
                <Button onClick={redir} className={styles.nextButton} small>
                    {commonAct('GO_MAP')}
                </Button>
            );
        }
        if (stepToShow === 'greet') {
            return (
                <Button
                    onClick={(e) => {
                        setStepToShow(stepToShowKeys[index + 1] as StepToShow);
                        e.currentTarget.blur();
                    }}
                    className={styles.nextButton}
                    small
                >
                    {commonAct('NEXT')}
                </Button>
            );
        }
        return (
            <div className={styles.buttonContainer}>
                <Button
                    onClick={(e) => {
                        setStepToShow(stepToShowKeys[index + 1] as StepToShow);
                        e.currentTarget.blur();
                    }}
                    className={styles.nextButton}
                    small
                    primary
                >
                    {commonAct('NEXT')}
                </Button>
                <Button
                    secondary
                    onClick={(e) => {
                        setStepToShow(stepToShowKeys[index - 1] as StepToShow);
                        e.currentTarget.blur();
                    }}
                    className={styles.backButton}
                    small
                >
                    {commonAct('BACK')}
                </Button>
            </div>
        );
    };

    const translationSetterInteractions = () => {
        if (
            tracker.type === TrackerType.PET2 ||
            tracker.type === TrackerType.CAT2
        ) {
            return t('MAP_INTERACTIONS.COMMON_TEXT.PET');
        } else {
            return t('MAP_INTERACTIONS.COMMON_TEXT.SILVER');
        }
    };

    const translationSetterSidePages = () => {
        if (
            tracker.type === TrackerType.PET2 ||
            tracker.type === TrackerType.CAT2
        ) {
            return t('SIDE_PAGES.COMMON_TEXT.PET');
        } else {
            return t('SIDE_PAGES.COMMON_TEXT.SILVER');
        }
    };

    const trackerTypeSetter = () => {
        if (tracker.type === TrackerType.PET2) {
            return (
                <img
                    alt="Weenect logo"
                    title={dogTurnOn}
                    className={styles.turnOn}
                    src={dogTurnOn}
                />
            );
        }
        if (tracker.type === TrackerType.CAT2) {
            return (
                <img
                    alt="Weenect logo"
                    title={catTurnOn}
                    className={styles.turnOn}
                    src={catTurnOn}
                />
            );
        }

        if (
            tracker.type === TrackerType.KID ||
            tracker.type === TrackerType.DEFAULT
        ) {
            return (
                <img
                    alt="Weenect logo"
                    title={kidsTurnOn}
                    className={styles.turnOn}
                    src={kidsTurnOn}
                />
            );
        } else {
            return (
                <img
                    alt="Weenect logo"
                    title={silverTurnOn}
                    className={styles.turnOn}
                    src={silverTurnOn}
                />
            );
        }
    };
    const displayBulletPoints = () => {
        if (
            stepToShow !== StepToShow.GREET &&
            stepToShow !== StepToShow.TUTORIAL_START
        ) {
            return (
                <div className={styles.bulletPointWrapper}>
                    <div
                        className={cx(styles['bullet-point'], {
                            [styles.currentStep]:
                                stepToShow === StepToShow.MAP_DISPLAY_SETTINGS,
                        })}
                    />
                    <div
                        className={cx(styles['bullet-point'], {
                            [styles.currentStep]:
                                stepToShow === StepToShow.MAP_UPDATE,
                        })}
                    />
                    <div
                        className={cx(styles['bullet-point'], {
                            [styles.currentStep]:
                                stepToShow === StepToShow.SUPERLIVE,
                        })}
                    />
                    <div
                        className={cx(styles['bullet-point'], {
                            [styles.currentStep]:
                                stepToShow === StepToShow.TRACKER_INTERACTIONS,
                        })}
                    />
                    <div
                        className={cx(styles['bullet-point'], {
                            [styles.currentStep]:
                                stepToShow === StepToShow.GPS_INTERVAL,
                        })}
                    />
                    <div
                        className={cx(styles['bullet-point'], {
                            [styles.currentStep]:
                                stepToShow === StepToShow.SIDE_PAGES,
                        })}
                    />
                </div>
            );
        }
    };
    const step = (shown: StepToShow) => {
        const getStepToShow = new Map<StepToShow, React.ReactElement>([
            [
                StepToShow.GREET,
                <React.Fragment key={StepToShow.GREET}>
                    <button onClick={redir} className={styles.skip}>
                        {t('SKIP')}
                    </button>
                    <Headline center className={styles.greet}>
                        {t('GREET.MAIN_TITLE')}
                    </Headline>
                    <div className={styles.commonTextWrapper}>
                        <p className={styles.greetCommonText}>
                            {t('GREET.COMMON_TEXT')}
                        </p>
                    </div>
                    <Headline center level={3}>
                        {t('MODAL.BUTTON_HINT')}
                    </Headline>
                    {trackerTypeSetter()}
                    <p className={styles.redText}>{t('GREET.RED_TEXT')}</p>
                </React.Fragment>,
            ],
            [
                StepToShow.TUTORIAL_START,
                <React.Fragment key={StepToShow.TUTORIAL_START}>
                    <Headline
                        className={styles.tutorialStartPositionner}
                        center
                        level={4}
                    >
                        {t('TUTORIAL_START.COMMON_TEXT')}
                    </Headline>
                </React.Fragment>,
            ],
            [
                StepToShow.MAP_DISPLAY_SETTINGS,
                <React.Fragment key={StepToShow.MAP_DISPLAY_SETTINGS}>
                    <Headline center className={styles.greet} level={2}>
                        {t('MAP_OPTIONS.TITLE')}
                    </Headline>
                    <p className={styles.mapCommonText}>
                        {t('MAP_OPTIONS.COMMON_TEXT')}
                    </p>
                    <div className={styles.screenWrapper}>
                        <img
                            alt="weenect map"
                            title={screen1}
                            className={styles.map}
                            src={screen1}
                        />
                    </div>
                </React.Fragment>,
            ],
            [
                StepToShow.MAP_UPDATE,
                <React.Fragment key={StepToShow.MAP_UPDATE}>
                    <Headline center className={styles.greet} level={2}>
                        {t('MAP_UPDATE.TITLE')}
                    </Headline>
                    <p className={styles.mapCommonText}>
                        {t('MAP_UPDATE.COMMON_TEXT')}
                    </p>
                    <div className={styles.screenWrapper}>
                        <img
                            alt="weenect map"
                            title={screen2}
                            className={styles.map}
                            src={screen2}
                        />
                    </div>
                </React.Fragment>,
            ],
            [
                StepToShow.SUPERLIVE,
                <React.Fragment key={StepToShow.SUPERLIVE}>
                    <Headline center className={styles.greet} level={2}>
                        {t('SUPERLIVE.TITLE')}
                    </Headline>
                    <p className={styles.mapCommonText}>
                        {t('SUPERLIVE.COMMON_TEXT')}
                    </p>
                    <div className={styles.screenWrapper}>
                        <img
                            alt="weenect map"
                            title={screen3}
                            className={styles.map}
                            src={screen3}
                        />
                    </div>
                </React.Fragment>,
            ],
            [
                StepToShow.TRACKER_INTERACTIONS,
                <React.Fragment key={StepToShow.TRACKER_INTERACTIONS}>
                    <Headline center className={styles.greet} level={2}>
                        {t('MAP_INTERACTIONS.TITLE')}
                    </Headline>
                    <p className={styles.mapCommonText}>
                        {translationSetterInteractions()}
                    </p>
                    <div className={styles.screenWrapper}>
                        <img
                            alt="weenect map"
                            title={screen4}
                            className={styles.map}
                            src={screen4}
                        />
                    </div>
                </React.Fragment>,
            ],
            [
                StepToShow.GPS_INTERVAL,
                <React.Fragment key={StepToShow.GPS_INTERVAL}>
                    <Headline center className={styles.greet} level={2}>
                        {t('MAP_INTERVAL.TITLE')}
                    </Headline>
                    <p className={styles.mapCommonText}>
                        {t('MAP_INTERVAL.COMMON_TEXT')}
                    </p>
                    <div className={styles.screenWrapper}>
                        <img
                            alt="weenect map"
                            title={screen5}
                            className={styles.map}
                            src={screen5}
                        />
                    </div>
                </React.Fragment>,
            ],
            [
                StepToShow.SIDE_PAGES,
                <React.Fragment key={StepToShow.SIDE_PAGES}>
                    <Headline center className={styles.greet} level={2}>
                        {t('SIDE_PAGES.TITLE')}
                    </Headline>
                    <p className={styles.commonText}>
                        {translationSetterSidePages()}
                    </p>
                    <div className={styles.screenWrapper}>
                        <img
                            alt="weenect map"
                            title={screen6}
                            className={styles.map}
                            src={screen6}
                        />
                    </div>
                </React.Fragment>,
            ],
        ]);
        return getStepToShow.get(shown);
    };

    // This modal can be used when we need to alert customers about a problem
    // For example a KORE maintenance...
    // IF YOU NEED TO ACTIVATE/DEACTIVATE THIS MODAL YOU MIGHT ALSO NEED TO ACTIVATE/DEACTIVATE
    // THE ONE IN TRANSFER-PAGE.COMPONENT.TSX.
    const [alertModalOpen, setAlertModalOpen] = useState(false); // Modify here to display or not the alert popup;

    const maybeRenderAlertModal = () => {
        if (alertModalOpen) {
            return (
                <Modal
                    onClosed={() => setAlertModalOpen(false)}
                    className={styles.alertPopup}
                >
                    <Headline level={2}>{alertT('BODY0')}</Headline>
                    <p>
                        <Trans ns="alertCustomer" i18nKey="BODY1">
                            Pendant cette maintenance ...
                            <strong>suite et fin</strong>
                        </Trans>
                    </p>
                    <p>
                        <Trans ns="alertCustomer" i18nKey="BODY2">
                            We are well aware of that situation ...
                            <strong>email</strong>
                            or by phone ...
                        </Trans>
                    </p>
                    <p>{alertT('FOOTER')}</p>
                </Modal>
            );
        }
    };

    return (
        <div className={styles.page}>
            <SimpleHeaderWithLanguages />
            <PageContainer className={styles.container}>
                {maybeRenderAlertModal()}
                {step(stepToShow)}
                {displayBulletPoints()}
                {getNextButton()}
                {shouldDisplaySkipButton()}
            </PageContainer>
        </div>
    );
};

const mapState = (state: IRootState): IStateProps => {
    return {
        trackerList: state.userTrackers.rawTrackers,
        currentUserCountry: state.user.userData?.country,
    };
};

export const ConnectedonBoardingPage = compose<IProps, {}>(
    connect(mapState, null),
)(OnBoardingPage);
