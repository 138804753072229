import { ModelConfig } from '@rematch/core';

import {
    IActivityStore,
    IAggregateData,
    IEvolution,
    ISummaryData,
    Period,
} from './interface';
import {
    getActivityAgregateApi,
    getActivitySummaryApi,
    getEvolution,
} from './services';

export const activityTrackingStore: ModelConfig<IActivityStore> = {
    state: {
        period: Period.DAY,
        loading: false,
        // savedDate : value used to make datePicker component remember wich date was
        // previoulsy set and dont reset to today date
        savedDate: new Date(),
        evolution: {
            activity: 0,
            rest: 0,
        },
        summaryToday: {
            objective: 0,
            awakening: 0,
            activity: 0,
            rest: 0,
        },
        summaryData: {
            objective: 0,
            awakening: 0,
            activity: 0,
            rest: 0,
        },
        translatedPeriodSelected: '',
        aggregateData: null,
    },

    effects: (dispatch: any) => ({
        async getEvolutionData(data) {
            return getEvolution(
                data.startDate,
                data.endDate,
                data.trackerId,
            ).then(async (dataValues: ISummaryData) => {
                dispatch.activityTracking.setEvolution(dataValues);
            });
        },
        async getSumaryDataToday(data) {
            return getActivitySummaryApi(
                data.startDate,
                data.endDate,
                data.trackerId,
            ).then(async (dataValues: ISummaryData) => {
                dispatch.activityTracking.setSummaryToday(dataValues);
            });
        },
        async getSumaryData(data) {
            return getActivitySummaryApi(
                data.startDate,
                data.endDate,
                data.trackerId,
            ).then(async (dataValues: ISummaryData) => {
                dispatch.activityTracking.setSummary(dataValues);
            });
        },
        async getAggregateData(data) {
            dispatch.activityTracking.setLoading(true);
            return getActivityAgregateApi(
                data.startDate,
                data.endDate,
                data.trackerId,
                data.periodUnit,
                data.periodValue,
                data.timezone,
            ).then(async (dataValues: ISummaryData) => {
                dispatch.activityTracking.setAggregate(dataValues);
            });
        },
    }),

    reducers: {
        setEvolution: (
            state: IActivityStore,
            evolution: IEvolution,
        ): IActivityStore => ({
            ...state,
            evolution,
        }),
        setPeriod: (state: IActivityStore, period: Period): IActivityStore => ({
            ...state,
            period,
            loading: false,
        }),
        setSummary: (
            state: IActivityStore,
            summaryData: ISummaryData,
        ): IActivityStore => {
            return {
                ...state,
                summaryData,
            };
        },
        setSummaryToday: (
            state: IActivityStore,
            summaryToday: ISummaryData,
        ): IActivityStore => {
            return {
                ...state,
                summaryToday,
            };
        },
        setLoading: (
            state: IActivityStore,
            loading: boolean,
        ): IActivityStore => ({
            ...state,
            loading,
        }),
        setTranslatedPeriodSelected: (
            state: IActivityStore,
            translatedPeriodSelected: string,
            savedDate: Date,
        ): IActivityStore => ({
            ...state,
            translatedPeriodSelected,
            savedDate,
        }),
        setAggregate: (
            state: IActivityStore,
            aggregateData: IAggregateData,
        ): IActivityStore => ({
            ...state,
            aggregateData,
            loading: false,
        }),
    },
};
