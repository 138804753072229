import { colors } from '../../../../styles/colors';
import { getVectorOpacity } from '../utils';

/**
 * Draw vectors on Google map 'map' object
 * by calling the 'new maps.Polyline' Google-map API function.
 * We use the API because we can't use custom components for vectors.
 *
 * @param vectors List of vectors. A vector is a start coord and a end coord (looks like [[lat, lng], [lat, lng]])
 * @param maps Google-map API object
 * @param map Google-map map object (on which we draw)
 */
export const drawVectors = (vectors: any, maps: any, map: any, mapVectors: any[]) => {
    mapVectors.map((vector) => {
        vector.setMap(null)
    })
    return vectors.map((v: number[][], i: number) => {
        const start = v[0];
        const end = v[1];
        const path = new maps.Polyline({
            path: [
                { lat: start[0], lng: start[1] },
                { lat: end[0], lng: end[1] },
            ],
            strokeOpacity: 0,
            icons: [
                {
                    icon: {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: getVectorOpacity(i, vectors),
                        strokeWeight: 10,
                        strokeColor: colors.colorFlamingo,
                        scale: 1,
                    },
                    offset: '0',
                    repeat: '20px',
                },
            ],
        });
        return path;
    });
}