import get from 'lodash/get';

import { ITracker, ITrackerEvent } from '../interfaces-api';

/**
 * If trackers doesn't have position data, are offline etc, remove them
 */
export const hasActiveGPS = (tracker: ITracker): boolean => {
    if (!tracker.position || tracker.position.length === 0) {
        return false;
    }

    const position: ITrackerEvent = tracker.position[0];

    return (
        position &&
        typeof position.longitude === 'number' &&
        typeof position.latitude === 'number' &&
        position.valid_signal
    );
};

export const hasPosition = (tracker: ITracker): boolean => {
    const lat = get(tracker, 'position[0].latitude');
    const lng = get(tracker, 'position[0].longitude');

    return typeof lat === 'number' && typeof lng === 'number';
};
