import { verifyIfUserHasPaymentIssue } from 'app/user/selectors/account-option-selector';
import cx from 'classnames';
import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';

import { config } from '../../../config';
import weenectLogo from '../../../images/LogoCouleur.svg';
import { ReactComponent as CloseIcon } from '../../icons/menu-x.svg';
import { ReactComponent as BurgerIcon } from '../../icons/menu.svg';

import { ConnectedNotifications } from '../../notifications/notifications/notifications.component';
import { trackersRoute } from '../../routing/routes';
import { IRootState } from '../../store/store';
import { ConnectedLogoutButton } from '../user-area/user-area.component';
import { HeaderNavLink } from './header-nav-link/header-nav-link.component';
import styles from './nav-header.module.scss';
import { INavLink } from './nav-links';

const logoSource = weenectLogo;
const appVersion = process.env.REACT_APP_VERSION;

export interface IStateProps {
    userHasPaymentIssue: boolean;
    logoHeader: string | null;
    displayLogoHeader: boolean | null;
    country: string | null;
}

export interface IHeaderOuterProps extends HTMLAttributes<HTMLDivElement> { }

export interface IHeaderProps extends IHeaderOuterProps, IStateProps { }

export const NavHeader = ({
    className,
    userHasPaymentIssue,
    logoHeader,
    country,
    displayLogoHeader,
}: IHeaderProps) => {
    const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

    const { t } = useTranslation('header');

    const navLinks: INavLink[] = [
        {
            label: t('NAV.TRACKERS'),
            path: '/trackers',
        },
        {
            label: t('NAV.ACCOUNT'),
            path: '/account/profile',
        },
        {
            label: t('NAV.SUPPORT'),
            path: '/support',
        },
    ];
    const renderBurgerIcon = () => {
        if (mobileNavOpen) {
            return (
                <CloseIcon
                    onClick={() => setMobileNavOpen(false)}
                    className={styles['burger-icon']}
                />
            );
        } else {
            return (
                <BurgerIcon
                    className={styles['burger-icon']}
                    onClick={() => setMobileNavOpen(true)}
                />
            );
        }
    };

    const maybeRenderInsuranceLink = () => {
        if (country === 'FR') {
            return (
                <HeaderNavLink
                    key={config.INSURANCE_LINK}
                    to={config.INSURANCE_LINK}
                    external={true}
                >
                    {t('NAV.INSURANCE')}
                </HeaderNavLink>
            );
        }
    };

    const maybeRenderWhiteLabel = () => {
        if (displayLogoHeader && logoHeader) {
            return (
                <div className={styles['logo-header']}>
                    <span> {t('WHITE_LABEL.IN_PARTERNSHIP_WITH')} </span>
                    <img src={logoHeader} alt="" />
                </div>
            );
        }
    };
    return (
        <header className={cx(styles['nav-header'], className)}>
            <div className={styles.container}>
                <RouterLink to={trackersRoute}>
                    <div className={styles['logo-container']}>
                        <img
                            alt="Weenect logo"
                            title={appVersion}
                            className={styles.logo}
                            src={logoSource}
                        />
                    </div>
                </RouterLink>
                <nav
                    className={cx(styles.nav, {
                        [styles['mobile-nav-open']]: mobileNavOpen,
                    })}
                >
                    {navLinks.map((link) => (
                        <HeaderNavLink
                            key={link.path}
                            to={link.path}
                            hasWarningIcon={link.path === '/account/profile' ? userHasPaymentIssue : false}
                            external={link.external}
                        >
                            {link.label}
                        </HeaderNavLink>
                    ))}
                    {maybeRenderInsuranceLink()}
                    {maybeRenderWhiteLabel()}
                    <ConnectedLogoutButton
                        className={styles['user-area-mobile']}
                    />
                </nav>
                {renderBurgerIcon()}
                < ConnectedLogoutButton className={styles['user-area']} />
            </div>
            <ConnectedNotifications className={styles.notifications} />
        </header>
    );
};

const mapState = (state: IRootState): IStateProps => {
    const componentState: IStateProps = {
        userHasPaymentIssue: false,
        logoHeader: null,
        displayLogoHeader: null,
        country: null,
    };
    const hasPaymentIssue = verifyIfUserHasPaymentIssue()
    if (state.user.userData) {
        componentState.country = state.user.userData.country;
        componentState.userHasPaymentIssue = hasPaymentIssue(state)
        if (state.user.userData.white_label) {
            componentState.logoHeader =
                state.user.userData.white_label.logo_header;
            componentState.displayLogoHeader =
                state.user.userData.white_label.display_header;
        }
    }
    return componentState;
};

export const ConnectedNavHeader = compose<IHeaderProps, IHeaderOuterProps>(
    connect(mapState, null),
)(NavHeader);
