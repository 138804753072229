import moment from 'moment';
import { createSelector } from 'reselect';

import { trackersFromApi } from './selectors';

/**
 * Checks if user has only one tracker available AND it has no subscription.
 * It's special case for app to force user to continue the process
 */
export const makeGetIsSingleTrackerWithoutSubscription = () => {
    return createSelector(
        trackersFromApi,
        (trackers) => {
            if (trackers.length !== 1) {
                return false;
            }

            const theTracker = trackers[0];

            /* https://redmine.weenect.com/issues/5906 */
            if (
                theTracker.had_subscription === true &&
                (theTracker.expiration_date === null ||
                    moment(theTracker.expiration_date).isAfter(moment.now()))
            ) {
                return false;
            }

            /**
             * Null means that no subscription was ever created, so user registered tracker
             * and dropped process without submitting.
             */
            return theTracker.subscription === null;
        },
    );
};
