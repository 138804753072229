import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router';
import { compose } from 'recompose';

import { IRootState } from '../../store/store';

interface IStateProps {
    useSubscription: any
    trackerIdDetected: number | null;
}

interface IParams {
    id: string;
}

interface IHOCProps extends IParams, IStateProps { }

/**
 * This HOC is responsible of checking if user has not come
 * in one of the subscription_process page after a reload (F5) or manually by URL inputs.
 * If so, it redirects to map page because we need to rebuild the userChoices object (tracker_id, offer selected, etc.).
 */
const hasUserChoicesGuard = (Component: ComponentType<any>) => ({
    trackerIdDetected,
    useSubscription,
    ...props
}: IHOCProps) => {
    const history = useNavigate()
    const { id } = useParams()
    useEffect(() => {
        if (!useSubscription) {
            if (trackerIdDetected === null) {
                // Try to guess current tracker from URL
                trackerIdDetected = parseInt(id!, 10) || null;
            }
            history(
                '/add/subscription/' + `${trackerIdDetected}`,
            )
        }
    }, [id,]);
    return <Component {...props} />;
};

const mapState = (state: IRootState): IStateProps => {
    return {
        trackerIdDetected: state.subscriptionProcess.choices.trackerId,
        useSubscription: state.subscriptionProcess.choices
    };
};

const connectedHasUserChoicesGuard = compose(
    connect(mapState),
    hasUserChoicesGuard,
);

export { connectedHasUserChoicesGuard as hasUserChoicesGuard };
