import * as yup from 'yup';
import { getGlobalT } from '../../../../i18n/setup-translations';
import { isIMEI } from '../../../../utils/imei-validator';

export const getRegisterTrackerFormValidationSchema = () => {
    const t = getGlobalT();

    return yup.object({
        name: yup.string().required(),
        imei: yup
            .string()
            .test({
                name: 'imei',
                test: isIMEI,
                // @ts-ignore
                message: t('errors:IMEI'),
            })
            .required(),
    });
};
