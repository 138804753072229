import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import 'i18next';

import { getUserCountryCode } from '../app/user/get-user-country';

import { DE } from './locales/de';
import { EN } from './locales/en'
import { ES } from './locales/es';
import { FR } from './locales/fr';
import { IT } from './locales/it';
import { NL } from './locales/nl';
import { setupValidationMessages } from './setup-validation-messages';

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}
export const initI18n = () => {
    return i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            debug: false,
            returnNull: false,
            /**
             * TODO: Add more languages here.
             *   Keep keys from src/i18n/available-languages.ts
             */
            resources: {
                de: DE,
                en: EN,
                es: ES,
                fr: FR,
                it: IT,
                nl: NL,
            },
            lng: (getUserCountryCode() || 'fr').toLowerCase(),
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false,
            },
            appendNamespaceToCIMode: true,
        })
        .then((t) => {
            setupValidationMessages(t);
        });
};

export const updateAppLanguage = (lng: string) =>
    i18n.changeLanguage(lng.toLowerCase());

/**
 * Use global singleton because only inside React it can be used from context
 * This has to be loaded lazy to work after init.
 */
export function getGlobalT() {
    return i18n.t.bind(i18n);
}
