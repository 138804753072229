import cx from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {  useLocation } from 'react-router';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { compose, mapProps } from 'recompose';
import { useIsTabletOrSmaller } from '../../../utils/media-queries';
import { AddButton } from '../../common/button/add-button.component';
import { Modal } from '../../common/modal/modal.component';
import { translationMapping } from '../../history/activity/activity-item/activity-item-factory';
import {
    getIsActivityVisible,
    getIsHeatmapVisible,
    getIsPlayingRunning,
    makeGetActivityLastFetchedEvents,
    makeGetActivityPlayingItems,
    makeGetEventsOnMap,
} from '../../history/selectors';
import { ConnectedNavHeader } from '../../layout/nav-header/nav-header.component';
import { TrackerActivatingModal } from '../../onboarding/tracker-activating-modal/tracker-activating-modal.component';
import { PopinAlert } from '../../popin-alert/popin-alert.component';
import { trackersRoute } from '../../routing/routes';
/**
 * Model
 */
import { Dispatch, IRootState } from '../../store/store';
import { ConnectedLiveModeDisplay } from '../../tracker-live-mode/components/live-mode-display/live-mode-display.component';
/**
 * Selectors
 */
import { getIsLiveModeModalOpen } from '../../tracker-live-mode/tracker-live-mode.store';
/**
 * Components
 */
import { TrackerReplaceModal } from '../../tracker-replacement/tracker-replace-modal/tracker-replace-modal.component';
import { ConnectedMobileTrackers } from '../../trackers/components/mobile-trackers/mobile-trackers.component';
import { TrackersListWithRouter } from '../../trackers/components/trackers-list/trackers-list.component';
import {
    ITrackerOnMap,
    ITrackerOnSidebar,
    mapTrackerTypeToImage,
    TrackerType,
} from '../../trackers/interfaces';
import {  ITrackerEvent } from '../../trackers/interfaces-api';
import { makeGetSelectedTrackerId } from '../../trackers/selectors/get-selected-tracker-id';
import { makeGetSelectedTrackerName } from '../../trackers/selectors/get-selected-tracker-name';
import { makeGetSelectedTrackerType } from '../../trackers/selectors/get-selected-tracker-type';
import { makeGetTrackerSupportsLiveMode } from '../../trackers/selectors/get-supports-live-mode';
import { makeGetTrackerIsActivating } from '../../trackers/selectors/get-tracker-is-activating';
import { makeGetTrackersOnMap } from '../../trackers/selectors/get-trackers-on-map';
import { makeGetTrackersOnSidebar } from '../../trackers/selectors/get-trackers-on-sidebar';
import { withUserLocation } from '../../user-location/with-user-location';
import { withExpiredSubRedirect } from '../../user-subscriptions/expired-sub-redirect';
import { ConnectedMapControls } from '../components/map-controls/map-controls.component';
import { MapComponent } from '../components/map/map.component';
/**
 * Guards
 */
import { biogaranGuardRaw } from '../guards/biogaran-redirect-guard';
import { hasFluidTrackerGuard } from '../guards/has-fluid-tracker-guard';
import { singleTrackerDroppedSubGuard } from '../guards/single-tracker-dropped-sub-guard';
import { singleTrackerEmptySubGuard } from '../guards/single-tracker-empty-sub-guard';
import { zeroTrackersGuard } from '../guards/zero-trackers-guard';
import { MapPageInnerRoutes } from '../inner-routes/inner-routes.component';
import { MapLayerType } from '../interfaces';
/**
 * Resolvers
 */
import { withGeofencesResolving } from '../resolvers/geofences-resolver';
import { withPaymentRedirectionResolving } from '../resolvers/payment-redirection-resolver';
import { withSubscriptionResolving } from '../resolvers/subscription-resolver';
import styles from './map-page.module.scss';

interface IActions {
    setSelectActiveTracker: (tracker: number | null) => any
    closeOnboardingModal: () => any;
    doUpdateQuery: (query: string) => Promise<any>;
    setScroll: (scroll: number) => any;
}

interface IStateProps {
    activeTrackerId: number | null;
    activeTrackerName: string;
    activeTrackerType: TrackerType | null;
    activityPathEnabled: boolean;
    events: ITrackerEvent[];
    heatMapEnabled: boolean;
    isActiveTrackerActivating: boolean;
    isActivityPlaying: boolean;
    liveModeModalOpen: boolean;
    onboardingModalOpen: boolean;
    selectedLayer: MapLayerType;
    trackersOnMap: ITrackerOnMap[];
    totalTrackers: number;
    trackersOnSidebar: ITrackerOnSidebar[];
    sideBarScroll: number;
    trackerSearchQuery: string;
    isMobileRedirect: boolean;
}

interface IOuterProps { }

interface IMappedProps {
    historyPageOpen: boolean;
    innerRouteOpen: boolean;
    onInnerRouteClose(): unknown;
    maybeRenderLiveModeModal(): ReactNode;
    maybeRenderOnboardingModal(): ReactNode;
}

export interface IMapPageProps
    extends IOuterProps,
    IStateProps,
    IActions,
    IMappedProps { }

/**
 * TODO: Make this component smaller
 *   To achieve this, create a portal to send items to map from other places
 *   The problem is to send also leaflet context somehow
 */
const areEqual = (
    prevProps: IStateProps & IMappedProps,
    nextProps: IStateProps & IMappedProps,
) => {
    if (
        prevProps.activeTrackerId === nextProps.activeTrackerId &&
        prevProps.innerRouteOpen === nextProps.innerRouteOpen &&
        prevProps.trackerSearchQuery === nextProps.trackerSearchQuery &&
        prevProps.activityPathEnabled === nextProps.activityPathEnabled &&
        prevProps.historyPageOpen === nextProps.historyPageOpen &&
        prevProps.selectedLayer === nextProps.selectedLayer &&
        prevProps.isActivityPlaying === nextProps.isActivityPlaying &&
        !nextProps.isActivityPlaying
        &&
        prevProps.events[0]?.date_server === nextProps.events[1]?.date_server &&
        prevProps.events[0]?.date_server === nextProps.events[0]?.date_server
    ) {
        return true;
    }
    return false;
};
export const MapPagePure = React.memo(
    ({
        activeTrackerId,
        activeTrackerName,
        events,
        heatMapEnabled,
        innerRouteOpen,
        selectedLayer,
        trackersOnMap,
        trackersOnSidebar,
        maybeRenderLiveModeModal,
        maybeRenderOnboardingModal,
        setScroll,
        doUpdateQuery,
        totalTrackers,
        trackerSearchQuery,
        setSelectActiveTracker,
        historyPageOpen,
        activityPathEnabled,
        isActivityPlaying,
        sideBarScroll,
        isMobileRedirect,
    }: IMapPageProps) => {
        const isMaxTablet = useIsTabletOrSmaller();
        const { t } = useTranslation('trackers');
        const history = useNavigate()
        const match = useLocation()
        const { id } = useParams()
        const [modifyTrackerOpen, setmodifyTrackerOpen] = useState(false);
        const showReplaceModal = () => setmodifyTrackerOpen(true);
        const closeReplaceModal = () => setmodifyTrackerOpen(false);

        historyPageOpen = location.pathname === `/trackers/${id}/history`
        const goToTransfer = () => {
            history('/transfer');
        };
        const goToRegister = () => {
            if (activeTrackerId) {
                setSelectActiveTracker(null)
            }
            history('/add/register');

        };
        useEffect(() => {
            maybeSetTrackersOnMap();
        }, []);
        useEffect(() => {
            const sidebar = document.getElementsByClassName(
                styles.trackersList,
            ) as HTMLCollectionOf<HTMLElement>;
            if (sidebar.length > 0) {
                sidebar[0].scrollTo(0, sideBarScroll);
            }
        }, []);
        const prevTrackersOnMapRef = useRef(trackersOnMap);
        useEffect(() => {
            if (trackersOnMap) {
                prevTrackersOnMapRef.current = trackersOnMap;
            }
        });
        const prevTrackerOnMap = prevTrackersOnMapRef.current;

        useEffect(() => {
            if (isActivityPlaying) {
                maybeSetTrackersOnMap();
            }
            trackersOnMap.map((tracker: ITrackerOnMap) => {
                const diff = prevTrackerOnMap.find(
                    (prevTracker: ITrackerOnMap) =>
                        prevTracker.id === tracker.id,
                )
                if (
                    JSON.stringify(diff?.position) !==
                    JSON.stringify(tracker.position)
                ) {
                    maybeSetTrackersOnMap(tracker);
                }
            });
        }, [
            selectedLayer,
            innerRouteOpen,
            activityPathEnabled,
            trackersOnMap,
            isActivityPlaying,
            historyPageOpen,
            activeTrackerId,
        ]);
        useEffect(() => {
            if (!activeTrackerId && trackersOnMap.length === 1) {
                setSelectActiveTracker(trackersOnMap[0].id)
                history(trackersRoute + '/' + trackersOnMap[0].id);
            }
        }, [activeTrackerId])
        useEffect(() => {
            if (!activeTrackerId && id) {
                setSelectActiveTracker(parseInt(id, 10))
            }
            if (activeTrackerId && !id) {
                setSelectActiveTracker(null)
            }
        }, [])
        const onTrackerSelected = (id: number | null) => {
            if (id) {
                setSelectActiveTracker(id)
                history(trackersRoute + '/' + id);
            } else {
                setSelectActiveTracker(null)
                history(trackersRoute);
            }
        };
        const child = document.getElementsByClassName(styles.trackersList);
        const onScroll = () => {
            if (child.length > 0) {
                setScroll(child[0].scrollTop);
            }
        };
        const maybeRenderInnerSidebar = () => {
            if (activeTrackerId && innerRouteOpen && !isMaxTablet) {
                return (
                    <div
                        className={cx(styles.innerSidebar, {
                            [styles.small]: historyPageOpen,
                        })}
                    >
                        <MapPageInnerRoutes
                            onInnerRouteClose={() => {
                                history(`${trackersRoute}/${id}`, { state: { stopRedirect: true } })
                            }
                            }
                            activeTrackerName={activeTrackerName}
                        />
                    </div>
                );
            }
        };

        const maybeRenderInnerSidebarMobile = () => {
            if (activeTrackerId && innerRouteOpen && isMaxTablet) {
                return (
                    <div>
                        <MapPageInnerRoutes
                            onInnerRouteClose={() => {
                                history(`${trackersRoute}/${id}`, { state: { stopRedirect: true } })
                            }
                            }
                            activeTrackerName={activeTrackerName}
                        />
                    </div>
                );
            }
        };

        const maybeRenderDesktopTrackers = () => {
            if (isMaxTablet) {
                return;
            }
            const filterTrackers = () => {
                return trackersOnSidebar.filter((tracker) =>
                    tracker.name
                        .toLowerCase()
                        .includes(trackerSearchQuery?.toLowerCase()),
                );
            };
            return (
                <aside className={cx(styles.sidebar)}>
                    <div className={styles.sidebarContainer}>
                        <div
                            className={styles.trackersList}
                            onScroll={onScroll}
                        >
                            <TrackersListWithRouter
                                updateQuery={doUpdateQuery}
                                onTrackerSelected={onTrackerSelected}
                                trackerSearchQuery={trackerSearchQuery}
                                activeTracker={activeTrackerId}
                                trackers={filterTrackers()}
                                totalTrackers={totalTrackers}
                            />
                        </div>
                        <div className={styles.addTrackerButtonContainer}>
                            <AddButton
                                onClick={showReplaceModal}
                                secondary
                                small
                                block
                            >
                                {t('ADD_TRACKER_BUTTON.LABEL')}
                            </AddButton>
                        </div>
                        {maybeRenderReplaceModal()}
                    </div>
                </aside>
            );
        };
        const maybeRenderReplaceModal = () => {
            if (modifyTrackerOpen) {
                return (
                    <TrackerReplaceModal
                        onAddClick={goToRegister}
                        onReplaceClick={goToTransfer}
                        onClosed={closeReplaceModal}
                    />
                );
            }
        };
        const maybeRenderMobileTrackers = () => {
            if (!isMaxTablet || innerRouteOpen) {
                return;
            }
            return (
                <ConnectedMobileTrackers
                    innerRouteOpen={innerRouteOpen}
                    trackers={trackersOnSidebar}
                    activeTrackerId={activeTrackerId}
                    className={styles.mobileTrackers}
                    onTrackerSelected={onTrackerSelected}
                    onReturnToTrackersList={() => onTrackerSelected(null)}
                />

            );
        };

        /**
         * Returns event date formatted with event type translation.
         */
        const renderEventDate = (event: ITrackerEvent) => {
            const eventTypeTraduction = translationMapping(event);
            const eventDate = new Date(event.date_server).toLocaleTimeString(
                t('misc:LOCALE'),
            );
            if (eventTypeTraduction) {
                return (
                    <tr>
                        <th>{eventDate} : </th>
                        <td>{eventTypeTraduction}</td>
                    </tr>
                );
            }
            return <th colSpan={2}>{eventDate}</th>;
        };
        /**
         * Create HTML elements rendered in marker tooltip on hover on the map icons.
         */
        const displayEventTooltip = (
            event: ITrackerEvent,
            tracker: ITrackerOnMap | null,
        ) => {
            if (tracker && event) {
                return (
                    <table>
                        <tr>
                            <th colSpan={2} className={styles.name}>
                                {tracker.name}
                            </th>
                        </tr>
                        {renderEventDate(event)}
                        <tr>
                            <th>{t('map:POSITION')} :</th>
                            <td>
                                {event.latitude}, {event.longitude}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {t(
                                    'trackerSettings:NOTIFICATION_SETTINGS.BATTERY.TITLE',
                                )}{' '}
                                :
                            </th>
                            <td>{event.battery}%</td>
                        </tr>
                    </table>
                );
            }
        };
        const maybeSetTrackersOnMap = (trackerTochange?: ITrackerOnMap) => {
            let tracker: any = trackersOnMap;
            const historyTabsOpen = match.pathname.includes('history');
            // check if we match history on url if yes disable trackers data to not display the live position
            if (historyTabsOpen) {
                return (tracker = []);
            }
            if (
                ((activityPathEnabled || isActivityPlaying) &&
                    historyPageOpen) ||
                (activeTrackerId && !trackerTochange)
            ) {
                const trackerInfoselect = trackersOnMap.find(
                    (el) => el.id === activeTrackerId,
                );
                tracker = [trackerInfoselect];
            }
            if (trackerTochange && activeTrackerId) {
                tracker = [trackerTochange];
            }
            return tracker;
        };

        return (
            <div className={styles.pageContainer}>
                {isMobileRedirect ? null : (
                    <ConnectedNavHeader className={styles.header} />
                )}
                <div className={styles.columnWrapper}>
                    {maybeRenderDesktopTrackers()}
                    {maybeRenderInnerSidebar()}
                    <div className={styles.mapContainer}>
                        <div className={styles.mapInnerContainer}>
                            {maybeRenderLiveModeModal()}
                            <ConnectedMapControls className={styles.controls} />
                            {/* {historyPageOpen && <MapEvents />} */}
                            <MapComponent
                                events={events}
                                layer={selectedLayer}
                                shouldDisplayHeatMap={
                                    heatMapEnabled &&
                                    !!activeTrackerId &&
                                    historyPageOpen
                                }
                                displayEventTooltip={displayEventTooltip}
                                onTrackerSelected={onTrackerSelected}
                                trackersOnMap={maybeSetTrackersOnMap()}
                            />
                            <PopinAlert />
                        </div>
                    </div>
                    {maybeRenderMobileTrackers()}
                    {maybeRenderInnerSidebarMobile()}
                </div>
                {maybeRenderOnboardingModal()}
            </div>
        );
    },
    areEqual,
);

const makeMapState = () => {
    const getTrackersOnMap = makeGetTrackersOnMap();
    const getTrackersOnSidebar = makeGetTrackersOnSidebar();
    const getSelectedTrackerId = makeGetSelectedTrackerId();
    const getSelectedTrackerName = makeGetSelectedTrackerName();
    const getSelectedTrackerType = makeGetSelectedTrackerType();
    return (state: IRootState): IStateProps => {
        const getEvents = (
            trackerId: number | null,
            isActivityPathEnabled: boolean,
            isHeatMapEnabled: boolean,
            activityIsPlaying: boolean,
        ) => {
            if (trackerId && (isActivityPathEnabled || isHeatMapEnabled)) {
                return makeGetEventsOnMap(trackerId)(state);
            }
            if (trackerId && activityIsPlaying) {
                return makeGetActivityPlayingItems(trackerId)(state);
            } else if (trackerId) {
                return makeGetActivityLastFetchedEvents(trackerId)(state);
            }
            const defaultResponse: ITrackerEvent[] = [];
            return defaultResponse;
        };
        const activeTrackerId = getSelectedTrackerId(state);
        const activityPathEnabled = getIsActivityVisible(state);
        const heatMapEnabled = getIsHeatmapVisible(state);
        const isActivityPlaying = getIsPlayingRunning(state);
        const events = getEvents(
            activeTrackerId,
            activityPathEnabled,
            heatMapEnabled,
            isActivityPlaying,
        );
        const getActivatingStatus = makeGetTrackerIsActivating(
            activeTrackerId as number,
        );

        const getTrackerSupportsLiveMode = makeGetTrackerSupportsLiveMode(
            activeTrackerId as number,
        );
        return {
            activeTrackerId,
            activeTrackerName: getSelectedTrackerName(state) || '',
            activeTrackerType: getSelectedTrackerType(state),
            activityPathEnabled,
            events,
            heatMapEnabled,
            isActiveTrackerActivating: getActivatingStatus(state) || false,
            isActivityPlaying,
            liveModeModalOpen:
                getIsLiveModeModalOpen(state) &&
                getTrackerSupportsLiveMode(state),
            onboardingModalOpen: state.onboarding.modalOpen,
            selectedLayer: state.map.selectedLayer,
            trackersOnMap: getTrackersOnMap(state),
            totalTrackers: state.userTrackers.rawTrackers.length,
            trackersOnSidebar: getTrackersOnSidebar(state),
            trackerSearchQuery: state.userTrackers.trackerSearchQuery,
            sideBarScroll: state.map.sideBarScroll,
            isMobileRedirect: state.layout.isMobileRedirect,
        };
    };
};

const mapDispatch = (dispatch: Dispatch) => ({
    setSelectActiveTracker: (trackerId: number | null) =>
        dispatch.userTrackers.selectActiveTracker(trackerId),
    closeOnboardingModal: dispatch.onboarding.hideOnboardingModal,
    doUpdateQuery: async (query: string) =>
        dispatch.userTrackers.updateQuery(query),
    setScroll: async (scroll: number) => dispatch.map.setScrollOnLoad(scroll),
});

export const MapPage = compose<IMapPageProps, IOuterProps>(
    connect(makeMapState, mapDispatch),
    mapProps(
        (props: any & IStateProps & IActions): IMappedProps => ({
            ...props,
            maybeRenderLiveModeModal() {
                const { liveModeModalOpen } = props;
                if (liveModeModalOpen) {
                    return (
                        <ConnectedLiveModeDisplay
                            className={styles.liveModeModal}
                        />
                    );
                }
            },
            maybeRenderOnboardingModal() {
                const { onboardingModalOpen, closeOnboardingModal } = props;

                // If trackerImageFromType stays null, modal will use a default image.
                let trackerImageFromType = null;
                if (props.activeTrackerType) {
                    trackerImageFromType =
                        mapTrackerTypeToImage.get(props.activeTrackerType) ||
                        null;
                }

                if (onboardingModalOpen) {
                    return (
                        <Modal onClosed={closeOnboardingModal}>
                            <TrackerActivatingModal
                                trackerImage={trackerImageFromType}
                                onClose={closeOnboardingModal}
                            />
                        </Modal>
                    );
                }
            },
            innerRouteOpen: RegExp(`/trackers/${useParams().id}/.+`).test(useLocation().pathname),
            historyPageOpen: useLocation().pathname === '/trackers/:id/history',
        }),
    ),
    withPaymentRedirectionResolving,
    zeroTrackersGuard,
    hasFluidTrackerGuard,
    singleTrackerEmptySubGuard,
    singleTrackerDroppedSubGuard,
    withSubscriptionResolving,
    withGeofencesResolving,
    withUserLocation,
    withExpiredSubRedirect,
    biogaranGuardRaw,
)(MapPagePure);
