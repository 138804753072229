import { PaymentStatus } from './interfaces-api';

export interface IPaymentHistoryItem {
    id: number;
    date: string;
    status: PaymentStatus;
    paymentId: string;
    paymentName: string;
    invoiceUrl?: string;
    code?: Array<string>; // Only if payment was for OPTION
}

export enum IMetricFactors {
    M_TO_YARD = 1.09361,
}
