import { getGlobalT } from '../../../i18n/setup-translations';
import { IMetricFactors } from '../../account/interfaces';
import { MetricSystem } from '../../history/interfaces';
import { store } from '../../store/store';
import { accuracyMap, AccuracyRange } from '../accuracy-map';
import { IAccuracyData } from '../interfaces';

const getAccuracyLabel = (accuracyRadius: number) => {
    const t = getGlobalT();
    if (accuracyRadius < accuracyMap.GOOD) {
        return t('trackerLeftPanel:GOOD_GPS_ACCURACY');
    } else if (accuracyRadius < accuracyMap.MEDIUM) {
        return t('trackerLeftPanel:MEDIUM_GPS_ACCURACY');
    } else {
        return t('trackerLeftPanel:POOR_GPS_ACCURACY');
    }
};

const getAccuracyRange = (accuracyRadius: number) => {
    if (accuracyRadius < accuracyMap.GOOD) {
        return AccuracyRange.GOOD;
    } else if (accuracyRadius < accuracyMap.MEDIUM) {
        return AccuracyRange.MEDIUM;
    } else {
        return AccuracyRange.POOR;
    }
};

export const formatAccuracyInfo = (accuracy: number): IAccuracyData => {
    const userData = store.getState().user.userData;
    let preferredMetric = MetricSystem.METERS;
    // Set Metric to yard
    if (userData) {
        if (userData.preferred_metric_system === MetricSystem.MILES) {
            preferredMetric = MetricSystem.YARD;
            accuracy = parseFloat(
                (accuracy * IMetricFactors.M_TO_YARD).toFixed(2),
            );
        }
    }

    return {
        accuracyDescriptive: `${getAccuracyLabel(
            accuracy,
        )} : < ${accuracy} ${preferredMetric}`,
        accuracyFormatted: `< ${accuracy} ${preferredMetric}`,
        accuracyRadius: accuracy,
        accuracyRange: getAccuracyRange(accuracy),
    };
};
