import { FormikProps, withFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getGlobalT } from '../../../i18n/setup-translations';
import { IWithFormUtils, withFormUtils } from '../../../utils/form-helpers';
import { Button } from '../../common/button/button.component';
import { Checkbox } from '../../common/checkbox/checkbox.component';
import { Input } from '../../common/input/input.component';
import { Typography } from '../../common/typography/typography.component';
import { INotification } from '../../notifications/interfaces';
import { Dispatch, IRootState } from '../../store/store';
import { ITracker } from '../../trackers/interfaces-api';
import { makeGetTrackerModel } from '../../trackers/selectors/get-tracker-model';
import { sosMode } from '../interfaces';
import { getCallButtonSettingsValidationSchema } from './call-button-settings-validation.schema';
import styles from './call-button-settings.module.scss';

interface IOuterProps {
    isAnimalType: boolean;
    trackerID: number;
}

export interface IFormValues {
    number: string;
    directCall: boolean;
}

interface IStateProps {
    existingSosNumber: string;
    sosMode: sosMode;
}

interface IActions {
    updateSettings(values: IFormValues): Promise<unknown>;
    showNotification(notification: INotification): Promise<unknown>;
    clearNotifications(): unknown;
}

interface IProps
    extends IOuterProps,
        FormikProps<IFormValues>,
        IActions,
        IWithFormUtils {}

const CallButtonSettings = ({
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    isAnimalType,
    isFieldValid,
    getErrorMessage,
    setFieldValue,
}: IProps) => {
    const { t } = useTranslation(['trackerSettings']) as any;

    /**
     * Direct call is always available in case of animals, but for people
     * it can be changed
     */
    const maybeRenderDirectCallCheckbox = () => {
        if (!isAnimalType) {
            return (
                <Checkbox
                    className={styles.checkbox}
                    checked={values?.directCall}
                    onChange={handleChange}
                    name="directCall"
                >
                    {t('TRAINING_SOS.DIRECT_CALL')}
                </Checkbox>
            );
        }
    };

    /**
     * "SOS button" is called "Training buttons" for animals
     * So we adjust traduction
     */
    const sosTraductionFromTrackerType = () => {
        if (isAnimalType) {
            return (
                <Typography withMarginBottom size12>
                    {t('TRAINING_SOS.TRAINING_TEXT')}
                </Typography>
            );
        }
        return (
            <Typography withMarginBottom size12>
                {t('TRAINING_SOS.SOS_TEXT')}
            </Typography>
        );
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            {sosTraductionFromTrackerType()}
            <Input
                placeholder={t('TRAINING_SOS.INPUT_PLACEHOLDER')}
                small
                value={values?.number || ''}
                onBlur={handleBlur}
                name="number"
                isValid={isFieldValid('number')}
                errorMessage={getErrorMessage('number')}
                isPhone
                // We need to setFieldValue this way to handle DOM re-render
                onChange={(phoneValue) => setFieldValue('number', phoneValue)}
            />
            {maybeRenderDirectCallCheckbox()}
            <Button type="submit" small className={styles.save}>
                {t('commonActions:SAVE')}
            </Button>
        </form>
    );
};

const mapStateToProps = (
    state: IRootState,
    props: IOuterProps,
): IStateProps => {
    const getTrackerModel = makeGetTrackerModel(props.trackerID);

    return {
        existingSosNumber: (getTrackerModel(state) as ITracker).sos_phone,
        sosMode: (getTrackerModel(state) as ITracker).sos_mode as sosMode,
    };
};

const mapDispatch = (dispatch: Dispatch, props: IOuterProps): IActions => ({
    async updateSettings(values: IFormValues) {
        const payload = {
            trackerId: props.trackerID,
            newNumber: values.number,
        };

        if (!props.isAnimalType) {
            (payload as any).mode = values.directCall
                ? sosMode.DIRECT
                : sosMode.FULL_SOS;
        }

        return dispatch.trackersSettings.updateSosSettings(payload);
    },
    showNotification: async (n: INotification) =>
        dispatch.notifications.showNotification(n),
    clearNotifications: dispatch.notifications.clearNotifications,
});

export const ConnectedCallButtonSettings = compose<IProps, IOuterProps>(
    connect(mapStateToProps, mapDispatch),
    withFormik<IStateProps & IOuterProps & IActions, IFormValues>({
        mapPropsToValues(props) {
            return {
                directCall: props.sosMode === sosMode.DIRECT,
                number: props.existingSosNumber,
            };
        },
        handleSubmit(values, formikBag) {
            const t = getGlobalT();
            formikBag.props.clearNotifications();

            formikBag.props
                .updateSettings(values)
                .then(() => {
                    formikBag.props.showNotification({
                        type: 'success',
                        content: t(
                            'trackerSettings:TRAINING_SOS.MESSAGES.UPDATE_SUCCESS',
                        ),
                    });
                })
                .catch((err) => {
                    formikBag.props.showNotification({
                        type: 'error',
                        content: t(
                            'trackerSettings:TRAINING_SOS.MESSAGES.UPDATE_ERROR_UNKNOWN',
                        ),
                    });
                });
        },
        validationSchema: () => getCallButtonSettingsValidationSchema(),
        enableReinitialize: true,
    }),
    withFormUtils,
)(CallButtonSettings);
