import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '../../common/button/button.component';
import { Link } from '../../common/link/link.component';
import { Typography } from '../../common/typography/typography.component';
import styles from '../subscription-alert-modal/subscription-alert-modal.module.scss';

export interface IProps {
    subscriptionId: number | null;
    onConfirm: () => any;
    onAbort: () => any;
}

export type ICreditCardAlertModal = IProps;

export const CreditCardAlertModal = ({
    subscriptionId,
    onAbort,
    onConfirm,
}: ICreditCardAlertModal) => {
    const { t } = useTranslation([
        'creditCardAlert',
        'changePaymentMean',
        'subscriptionAlert',
    ]);
    if (!subscriptionId) {
        return null;
    }

    return (
        <div className={styles.container}>
            <Typography className={styles.text}>
                <Trans ns="creditCardAlert" i18nKey="AUTO_RENEWAL_NOTICE">
                    DOES_NOT_WORK_WITHOUT_THIS_TEXT <strong>Attention !</strong>{' '}
                    Votre abonnement arrive à sa fin et votre carte a expiré.
                </Trans>
            </Typography>

            <Typography className={styles.text}>
                <> {t('creditCardAlert:AUTO_RENEWAL_ADVICE')}</>
            </Typography>

            <div className={styles.buttons}>
                <Button
                    className={styles.button}
                    primary
                    onClick={() => {
                        onConfirm();
                    }}
                >
                    <> {t('changePaymentMean:CHANGE_PAYMENT_MEAN')}</>
                </Button>
            </div>

            <hr className={styles.text} />

            <Typography className={styles.text}>
                <Trans ns="subscriptionAlert" i18nKey="SUPPORT_TEXT">
                    Si vous avez des questions ou que vous pensez que c'est une
                    erreur, contactez notre
                    <Link
                        href="https://weenect.zendesk.com/hc/fr"
                        target="_blank"
                    >
                        service client
                    </Link>
                </Trans>
            </Typography>

            <div className={styles.buttons}>
                <Button className={styles.button} secondary onClick={onAbort}>
                    <> {t('subscriptionAlert:CONTINUE_BUTTON')}</>
                </Button>
            </div>
        </div>
    );
};
