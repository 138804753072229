/**
 * https://redmine.weenect.com/issues/4985
 */
import { ITrackerEvent, TrackerEventType } from '../../trackers/interfaces-api';

const eventsOnList: TrackerEventType[] = [
    'ALM-A',
    'ALM-BAT',
    'ALM-F',
    'ALM-G',
    'ALM-H',
    'ALM-GEO-I',
    'ALM-GEO-O',
    'ALM-ON',
    'ALM-OFF',
    'CMD-W',
    'CMD-R',
    'CMD-M',
    'CMD-C',
    'CMD-V30',
    'CMD-V3',
];

export const filterEventsOnList = (event: ITrackerEvent) =>
    eventsOnList.includes(event.type);
