import React from 'react';
import { IconBadge } from '../../../common/icon-badge/icon-badge.component';
import { TrackerType } from '../../interfaces';
import {
    resolveTrackerColor,
    resolveTrackerIcon,
    TrackerIcon,
} from '../../tracker-icons';
import styles from './tracker-icon-badge.module.scss';

const getBadgeColor = (online: boolean, isInDeepSleep: boolean) => {
    if (online || isInDeepSleep) {
        return 'green';
    } else {
        return 'red';
    }
};

export interface ITrackerIconBadgeProps {
    color: string;
    icon: TrackerIcon | null;
    iconUrl: string | null;
    online: boolean;
    type: TrackerType;
    isInDeepSleep: boolean;
}

export const TrackerIconBadge = ({
    iconUrl,
    icon,
    type,
    color,
    isInDeepSleep,
    online,
}: ITrackerIconBadgeProps) => {
    return (
        <IconBadge
            cornerIndicator={getBadgeColor(online, isInDeepSleep)}
            icon={
                <img
                    className={iconUrl ? styles.iconUrl : ''}
                    alt="Tracker icon"
                    src={resolveTrackerIcon(iconUrl, icon, type)}
                />
            }
            color={resolveTrackerColor(color, type)}
        />
    );
};
