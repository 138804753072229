import React, { ComponentType, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { compose } from 'recompose';

import { config } from '../../../config';
import { Link } from '../../common/link/link.component';
import { Typography } from '../../common/typography/typography.component';
import { INotification } from '../../notifications/interfaces';
import {
    accountRoute,
    paymentErrorRoute,
    paymentErrorRouteBiogaran,
    paymentSuccessRoute,
    paymentSuccessRouteBiogaran,
    trackersRoute,
} from '../../routing/routes';
import { Dispatch, IRootState } from '../../store/store';
import { PaymentRedirectionUrls } from '../../subscription-process/payment-redirection-routes';
import { makeGetIsTrackerAnimalType } from '../../trackers/selectors/get-is-tracker-animal-type';
import { ISubscriptionDetails } from '../../user-subscriptions/interfaces';
import { makeGetSubscriptionDetailsFromTracker } from '../../user-subscriptions/selectors/user-subscriptions.selectors';

interface IActions {
    showOnboardingModal: () => any;
    showNotification: (n: INotification) => unknown;
}

interface IStateProps {
    subscriptionDetail: ISubscriptionDetails | null;
    language: string | undefined;
    isAnimalType: boolean | null;
}


/**
 * Check if string params corresponds to a response from API
 * after a success in subscription payment for biogaran
 */
const shouldDisplaybiogaranSubscriptionSuccess = (urlParams: string) => {
    if (urlParams === PaymentRedirectionUrls.BIOGARAN_SUBSCRIPTION_SUCCESS) {
        return true;
    }
    return false;
};

/**
 * Check if string params corresponds to a response from API
 * after an error in subscription payment for biogaran
 */
const shouldDisplaybiogaranSubscriptionError = (urlParams: string) => {
    if (urlParams === PaymentRedirectionUrls.BIOGARAN_SUBSCRIPTION_ERROR) {
        return true;
    }
    return false;
};

/**
 * Check if string params corresponds to a response from API
 * after a success in subscription payment
 */
const shouldDisplayPayementSuccess = (urlParams: string) => {
    if (
        urlParams === PaymentRedirectionUrls.SUBSCRIPTION_SUCCESS ||
        urlParams === PaymentRedirectionUrls.ONBOARDING_SUCCESS
    ) {
        return true;
    }
    return false;
};

const shouldDisplayPayementError = (urlParams: string) => {
    if (urlParams === PaymentRedirectionUrls.SUBSCRIPTION_ERROR) {
        return true;
    }
    return false;
};

/**
 * Check if string params corresponds to a response from API
 * after an error in payment during subscription process
 */
const shouldDisplaySubscriptionError = (urlParams: string) => {
    if (urlParams === PaymentRedirectionUrls.SUBSCRIPTION_ERROR) {
        return true;
    }
    return false;
};

const shouldDisplayChangeAccountSuccess = (urlParams: string) => {
    if (urlParams === PaymentRedirectionUrls.CHANGE_BANK_ACCOUNT_SUCCESS) {
        return true;
    }
    return false;
};

/**
 * Check if string params corresponds to a response from API
 * after an error in bank account modification
 */
const shouldDisplayChangeAccountError = (urlParams: string) => {
    if (urlParams === PaymentRedirectionUrls.CHANGE_BANK_ACCOUNT_ERROR) {
        return true;
    }
    return false;
};

/**
 * Check if string params corresponds to a response from API
 * after a success in credit card modification
 */
const shouldDisplayChangeCardSuccess = (urlParams: string) => {
    if (urlParams === PaymentRedirectionUrls.CHANGE_CARD_SUCCESS) {
        return true;
    }
    return false;
};

/**
 * Check if string params corresponds to a response from API
 * after an error in credit card modification
 */
const shouldDisplayChangeCardError = (urlParams: string) => {
    if (urlParams.includes(PaymentRedirectionUrls.CHANGE_CARD_ERROR)) {
        return true;
    }
    return false;
};

/**
 * Takes URL params from redirection, dispatches proper actions and cleanups routes
 * @param Component
 */
export const withPaymentRedirectionResolvingRaw = (
    Component: ComponentType,
) => ({
    showNotification,
    showOnboardingModal,
    subscriptionDetail,
    language,
    isAnimalType,
    ...props
}: IActions & IStateProps) => {
        const { t } = useTranslation(['changePaymentMean', 'subscriptionPayment']);
        const param = useLocation()
        const history = useNavigate()
        /**
         * Detect if user is redirected from gate with successful payment
         */
        const trackerId = param.pathname.split('/')[2];
        useEffect(() => {
            if (!trackerId) {
                return;
            }
            let shouldRedirectToDefaultRoute = false;

            const DisplaybiogaranSubscriptionSuccess = shouldDisplaybiogaranSubscriptionSuccess(
                param.search,
            );

            const DisplaybiogaranSubscriptionError = shouldDisplaybiogaranSubscriptionError(
                param.search,
            );

            const displaySuccess = shouldDisplayPayementSuccess(
                param.search,
            );
            const displayError = shouldDisplayPayementError(param.search);
            const displaySubscriptionError = shouldDisplaySubscriptionError(
                param.search,
            );

            const displayChangeAccountError = shouldDisplayChangeAccountError(
                param.search,
            );

            const displayChangeAccountSuccess = shouldDisplayChangeAccountSuccess(
                param.search,
            );

            const displayChangeCardSuccess = shouldDisplayChangeCardSuccess(
                param.search,
            );
            const displayChangeCardError = shouldDisplayChangeCardError(
                param.search,
            );

            if (DisplaybiogaranSubscriptionSuccess) {
                history(paymentSuccessRouteBiogaran);
            }
            if (DisplaybiogaranSubscriptionError) {
                history(
                    paymentErrorRouteBiogaran.replace(':id', trackerId),
                );
            }
            if (displaySuccess) {
                history(paymentSuccessRoute);
            }
            if (displayError) {
                history(paymentErrorRoute.replace(':id', trackerId));
            }
            if (displaySubscriptionError) {
                showNotification({
                    content: t('subscriptionPayment:ERRORS.UNKNOWN'),
                    type: 'error',
                });
                shouldRedirectToDefaultRoute = true;
            }
            if (displayChangeCardSuccess) {
                showNotification({
                    content: t('changePaymentMean:BANK_CARD_UPDATED'),
                    type: 'success',
                });
                history(accountRoute + '/payments');
            }
            if (displayChangeCardError) {
                showNotification({
                    // Only found trick to convert Trans element to string
                    content: ((
                        <Typography>
                            <Trans
                                ns="changePaymentMean"
                                i18nKey="BANK_CARD_ERRORED"
                            >
                                Error occurred
                                <Link
                                    href={config.SUPPORT_LINK}
                                    target="_blank"
                                    white
                                >
                                    client service
                                </Link>
                                .
                            </Trans>
                        </Typography>
                    ) as unknown) as string,
                    type: 'error',
                });
                history(accountRoute + '/payments');
            }
            if (displayChangeAccountError) {
                showNotification({
                    content: t('BANK_ACCOUNT_ERROR'),
                    type: 'error',
                });
                history(accountRoute + '/payments');

            }
            if (displayChangeAccountSuccess) {
                showNotification({
                    content: t('BANK_ACCOUNT_SUCCESS'),
                    type: 'success',
                });
                history(accountRoute + '/payments');
                return;
            }

            /* This allows to hide url parameters */
            if (shouldRedirectToDefaultRoute) {
                history(trackersRoute);
            }
        }, [
            param.search,
            param.pathname,
            history,
            showNotification,
            t,
        ]);

        return <Component {...props} />;
    };

const makeMapState = (
    state: IRootState,
    props: any,
): IStateProps => {
    const trackerId = props?.activeTrackerId
    const getSubscriptionDetails = makeGetSubscriptionDetailsFromTracker(
        trackerId,
        true,
    );

    const langague = state.user.userData?.language;
    const getIsAnimalType = makeGetIsTrackerAnimalType(trackerId);
    return {
        subscriptionDetail: getSubscriptionDetails(
            state,
        ) as ISubscriptionDetails,
        language: langague,
        isAnimalType: getIsAnimalType(state),
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    showOnboardingModal: dispatch.onboarding.showOnboardingModal as any,
    showNotification: dispatch.notifications.showNotification,
});

export const withPaymentRedirectionResolving = compose(
    connect(makeMapState, mapDispatch),
    withPaymentRedirectionResolvingRaw,
);
