import { createSelector } from 'reselect';

import { makeGetTrackerModel } from './get-tracker-model';

export const makeGetTrackerIsDuringLiveMode = (trackerId: number) => {
    const getTracker = makeGetTrackerModel(trackerId);

    return createSelector(
        getTracker,
        (tracker) => {
            if (!tracker) {
                return false;
            }
            return tracker.freq_mode === '10S';
        },
    );
};
