import * as i18next from 'i18next';
import { setLocale } from 'yup';

/**
 * This file sets DEFAULT translations for library YUP, which is responsible of
 * fields validation.
 * This doesn't work for custom tests yet.
 * @see https://github.com/jquense/yup/issues/527
 *
 * Note: Validation schemas have to be lazy loaded to work after translations are set.
 * Example of form config: validationSchema: () => getValidationSchema()
 */
export const setupValidationMessages = (t: i18next.TFunction) => {
    setLocale({
        mixed: {
            default: t('errors:DEFAULT')!,
            required: t('errors:FIELD_REQUIRED')!,
        },
        string: {
            email: t('errors:MAIL_FORMAT')!,
            max: t('errors:MAX_LENGTH')!,
        },
    });
};
