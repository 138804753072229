import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Dispatch, IRootState } from '../store/store';

interface IActions {
    detectLanguage(): void;
}

interface IState {
    shouldDetect: boolean;
}

/**
 * Wraps page and detects language from server
 */
export const withLanguageDetectingRaw = (Component: ComponentType<any>) => ({
    shouldDetect,
    detectLanguage,
    ...props
}: IActions & IState) => {
    useEffect(() => {
        if (shouldDetect) {
            detectLanguage();
        }
    }, [shouldDetect, detectLanguage]);

    return <Component {...props} />;
};

const mapState = (state: IRootState): IState => ({
    shouldDetect: !state.language.detected,
});

const mapDispatch = (dispatch: any): IActions => ({
    async detectLanguage() {
        return dispatch.language.detectLanguageFromServer();
    },
});

export const withLanguageDetecting = compose(
    connect(
        mapState,
        mapDispatch,
    ),
    withLanguageDetectingRaw,
);
