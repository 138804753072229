import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { MenuItem } from '../../../common/menu/menu-item.component';
import { IRootState } from '../../../store/store';
import { ITrackerOnSidebar } from '../../interfaces';
import { formatAccuracyInfo } from '../../mappers/format-accuracy-info';
import { makeGetTrackersOnSidebar } from '../../selectors/get-trackers-on-sidebar';
import { TrackerAttributesList } from '../tracker-attributes-list/tracker-attributes-list.component';
import { TrackerIconBadge } from '../tracker-icon-badge/tracker-icon-badge.component';
import styles from './mobile-selected-tracker-bar.module.scss';

interface IOuterProps {
    trackerId: number;
}

interface IStateProps {
    tracker: ITrackerOnSidebar | null;
}

interface IProps extends IOuterProps, IStateProps { }

const MobileSelectedTrackerBarRaw = ({ tracker }: IProps) => {
    const renderTrackerBar = () => {
        if (tracker) {
            const accuracyData = formatAccuracyInfo(tracker.accuracy || 0);
            return (
                <React.Fragment>
                    <MenuItem
                        icon={
                            <TrackerIconBadge
                                iconUrl={tracker.urlPicture}
                                isInDeepSleep={tracker.isInDeepSleep}
                                icon={tracker.icon}
                                type={tracker.type}
                                color={tracker.color}
                                online={tracker.online}
                            />
                        }
                        label={tracker.name}
                        subText={tracker.lastSeen}
                    />
                    <TrackerAttributesList
                        gpsSignal={tracker.signalStrength}
                        gpsOn={tracker.gps}
                        batteryLevel={tracker.batteryLevel}
                        accuracyData={accuracyData}
                        className={styles.attributes}
                    />
                </React.Fragment>
            );
        }
    };

    return <div>{renderTrackerBar()}</div>;
};

const mapState = (state: IRootState, props: IOuterProps): IStateProps => {
    const getTrackers = makeGetTrackersOnSidebar();

    const trackers = getTrackers(state);
    const tracker = trackers.find((t) => t.id === props.trackerId);

    if (tracker) {
        return {
            tracker,
        };
    }
    return {
        tracker: null,
    };
};

export const MobileSelectedTrackerBar = compose<IProps, IOuterProps>(
    connect(mapState),
)(MobileSelectedTrackerBarRaw);
