import cx from 'classnames';
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { compose } from 'recompose';

import { getGlobalT } from '../../../../i18n/setup-translations';
import { Input } from '../../../common/input/input.component';
import { MenuItem } from '../../../common/menu/menu-item.component';
import { Menu } from '../../../common/menu/menu.component';
import { Typography } from '../../../common/typography/typography.component';
import { ReactComponent as LoopIcon } from '../../../icons/Search.svg';
import { ActivatingIndicator } from '../../../onboarding/activating-indicator/activating-indicator.component';
import { trackersRoute } from '../../../routing/routes';
import { ITrackerOnSidebar } from '../../interfaces';
import { AllTrackersIconBadgeComponent } from '../all-trackers-icon-badge/all-trackers-icon-badge.component';
import { ConnectedTrackerContent } from '../tracker-content/tracker-content.component';
import { TrackerIconBadge } from '../tracker-icon-badge/tracker-icon-badge.component';
import styles from './trackers-list.module.scss';

export interface ITrackerListProps {
    totalTrackers: number;
    trackers: ITrackerOnSidebar[];
    activeTracker: number | null;
    trackerSearchQuery: string;
    updateQuery: (query: string) => Promise<any>;
    onTrackerSelected: (id: number | null) => any;
}

export interface IProps extends ITrackerListProps { }

export const TrackersList = ({
    totalTrackers,
    trackers,
    trackerSearchQuery,
    activeTracker,
    onTrackerSelected,
    updateQuery,
}: IProps) => {
    const t = getGlobalT();
    const onAllTrackersSelected = () => {
        onTrackerSelected(null);
    };
    const { id } = useParams()
    const match = useLocation()
    /* Click on an expended tracker panel should close this panel */
    const onNewTrackerSelected = (
        trackerId: number,
        currentTrackerId: number | null,
        totalTrackersOnAccount: number,
    ) => {
        if (totalTrackersOnAccount < 2) {
            // Do nothing if customer only have one tracker
            return;
        }
        if (!currentTrackerId || currentTrackerId !== trackerId) {
            return onTrackerSelected(trackerId);
        }
        return onTrackerSelected(null);
    };
    const maybeRenderResultText = (queryValue: string) => {
        if (queryValue) {
            return (
                <div className={styles.searchResult}>
                    <Typography className={styles.searchResult}>
                        {trackers.length}/{totalTrackers}{' '}
                        {t('header:SEARCHBAR_RESULT')}
                    </Typography>
                </div>
            );
        }
    };
    const updateSearch = (trackerNameFilter: string) => {
        updateQuery(trackerNameFilter);
    };

    const maybeRenderSearchBar = () => {
        if (totalTrackers > 9) {
            return (
                <div>
                    <div className={styles.searchBarContainer}>
                        <Input
                            className={styles.searchBar}
                            searchBarLabel={t('header:SEARCHBAR_TITLE')}
                            value={trackerSearchQuery}
                            onChange={(e) => updateSearch(e.target.value)}
                            rightIcon={<LoopIcon />}
                            isSearchBar
                        />
                    </div>
                    {maybeRenderResultText(trackerSearchQuery)}
                </div>
            );
        }
    };
    const maybeRenderIsActivatingStatus = (tracker: ITrackerOnSidebar) => {
        if (tracker.isActivating) {
            return (
                <ActivatingIndicator className={styles.activating} withHint />
            );
        }
    };
    /**
     * Tell MenuItem to not render any right icon
     * when customers only have one tracker.
     */
    const maybeRenderRightIcon = (
        totalTrackersOnAccount: number,
    ): ReactNode => {
        if (totalTrackersOnAccount < 2) {
            return false;
        }
    };

    // Redirect to tracker/:id if user only have one tracker
    if (trackers?.length === 1 && match.pathname === trackersRoute) {
        onTrackerSelected(trackers[0].id);
    }

    return (
        <Menu>
            <MenuItem
                onClick={onAllTrackersSelected}
                key={'All trackers key'}
                icon={<AllTrackersIconBadgeComponent />}
                label={t('header:ALL_TRACKERS')}
                subText={`${trackers?.length} ${t('header:TRACKERS')} ${t(
                    'header:AVAILABLE_PLURAL',
                )}`}
                className={cx(styles.item, styles['all-trackers-item'], {
                    [styles.inactive]: activeTracker,
                })}
            />
            {maybeRenderSearchBar()}
            {
                trackers?.map((tracker: ITrackerOnSidebar) => (
                    <MenuItem
                        className={cx(styles.item, {
                            [styles.inactive]:
                                activeTracker !== tracker.id &&
                                activeTracker !== null,
                        })}
                        onClick={() => {
                            onNewTrackerSelected(
                                tracker.id,
                                activeTracker,
                                totalTrackers,
                            )
                        }
                        }
                        key={tracker.id}
                        expanded={tracker.id === activeTracker}
                        icon={
                            <TrackerIconBadge
                                iconUrl={tracker.urlPicture}
                                isInDeepSleep={tracker.isInDeepSleep}
                                icon={tracker.icon}
                                type={tracker.type}
                                color={tracker.color}
                                online={tracker.online}
                            />
                        }
                        label={tracker.name}
                        subText={tracker.lastConnected}
                        overrideRightIcon={maybeRenderRightIcon(totalTrackers)}
                    >
                        <div
                            className={styles['tracker-content']}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {maybeRenderIsActivatingStatus(tracker)}
                            <div
                                className={cx(styles['content-components'], {
                                    [styles.activating]: tracker.isActivating,
                                })}
                            >
                                <ConnectedTrackerContent tracker={tracker} />
                            </div>
                        </div>
                    </MenuItem>
                ))
            }
        </Menu >
    );
};

export const TrackersListWithRouter = compose<IProps, ITrackerListProps>(
)(TrackersList);
