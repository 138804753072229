import { IWifiZone, IWifiZoneMarker } from '../interfaces';
import { IRawWifiZone } from '../interfaces-api';

export const mapWifiZoneApiToClient = (wifiZone: IRawWifiZone): IWifiZone => {
    return {
        id: wifiZone.id,
        created_at: wifiZone.created_at,
        updated_at: wifiZone.updated_at,
        name: wifiZone.name,
        mac_address: wifiZone.mac_address,
        tracker_id: wifiZone.tracker_id,
        latitude: wifiZone.latitude,
        longitude: wifiZone.longitude,
        enable_notifications: wifiZone.enable_notifications,
        radius: wifiZone.radius,
        is_active: wifiZone.is_active,
        displayed: wifiZone.displayed,
        appli_notification: wifiZone.appli_notification,
        mail_notification: wifiZone.mail_notification,
        sms_notification: wifiZone.sms_notification,
    };
};

export const mapWifiZoneApiToMarker = (
    wifiZone: IRawWifiZone,
): IWifiZoneMarker => {
    return {
        id: wifiZone.id,
        radius: wifiZone.radius,
        displayed: wifiZone.displayed,
        position: {
            lat: wifiZone.latitude,
            lng: wifiZone.longitude,
        },
    };
};
