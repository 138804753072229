import { ModelConfig } from '@rematch/core';

import { IRootState } from '../store/store';
import { ICancelReason, IOtherCancelReason } from './interfaces';

export interface ICancelSubscriptionStore {
    cancelReason: ICancelReason | IOtherCancelReason | null;
    cancelExplanation: string;
}

export const cancelSubscriptionStore: ModelConfig<ICancelSubscriptionStore> = {
    state: {
        cancelReason: null,
        cancelExplanation: '',
    },
    effects: (dispatch) => ({
        async updateCancelExplanation(
            cancelExplanation: string,
            models: IRootState,
        ) {
            return this.setCancelExplanation(cancelExplanation);
        },
        async updateCancelReason(
            cancelReason: ICancelReason | IOtherCancelReason | null,
            models: IRootState,
        ) {
            return this.setCancelReason(cancelReason);
        },
    }),
    reducers: {
        setCancelExplanation: (
            state: ICancelSubscriptionStore,
            newCancelExplanation: string,
        ): ICancelSubscriptionStore => ({
            ...state,
            cancelExplanation: newCancelExplanation,
        }),
        setCancelReason: (
            state: ICancelSubscriptionStore,
            newCancelReason: ICancelReason | IOtherCancelReason,
        ): ICancelSubscriptionStore => ({
            ...state,
            cancelReason: newCancelReason,
        }),
    },
};
