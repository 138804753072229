import React from 'react';
import { withProps } from 'recompose';

import { Button, IButtonProps } from '../../common/button/button.component';
import { ReactComponent as IconMinus } from '../../icons/minus.svg';
import { ReactComponent as IconPlus } from '../../icons/plus.svg';
import styles from './edit-geofence-form.module.scss';

export const RoundButtonMinus = withProps<IButtonProps, IButtonProps>({
    secondary: true,
    small: true,
    type: 'button',
    children: <IconMinus />,
    className: styles.roundButton,
})(Button);

export const RoundButtonPlus = withProps<IButtonProps, IButtonProps>({
    secondary: true,
    small: true,
    type: 'button',
    children: <IconPlus />,
    className: styles.roundButton,
})(Button);
