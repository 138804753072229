import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';

import { ReactComponent as Fire } from '../../../../images/activityTracking/fire.svg';
import { ReactComponent as GraphVectorIcon } from '../../../../images/activityTracking/graph-vector-icon.svg';
import { ReactComponent as IconGraphBar } from '../../../../images/activityTracking/icon-graph-bar.svg';
import { ReactComponent as Moon } from '../../../../images/activityTracking/moon.svg';
import { ReactComponent as Sun } from '../../../../images/activityTracking/sun.svg';
import { ReactComponent as TrendingDown } from '../../../../images/activityTracking/trendingDown.svg';
import { ReactComponent as TrendingUp } from '../../../../images/activityTracking/trendingUp.svg';
import { convertMinutes } from '../../../../utils/convert-minute-in-days-hours-minutes';
import { IRootState } from '../../../store/store';
import { ITracker } from '../../../trackers/interfaces-api';
import {
    IAggregateData,
    IEvolution,
    ISummaryData,
    Period,
} from '../../interface';
import { ChartComponent } from '../chart';
import { ConnectedDatePickerComponent } from '../date-picker';
import { DoughnutComponent } from '../doughnut-chart';
import { ProgressBar } from '../progress-bar';
import styles from './data-graph.module.scss';

interface IState {
    evolution: IEvolution;
    translatedPeriodSelected: string;
    aggregateData: IAggregateData | null;
    period: Period;
    summaryData: ISummaryData;
    loading: boolean;
}
interface IOuterProps {
    datePicker: boolean;
    tracker?: ITracker;
}
export const areEmpty = (summaryData: ISummaryData) => {
    return Object.values(summaryData).every((value) => value === 0);
};
export interface IMappedProps extends IOuterProps, IState {}

export const DataGraphComponent = ({
    aggregateData,
    evolution,
    period,
    tracker,
    loading,
    summaryData,
    translatedPeriodSelected,
}: IMappedProps) => {
    const [datePicker, setDatePicker] = useState<boolean>(false);
    const [graphTypeSelected, setGraphTypeSelected] = useState(false);
    const [total, setTotal] = useState([]);
    const { t } = useTranslation('activity');

    const getPercentage = (valueToConvert: number) => {
        const sum =
            summaryData.activity + summaryData.rest + summaryData.awakening;
        return (valueToConvert / sum) * 100;
    };
    useEffect(() => {
        let newTotal: number[] = [];
        if (aggregateData?.activity) {
            aggregateData.activity.map((el) => {
                newTotal.push(el.activity);
            });
        }
        if (newTotal.length) {
            //@ts-ignore
            setTotal(newTotal.reduce((sum, num) => sum + num));
        }
    }, [aggregateData]);

    const renderTrendingIcon = (evolutionValue: number) => {
        if (evolutionValue < 0) {
            return (
                <>
                    <TrendingUp />+
                </>
            );
        }
        if (evolutionValue === 0) {
            return;
        }
        return (
            <>
                <TrendingDown />-
            </>
        );
    };
    const renderEvolutionTextBlock = () => {
        return (
            <div className={styles.evolutionTextContainer}>
                <div className={styles.evolutionText}>
                    <div style={{ fontWeight: 'bold' }}>
                        {t('activity:SUMMARY.AWAKENING')}
                    </div>
                    : {renderTrendingIcon(evolution.activity)}
                    {convertMinutes(evolution.activity)}
                </div>
                <div className={styles.evolutionText}>
                    <div style={{ fontWeight: 'bold' }}>
                        {t('activity:SUMMARY.REST')}
                    </div>
                    : {renderTrendingIcon(evolution.rest)}
                    {convertMinutes(evolution.rest)}
                </div>
            </div>
        );
    };
    const addTranslatedEvolutionText: any = () => {
        const isActivityHigher = evolution.activity;
        let transaltedTemplate = '';
        if (isActivityHigher) {
            transaltedTemplate = 'FEEDBACKTEXT.FIRST';
        } else {
            transaltedTemplate = 'FEEDBACKTEXT.SECOND';
        }
        let currentPeriod = translatedPeriodSelected;
        let previousPeriod = '';
        if (period === Period.DAY) {
            currentPeriod = translatedPeriodSelected;
            previousPeriod = t('SUMMARY.PREVIOUS_DAY');
        }
        if (period === Period.WEEK) {
            currentPeriod = translatedPeriodSelected;
            previousPeriod = t('SUMMARY.PREVIOUS_WEEK');
        }
        if (period === Period.MONTH) {
            currentPeriod = 'en ' + translatedPeriodSelected;
            previousPeriod = t('SUMMARY.PREVIOUS_MONTH');
        }
        return t(transaltedTemplate, {
            name: tracker?.name,
            currentPeriod: currentPeriod.toLowerCase(),
            previousPeriod: previousPeriod.toLowerCase(),
        });
    };
    const mayRenderGraph = () => {
        if (!datePicker && !graphTypeSelected) {
            return (
                <>
                    {/* <div className={styles.headerContainer}>
                        {addTranslatedEvolutionText()}
                    </div> */}
                    {/* {renderEvolutionTextBlock()} */}
                    <div className={styles.chartContainer}>
                        <ChartComponent
                            loading={loading}
                            aggregateData={aggregateData}
                            period={period}
                        />
                    </div>
                </>
            );
        } else if (!datePicker && graphTypeSelected) {
            if (areEmpty(summaryData)) {
                return (
                    <div className={styles.doughnutContainer}>
                        {t('activity:SUMMARY.TEXT_OBJECTIVE_NONE', {
                            name: tracker?.name,
                        })}
                    </div>
                );
            }
            return (
                <>
                    {' '}
                    <div className={styles.headerContainer}>
                        {/* {addTranslatedEvolutionText()} */}
                    </div>
                    {/* {renderEvolutionTextBlock()} */}
                    <div className={styles.doughnutContainer}>
                        {/* gauge */}
                        <div className={styles.gaugeContainer}>
                            <div
                                className={styles.displayFlex}
                                style={{ height: '-webkit-fill-available' }}
                            >
                                <div className={styles.iconContainer}>
                                    <Fire />
                                </div>
                                <ProgressBar
                                    width="70%"
                                    color="#ED6E18"
                                    completionValueInPercent={getPercentage(
                                        summaryData.activity,
                                    )}
                                    title={t('activity:SUMMARY.ACTIVITY')}
                                    subtitle={convertMinutes(
                                        summaryData.activity,
                                    )}
                                />
                            </div>
                            <div
                                className={styles.displayFlex}
                                style={{ height: '-webkit-fill-available' }}
                            >
                                <div className={styles.iconContainer}>
                                    <Sun />
                                </div>
                                <ProgressBar
                                    width="70%"
                                    color="#9949FF"
                                    completionValueInPercent={getPercentage(
                                        summaryData.awakening,
                                    )}
                                    title={t('activity:SUMMARY.AWAKENING')}
                                    subtitle={convertMinutes(
                                        summaryData.awakening,
                                    )}
                                />
                            </div>
                            <div
                                className={styles.displayFlex}
                                style={{ height: '-webkit-fill-available' }}
                            >
                                <div className={styles.iconContainer}>
                                    <Moon />
                                </div>
                                <ProgressBar
                                    width="70%"
                                    color="#3532C6"
                                    completionValueInPercent={getPercentage(
                                        summaryData.rest,
                                    )}
                                    title={t('activity:SUMMARY.REST')}
                                    subtitle={convertMinutes(summaryData.rest)}
                                />
                            </div>
                        </div>
                        {/* gauge */}
                        <div
                            className={styles.doughnut}
                            style={{ height: '-webkit-fill-available' }}
                        >
                            <DoughnutComponent
                                activityPercentage={getPercentage(
                                    summaryData.activity,
                                )}
                                awakeningPercentage={getPercentage(
                                    summaryData.awakening,
                                )}
                                restPercentage={getPercentage(summaryData.rest)}
                            />
                        </div>
                    </div>
                </>
            );
        }
    };
    return (
        <div className={styles.dataBlockContainer}>
            <div className={styles.headerContainerNew}>
                <div className={styles.header}>
                    <div
                        className={styles.roundedIconContainer}
                        onClick={() => {
                            setGraphTypeSelected(!graphTypeSelected);
                            setDatePicker(false);
                        }}
                    >
                        {graphTypeSelected ? (
                            <IconGraphBar className={styles.icon} />
                        ) : (
                            <GraphVectorIcon className={styles.icon} />
                        )}
                    </div>
                    <div
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {translatedPeriodSelected}
                    </div>
                    <div> </div>
                </div>
                <div>
                    <Typography variant="h6" style={{ fontWeight: 450 }}>
                        {' '}
                        {t('activity:SUMMARY.TOTAL')} :{' '}
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: 450 }}>
                        <b style={{ fontSize: '14px' }}>{total}</b>{' '}
                        <b>{t('activity:SUMMARY.REGISTERED_ACTIVITY')}</b>
                    </Typography>
                </div>
            </div>
            {mayRenderGraph()}
        </div>
    );
};

const makeMapState = (state: IRootState, props: IOuterProps): IState => {
    const {
        evolution,
        period,
        summaryData,
        aggregateData,
        translatedPeriodSelected,
        loading,
    } = state.activityTracking;

    return {
        evolution,
        translatedPeriodSelected,
        aggregateData,
        loading,
        summaryData,
        period,
    };
};

export const ConnectedDataGraphComponent = compose<IMappedProps, IOuterProps>(
    connect(makeMapState),
    mapProps(
        (props: IMappedProps): IMappedProps => ({
            ...props,
        }),
    ),
)(DataGraphComponent);
