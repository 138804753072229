import cx from 'classnames';
import React, { HTMLAttributes } from 'react';

import styles from './track-accuracy.module.scss';

export interface ITrackerAccuracyProps extends HTMLAttributes<HTMLSpanElement> {
    accuracy: number;
    accuracyFormatted: string;
}

export const TrackerAccuracy = ({
    accuracy,
    accuracyFormatted,
    className,
    ...props
}: ITrackerAccuracyProps) => (
    <span className={cx(styles.accuracy, className)} {...props}>
        {accuracyFormatted}
    </span>
);
