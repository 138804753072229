import React from 'react';
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';

import { config as appConfig } from '../config';

import { AppBootstrap } from './app-bootstrap.component';

import styles from './app.module.scss';
import { router } from './routing/routes';
import { withStore } from './store/with-store';
import { pure } from 'recompose';
import { RouterProvider } from 'react-router-dom';




function App() {
    const { t } = useTranslation('misc');
    return (
        <AppBootstrap>
            <div className={styles.container}>
                <RouterProvider router={router} />
            </div>
            <p className={styles.noSupport}>{t('ROTATE_DEVICE')}</p>

        </AppBootstrap >
    );
};

/**
 * Google Analytics init.
 * 1. testMode is used to avoid error when launching npm test
 *
 * 2. Debug option can be set to true to enable prints in console.
 * Another way to debug GA is to download chrome extension :
 * https://chrome.google.com/webstore/detail/google-analytics-debugger/jnkmfdileelhofjcijamephohjechhna?hl=fr
 */
if (appConfig.GOOGLE_ANALYTICS_ID)
    ReactGA.initialize(appConfig.GOOGLE_ANALYTICS_ID, {
        testMode: process.env.NODE_ENV === 'test',
    });

const AppWithStore = compose(withStore)(pure(App));

export { AppWithStore as App };
