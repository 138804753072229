import { FormikProps, withFormik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { branch, compose, renderComponent } from 'recompose';

import { getGlobalT } from '../../../../i18n/setup-translations';
import { ReactComponent as AnimalCatIcon } from '../../../../images/animalcat.svg';
import { ReactComponent as AnimalDogIcon } from '../../../../images/animaldog.svg';
import { IWithFormUtils, withFormUtils } from '../../../../utils/form-helpers';
import { Button } from '../../../common/button/button.component';
import { IconBadge } from '../../../common/icon-badge/icon-badge.component';
import { Input } from '../../../common/input/input.component';
import { Typography } from '../../../common/typography/typography.component';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { ReactComponent as EditIcon } from '../../../icons/edit.svg';
import { ReactComponent as TickIcon } from '../../../icons/tick.svg';
import { INotification } from '../../../notifications/interfaces';
import { Dispatch, IRootState } from '../../../store/store';
import { TrackerType, URLPictureAttribute } from '../../../trackers/interfaces';
import { ITracker } from '../../../trackers/interfaces-api';
import { makeGetSelectedRegularTrackerName } from '../../../trackers/selectors/get-selected-tracker-name';
import { makeGetTrackerModel } from '../../../trackers/selectors/get-tracker-model';
import {
    resolveTrackerColor,
    resolveTrackerIcon,
    TrackerIcon,
} from '../../../trackers/tracker-icons';
import {
    deletePicture,
    fetchTracker,
} from '../../../trackers/trackers.service';
import { TrackerIconModal } from '../tracker-icon-modal/tracker-icon-modal.component';
import styles from './tracker-display-form.module.scss';
import { getTrackerDisplayValidationSchema } from './tracker-display-validation.schema';

interface IFormValues {
    trackerName: string;
    trackerIcon: string;
    trackerColor: string;
    isBeingModified: boolean;
}

interface IStateProps {
    icon: TrackerIcon;
    color: string;
    trackerId: number;
    tracker: ITracker;
    trackerName: string;
    trackerType: TrackerType;
}

interface IActions {
    refreshTrackerList: () => any;
    updateTracker: (trackerId: number) => Promise<any>;
    doUpdateSingleTracker: (trackerId: number, newType: string) => Promise<any>;
    updateTrackerNameAndIcon: (
        name: string,
        icon: string,
        color: string,
    ) => Promise<unknown>;
    showNotification: (notification: INotification) => Promise<unknown>;
    clearNotifications: () => void;
}

interface IOuterProps {
    trackerID: number;
}

interface IProps
    extends IStateProps,
    IOuterProps,
    FormikProps<IFormValues>,
    IActions,
    IWithFormUtils { }

const t = getGlobalT() as any;
export const TrackerDisplayForm = ({
    trackerName,
    handleSubmit,
    tracker,
    updateTracker,
    handleReset,
    handleChange,
    handleBlur,
    trackerId,
    values,
    setFieldValue,
    trackerType,
    getErrorMessage,
    showNotification,
    isFieldValid,
    doUpdateSingleTracker,
    refreshTrackerList,
}: IProps) => {
    const [iconModalOpen, setIconModalOpen] = useState(false);
    const showIconModal = () => setIconModalOpen(true);
    const closeIconModal = () => setIconModalOpen(false);
    const [modified, setModified] = useState<boolean>(values.isBeingModified);

    const onIconChanged = (newIcon: string, newColor: string) => {
        if (newIcon !== URLPictureAttribute && tracker.url_picture) {
            deletePicture(trackerId).then(() => {
                updateTracker(trackerId);
                return closeIconModal();
            });
        }
        setFieldValue('trackerIcon', newIcon);
        setFieldValue('trackerColor', newColor);

        closeIconModal();
        setTimeout(submitChange, 0);
    };
    const [currentTrackerType, setcurrentTrackerType] = useState<string>(
        trackerType,
    );

    const preventSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };
    const getReverseAnimalType = () => {
        if (currentTrackerType === TrackerType.ANIMALCAT) {
            return TrackerType.ANIMALDOG;
        }
        return TrackerType.ANIMALCAT;
    };
    const newType = getReverseAnimalType();
    const onTrackerTypeChange = () => {
        doUpdateSingleTracker(trackerId, newType).then(() => {
            showNotification({
                type: 'success',
                content: t('subscriptionAlert:MESSAGES.UPDATE_SUCCESS'),
            }).then(() => {
                refreshTrackerList();
            });
        });
        return setcurrentTrackerType(newType);
    };
    const onModification = () => {
        values.isBeingModified = true;
        setModified(values.isBeingModified);
    };

    const submitChange = () => {
        if (values.trackerIcon === URLPictureAttribute) {
            return;
        }
        handleSubmit();
        if (getErrorMessage('trackerName') === undefined) {
            values.isBeingModified = false;
            setModified(values.isBeingModified);
        }
    };

    const cancelModification = () => {
        values.isBeingModified = false;
        setModified(values.isBeingModified);
    };
    const maybeRenderLongTrackerName = (name: string) => {
        if (name.length > 50) {
            const split = 50;
            return (
                <Typography className={styles['registered-info']} noMargin>
                    {name.substr(0, split)}
                </Typography>
            );
        }
        return (
            <Typography className={styles['registered-info']} noMargin>
                {name}
            </Typography>
        );
    };
    const maybeRenderModified = () => {
        if (modified === true) {
            return (
                <div className={styles['add-container']}>
                    <Input
                        placeholder={t(
                            'trackerSettings:DISPLAY.TRACKER_NAME.PLACEHOLDER',
                        )}
                        isModified
                        small
                        value={values.trackerName}
                        name={`trackerName`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={isFieldValid('trackerName')}
                    />
                    <div className={styles['add-buttons-container']}>
                        <Button
                            onClick={() => submitChange()}
                            className={styles['submit-button']}
                            title="Submit"
                        >
                            {t('commonActions:VALIDATE')}
                            <TickIcon className={styles['form-svg']} />
                        </Button>
                        <Button
                            onClick={() => cancelModification()}
                            className={styles['cancel-button']}
                            title="cancel"
                        >
                            <CrossIcon className={styles['form-svg']} />
                        </Button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles['form-option-container']}>
                    {maybeRenderLongTrackerName(values.trackerName)}
                    <div className={styles['form-buttons-container']}>
                        <Button
                            onClick={onModification}
                            className={styles['edit-button']}
                            title="Edit"
                        >
                            <EditIcon className={styles['form-svg']} />
                        </Button>
                    </div>
                </div>
            );
        }
    };
    const maybeRenderTrackerTypeChange = () => {
        if (
            currentTrackerType === TrackerType.ANIMALCAT ||
            currentTrackerType === TrackerType.ANIMALDOG
        ) {
            return (
                <div className={styles.typeModifier}>
                    {currentTrackerType === TrackerType.ANIMALCAT ? (
                        <Typography noMargin gray>
                            {t('TypeSwitch:CAT_TYPE')}
                        </Typography>
                    ) : (
                        <Typography noMargin gray>
                            {t('TypeSwitch:DOG_TYPE')}
                        </Typography>
                    )}
                    <div className={styles['icon-section']}>
                        <div>
                            {currentTrackerType === TrackerType.ANIMALCAT ? (
                                <AnimalCatIcon className={styles.icon} />
                            ) : (
                                <AnimalDogIcon className={styles.icon} />
                            )}
                        </div>
                        <Typography
                            className={styles.edit}
                            onClick={onTrackerTypeChange}
                            orange
                        >
                            <EditIcon className={styles.pencil} />
                            {t('trackerSettings:DISPLAY.TRACKER_TYPE.CHANGE')}
                        </Typography>
                    </div>
                </div>
            );
        }
    };
    const maybeRenderIconModal = () => {
        if (iconModalOpen) {
            return (
                <TrackerIconModal
                    showNotification={showNotification}
                    tracker={tracker}
                    updateTracker={updateTracker}
                    onSave={onIconChanged}
                    onClosed={closeIconModal}
                    initialColor={values.trackerColor}
                    initialIcon={
                        tracker.url_picture
                            ? URLPictureAttribute
                            : values.trackerIcon
                    }
                />
            );
        }
    };

    return (
        <form onSubmit={preventSubmit} onReset={handleReset}>
            <Typography className={styles.displaySuccessMessage}>
                {t('trackerSettings:DISPLAY.MESSAGES.UPDATE_SUCCESS')}
            </Typography>
            <Typography noMargin gray>
                {t('trackerSettings:DISPLAY.TRACKER_NAME.LABEL')}
            </Typography>
            {maybeRenderModified()}
            <div className={styles.editIconTypeContainer}>
                <div>
                    <Typography noMargin gray>
                        {t('trackerSettings:DISPLAY.TRACKER_ICON.LABEL')}
                    </Typography>
                    <div className={styles['icon-section']}>
                        <div>
                            <IconBadge
                                color={resolveTrackerColor(
                                    values.trackerColor,
                                    trackerType,
                                )}
                                icon={
                                    <img
                                        className={
                                            tracker.url_picture
                                                ? styles.iconUrl
                                                : ''
                                        }
                                        alt=""
                                        src={resolveTrackerIcon(
                                            tracker.url_picture,
                                            values.trackerIcon as TrackerIcon,
                                            trackerType,
                                        )}
                                    />
                                }
                            />
                        </div>
                        <Typography
                            className={styles.edit}
                            onClick={showIconModal}
                            orange
                        >
                            <EditIcon className={styles.pencil} />
                            {t(
                                'trackerSettings:DISPLAY.TRACKER_ICON.EDIT_TEXT',
                            )}
                        </Typography>
                    </div>
                </div>
                {maybeRenderTrackerTypeChange()}
            </div>
            {maybeRenderIconModal()}
        </form>
    );
};

const mapStateToProps = (
    state: IRootState,
    props: IOuterProps,
): IStateProps => {
    const getActiveTrackerName = makeGetSelectedRegularTrackerName();
    const getTrackerModel = makeGetTrackerModel(props.trackerID);

    const model = getTrackerModel(state) as ITracker;

    return {
        trackerId: props.trackerID,
        tracker: model,
        trackerName: getActiveTrackerName(state) as string,
        icon: model.icon as TrackerIcon,
        color: `#${model.color}`,
        trackerType: model.type as TrackerType,
    };
};

const mapDispatch = (dispatch: Dispatch, props: IOuterProps): IActions => ({
    doUpdateSingleTracker: async (trackerId: number, newType: string) =>
        dispatch.trackersSettings.putSingleTracker({ trackerId, newType }),
    refreshTrackerList: () =>
        dispatch.userTrackers.fetchTrackers({ deepFetch: true }),
    updateTrackerNameAndIcon: async (name, icon, color) =>
        dispatch.trackersSettings.updateTrackerNameAndIcon({
            name,
            color,
            icon,
            trackerId: props.trackerID,
        }),
    updateTracker: async (trackerId: number) =>
        dispatch.userTrackers.fetchSingleTracker({ trackerId }),

    showNotification: async (n: INotification) =>
        dispatch.notifications.showNotification(n),
    clearNotifications: async () => dispatch.notifications.clearNotifications,
});

const shouldRender = (props: IStateProps) =>
    Boolean(typeof props.trackerName === 'string');

export const ConnectedTrackerDisplayForm = compose<IProps, IOuterProps>(
    connect(mapStateToProps, mapDispatch),
    /**
     * Block rendering till tracker name is properly resolved. Normally it will
     * not happen, but if page was refreshed ON setting page, there will be null for
     * a moment
     */
    branch(
        (props: IStateProps) => !shouldRender(props),
        renderComponent(() => null),
    ),
    withFormik<IOuterProps & IStateProps & IActions, IFormValues>({
        enableReinitialize: true,
        mapPropsToValues(props) {
            return {
                isBeingModified: false,
                trackerColor: props.color,
                trackerIcon: props.icon,
                trackerName: props.trackerName || '',
            };
        },
        handleSubmit(values, formikBag) {
            formikBag.props.clearNotifications();
            formikBag.props
                .updateTrackerNameAndIcon(
                    values.trackerName,
                    values.trackerIcon,
                    values.trackerColor,
                )
                .then(() => {
                    if (
                        document.getElementsByClassName(styles.displayActive)
                            .length === 0
                    ) {
                        document
                            .getElementsByClassName(
                                styles.displaySuccessMessage,
                            )[0]
                            .classList.add(styles.displayActive);
                        setTimeout(() => {
                            document
                                .getElementsByClassName(
                                    styles.displaySuccessMessage,
                                )[0]
                                .classList.remove(styles.displayActive);
                        }, 2000);
                    }
                })
                .catch((err) => {
                    formikBag.props.showNotification({
                        type: 'error',
                        content: t(
                            'trackerSettings:DISPLAY.MESSAGES.UPDATE_ERROR_UNKNOWN',
                        ),
                    });
                });
        },
        validationSchema: () => getTrackerDisplayValidationSchema(),
    }),
    withFormUtils,
)(TrackerDisplayForm);
