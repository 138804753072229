import { FormikProps, withFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getGlobalT } from '../../../i18n/setup-translations';
import { IWithFormUtils, withFormUtils } from '../../../utils/form-helpers';
import { Button } from '../../common/button/button.component';
import { Input } from '../../common/input/input.component';
import { Typography } from '../../common/typography/typography.component';
import { INotification } from '../../notifications/interfaces';
import { Dispatch, IRootState } from '../../store/store';
import {
    ITracker,
    ITrackerButton,
    TrackerFeature,
} from '../../trackers/interfaces-api';
import { makeGetTrackerModel } from '../../trackers/selectors/get-tracker-model';
import styles from './buttons-settings.module.scss';

interface IOuterProps {
    trackerID: number;
}

/**
 * Only buttons 2 and 3 are used to send pre-defined messages.
 * Button1 is only used for on/off feature but still need to be in API call
 */
export interface IFormValues {
    button1Message: string;
    button2Message: string;
    button3Message: string;
}

interface IStateProps {
    existingButtons: ITrackerButton[];
    trackerFeatures: TrackerFeature[];
}

interface IActions {
    updateSettings(values: IFormValues): Promise<unknown>;
    showNotification(notification: INotification): Promise<unknown>;
    clearNotifications(): unknown;
}

interface IProps
    extends IOuterProps,
    FormikProps<IFormValues>,
    IActions,
    IStateProps,
    IWithFormUtils { }

const ButtonsSettings = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isFieldValid,
    getErrorMessage,
    ...props
}: IProps) => {
    const { t } = useTranslation(['trackerSettings']) as any;

    const renderButton1 = () => {
        if (props.trackerFeatures.indexOf('has_buttons') >= 0) {
            return (
                <Input
                    placeholder={t('BUTTONS.PLACEHOLDER_BUTTON', {
                        button_number: 1,
                    })}
                    small
                    label={t('BUTTONS.LABELS.BUTTON') + ' 1 :'}
                    value={values.button1Message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="button1Message"
                    isValid={isFieldValid('button1Message')}
                    errorMessage={getErrorMessage('button1Message')}
                />
            );
        }
    };

    const renderButton2And3 = () => {
        if (
            props.trackerFeatures.indexOf('has_buttons') >= 0 ||
            props.trackerFeatures.indexOf('limited_buttons') >= 0
        ) {
            return (
                <div>
                    {/* Button 2 */}
                    <Input
                        placeholder={t('BUTTONS.PLACEHOLDER_BUTTON', {
                            button_number: 2,
                        })}
                        small
                        label={t('BUTTONS.LABELS.BUTTON') + ' 2 :'}
                        value={values.button2Message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="button2Message"
                        isValid={isFieldValid('button2Message')}
                        errorMessage={getErrorMessage('button2Message')}
                    />

                    {/* Button 3 */}
                    <Input
                        placeholder={t('BUTTONS.PLACEHOLDER_BUTTON', {
                            button_number: 3,
                        })}
                        small
                        label={t('BUTTONS.LABELS.BUTTON') + ' 3 :'}
                        value={values.button3Message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="button3Message"
                        isValid={isFieldValid('button3Message')}
                        errorMessage={getErrorMessage('button3Message')}
                    />
                </div>
            );
        }
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <Typography withMarginBottom size12>
                {t('BUTTONS.TEXT')}
            </Typography>

            {/* Button 1 */}
            {renderButton1()}

            {/* Button 2 & 3 */}
            {renderButton2And3()}

            <Button type="submit" small className={styles.save}>
                {t('commonActions:SAVE')}
            </Button>
        </form>
    );
};

const mapStateToProps = (
    state: IRootState,
    props: IOuterProps,
): IStateProps => {
    const getTrackerModel = makeGetTrackerModel(props.trackerID);

    return {
        existingButtons: (getTrackerModel(state) as ITracker).buttons,
        trackerFeatures: (getTrackerModel(state) as ITracker).features,
    };
};

const mapDispatch = (dispatch: Dispatch, props: IOuterProps): IActions => ({
    async updateSettings(values: IFormValues) {
        const payload = {
            trackerId: props.trackerID,
            button1: values.button1Message,
            button2: values.button2Message,
            button3: values.button3Message,
        };

        return dispatch.trackersSettings.updateTrackerButtons(payload);
    },
    showNotification: async (n: INotification) =>
        dispatch.notifications.showNotification(n),
    clearNotifications: dispatch.notifications.clearNotifications,
});

export const ConnectedButtonsSettings = compose<IProps, IOuterProps>(
    connect(mapStateToProps, mapDispatch),
    withFormik<IStateProps & IOuterProps & IActions, IFormValues>({
        /**
         * Function used to set input value to message values retrieved from API
         */
        mapPropsToValues(props) {
            return {
                button1Message: props.existingButtons[0].message,
                button2Message: props.existingButtons[1].message,
                button3Message: props.existingButtons[2].message,
            };
        },
        handleSubmit(values, formikBag) {
            const t = getGlobalT();

            formikBag.props.clearNotifications();

            formikBag.props
                .updateSettings(values)
                .then(() => {
                    formikBag.props.showNotification({
                        type: 'success',
                        content: t(
                            'trackerSettings:DISPLAY.MESSAGES.UPDATE_SUCCESS',
                        ),
                    });
                })
                .catch((err) => {
                    formikBag.props.showNotification({
                        type: 'error',
                        content: t(
                            'trackerSettings:DISPLAY.MESSAGES.UPDATE_ERROR_UNKNOWN',
                        ),
                    });
                });
        },
        enableReinitialize: true,
    }),
    withFormUtils,
)(ButtonsSettings);
