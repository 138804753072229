import { ModelConfig } from '@rematch/core';

import { assignNewDevice } from './services/tracker-replacement.service';

export const trackerReplacementStore: ModelConfig<{}> = {
    state: {},
    effects: {
        async setNewTrackerDevice({
            trackerId,
            imei,
        }: {
            trackerId: number;
            imei: number | string;
        }) {
            return assignNewDevice(trackerId, imei).then(
                (response) => response,
            );
        },
    },
};
