import cx from 'classnames';
import React, { FormEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { NextButton } from '../../../common/button/next-button.component';
import { CheckSection } from '../../../common/check-section/check-section.component';
import { Headline } from '../../../common/headline/headline.component';
import { ConnectedFluidTypeSwitch } from '../../../fluid-type-switch/component/fluid-type-switch.component';
import { ReactComponent as XmasGift } from '../../../icons/xmas-gift.svg';
import { ReactComponent as XmasSanta } from '../../../icons/xmas-santa.svg';
import { ReactComponent as XmasTree } from '../../../icons/xmas-tree.svg';
import { Grid } from '../../../layout/grid/grid.component';
import { PageContainer } from '../../../layout/page-container/page-container.component';
import { Dispatch, IRootState } from '../../../store/store';
import {
    ISubscriptionOffer,
    SubscriptionVariantCode,
} from '../../../subscription-offers/interfaces';
import { makeGetSubscriptionsOffersVariants } from '../../../subscription-offers/selectors/subscription-offers-selectors';
import { TrackerType } from '../../../trackers/interfaces';
import { ITracker } from '../../../trackers/interfaces-api';
import { SubscriptionOptionType } from '../../../user-subscriptions/interfaces-api';
import { SubscriptionPeriodOption } from '../../components/subscription-period-option/subscription-period-option.component';
import { ConnectedSummaryTable } from '../../components/summary-table/summary-table.component';
import {
    makeGetRegisteredTracker,
    makeGetSubscriptionProcessChoices,
} from '../../selectors/subscription-process-selectors';
import { getDefaultSubscriptionCode } from '../../subscription-process.store';
import styles from './choose-variant-step.module.scss';

const NOEL_OFFER_RETAILER_ID = 1216; // TODO Remove at the end of the offer

interface IActions {
    setSelectedSubscriptionVariant: (
        code: SubscriptionVariantCode,
        offerId: number,
    ) => any;
    setGiftCardSelected: (value: boolean) => any;
}

interface IState {
    offers: ISubscriptionOffer[];
    registeredTracker: ITracker | null;
    selectedSubscriptionVariant: SubscriptionVariantCode;
    language: string;
    customerHasGiftCard: boolean;
}

interface IOuterProps {
    onSubmit: (subCode: SubscriptionVariantCode) => any;
    onCheckboxChanged: (code: SubscriptionOptionType) => any;
    renderConfirmationModal: () => any;
}

type Props = IActions & IState & IOuterProps;

export const ChooseVariantStep = ({
    onCheckboxChanged,
    language,
    offers,
    registeredTracker,
    customerHasGiftCard,
    setGiftCardSelected,
    onSubmit,
    selectedSubscriptionVariant,
    setSelectedSubscriptionVariant,
    renderConfirmationModal,
}: Props) => {
    const { t } = useTranslation('subscriptionSelection');
    const subT = useTranslation('subscriptions').t;
    const subFeature = useTranslation('subscription').t;
    const subPaymentT = useTranslation('subscriptionPayment').t;
    const features = [];
    features.push(subFeature('FEATURES.APP_ACCESS'));
    features.push(subFeature('FEATURES.REFOUND'));
    features.push(subFeature('FEATURES.SAV'));
    features.push(subFeature('FEATURES.WARRANTY'));
    const [fluidtype, setFluidType] = useState(false);

    const cguLink: { [key: string]: string } = {
        FR: 'https://www.weenect.com/fr/cgv/',
        EN: 'https://www.weenect.com/en/terms-conditions/',
        DE: 'https://www.weenect.com/de/agb/',
        ES: 'https://www.weenect.com/es/cgv/',
        IT: 'https://www.weenect.com/it/cgv/',
        NL: 'https://www.weenect.com/nl/algemene-voorwaarden/',
    };
    registeredTracker;
    const isFluidType = () => {
        if (
            registeredTracker?.type === TrackerType.ANIMAL ||
            registeredTracker?.type === TrackerType.FAMILY
        ) {
            return true;
        }
        return false;
    };
    useEffect(() => {
        if (registeredTracker) {
            setFluidType(isFluidType());
        }
    }, [registeredTracker]);
    const onFormSubmitted = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (customerHasGiftCard) {
            /* Here we simply select the default subscription offer */
            /* Once redirected to the gift-card component, the selected variant will be provided by
               the validation of the gift card */
            const defaultOffer = offers.find(
                (offer) => offer.code === getDefaultSubscriptionCode(),
            );

            if (defaultOffer) {
                setSelectedSubscriptionVariant(
                    defaultOffer?.code,
                    defaultOffer.id,
                );
            }
        }
        onSubmit(selectedSubscriptionVariant);
    };

    const MOST_POPULAR_OFFER_CODE = '12_month_1_tracker';
    const renderOffer = (offer: ISubscriptionOffer) => {
        let bestOffer = null;
        let promoText = null;
        let commitmentText = null;
        const textPopularOffer = null;
        let textInfo: Array<string> = [];
        let textTitle = null;
        if (offer.code === MOST_POPULAR_OFFER_CODE) {
            textInfo = [subT('OFFERS_TEXT.ONE_YEAR.INFO')];
            textTitle = subT('OFFERS_TEXT.ONE_YEAR.TITLE');
        }
        if (offer.code === '36_month_1_tracker') {
            textInfo = [
                subT('OFFERS_TEXT.ONE_YEAR.INFO'),

                subT('OFFERS_TEXT.THREE_YEAR.INFO2'),
            ];
            textTitle = subT('OFFERS_TEXT.THREE_YEAR.TITLE');
        }

        if (offer.code === '1_month_1_tracker') {
            textInfo = [subT('OFFERS_TEXT.ONE_MONTH.INFO')];
            textTitle = subT('OFFERS_TEXT.ONE_MONTH.TITLE');
        }

        if (offer.isBestChoice) {
            bestOffer = t('BEST_OFFER');
            promoText = offer.saving;
        }

        if (offer.hasCommitment) {
            commitmentText = offer.hasCommitmentText;
        }

        return (
            <SubscriptionPeriodOption
                className={
                    textPopularOffer
                        ? styles['single-offer-with-top-left-border']
                        : styles['single-offer']
                }
                key={offer.id}
                textPopularOffer={textPopularOffer}
                //@ts-ignore
                title={textTitle}
                textInfo={textInfo}
                period={offer.formattedPeriod}
                priceCost={offer.formattedPrice}
                amountMonth={offer.amountMonth}
                onChange={() => {
                    setSelectedSubscriptionVariant(offer.code, offer.id) &&
                        setGiftCardSelected(false);
                }}
                checked={
                    selectedSubscriptionVariant === offer.code &&
                    !customerHasGiftCard
                }
            />
        );
    };
    const maybeRenderSpecialOffer = () => {
        if (
            registeredTracker &&
            registeredTracker.retailer_id === NOEL_OFFER_RETAILER_ID
        ) {
            return (
                <div className={styles['noel-offer']}>
                    <XmasTree />
                    <XmasSanta />
                    <div className={styles['noel-offer-text']}>
                        <p className={styles['noel-offer-title']}>
                            {t('XMAS_OFFER.TITLE')}
                        </p>
                        <p className={styles['noel-offer-body']}>
                            {t('XMAS_OFFER.BODY')}
                        </p>
                    </div>
                    <XmasGift />
                    <XmasTree className={styles['noel-offer-icon-revert']} />
                </div>
            );
        }
    };
    if (fluidtype) {
        return (
            <ConnectedFluidTypeSwitch
                trackerToUpdate={registeredTracker?.id}
                currentTrackerType={registeredTracker?.type!}
                typeSwitchOutcome={() => setFluidType(false)}
            />
        );
    }
    return (
        <React.Fragment>
            {maybeRenderSpecialOffer()}

            <PageContainer className={styles.container}>
                <form onSubmit={onFormSubmitted}>
                    <Headline className={styles.headline} center>
                        {t('HEADLINE')}
                    </Headline>
                    <Headline
                        level={4}
                        className={styles['main-subtitle']}
                        center
                    >
                        <span>{t('REFUNDED_TEXT')}</span>
                        <br />
                        <span style={{ color: 'black' }}>
                            {t('REFUNDED_INFO')}
                        </span>
                    </Headline>
                    <div>
                        <Grid className={styles.grid}>
                            <div>
                                {/* We display offers from longer one to shorter one */}
                                {offers
                                    .sort((a, b) => b.rawPrice - a.rawPrice)
                                    .sort((a, b) => {
                                        if (
                                            a.code === MOST_POPULAR_OFFER_CODE
                                        ) {
                                            return -1;
                                        }
                                        return 0;
                                    })
                                    .map(renderOffer)}

                                <CheckSection
                                    onChange={() => setGiftCardSelected(true)}
                                    checked={customerHasGiftCard}
                                >
                                    <div>
                                        <div className={styles.periodHeadline}>
                                            {subPaymentT(
                                                'GIFT_CARD.I_HAVE_ONE',
                                            )}
                                        </div>
                                    </div>
                                </CheckSection>
                            </div>
                            <div>
                                <div className={styles.summary}>
                                    <Headline level={3}>
                                        {subFeature('SUMMARY')}
                                    </Headline>
                                    <ConnectedSummaryTable
                                        onDeleteOption={onCheckboxChanged}
                                    />
                                    <Grid
                                        className={cx([
                                            styles.button,
                                            styles.submitButtonMobile,
                                        ])}
                                    >
                                        <div className={styles.width50}>
                                            <NextButton
                                                block
                                                className={styles.mobileButton}
                                                type="submit"
                                            >
                                                {t('SUBMIT_BUTTON')}
                                            </NextButton>
                                        </div>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            className={cx([
                                styles.button,
                                styles.submitButtonDesktop,
                            ])}
                        >
                            <div className={styles.width50}>
                                <NextButton block type="submit">
                                    {t('SUBMIT_BUTTON')}
                                </NextButton>
                            </div>
                        </Grid>
                    </div>
                </form>
                {renderConfirmationModal()}
            </PageContainer>
        </React.Fragment>
    );
};

const makeMapState = () => {
    const getSubscriptionOffersVariants = makeGetSubscriptionsOffersVariants();
    const getUserChoices = makeGetSubscriptionProcessChoices();
    const getRegisteredTracker = makeGetRegisteredTracker();

    return (state: IRootState, props: any): IState => ({
        offers: getSubscriptionOffersVariants(state),
        selectedSubscriptionVariant: getUserChoices(state).subscriptionCode,
        registeredTracker: getRegisteredTracker(state),
        language: state.language.selectedLanguage,
        customerHasGiftCard: state.subscriptionProcess.customerHasGiftCard,
    });
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    setSelectedSubscriptionVariant: (code, id) =>
        dispatch.subscriptionProcess.setSelectedSubscriptionVariant({
            subscriptionOfferCode: code,
            subscriptionOfferId: id,
        }),
    setGiftCardSelected: (value: boolean) =>
        dispatch.subscriptionProcess.setCustomerHasGiftCard(value),
});

export const ConnectedChooseVariantPage = compose<Props, IOuterProps>(
    connect(makeMapState, mapDispatch),
)(ChooseVariantStep);
