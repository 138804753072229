import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { TrackerEventType } from '../../../trackers/interfaces-api';

import styles from './activity-icon.module.scss';
import { getEventIcon } from './icon-mapping';

interface IProps extends HTMLAttributes<HTMLDivElement> {
    icon: TrackerEventType;
}

export const ActivityIcon = ({ icon, className }: IProps) => {
    const Icon = getEventIcon(icon);

    return (
        <div className={cx(styles.wrapper, className)}>
            <Icon className={styles.icon} />
        </div>
    );
};
