import cx from 'classnames';
import React, { HTMLAttributes } from 'react';

import { IconBadge } from '../../../common/icon-badge/icon-badge.component';
import { ImageCropper } from '../../../common/image-cropper/image-cropper';
import { INotification } from '../../../notifications/interfaces';
import { URLPictureAttribute } from '../../../trackers/interfaces';
import styles from './icon-select.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {
    icons: Array<{ id: string; src: string }>;
    colors: string[];
    activeIconId: string;
    urlPicture: string | null;
    trackerId: number;
    activeColor: string;
    showNotification: (notification: INotification) => Promise<unknown>;

    onIconSelected(id: string, color: string): unknown;
}

export const IconSelect = ({
    className,
    icons,
    showNotification,
    trackerId,
    urlPicture,
    colors,
    activeColor,
    onIconSelected,
    activeIconId,
}: IProps) => {
    return (
        <div className={cx(styles.list, className)}>
            <ImageCropper
                showNotification={showNotification}
                urlPicture={urlPicture}
                onIconSelected={() =>
                    onIconSelected(URLPictureAttribute, colors[0])
                }
                activeIconId={
                    activeIconId === URLPictureAttribute
                        ? URLPictureAttribute
                        : ''
                }
                trackerId={trackerId}
            />
            {icons.map((icon) => (
                <div key={icon.id} className={styles.row}>
                    {colors.map((color) => (
                        <IconBadge
                            className={cx(styles.item, {
                                [styles.active]:
                                    color === activeColor &&
                                    icon.id === activeIconId,
                            })}
                            key={color + icon.id}
                            onClick={() => onIconSelected(icon.id, color)}
                            icon={<img src={icon.src} alt="" />}
                            color={color}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};
