import englishCountries from './countries/en.json';

export interface ICountry {
    name: string;
    code: string;
}

/**
 * TODO: Load countries list for other languages
 */
export const getAvailableCountries = (): ICountry[] =>
    englishCountries.map(([code, name]) => ({
        code,
        name,
    }));
