import * as yup from 'yup';
import { getGlobalT } from '../../../i18n/setup-translations';

import { isIMEI } from '../../../utils/imei-validator';

export const getReplaceTrackerValidationSchema = () => {
    const t = getGlobalT();

    return yup.object({
        imei: yup
            .string()
            .required()
            .test({
                name: 'imei',
                test: isIMEI,
                // @ts-ignore
                message: t('errors:IMEI'),
            }),
    });
};
