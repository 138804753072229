import cx from 'classnames';
import React, { HTMLAttributes, MouseEvent } from 'react';
import { Link } from '../../../common/link/link.component';

import { getGlobalT } from '../../../../i18n/setup-translations';
import { Typography } from '../../../common/typography/typography.component';
import styles from './single-payment.module.scss';

interface ISinglePaymentProps extends HTMLAttributes<HTMLDivElement> {
    formattedDate: string;
    name: string;
    status: string;
    hasInvoice?: boolean;
    onInvoiceRequested(): unknown;
}

export const SinglePayment = ({
    formattedDate,
    name,
    status,
    hasInvoice,
    className,
    onInvoiceRequested,
}: ISinglePaymentProps) => {
    const t = getGlobalT();
    const onInvoiceClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        onInvoiceRequested();
    };

    const renderStatusOrInvoice = () => {
        if (hasInvoice) {
            return (
                <Link onClick={onInvoiceClick} href="#">
                    PDF
                </Link>
            );
        }
        return (
            <span className={styles.status}>
                {t('payments:STATUS.' + status.toUpperCase())}
            </span>
        );
    };

    return (
        <div className={cx(styles.wrapper, className)}>
            <Typography className={styles.date} size12>
                {formattedDate}
            </Typography>
            <Typography gray size12 className={styles.name}>
                {name}
            </Typography>
            {renderStatusOrInvoice()}
        </div>
    );
};
