import { errorBoundaryWrapper } from 'app/error-boundary/error-boundary';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { ConnectedLoginPage } from '../login/login-page/login-page.component';

import { IRootState } from '../store/store';
import { loginRoute } from './routes';

export interface IProtectedRouteProps {
    isAllowed: boolean;
    routeToRedirect?: string;
    children: ReactElement
}

export const PureProtectedRoute = ({
    isAllowed,
    routeToRedirect = loginRoute,
    children
}: IProtectedRouteProps) => {
    if (isAllowed) {
        return errorBoundaryWrapper(children);
    }
    else {
        return <Navigate to={routeToRedirect} />
    }
};

const ProtectedRouteWithRedux = connect((state: IRootState) => ({
    isAllowed: state.login.logged,
}))(PureProtectedRoute);

export { ProtectedRouteWithRedux as ProtectedRoute };
