import cx from 'classnames';
import { FormikBag, FormikProps, withFormik } from 'formik';
import React, { ChangeEvent, HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose, mapProps } from 'recompose';

import { getGlobalT } from '../../../i18n/setup-translations';
import { Button } from '../../common/button/button.component';
import { Input } from '../../common/input/input.component';
import { Link } from '../../common/link/link.component';
import { Toggle } from '../../common/toggle/toggle.component';
import { Typography } from '../../common/typography/typography.component';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as EyeIcon } from '../../icons/eye-icon.svg';
import { ReactComponent as TickIcon } from '../../icons/tick.svg';
import { ReactComponent as WalkIcon } from '../../icons/walk-icon.svg';
import { ITracker } from '../../trackers/interfaces-api';
import { IWifiZone } from '../interfaces';
import styles from './edit-wifi-zones-form.module.scss';

export interface IFormValues {
    radius: number;
    name: string;
}

export interface IWifiZonePayloadInterface {
    wifiZone: IWifiZone;
}
export interface IOuterProps extends HTMLAttributes<HTMLFormElement> {
    tracker: ITracker;
    onEditWifi: (wifi: any) => void;
    onToggleWifi: (payload: any) => Promise<any>;
    canBeDeleted: boolean;
    wifiZone?: any;
    onDelete?(): unknown;
    onCancel(): any;
}

export interface IProps extends FormikProps<IFormValues>, IOuterProps { }

export const EditWifiZoneForm = ({
    canBeDeleted,
    onEditWifi,
    handleBlur,
    wifiZone,
    onDelete,
    onToggleWifi,
}: IProps) => {
    const [name, setName] = useState(wifiZone.name);
    const [modified, setModified] = useState<boolean>(false);
    const { t } = useTranslation(['wifiZones', 'commonActions']);
    const g = getGlobalT();
    const handleNameChange = (event: any) => {
        setName(event.target.value);
    };
    const handleToggleActive = (wifiZonePayload: IWifiZone) => {
        wifiZonePayload.is_active = !wifiZone.is_active;
        onToggleWifi(wifiZone);
    };
    const handleToggleNotif = (wifiZonePayload: IWifiZone) => {
        wifiZonePayload.enable_notifications = !wifiZone.enable_notifications;
        onToggleWifi(wifiZone);
    };
    const handleToggleDisplayeWifizone = (wifiZonePayload: IWifiZone) => {
        wifiZonePayload.displayed = !wifiZone.displayed;
        onEditWifi(wifiZonePayload);
    };

    const maybeRenderDeleteButton = () => {
        if (canBeDeleted) {
            return (
                <div className={styles.deleteButtonWrapper}>
                    <Link
                        className={styles.deleteButton}
                        onClick={(e) => {
                            e.preventDefault();
                            if (onDelete) {
                                onDelete();
                            }
                        }}
                    >
                        {g('geofences:EDIT.DELETE_GEOFENCE')}
                    </Link>
                </div>
            );
        }
    };
    const cancelModification = () => {
        setModified(false);
    };
    const onModification = () => {
        setModified(true);
    };
    const submitNameChange = (wifiZonePayload: IWifiZone, newName: string) => {
        wifiZonePayload.name = newName;
        onToggleWifi(wifiZone);
        setModified(false);
    };
    const maybeRenderLongTrackerName = (nameDisplayed: string) => {
        if (nameDisplayed.length > 50) {
            const split = 50;
            return (
                <Typography className={styles['registered-info']} noMargin>
                    {nameDisplayed.substr(0, split)}
                </Typography>
            );
        }
        return (
            <Typography className={styles['registered-info']} noMargin>
                {name}
            </Typography>
        );
    };
    const maybeRenderModified = () => {
        if (modified === true) {
            return (
                <div className={styles['add-container']}>
                    <Input
                        placeholder={t(
                            'trackerSettings:DISPLAY.TRACKER_NAME.PLACEHOLDER',
                        )}
                        isModified
                        small
                        value={name}
                        name={`trackerName`}
                        onChange={handleNameChange}
                        onBlur={handleBlur}
                    />
                    <div className={styles['add-buttons-container']}>
                        <Button
                            onClick={() => submitNameChange(wifiZone, name)}
                            className={styles['submit-button']}
                            title="Submit"
                        >
                            {t('commonActions:VALIDATE')}
                            <TickIcon className={styles['form-svg']} />
                        </Button>
                        <Button
                            onClick={() => cancelModification()}
                            className={styles['cancel-button']}
                            title="cancel"
                        >
                            <CrossIcon className={styles['form-svg']} />
                        </Button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles['form-option-container']}>
                    {maybeRenderLongTrackerName(wifiZone.is_active)}
                    <div className={styles['form-buttons-container']}>
                        <Button
                            onClick={() => onModification()}
                            className={styles['edit-button']}
                            title="Edit"
                        >
                            <EditIcon color='#ed6e18' style={{ color: '#ed6e18' }} className={styles['form-svg']} />
                        </Button>
                    </div>
                </div>
            );
        }
    };

    return (
        <div>
            {maybeRenderModified()}
            <div>
                <div className={styles['wifi-toggle']}>
                    <Typography className={styles['wifi-activation']} noMargin>
                        {t('ZONE_ACTIVATION')}
                    </Typography>
                    <Toggle
                        wifiToggle
                        checked={wifiZone.is_active}
                        name="entranceNotification"
                        onChange={() => handleToggleActive(wifiZone)}
                        className={styles.toggle}
                    />
                </div>
                <Typography bold className={styles['title-class']}>
                    {t('DISPLAY_TITLE')}
                </Typography>
                <div
                    className={styles['wifi-toggle']}
                    style={{ alignItems: 'center' }}
                >
                    <EyeIcon className={styles['form-svg-icon']} />


                    <Typography noMargin>{t('DISPLAY_BUTTON')}</Typography>
                    <Toggle
                        wifiToggle
                        checked={wifiZone.displayed}
                        name="entranceNotification"
                        onChange={() => handleToggleDisplayeWifizone(wifiZone)}
                        className={styles.toggle}
                    />
                </div>
                <Typography size14 bold noMargin>
                    {t('IN_OUT_TEXT')}
                </Typography>
                <Typography size12 gray className={styles['gray-text']}>
                    {t('NOTIF_TEXT')}
                </Typography>
                <div className={styles['wifi-toggle']}>
                    <WalkIcon className={styles['form-svg-icon']} />
                    <Typography noMargin>{t('PUSH_NOTIF')}</Typography>
                    <Toggle
                        wifiToggle
                        checked={wifiZone.enable_notifications}
                        name="entranceNotification"
                        onChange={() => handleToggleNotif(wifiZone)}
                        className={styles.toggle}
                    />
                </div>
            </div>
            {maybeRenderDeleteButton()}
        </div>
    );
};

export const ControlledEditWifiZoneForm = compose<IProps, IOuterProps>()(
    EditWifiZoneForm,
);
