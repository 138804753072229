import React from 'react';
import { Provider } from 'react-redux';

import { IRootState, store } from './store';

export const withStore = (
    Component: React.ComponentType,
    overrideStore?: Partial<IRootState>,
) => (props: any) => (
    <Provider store={overrideStore || store} {...props}>
        <Component {...props} />
    </Provider>
);
