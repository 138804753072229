import { FormikBag, FormikProps, withFormik } from 'formik';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { IWithFormUtils, withFormUtils } from '../../../../utils/form-helpers';

import { SaveButton } from '../../../common/button/save-button.component';
import { PasswordInput } from '../../../common/password-input/password-input.component';
import styles from './password-change-form.module.scss';
import { getPasswordChangeValidationSchema } from './password-change-validation.schema';

export interface IPasswordChangeFormValues {
    oldPassword: string;
    newPassword: string;
}

interface IOuterProps {
    onSubmit: (values: IPasswordChangeFormValues) => Promise<unknown>;
}

interface IProps
    extends IOuterProps,
        FormikProps<IPasswordChangeFormValues>,
        IWithFormUtils {}

const PasswordChangeForm = ({
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    isFieldValid,
    getErrorMessage,
}: IProps) => {
    const { t } = useTranslation(['forms', 'commonActions']);

    return (
        <form onSubmit={handleSubmit}>
            <PasswordInput
                onBlur={handleBlur}
                onChange={handleChange}
                className={styles.control}
                label={t('OLD_PASSWORD.LABEL')}
                placeholder={t('OLD_PASSWORD.PLACEHOLDER')}
                value={values.oldPassword}
                name="oldPassword"
                id="password-change-old-password"
                errorMessage={getErrorMessage('oldPassword')}
                isValid={isFieldValid('oldPassword')}
            />
            <PasswordInput
                onBlur={handleBlur}
                onChange={handleChange}
                className={styles.control}
                label={t('NEW_PASSWORD.LABEL')}
                placeholder={t('NEW_PASSWORD.PLACEHOLDER')}
                value={values.newPassword}
                name="newPassword"
                id="password-change-new-password"
                errorMessage={getErrorMessage('newPassword')}
                isValid={isFieldValid('newPassword')}
            />
            <SaveButton className={styles.submit} type="submit">
                {t('commonActions:CHANGE')}
            </SaveButton>
        </form>
    );
};

export const ControlledPasswordChangeForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IPasswordChangeFormValues>({
        handleSubmit(
            values,
            formikBag: FormikBag<IOuterProps, IPasswordChangeFormValues>,
        ) {
            formikBag.props.onSubmit(values);
        },
        mapPropsToValues() {
            return {
                newPassword: '',
                oldPassword: '',
            };
        },
        validationSchema: () => getPasswordChangeValidationSchema(),
    }),
    withFormUtils,
)(PasswordChangeForm);
