import { apiService } from '../api/api.service';
import { IPopinAlertAPI } from './interface';

const url = '/popin_alert';

export const fetchPopinAlerts = (): Promise<IPopinAlertAPI> => {
    return apiService.fetch(url, {}).then((resp) => {
        // resp status can be 404, and its ok.
        // Simply return null
        if (resp.status !== 200) {
            return null;
        }
        return resp.json();
    });
};
