import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EyeHiddenIcon } from '../../icons/eye-hidden.svg';
import { ReactComponent as EyeVisibleIcon } from '../../icons/eye-visible.svg';
import { ReactComponent as LockIcon } from '../../icons/lock.svg';
import { IInputProps, Input } from '../input/input.component';

import styles from './password-input.module.scss';

export const PasswordInput = ({ ...props }: IInputProps) => {
    const { t } = useTranslation('forms');

    const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);

    const getPasswordIcon = () => {
        if (isPasswordVisible) {
            return <EyeHiddenIcon onClick={(e) => setPasswordVisible(false)} />;
        } else {
            return <EyeVisibleIcon onClick={(e) => setPasswordVisible(true)} />;
        }
    };

    const getPasswordType = () => {
        if (isPasswordVisible) {
            return 'text';
        }
        return 'password';
    };

    return (
        <Input
            label={t('PASSWORD.LABEL')}
            type={getPasswordType()}
            leftIcon={<LockIcon className={styles.icon} />}
            rightIcon={getPasswordIcon()}
            {...props}
        />
    );
};
