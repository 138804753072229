import get from 'lodash/get';

import { getGlobalT } from '../../../i18n/setup-translations';
import { formatDate, formatTimeAgo } from '../../../utils/time-formatters';
import { ITrackerOnSidebar, TrackerType } from '../interfaces';
import { ITracker, ITrackerEvent } from '../interfaces-api';
import { resolveTrackerColor, TrackerIcon } from '../tracker-icons';
import { hasActiveGPS } from './tracker-mappers-utils';
import { trackerToIsActivating } from './tracker-to-is-activating';

export const mapTrackersToSidebar = (
    trackers: ITracker[],
): ITrackerOnSidebar[] => {
    const t = getGlobalT();

    const getLastConnectionTime = (pos: ITrackerEvent) => {
        if (pos.date_server) {
            return formatTimeAgo(pos.date_server);
        }
        return t('misc:NO_DATE');
    };

    const getLastSeenTime = (pos: ITrackerEvent) => {
        if (pos.date_tracker) {
            return formatTimeAgo(pos.date_tracker);
        }
        return t('misc:NO_DATE');
    };

    const getDate = (pos: ITrackerEvent) => {
        if (pos.date_tracker) {
            return formatDate(pos.date_tracker);
        }
        return t('misc:NO_DATE');
    };

    const getRemainingCallTime = (maxThreshold: number, callUSage: number) => {
        const callTimeRemaining = maxThreshold - callUSage;
        if (callTimeRemaining > 0) {
            return callTimeRemaining;
        }
        /* This case should not happen but we lack some handling on backend side for now,
        so we need to handle negative values */
        return 0;
    };
    const getSignalStrength = (gsmField: number | null): number => {
        if (gsmField === null || gsmField === 0 || gsmField === undefined) {
            return 0;
        }
        return gsmField;
    };

    return trackers.map((tracker) => {
        let position = {} as ITrackerEvent;

        if (tracker.position && tracker.position.length) {
            position = tracker.position[0];
        }
        return {
            color: resolveTrackerColor(tracker.color, tracker.type),
            id: tracker.id,
            signalStrength: getSignalStrength(position.gsm),
            batteryLevel: position.battery || 0,
            online: position.is_online || false,
            address: 'TODO: No address data', // todo
            lastSeen: getLastSeenTime(position),
            lastConnected: getLastConnectionTime(position),
            date: getDate(position),
            icon: tracker.icon as TrackerIcon,
            name: tracker.name,
            gps: hasActiveGPS(tracker),
            callTimeRemaining: getRemainingCallTime(
                tracker.call_max_threshold,
                tracker.call_usage,
            ),
            isInDeepSleep: position?.is_in_deep_sleep,
            isInWifiZone: position?.wifi_zone_id,
            urlPicture: tracker.url_picture,
            hasRing: tracker.features.includes('ringing'),
            hasVibrate: tracker.features.includes('vibrate'),
            hasSos: tracker.features.includes('sos_button'),
            hasFlash: tracker.features.includes('has_flash'),
            /**
             * Normally this would be tracker.call_directly
             * but it is temporary disabled
             * https://redmine.weenect.com/issues/5007
             */
            hasCall: false,
            type: tracker.type as TrackerType,
            accuracy: position.radius || null,
            willExpireIn: tracker.remaining_days || 0,
            hasSubscription: Boolean(tracker.subscription),
            hasSubscriptionAlert:
                get(tracker, 'subscription.status', null) === 'canceled' &&
                tracker.remaining_days <= 30,
            subscriptionStatus: get(tracker, 'subscription.status', null),
            isActivating: trackerToIsActivating(tracker),
            subscriptionId: get(tracker, 'subscription.id', null),
        };
    });
};
