import React from 'react';
import { getGlobalT } from '../../../../i18n/setup-translations';
import { Typography } from '../../../common/typography/typography.component';

export interface IExpirationInfoProps {
    expireInDays: number;
    trackerName: string;
}

export const ExpirationInfo = ({
    expireInDays,
    trackerName,
}: IExpirationInfoProps) => {
    const t = getGlobalT();

    let textToPrint: string;

    if (expireInDays > 0) {
        textToPrint = t('trackers:EXPIRATION.WILL_EXPIRE', {
            days: expireInDays,
            tracker: trackerName,
        });
    } else {
        textToPrint = t('trackers:EXPIRATION.EXPIRED', {
            tracker: trackerName,
        });
    }

    return <Typography withMarginBottom>{textToPrint}</Typography>;
};
