import cx from 'classnames';
import React, { HTMLAttributes, ReactNode } from 'react';
import { useState } from 'react';

import { ReactComponent as TriangleArrowIcon } from '../../icons/triangle-arrow.svg';

import styles from './select.module.scss';

export interface ISelectProps extends HTMLAttributes<HTMLSelectElement> {
    label?: string | ReactNode;
    value: string;
    disabled?: boolean;
    name: string;
    small?: boolean;
}

export const Select = ({
    label,
    children,
    value,
    className,
    disabled,
    onChange,
    id,
    small,
    ...props
}: ISelectProps) => {
    const maybeRenderLabel = () => {
        if (label) {
            return (
                <label htmlFor={id} className={styles.label}>
                    {label}
                </label>
            );
        }
    };

    return (
        <div>
            {maybeRenderLabel()}
            <div className={cx(styles.container, className)}>
                <select
                    disabled={disabled}
                    className={cx(styles.field, {
                        [styles.small]: small,
                    })}
                    value={value}
                    onChange={onChange}
                    {...props}
                >
                    {children}
                </select>
                <TriangleArrowIcon className={styles.arrow} />
            </div>
        </div>
    );
};
