export type TimeInterval = 1 | 3 | 6 | 12 | 24;

export interface ITrackerSpeedDistanceActivity {
    totalDistance: number;
    distanceMetric: DistanceMetric;
    averageSpeed: number;
    maxSpeed: number;
    metricSystem: MetricSystem;
}

export enum DistanceMetric {
    KM = 'km',
    METERS = 'meters',
    MILES = 'miles',
    YARDS = 'yards',
}

export enum MetricSystem {
    KM = 'km',
    MILES = 'miles',
    YARD = 'yd',
    METERS = 'm',
}
