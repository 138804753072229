import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { compose } from 'redux';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../utils/with-selected-tracker';
import { IGeofencesStore } from '../../geofences/geofences.store';
import { makeGetGeofencesFetchedForTracker } from '../../geofences/selectors/geofences.selectors';
import { Dispatch, IRootState } from '../../store/store';
import { IMapPageProps } from '../map-page/map-page.component';

interface IState {
    geofencesFetched: boolean;
    geofence: IGeofencesStore;
}

interface IActions {
    fetchGeofences: () => Promise<unknown>;
}

/**
 * Detect subscription route in url and fetch data if not exist yet
 */
export const withGeofencesResolvingRaw = (
    Component: ComponentType<IMapPageProps>,
) => ({
    geofence,
    trackerId,
    fetchGeofences,
    geofencesFetched,
    ...props
}: IActions & IState & IMapPageProps & IWithSelectedTracker) => {
        const param = useLocation()
        useEffect(() => {
            const geofences = geofence.userGeofencesByTrackerId;
            if (
                param.pathname === '/trackers/:id/geofences' &&
                Object.keys(geofences).length === 0
            ) {
                fetchGeofences();
            }
        }, [
            param.pathname,
            fetchGeofences,
            param.pathname,
            geofence.userGeofencesByTrackerId,
        ]);

        return <Component {...props} />;
    };

const mapState = (state: IRootState, props: IWithSelectedTracker): IState => {
    const getHasBeenFetched = makeGetGeofencesFetchedForTracker(
        props.trackerId!,
    );

    return {
        geofence: state.geofences,
        geofencesFetched: getHasBeenFetched(state),
    };
};

const mapDispatch = (
    dispatch: any,
    props: IWithSelectedTracker,
): IActions => ({
    fetchGeofences: async () =>
        dispatch.geofences.saveGeofences(props.trackerId),
});

export const withGeofencesResolving = compose(
    withSelectedTracker,
    connect(mapState, mapDispatch),
    withGeofencesResolvingRaw,
);
