import { get } from 'lodash';
import { createSelector } from 'reselect';

import { Frequency } from '../../tracker-settings/interfaces';
import { ITracker } from '../interfaces-api';
import { mapFrequencyModeToSeconds } from '../mappers/frequency-mode-to-seconds';
import { makeGetFrequencyModeAvailable } from './get-frequency-mode-available';
import { makeGetTrackerModel } from './get-tracker-model';

export const makeGetFrequencyInterval = (trackerId: number) => {
    const getTrackerModel = makeGetTrackerModel(trackerId);
    const getFrequencyModeAvailable = makeGetFrequencyModeAvailable(trackerId);

    return createSelector(
        getTrackerModel,
        getFrequencyModeAvailable,
        (tracker: ITracker | undefined, available: boolean) => {
            const freqMode = get(tracker, 'last_freq_mode') as
                | Frequency
                | undefined;

            if (!available || !freqMode || !tracker) {
                return null;
            }

            return mapFrequencyModeToSeconds(freqMode);
        },
    );
};
