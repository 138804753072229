import { createSelector } from 'reselect';

import { ITracker } from '../interfaces-api';
import { mapTrackersToSidebar } from '../mappers/trackers-to-sidebar';
import { trackersFromApi } from './selectors';

export const makeGetTrackersOnSidebar = () =>
    createSelector(trackersFromApi, (trackers: ITracker[]) =>
        mapTrackersToSidebar(trackers),
    );
