import React, { HTMLAttributes } from 'react';

import { getGlobalT } from '../../../i18n/setup-translations';
import { Toggle } from '../../common/toggle/toggle.component';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import {
    PausePlayingButton,
    ResumePlayingButton,
    StartPlayingButton,
    StopPlayingButton,
} from '../history-route/activity-playing-buttons';

import styles from './position-controls.module.scss';

interface IPositionControlsProps extends HTMLAttributes<HTMLDivElement> {
    isPlayingRunning: boolean;
    pathModeEnabled: boolean;
    heatmapModeEnabled: boolean;
    isPaused: boolean;
    currentEventTime: string;
    stopPlaying(): unknown;
    pausePlaying(): unknown;
    startPlaying(): unknown;
    toggleActivityMode(): unknown;
    toggleHeatmapMode(): unknown;
}

export const PositionControls = ({
    className,
    isPlayingRunning,
    stopPlaying,
    startPlaying,
    pausePlaying,
    isPaused,
    currentEventTime,
    heatmapModeEnabled,
    pathModeEnabled,
    toggleActivityMode,
    toggleHeatmapMode,
}: IPositionControlsProps) => {
    const t = getGlobalT();

    const renderPlayButton = () => {
        if (isPlayingRunning) {
            return (
                <StopPlayingButton
                    title={t('activity:CONTROLS.STOP_PLAYING_TITLE')}
                    onClick={stopPlaying}
                >
                    {t('activity:CONTROLS.STOP_PLAYING')}
                </StopPlayingButton>
            );
        } else {
            return (
                <StartPlayingButton
                    title={t('activity:CONTROLS.START_PLAYING_TITLE')}
                    onClick={startPlaying}
                >
                    {t('activity:CONTROLS.START_PLAYING')}
                </StartPlayingButton>
            );
        }
    };

    const renderSecondaryControls = () => {
        if (!isPlayingRunning) {
            return (
                <div>
                    <Toggle
                        checked={pathModeEnabled}
                        onChange={toggleActivityMode}
                        className={styles.toggle}
                        title={t('activity:CONTROLS.TOGGLE_PATH_TITLE')}
                    >
                        {t('activity:CONTROLS.TOGGLE_PATH')}
                    </Toggle>
                    <Toggle
                        checked={heatmapModeEnabled}
                        onChange={toggleHeatmapMode}
                        className={styles.toggle}
                        title={t('activity:CONTROLS.TOGGLE_HEATMAP_TITLE')}
                    >
                        {t('activity:CONTROLS.TOGGLE_HEATMAP')}
                    </Toggle>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    {renderPlayPauseButton()}
                    {renderEventTime()}
                </React.Fragment>
            );
        }
    };

    const renderPlayPauseButton = () => {
        if (isPaused) {
            return (
                <ResumePlayingButton
                    onClick={startPlaying}
                    className={styles['play-pause']}
                    title={t('activity:CONTROLS.RESUME_PLAYING_TITLE')}
                />
            );
        } else {
            return (
                <PausePlayingButton
                    onClick={pausePlaying}
                    className={styles['play-pause']}
                    title={t('activity:CONTROLS.PAUSE_PLAYING_TITLE')}
                />
            );
        }
    };

    const renderEventTime = () => {
        return (
            <span className={styles.eventTime}>
                <ClockIcon />
                <span>{currentEventTime}</span>
            </span>
        );
    };

    return (
        <div className={styles.controls}>
            <div className={styles.primary}>{renderPlayButton()}</div>
            <div className={styles.secondary}>{renderSecondaryControls()}</div>
        </div>
    );
};
