import cx from 'classnames';
import React, { HTMLAttributes, ReactNode, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'recompose';

import { getGlobalT } from '../../../../i18n/setup-translations';
import { Button } from '../../../common/button/button.component';
import { MenuItem } from '../../../common/menu/menu-item.component';
import { Menu } from '../../../common/menu/menu.component';
import { ReactComponent as DashIcon } from '../../../icons/dash.svg';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { getMobileTrackersMenuOpen } from '../../../layout/layout.store';
import { subscriptionProcessRoute } from '../../../routing/routes';
import { Dispatch, IRootState } from '../../../store/store';
import { ITrackerOnSidebar } from '../../interfaces';

import { TrackerReplaceModal } from '../../../tracker-replacement/tracker-replace-modal/tracker-replace-modal.component';
import { AllTrackersIconBadgeComponent } from '../all-trackers-icon-badge/all-trackers-icon-badge.component';
import { MobileSelectedTrackerBar } from '../mobile-selected-tracker-bar/mobile-selected-tracker-bar.component';
import { ConnectedTrackerContent } from '../tracker-content/tracker-content.component';
import { TrackerIconBadge } from '../tracker-icon-badge/tracker-icon-badge.component';

import styles from './mobile-trackers.module.scss';
import { ToggleButton } from './toggle-button/toggle-button.component';

interface IStateProps {
    hideOpenButton: boolean;
    isOpen: boolean;
    isMobileRedirect: boolean;
}

interface IActions {
    onOpenToggled: (open: boolean) => any;
}
interface ILocationState {
    isMobileRedirect: boolean | null;
}

interface IOuterProps extends HTMLAttributes<HTMLDivElement> {
    trackers: ITrackerOnSidebar[];
    activeTrackerId: number | null;
    onTrackerSelected: (trackerId: number | null) => any;
    onReturnToTrackersList: () => any;
    innerRouteOpen: boolean;
}

export interface IMobileTrackersProps
    extends IStateProps,
    IActions,
    IOuterProps { }
// This component is meant to be rendered only in tablet & phone views
export const MobileTrackers = ({
    activeTrackerId,
    className,
    hideOpenButton,
    innerRouteOpen,
    isOpen,
    onOpenToggled,
    onReturnToTrackersList,
    onTrackerSelected,
    trackers,
    isMobileRedirect,
    ...props
}: IMobileTrackersProps) => {
    const t = getGlobalT();
    const history = useNavigate();

    const [modifyTrackerOpen, setmodifyTrackerOpen] = useState(false);
    const showReplaceModal = () => setmodifyTrackerOpen(true);
    const closeReplaceModal = () => setmodifyTrackerOpen(false);
    const onNewTrackerSelected = (id: number | null) => {
        onTrackerSelected(id);
    };
    const goToTransfer = () => {
        history('/transfer');
    };
    const onAddTracker = () => {
        history(subscriptionProcessRoute + '/register');
    };
    // todo: Move to routing entirely, refactor
    const renderContent = () => {
        if (isOpen) {
            let content: ReactNode;
            if (activeTrackerId && !innerRouteOpen) {
                content = maybeRenderActiveTracker();
            }
            if (!activeTrackerId) {
                content = renderTrackersList();
            }

            return <React.Fragment>{content}</React.Fragment>;
        } else {
            if (!activeTrackerId) {
                return (
                    <MenuItem
                        onClick={() => onOpenToggled(true)}
                        key="All trackers key"
                        icon={<AllTrackersIconBadgeComponent />}
                        label={t('header:ALL_TRACKERS')}
                        subText={`${trackers.length} ${t(
                            'header:TRACKERS',
                        )} ${t('header:AVAILABLE_PLURAL')}`}
                    />
                );
            } else {
                return (
                    <div role="button" onClick={() => onOpenToggled(true)}>
                        <MobileSelectedTrackerBar trackerId={activeTrackerId} />
                    </div>
                );
            }
        }
    };
    const maybeRenderReplaceModal = () => {
        if (modifyTrackerOpen) {
            return (
                <TrackerReplaceModal
                    onAddClick={onAddTracker}
                    onReplaceClick={goToTransfer}
                    onClosed={closeReplaceModal}
                />
            );
        }
    };
    const maybeRenderActiveTracker = () => {

        const activeTrackerData = trackers.find(
            (tracker) => tracker.id === activeTrackerId,
        );
        if (activeTrackerData) {
            const {
                lastSeen,
                name,
                icon,
                type,
                online,
                isInDeepSleep,
                id,
                color,
            } = activeTrackerData;

            return (
                <div
                    className={styles['tracker-content']}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={styles['active-tracker-header']}>
                        {isMobileRedirect ? null : (
                            <DashIcon
                                onClick={onReturnToTrackersList}
                                className={styles['back-dash-icon']}
                            />
                        )}
                        <MenuItem
                            className={cx(styles.item, styles['show-map'])}
                            key={id}
                            icon={
                                <TrackerIconBadge
                                    iconUrl={activeTrackerData.urlPicture}
                                    isInDeepSleep={isInDeepSleep}
                                    icon={icon}
                                    type={type}
                                    color={color}
                                    online={online}
                                />
                            }
                            label={name}
                            subText={lastSeen}
                        />
                    </div>
                    <ConnectedTrackerContent tracker={activeTrackerData} />
                </div>
            );
        }
    };

    const renderTrackersList = () => {
        return (
            <div className={styles['show-map']}>
                {maybeRenderReplaceModal()}
                <MenuItem
                    onClick={() => onOpenToggled(false)}
                    key="All trackers key"
                    icon={<AllTrackersIconBadgeComponent />}
                    label={t('header:ALL_TRACKERS')}
                    subText={`${trackers.length} ${t('header:TRACKERS')} ${t(
                        'header:AVAILABLE_PLURAL',
                    )}`}
                />
                {trackers.map((tracker) => {
                    return (
                        <MenuItem
                            className={cx(styles.item, {
                                [styles.inactive]:
                                    activeTrackerId !== tracker.id &&
                                    activeTrackerId !== null,
                            })}
                            onClick={() => onNewTrackerSelected(tracker.id)}
                            key={tracker.id}
                            icon={
                                <TrackerIconBadge
                                    iconUrl={tracker.urlPicture}
                                    isInDeepSleep={tracker.isInDeepSleep}
                                    icon={tracker.icon}
                                    type={tracker.type}
                                    color={tracker.color}
                                    online={tracker.online}
                                />
                            }
                            label={tracker.name}
                            subText={tracker.lastSeen}
                            overrideRightIcon={
                                <DashIcon
                                    className={styles['dash-forward-icon']}
                                />
                            }
                        />
                    );
                })}
                <div className={styles['button-container']}>
                    <Button
                        secondary
                        small
                        iconLeft={<PlusIcon />}
                        onClick={showReplaceModal}
                    >
                        {t('trackers:ADD_TRACKER_BUTTON.LABEL')}
                    </Button>
                </div>
            </div>
        );
    };

    const maybeRenderToggleButton = () => {
        if (!hideOpenButton) {
            return (
                <ToggleButton
                    isOpen={isOpen}
                    onClick={() => onOpenToggled(!isOpen)}
                />
            );
        }
    };

    return (
        <div className={cx(className, styles.container)}>
            {maybeRenderToggleButton()}

            <Menu className={styles.list}>{renderContent()}</Menu>
        </div>
    );
};

const mapState = (state: IRootState, props: IOuterProps): IStateProps => {
    return {
        hideOpenButton: props.innerRouteOpen,
        isOpen: getMobileTrackersMenuOpen(state),
        isMobileRedirect: state.layout.isMobileRedirect,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    onOpenToggled: (open: boolean) =>
        dispatch.layout.toggleMobileTrackersMenu(open),
});

export const ConnectedMobileTrackers = compose<
    IMobileTrackersProps,
    IOuterProps
>(
    connect(mapState, mapDispatch),
)(MobileTrackers);
