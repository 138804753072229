import cx from 'classnames';
import moment from 'moment';
import React, { HTMLAttributes, ReactNode } from 'react';

import { ITrackerEvent } from '../../../trackers/interfaces-api';

import styles from './activity-item.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {
    data: ITrackerEvent;
    shouldRenderPosition?: boolean;
    eventContent?: ReactNode;
}

const formatTime = (time: string) => moment(new Date(time)).format('HH:mm:ss');

export const ActivityItem = ({
    className,
    data,
    shouldRenderPosition,
    eventContent = null,
}: IProps) => {
    const renderTime = () => formatTime(data.date_server);

    const renderPosition = () => {
        // Some positions can have no coordinates.
        if (data.latitude !== null && data.longitude !== null) {
            return (
                <span className={styles.position}>
                    {data.latitude}, {data.longitude}
                </span>
            );
        }
    };

    return (
        <div
            className={cx(styles.container, className)}
            data-testid="tracker-activity-item-container"
        >
            <span className={styles.date}>{renderTime()}</span>
            {shouldRenderPosition && renderPosition()}
            {eventContent}
        </div>
    );
};
