import { TrackerType } from '../../trackers/interfaces';

/**
 * Colors provided (backend)
 */
export const trackerColors = ['b64490', '34548b', '5bc2e7', '97d700', '888b8d'];

export const getTrackersAvailableColors = () =>
    trackerColors.map((color) => `#${color}`);

export const trackerColorsByType: { [type: string]: string } = {
    [TrackerType.CAT]: '#b64490',
    [TrackerType.CAT2]: '#b64490',
    [TrackerType.DEFAULT]: '#5bc2e7',
    [TrackerType.KID]: '#5bc2e7',
    [TrackerType.PET]: '#97d700',
    [TrackerType.PET2]: '#97d700',
    [TrackerType.SILVER]: '#888b8d',
};

export const getDefaultColorByTrackerType = () => trackerColorsByType;
