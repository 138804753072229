import { Icon } from 'leaflet';
import React from 'react';
import { Marker } from 'react-leaflet';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import userIcon from '../icons/user.svg';

import { IRootState } from '../store/store';
import { getUserLocation } from './selectors';

interface IStateProps {
    lat: number | null;
    lng: number | null;
}

interface IProps extends IStateProps {}

const icon = new Icon({
    iconSize: [40, 40],
    iconUrl: userIcon,
});

export const UserMarker = ({ lat, lng }: IProps) => {
    if (!lat || !lng) {
        return null;
    }

    return <Marker icon={icon} position={[lat, lng]} />;
};

export const ConnectedUserMarker = compose<IProps, {}>(
    connect(
        (state: IRootState): IStateProps => {
            return {
                ...getUserLocation(state),
            };
        },
    ),
)(UserMarker);
