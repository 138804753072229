import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './option-content.module.scss';

export interface IOptionContentProps {
    logo?: React.ReactElement;
    name: string;
    pricePeriodOffert?: string | undefined | null;
    price?: string | undefined | null;
    period?: string;
    iconInfo?: boolean;
    features: string[];
    subText?: string;
    extraContent?: ReactNode;
    component?: string;
}

export const OptionContent = ({
    logo,
    features,
    name,
    period,
    pricePeriodOffert,
    price,
    extraContent,
}: IOptionContentProps) => {
    const { t } = useTranslation('commonActions');

    const renderPrice = () => {
        if (pricePeriodOffert) {
            return (<span className={styles['price-period-with-promo']}>
                {t('commonActions:THEN')} {price} / {period}
            </span>)
        }
        return (
            <span className={styles['price-period']}>
                {price} / {period}
            </span>
        )
    }
    const maybeDisplayWeenectFeatureList = () => {
        return (
            <div className={styles.flex}>
                <div>
                    <div className={styles.flex}>
                        <div className={styles['top-row']}>
                            {name}
                        </div>
                        <span>{logo}</span>
                    </div>
                <ul className={styles['features-list']}>
                    <li className={styles.feature} key={features[0]}>
                        {features}
                    </li>
                </ul>
                </div>
                {extraContent}
                <div className={styles['display-prices']}>
                    <span className={styles['price-period-offert']}>
                        {pricePeriodOffert}
                    </span>
                    {renderPrice()}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className={styles.container}>
            </div>
            {maybeDisplayWeenectFeatureList()}
        </>

    );
};
