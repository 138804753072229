import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';

import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { Link } from '../../common/link/link.component';
import { Modal } from '../../common/modal/modal.component';
import { Typography } from '../../common/typography/typography.component';

import { withLanguageDetecting } from '../../language/language-detecting-resolver';
import { PageContainer } from '../../layout/page-container/page-container.component';
import { SimpleHeaderWithLanguages } from '../../layout/simple-header/simple-header.component';
import { INotification } from '../../notifications/interfaces';
import { ConnectedNotifications } from '../../notifications/notifications/notifications.component';
import { forgotPasswordRoute, loginRoute, trackersRoute } from '../../routing/routes';
import { Dispatch } from '../../store/store';
import {
    ControlledSignupForm,
    ISignupFormValues,
} from '../signup-form/signup-form.component';
import { registerUser } from '../signup.service';
import styles from './signup-page.module.scss';

interface IResponseError {
    code: string;
    message: string;
    site: string;
}
const EMAIL_ALREADY_USED_ERROR_CODE = 'EMAIL_ALREADY_USED';

interface IActions {
    showNotification: (notification: INotification) => any;
    clearNotifications: () => any;
    registerUser: (data: ISignupFormValues) => Promise<any>;
    requestLogin: ({
        email,
        password,
    }: {
        email: string;
        password: string;
    }) => Promise<any>;
    updateLoadedState: (value: boolean) => Promise<any>;
}

interface IProps extends IActions { }

export const SignupPage = ({
    requestLogin,
    showNotification,
    clearNotifications,
    updateLoadedState,
}: IProps) => {
    const { t } = useTranslation('signup');
    const loginT = useTranslation('login').t;
    const history = useNavigate()
    const [confirmationModalOpenForId, setConfirmationModalOpen] = useState(
        false,
    );
    const [isMailUsed, setIsMailUsed] = useState(false);
    const closeModal = () => {
        setConfirmationModalOpen(false);
        setIsMailUsed(true);
    };
    const maybeRenderConfirmationModal = () => {
        if (confirmationModalOpenForId) {
            return (
                <Modal
                    className={styles['modal-width']}
                    onClosed={() => setConfirmationModalOpen(false)}
                >
                    <Headline
                        className={styles['modal-close-button']}
                        level={2}
                    />
                    <Typography center className={styles['login-info']}>
                        <Trans
                            ns="signup"
                            i18nKey="SIGNUP_ERRORS.POPIN_EMAIL_ALREADY_EXIST"
                        >
                            oups , cette adresse email semble etre deja utiliser
                        </Trans>
                    </Typography>
                    <Typography center className={styles['login-info']}>
                        <RouterLink to={loginRoute}>
                            <Button className={styles['button-width']}>
                                {t('LOGIN_TEXT')}
                            </Button>
                        </RouterLink>
                        <Typography
                            center
                            className={styles['forgot-password']}
                        >
                            <RouterLink to={forgotPasswordRoute}>
                                {loginT('FORGOT_PASSWORD')}
                            </RouterLink>
                        </Typography>
                    </Typography>
                    <div className={styles['grey-line']} />
                    <Typography center className={styles.text}>
                        <Trans ns="subscriptionAlert" i18nKey="SUPPORT_TEXT">
                            Si vous avez des questions ou que vous pensez que
                            c'est une erreur, contactez notre
                            <Link
                                href="https://weenect.zendesk.com/hc/fr"
                                target="_blank"
                            >
                                service client
                            </Link>
                        </Trans>
                    </Typography>
                    <Button
                        block
                        className={styles['button-retry']}
                        onClick={() => closeModal()}
                    >
                        {t('RETRY')}
                    </Button>
                </Modal>
            );
        }
    };
    const onSubmit = (values: ISignupFormValues) => {
        updateLoadedState(true);
        registerUser(values)
        .then((resp) => {
            return requestLogin({
                email: values.email,
                password: values.password,
            }).then(() => {
                    history(trackersRoute);
                    updateLoadedState(false);
                    clearNotifications();
                })
            }).catch((err: IResponseError) => {
                updateLoadedState(false);
                // Display specific error message if email is already used
                if (err.code === EMAIL_ALREADY_USED_ERROR_CODE) {
                    setConfirmationModalOpen(true);
                }

                // Else display global error message.
                clearNotifications();
                showNotification({
                    content: t('SIGNUP_ERRORS.UNKNOWN'),
                    type: 'error',
                });
            });

    };

    return (
        <div>
            <SimpleHeaderWithLanguages />
            <ConnectedNotifications />
            <PageContainer>
                <Headline className={styles.headline} center level={2}>
                    {t('CREATE_ACCOUNT_HEADLINE')}
                </Headline>
                <ControlledSignupForm
                    className={styles.form}
                    onFormSubmit={onSubmit}
                    isMailUsed={isMailUsed}
                />
            </PageContainer>
            {maybeRenderConfirmationModal()}
        </div>
    );
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    clearNotifications: dispatch.notifications.clearNotifications,
    showNotification: dispatch.notifications.showNotification,
    registerUser: async (data: ISignupFormValues) =>
        dispatch.signup.registerUser(data),
    requestLogin: dispatch.login.requestLogin as any,
    updateLoadedState: async (loaded: boolean) =>
        dispatch.login.updateLoading(loaded),
});

export const ConnectedSignupPage = compose<IProps, any>(
    (props: IProps) => ({
        ...props,
    }),
    connect(null, mapDispatch),
    withLanguageDetecting,
)(SignupPage);
