import * as yup from 'yup';
import { getGlobalT } from '../../../i18n/setup-translations';
import { isInternationalPhone } from '../../../utils/phone-validator';

export const getSmsNumbersFormValidationSchema = () => {
    const t = getGlobalT();

    return yup.object({
        numbers: yup.array(
            yup
                .string()
                // @ts-ignore
                .test('phone', t('errors:PHONE_FORMAT'), isInternationalPhone),
        ),
    });
};
