import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch, IRootState } from '../../store/store';

import { NotificationBar } from '../notification-bar/notification-bar.component';

interface INotification {
    type: 'error' | 'success';
    content: string;
}

interface IActions {
    closeNotification: (notification: INotification) => any;
}

interface IState {
    notifications: INotification[];
}

interface IOwnProps extends HTMLAttributes<HTMLDivElement> {}

export interface INotificationsProps extends IActions, IState, IOwnProps {}

export const NotificationsRaw = ({
    notifications = [],
    closeNotification,
    className,
}: INotificationsProps) => {
    if (notifications.length === 0) {
        return null;
    }

    const onClose = (n: INotification) => {
        closeNotification(n);
    };

    return (
        <div className={cx(className)}>
            {notifications.map((notification, i) => (
                <NotificationBar
                    key={notification.content + i}
                    type={notification.type}
                    onClose={() => onClose(notification)}
                >
                    {notification.content}
                </NotificationBar>
            ))}
        </div>
    );
};

const mapState = (state: IRootState): IState => ({
    notifications: state.notifications.items,
});

const mapDispatch = (dispatch: Dispatch): IActions => ({
    closeNotification: (n: INotification) =>
        dispatch.notifications.closeNotification(n),
});

export const ConnectedNotifications = compose<INotificationsProps, IOwnProps>(
    connect(
        mapState,
        mapDispatch as any,
    ),
)(NotificationsRaw);
