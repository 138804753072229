import React, { InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckSection } from '../../../common/check-section/check-section.component';

import styles from './subscription-period-option.module.scss';

export interface ISubscriptionPeriodOptionProps
    extends InputHTMLAttributes<HTMLInputElement> {
    component?: string;
    period: string;
    textInfo?: string[] | null;
    title?: string;
    priceCost: string;
    textPopularOffer?: string | null;
    amountMonth?: string;
}

export const SubscriptionPeriodOption = ({
    period,
    priceCost,
    component,
    amountMonth,
    textPopularOffer,
    textInfo,
    title,
    className,
    ...props
}: ISubscriptionPeriodOptionProps) => {
    const subT = useTranslation('subscriptions').t;
    const sub = useTranslation('subscription').t;

    const priceByMonth = () => {
        if (
            amountMonth &&
            amountMonth !== priceCost &&
            period !== '1 an' &&
            !component
        ) {
            return (
                <span className={styles['promo-saving']}>
                    {sub('PRICE_MONTH_SAVED', {
                        price: amountMonth,
                    })}
                </span>
            );
        }
        if (period === '1 an' && !component) {
            return (
                <div className={styles['promo-saving']}>
                    {' '}
                    {sub('PRICE_MONTH_SAVED', {
                        price: amountMonth,
                    })}
                </div>
            );
        }
    };

    return (
        <>
            <CheckSection
                className={className}
                {...props}
                price={priceCost}
                period={period}
            >
                {textPopularOffer && !component ? (
                    <div className={styles.offert}>{textPopularOffer}</div>
                ) : null}
                <div className={styles.container}>
                    <div
                        className={
                            component
                                ? styles.subscriptionPeriodOption
                                : styles['flex-center']
                        }
                    >
                        <div className={styles['period-container']}>
                            <div className={styles['period-time']}>
                                {component
                                    ? subT('BASE') + ' ' + period
                                    : title}
                            </div>
                            <ul className={styles['list-info']}>
                                {textInfo?.map((el, i) => (
                                    <li key={i}>{el}</li>
                                ))}
                            </ul>
                        </div>
                        <div className={styles['price-container']}>
                            <span className={styles['price-value']}>
                                {priceCost}
                                {period === '1 mois' ? (
                                    <span className={styles.monthly}>
                                        {sub('COMMON_TEXT.MONTH')}
                                    </span>
                                ) : null}
                            </span>
                            {priceByMonth()}
                        </div>
                    </div>
                </div>
            </CheckSection>
        </>
    );
};
