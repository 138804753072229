import { createSelector } from 'reselect';

import { IRootState } from '../../store/store';
import { activeTrackerId } from '../../trackers/selectors/selectors';
import { IWifiZone, IWifiZoneMarker } from '../interfaces';
import {
    mapWifiZoneApiToClient,
    mapWifiZoneApiToMarker,
} from '../mappers/wifi-zones-api-to-client';
import { IWifiZoneEditStore } from '../wifi-zones-edit.store';
import { IWifiZonesStore } from '../wifi-zones.store';

const wifiZoneStore = (state: IRootState) => state.wifiZones;
const wifiZonesEditStore = (state: IRootState) => state.wifiZonesEdit;

export const makeGetUserWifiZonesForTracker = (trackerId: number) => {
    return createSelector(
        wifiZoneStore,
        (state: IWifiZonesStore): IWifiZone[] => {
            if (state === undefined) {
                return [];
            }
            if (!state.userWifiZonesByTrackerId[trackerId]) {
                return [];
            }

            return Object.values(state.userWifiZonesByTrackerId[trackerId]).map(
                mapWifiZoneApiToClient,
            );
        },
    );
};

export const makeGetAllUserWifiZones = () => {
    return createSelector(
        wifiZoneStore,
        (store: IWifiZonesStore): IWifiZone[] => {
            return Object.values(store.userWifiZonesByTrackerId)
                .map((wifiZonesObj) => Object.values(wifiZonesObj))
                .reduce((acc, val) => acc.concat(val), [])
                .map(mapWifiZoneApiToClient);
        },
    );
};

export const makeGetWifiZonesToDisplay = () => {
    return createSelector(
        activeTrackerId,
        wifiZoneStore,
        (
            trackerId: number | null,
            currentWifiZoneStore: IWifiZonesStore,
        ): IWifiZoneMarker[] => {
            if (trackerId) {
                return Object.values(
                    currentWifiZoneStore.userWifiZonesByTrackerId,
                )
                    .map((wifiZonesObj) => Object.values(wifiZonesObj))
                    .reduce((acc, val) => acc.concat(val), [])
                    .filter((wifiZone) => wifiZone.tracker_id === trackerId)
                    .map(mapWifiZoneApiToMarker);
            }

            return Object.values(currentWifiZoneStore.userWifiZonesByTrackerId)
                .map((wifiZonesObj) => Object.values(wifiZonesObj))
                .reduce((acc, val) => acc.concat(val), [])
                .map(mapWifiZoneApiToMarker);
        },
    );
};
export const makeGetWifiZonesFetchedForTracker = (trackerId: number) =>
    createSelector(
        wifiZoneStore,

        (store): boolean => store.dataFetchedByTrackerId[trackerId] || false,
    );

export const makeGetEditableWifiZoneId = () =>

    createSelector(
        wifiZonesEditStore,
        (state: IWifiZoneEditStore): number | null => state.id,
    );

export const makeGetEditableWifiZonePosition = () => {

    return createSelector(
        wifiZonesEditStore,
        (state: IWifiZoneEditStore): { lat: number; lng: number } =>
            state.position,
    );
};
