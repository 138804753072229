import cx from 'classnames';
import React, { HTMLAttributes, ReactNode } from 'react';

import { ReactComponent as ToggleIcon } from '../../icons/dash.svg';

import styles from './menu-item.module.scss';

export interface IMenuItemProps extends HTMLAttributes<HTMLDivElement> {
    icon: ReactNode;
    label: string;
    subText?: string;
    expanded?: boolean; // If has children, control accordion
    overrideRightIcon?: ReactNode | boolean;
}

export const MenuItem = ({
    expanded,
    icon,
    label,
    subText,
    className,
    children,
    onClick,
    overrideRightIcon,
    ...props
}: IMenuItemProps) => {
    const itemRef = React.useRef<HTMLDivElement>(null);
    const [hasBeenScrolled, setHasBeenScrolled] = React.useState(false);
    const maybeRenderIcon = () => {
        if (icon) {
            return <div className={styles.iconContainer}>{icon}</div>;
        }
    };

    const maybeRenderSubText = () => {
        if (subText) {
            return <span className={styles.subText}>{subText}</span>;
        }
    };

    const maybeRenderLongTrackerName = (name: string) => {
        if (name?.length > 20) {
            const split = 17;
            let wordBreak = name.substr(
                0,
                name.substr(0, split).lastIndexOf(' '),
            );
            if (wordBreak.lastIndexOf(' ') === -1) {
                wordBreak = name.substr(0, split);
            }
            return (
                <div>
                    <span className={cx(styles.mainLabel, styles.split)}>
                        {wordBreak}
                    </span>
                    <br className={styles.menuBr} />
                    {maybeRenderLongTrackerName(
                        name.substr(wordBreak.length + 1),
                    )}
                </div>
            );
        }
        return <span className={styles.mainLabel}>{name}</span>;
    };

    const maybeRenderRightIconButton = () => {
        if (overrideRightIcon === false) {
            return;
        }
        if (overrideRightIcon) {
            return overrideRightIcon;
        } else if (children) {
            return (
                <button
                    className={cx(styles.toggleButton, {
                        [styles.active]: expanded,
                    })}
                >
                    <ToggleIcon />
                </button>
            );
        }
    };

    const maybeRenderChildren = () => {
        if (children && expanded) {
            return <div className={styles.childrenContainer}>{children}</div>;
        }
    };

    const item = (
        <div
            className={cx(styles.item, className)}
            onClick={
                onClick
            }
            ref={itemRef}
            {...props}
        >
            <div className={styles.itemContainer}>
                {maybeRenderIcon()}
                <div className={styles.labelsContainer}>
                    {maybeRenderLongTrackerName(label)}
                    {maybeRenderSubText()}
                </div>
                {maybeRenderRightIconButton()}
            </div>
            {maybeRenderChildren()}
        </div>
    );

    /* Each time a tracker is selected, the trackers-list scroll to that element */
    if (
        expanded &&
        itemRef &&
        itemRef.current &&
        itemRef.current.scrollIntoView &&
        !hasBeenScrolled
    ) {
        setHasBeenScrolled(true); // Required to enable manual scrolls of the UL element
        itemRef.current.scrollIntoView(true);
    }
    return item;
};
