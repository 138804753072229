import { createSelector } from 'reselect';

import { INotificationSettingsDTO } from '../../tracker-settings/interfaces';
import { makeGetTrackerModel } from './get-tracker-model';

export const makeGetTrackerNotificationsSettings = (trackerId: number) => {
    const getTrackerModel = makeGetTrackerModel(trackerId);

    return createSelector(
        getTrackerModel,
        (tracker): INotificationSettingsDTO => {
            if (!tracker) {
                throw Error('Wrong ID');
            }

            const {
                area_appli_notification,
                area_mail_notification,
                area_sms_notification,
                battery_mail_notification,
                battery_sms_notification,
                battery_appli_notification,
                button_appli_notification,
                button_mail_notification,
                button_sms_notification,
                sos_appli_notification,
                sos_mail_notification,
                sos_sms_notification,
            } = tracker;

            return {
                area_appli_notification,
                area_mail_notification,
                area_sms_notification,
                battery_mail_notification,
                battery_sms_notification,
                battery_appli_notification,
                button_appli_notification,
                button_mail_notification,
                button_sms_notification,
                sos_appli_notification,
                sos_mail_notification,
                sos_sms_notification,
            };
        },
    );
};
