export const cguContent = [
    `ARTICLE 8. SERVICES OPTIONNELS

    8.1.5. L’option “Multirisque” permet d’étendre la garantie constructeur à la casse et à la perte du boitier. Le remplacement du boitier se fait sans conditions dans la limite de trois (3) remplacements. La Souscription à l’option « Multirisque » peut avoir lieu (i) soit lors de l’enregistrement du boitier, (ii) soit postérieurement à l’enregistrement du boitier sous réserve d’apporter la preuve de l’état de fonctionnement du boitier à la date de la souscription (via une date de connexion ultérieure à la date de souscription par exemple). Dans l’hypothèse où trois (3) remplacements du même boitier seraient effectués, l’option « Multirisque » sera résiliée de plein droit (et le Client ne sera en conséquence plus prélevé du montant de cette option) ». `,
    `ARTICLE 7. ENTREE EN VIGUEUR ET DUREE DE L’ABONNEMENT

    7.1. L’ABONNEMENT entre en vigueur au jour de l’enregistrement par le Client du Traceur sur le Site, sous
    réserve d’encaissement par la Société du prix du Traceur et de L’ABONNEMENT.
    7.2. L’ABONNEMENT comprend :
    7.2.1. L’accès à l’Espace Client de paramétrage et gestion des alertes et du compte Client ;
    7.2.2. Trois Mega Octets de transfert de données par mois donnant droit, en théorie, à un nombre de 20.000
    positions par mois. Au-delà de 3 Mega Octets de consommation par mois, la carte Sim sera suspendue pour le
    restant du mois en cours puis réactivée le mois suivant. Cette limite est appliquée par la Société afin de se
    prémunir d’une utilisation frauduleuse ou abusive des cartes SIM.
    7.3. Le Client choisit la formule d’Abonnement souhaitée au moment où il active son Traceur sur le Site.
    7.4. Les Abonnements « 1 an » sont conclus pour une période de 1 an à compter de l’activation du Traceur.
    A l’issue des 12 mois s’effectue une reconduction tacite. Cette reconduction tacite est annulable jusqu’à un mois
    avant la fin de l’Abonnement d’un an, depuis son espace personnel accessible à l’adresse
    https://my.weenect.com, en cliquant sur le bouton dédié.
    7.5. Les Abonnements « 2 ans » sont conclus pour une période de 2 ans à compter de l’activation du Traceur.`,
];
