import { ModelConfig } from '@rematch/core';

import { INotification } from './interfaces';

export interface INotificationsStore {
    items: INotification[];
}

export const notificationsStore: ModelConfig<INotificationsStore> = {
    state: {
        items: [],
    },
    reducers: {
        clear: (state: INotificationsStore): INotificationsStore => ({
            ...state,
            items: [],
        }),
        append: (
            state: INotificationsStore,
            payload: INotification,
        ): INotificationsStore => ({
            ...state,
            items: [...state.items, payload],
        }),
        removeOne: (
            state: INotificationsStore,
            payload: INotification,
        ): INotificationsStore => ({
            ...state,
            items: state.items.filter((n) => n !== payload),
        }),
    },
    effects: (dispatch) => ({
        showNotification(notification: INotification) {
            this.append(notification);
        },
        closeNotification(notification: INotification) {
            this.removeOne(notification);
        },
        clearNotifications() {
            this.clear();
        },
    }),
};
