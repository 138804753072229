import { PaymentCode } from '../subscription-process/interfaces';

export enum SubscriptionOptionType {
    PREMIUMPACK = 'premium_pack',
    WARRANTY = 'warranty',
    SMS = 'sms',
}

export type SubscriptionStatus =
    | 'active'
    | 'canceled'
    | 'new'
    | 'cancel_scheduled'
    | 'refunded';

/**
 * Offered option for subscription
 */
export interface IRawUserSubscriptionOption {
    activated: boolean;
    activation_date: string;
    next_charge_at: string;
    subscription_id: number;
    amount: number;
    amount_gbp: number;
    code: SubscriptionOptionType;
    created_at: string;
    id: number;
    is_running: boolean;
    updated_at: string;
    site: string;
    currency: string;
}

/**
 * Interface for subscription for each tracker/user
 */
export interface IRawUserSubscription {
    amount: number;
    amount_gbp: number;
    cancel_explanation: string | null;
    cancel_reason: string | null;
    canceled_at: string | null;
    card_pan: string | null;
    created_at: string;
    currency: 'EUR' | string;
    id: number;
    max_tracker_nb: number;
    next_charge_at: string | null;
    offer_id: number;
    option_error_payment: number;
    is_cancelled_because_of_payment_error: boolean;
    option_status: boolean;
    options: IRawUserSubscriptionOption[];
    payment_mean: PaymentCode.GOCARDLESS | PaymentCode.HIPAY;
    period: number;
    site: 'weenect' | 'bt';
    status: SubscriptionStatus;
    trackers: number[];
    updated_at: string;
    user_id: number;
    is_under_commitment_period: boolean;
    is_under_notice_period: string;
}

/**
 * Interface on :
 *  1. new subscription success response
 *  2. change payment mean success response
 *  3. change card success response
 */
export interface ISubscriptionRedirection {
    redirect_url: string;
}
