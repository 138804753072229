import cx from 'classnames';
import React, { HTMLAttributes, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { pure } from 'recompose';

import weenectLogo from '../../../images/LogoCouleur.svg';
import { Omit } from '../../../utils/omit';
import { ConnectedLanguageSwitcher } from '../../language/components/language-switcher/language-switcher.component';
import { ConnectedNotifications } from '../../notifications/notifications/notifications.component';
import { trackersRoute } from '../../routing/routes';
import { Theme } from '../../trackers/interfaces';
import { ConnectedLogoutButton } from '../user-area/user-area.component';

import styles from './simple-header.module.scss';

let logoSource;
const appVersion = process.env.REACT_APP_VERSION;

export interface IHeaderProps extends HTMLAttributes<HTMLDivElement> {
    leftSlot?: ReactNode;
    rightSlot?: ReactNode;
    theme?: Theme | null;
}

export const SimpleHeader = pure(
    ({ className, leftSlot, rightSlot }: IHeaderProps) => {
        logoSource = weenectLogo;
        const { id } = useParams()
        parseInt(id!, 10)
        const logoComponent = (
            <div className={styles['logo-container']}>
                <img
                    alt="Weenect logo"
                    title={appVersion}
                    className={styles.logo}
                    src={logoSource}
                />
            </div>
        );

        return (
            <header className={cx(styles['simple-header'], className)}>
                <div className={styles.container}>
                    <div className={styles['left-slot']} >{leftSlot}</div>
                    <Link to={id ? trackersRoute + `/${id}` : trackersRoute}>
                        {logoComponent}
                    </Link>
                    <div className={styles['right-slot']}>{rightSlot}</div>
                </div>
                <ConnectedNotifications className={styles.notifications} />
            </header>
        );
    },
);

interface IWithLogoutProps extends IHeaderProps { }

export const SimpleHeaderWithLogout = pure(
    ({ ...props }: Omit<IWithLogoutProps, 'rightSlot'>) => (
        <SimpleHeader
            {...props}
            rightSlot={
                <ConnectedLogoutButton className={styles['logout-button']} />
            }
        />
    ),
);

export const SimpleHeaderWithLanguages = ({
    ...props
}: Omit<IWithLogoutProps, 'rightSlot'>) => (
    <SimpleHeader {...props} rightSlot={<ConnectedLanguageSwitcher />} />
);
