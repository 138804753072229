import cx from 'classnames';
import React, { HTMLAttributes, ReactNode } from 'react';
import { withProps } from 'recompose';

import { Omit } from '../../../utils/omit';

import { ReactComponent as InfoIcon } from '../../icons/info-2.svg';

import styles from './tooltip.module.scss';

export interface ITooltipProps extends HTMLAttributes<HTMLSpanElement> {
    content: ReactNode;
}

export const Tooltip = ({ className, children, content }: ITooltipProps) => (
    <span className={cx(styles.tooltip, className)}>
        <span className={styles.trigger}>{children}</span>
        <span className={styles.content}>{content}</span>
    </span>
);

export const InfoTooltip = withProps<
    { children: ReactNode },
    Omit<ITooltipProps, 'children'>
>({
    children: <InfoIcon />,
})(Tooltip);
