import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../common/button/button.component';
import { Headline } from '../../../common/headline/headline.component';
import { Typography } from '../../../common/typography/typography.component';
import { SubscriptionOptionType } from '../../interfaces-api';
import styles from './cancel-option-confirmation.module.scss';

export interface ICancelOptionConfirmationProps {
    code: string;
    name: string;
    expirationDate?: string;
    onConfirm: () => any;
    onAbort: () => any;
}

export const CancelOptionConfirmation = ({
    code,
    name,
    expirationDate,
    onConfirm,
    onAbort,
}: ICancelOptionConfirmationProps) => {
    const { t } = useTranslation('subOptions');
    const subscriptionT = useTranslation('subscriptionOptionsSelection').t;

    const renderOptionText = (optionCode: string) => {
        if (optionCode === SubscriptionOptionType.WARRANTY) {
            return (
                <Typography>
                    <>{subscriptionT('CANCEL_WARNING_WARRANTY')}</>
                </Typography>
            );
        } else if (optionCode === SubscriptionOptionType.PREMIUMPACK) {
            return <Typography><>{t('PREMIUM.DEACTIVATE')}</></Typography>;
        }
        return null;
    };

    return (
        <React.Fragment>
            <div className={styles.center}>
                <Headline className={styles.headline} level={2}>
                    <>{t('CANCEL.HEADLINE', { option: name })}</>
                </Headline>
                {renderOptionText(code)}
                <p><>{t('CANCEL.EXPIRATION_DATE', { date: expirationDate })}
                </></p>
                <div className={styles.buttons}>
                    <Button primary onClick={onConfirm}>
                        <>{t('commonActions:YES')}</>
                    </Button>
                    <Button secondary onClick={onAbort}>
                        <>{t('commonActions:NO')}</>
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};
