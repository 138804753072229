import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { compose } from 'redux';

import { Dispatch, IRootState } from '../../store/store';
import { ITracker } from '../../trackers/interfaces-api';
import { IMapPageProps } from '../map-page/map-page.component';

interface IState {
    trackers: ITracker[];
}

interface IActions {
    selectActiveTracker: (id: number | null) => any;
    selectTheme: (id: number | null) => any;
}

/**
 * Each time tracker ID in URL changes, set active tracker in redux
 */
export const withActiveTrackerResolvingRaw = (
    Component: ComponentType<IMapPageProps>,
) => ({
    selectActiveTracker,
    selectTheme,
    ...props
}: IActions & IState & IMapPageProps) => {
        const { id } = useParams()
        const formatedId = id ? parseInt(id, 10) : null
        useEffect(() => {
            if (!formatedId && props.trackers.length === 1) {
                selectActiveTracker(props.trackers[0].id);
            }
            selectActiveTracker(formatedId || null);
        }, [formatedId, props.activeTrackerId, props.trackers]);

        return (
            <Component
                {...props}
            />
        );
    };

const mapState = (state: IRootState) => ({
    trackers: state.userTrackers.rawTrackers,
});

const mapDispatch = (dispatch: Dispatch): IActions => ({
    selectActiveTracker: (trackerId: number | null) =>
        dispatch.userTrackers.selectActiveTracker(trackerId as any),
    selectTheme: async (trackerId: number | null) =>
        dispatch.userTrackers.selectTheme(trackerId as any),
});

export const withActiveTrackerResolving = compose(
    connect(mapState, mapDispatch),
    withActiveTrackerResolvingRaw,
);
