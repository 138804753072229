import './index.scss';

import React from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux'
import { IRootState, store } from './app/store/store';

import whyDidYouRender from '@welldone-software/why-did-you-render';

import { App } from './app/app';
import { initI18n } from './i18n/setup-translations';

Sentry.init({
    dsn:
        'https://3d11f57b545c4c29b081a58d7134b6f2@o421117.ingest.sentry.io/6465579',
    integrations: [new BrowserTracing(), new Sentry.Integrations.Breadcrumbs({ console: false })],


    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.001, // can be replaced by the float value of process.env.SENTRY_TRACES_SAMPLE_RATE
    environment: process.env.NODE_ENV,
});

// if (process.env.NODE_ENV !== 'production') {
//     // @ts-ignore
//     // disable google analytics when dev env
//     window[`ga-disable-${process.env.REACT_APP_GTM_ID}`] = true;

//     /**
//      * You can uncomment below lines to watch for potentials optimizations
//      * in re-render.
//      */
//     const { whyDidYouUpdate } = require('why-did-you-update');
//     whyDidYouUpdate(React);
//     whyDidYouRender(React, {
//         trackAllPureComponents: true,
//     });
// }

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

initI18n().then(() => {
    root.render(
        <Provider store={store}>
            <App />
        </Provider>
    );
});
