import React from 'react';

import { ReactComponent as Avatar } from '../../../../images/LogoTracker.svg';
import { colors } from '../../../../styles/colors';
import { IconBadge } from '../../../common/icon-badge/icon-badge.component';

import styles from './all-trackers-icon-badge.module.scss';

export const AllTrackersIconBadgeComponent = () => (
    <IconBadge
        icon={<Avatar className={styles.icon} />}
        color={colors.colorWhite}
    />
);
