import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { compose } from 'recompose';

import { Dispatch, IRootState } from '../../store/store';
import { makeGetAllTrackersIds } from '../../trackers/selectors/get-all-tracker-ids';
import { makeGetIsSingleTrackerWithoutSubscription } from '../../trackers/selectors/get-is-single-tracker-without-subscription';

interface IStateProps {
    singleWithoutSub: boolean;
    trackerId: number;
    hasApplyFree: boolean;
}

interface IActions {
    setSelectedTracker: (id: number) => Promise<unknown>;
}

interface IHOCProps extends IStateProps, IActions { }

/**
 * This HOC checks if user has only one tracker BUT empty subscription,
 * so it will redirect to finish the subscription.
 *
 * N.B. If user is a b2b client, it doesn't need to purchase a subscription.
 * Thus we don't redirect it to the subscription page.
 * This condition is based on the apply_free attribute of the retailer object :
 * When apply_free is false, the customer DOESN'T need a subscription.
 */
const singleTrackerEmptySubGuard = (Component: ComponentType<any>) => ({
    singleWithoutSub,
    trackerId,
    setSelectedTracker,
    hasApplyFree,
    ...props
}: IHOCProps) => {
    const history = useNavigate()
    useEffect(() => {
        if (singleWithoutSub && trackerId && hasApplyFree) {
            setSelectedTracker(trackerId).then(() => {
                history(`/add/subscription/${trackerId}`);
            });
        }
    }, [
        singleWithoutSub,
        useLocation().pathname,
        setSelectedTracker,
        trackerId,
        hasApplyFree,
    ]);

    return <Component {...props} />;
};

const mapState = (state: IRootState, props: any): IStateProps => {
    const getIsSingleTrackerWithoutSub = makeGetIsSingleTrackerWithoutSubscription();
    const getTrackerIds = makeGetAllTrackersIds();

    return {
        singleWithoutSub: getIsSingleTrackerWithoutSub(state),
        trackerId: getTrackerIds(state)[0], // This will be always single id
        hasApplyFree: state.retailer.hasApplyFree,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    setSelectedTracker: async (id: number) =>
        dispatch.subscriptionProcess.setSelectedTracker(id),
});

const connectedSingleTrackerEmptySubGuard = compose(
    connect(mapState, mapDispatch),
    singleTrackerEmptySubGuard,
);

export { connectedSingleTrackerEmptySubGuard as singleTrackerEmptySubGuard };
