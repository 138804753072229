import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getGlobalT } from '../../../i18n/setup-translations';
import { IWithSelectedTracker } from '../../../utils/with-selected-tracker';
import { Typography } from '../../common/typography/typography.component';
import { Dispatch, IRootState } from '../../store/store';
import { makeGetFrequencyInterval } from '../../trackers/selectors/get-tracker-frequency-interval';
import { formatTimeInterval } from '../format-time-interval';
import { ConnectedFrequencySlider } from '../frequency-slider/frequency-slider.component';
import styles from './frequency-settings.module.scss';

interface IOuterProps
    extends HTMLAttributes<HTMLDivElement>,
        IWithSelectedTracker {}

interface IState {
    frequency: number;
}

interface IActions {
    updateTrackerRefreshMode: (seconds: number) => Promise<any>;
}

interface IFrequencySettingsProps extends IOuterProps, IActions, IState {}

const getBatteryUsageFromSeconds = (seconds: number) => {
    const t = getGlobalT();

    switch (seconds) {
        case 30:
        case 60:
            return t('trackerSettings:GPS_FREQUENCY.BATTERY_USAGE_LEVELS.LOW');
        case 300:
        case 600:
            return t(
                'trackerSettings:GPS_FREQUENCY.BATTERY_USAGE_LEVELS.MEDIUM',
            );
        case 1800:
        case 3600:
        default:
            return t('trackerSettings:GPS_FREQUENCY.BATTERY_USAGE_LEVELS.HIGH');
    }
};

export const FrequencySettings = ({
    className,
    trackerId,
    updateTrackerRefreshMode,
    frequency,
    ...props
}: IFrequencySettingsProps) => {
    const { t } = useTranslation('trackerSettings');

    return (
        <div className={cx(className)}>
            <Typography withMarginBottom>{t('GPS_FREQUENCY.TEXT')}</Typography>
            <Typography size12 gray>
                {t('GPS_FREQUENCY.HINT')}
            </Typography>

            <ConnectedFrequencySlider
                trackerId={trackerId}
                hasPreview={false}
            />

            <Typography size12>
                {t('GPS_FREQUENCY.BATTERY_USAGE')}
                <Typography
                    inlineBlock
                    tag="span"
                    orange
                    className={styles.span}
                >
                    {getBatteryUsageFromSeconds(frequency)}
                </Typography>
            </Typography>
            <Typography size12>
                {t('GPS_FREQUENCY.GEOFENCE_NOTIFICATIONS')}
                <Typography
                    inlineBlock
                    tag="span"
                    orange
                    className={styles.span}
                >
                    {formatTimeInterval(frequency)}
                </Typography>
            </Typography>
        </div>
    );
};

const mapState = (state: IRootState, props: IOuterProps): IState => {
    const getActiveTrackerFrequency = makeGetFrequencyInterval(
        props.trackerId!,
    );

    return {
        frequency: getActiveTrackerFrequency(state) as number,
    };
};

const mapDispatch = (dispatch: Dispatch, props: IOuterProps): IActions => ({
    updateTrackerRefreshMode: async (seconds: number) =>
        dispatch.trackersSettings.updateTrackerRefreshMode({
            secondsPeriod: seconds,
            trackerId: props.trackerId,
        }),
});

export const ConnectedFrequencySettings = compose<
    IFrequencySettingsProps,
    IOuterProps
>(connect(mapState, mapDispatch))(FrequencySettings);
