import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from '../../icons/dash.svg';
import styles from '../animal-info.module.scss';
import { AnimalFormStep } from '../interfaces';

interface IOuterProps {
    activeIndex: number;
    setCurrentStep: any;
}

export const AnimalInfoStep = ({
    activeIndex,
    setCurrentStep,
}: IOuterProps) => {
    const { t } = useTranslation(['activityOnboarding']);
    const [totalStep] = useState(Object.keys(AnimalFormStep).length / 2 - 1);

    return (
        <>
            <React.Fragment key={'name'}>
                <div
                    onClick={() =>
                        activeIndex > 0 ? setCurrentStep(activeIndex - 1) : null
                    }
                    className={styles.stepContainer}
                >
                    {activeIndex > 0 ? (
                        <ArrowIcon className={styles.arrowLeft} />
                    ) : null}
                    <p>
                        {t('COMMON.STEP')} {activeIndex + 1}/{totalStep}
                    </p>
                </div>
            </React.Fragment>
        </>
    );
};

export const ConnectedAnimalInfoStep = AnimalInfoStep;
