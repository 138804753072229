import { createSelector } from 'reselect';

import { TrackerType } from '../interfaces';
import { ITracker } from '../interfaces-api';
import { makeGetSelectedTrackerModel } from './get-selected-tracker-model';

export const makeGetSelectedTrackerType = () => {
    const getActiveTrackerModel = makeGetSelectedTrackerModel();

    return createSelector(
        getActiveTrackerModel,
        (tracker: ITracker | null): TrackerType | null => {
            if (tracker) {
                return tracker.type;
            }

            return null;
        },
    );
};
