import React, { HTMLAttributes } from 'react';

interface IProps extends HTMLAttributes<HTMLDivElement> {}

/**
 * TODO: Make mobile version
 */
export const InnerNavigation = ({ children }: IProps) => {
    return <div>{children}</div>;
};
