import { IGeofence } from '../interfaces';
import { GeofenceMode } from '../interfaces-api';
import { IGeofenceDTO } from '../services/geofences.service';

const getMode = (enterNotification: boolean, exitNotification: boolean) => {
    if (enterNotification && exitNotification) {
        return GeofenceMode.ALL_NOTIFICATIONS;
    } else if (enterNotification) {
        return GeofenceMode.ENTER_NOTIFICATIONS;
    }

    return GeofenceMode.EXIT_NOTIFICATIONS;
};

export const mapClientGeofenceToApiDTO = (
    geofence: Pick<
        IGeofence,
        | 'radius'
        | 'outdoors'
        | 'position'
        | 'name'
        | 'entranceNotification'
        | 'exitNotification'
        | 'active'
        | 'address'
    >,
): IGeofenceDTO => ({
    distance: geofence.radius,
    is_outside: geofence.outdoors,
    latitude: geofence.position.lat,
    longitude: geofence.position.lng,
    address: geofence.address,
    name: geofence.name,
    mode: getMode(geofence.entranceNotification, geofence.exitNotification),
    active: geofence.active,
});
