import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { compose } from 'recompose';

import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../utils/with-selected-tracker';
import {
    trackersRoute,
    trackersRouteSelectedWithSubscription,
} from '../../routing/routes';
import { IRootState } from '../../store/store';
import { Theme } from '../../trackers/interfaces';
import { ISubscriptionDetails } from '../../user-subscriptions/interfaces';
import { makeGetSubscriptionDetailsFromTracker } from '../../user-subscriptions/selectors/user-subscriptions.selectors';

interface IStateProps {
    currentSubscription: ISubscriptionDetails | null;
    theme: Theme | null;
}

interface IHOCProps
    extends
    IWithSelectedTracker,
    IStateProps { }

/**
 * This HOC is responsible of checking if a subscription can be canceled
 * and redirect to /tracker/:id/subscription if it can't be.
 */
export const notCancelableSubscriptionGuardRaw = (Component: ComponentType<any>) => ({
    currentSubscription,
    trackerId,
    theme,
    ...props
}: IHOCProps) => {
    const history = useNavigate();
    const match = useLocation()

    useEffect(() => {
        if (!trackerId) {
            return history(trackersRoute);
        }
        if (!currentSubscription || currentSubscription.status !== 'active') {
            if (theme === Theme.BIOGARAN) {
                return history(`/trackers/${trackerId.toString()}/manage${match.search}`);
            }
            return history(
                trackersRouteSelectedWithSubscription.replace(
                    ':id',
                    trackerId.toString(),
                ) + match.search,
            );
        }
    }, [currentSubscription, history, trackerId]);

    return <Component {...props} />;
};

const mapState = (
    state: IRootState,
    props: IWithSelectedTracker,
): IStateProps => {
    if (!props.trackerId) {
        return {
            theme: state.userTrackers.theme,

            currentSubscription: null,
        };
    }
    const getSubscriptionDetails = makeGetSubscriptionDetailsFromTracker(
        props.trackerId,
        true,
    );
    return {
        theme: state.userTrackers.theme,
        currentSubscription: getSubscriptionDetails(
            state,
        ) as ISubscriptionDetails,
    };
};


export const connectedNotCancelableSubscriptionGuard = compose(
    connect(mapState),
    withSelectedTracker,
    notCancelableSubscriptionGuardRaw,
)

export { connectedNotCancelableSubscriptionGuard as notCancelableSubscriptionGuard };
