import moment from 'moment';

import { IRegistrationDetails } from '../../user-subscriptions/interfaces';
import { ITracker } from '../interfaces-api';

export const mapTrackerToRegistrationDetails = (
    tracker: ITracker,
): IRegistrationDetails => ({
    imei: tracker.imei,
    sim: tracker.sim,
    registrationDate: moment(tracker.activation_date).format('D MMM YYYY'),
    warranty: `${moment(tracker.warranty_start).format(
        'D MMM YYYY',
    )} - ${moment(tracker.warranty_end).format('D MMM YYYY')}`,
});
