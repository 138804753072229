import { IRawUserData } from 'app/user/interfaces';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ReactComponent as CreditCardSVG } from '../../../images/creditcard.svg';
import { ReactComponent as IbanSvg } from '../../../images/iban.svg';
import { ReactComponent as WarningIcon } from '../../../images/warning_amber.svg';

import { Headline } from '../../common/headline/headline.component';
import { Dispatch, IRootState } from '../../store/store';
import { IPaymentHistoryItem } from '../interfaces';
import { makeGetAllFormattedPaymentHistoryItems } from '../selectors/account-selectors';
import { PaymentsList } from './payments-list/payments-list.component';
import styles from './user-payments.module.scss';
import { Button } from 'app/common/button/button.component';
import { PaymentCode } from 'app/subscription-process/interfaces';
import { ChangePaymentMean } from './change-payment';
import { Typography } from 'app/common/typography/typography.component';
import { withPaymentRedirectionResolving } from 'app/map/resolvers/payment-redirection-resolver';

interface IActions {
    fetchPayments(): Promise<unknown>;
    fetchUser(): Promise<unknown>;
    preFetchSubscriptions(): Promise<unknown>;
    downloadInvoice(
        id: number,
        type: 'subscription' | 'option',
    ): Promise<unknown>;
}

interface IStateProps {
    payments: IPaymentHistoryItem[];
    user: IRawUserData | null;
}

interface IOuterProps {}

interface IProps extends IActions, IStateProps {}

export const UserPayments = ({
    fetchPayments,
    fetchUser,
    preFetchSubscriptions,
    payments,
    user,
    downloadInvoice,
}: IProps) => {
    const [changePaymentMean, setchangePaymentMean] = useState(false);
    const { t } = useTranslation(['account', 'payment']);

    const paymentT = useTranslation('payment').t;

    const changePaymentMeanT = useTranslation('changePaymentMean').t;
    const formatDate = (dateReceived: string) => {
        let date = new Date(dateReceived);
        const month = date.getMonth() + 1;
        const monthFormatted = month > 9 ? month : '0' + month;
        return `${monthFormatted} / ${date.getFullYear()}`;
    };

    useEffect(() => {
        preFetchSubscriptions().then(() => {
            fetchPayments();
            fetchUser();
        });
    }, [fetchPayments, preFetchSubscriptions]);

    const onInvoiceRequested = (id: number) => {
        const selectedPayment = payments.find((payment) => payment.id === id)!;
        if ('code' in selectedPayment) {
            downloadInvoice(id, 'option');
        } else {
            downloadInvoice(id, 'subscription');
        }
    };

    const renderTrad = () => {
        if (user?.default_payment_mean.payment_mean === PaymentCode.HIPAY) {
            return {
                alias: 'CB  :',
                icon: <CreditCardSVG />,
                paymentRef: user?.default_payment_mean.card_pan?.replaceAll(
                    /x|X/g,
                    '*',
                ),
            };
        }
        return {
            alias: 'IBAN  :',
            icon: <IbanSvg />,
            paymentRef: user?.default_payment_mean.bank_account,
        };
    };

    const renderWarningMessage = (firstText: string, secondText: string) => {
        return (
            <>
                <div className={cx(styles.flexBlock, styles.alignCenter)}>
                    <>
                        <WarningIcon />
                    </>
                    <div style={{ marginLeft: '1rem' }}>
                        <Typography
                            noMargin
                            bold
                            className={styles.colorWarning}
                        >
                            {firstText}
                        </Typography>
                        <Typography noMargin bold>
                            {secondText}
                        </Typography>
                    </div>
                </div>
                <div className={cx(styles.marginTop, styles.marginBottom)}>
                    <Button
                        className={styles.width50}
                        onClick={() => setchangePaymentMean(true)}
                    >
                        {changePaymentMeanT('MODIFY')}
                    </Button>
                </div>
            </>
        );
    };

    const mayRenderWarningText = () => {
        if (user?.default_payment_mean.has_card_expired) {
            return renderWarningMessage(
                paymentT('WARNING_EXPIRACY_CREDIT_CARD'),
                paymentT('EXPIRACY_CREDIT_CARD_TEXT'),
            );
        }
        if (user?.default_payment_mean.has_insufficient_funds) {
            return renderWarningMessage(
                paymentT('WARNING_NOT_ENOUGH_FOUNDS'),
                paymentT('ADD_NEW_PAYMENT_REGULARISE'),
            );
        }
        if (
            (user?.default_payment_mean.count_option_payment_error !== 0 &&
                user?.default_payment_mean.count_option_payment_error !==
                    undefined) ||
            (user?.default_payment_mean.count_subscription_payment_error !==
                0 &&
                user?.default_payment_mean.count_subscription_payment_error !==
                    undefined)
        ) {
            return renderWarningMessage(
                paymentT('WARNING_ERROR'),
                paymentT('ADD_NEW_PAYMENT_REGULARISE'),
            );
        }
        return (
            <div className={cx(styles.marginBottom)}>
                <Button
                    className={styles.buttonModify}
                    onClick={() => setchangePaymentMean(true)}
                >
                    {changePaymentMeanT('MODIFY')}
                </Button>
            </div>
        );
    };

    const mayRenderPaymentField = () => {
        // user.default_payment_mean.id !== 0 means customer has no payment mean
        if (user && user.default_payment_mean.id !== 0) {
            return (
                <>
                    <Headline className={cx(styles.headline, styles.marginTop)}>
                        {changePaymentMeanT('TITLE')}
                    </Headline>
                    <div className={cx(styles.flexBlock, styles.marginLeft)}>
                        <div className={styles.marginRight}>
                            {renderTrad().icon}
                        </div>
                        <div>
                            <Typography bold noMargin>
                                {changePaymentMeanT('DEFAULT_MEAN')}
                            </Typography>
                            <div
                                className={cx(
                                    styles.flexBlock,
                                    styles.marginTop,
                                )}
                                style={{
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    className={styles.marginRight}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        noMargin
                                        style={{ marginRight: '1rem' }}
                                    >
                                        {renderTrad().alias}
                                    </Typography>
                                    {renderTrad().paymentRef}
                                </div>
                                <div
                                    className={
                                        user.default_payment_mean
                                            .has_card_expired
                                            ? styles.colorWarning
                                            : ''
                                    }
                                >
                                    {user?.default_payment_mean.payment_mean ===
                                    PaymentCode.HIPAY
                                        ? formatDate(
                                              user.default_payment_mean
                                                  .card_expiry!,
                                          )
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.flexBlock, styles.margin3rem)}>
                        {changePaymentMeanT('USED_PAYMENT_EXPLANATION')}
                    </div>
                    <div className={cx(styles.marginLeft)}>
                        {mayRenderWarningText()}
                    </div>
                </>
            );
        }
        return (
            <p className={styles.paymentmeanwarning}>
                {paymentT('NO_PAYMENT_MEAN_YET')}
            </p>
        );
    };

    const maybeRenderPaymentMean = () => {
        return (
            <>
                {mayRenderPaymentField()}
                <Headline className={styles.headline}>
                    {t('PAYMENTS.MAIN_HEADLINE')}
                </Headline>
                <Headline className={styles.subheadline} level={4}>
                    {t('PAYMENTS.HISTORY_HEADLINE')}
                </Headline>
                <PaymentsList
                    className={styles.list}
                    payments={payments}
                    onInvoiceRequested={onInvoiceRequested}
                />
            </>
        );
    };

    const renderChangePaymentMean = () => {
        return <ChangePaymentMean goBack={() => setchangePaymentMean(false)} />;
    };

    return (
        <div className={styles.overflow}>
            {changePaymentMean
                ? renderChangePaymentMean()
                : maybeRenderPaymentMean()}
        </div>
    );
};

const mapState = (state: IRootState): IStateProps => {
    const getPayments = makeGetAllFormattedPaymentHistoryItems();

    return {
        user: state.user.userData,
        payments: getPayments(state),
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => {
    return {
        fetchPayments: async () => dispatch.account.fetchUserPayments(),
        fetchUser: async () => dispatch.user.fetchUserData(),
        preFetchSubscriptions: async () =>
            dispatch.userSubscriptions.fetchAllUserSubscriptions(),
        downloadInvoice: async (
            paymentId: number,
            type: 'subscription' | 'option',
        ) =>
            dispatch.account.downloadInvoice({
                id: paymentId,
                type,
            }),
    };
};

export const ConnectedUserPayments = compose<IProps, IOuterProps>(
    connect(mapState, mapDispatch),
    withPaymentRedirectionResolving,
)(UserPayments);
