import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { Toggle } from '../../common/toggle/toggle.component';
import { Typography } from '../../common/typography/typography.component';
import { IAccountOptions } from '../../user/interfaces-api';

import styles from './sms-notification.module.scss';

interface ISmsNotificationProps extends HTMLAttributes<HTMLDivElement> {
    isChecked: boolean;
    className: string;
    handleChange: any;
    accountOption: IAccountOptions[] | null | undefined;
    sendValue: (name: string) => void;
    setOpenPopup: () => void;
    name: string;
    title: string;
}
interface IProps extends ISmsNotificationProps { }

export const SmsNotification = ({
    className,
    isChecked,
    sendValue,
    setOpenPopup,
    accountOption,
    name,
    title,
}: IProps) => {
    const { t } = useTranslation(['trackerSettings']);

    const maybeRenderOptionWarning = () => {
        if (
            !accountOption ||
            (!accountOption[0]?.is_running &&
                accountOption[0]?.activated &&
                isChecked)
        ) {
            return (
                <div className={styles.warning}>
                    <span>{t('NOTIFICATION_SETTINGS.WARNING.SMS.TEXT')}</span>
                    <span
                        className={styles.link}
                        onClick={() => setOpenPopup()}
                    >
                        {t('NOTIFICATION_SETTINGS.WARNING.SMS.LINK')}
                    </span>
                </div>
            );
        }
    };
    return (
        <div
            className={cx(className, {
                [styles['has-warning']]:
                    !accountOption ||
                    (!accountOption[0]?.is_running && isChecked),
            })}
        >
            <Typography noMargin>{title}</Typography>
            <Toggle
                checked={isChecked}
                onChange={() => {
                    if (accountOption && accountOption[0]?.is_running) {
                        return sendValue(name);
                    }
                    setOpenPopup();
                }}
                name={name}
                isError={
                    !accountOption ||
                    (!accountOption[0]?.is_running && isChecked)
                }
            />

            {maybeRenderOptionWarning()}
        </div>
    );
};

export const ConnectedSmsNotification = compose<
    IProps,
    ISmsNotificationProps
>()(SmsNotification);
