import { createSelector } from 'reselect';

import { TrackerType } from '../interfaces';
import { ITracker } from '../interfaces-api';
import { makeGetTrackerModel } from './get-tracker-model';

export const makeGetIsTrackerAnimalType = (trackerId: number) => {
    const getTrackerModel = makeGetTrackerModel(trackerId);

    const animalTypes = [
        TrackerType.ANIMAL,
        TrackerType.ANIMALCAT,
        TrackerType.ANIMALDOG,
        TrackerType.CAT,
        TrackerType.CAT2,
        TrackerType.PET,
        TrackerType.PET2,
    ];

    return createSelector(
        getTrackerModel,
        (t: ITracker | undefined): boolean => {
            if (!t) {
                return false;
            }

            return animalTypes.includes(t.type as TrackerType);
        },
    );
};
