import cx from 'classnames';
import React, {
    HTMLAttributes,
    SyntheticEvent,
    useEffect,
    useState,
} from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getGlobalT } from '../../../../i18n/setup-translations';
import { ReactComponent as FlashTurnOffIcon } from '../../../icons/Flash-turn-off.svg';
import { ReactComponent as FlashTurnOnIcon } from '../../../icons/Flash-turn-on.svg';
import { ReactComponent as RingIcon } from '../../../icons/music.svg';
import { ReactComponent as PhoneCallIcon } from '../../../icons/phone-call.svg';
import { ReactComponent as VibrateIcon } from '../../../icons/vibration.svg';
import { MapFloatingButton } from '../../../map/components/map-floating-button/map-floating-button.component';
import { INotification } from '../../../notifications/interfaces';
import { Dispatch } from '../../../store/store';
import { IWifiZone } from '../../../wifi-zones/interfaces';
import { IMakeVibrateCallRingResponse } from '../../interfaces-api';
import styles from './tracker-actions.module.scss';

interface IActions {
    showNotification: (notification: INotification) => any;
    onCallTriggered(id: number): any;
    onRingTriggered(id: number): any;
    onVibrateTriggered(id: number): any;
    onFlashTriggered(payload: any): any;
}

interface IProps extends HTMLAttributes<HTMLDivElement> {
    activeWifi?: boolean;
    openWifiPopup: any;
    trackerId: number;
    hasCall: boolean;
    hasRing: boolean;
    hasVibrate: boolean;
    disabled: boolean | null;
    hasFlash: boolean;
}

export type ITrackerActionsProps = IActions & IProps;

const runWithoutPropagation = (fun: () => any) => (e: SyntheticEvent) => {
    e.stopPropagation();
    fun();
};

export const TrackerActions = ({
    activeWifi,
    openWifiPopup,
    onCallTriggered,
    onRingTriggered,
    onVibrateTriggered,
    onFlashTriggered,
    showNotification,
    disabled,
    className,
    trackerId,
    hasCall,
    hasRing,
    hasVibrate,
    hasFlash,
    ...props
}: ITrackerActionsProps) => {
    const t = getGlobalT() as any;
    const saveFlashTime = (Id: number) => {
        localStorage.setItem(`flash-mode-time-${Id}`, `${Date.now()}`);
    };

    /**
     * Returns the cookie value of the flash modes or -1 if there is none
     * @params {id}: currentTracker Id
     */
    const getTimeFlash = (Id: number): number => {
        const timeFlash = localStorage.getItem(`flash-mode-time-${Id}`);

        if (!timeFlash) {
            return -1;
        }

        return parseInt(timeFlash, 10);
    };

    /**
     * Returns what the state of the flash button should be, depending on the flash's cookie value
     * @params {id}: currentTracker Id
     */
    const getPassedFlashModeSeconds = (Id: number): boolean => {
        const savedAt = getTimeFlash(Id);

        if (savedAt === -1) {
            return false;
        }

        const diffInMs = Date.now() - savedAt;

        if (diffInMs / 1000 > 60) {
            return false;
        }
        return true;
    };

    const [flashActive, setFlashActive] = useState(
        getPassedFlashModeSeconds(trackerId),
    );

    /**
     * Determines how long the flash will be active and corrects the state of the button depending on that value
     */
    useEffect(() => {
        if (flashActive) {
            const remainingTimeDiff =
                (Date.now() - getTimeFlash(trackerId)) / 1000;
            setTimeout(setFlashActive, (60 - remainingTimeDiff) * 1000, false);
        }
    }, [flashActive]);

    const flashTrigger = () => {
        let params = {
            tracker_Id: trackerId,
            duration_ms_on: 0,
            duration_ms_off: 0,
        };
        if (!flashActive) {
            params = {
                tracker_Id: trackerId,
                duration_ms_on: 100,
                duration_ms_off: 100,
            };
            saveFlashTime(trackerId);
        }
        return onFlashTriggered(params);
    };

    const maybeRenderCallButton = () => {
        if (hasCall) {
            return (
                <MapFloatingButton
                    className={styles.floating}
                    isLockedDuToWifi={disabled}
                    description={t('trackerLeftPanel:CALL')}
                    onClick={runWithoutPropagation(() =>
                        disabled
                            ? openWifiPopup()
                            : onCallTriggered(trackerId)
                                .then(
                                    (resp: IMakeVibrateCallRingResponse) => {
                                        if (resp.status === 204) {
                                            showNotification({
                                                content: t(
                                                    'activity:EVENTS.CALL_REQ',
                                                ),
                                                type: 'success',
                                            });
                                        }
                                    },
                                )
                                .catch(() => {
                                    showNotification({
                                        content: t('errors:GENERAL'),
                                        type: 'error',
                                    });
                                }),
                    )}
                >
                    <PhoneCallIcon className={styles.icon} />
                </MapFloatingButton>
            );
        }
    };

    const maybeRenderRingButton = () => {
        if (hasRing) {
            return (
                <MapFloatingButton
                    className={styles.floating}
                    isLockedDuToWifi={disabled}
                    description={t('trackerLeftPanel:RING')}
                    onClick={runWithoutPropagation(() =>
                        disabled
                            ? openWifiPopup()
                            : onRingTriggered(trackerId)
                                .then(
                                    (resp: IMakeVibrateCallRingResponse) => {
                                        if (resp.status === 204) {
                                            showNotification({
                                                content: t(
                                                    'activity:EVENTS.RING_REQ',
                                                ),
                                                type: 'success',
                                            });
                                        }
                                    },
                                )
                                .catch(() => {
                                    showNotification({
                                        content: t('errors:GENERAL'),
                                        type: 'error',
                                    });
                                }),
                    )}
                >
                    <RingIcon className={styles.icon} />
                </MapFloatingButton>
            );
        }
    };

    const maybeRenderVibrateButton = () => {
        if (hasVibrate) {
            return (
                <MapFloatingButton
                    className={styles.floating}
                    biggerIcon
                    isLockedDuToWifi={disabled}
                    description={t('trackerLeftPanel:VIBRATE')}
                    onClick={runWithoutPropagation(() =>
                        disabled
                            ? openWifiPopup()
                            : onVibrateTriggered(trackerId)
                                .then(
                                    (resp: IMakeVibrateCallRingResponse) => {
                                        if (resp.status === 204) {
                                            showNotification({
                                                content: t(
                                                    'activity:EVENTS.VIBRATE_REQ',
                                                ),
                                                type: 'success',
                                            });
                                        }
                                    },
                                )
                                .catch(() => {
                                    showNotification({
                                        content: t('errors:GENERAL'),
                                        type: 'error',
                                    });
                                }),
                    )}
                >
                    <VibrateIcon className={styles.icon} />
                </MapFloatingButton>
            );
        }
    };

    const maybeRenderFlashButton = () => {
        if (hasFlash) {
            return (
                <MapFloatingButton
                    className={styles.floating}
                    isLockedDuToWifi={disabled}
                    biggerIcon
                    description={t('trackerLeftPanel:FLASH')}
                    onClick={runWithoutPropagation(() =>
                        disabled
                            ? openWifiPopup()
                            : flashTrigger()
                                .then(
                                    (resp: IMakeVibrateCallRingResponse) => {
                                        if (
                                            resp.status === 204 &&
                                            !flashActive
                                        ) {
                                            showNotification({
                                                content: t(
                                                    'activity:EVENTS.FLASH_REQ',
                                                ),
                                                type: 'success',
                                            });
                                        }
                                        setFlashActive(!flashActive);
                                    },
                                )
                                .catch(() => {
                                    showNotification({
                                        content: t('errors:GENERAL'),
                                        type: 'error',
                                    });
                                }),
                    )}
                >
                    {flashActive ? (
                        <FlashTurnOffIcon className={styles.icon} />
                    ) : (
                        <FlashTurnOnIcon className={styles.icon} />
                    )}
                </MapFloatingButton>
            );
        }
    };

    /**
     * Doesn't render itself if no actions possible
     */
    if (!hasVibrate && !hasRing && !hasCall) {
        return null;
    }

    return (
        <div className={cx(styles['tracker-actions'], className)} {...props}>
            {maybeRenderRingButton()}
            {maybeRenderVibrateButton()}
            {maybeRenderFlashButton()}
            {maybeRenderCallButton()}
        </div>
    );
};

const mapDispatch = (dispatch: Dispatch, ownProps: IProps): IActions => ({
    showNotification: dispatch.notifications.showNotification,
    onCallTriggered: (id: number) =>
        dispatch.userTrackers.makeTrackerCall(id as any),
    onRingTriggered: (id: number) =>
        dispatch.userTrackers.makeTrackerRing(id as any),
    onVibrateTriggered: (id: number) =>
        dispatch.userTrackers.makeTrackerVibrate(id as any),
    onFlashTriggered: (payload: any) =>
        dispatch.userTrackers.makeTrackerFlash(payload as any),
});

export const ConnectedTrackerActions = compose<ITrackerActionsProps, IProps>(
    connect(null, mapDispatch),
)(TrackerActions);
