import { get } from 'lodash';
import { createSelector } from 'reselect';
import { makeGetTrackerModel } from './get-tracker-model';

export const makeGetSubscriptionIdFromTracker = (trackerId: number) => {
    const getTracker = makeGetTrackerModel(trackerId);

    return createSelector(getTracker, (tracker): number =>
        //@ts-ignore
        get(tracker, 'subscription.id', null),
    );
};
