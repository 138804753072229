/**
 * In backend its "Levy".
 * This should be compared with user IP to conditionally show transfer payment
 */
export const bankTransferAvailableCountries = [
    'AT',
    'BE',
    'CY',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'IE',
    'IT',
    'LV',
    'LU',
    'MT',
    'MC',
    'NL',
    'PT',
    'SM',
    'SK',
    'SI',
    'ES',
    'GB',
];
