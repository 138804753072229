import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/button/button.component';

import { Headline } from '../../../common/headline/headline.component';
import { IModalProps, Modal } from '../../../common/modal/modal.component';
import { Typography } from '../../../common/typography/typography.component';
import { Grid } from '../../../layout/grid/grid.component';
import { INotification } from '../../../notifications/interfaces';
import { URLPictureAttribute } from '../../../trackers/interfaces';
import { ITracker } from '../../../trackers/interfaces-api';
import { fetchTracker } from '../../../trackers/trackers.service';
import { getIcons } from '../get-icons';
import { IconSelect } from '../icon-select/icon-select.component';

import { getTrackersAvailableColors } from '../tracker-colors';

import styles from './tracker-icon-modal.module.scss';

interface IProps extends IModalProps {
    tracker: ITracker;
    initialColor: string;
    initialIcon: string;
    showNotification: (notification: INotification) => Promise<unknown>;
    updateTracker: (trackerId: number) => Promise<any>;
    onSave(id: string, color: string): unknown;
}

const availableIcons = getIcons();
const availableColors = getTrackersAvailableColors();

export const TrackerIconModal = ({
    onClosed,
    tracker,
    showNotification,
    updateTracker,
    onSave,
    initialColor,
    initialIcon,
}: IProps) => {
    const [selectedIcon, setSelectedIcon] = useState(initialIcon);
    const [selectedColor, setSelectedColor] = useState(initialColor);
    const { t } = useTranslation(['trackerSettings, commonActions']);
    return (
        <Modal onClosed={onClosed}>
            <Headline center level={2}>
                {t('trackerSettings:DISPLAY.MODAL.HEADLINE', {
                    tracker: tracker.name,
                })}
            </Headline>
            <Typography center>
                {t('trackerSettings:DISPLAY.MODAL.TEXT')}
            </Typography>
            <IconSelect
                showNotification={showNotification}
                trackerId={tracker.id}
                className={styles.list}
                icons={availableIcons}
                urlPicture={tracker.url_picture}
                colors={availableColors}
                activeIconId={selectedIcon}
                activeColor={selectedColor}
                onIconSelected={(id, color) => {
                    setSelectedColor(color);
                    setSelectedIcon(id);
                }}
            />
            <Grid>
                <Button
                    onClick={() => {
                        if (selectedIcon === URLPictureAttribute) {
                            updateTracker(tracker.id).then(() => {
                                return onClosed;
                            });
                        }
                        onSave(selectedIcon, selectedColor);
                    }}
                >
                    {t('commonActions:UPDATE')}
                </Button>
                <Button onClick={onClosed} secondary>
                    {t('commonActions:CANCEL')}
                </Button>
            </Grid>
        </Modal>
    );
};
