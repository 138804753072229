import L, { LatLng } from 'leaflet';
import { isEqual } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Pane, Polyline } from 'react-leaflet';

import { colors } from '../../../../styles/colors';
import { ConnectedMapMarker } from '../../../map/components/map-marker/map-marker.component';
import { ConnectedTrackerMarker } from '../../../map/components/tracker-marker/tracker-marker.component';
import { ITrackerEvent } from '../../../trackers/interfaces-api';
import { getMarkerOpacity, getVectorOpacity } from '../utils';
import { IActivityPathProps } from './interface';

export const LeafletActivityPath = ({
    activeTracker,
    events,
    lastEvent,
    leaflet,
    vectors,
    displayEventTooltip,
}: IActivityPathProps) => {
    const previousPoints = useRef<ITrackerEvent[]>();
    const paneStyles = {
        zIndex: 600,
    };

    /**
     * Checks points provided and sets map bounds during first render.
     * Checks equality with previous points to make sure it happens only once
     */
    useEffect(() => {
        if (
            leaflet &&
            (leaflet as any).leafletElement &&
            events &&
            !isEqual(previousPoints.current, events)
        ) {
            const pointsForBounds = events.map(
                (p) => new LatLng(p.latitude, p.longitude),
            );
            if (pointsForBounds.length < 2) {
                return;
            }

            const bounds = L.latLngBounds(pointsForBounds);

            // Below, maxZoom need to be set to fix a bug when bounds is a pair of same coordinates
            // https://github.com/Leaflet/Leaflet/issues/5153
            (leaflet as any).leafletElement.fitBounds(bounds, {
                padding: [30, 30],
                maxZoom: 18,
            });

            previousPoints.current = events;
        }
    }, [leaflet, events]);

    const drawVectors = () =>
        vectors.map((v: number[][], i: number) => {
            const start = v[0];
            const end = v[1];

            return (
                <Polyline
                    key={i + start[0]}
                    dashArray={[1, 20]}
                    weight={10}
                    opacity={getVectorOpacity(i, vectors)}
                    color={colors.colorFlamingo}
                    smoothFactor={1}
                    positions={[start, end] as any}
                />
            );
        });

    const drawMarkers = () =>
        events.slice(0, -1).map((event, i) => {
            const eventPos = [event.latitude, event.longitude] as [
                number,
                number,
            ];
            const tooltipChild = displayEventTooltip(event, activeTracker);

            return (
                <ConnectedMapMarker
                    zIndex={true}
                    opacity={getMarkerOpacity(i, events)}
                    key={event.id}
                    position={eventPos}
                    tooltipChild={tooltipChild}
                />
            );
        });

    const maybeDrawTrackerMarker = () => {
        if (activeTracker) {
            const lastPos = [lastEvent.latitude, lastEvent.longitude] as [
                number,
                number,
            ];
            return (
                <ConnectedTrackerMarker
                    iconUrl={activeTracker.urlPicture}
                    iconName={activeTracker.icon}
                    position={lastPos}
                    trackerType={activeTracker.type}
                    trackerColor={activeTracker.color}
                    validGps={true}
                />
            );
        }
    };

    return (
        <Pane name="activity-events" style={paneStyles}>
            {drawMarkers()}

            {drawVectors()}

            {maybeDrawTrackerMarker()}
        </Pane>
    );
};
