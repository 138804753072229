import { get } from 'lodash';
import { createSelector } from 'reselect';

import { makeGetTrackerModel } from './get-tracker-model';

export const makeGetIsTrackerOnline = (trackerId: number) => {
    const getTrackerModel = makeGetTrackerModel(trackerId);

    return createSelector(
        getTrackerModel,
        (model) => get(model, 'position[0].is_online', false),
    );
};
