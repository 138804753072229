import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useNavigate } from 'react-router-dom';

import {
    formatPrice,
    normalizePriceAmount,
} from '../../../utils/normalize-price-amount';
import { formatDate } from '../../../utils/time-formatters';
import { Modal } from '../../common/modal/modal.component';
import { Typography } from '../../common/typography/typography.component';
import { changePaymentMeanRoute } from '../../routing/routes';
import { Dispatch, IRootState } from '../../store/store';
import { makeGetSelectedTrackerId } from '../../trackers/selectors/get-selected-tracker-id';
import { makeGetFirstActiveSubscription } from '../selectors/user-subscriptions.selectors';
import { IRawUserData } from '../../user/interfaces';
import {
    IAccountBody,
    IAccountOptionOffers,
    IAccountOptions,
} from '../../user/interfaces-api';
import {
    makeGetAccountOptionPremiumPack,
    makeGetAccountOptions,
    makeGetAccountOptionsOffers,
    makeGetAccountOptionsOffersForView,
} from '../../user/selectors/account-option-selector';
import { putAccountOption } from '../../user/user.service';
import { ISubscriptionOptionData } from '../interfaces';
import { SubscriptionOptionType } from '../interfaces-api';
import { CancelOptionConfirmation } from './cancel-option-confirmation/cancel-option-confirmation.component';
import { OptionCardExpired } from './option-card-expired/option-card-expired.component';
import {
    PremiumPackOption,
    WarrantyOption,
} from './subscription-option/subscription-option.component';
import styles from './subscription-options.module.scss';

interface IOwnProps {
    options: ISubscriptionOptionData[];
    subscriptionId: number;
}
interface IOptionFormated {
    code: SubscriptionOptionType;
    name: string;
    expirationDate?: string;
}

interface IStateProps {
    activeTrackerId: number | null;
    userHasErrorPayement: boolean | undefined;
    mapOptionIsActive: boolean;
    accountOptions: any;
    accountOptionTemplate: any;
    user: IRawUserData | null;
}

interface IActions {
    toggleOption: (option: SubscriptionOptionType) => Promise<any>;
    setUserData: (user: IRawUserData) => Promise<unknown>;
}

export interface ISubscriptionOptionsProps
    extends HTMLAttributes<HTMLDivElement>,
        IOwnProps,
        IStateProps,
        IActions {}

/**
 * TODO: Move options to reselect mapper
 */
export const SubscriptionOptions = ({
    accountOptions,
    activeTrackerId,
    userHasErrorPayement,
    accountOptionTemplate,
    options,
    user,
    setUserData,
    toggleOption,
}: ISubscriptionOptionsProps) => {
    const defaultStateMap = options.reduce((data, next) => {
        data[next.id] = false;

        return data;
    }, {} as any);
    const { t } = useTranslation('manageSubscription');
    const history = useNavigate();
    let formatPricePremiumPack;
    if (accountOptionTemplate) {
        formatPricePremiumPack = formatPrice(
            normalizePriceAmount(
                accountOptionTemplate[0].price_offer.fr.amount,
            ),
            accountOptionTemplate[0].price_offer.fr.currency,
        );
    }

    useEffect(() => {
        setIsPremiumPackActive(accountOptions[0]?.activated);
    }, [accountOptions[0]]);

    const [isPremiumPackActive, setIsPremiumPackActive] = useState<
        boolean | undefined
    >(accountOptions[0]?.activated);

    const [optionsOpenMap, setOptionOpen] = useState<{
        [key: number]: boolean;
    }>(defaultStateMap);

    const [idOfOptionDuringCancel, setIdOfOptionDuringCancel] =
        useState<SubscriptionOptionType | null>(null);

    const [shouldDisplayChangeCardModal, setDisplayChangeCardModalOpen] =
        useState(false);

    const toggleOpen = (id: number) => {
        const isOptionOpen = optionsOpenMap[id];
        const newState = { ...optionsOpenMap };

        newState[id] = !isOptionOpen;

        setOptionOpen(newState);
    };
    const maySwitchAccountOption = (accountOptions: string[]) => {
        if (!user) {
            return;
        }
        putAccountOption({
            account_options: accountOptions,
            site: user.site,
        }).then((res: IAccountOptions[]) => {
            Object.assign(user.account_options, res);
            return setUserData(user);
        });
    };

    const onActivated = (code: SubscriptionOptionType) => {
        // Otherwise, we can simply toggle the option.
        if (code === SubscriptionOptionType.PREMIUMPACK && user) {
            if (userHasErrorPayement) {
                return setDisplayChangeCardModalOpen(true);
            }
            return maySwitchAccountOption([SubscriptionOptionType.PREMIUMPACK]);
        }
        toggleOption(code).then((ret) => {
            setDisplayChangeCardModalOpen(false);
            if (ret === false) {
                setDisplayChangeCardModalOpen(true);
            }
        });
    };

    const onDeactivated = (code: SubscriptionOptionType) => {
        setIdOfOptionDuringCancel(code);
        if (code === SubscriptionOptionType.PREMIUMPACK) {
            if (!user) {
                return;
            }
        }
        setIdOfOptionDuringCancel(code);
    };
    const maybeRenderDeactivationModal = () => {
        let premiumOptModel;
        if (accountOptions) {
            premiumOptModel = {
                code: SubscriptionOptionType.PREMIUMPACK,
                name: t('subOptions:PREMIUM.BASE'),
                expirationDate: formatDate(accountOptions?.next_charge_at),
            };
        }
        if (idOfOptionDuringCancel) {
            let optionModel:
                | ISubscriptionOptionData
                | undefined
                | IOptionFormated = options.find(
                (opt) => opt.code === idOfOptionDuringCancel,
            );
            if (!optionModel && premiumOptModel) {
                optionModel = premiumOptModel;
            }
            if (!optionModel) {
                return null;
            }
            return (
                <Modal onClosed={() => setIdOfOptionDuringCancel(null)}>
                    <CancelOptionConfirmation
                        code={optionModel.code}
                        name={optionModel.name}
                        expirationDate={optionModel.expirationDate}
                        onAbort={() => setIdOfOptionDuringCancel(null)}
                        onConfirm={() => {
                            if (
                                idOfOptionDuringCancel ===
                                SubscriptionOptionType.PREMIUMPACK
                            ) {
                                setIdOfOptionDuringCancel(null);
                                maySwitchAccountOption([]);
                            } else {
                                setIdOfOptionDuringCancel(null);
                                toggleOption(idOfOptionDuringCancel);
                            }
                        }}
                    />
                </Modal>
            );
        }
    };

    const maybeRenderCardExpiredModal = () => {
        if (shouldDisplayChangeCardModal) {
            return (
                <Modal
                    onClosed={() => setDisplayChangeCardModalOpen(false)}
                    className={styles.cardExpiredModal}
                >
                    <OptionCardExpired
                        trackerId={activeTrackerId}
                        onAbort={() => setDisplayChangeCardModalOpen(false)}
                        onConfirm={() => {
                            if (activeTrackerId) {
                                history(
                                    changePaymentMeanRoute.replace(
                                        ':id',
                                        activeTrackerId.toString(),
                                    ),
                                );
                            }
                        }}
                    />
                </Modal>
            );
        }
    };

    return (
        <div className={styles.container}>
            {formatPricePremiumPack && accountOptionTemplate ? (
                <PremiumPackOption
                    onActivated={() =>
                        onActivated(SubscriptionOptionType.PREMIUMPACK)
                    }
                    onDeactivated={() =>
                        onDeactivated(SubscriptionOptionType.PREMIUMPACK)
                    }
                    className={styles.option}
                    key={accountOptionTemplate.code}
                    price={formatPricePremiumPack}
                    isActive={isPremiumPackActive!}
                    expanded={optionsOpenMap[accountOptionTemplate.id]}
                    onExpand={() => toggleOpen(accountOptionTemplate.id)}
                />
            ) : null}
            {options.map((option) => {
                switch (option.code) {
                    case SubscriptionOptionType.WARRANTY:
                        return (
                            <WarrantyOption
                                onActivated={() =>
                                    onActivated(SubscriptionOptionType.WARRANTY)
                                }
                                onDeactivated={() =>
                                    onDeactivated(
                                        SubscriptionOptionType.WARRANTY,
                                    )
                                }
                                className={styles.option}
                                key={option.id}
                                price={option.price}
                                isActive={option.activated}
                                expanded={optionsOpenMap[option.id]}
                                onExpand={() => toggleOpen(option.id)}
                            />
                        );
                    default:
                        return null;
                }
            })}
            {maybeRenderDeactivationModal()}
            {maybeRenderCardExpiredModal()}
        </div>
    );
};

const mapState = (state: IRootState): IStateProps => {
    const getAccountOptions = makeGetAccountOptions();
    const getAccountOptionTemplate = makeGetAccountOptionsOffers();
    const getSelectedTrackerId = makeGetSelectedTrackerId();
    const getActiveSub = makeGetFirstActiveSubscription();
    const activeSub = getActiveSub(state);
    return {
        activeTrackerId: getSelectedTrackerId(state),
        userHasErrorPayement: activeSub?.is_cancelled_because_of_payment_error,
        mapOptionIsActive: state.userSubscriptions.hasMapOptionActivated,
        accountOptions: getAccountOptions(state),
        accountOptionTemplate: getAccountOptionTemplate(state),
        user: state.user.userData,
    };
};

// TODO Remove props from mapDispatch to avoid useless re-render.
const mapDispatch = (dispatch: Dispatch, props: IOwnProps): IActions => ({
    toggleOption: async (option: SubscriptionOptionType) =>
        dispatch.userSubscriptions.toggleSubscriptionOption({
            option,
            subscriptionId: props.subscriptionId,
        }),
    setUserData: async (data: IRawUserData) => {
        dispatch.user.setUserData(data);
        return data;
    },
});

export const ConnectedSubscriptionOptions = compose<
    ISubscriptionOptionsProps,
    IOwnProps
>(connect(mapState, mapDispatch))(SubscriptionOptions);
