/**
 * Copied from
 * https://github.com/Sylvain59650/format-validator
 */

function isLuhn(input: string, modulo = 10) {
    let sum = 0;
    let tmp;
    let multiply = false;
    for (let i = input.length - 1; i >= 0; i--) {
        tmp = parseInt(input.charAt(i), 10);
        if (multiply && tmp !== 0) {
            tmp *= 2;
            if (tmp > 9) {
                tmp -= 9;
            }
        }
        multiply = !multiply;
        sum += tmp;
    }
    return sum % modulo === 0;
}

function areDigits(input: string) {
    return /^\d+$/.test(input);
}

export function isIMEI(input: string = '') {
    return input.length === 15 && areDigits(input) && isLuhn(input);
}
