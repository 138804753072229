import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Headline } from '../../common/headline/headline.component';
import { INotification } from '../../notifications/interfaces';
import { Dispatch } from '../../store/store';
import {
    ControlledPasswordChangeForm,
    IPasswordChangeFormValues,
} from './password-change-form/password-change-form.component';

import styles from './password-change.module.scss';

interface IActions {
    clearNotifications(): unknown;
    updatePassword(oldPassword: string, newPassword: string): Promise<unknown>;
    showNotification(n: INotification): unknown;
}

interface IProps extends IActions {}

export const PasswordChange = ({
    clearNotifications,
    showNotification,
    updatePassword,
}: IProps) => {
    const { t } = useTranslation('account');

    const onSubmit = async (v: IPasswordChangeFormValues) => {
        clearNotifications();

        updatePassword(v.oldPassword, v.newPassword)
            .then(() => {
                showNotification({
                    type: 'success',
                    content: t('PASSWORD.MESSAGES.UPDATE_SUCCESS'),
                });
            })
            .catch((err) => {
                if (err.status === 400) {
                    showNotification({
                        type: 'error',
                        content: t(
                            'PASSWORD.MESSAGES.UPDATE_ERROR_WRONG_PASSWORD',
                        ),
                    });
                } else {
                    showNotification({
                        type: 'error',
                        content: t('PASSWORD.MESSAGES.UPDATE_ERROR_UNKNOWN'),
                    });
                }
            });
    };

    useEffect(() => {
        return () => {
            /**
             * Clear notifications on unmount
             */
            clearNotifications();
        };
    });

    return (
        <div className={styles.wrapper}>
            <Headline className={styles.headline}>
                {t('PASSWORD.HEADLINE')}
            </Headline>
            <ControlledPasswordChangeForm onSubmit={onSubmit} />
        </div>
    );
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    showNotification: dispatch.notifications.showNotification,
    clearNotifications: dispatch.notifications.clearNotifications,
    updatePassword: async (oldPassword: string, newPassword: string) =>
        dispatch.account.updatePassword({
            newPassword,
            oldPassword,
        }),
});

export const ConnectedPasswordChange = compose<IProps, {}>(
    connect(null, mapDispatch),
)(PasswordChange);
