import { ModelConfig } from '@rematch/core';
import { IRegisterUserPayload, registerUser } from './signup.service';

export const signupStore: ModelConfig<{}> = {
    state: {},
    effects: (dispatch) => ({
        async registerUser(payload: IRegisterUserPayload) {
            return registerUser(payload);
        },
    }),
};
