import { createSelector } from 'reselect';

import { ITracker } from '../interfaces-api';
import { makeGetSelectedTrackerModel } from './get-selected-tracker-model';

export const makeGetSelectedTrackerName = () => {
    const getActiveTrackerModel = makeGetSelectedTrackerModel();

    return createSelector(getActiveTrackerModel, (tracker: ITracker | null):
        | string
        | null => {
        if (tracker) {
            return tracker.name;
        }

        return null;
    });
};

export const makeGetSelectedRegularTrackerName = () => {
    const getActiveTrackerModel = makeGetSelectedTrackerModel();

    return createSelector(getActiveTrackerModel, (tracker: ITracker | null):
        | string
        | null => {
        if (tracker) {
            return tracker.name;
        }

        return null;
    });
};
