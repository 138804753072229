/**
 * Server will accept any matching period in seconds and minutes.
 * These values correspond with view and live mode (10S)
 */
export type Frequency = '10S' | '30S' | '5M' | '10M' | '30M' | '60M';

export enum sosMode {
    DIRECT = 'direct',
    FULL_SOS = 'full-sos',
}
export interface INotificationSettingsDTO {
    button_appli_notification: boolean;
    button_mail_notification: boolean;
    button_sms_notification: boolean;
    area_appli_notification: boolean;
    area_mail_notification: boolean;
    area_sms_notification: boolean;
    sos_appli_notification: boolean;
    sos_sms_notification: boolean;
    sos_mail_notification: boolean;
    battery_appli_notification: boolean;
    battery_sms_notification: boolean;
    battery_mail_notification: boolean;
}

export type NotificationSetting = keyof INotificationSettingsDTO;
