import { getUserCountryCode } from '../../user/get-user-country';
import { config as appConfig } from '../../../config';
import { getGlobalT } from '../../../i18n/setup-translations';
import { apiService, timeoutPromise } from '../../api/api.service';
import { IGraphhoperProps } from '../geofence-geocode/geofence-geocode.component';
import {
    GeofenceMode,
    IRawGeofence,
    IRawGeofenceResponse,
} from '../interfaces-api';

const t = getGlobalT();

export interface IGeofenceDTO {
    name: string;
    latitude: number;
    longitude: number;
    mode: GeofenceMode;
    distance: number; // Radius
    is_outside: boolean;
    active: boolean;
    address: string;
}

export interface IGraphhopperResponse {
    hits: IGraphhoperProps[];
    took: number;
}

export interface IGraphhopperReverseGeocodeResponse {
    hits: IGraphhoperProps[];
    took: number;
}

export const fetchGeofences = (
    trackerId: number,
): Promise<IRawGeofenceResponse> => {
    const getUrl = () => `/mytracker/${trackerId}/zones`;

    return apiService.fetch(getUrl()).then((resp) => resp.json());
};

export const fetchSingleGeofence = (
    trackerId: number,
    geofenceId: number,
): Promise<IRawGeofence> =>
    apiService
        .fetch(`/mytracker/${trackerId}/zones/${geofenceId}`)
        .then((resp) => resp.json());

export const createGeofence = (
    trackerId: number,
    dto: IGeofenceDTO,
): Promise<IRawGeofence> =>
    apiService
        .fetch(`/mytracker/${trackerId}/zones`, {
            method: 'POST',
            body: JSON.stringify(dto),
        })
        .then((resp) => resp.json());

export const updateGeofence = (
    trackerId: number,
    geofenceId: number,
    dto: IGeofenceDTO,
): Promise<IRawGeofence> =>
    apiService
        .fetch(`/mytracker/${trackerId}/zones/${geofenceId}`, {
            method: 'PUT',
            body: JSON.stringify(dto),
        })
        .then((resp) => resp.json());

export const deleteGeofence = (trackerId: number, geofenceId: number) =>
    apiService.fetch(`/mytracker/${trackerId}/zones/${geofenceId}`, {
        method: 'DELETE',
    });

export const geocode = (userInput: string): Promise<IGraphhopperResponse> => {
    const graphhopperApiKey = appConfig.GRAPHHOPPER_API_KEY;
    const local = (getUserCountryCode() || 'en').toLowerCase();
    const url =
        `https://graphhopper.com/api/1/geocode?key=${graphhopperApiKey}` +
        `&local=${local}` +
        `&q=${userInput}` +
        `&limit=5`;
    return fetch(url, {
        method: 'GET',
    }).then((addresses) => addresses.json());
};

export const reverseGeocode = async (
    latitude: number,
    longitude: number,
): Promise<string> => {
    const graphhopperApiKey = appConfig.GRAPHHOPPER_API_KEY;
    const local = (getUserCountryCode() || 'en').toLowerCase();
    const url =
        `https://graphhopper.com/api/1/geocode?key=${graphhopperApiKey}` +
        `&local=${local}` +
        `&point=${latitude},${longitude}` +
        `&reverse=true`;

    const geocodedAddress: Promise<string> = fetch(url, {
        method: 'GET',
    }).then(
        (response: Response): Promise<string> => {
            const returnValue: Promise<string> = response.json().then(
                (
                    result: IGraphhopperReverseGeocodeResponse,
                ): Promise<string> | string => {
                    let address = '';
                    const addresses = result.hits;
                    if (addresses.length === 0) {
                        return t('geofences.EDIT.UNKNOWN_ADDRESS');
                    }

                    if (
                        addresses[0].hasOwnProperty('housenumber') &&
                        addresses[0].housenumber
                    ) {
                        address += addresses[0].housenumber + ' ';
                    }
                    if (
                        addresses[0].hasOwnProperty('street') &&
                        addresses[0].street
                    ) {
                        address += addresses[0].street + ' ';
                    }
                    if (
                        !address &&
                        addresses[0].hasOwnProperty('name') &&
                        addresses[0].name
                    ) {
                        address += addresses[0].name + ' ';
                    }
                    if (
                        addresses[0].hasOwnProperty('postcode') &&
                        addresses[0].postcode
                    ) {
                        address += addresses[0].postcode + ' ';
                    }
                    if (
                        addresses[0].hasOwnProperty('city') &&
                        addresses[0].city
                    ) {
                        address += addresses[0].city + ' ';
                    }
                    return address.trim();
                },
                (error): string => {
                    console.log(error);
                    return t('geofences.EDIT.UNKNOWN_ADDRESS');
                },
            );
            return returnValue;
        },
    );
    return geocodedAddress;
};

export const getReverseGeocode = (lat: number, lng: number): Promise<string> =>
    timeoutPromise(3000, reverseGeocode(lat, lng))
        .then((newAddress) => newAddress)
        .catch((error) => {
            console.log(error);
            return lat.toFixed(6) + ', ' + lng.toFixed(6);
        });
