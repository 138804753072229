import { ModelConfig } from '@rematch/core';
import { IRawSubscriptionVariantOffer } from './interfaces-api';
import { fetchSubscriptionVariants } from './services/subscription-offers.service';

export interface ISubscriptionOffersStore {
    variants: IRawSubscriptionVariantOffer[];
}

export const subscriptionOffersStore: ModelConfig<ISubscriptionOffersStore> = {
    state: {
        variants: [],
    },
    effects: (dispatch) => ({
        fetchSubscriptionVariants(payload) {
            return fetchSubscriptionVariants(payload).then((data) =>
                this.setAvailableSubscriptionsVariants(data.items),
            );
        },
    }),
    reducers: {
        setAvailableSubscriptionsVariants: (
            state: ISubscriptionOffersStore,
            payload: IRawSubscriptionVariantOffer[],
        ): ISubscriptionOffersStore => ({
            ...state,
            variants: payload.filter((offer) => offer.displayed === true),
        }),
    },
};
