import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { IRawUserData } from '../../../user/interfaces';
import styles from './tracker-comms-data.module.scss';

/**
 * Takes current logged user data, tracker call time remaining (call_max_treshold - call_usage)
 * and tracker type.
 */
export interface ITrackerDetailsProps extends HTMLAttributes<HTMLDivElement> {
    callTimeRemaining: number;
    currentUser: IRawUserData;
    hasSmsActivated: boolean;
    hasSos: boolean;
}

export const TrackerCommunicationData = ({
    callTimeRemaining,
    className,
    currentUser,
    hasSmsActivated,
    hasSos,
    ...props
}: ITrackerDetailsProps) => {
    const { t } = useTranslation('trackerLeftPanel');
    const remainingCallTimeMinutes = Math.floor(callTimeRemaining / 60);
    const remainingCallTimeSeconds = callTimeRemaining % 60;
    const shouldDisplayRemainingCallTime = (): boolean => {
        return hasSos === true;
    };
    const remainingCallTime = () => {
        if (shouldDisplayRemainingCallTime()) {
            if (remainingCallTimeMinutes > 0) {
                return (
                    <span>
                        {t('CTR_WITH_MINUTES', {
                            MINUTES: remainingCallTimeMinutes,
                            SECONDS: remainingCallTimeSeconds,
                        })}
                        ;
                    </span>
                );
            }
            return (
                <span>
                    {t('CTR_ONLY_SECONDS', {
                        SECONDS: remainingCallTimeSeconds,
                    })}
                    ;
                </span>
            );
        }
        return;
    };

    const maybeRenderSmsAvailable = () => {
        if (hasSmsActivated) {
            return (
                <span className={styles.sms}>
                    {t('SMS_AVAILABLE', {
                        SMS_count: currentUser.sms,
                    })}
                </span>
            );
        }
    };

    const maybeRenderTrackerCommsData = () => {
        if (shouldDisplayRemainingCallTime() || hasSmsActivated) {
            return (
                <div
                    className={cx(styles['tracker-comms'], className)}
                    {...props}
                >
                    {maybeRenderSmsAvailable()}
                    {remainingCallTime()}
                </div>
            );
        }
        return;
    };

    return <React.Fragment>{maybeRenderTrackerCommsData()}</React.Fragment>;
};
