import { ModelConfig } from '@rematch/core';
import { validateForgotPasswordKey } from './forgot-password.service';

interface IModelStore {
    status_code: number;
}

export const forgotPasswordStore: ModelConfig<IModelStore> = {
    state: {
        status_code: 0,
    },
    reducers: {
        setForgotPasswordKey: (state, data: number) => {
            return {
                ...state,
                status_code: data,
            };
        },
    },
    effects: (dispatch) => {
        return {
            fetchValidateKey(forgotPasswordKey: string) {
                return validateForgotPasswordKey(forgotPasswordKey).then(
                    (responseStatusCode) => {
                        this.setForgotPasswordKey(responseStatusCode);
                    },
                );
            },
        };
    },
};
