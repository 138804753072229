import cx from 'classnames';
import React, { HTMLAttributes, useRef } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as CrossIcon } from '../../icons/cross.svg';

import styles from './modal.module.scss';

const getModalRoot = () => document.getElementById('modals');

export interface IModalProps extends HTMLAttributes<HTMLDivElement> {
    onClosed?: () => any;
}

export const Modal = ({
    children,
    className,
    onClosed,
    ...props
}: IModalProps) => {
    const containerRef = useRef<HTMLElement | null>(null);
    const getContainer = () => {
        if (!containerRef.current) {
            containerRef.current = document.createElement('div');
            containerRef.current.setAttribute('data-testid', 'modal-container');
        }

        return containerRef.current;
    };

    const modalRoot = getModalRoot();
    modalRoot?.appendChild(getContainer());

    const renderModal = () => {
        const maybeRenderCloseButton = () => {
            if (onClosed) {
                return (
                    <button onClick={onClosed} className={styles.closeButton}>
                        <CrossIcon />
                    </button>
                );
            }
        };
        return (
            <div
                className={styles.overlay}
                onClick={onClosed}
                data-testid="modal-overlay"
            >
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={cx(styles.modal, className)}
                    {...props}
                >
                    {maybeRenderCloseButton()}
                    {children}
                </div>
            </div>
        );
    };

    return createPortal(renderModal(), getContainer());
};
