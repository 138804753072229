import { config } from '../../config';
import CAP from '../icons/trackers/tracker-cap.svg';
import CAT_1 from '../icons/trackers/tracker-cat-1.svg';
import CAT_2 from '../icons/trackers/tracker-cat-2.svg';
import CAT_3 from '../icons/trackers/tracker-cat-3.svg';
import CAT_4 from '../icons/trackers/tracker-cat-4.svg';
import DOG_1 from '../icons/trackers/tracker-dog-1.svg';
import DOG_2 from '../icons/trackers/tracker-dog-2.svg';
import DOG_3 from '../icons/trackers/tracker-dog-3.svg';
import DOG_4 from '../icons/trackers/tracker-dog-4.svg';
import GRANDMA_1 from '../icons/trackers/tracker-grandma-1.svg';
import GRANDMA_2 from '../icons/trackers/tracker-grandma-2.svg';
import GRANDPA_1 from '../icons/trackers/tracker-grandpa-1.svg';
import GRANDPA_2 from '../icons/trackers/tracker-grandpa-2.svg';
import HAT from '../icons/trackers/tracker-hat.svg';
import KID_BOY_1 from '../icons/trackers/tracker-kid-boy-1.svg';
import KID_BOY_2 from '../icons/trackers/tracker-kid-boy-2.svg';
import KID_GIRL_1 from '../icons/trackers/tracker-kid-girl-1.svg';
import KID_GIRL_2 from '../icons/trackers/tracker-kid-girl-2.svg';
import PAW from '../icons/trackers/tracker-paw.svg';
import POLICE from '../icons/trackers/tracker-police.svg';
import SHIELD from '../icons/trackers/tracker-shield.svg';
import SHOP from '../icons/trackers/tracker-shop.svg';
import STAR from '../icons/trackers/tracker-star.svg';
import USER from '../icons/trackers/tracker-user.svg';
import { getDefaultColorByTrackerType } from '../tracker-settings/tracker-display/tracker-colors';
import { TrackerType } from './interfaces';

export const icons = {
    CAP,
    CAT_1,
    CAT_2,
    CAT_3,
    CAT_4,
    DOG_1,
    DOG_2,
    DOG_3,
    DOG_4,
    GRANDMA_1,
    GRANDMA_2,
    GRANDPA_1,
    GRANDPA_2,
    HAT,
    KID_BOY_1,
    KID_BOY_2,
    KID_GIRL_1,
    KID_GIRL_2,
    PAW,
    STAR,
    POLICE,
    SHOP,
    SHIELD,
    USER,
};

export const iconMap = {
    falling_mustache: icons.CAT_1,
    oval_head: icons.CAT_2,
    round_head: icons.CAT_3,
    triangle_head: icons.CAT_4,
    good_boy: icons.DOG_1,
    classy_doggy: icons.DOG_2,
    fluffy_doggy: icons.DOG_3,
    sweet_doggy: icons.DOG_4,
    normal_boy: icons.KID_BOY_1,
    smily_boy: icons.KID_BOY_2,
    normal_girl: icons.KID_GIRL_1,
    smily_girl: icons.KID_GIRL_2,
    grandma: icons.GRANDMA_1,
    wise_grandma: icons.GRANDMA_2,
    grandpa: icons.GRANDPA_1,
    wise_grandpa: icons.GRANDPA_2,
    cap: icons.CAP,
    hat: icons.HAT,
    paw: icons.PAW,
    star: icons.STAR,
    user: icons.USER,
    shop: icons.SHOP,
    shield: icons.SHIELD,
    police: icons.POLICE,
};

const typeIconsMap: { [type: string]: string } = {
    [TrackerType.CAT]: icons.CAT_1,
    [TrackerType.CAT2]: icons.CAT_1,
    [TrackerType.DEFAULT]: icons.KID_BOY_1,
    [TrackerType.SPORT]: icons.KID_BOY_1,
    [TrackerType.SKISET]: icons.KID_BOY_1,
    [TrackerType.PET]: icons.PAW,
    [TrackerType.PET2]: icons.PAW,
    [TrackerType.SILVER]: icons.GRANDMA_1,
};

export type TrackerIcon = keyof typeof iconMap;

/**
 * Resolves icon asset from icon name from api
 */
export const resolveTrackerIcon = (
    iconUrl: string | null,
    iconName: TrackerIcon | null,
    trackerType: string,
) => {
    if (iconUrl) {
        return iconUrl;
    }
    if (iconName) {
        return iconMap[iconName];
    } else {
        return typeIconsMap[trackerType] || icons.KID_BOY_1;
    }
};

/**
 * Resolves icon's color from api result
 */
export const resolveTrackerColor = (color: string, trackerType: string) => {
    /* Case where color is extracted from API */
    if (
        color &&
        color !== '#' && // Sometimes format can lead to this
        !color.includes('null') // When color is set in db object
    ) {
        if (color.charAt(0) !== '#') {
            return `#${color}`; // Server color is without #
        }
        return `${color}`;
    }

    /* Case when color is null from API */
    const defaultColorsByType = getDefaultColorByTrackerType();
    if (trackerType in defaultColorsByType) {
        return defaultColorsByType[trackerType];
    }

    /* Finally, we fallback to a default color */
    return config.FALLBACK_TRACKER_COLOR;
};
