import * as yup from 'yup';
import { getGlobalT } from '../../../i18n/setup-translations';
import { isInternationalPhone } from '../../../utils/phone-validator';
import { IFormValues } from './call-button-settings.component';

export const getCallButtonSettingsValidationSchema = () => {
    const t = getGlobalT()
    return yup.object<any>({
        number: yup
            .string()
            .test('phone', t('errors:PHONE_FORMAT'), isInternationalPhone)
            .required(),
        directCall: yup.bool(),
    });
};
