import cx from 'classnames';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Handles, Slider as RCSlider, Ticks } from 'react-compound-slider';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Typography } from '../../common/typography/typography.component';
import { Toggle } from '../../common/toggle/toggle.component';
import { Dispatch, IRootState } from '../../store/store';
import { makeGetFrequencyInterval } from '../../trackers/selectors/get-tracker-frequency-interval';
import { formatTimeInterval } from '../format-time-interval';
import { Handle } from './handle.component';
import styles from './slider.module.scss';
import { Tick } from './tick.component';


interface IState {
    frequency: number;
}
interface IOuterProps extends HTMLAttributes<HTMLDivElement> {
    trackerId: number | null;
    hasPreview?: boolean | null;
}

interface IActions {
    doUpdateTrackerRefreshMode: (seconds: number) => Promise<any>;
}

interface ISliderProps extends IOuterProps, IActions, IState { }

export const FrequencySlider = ({
    frequency,
    trackerId,
    doUpdateTrackerRefreshMode,
    className,
    hasPreview,
}: ISliderProps) => {
    const { t } = useTranslation('Slider');
    // Store value locally to update ticks highlighting
    const values = [30, 60, 300, 600, 1800, 3600];
    const [valueIndex, setValueIndex] = useState(0);
    // Hide slider by default if hasPreview is true
    const [isHidden, toggleHidden] = useState(hasPreview);
    const [isChecked, setIsChecked] = useState(frequency != 0);
    const handleSwitchChange = () => {
        doUpdateTrackerRefreshMode(isChecked ? 0 : 60)
            .then(() => setIsChecked(!isChecked));
    };
    const maybeRenderFrequencyPreview = () => {
        if (hasPreview && isHidden) {
            const formattedTimeIntervalFrequencyToOff = isChecked ? formatTimeInterval(frequency) : "Off";
            return (
                <div className={styles.preview}>
                    <Typography
                        className={cx(styles.preview_text, {
                            [styles.isVisible]: isHidden,
                        })}
                    >
                        {formattedTimeIntervalFrequencyToOff}
                    </Typography>
                </div>
            );
        }
    };
    const maybeRenderFrequencyTitle = () => {
        if (hasPreview) {
            return (
            <div>
                <p className={styles.sliderTitle}>{t('GPS_INTERVAL')}</p>
            </div>)
        }
    };

    useEffect(() => {
        if(isChecked) {
            setValueIndex(values.findIndex(
                (v) => v === frequency)
            )
        }

    }, [values, frequency, setValueIndex, isChecked]);

    const onMouseOver = () => {
        if (hasPreview) {
            toggleHidden(false);
        }
    };

    const onMouseLeave = () => {
        if (hasPreview) {
            toggleHidden(true);
        }
    };

    const renderSlider = () => {
        if (isHidden) {
            return null;
        }

        if (hasPreview && !isChecked && !isHidden) {
            return (
                <div>
                    {maybeRenderFrequencyTitle()}
                    <div className={styles.intervalContainerToggleDeactivated}>
                        <p className={styles.intervalTitleToggleDeactivated}>{t('GPS_POSITION_REPORT_ENABLED')}</p>
                        <Toggle onChange={handleSwitchChange} />
                    </div>
                </div>
            );
        }
        return (
            <RCSlider
                mode={1}
                values={[valueIndex]}
                domain={[0, values.length - 1]}
                className={cx(styles.slider, {
                    [styles.isHidden]: isHidden,
                })}
                step={1}
                onChange={(selectedIndexes) => {
                    if (!isNaN(selectedIndexes[0])) {
                        setValueIndex(selectedIndexes[0]);
                        doUpdateTrackerRefreshMode(values[selectedIndexes[0]]);
                    }
                }}
            >
                {maybeRenderFrequencyTitle()}
                <div className={styles.rail} />
                <Handles>
                    {({ handles, getHandleProps }) => (
                        <React.Fragment>
                            {handles.map((handle) => (
                                <Handle
                                    key={handle.id}
                                    handle={handle}
                                    getHandleProps={getHandleProps}
                                />
                            ))}
                        </React.Fragment>
                    )}
                </Handles>

                <Ticks count={values.length}>
                    {({ ticks }) => {
                        return (
                            <div className={styles.values}>
                                {ticks.map((tick, i) => {
                                    return (
                                        <Tick
                                            key={tick.id}
                                            tick={tick}
                                            count={ticks.length}
                                            format={(v: number) =>
                                                formatTimeInterval(values[v])
                                            }
                                            active={valueIndex === i}
                                            onClick={() => {
                                                if (trackerId) {
                                                    doUpdateTrackerRefreshMode(
                                                        values[i],
                                                    );
                                                }
                                                setValueIndex(i);
                                                    }}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }}
                </Ticks>
                <div className={styles.intervalContainerToggleActivated}>
                    <p className={styles.intervalTitleToggleDeactivatedToggleActivated}>{t('GPS_POSITION_REPORT_ENABLED')}</p>
                    <Toggle 
                        onChange={handleSwitchChange} 
                        checked
                    />
                </div>
            </RCSlider>
        );
    };

    return (
        <div
            className={cx(styles['slider-container'], className)}
            onMouseOver={() => onMouseOver()}
            onMouseLeave={() => onMouseLeave()}
        >
            {renderSlider()}
            {maybeRenderFrequencyPreview()}
        </div>
    );
};

const mapState = (state: IRootState, props: IOuterProps): IState => {
    const getActiveTrackerFrequency = makeGetFrequencyInterval(
        props.trackerId!,
    );

    return {
        frequency: getActiveTrackerFrequency(state) as number,
    };
};

const mapDispatch = (dispatch: Dispatch, props: IOuterProps): IActions => ({
    doUpdateTrackerRefreshMode: async (seconds: number) => {
        return dispatch.trackersSettings.updateTrackerRefreshMode({
            secondsPeriod: seconds,
            trackerId: props.trackerId,
        });
    },
});

export const ConnectedFrequencySlider = compose<ISliderProps, IOuterProps>(
    connect(mapState, mapDispatch),
)(FrequencySlider);
