import React from 'react';
import { Pane } from 'react-leaflet';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { MapLayerType } from '../../map/interfaces';
import { IRootState } from '../../store/store';
import { IWifiZoneMarker } from '../interfaces';
import { makeGetWifiZonesToDisplay } from '../selectors/wifi-zones.selectors';
import { ConnectedWifiZoneMapMarker } from '../wifi-zones-map-marker/wifi-zones-map-marker.component';

export interface IStateProps {
    wifiZonesToDisplay: IWifiZoneMarker[];
    selectedLayer: MapLayerType;
}

export interface IActions {
    updateEditablePosition: (position: { lat: number; lng: number }) => any;
}

export interface IProps extends IStateProps, IActions { }

export const WifiZonesMapMarkersList = ({
    wifiZonesToDisplay,
    selectedLayer,
    updateEditablePosition,
}: IProps) => {
    const getWifiZoneMarkers = () =>
        wifiZonesToDisplay.map((wifiZone: IWifiZoneMarker) => (
            <ConnectedWifiZoneMapMarker
                key={wifiZone.id || '0'}
                id={wifiZone.id || 0}
                display={wifiZone.displayed}
                position={wifiZone.position}
                radius={wifiZone.radius}
                editable={false}
                onPositionUpdated={updateEditablePosition}
            />
        ));

    const renderWifiZones = () => {
        // When displaying leaflet layers
        if (
            selectedLayer === MapLayerType.GRAY ||
            selectedLayer === MapLayerType.SATELLITE ||
            selectedLayer === MapLayerType.STREET
        ) {
            return <Pane style={{ zIndex: 1200 }}>{getWifiZoneMarkers()}</Pane>;
        }
        return getWifiZoneMarkers();
    };

    return <React.Fragment>{renderWifiZones()}</React.Fragment>;
};

const makeMapState = () => {
    const getWifiZonesToDisplay = makeGetWifiZonesToDisplay();

    return (state: IRootState): IStateProps => ({
        wifiZonesToDisplay: getWifiZonesToDisplay(state),
        selectedLayer: state.map.selectedLayer,
    });
};

export const ConnectedWifiZonesMapMarkersList = compose<IProps, {}>(
    connect(makeMapState),
)(WifiZonesMapMarkersList);
