import { LatLngExpression, LeafletMouseEvent } from 'leaflet';
import React from 'react';
import { Circle, Marker, MarkerProps, Pane } from 'react-leaflet';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { IRootState } from '../../../store/store';
import { TrackerType } from '../../../trackers/interfaces';
import {
    resolveTrackerIcon,
    TrackerIcon,
} from '../../../trackers/tracker-icons';
import { GoogleLayers, MapLayerType } from '../../interfaces';
import { createTrackerIcon } from './create-tracker-icon';
import styles from './tracker-marker.module.scss';

interface IStateProps {
    selectedLayer: MapLayerType;
}

interface IOuterProps extends MarkerProps {
    // This are required props for leaflet
    iconUrl: string | null;

    /**
     * Tracker radius can be turned on/off by user, so make it optional
     */

    trackerRadius?: number;
    /**
     * Color of tracker area, if trackerRadius is true
     */
    trackerColor?: string;
    /**
     * Icon from API
     */
    iconName: TrackerIcon | null;
    /**
     * Tracker type from API
     */
    trackerType: TrackerType;
    /**
     * It's possible that tracker has position (historical),
     * but GPS is off.
     */
    validGps: boolean;
    /**
     * position is required props for leaflet
     */
    position: LatLngExpression | [number, number];
    /**
     * lat is required props for google-map-react
     */
    lat?: number | undefined;
    /**
     * lng is required props for google-map-react
     */
    lng?: number | undefined;
    /**
     * This is the onClick attribute that handle the tracker selection on marker click
     * Common to Leaflet and Google Markers
     */
    onClick?:
    | ((
        event:
            | LeafletMouseEvent
            | React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => void)
    | undefined;
}

interface ITrackerMarkerProps extends IOuterProps, IStateProps { }

const radiusOpacity = 0.2;
const rangeRadiusBorderColor = '#fff';

/**
 * Icon is only svg path, without circle around it.
 * TODO: Consider making this SVG with background and steer it with currentColor / inheritance of fill/stroke.
 *   Current approach adds extra complexity and performance load, because it creates a lot of extra layers.
 *   Its also buggy.
 */

/**
 * Icon and it's background has to be on the same pane (grouped), but default
 * pane is lower than default shadow pane.
 * This moves it higher
 */
const paneStyles = {
    zIndex: 800,
};

/**
 * A TrackerMarker is an abstract component which automatically select the component to use,
 * depending on the selected layer and draw the position of a tracker on the map.
 */
export const TrackerMarker = ({
    selectedLayer,
    iconUrl,
    position,
    trackerRadius,
    trackerColor,
    iconName,
    trackerType,
    validGps,
    onClick,
}: ITrackerMarkerProps) => {
    const renderGoogleTrackerMarker = () => (
        <GoogleTrackerMarker
            iconUrl={iconUrl}
            position={position}
            trackerRadius={trackerRadius}
            trackerColor={trackerColor}
            iconName={iconName}
            trackerType={trackerType}
            validGps={validGps}
            onClick={onClick}
        />
    );

    const renderLeafletTrackerMarker = () => (
        <LeafletTrackerMarker
            iconUrl={iconUrl}
            position={position}
            trackerRadius={trackerRadius}
            trackerColor={trackerColor}
            iconName={iconName}
            trackerType={trackerType}
            validGps={validGps}
            onClick={onClick}
        />
    );

    const GoogleLayersValues = Object.values(GoogleLayers) as string[];
    if (GoogleLayersValues.includes(selectedLayer as string)) {
        return renderGoogleTrackerMarker();
    }
    return renderLeafletTrackerMarker();
};

/**
 * LeafletTrackerMarker uses react-leaflet components to draw on map.
 */
const LeafletTrackerMarker = ({
    position,
    trackerRadius,
    iconUrl,
    trackerColor,
    iconName,
    trackerType,
    validGps,
    onClick,
    ...props
}: IOuterProps) => {
    const maybeRenderTrackerRadius = () => {
        if (trackerRadius) {
            return (
                <Circle
                    fillColor={trackerColor}
                    fillOpacity={radiusOpacity}
                    color={trackerColor}
                    center={position}
                    radius={trackerRadius}
                    weight={1}
                />
            );
        }
    };

    const trackerIcon = createTrackerIcon(
        iconName,
        trackerType,
        iconUrl,
        trackerColor,
    );

    return (
        <React.Fragment>
            <Pane style={paneStyles}>
                <Marker
                    icon={trackerIcon}
                    position={position}
                    onclick={onClick}
                    {...props}
                >
                    {/* maybeRenderNoGPS() Removed until we find better icon */}
                </Marker>
            </Pane>
            {maybeRenderTrackerRadius()}
        </React.Fragment>
    );
};

/**
 * GoogleTrackerMarker are hand-made components.
 * Note that radius (confidence circles) are created on google-map-react.component.tsx
 */
const GoogleTrackerMarker = ({
    iconUrl,
    trackerColor,
    iconName,
    trackerType,
    onClick,
}: IOuterProps) => (
    <div
        className={styles['google-marker-container']}
        style={{ backgroundColor: trackerColor }}
        onClick={onClick}
    >
        <img
            height="8px"
            src={resolveTrackerIcon(iconUrl, iconName, trackerType)}
            className={styles['google-marker']}
        />
    </div>
);

const mapState = (state: IRootState): IStateProps => {
    return {
        selectedLayer: state.map.selectedLayer,
    };
};

export const ConnectedTrackerMarker = compose<ITrackerMarkerProps, IOuterProps>(
    connect(mapState, null),
)(TrackerMarker);
