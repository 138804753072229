import { createSelector } from 'reselect';

import { IRootState } from '../../store/store';
import { mapOffersToPayments } from '../../subscription-process/mappers/map-offers-to-payments';
import { getRawUserChoices } from '../../subscription-process/selectors/subscription-process-selectors';
import { SubscriptionOptionType } from '../../user-subscriptions/interfaces-api';
import { createUserCountrySelector } from '../../user/user-selectors';
import { IOptionOffer, SubscriptionVariantCode } from '../interfaces';
import { IRawSubscriptionVariantOffer } from '../interfaces-api';
import { mapRawOptionToView } from '../mappers/map-offer-options';
import { mapRawSubscriptionOffersVariants } from '../mappers/map-subscription-offers-variants';

export const getRawSubscriptionVariants = (store: IRootState) =>
    store.subscriptionOffers.variants;

export const makeGetSubscriptionsOffersVariants = () =>
    createSelector(
        getRawSubscriptionVariants,
        mapRawSubscriptionOffersVariants,
    );

/**
 * Gets options
 */
export const makeGetSubscriptionOfferOptions = (
    subscriptionCode: SubscriptionVariantCode,
    activeSubscription?: boolean,
) => {
    const getUserSelectedCountry = createUserCountrySelector();
    return createSelector(
        getRawSubscriptionVariants,
        getUserSelectedCountry,
        (
            variants: IRawSubscriptionVariantOffer[],
            countryCode: string,
        ): IOptionOffer[] => {
            const activeVariant = variants.find(
                (variant) => subscriptionCode === variant.code,
            );
            if (!activeVariant) {
                return [];
            }
            const optionsOffers = activeVariant.subscription_option_offers;
            return optionsOffers
                .map(mapRawOptionToView)
                .filter((option) => Boolean(option)) as IOptionOffer[];
        },
    );
};

export const makeGetAvailablePaymentTypes = () => {
    const getUserSelectedCountry = createUserCountrySelector();

    const getIsResolvingSubscription = (store: IRootState) =>
        store.subscriptionProcess.isResolvingSubscription;

    /* We won't display Levy (Gocardless) if canChangePlan is False */
    const getCanChangePlan = (store: IRootState) =>
        store.manageSubscription.canChangePlan;

    return createSelector(
        getRawSubscriptionVariants,
        getRawUserChoices,
        getUserSelectedCountry,
        getIsResolvingSubscription,
        getCanChangePlan,
        (variants, choices, country, isResolvingSubscription, canChangePlan) =>
            mapOffersToPayments(
                variants,
                choices.subscriptionCode,
                country,
                isResolvingSubscription,
                canChangePlan,
            ),
    );
};
