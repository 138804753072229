import { ReactElement } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorPage } from '.'


//@ts-ignore
function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div role="alert">
            <ErrorPage errorMessage={error.message} resetErrorBoundary={resetErrorBoundary} />
        </div>
    )
}

export const errorBoundaryWrapper = (children: ReactElement) => (
    <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
            // todo may refresh trackers or reset some api calls that can restore user navigation 
        }}
    >
        {children}
    </ErrorBoundary>
)