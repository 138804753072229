import cx from 'classnames';
import { FormikBag, FormikProps, withFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { IWithFormUtils, withFormUtils } from '../../../../utils/form-helpers';
import { Button } from '../../../common/button/button.component';
import { Input } from '../../../common/input/input.component';
import { ReactComponent as InfoIcon } from '../../../icons/info.svg';
import { getRegisterTrackerFormValidationSchema } from './register-tracker-form-validation.schema';

import styles from './register-tracker-form.module.scss';

export interface IFormValues {
    name: string;
    imei: string;
}

export interface IRegisterTrackerInnerProps
    extends FormikProps<IFormValues>,
        IWithFormUtils {
    className?: string;
    onImeiInfoClick: () => any;
}

export interface IRegisterTrackerOuterProps {
    onSubmit: (values: IFormValues) => any | Promise<any>;
    className?: string;
    onImeiInfoClick: () => any;
}

export const RegisterTrackerForm = ({
    handleSubmit,
    handleChange,
    values,
    getErrorMessage,
    isFieldValid,
    handleBlur,
    onImeiInfoClick,
    className,
}: IRegisterTrackerInnerProps) => {
    const { t } = useTranslation('registerTracker');

    return (
        <form className={cx(styles.form, className)} onSubmit={handleSubmit}>
            <Input
                id="register-tracker-form-name"
                className={styles.input}
                label={t('NAME_INPUT.LABEL')}
                placeholder={t('NAME_INPUT.PLACEHOLDER')}
                onBlur={handleBlur}
                value={values.name}
                onChange={handleChange}
                name="name"
                errorMessage={getErrorMessage('name')}
                isValid={isFieldValid('name')}
            />
            <Input
                id="register-tracker-form-imei"
                className={styles.input}
                onBlur={handleBlur}
                value={values.imei}
                onChange={handleChange}
                name="imei"
                label={t('IMEI_INPUT.LABEL')}
                placeholder={t('IMEI_INPUT.PLACEHOLDER')}
                rightIcon={
                    <InfoIcon
                        onClick={onImeiInfoClick}
                        data-testid="imei-info-icon"
                    />
                }
                errorMessage={getErrorMessage('imei')}
                isValid={isFieldValid('imei')}
            />
            <Button type="submit" block small className={styles.button}>
                {t('commonActions:REGISTER')}
            </Button>
        </form>
    );
};

export const ControlledRegisterForm = compose<
    IRegisterTrackerInnerProps,
    IRegisterTrackerOuterProps
>(
    withFormik<IRegisterTrackerOuterProps, IFormValues>({
        mapPropsToValues: (props: IRegisterTrackerOuterProps) => ({
            name: '',
            imei: '',
        }),
        validationSchema: () => getRegisterTrackerFormValidationSchema(),
        handleSubmit: (
            values: IFormValues,
            formikBag: FormikBag<IRegisterTrackerOuterProps, IFormValues>,
        ) => {
            formikBag.props.onSubmit({
                imei: values.imei,
                name: values.name,
            });
        },
    }),
    withFormUtils,
)(RegisterTrackerForm);
