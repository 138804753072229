import React from 'react';
import { connect } from 'react-redux';

import { getGlobalT } from '../../../i18n/setup-translations';
import { Button } from '../../common/button/button.component';
import { Input } from '../../common/input/input.component';
import { ReactComponent as IconLocation } from '../../icons/gps-on.svg';
import { Dispatch, IRootState } from '../../store/store';

import {
    makeGetEditableGeofenceAddressesChoice,
    makeGetEditableGeofencePosition,
} from '../selectors/geofences-edit.selectors';
import { geocode, IGraphhopperResponse } from '../services/geofences.service';
import styles from './geofence-geocode.module.scss';

export interface IStateProps {
    position: {
        lat: number;
        lng: number;
    };
    addressesChoice: IGraphhoperProps[];
}

export interface IGraphhoperProps {
    country: string;
    housenumber: string;
    city: string;
    street: string;
    postcode: string;
    name: string;
    state: string;
    point: {
        lng: number;
        lat: number;
    };
}

export interface IActions {
    updateEditablePosition: (position: { lat: number; lng: number }) => any;
    updateEditableAddressesChoice: (addressesChoice: IGraphhoperProps[]) => any;
    updateMapPosition: (position: { lat: number; lng: number }) => any;
}

export interface IProps extends IStateProps, IActions {}

export const GeocodeInput = ({
    updateEditablePosition,
    addressesChoice,
    updateEditableAddressesChoice,
    updateMapPosition,
}: IProps) => {
    const t = getGlobalT();

    const onGeocodeChange = (event: React.FormEvent<HTMLInputElement>) => {
        addressesChoice = [];
        const userInput = event.currentTarget.value;
        if (userInput.length === 0) {
            updateEditableAddressesChoice(addressesChoice);
            return;
        }
        geocode(userInput).then((addresses: IGraphhopperResponse) => {
            if (addresses.hits && addresses.hits.length > 0) {
                addresses.hits.forEach((address: IGraphhoperProps) => {
                    addressesChoice.push(address);
                });
                updateEditableAddressesChoice(addressesChoice);
            }
        });
    };

    const selectAddress = (selectedCoord: IGraphhoperProps['point']) => {
        updateEditablePosition(selectedCoord);
        addressesChoice = [];
        updateEditableAddressesChoice(addressesChoice);
        updateMapPosition(selectedCoord);
    };

    return (
        <div>
            <Input
                small
                label={t('geofences:EDIT.GEOCODE_LABEL')}
                placeholder={t('geofences:EDIT.GEOCODE_PLACEHOLDER')}
                onChange={onGeocodeChange}
                onBlur={onGeocodeChange}
                id="input-geocode-zone"
                name="Find address"
            />
            <div id="geocodeResponses" className={styles.geocodeResponses}>
                {/* Here will be added geocoding responses */}
                {addressesChoice.map((address, index) => {
                    return (
                        <Button
                            key={index}
                            secondary
                            onClick={() => selectAddress(address.point)}
                            iconLeft={<IconLocation />}
                        >
                            <div className={styles.singleResponse}>
                                <span>
                                    {address.housenumber} {address.name}
                                </span>
                                <span>{address.city}</span>
                            </div>
                            <span>{address.country}</span>
                        </Button>
                    );
                })}
            </div>
        </div>
    );
};

const makeMapState = () => {
    const getEditablePosition = makeGetEditableGeofencePosition();
    const getEditableAddressesChoice = makeGetEditableGeofenceAddressesChoice();
    return (state: IRootState): IStateProps => ({
        position: getEditablePosition(state),
        addressesChoice: getEditableAddressesChoice(state),
    });
};
const mapDisptach = (dispatch: Dispatch): IActions => ({
    updateEditablePosition: dispatch.geofenceEdit.updatePosition,
    updateEditableAddressesChoice: dispatch.geofenceEdit.updateAddressesChoice,
    updateMapPosition: dispatch.map.setPosition,
});

export const ConnectedGeocodeInput = connect(
    makeMapState,
    mapDisptach,
)(GeocodeInput);
