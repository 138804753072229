import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import { get } from 'lodash';
import { mapProps } from 'recompose';

export interface IWithFormUtils {
    getErrorMessage(fieldName: string): string | undefined;
    isFieldValid(fieldName: string): boolean | undefined;
}

/**
 * TODO: Part of this logic can be removed to "validateOnBlur" field in formik config
 */
export function createFormUtils<Values extends {}>({
    touched,
    errors,
}: {
    touched: FormikTouched<Values>;
    errors: FormikErrors<Values>;
}) {
    const getErrorMessage = (field: keyof Values) => {
        if (get(touched, field) && get(errors, field)) {
            return get(errors, field);
        }
    };

    const isFieldValid = (field: keyof Values) => {
        const errorMessage = get(errors, field);
        const fieldTouched = get(touched, field);

        if (errorMessage && fieldTouched) {
            return false;
        }

        if (fieldTouched && errorMessage === undefined) {
            return true;
        }
    };

    return { getErrorMessage, isFieldValid };
}

export const withFormUtils = mapProps((props: FormikProps<any>) => {
    const { getErrorMessage, isFieldValid } = createFormUtils<any>({
        errors: props.errors,
        touched: props.touched,
    });

    return {
        ...props,
        getErrorMessage,
        isFieldValid,
    };
});
