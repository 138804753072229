import React from 'react';
import { useTranslation } from 'react-i18next';
import imeiImage from '../../../../images/tracker.png';
import { Button } from '../../../common/button/button.component';
import { Headline } from '../../../common/headline/headline.component';

import styles from './imei-info-modal.module.scss';

export const ImeiInfoModal = ({
    onAcceptClicked,
}: {
    onAcceptClicked: () => any;
}) => {
    const { t } = useTranslation('imeiInfo');

    return (
        <React.Fragment>
            <Headline center>{t('TITLE')}</Headline>
            <p className={styles['tracker-description']}>{t('HINT')}</p>
            <img
                alt={t('IMAGE_ALT')}
                src={imeiImage}
                className={styles['tracker-image']}
            />
            <Button
                className={styles['ok-button']}
                secondary
                onClick={onAcceptClicked}
            >
                {t('commonActions:OK')}
            </Button>
        </React.Fragment>
    );
};
