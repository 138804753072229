import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { compose } from 'recompose';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../utils/with-selected-tracker';
import { deleteAnimalInfo } from '../../animal-info/animal-info.service';
import { SummaryComponent } from '../../animal-info/component/summary-component';
import {
    IAnimal,
    IFormValidationInterface,
} from '../../animal-info/interfaces';
import { Collapse } from '../../common/collapse/collapse.component';
import { Typography } from '../../common/typography/typography.component';
import { Dispatch, IRootState } from '../../store/store';
import { ITracker } from '../../trackers/interfaces-api';
import { makeGetFrequencyModeAvailable } from '../../trackers/selectors/get-frequency-mode-available';
import { makeGetIsTrackerAnimalType } from '../../trackers/selectors/get-is-tracker-animal-type';
import { makeGetTrackerModel } from '../../trackers/selectors/get-tracker-model';

import { ConnectedButtonsSettings } from '../buttons-settings/buttons-settings.component';
import { ConnectedCallButtonSettings } from '../call-button-settings/call-button-settings.component';
import { ConnectedFrequencySettings } from '../frequency-settings/frequency-settings.component';
import { ConnectedNotificationContacts } from '../notification-contacts/notification-contacts.component';
import { ConnectedNotificationsSettings } from '../notifications-settings/notifications-settings.component';
import { ConnectedTrackerDisplayForm } from '../tracker-display/tracker-display-form/tracker-display-form.component';
import styles from './tracker-settings-route.module.scss';


interface IActions {
    fetchAnimalInfo: (imei: number) => IAnimal;
}

interface IStateProps {
    imei: number;
    animalInfo: IAnimal | null;
    isAnimalType: boolean;
    hasButtons: boolean;
    hasSos: boolean;
    frequencyAvailable: boolean | '' | null | undefined;
    isMobileRedirect: boolean;
}

interface IProps
    extends
    IStateProps,
    IWithSelectedTracker,
    IActions { }

const TrackerSettingsRouteRaw = ({
    animalInfo,
    fetchAnimalInfo,
    imei,
    isAnimalType,
    hasButtons,
    hasSos,
    frequencyAvailable,
    trackerId,
    isMobileRedirect,
}: IProps) => {
    const param = useLocation();
    const [openSectionId, setOpenSectionId] = useState<string | null>(
        (param.search.includes('source=app&tab=animal-info')) ? 'buttons' : 'display',
    );
    const history = useNavigate()
    const [
        formValidation,
        setFormValidation,
    ] = useState<IFormValidationInterface | null>(animalInfo);

    useEffect(() => {
        fetchAnimalInfo(imei);
    }, []);

    const handleRemove = () => {
        deleteAnimalInfo(animalInfo?.id!).then(() => {
            fetchAnimalInfo(imei);
        });
    };
    useEffect(() => {
        if (animalInfo?.birth_date) {
            animalInfo.birth_date = new Date(animalInfo.birth_date);
        }
        if (animalInfo?.last_vaccination_date) {
            animalInfo.last_vaccination_date = new Date(
                animalInfo.last_vaccination_date,
            );
        }
        if (animalInfo?.last_vet_visit_date) {
            animalInfo.last_vet_visit_date = new Date(
                animalInfo.last_vet_visit_date,
            );
        }
        setFormValidation(animalInfo);
    }, [animalInfo]);

    const openSectionIdSetter = (targetedSectionId: string) => {
        if (openSectionId !== targetedSectionId || openSectionId === null) {
            return setOpenSectionId(targetedSectionId);
        }
        return setOpenSectionId(null);
    };
    const { t } = useTranslation(['trackerSettings', 'activityOnboarding']);

    const getSosLabel = () => {
        if (isAnimalType) {
            return t('SECTION_TITLES.TRAINING');
        }
        return t('SECTION_TITLES.SOS');
    };
    const redirectToFormPage = (step: number) => {
        return history(
            `/trackers/${trackerId}/settings/animalInfo`,
            { state: step },
        );
    };
    const maybeShowButtonForTrackerProfil = () => {
        if (isAnimalType) {
            return (
                <Collapse
                    className={styles.section}
                    panel={
                        <Typography noMargin bold>
                            {t('activityOnboarding:COMMON.TRACKER_PROFIL')}
                        </Typography>
                    }
                    content={
                        <div className={styles.content}>
                            {formValidation ? (
                                <SummaryComponent
                                    animalInfo={animalInfo}
                                    formValidation={formValidation}
                                    nextStep={redirectToFormPage}
                                    editMode={true}
                                    setFormValidation={setFormValidation}
                                    handleRemove={() => handleRemove()}
                                />
                            ) : null}
                        </div>
                    }
                    expanded={
                        formValidation && isMobileRedirect
                            ? openSectionId === 'display'
                            : openSectionId === 'buttons'
                    }
                    onExpand={() => {
                        formValidation
                            ? openSectionIdSetter('buttons')
                            : redirectToFormPage(0);
                    }}
                />
            );
        }
    };
    const maybeShowButtonsCollapse = () => {
        if (!isAnimalType) {
            return (
                <Collapse
                    className={styles.section}
                    panel={
                        <Typography noMargin bold>
                            {t('SECTION_TITLES.BUTTONS')}
                        </Typography>
                    }
                    content={
                        <div className={styles.content}>
                            <ConnectedButtonsSettings trackerID={trackerId!} />
                        </div>
                    }
                    expanded={openSectionId === 'buttons'}
                    onExpand={() => {
                        openSectionIdSetter('buttons');
                    }}
                />
            );
        }
        return null;
    };

    const maybeShowSosCollapse = () => {
        if (!isAnimalType) {
            return (
                <Collapse
                    className={styles.section}
                    panel={
                        <Typography noMargin bold>
                            {getSosLabel()}
                        </Typography>
                    }
                    content={
                        <div className={styles.content}>
                            <ConnectedCallButtonSettings
                                trackerID={trackerId!}
                                isAnimalType={isAnimalType}
                            />
                        </div>
                    }
                    expanded={openSectionId === 'call-button'}
                    onExpand={() => {
                        openSectionIdSetter('call-button');
                    }}
                />
            );
        }
        return null;
    };

    const maybeRenderFrequencySettings = () => {
        if (frequencyAvailable) {
            return (
                <Collapse
                    className={styles.section}
                    panel={
                        <Typography noMargin bold>
                            {t('SECTION_TITLES.GPS_FREQUENCY')}
                        </Typography>
                    }
                    content={
                        <div className={styles.content}>
                            <ConnectedFrequencySettings trackerId={trackerId} />
                        </div>
                    }
                    expanded={openSectionId === 'frequency'}
                    onExpand={() => {
                        openSectionIdSetter('frequency');
                    }}
                />
            );
        }
    };

    return (
        <div>
            <Collapse
                className={styles.section}
                panel={
                    <Typography noMargin bold>
                        {t('SECTION_TITLES.DISPLAY')}
                    </Typography>
                }
                content={
                    <div className={styles.content}>
                        <ConnectedTrackerDisplayForm trackerID={trackerId!} />
                    </div>
                }
                expanded={
                    isMobileRedirect
                        ? openSectionId === 'button'
                        : openSectionId === 'display'
                }
                onExpand={() => {
                    openSectionIdSetter('display');
                }}
            />
            {maybeShowButtonForTrackerProfil()}
            {maybeShowButtonsCollapse()}
            {maybeShowSosCollapse()}
            <Collapse
                className={styles.section}
                panel={
                    <Typography noMargin bold>
                        {t('SECTION_TITLES.CONTACTS')}
                    </Typography>
                }
                content={
                    <div className={styles.content}>
                        <ConnectedNotificationContacts trackerID={trackerId!} />
                    </div>
                }
                expanded={openSectionId === 'contacts'}
                onExpand={() => {
                    openSectionIdSetter('contacts');
                }}
            />
            <Collapse
                className={styles.section}
                panel={
                    <Typography noMargin bold>
                        {t('SECTION_TITLES.NOTIFICATION_SETTINGS')}
                    </Typography>
                }
                content={
                    <div className={styles.content}>
                        <ConnectedNotificationsSettings
                            hasButtons={!isAnimalType} // Only 'human' trackers have buttons
                            trackerID={trackerId!}
                            hasSos={!isAnimalType}
                        />
                    </div>
                }
                expanded={openSectionId === 'notifications'}
                onExpand={() => {
                    openSectionIdSetter('notifications');
                }}
            />
            {maybeRenderFrequencySettings()}
        </div>
    );
};

const mapDispatch = (dispatch: Dispatch) => ({
    fetchAnimalInfo: (imei: number) => {
        dispatch.animalInfo.getAnimalInfo(imei);
    },
});

const mapState = (
    state: IRootState,
    props: IWithSelectedTracker & IActions,
): IStateProps => {
    const getIsAnimalType = makeGetIsTrackerAnimalType(props.trackerId!);

    const getTrackerModel = makeGetTrackerModel(props.trackerId!);

    const tracker = getTrackerModel(state) as ITracker;
    const getActiveTrackerFrequencyAvailable = makeGetFrequencyModeAvailable(
        tracker.id,
    );

    return {
        imei: tracker.imei,
        animalInfo: state.animalInfo.rawPetInfos,
        hasButtons: tracker.features.includes('has_buttons'),
        hasSos: tracker.features.includes('sos_button'),
        isAnimalType: getIsAnimalType(state),
        frequencyAvailable: getActiveTrackerFrequencyAvailable(state),
        isMobileRedirect: state.layout.isMobileRedirect,
    };
};

export const TrackerSettingsRoute = compose(
    withSelectedTracker,
    connect(mapState, mapDispatch),
)(TrackerSettingsRouteRaw);
