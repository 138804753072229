import { getGlobalT } from '../../../i18n/setup-translations';
import { apiService } from '../../api/api.service';
import { IRawWifiZone } from '../interfaces-api';

const t = getGlobalT();

export interface ITriggerDeepSleepDTO {
    enable_deep_sleep_wifi: boolean;
}
export interface IWifiZoneDTO {
    name: string;
    mac_address: string;
    tracker_id: number;
    latitude: number;
    longitude: number;
    radius: number;
    is_active: boolean;
    appli_notification: boolean;
    mail_notification: boolean;
    sms_notification: boolean;
}
export interface IWifiZoneResponse {
    items: IRawWifiZone[];
    total: number;
}
export const fetchWifiZones = (
    trackerId: number,
): Promise<IWifiZoneResponse> => {
    const getUrl = () => `/mytracker/${trackerId}/wifi-zones`;

    return apiService.fetch(getUrl()).then((resp) => resp.json());
};

export const fetchSingleWifiZone = (
    wifiZoneId: number,
): Promise<IRawWifiZone> =>
    apiService.fetch(`/wifi-zone/${wifiZoneId}`).then((resp) => resp.json());

export const triggerDeepSleep = (
    trackerId: number,
    body: ITriggerDeepSleepDTO,
): Promise<ITriggerDeepSleepDTO> =>
    apiService
        .fetch(`/mytracker/${trackerId}/deep-sleep-wifi`, {
            method: 'PUT',
            body: JSON.stringify(body),
        })
        .then((resp) => resp.json());

export const updateWifiZone = (
    body: IWifiZoneDTO,
    wifiZoneId: number,
): Promise<IRawWifiZone> =>
    apiService
        .fetch(`/wifi-zone/${wifiZoneId}`, {
            method: 'PUT',
            body: JSON.stringify(body),
        })
        .then((resp) => resp.json());

export const deleteWifiZone = (wifiZoneId: number) =>
    apiService.fetch(`/wifi-zone/${wifiZoneId}`, {
        method: 'DELETE',
    });
