import { ModelConfig } from '@rematch/core';

import { IUser } from './interfaces-api';
import { IUserEmailPrefData } from './interfaces';
import { fetchUserEmailPref, postUserEmailPref } from './userpref.service';

export interface IEmailPrefParams {
    token: string;
    validation_key: string;
    user_email?: string;
};

export interface IEmailPrefPostParams {
    emailpref: IUserEmailPrefData;
    token: string;
    validation_key: string;
    user_email?: string;
};

/**
 * Add more fields when needed in the app
 */
export interface IUserPrefStore {
    emailPrefData: IUserEmailPrefData | null;
}

export const userPrefStore: ModelConfig<IUserPrefStore> = {
    state: {
        emailPrefData: null,
    },
    reducers: {
        setEmailPrefData(state, emailPrefData: IUserEmailPrefData) {
            return { ...state, emailPrefData };
        },
    },
    effects: (dispatch: any) => ({
        async fetchUserEmailPrefData({
            token,
            validation_key,
            user_email,
        }: IEmailPrefParams) {
            return fetchUserEmailPref(token, validation_key, user_email).then(
                (emailpref: IUserEmailPrefData) => {
                    dispatch.userPref.setEmailPrefData(emailpref);
                    return emailpref;
            });
        },
        async updateEmailPrefData({
            emailpref,
            token,
            validation_key,
            user_email,
        }: IEmailPrefPostParams) {
            return postUserEmailPref(
                token, validation_key, emailpref, user_email
            ).then((data: IUserEmailPrefData) => {
                dispatch.userPref.setEmailPrefData(data);
                return data;
            });
        },
    }),
};
