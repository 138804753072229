import { useNavigate } from 'react-router';
import { config as appConfig } from '../../config';
import { getSession, killSession } from '../login/login.service';
import { loginRoute } from '../routing/routes';

export type IFetch = (
    input: RequestInfo,
    init?: RequestInit,
) => Promise<Response>;

interface IRequestMappers {
    mapHeaders?: (headers: Headers) => Headers;
}

/**
 * TODO: Add generic types
 */
export class ApiService {
    private host = appConfig.API_HOST;

    constructor(
        private fetchApi: IFetch,
        private onAuthFailed: () => any = () => { },
    ) { }

    fetch(
        endpoint: string,
        config: RequestInit = {},
        mappers: IRequestMappers = {},
    ): Promise<any> {
        const session = getSession();

        let headers: Headers;

        if (config.headers) {
            headers = new Headers(config.headers);
        } else {
            headers = new Headers();
        }

        if (config.method === 'POST' || config.method === 'PUT') {
            headers.append('Content-Type', 'application/json');
        }

        headers.append('Accept', 'application/json');
        headers.append('X-App-Version', appConfig.APP_VERSION);
        headers.append('X-App-User-Id', appConfig.USER_ID);
        headers.append('X-App-Type', 'userspace');

        if (session) {
            headers.append('Authorization', `JWT ${session.access_token}`);
        }

        if (mappers.mapHeaders) {
            headers = mappers.mapHeaders(headers);
        }

        return this.fetchApi(this.host + endpoint, {
            ...config,
            headers,
        }).then((resp) => {
            if (resp.status === 401) {
                this.onAuthFailed();
                throw Error('Not authorized');
            }
            return resp;
        });
    }
}

export const apiService = new ApiService(fetch.bind(window), () => {
    killSession();
    useNavigate()(loginRoute);
});

export const timeoutPromise = (
    ms: number,
    promise: Promise<any>,
): Promise<any> => {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(new Error('promise timeout'));
        }, ms);
        promise.then(
            (res) => {
                clearTimeout(timeoutId);
                resolve(res);
            },
            (err) => {
                clearTimeout(timeoutId);
                reject(err);
            },
        );
    });
};
