import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { Select } from '../../common/select/select.component';
import styles from '../animal-info.module.scss';
import {
    AnimalFormStep,
    IAnimal,
    IFormValidationInterface,
    KeyDate,
    MonthTranslated,
} from '../interfaces';

interface IOuterProps {
    animalInfo: IAnimal;
    formValidation: IFormValidationInterface;
    setFormValidation: (value: any) => void;
    nextStep: (value: number) => void;
    handleSubmit: any;
}

export const HealthComponent = ({
    animalInfo,
    formValidation,
    setFormValidation,
    nextStep,
    handleSubmit,
}: IOuterProps) => {
    const { t } = useTranslation([
        'activityOnboarding',
        'activityTracking',
        'commonActions',
    ]);

    const maxOffset = 30;
    const thisYear = new Date().getFullYear();
    const allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x);
    }

    const handleDate = ({
        value = 1,
        isMonth = false,
        isYear = false,
        key = KeyDate.VACCINATE,
    }: {
        value: number;
        isMonth?: boolean;
        isYear?: boolean;
        key: KeyDate.VACCINATE | KeyDate.VET;
    }) => {
        if (!value) {
            return setFormValidation((form: IFormValidationInterface) => ({
                ...form,
                [key]: null,
            }));
        }
        const currentDate = formValidation[key]
            ? formValidation[key]
            : new Date();

        if (isMonth) {
            currentDate!.setMonth(value - 1);
            setFormValidation((form: IFormValidationInterface) => ({
                ...form,
                [key]: currentDate,
            }));
        }
        if (isYear) {
            currentDate!.setFullYear(value);
            setFormValidation((form: IFormValidationInterface) => ({
                ...form,
                [key]: currentDate,
            }));
        }
    };

    return (
        <React.Fragment>
            <div className={styles.container}>
                <Headline level={2} center className={styles.title}>
                    {t('PET_HEALTH.TITLE')}
                </Headline>
                <div className={styles.containerBlockVisible}>
                    <p className={styles.commonText}>
                        {t('PET_GENDER.SECOND_TITLE')}
                    </p>
                    <div className={styles.grid}>
                        <div
                            onClick={() =>
                                setFormValidation(
                                    (form: IFormValidationInterface) => ({
                                        ...form,
                                        is_sterilized: true,
                                    }),
                                )
                            }
                            className={
                                formValidation?.is_sterilized
                                    ? styles.textContainerSelected
                                    : styles.textContainer
                            }
                        >
                            <p className={styles.textBlockIcon}>
                                {t('commonActions:YES')}
                            </p>
                        </div>
                        <div
                            onClick={() =>
                                setFormValidation(
                                    (form: IFormValidationInterface) => ({
                                        ...form,
                                        is_sterilized: false,
                                    }),
                                )
                            }
                            className={
                                formValidation?.is_sterilized === null ||
                                formValidation?.is_sterilized
                                    ? styles.textContainer
                                    : styles.textContainerSelected
                            }
                        >
                            <p className={styles.textBlockIcon}>
                                {t('commonActions:NO')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.textalignLeft}>
                    <p
                        className={styles.commonText}
                        style={{ marginBottom: '1rem!mportant' }}
                    >
                        {t('PET_HEALTH.LAST_VET_VISIT')}
                    </p>
                    <div className={styles.gridDate}>
                        <div className={styles.width25}>
                            <p>{t('activityTracking:PERIOD.MONTH')}</p>
                            <Select
                                className={styles.control}
                                name="month"
                                style={{ color: 'black' }}
                                onChange={(e) => {
                                    const { value } = e.currentTarget;
                                    handleDate({
                                        value: parseFloat(value),
                                        isMonth: true,
                                        isYear: false,
                                        key: KeyDate.VET,
                                    });
                                }}
                                value={
                                    formValidation.last_vet_visit_date
                                        ? (
                                              formValidation.last_vet_visit_date.getMonth() +
                                              1
                                          )
                                              .toString()
                                              .padStart(2, '0')
                                        : '--'
                                }
                            >
                                <option value={0}>--</option>
                                {MonthTranslated.map((month, i) => (
                                    <option
                                        key={t(month)}
                                        value={(i + 1)
                                            .toString()
                                            .padStart(2, '0')}
                                    >
                                        {t(month)}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div className={styles.width25}>
                            <p>{t('activityTracking:PERIOD.YEAR')}</p>
                            <Select
                                className={styles.control}
                                name="year"
                                style={{ color: 'black' }}
                                value={
                                    formValidation.last_vet_visit_date
                                        ? formValidation
                                              .last_vet_visit_date!.getFullYear()
                                              .toString()
                                        : '--'
                                }
                                onChange={(e) => {
                                    const { value } = e.currentTarget;
                                    handleDate({
                                        value: parseFloat(value),
                                        isMonth: false,
                                        isYear: true,
                                        key: KeyDate.VET,
                                    });
                                }}
                            >
                                <option value={0}>--</option>
                                {allYears.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className={styles.textalignLeft}>
                    <p
                        className={styles.commonText}
                        style={{ marginBottom: '1rem!mportant' }}
                    >
                        {t('PET_HEALTH.LAST_VACINATION')}
                    </p>
                    <div className={styles.gridDate}>
                        <div className={styles.width25}>
                            <p>{t('activityTracking:PERIOD.MONTH')}</p>
                            <Select
                                className={styles.control}
                                name="month"
                                style={{ color: 'black' }}
                                onChange={(e) => {
                                    const { value } = e.currentTarget;
                                    handleDate({
                                        value: parseFloat(value),
                                        isMonth: true,
                                        isYear: false,
                                        key: KeyDate.VACCINATE,
                                    });
                                }}
                                value={
                                    formValidation.last_vaccination_date
                                        ? (
                                              formValidation.last_vaccination_date!.getMonth() +
                                              1
                                          )
                                              .toString()
                                              .padStart(2, '0')
                                        : '--'
                                }
                            >
                                <option value={0}>--</option>
                                {MonthTranslated.map((month, i) => (
                                    <option
                                        key={t(month)}
                                        value={(i + 1)
                                            .toString()
                                            .padStart(2, '0')}
                                    >
                                        {t(month)}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div className={styles.width25}>
                            <p>{t('activityTracking:PERIOD.YEAR')}</p>
                            <Select
                                className={styles.control}
                                name="year"
                                style={{ color: 'black' }}
                                value={
                                    formValidation.last_vaccination_date
                                        ? formValidation
                                              .last_vaccination_date!.getFullYear()
                                              .toString()
                                        : '--'
                                }
                                onChange={(e) => {
                                    const { value } = e.currentTarget;
                                    handleDate({
                                        value: parseFloat(value),
                                        isMonth: false,
                                        isYear: true,
                                        key: KeyDate.VACCINATE,
                                    });
                                }}
                            >
                                <option value={0}>--</option>
                                {allYears.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className={styles.buttonBlock}>
                    {animalInfo ? (
                        <Button
                            disabled={
                                formValidation.last_vaccination_date &&
                                formValidation.last_vet_visit_date &&
                                formValidation.is_sterilized !== null
                                    ? false
                                    : true
                            }
                            className={styles.buttonWidth}
                            onClick={() => handleSubmit()}
                        >
                            {t('SUMMARY.VALIDATION')}
                        </Button>
                    ) : (
                        <Button
                            disabled={
                                formValidation.last_vaccination_date &&
                                formValidation.last_vet_visit_date &&
                                formValidation.is_sterilized !== null
                                    ? false
                                    : true
                            }
                            className={styles.buttonWidth}
                            onClick={() => nextStep(AnimalFormStep.SUMMARY)}
                        >
                            {t('commonActions:NEXT')}
                        </Button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
