import cx from 'classnames';
import React, { HTMLAttributes, ReactNode } from 'react';

import { ReactComponent as DashIcon } from '../../icons/dash.svg';

import styles from './collapse.module.scss';

export interface ICollapseProps extends HTMLAttributes<HTMLDivElement> {
    panel: ReactNode;
    content: ReactNode;
    expanded: boolean;
    onExpand: (...args: any[]) => any;
}

export const Collapse = ({
    panel,
    content,
    className,
    expanded,
    onExpand,
}: ICollapseProps) => (
    <div className={cx(styles.collapse, className)}>
        <div className={styles.panel} onClick={onExpand}>
            <div className={styles.panelContainer}>{panel}</div>
            <DashIcon
                className={cx(styles.dash, {
                    [styles.rotated]: expanded,
                })}
            />
        </div>
        <div
            className={cx(styles.content, {
                [styles.expanded]: expanded,
            })}
        >
            {content}
        </div>
    </div>
);
