import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../common/typography/typography.component';

import styles from './activating-indicator.module.scss';

export interface IActivatingIndicatorProps
    extends HTMLAttributes<HTMLDivElement> {
    withHint?: boolean;
}

export const ActivatingIndicator = ({
    className,
    withHint,
    ...props
}: IActivatingIndicatorProps) => {
    const { t } = useTranslation('onboarding');

    const maybeRenderHint = () => {
        if (withHint) {
            return (
                <Typography className={styles.hint} gray size12 noMargin>
                    {t('WAITING_GPS_POSITION_HINT')}
                </Typography>
            );
        }
    };

    return (
        <div className={cx(className)} {...props}>
            <div className={styles.wrapper}>
                <div className={styles.indicator} />
                <Typography orange size14 noMargin>
                    {t('WAITING_GPS_POSITION')}
                </Typography>
            </div>
            {maybeRenderHint()}
        </div>
    );
};
