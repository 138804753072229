import cx from 'classnames';
import React from 'react';

import { ReactComponent as DashIcon } from '../../icons/dash.svg';
import { Button, IButtonProps } from './button.component';

import styles from './next-button.module.scss';

type Props = IButtonProps;

export const NextButton = ({ children, className, ...props }: Props) => (
    <Button
        block
        primary
        className={cx(className)}
        iconRight={<DashIcon className={styles.nextIcon} />}
        {...props}
    >
        {children}
    </Button>
);
