import React, { InputHTMLAttributes } from 'react';
import { getGlobalT } from '../../../../i18n/setup-translations';
import { CheckSection } from '../../../common/check-section/check-section.component';

import styles from './subscription-period-option.module.scss';

export interface ISubscriptionPeriodOptionProps
    extends InputHTMLAttributes<HTMLInputElement> {
    commitment?: string | null;
    component?: string;
    period: string;
    activeSubscription?: any;
    subtext?: string | null;
    priceCost: string;
    pricePeriod: string;
    amountMonth?: string;
    promoText?: string | null;
    info?: Array<string> | null;
}

export const SubscriptionPeriodOptionBiogaran = ({
    commitment,
    period,
    subtext,
    priceCost,
    component,
    activeSubscription,
    amountMonth,
    pricePeriod,
    promoText,
    className,
    ...props
}: ISubscriptionPeriodOptionProps) => {
    const t = getGlobalT();
    const maybeRenderSubtext = () => {
        if (subtext && !component) {
            return <div className={styles.subtext}>{subtext}</div>;
        }
    };
    const maybeRenderPromoText = () => {
        if (promoText && period === '2 ans' && !component) {
            return (
                <div className={styles['promo-text']}>
                    économisez 33,60€ / an !
                </div>
            );
        }
    };
    const maybeRenderInfoTextBiogaran = () => { };
    const priceByMonth = () => {
        if (amountMonth && period !== '1 mois') {
            return (
                <div className={styles['promo-saving']}>
                    {t('subscription:COMMON_TEXT.OR')} {amountMonth}{' '}
                    {t('subscription:COMMON_TEXT.MONTH')}
                </div>
            );
        }
    };

    const maybeRenderCommitmentText = () => {
        if (commitment) {
            return (
                <div className={styles['commitment-text']}>
                    Résiliation possible à tout moment à l’issue de la période
                    minimale d’engagement de 3 mois
                </div>
            );
        }
        if (component) {
            switch (period) {
                case '1 mois':
                    return (
                        <div className={styles['commitment-text-manage']}>
                            le paiement est mensuel et peut s’arrêter à tout
                            moment.
                        </div>
                    );
                case '1 an':
                    return (
                        <div className={styles['commitment-text-manage']}>
                            le paiement se fait une fois par an et peut
                            s’arrêter à chaque date anniversaire.
                        </div>
                    );
                default:
            }
        }
    };
    return (
        <>
            <CheckSection
                className={className}
                {...props}
                price={priceCost}
                period={period}
            >
                {maybeRenderPromoText()}
                <div className={styles.containerBiogaran}>
                    <div
                        className={
                            component ? styles['flex-manage'] : styles.flex
                        }
                    >
                        <div className={styles['period-container-biogaran']}>
                            <div className={styles['period-time']}>
                                {component ? 'Abonnement ' + period : period}
                            </div>
                            {maybeRenderSubtext()}
                        </div>
                        {/* @ts-ignore */}
                        <div
                            className={
                                component
                                    ? styles['price-container-biogaran-manage']
                                    : styles['price-container-biogaran']
                            }
                        >
                            <div>
                                <span className={styles['price-value']}>
                                    {priceCost}
                                </span>
                                <span>/ {pricePeriod}</span>
                            </div>

                            {priceByMonth()}
                            {maybeRenderCommitmentText()}
                            {maybeRenderInfoTextBiogaran()}
                        </div>
                    </div>
                </div>
            </CheckSection>
        </>
    );
};
