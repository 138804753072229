import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { compose } from 'recompose';

import { IClassicAPIError } from '../../../utils/errors-interface';
import { BackLink } from '../../common/back-link/back-link.component';
import { Headline } from '../../common/headline/headline.component';
import { IconBadge } from '../../common/icon-badge/icon-badge.component';
import { Link } from '../../common/link/link.component';
import { Modal } from '../../common/modal/modal.component';
import { Radio } from '../../common/radio/radio.component';
import { Typography } from '../../common/typography/typography.component';
import { ConnectedFluidTypeSwitch } from '../../fluid-type-switch/component/fluid-type-switch.component';
import { Grid } from '../../layout/grid/grid.component';
import { PageContainer } from '../../layout/page-container/page-container.component';
import { SimpleHeaderWithLogout } from '../../layout/simple-header/simple-header.component';
import { INotification } from '../../notifications/interfaces';
import { trackersRoute } from '../../routing/routes';
import { Dispatch, IRootState } from '../../store/store';
import { ImeiInfoModal } from '../../trackers/components/imei-info-modal/imei-info-modal.component';
import { TrackerType } from '../../trackers/interfaces';
import { ITracker } from '../../trackers/interfaces-api';
import {
    resolveTrackerColor,
    resolveTrackerIcon,
    TrackerIcon,
} from '../../trackers/tracker-icons';
import { resolveTrackerSellPage } from '../mappers/resolve-tracker-sell-url';
import {
    ControlledReplaceTrackerForm,
    IReplaceTrackerFormValues,
} from '../replace-tracker-form/replace-tracker-form.component';
import styles from './transfer-page.module.scss';

interface IStateProps {
    trackerList: ITracker[];
    isMobileRedirect: boolean;
}

interface IOuterProps { }

interface IActions {
    refreshTrackerList: () => any;
    clearNotifications: () => any;
    requestImeiReplacement: (trackerId: number, imei: string) => Promise<any>;
    showNotification: (notification: INotification) => any;
    doUpdateSingleTracker: (
        trackerId: number | undefined,
        newType: string,
    ) => Promise<any>;
}

export interface ITransferPageProps extends IStateProps, IActions { }

export const TransferPage = ({
    refreshTrackerList,
    trackerList,
    requestImeiReplacement,
    showNotification,
    clearNotifications,
    doUpdateSingleTracker,
    isMobileRedirect,
}: ITransferPageProps) => {
    const [imeiInfoModalOpen, setImeiInfoModalOpen] = useState<boolean>(false);
    const { t } = useTranslation('transferPage');
    const alertT = useTranslation('alertCustomer').t;
    const [selectedTracker, setselectedTracker] = useState<ITracker>(
        trackerList[0],
    );
    const [trackerToUpdate, setTrackerToUpdate] = useState<ITracker | null>(
        null,
    );
    const [fluidType, setFluidType] = useState(false);

    const detectAnimalTracker = (trackerListToCheck: ITracker[]) => {
        const matched = trackerListToCheck.find(
            (tracker) => tracker.type === TrackerType.ANIMAL,
        );

        if (matched !== undefined) {
            setTrackerToUpdate(matched);
            setFluidType(true);
            return;
        }
        return setFluidType(false);
    };

    useEffect(() => {
        detectAnimalTracker(trackerList);
    }, []);

    const onSubmit = async (values: IReplaceTrackerFormValues) => {
        clearNotifications();
        requestImeiReplacement(selectedTracker.id, values.imei)
            .then((resp) => {
                if (resp.type === TrackerType.ANIMAL) {
                    setTrackerToUpdate(resp);
                    setFluidType(true);
                }
                showNotification({
                    type: 'success',
                    content: t('MESSAGES.SUCCESS'),
                });
                refreshTrackerList();
            })
            .catch((error: Promise<IClassicAPIError>) => {
                error.then((APIError) => {
                    if (APIError.code === 'TRACKER_WRONG_TYPE') {
                        return showNotification({
                            type: 'error',
                            content: t('MESSAGES.WRONG_TYPE'),
                        });
                    }
                    return showNotification({
                        type: 'error',
                        content: t('MESSAGES.ERROR_UNKNOWN'),
                    });
                });
            });
    };

    const imeiInfoClicked = () => {
        setImeiInfoModalOpen(true);
    };

    const handleChange = (checkedTracker: ITracker) => {
        setselectedTracker(checkedTracker);
    };

    const maybeRenderImeiInfoModal = () => {
        if (imeiInfoModalOpen) {
            return (
                <Modal onClosed={() => setImeiInfoModalOpen(false)}>
                    <ImeiInfoModal
                        onAcceptClicked={() => setImeiInfoModalOpen(false)}
                    />
                </Modal>
            );
        }
    };

    // This modal can be used when we need to alert customers about a problem
    // For example a KORE maintenance...
    // IF YOU NEED TO ACTIVATE/DEACTIVATE THIS MODAL YOU MIGHT ALSO NEED TO ACTIVATE/DEACTIVATE
    // THE ONE IN ONBOARDING.COMPONENT.TSX.
    const [alertModalOpen, setAlertModalOpen] = useState(false); // Modify here to display or not the alert popup;

    const maybeRenderAlertModal = () => {
        if (alertModalOpen) {
            return (
                <Modal
                    onClosed={() => setAlertModalOpen(false)}
                    className={styles.alertPopup}
                >
                    <Headline level={2}>{alertT('BODY0')}</Headline>
                    <p>
                        <Trans ns="alertCustomer" i18nKey="BODY1">
                            Pendant cette maintenance ...
                            <strong>suite et fin</strong>.
                        </Trans>
                    </p>
                    <p>
                        <Trans ns="alertCustomer" i18nKey="BODY2">
                            We are well aware of that situation ...
                            <strong>email</strong>
                            ou par phone ...
                        </Trans>
                    </p>
                    <p>{alertT('FOOTER')}</p>
                </Modal>
            );
        }
    };
    const maybeRenderFluidTypeChoice = () => {
        if (fluidType) {
            return (
                <ConnectedFluidTypeSwitch
                    trackerToUpdate={trackerToUpdate?.id}
                    currentTrackerType={trackerToUpdate?.type!}
                    typeSwitchOutcome={() => setFluidType(false)}
                />
            );
        }
        return (
            <React.Fragment>
                {maybeRenderAlertModal()}
                <Headline className={styles['main-headline']} center>
                    {t('HEADLINE')}
                </Headline>
                <p className={styles['main-text']}>{t('INFO')}</p>
                <Grid className={styles.grid}>
                    <div className={styles.column}>
                        <Headline level={3} className={styles.subheadline}>
                            {t('FROM_HEADLINE')}
                        </Headline>
                        <div>
                            {trackerList.map((tracker) => (
                                <Radio
                                    className={styles['tracker-options']}
                                    name="preferredMetricSystem"
                                    key={tracker.id}
                                    checked={tracker.id === selectedTracker.id}
                                    isTracker={true}
                                    value={tracker.name}
                                    onChange={(e) => {
                                        handleChange(tracker);
                                    }}
                                >
                                    <div className={styles['radio-content']}>
                                        <IconBadge
                                            color={resolveTrackerColor(
                                                tracker.color,
                                                tracker.type,
                                            )}
                                            icon={
                                                <img
                                                    alt=""
                                                    src={resolveTrackerIcon(
                                                        tracker.url_picture,
                                                        tracker.icon as TrackerIcon,
                                                        tracker.type,
                                                    )}
                                                />
                                            }
                                        />
                                        <div
                                            className={styles['text-container']}
                                        >
                                            <Typography
                                                className={
                                                    styles['tracker-name']
                                                }
                                            >
                                                {tracker.name}
                                            </Typography>
                                            <label
                                                className={
                                                    styles['tracker-imei']
                                                }
                                            >
                                                IMEI: {tracker.imei}
                                            </label>
                                        </div>
                                    </div>
                                </Radio>
                            ))}
                        </div>
                    </div>
                    <div className={styles.column}>
                        <Headline level={3} className={styles.subheadline}>
                            {t('TO_HEADLINE')}
                        </Headline>
                        {/* @ts-ignore */}
                        <ControlledReplaceTrackerForm
                            onImeiInfoClicked={imeiInfoClicked}
                            onSubmit={onSubmit}

                        >
                            {renderBuyTrackerNotice()}
                        </ControlledReplaceTrackerForm>
                    </div>
                </Grid>
            </React.Fragment>
        );
    };

    const renderBuyTrackerNotice = () => {
        return (
            <p>
                <Trans i18nKey="transferPage:PURCHASE">
                    If you don't have new tracker yet you can make a purchase
                    <Link
                        target="_blank"
                        href={resolveTrackerSellPage(selectedTracker.type)}
                    >
                        here
                    </Link>
                </Trans>
            </p>
        );
    };

    return (
        <div>
            {
                isMobileRedirect ? null : (
                    <SimpleHeaderWithLogout
                        leftSlot={
                            <RouterLink to={trackersRoute}>
                                <BackLink black collapseOnMobile>
                                    {t('BACK_TO_TRACKERS')}
                                </BackLink>
                            </RouterLink>
                        }
                    />   
                    )
                }
            <PageContainer>{maybeRenderFluidTypeChoice()}</PageContainer>
            {maybeRenderImeiInfoModal()}
        </div>
    );
};

const mapState = () => {
    return (state: IRootState): IStateProps => {
        return {
            trackerList: state.userTrackers.rawTrackers,
            isMobileRedirect: state.layout.isMobileRedirect,
        };
    };
};
const mapDispatch = (dispatch: Dispatch) => ({
    requestImeiReplacement: (trackerId: number, imei: string) =>
        dispatch.trackerReplacement.setNewTrackerDevice({
            trackerId,
            imei,
        } as any),
    doUpdateSingleTracker: async (trackerId: number, newType: string) =>
        dispatch.trackersSettings.putSingleTracker({ trackerId, newType }),
    refreshTrackerList: () =>
        dispatch.userTrackers.fetchTrackers({ deepFetch: true }),
    showNotification: dispatch.notifications.showNotification,
    clearNotifications: dispatch.notifications.clearNotifications,
});

export const ConnectedTransferPage = compose<
    ITransferPageProps & IOuterProps,
    {}
>(connect(mapState, mapDispatch))(TransferPage);
