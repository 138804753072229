import { getGlobalT } from '../../i18n/setup-translations';
import { IPaymentType, PaymentCode } from './interfaces';

export const getBankTransferPayment = (): IPaymentType => {
    const t = getGlobalT();

    return {
        code: PaymentCode.GOCARDLESS,
        description: t('payments:TRANSFER.DESCRIPTION'),
        name: t('payments:TRANSFER.NAME'),
        accepted: '',
    };
};

export const getCardPayment = (): IPaymentType => {
    const t = getGlobalT();

    return {
        code: PaymentCode.HIPAY,
        description: t('payments:CARD.DESCRIPTION'),
        name: t('payments:CARD.NAME'),
        accepted: t('payments:CARD.ACCEPTED'),
    };
};

export const getPaymentTypes = (): IPaymentType[] => [
    getBankTransferPayment(),
    getCardPayment(),
];
