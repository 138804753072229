import cx from 'classnames';
import React, { HTMLAttributes } from 'react';

import styles from './grid.module.scss';

export interface IGridProps extends HTMLAttributes<HTMLDivElement> {}

/**
 * Simple grid with 2 columns - only this is used in designs at the moment.
 * TODO: Find some small, flexible library for grids if needed something more complex
 */
export const Grid = ({ children, className }: IGridProps) => (
    <div className={cx(styles.grid, className)}>{children}</div>
);
