export enum AccuracyRange {
    POOR = 'POOR',
    MEDIUM = 'MEDIUM',
    GOOD = 'GOOD',
}

export const accuracyMap = {
    GOOD: 15,
    MEDIUM: 30,
};
