import 'react-phone-input-2/lib/bootstrap.css';

import cx from 'classnames';
import React, { InputHTMLAttributes, ReactNode } from 'react';
// @ts-ignore We need to ignore this as the lib doesn't contain a definition file (.d.ts file)
import PhoneInput from 'react-phone-input-2';

import { Typography } from '../typography/typography.component';

import { ReactComponent as ErrorIcon } from '../../icons/cross-round-red.svg';
import { ReactComponent as SuccessIcon } from '../../icons/tick-round-green.svg';
import { store } from '../../store/store';
import styles from './input.module.scss';

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    shouldBeTypo?: boolean;
    isModified?: boolean;
    isSearchBar?: boolean;
    inputText?: string;
    inputTitle?: string;
    errorMessage?: string; // If provided, marks input as error and prints message
    isValid?: boolean; // If false, marks as error without message, if true, sets validated styles
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    searchBarLabel?: ReactNode;
    label?: ReactNode;
    small?: boolean;
    textArea?: boolean;
    nativeFieldClass?: string;
    isPhone?: boolean; // Used to create international phone number inputs using react-phone-input-2 lib.
    defaultCountry?: string; // Used to override default country of phone input (by default set on user.country)
}

/**
 * By design, input's icon (if exists) should be replace with validation icon (if validated)
 * If by future designs this changes, handle it optionally
 */
export const Input = ({
    isSearchBar,
    inputTitle,
    inputText,
    shouldBeTypo,
    isModified,
    className,
    errorMessage,
    id,
    isValid,
    searchBarLabel,
    label,
    leftIcon,
    nativeFieldClass,
    onBlur,
    onChange,
    onFocus,
    rightIcon,
    small,
    textArea,
    value,
    isPhone,
    defaultCountry,
    ...props
}: IInputProps) => {
    /**
     * TODO: Simplify these
     */
    const searchBar = Boolean(isSearchBar);
    const showTypo = Boolean(shouldBeTypo);
    const dontShowBorder = Boolean(isModified);
    const showErrorState = errorMessage || isValid === false;
    const showLeftIcon =
        leftIcon ||
        (isValid === true && value && (value as string).length > 0) ||
        showErrorState;
    const showRightIcon = Boolean(rightIcon);
    let validationIcon = null;

    if (showErrorState) {
        validationIcon = <ErrorIcon />;
    } else if (value && (value as string).length > 0) {
        validationIcon = <SuccessIcon />;
    }

    let leftIconToRender: ReactNode = null;

    if (isValid !== undefined) {
        leftIconToRender = validationIcon;
    } else {
        leftIconToRender = leftIcon;
    }

    const maybeRenderLeftIcon = () => {
        if (showLeftIcon) {
            return <div className={styles.leftIcon}>{leftIconToRender}</div>;
        }
    };

    const maybeRenderLabel = () => {
        if (searchBarLabel) {
            return (
                <label htmlFor={id} className={styles.searchBarLabel}>
                    {searchBarLabel}
                </label>
            );
        }
        if (label) {
            return (
                <label htmlFor={id} className={styles.label}>
                    {label}
                </label>
            );
        }
    };

    const maybeRenderRightIcon = () => {
        if (showRightIcon) {
            return <div className={styles.rightIcon}>{rightIcon}</div>;
        }
    };

    const maybeRenderErrorMessage = () => {
        if (errorMessage) {
            return (
                <span
                    data-testid="error-message"
                    className={styles.errorMessage}
                >
                    {errorMessage}
                </span>
            );
        }
    };
    const maybeRenderBorderInput = () => {
        if (dontShowBorder) {
            return React.createElement(textArea ? 'textarea' : 'input', {
                id,
                className: cx(
                    styles.inputField,
                    styles['is-modified'],
                    {
                        [styles.withError]: showErrorState,
                        [styles.withLeftIcon]: showLeftIcon,
                        [styles.withRightIcon]: showRightIcon,
                        [styles.small]: small,
                        [styles.textarea]: textArea,
                    },
                    nativeFieldClass,
                ),
                value,
                onBlur,
                onChange,
                onFocus,
                ...props,
            });
        }
        return React.createElement(textArea ? 'textarea' : 'input', {
            id,
            className: cx(
                styles.inputField,
                {
                    [styles.isSearchbar]: searchBar,
                    [styles.withError]: showErrorState,
                    [styles.withLeftIcon]: showLeftIcon,
                    [styles.withRightIcon]: showRightIcon,
                    [styles.small]: small,
                    [styles.textarea]: textArea,
                },
                nativeFieldClass,
            ),
            value,
            onBlur,
            onChange,
            onFocus,
            ...props,
        });
    };

    const maybeRenderBorderInputPhone = () => {
        if (dontShowBorder) {
            return (
                <PhoneInput
                    country={
                        defaultCountry ||
                        currentUser?.country?.toLocaleLowerCase() ||
                        'fr'
                    }
                    //@ts-ignore
                    value={value}
                    inputClass={cx(
                        styles.inputField,
                        styles['is-modified'],
                        {
                            [styles.withError]: showErrorState,
                            [styles.small]: small,
                            [styles.phone]: isPhone,
                        },
                        nativeFieldClass,
                    )}
                    onBlur={onBlur}
                    //@ts-ignore
                    onChange={onChange}
                    inputStyle={{ width: '100%' }}
                    // Add classic input properties as name, id, etc. passed in the element
                    inputProps={{
                        ...props,
                    }}
                    autoFormat={false}
                />
            );
        }
        return (
            <PhoneInput
                country={
                    defaultCountry ||
                    currentUser?.country?.toLocaleLowerCase() ||
                    'fr'
                }
                //@ts-ignore
                value={value}
                inputClass={cx(
                    styles.inputField,
                    {
                        [styles.withError]: showErrorState,
                        [styles.small]: small,
                        [styles.phone]: isPhone,
                    },
                    nativeFieldClass,
                )}
                onBlur={onBlur}
                //@ts-ignore
                onChange={onChange}
                inputStyle={{ width: '100%' }}
                // Add classic input properties as name, id, etc. passed in the element
                inputProps={{
                    ...props,
                }}
                autoFormat={false}
            />
        );
    };
    const maybeRenderTypoTitle = () => {
        if (inputTitle) {
            return (
                <Typography className={styles['input-title']}>
                    {inputTitle}
                </Typography>
            );
        }
    };
    const maybeRenderTypoText = () => {
        if (inputText) {
            return (
                <Typography className={styles['input-text']}>
                    {inputText}
                </Typography>
            );
        }
    };
    const maybeRenderAsTypography = () => {
        if (showTypo === true) {
            return (
                <div>
                    {maybeRenderTypoTitle()}
                    {maybeRenderTypoText()}
                </div>
            );
        }
        if (!isPhone) {
            return (
                <div className={cx(styles.input, className)}>
                    {maybeRenderLabel()}
                    <div className={styles.inputContainer}>
                        {maybeRenderLeftIcon()}
                        {maybeRenderBorderInput()}
                        {maybeRenderRightIcon()}
                    </div>
                    {maybeRenderErrorMessage()}
                </div>
            );
        }
        return (
            <div className={cx(styles.input, className)}>
                {maybeRenderLabel()}
                <div className={styles.inputContainer}>
                    {/* We use external library to create international phone input */}
                    {/* https://github.com/bl00mber/react-phone-input-2 */}
                    {maybeRenderBorderInputPhone()}
                </div>
                {maybeRenderErrorMessage()}
            </div>
        );
    };

    const currentUser = store.getState().user.userData;

    return maybeRenderAsTypography();
};
