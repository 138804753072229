import { IOptionOffer } from 'app/subscription-offers/interfaces';
import { FormikProps, withFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getGlobalT } from '../../../i18n/setup-translations';
import {
    formatPrice,
    normalizePriceAmount,
} from '../../../utils/normalize-price-amount';
import { Button } from '../../common/button/button.component';
import { PremiumPopup } from '../../common/popup-premium';
import { Toggle } from '../../common/toggle/toggle.component';
import { Typography } from '../../common/typography/typography.component';
import { INotification } from '../../notifications/interfaces';
import { Dispatch, IRootState } from '../../store/store';
import { makeGetTrackerNotificationsSettings } from '../../trackers/selectors/get-tracker-notifications-settings';
import { SubscriptionOptionType } from '../../user-subscriptions/interfaces-api';
import { IRawUserData } from '../../user/interfaces';
import {
    IAccountOptionOffers,
    IAccountOptions,
} from '../../user/interfaces-api';
import {
    getUserData,
    makeGetAccountOptions,
    makeGetAccountOptionsOffers,
} from '../../user/selectors/account-option-selector';
import { putAccountOption } from '../../user/user.service';
import { INotificationSettingsDTO } from '../interfaces';
import styles from './notifications-settings.module.scss';
import { ConnectedSmsNotification } from './sms-notification.component';

interface IFormValues extends INotificationSettingsDTO { }

interface IStateProps {
    existingSettings: INotificationSettingsDTO;
    accountOption: IAccountOptions[] | null | undefined;
    accountOffert: IAccountOptionOffers[] | null | undefined;
    user: IRawUserData | null;
    currentUserSite: string | undefined;
}

interface IOuterProps {
    trackerID: number;
    hasButtons: boolean;
    hasSos: boolean;
}

interface IActions {
    setUserData: (user: IRawUserData) => void;
    clearNotifications(): unknown;
    showNotification(notification: INotification): Promise<unknown>;
    doUpdateNotificationSettings(
        trackerIdToUpdate: number,
        dto: INotificationSettingsDTO,
    ): Promise<unknown>;
}

interface IProps
    extends FormikProps<IFormValues>,
    IOuterProps,
    IStateProps,
    IActions { }

export const NotificationsSettings = ({
    values,
    handleSubmit,
    accountOffert,
    accountOption,
    handleChange,
    existingSettings,
    trackerID,
    doUpdateNotificationSettings,
    hasButtons,
    user,
    setUserData,
    hasSos,
}: IProps) => {
    const { t } = useTranslation(['trackerSettings']);
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const handleChangeSettings = (
        settingsKey: keyof INotificationSettingsDTO,
    ) => {
        existingSettings[settingsKey] = !existingSettings[settingsKey];
        const newValue = existingSettings;
        doUpdateNotificationSettings(trackerID, newValue);
    };
    const mayRenderPremiumPopup = () => {
        if (openPopup && accountOffert) {
            if (accountOffert[0]) {
                return (
                    <PremiumPopup
                        price={formatPrice(
                            normalizePriceAmount(+accountOffert[0].price_offer),
                            accountOffert[0].price_offer.fr.currency,
                        )}
                        onClosed={() => setOpenPopup(false)}
                        onClick={() => {
                            if (user) {
                                putAccountOption({
                                    account_options: [
                                        SubscriptionOptionType.PREMIUMPACK,
                                    ],
                                    site: user.site,
                                }).then((res: IAccountOptions[]) => {
                                    Object.assign(user.account_options, res);
                                    setOpenPopup(false);
                                    return setUserData(user);
                                });
                            }
                        }}
                    />
                );
            }
        }
    };

    const maybeRenderSosSection = () => {
        if (hasSos) {
            return (
                <div className={styles.section}>
                    <Typography noMargin>
                        {t('NOTIFICATION_SETTINGS.SOS.TITLE')}
                    </Typography>
                    <Typography withMarginBottom gray size12>
                        {t('NOTIFICATION_SETTINGS.SOS.HINT')}
                    </Typography>
                    <div className={styles.checkboxes}>
                        <div className={styles['toggle-section']}>
                            <Typography noMargin>
                                {t('NOTIFICATION_SETTINGS.APP')}
                            </Typography>
                            <Toggle
                                checked={values.sos_appli_notification}
                                onChange={handleChange}
                                name="sos_appli_notification"
                            />
                        </div>
                        <div className={styles['toggle-section']}>
                            <Typography noMargin>
                                {t('NOTIFICATION_SETTINGS.MAIL')}
                            </Typography>
                            <Toggle
                                checked={values.sos_mail_notification}
                                onChange={handleChange}
                                name="sos_mail_notification"
                            />
                        </div>
                        <ConnectedSmsNotification
                            className={styles['toggle-section']}
                            title={t('NOTIFICATION_SETTINGS.SMS')}
                            setOpenPopup={() => setOpenPopup(true)}
                            accountOption={accountOption}
                            isChecked={
                                values.sos_sms_notification &&
                                accountOption &&
                                accountOption[0]?.is_running
                                    ? true
                                    : false
                            }
                            handleChange={handleChange}
                            sendValue={handleChangeSettings}
                            name="sos_sms_notification"
                        />
                    </div>
                </div>
            );
        }
    };

    const maybeRenderButtonsSection = () => {
        if (hasButtons) {
            return (
                <div className={styles.section}>
                    <Typography noMargin>
                        {t('NOTIFICATION_SETTINGS.BUTTONS.TITLE')}
                    </Typography>
                    <Typography withMarginBottom gray size12>
                        {t('NOTIFICATION_SETTINGS.BUTTONS.HINT')}
                    </Typography>
                    <div className={styles.checkboxes}>
                        <div className={styles['toggle-section']}>
                            <Typography noMargin>
                                {t('NOTIFICATION_SETTINGS.APP')}
                            </Typography>
                            <Toggle
                                checked={values.button_appli_notification}
                                onChange={handleChange}
                                name="button_appli_notification"
                            />
                        </div>
                        <div className={styles['toggle-section']}>
                            <Typography noMargin>
                                {t('NOTIFICATION_SETTINGS.MAIL')}
                            </Typography>
                            <Toggle
                                checked={values.button_mail_notification}
                                onChange={handleChange}
                                name="button_mail_notification"
                            />
                        </div>
                        <ConnectedSmsNotification
                            className={styles['toggle-section']}
                            setOpenPopup={() => setOpenPopup(true)}
                            accountOption={accountOption}
                            title={t('NOTIFICATION_SETTINGS.SMS')}
                            isChecked={
                                values.button_sms_notification &&
                                accountOption &&
                                accountOption[0]?.is_running
                                    ? true
                                    : false
                            }
                            sendValue={handleChangeSettings}
                            handleChange={handleChange}
                            name="button_sms_notification"
                        />
                    </div>
                </div>
            );
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {maybeRenderSosSection()}
            {maybeRenderButtonsSection()}
            <div className={styles.section}>
                <Typography noMargin>
                    {t('NOTIFICATION_SETTINGS.GEOFENCES.TITLE')}
                </Typography>
                <Typography withMarginBottom gray size12>
                    {t('NOTIFICATION_SETTINGS.GEOFENCES.HINT')}
                </Typography>
                <div className={styles.checkboxes}>
                    <div className={styles['toggle-section']}>
                        <Typography noMargin>
                            {t('NOTIFICATION_SETTINGS.APP')}
                        </Typography>
                        <Toggle
                            checked={values.area_appli_notification}
                            onChange={handleChange}
                            name="area_appli_notification"
                        />
                    </div>
                    <div className={styles['toggle-section']}>
                        <Typography noMargin>
                            {t('NOTIFICATION_SETTINGS.MAIL')}
                        </Typography>
                        <Toggle
                            checked={values.area_mail_notification}
                            onChange={handleChange}
                            name="area_mail_notification"
                        />
                    </div>
                    <ConnectedSmsNotification
                        className={styles['toggle-section']}
                        title={t('NOTIFICATION_SETTINGS.SMS')}
                        setOpenPopup={() => setOpenPopup(true)}
                        accountOption={accountOption}
                        isChecked={
                            values.area_sms_notification &&
                            accountOption &&
                            accountOption[0]?.is_running
                                ? true
                                : false
                        }
                        sendValue={handleChangeSettings}
                        handleChange={handleChange}
                        name="area_sms_notification"
                    />
                </div>
            </div>
            <div className={styles.section}>
                <Typography noMargin>
                    {t('NOTIFICATION_SETTINGS.BATTERY.TITLE')}
                </Typography>
                <Typography withMarginBottom gray size12>
                    {t('NOTIFICATION_SETTINGS.BATTERY.HINT')}
                </Typography>
                <div className={styles.checkboxes}>
                    <div className={styles['toggle-section']}>
                        <Typography noMargin>
                            {t('NOTIFICATION_SETTINGS.APP')}
                        </Typography>
                        <Toggle
                            checked={values.battery_appli_notification}
                            onChange={handleChange}
                            name="battery_appli_notification"
                        />
                    </div>
                    <div className={styles['toggle-section']}>
                        <Typography noMargin>
                            {t('NOTIFICATION_SETTINGS.MAIL')}
                        </Typography>
                        <Toggle
                            checked={values.battery_mail_notification}
                            onChange={handleChange}
                            name="battery_mail_notification"
                        />
                    </div>
                    <ConnectedSmsNotification
                        className={styles['toggle-section']}
                        title={t('NOTIFICATION_SETTINGS.SMS')}
                        setOpenPopup={() => setOpenPopup(true)}
                        accountOption={accountOption}
                        sendValue={handleChangeSettings}
                        isChecked={
                            values.battery_sms_notification &&
                            accountOption &&
                            accountOption[0]?.is_running
                                ? true
                                : false
                        }
                        handleChange={handleChange}
                        name="battery_sms_notification"
                    />
                </div>
            </div>
            <Button className={styles.submit} small type="submit">
                {t('commonActions:SAVE')}
            </Button>
            {mayRenderPremiumPopup()}
        </form>
    );
};

const mapState = (state: IRootState, props: IOuterProps): IStateProps => {
    const getSettings = makeGetTrackerNotificationsSettings(props.trackerID);
    const getAccountOptionOffer = makeGetAccountOptionsOffers();
    const getAccountOption = makeGetAccountOptions();

    const user = getUserData(state);
    return {
        currentUserSite: state.user?.userData?.site,
        existingSettings: getSettings(state),
        accountOption: getAccountOption(state),
        accountOffert: getAccountOptionOffer(state),
        user,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    setUserData: async (data: IRawUserData) => {
        dispatch.user.setUserData(data);
        return data;
    },
    clearNotifications: async () => dispatch.notifications.clearNotifications,
    showNotification: async (n: INotification) =>
        dispatch.notifications.showNotification(n),
    doUpdateNotificationSettings: async (
        trackerIdToUpdate: number,
        dto: INotificationSettingsDTO,
    ) =>
        dispatch.trackersSettings.updateNotificationSettings({
            trackerId: trackerIdToUpdate,
            settings: dto,
        }),
});

export const ConnectedNotificationsSettings = compose<IProps, IOuterProps>(
    connect(mapState, mapDispatch),
    withFormik<IStateProps & IOuterProps & IActions, IFormValues>({
        handleSubmit(values, formikBag) {
            const t = getGlobalT();
            formikBag.props
                .doUpdateNotificationSettings(formikBag.props.trackerID, values)
                .then(() => {
                    formikBag.props.showNotification({
                        type: 'success',
                        content: t(
                            'trackerSettings:NOTIFICATION_SETTINGS.MESSAGES.UPDATE_SUCCESS',
                        ),
                    });
                })
                .catch(() => {
                    formikBag.props.showNotification({
                        type: 'error',
                        content: t(
                            'trackerSettings:NOTIFICATION_SETTINGS.MESSAGES.UPDATE_ERROR_UNKNOWN',
                        ),
                    });
                });
        },
        mapPropsToValues(props) {
            return {
                ...props.existingSettings,
            };
        },
        enableReinitialize: true,
    }),
)(NotificationsSettings);
