import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { compose } from 'recompose';

import { IRootState } from '../../store/store';
import { Theme } from '../../trackers/interfaces';
import { ISubscriptionDetails } from '../../user-subscriptions/interfaces';
import { makeGetSubscriptionDetailsFromTracker } from '../../user-subscriptions/selectors/user-subscriptions.selectors';

interface IStateProps {
    activeTheme: Theme | null;
    subscriptionDetails: any | null;
    trackerId: number | null;
}




const mapState = (state: IRootState, props: any): IStateProps => {
    const getSubscriptionDetailsFromTracker = makeGetSubscriptionDetailsFromTracker(
        props.trackerId,
    );
    const subscriptionDetails = getSubscriptionDetailsFromTracker(
        state,
    ) as ISubscriptionDetails;

    return {
        subscriptionDetails,
        trackerId: props.trackerId,
        activeTheme: state.userTrackers.theme,
    };
};

const bioggyguard = compose(connect(mapState));

export { bioggyguard as biogaranGuardRaw };
