import { defaultViewport } from 'app/map/map.store';
import { FitBoundsOptions, LatLng } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { Map, Map as LMap, MapProps, Pane, Viewport } from 'react-leaflet';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { compose } from 'recompose';

import { ConnectedGeofencesMapMarkersList } from '../../../geofences/geofences-map-markers-list/geofences-map-markers-list.component';
import { ConnectedActivityPath } from '../../../history/activity-path/activity-path.component';
import { ConnectedHeatMap } from '../../../history/heatmap/heatmap.component';
import { getIsHeatmapVisible } from '../../../history/selectors';
import { Dispatch, IRootState } from '../../../store/store';
import { makeGetSelectedTrackerId } from '../../../trackers/selectors/get-selected-tracker-id';
import { makeGetTrackersPositions } from '../../../trackers/selectors/get-trackers-positions';
import { ConnectedUserMarker } from '../../../user-location/user-marker.component';
import { ConnectedWifiZonesMapMarkersList } from '../../../wifi-zones/wifi-zones-map-markers-list/wifi-zones-map-markers-list.component';
import { IOuterMapProps, IViewport, MapLayerType } from '../../interfaces';
import { getTilesLayer } from '../map/get-tiles-layer';
import styles from '../map/map.module.scss';
import { TrackerOnMap } from '../trackers-on-map/trackers-on-map.component';

/**
 * Higher then markers, so icon can be still draggable and on top.
 */
const geofencesIconsPaneStyles = {
    zIndex: 700,
};

/**
 * Below markers, so don't hide icons and events
 */
const geofencesRadiusPaneStyles = {
    zIndex: 500,
};

const boundsSettings: FitBoundsOptions = {
    padding: [100, 100],
    maxZoom: 22,
};

interface IStateProps {
    activeTrackerId: number | null;
    heatMapEnabled: boolean;
    isGeofenceEditing: boolean;
    isTrackerSelected: boolean;
    positions: Array<[number, number]>;
    selectedLayer: MapLayerType;
    viewport: IViewport;
}

interface IActions {
    onViewportManuallyChanged: (viewport: Viewport) => any;
    updateGeofenceOnClick: (newPosition: LatLng) => any;
    setZoom: (value: number) => unknown;
}

export const LeafletMap = ({
    activeTrackerId,
    events,
    isGeofenceEditing,
    isTrackerSelected,
    positions,
    selectedLayer,
    shouldDisplayHeatMap,
    trackersOnMap,
    viewport,
    setZoom,
    displayEventTooltip,
    onTrackerSelected,
    onViewportManuallyChanged,
    updateGeofenceOnClick,
    ...props
}: MapProps & IStateProps & IActions & IOuterMapProps) => {
    const [bounds, setBounds] = useState<Array<[number, number]> | undefined>(
        undefined,
    );
    const [viewportState, setViewport] = useState<
        (IViewport | Viewport) | undefined
    >(defaultViewport);

    const { id } = useParams();
    let historyOpen = useLocation().pathname.includes('history');
    const prevZoom: any = useRef(viewport.zoom);
    useEffect(() => {
        /**
         * If no tracker is selected, handle bounds.
         * Don't react if positions is empty - it means that trackers are not loaded yet
         */
        if (prevZoom.current !== viewport.zoom && !isTrackerSelected) {
            if (ref.current.leafletElement) {
                ref.current.leafletElement.on('zoomend', function () {
                    setZoom(ref.current.leafletElement.getZoom());
                });
            }
        }
        if (!isTrackerSelected && positions.length) {
            /**
             * Set local bounds state calculated from all trackers positions
             */
            setBounds(positions);
            /**
             * Reset viewport from Redux, since it can only calculate single tracker position
             */
            setViewport({
                ...viewport,
                zoom: ref.current.leafletElement.getZoom(),
            });
        }
    }, [id, viewport]);

    useEffect(() => {
        if (ref.current.leafletElement.getZoom() !== viewport?.zoom) {
            setViewport({ ...viewport, zoom: viewport.zoom });
        }
    }, [viewport.zoom, isTrackerSelected]);

    useEffect(() => {
        /**
         * If tracker is selected, show viewport from Redux
        //  */
        if (isTrackerSelected) {
            /**
             * Reset local bounds state so map doesn't use it
             */
            setBounds(undefined);
            /**
             * Set local state with viewport again
             * (Only when needed to avoid useless re-render).
             */
            if (
                !trackersOnMap ||
                (!trackersOnMap[0]?.position && !historyOpen)
            ) {
                setViewport(defaultViewport);
            }
            if (
                trackersOnMap &&
                trackersOnMap[0] &&
                trackersOnMap[0].position &&
                viewport.center !== trackersOnMap[0].position
            ) {
                setViewport({
                    center: trackersOnMap[0].position,
                    zoom: viewport.zoom,
                });
            }
        }
    }, [id, viewport.center, isTrackerSelected]); // Don't add viewportState dependency to the array. It causes rendering issues.
    const ref: any = useRef(null); // we use ref : any , to prevent object possibly null below
    useEffect(() => {
        setTimeout(() => {
            if (!ref.current) {
                return;
            }

            const leafletNativeMap: LMap = (ref.current as any)
                .leafletElement as LMap;

            /**
             * Fix problem with not loading tiles by leaflet
             */
            // invalidateSize method Checks if the map container size changed and updates the map if so (prevent grey tiles rendering)
            if (ref?.current) {
                // we use optionnal chaining to make sure current already exist on ref before
                ref.current.leafletElement.invalidateSize();
            }
        }, 0);
    });

    /**
     * Update viewport state (from map.store.ts) when user zoom or move the map manually.
     * Only when tracker is selected has we only use viewport state in this case.
     */
    const reactOnViewportChange = (newViewport: Viewport) => {
        if (!isTrackerSelected) {
            return onViewportManuallyChanged(newViewport);
        }
    };

    const maybeRenderHeatMap = () => {
        if (shouldDisplayHeatMap) {
            return <ConnectedHeatMap trackerId={activeTrackerId!} />;
        }
    };
    return (
        <Map
            maxZoom={22}
            ref={ref}
            className={styles.map}
            zoomControl={false}
            bounds={bounds}
            boundsOptions={boundsSettings}
            viewport={viewportState}
            onViewportChange={(newViewport) =>
                reactOnViewportChange(newViewport)
            }
            onclick={(event) => {
                // Set position of the geofence currently edited
                // when customer clicks on the map.
                if (isGeofenceEditing) {
                    updateGeofenceOnClick(event.latlng);
                }
            }}
            {...props}
        >
            {getTilesLayer(selectedLayer)}
            <Pane name="geofences-icons" style={geofencesIconsPaneStyles} />
            <Pane name="geofences-radius" style={geofencesRadiusPaneStyles} />

            <ConnectedGeofencesMapMarkersList />

            <Pane name="wifiZones-icons" style={geofencesIconsPaneStyles} />
            <Pane name="wifiZones-radius" style={geofencesRadiusPaneStyles} />

            <ConnectedWifiZonesMapMarkersList />
            {maybeRenderHeatMap()}
            <ConnectedUserMarker />

            {trackersOnMap?.map((trackerOnMap) => {
                if (trackerOnMap) {
                    return (
                        <TrackerOnMap
                            key={trackerOnMap?.id}
                            trackerOnMap={trackerOnMap}
                            onTrackerSelected={onTrackerSelected}
                        />
                    );
                }
            })}

            <ConnectedActivityPath
                events={events}
                isHeatMapActivated={shouldDisplayHeatMap}
                leaflet={ref.current as any}
                displayEventTooltip={displayEventTooltip}
            />
        </Map>
    );
};

const mapState = (state: IRootState): IStateProps => {
    const getTrackersPositions = makeGetTrackersPositions();
    const getActiveTrackerId = makeGetSelectedTrackerId();
    const activeTrackerId = getActiveTrackerId(state);
    return {
        activeTrackerId,
        heatMapEnabled: getIsHeatmapVisible(state),
        isGeofenceEditing: state.geofenceEdit.duringNewCreating,
        isTrackerSelected: Boolean(activeTrackerId),
        positions: getTrackersPositions(state),
        selectedLayer: state.map.selectedLayer,
        viewport: { center: state.map.center, zoom: state.map.zoom },
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    setZoom: dispatch.map.setZoom as any,
    onViewportManuallyChanged: (viewport: Viewport) =>
        dispatch.map.setViewportByUserAction(viewport),
    updateGeofenceOnClick: (newPosition: LatLng) =>
        dispatch.geofenceEdit.centerOnMapClick(newPosition),
});

export const ConnectedLeafletMap = compose<MapProps, IOuterMapProps>(
    connect(mapState, mapDispatch),
)(LeafletMap);
