import { ModelConfig } from '@rematch/core';

import { getGlobalT } from '../../i18n/setup-translations';
import { INewOption } from '../manage-subscription/manage-subscription.store';
import { IRootState } from '../store/store';
import { redirectToPaymentGate } from '../subscription-process/services/order-subscription.service';
import { IProcessPayment } from '../subscription-process/steps/hipay-step/interfaces';
import { ITracker } from '../trackers/interfaces-api';
import { makeGetAllTrackerSubscriptionsIds } from '../trackers/selectors/get-all-tracker-subs-ids';
import {
    IRawUserSubscription,
    IRawUserSubscriptionOption,
    SubscriptionOptionType,
} from './interfaces-api';
import { makeGetFirstActiveSubscription } from './selectors/user-subscriptions.selectors';
import {
    cancelSubscription,
    changeBankAccount,
    changeCreditCard,
    fetchSubscriptionDetails,
    getIsEligibleToChangeMean,
    postChangeMean,
    updateSubscriptionOptions,
} from './subscription.service';

export interface ISubscriptionModel {
    subscriptionsById: {
        [subscriptionId: number]: IRawUserSubscription;
    };
    // Define an account wide variable
    // to determine if at least one tracker of the account has the map option activated.
    hasMapOptionActivated: boolean;
    hasMapOptionRunning: boolean;
}

export const userSubscriptionsStore: ModelConfig<ISubscriptionModel> = {
    state: {
        subscriptionsById: {},
        hasMapOptionActivated: false,
        hasMapOptionRunning: false,
    },
    effects: (dispatch: any) => ({
        setSubscriptionBasedOnStore(rawSubscription: IRawUserSubscription) {
            this.setSubscription(rawSubscription);
        },

        // The above function has to be called based on a v4/myTracker call or the response of a call that returns the subscription object.

        // The below function is to be deleted once every call that modifies
        // the subscription has been modified to return the modified object so we can use the above function.

        fetchSubscriptionDetails(subscriptionId: number) {
            return fetchSubscriptionDetails({
                subscriptionId,
            }).then((rawSubscription: IRawUserSubscription) => {
                this.setSubscription(rawSubscription);
            });
        },
        fetchAllUserSubscriptions(
            payload: { force: boolean } = { force: false },
            models: IRootState,
        ) {
            const getSubscriptionIds = makeGetAllTrackerSubscriptionsIds();
            const subscriptionsIds = getSubscriptionIds(models);

            const subscriptionsToFetch = subscriptionsIds.filter((sub) => {
                if (payload.force) {
                    return true;
                }

                return !Object.keys(
                    models.userSubscriptions.subscriptionsById,
                ).find((id) => parseInt(id, 10) === sub);
            });

            return Promise.all(
                subscriptionsToFetch.map((id) =>
                    dispatch.userSubscriptions.fetchSubscriptionDetails(id),
                ),
            );
        },
        fetchSubscriptionOfActiveTracker(payload, model) {
            const activeTrackerData = model.userTrackers.rawTrackers.find(
                (t: ITracker) => t.id === model.userTrackers.activeTracker,
            );

            if (
                activeTrackerData &&
                activeTrackerData.subscription &&
                activeTrackerData.subscription.id
            ) {
                return dispatch.userSubscriptions.fetchSubscriptionDetails(
                    activeTrackerData.subscription.id,
                );
            }
        },
        toggleSubscriptionOption(
            {
                option,
                subscriptionId,
            }: {
                option: SubscriptionOptionType;
                subscriptionId: number;
            },
            model: any,
        ) {
            const currentSub =
                model.userSubscriptions.subscriptionsById[subscriptionId];
            const currentlyActiveOptions: SubscriptionOptionType[] = currentSub.options
                .filter(
                    (optionModel: IRawUserSubscriptionOption) =>
                        optionModel.activated,
                )
                .map(
                    (optionModel: IRawUserSubscriptionOption) =>
                        optionModel.code,
                );

            let newOptions = [];

            // Remove the option from the list of activated option if it is already activated.
            // (Toggle from activated to not activated)
            if (currentlyActiveOptions.includes(option)) {
                newOptions = currentlyActiveOptions.filter(
                    (o: string) => o !== option,
                );
            } else {
                // Add the option to the list of activated option if it is not.
                // (Toggle from not activated to activated)
                newOptions = [...currentlyActiveOptions, option];
            }

            return updateSubscriptionOptions(currentSub.id, newOptions)
                .then((newSubscriptionData: IRawUserSubscription) => {
                    this.setSubscription(newSubscriptionData);
                    return true;
                })
                .catch((err) => {
                    return false;
                });
        },
        /**
         * Retrieve active options of desired subscription
         * and add options the customer wants to add
         * and deactivate options the customers wants to remove
         */
        modifySubscriptionOptions(
            payload: {
                options: INewOption[];
                subscriptionId: number;
            },
            models: IRootState,
        ) {
            const currentSub =
                models.userSubscriptions.subscriptionsById[
                payload.subscriptionId
                ];
            const currentlyActiveOptions: SubscriptionOptionType[] = currentSub.options
                .filter(
                    (optionModel: IRawUserSubscriptionOption) =>
                        optionModel.activated,
                )
                .map(
                    (optionModel: IRawUserSubscriptionOption) =>
                        optionModel.code,
                );
            const optionsToAdd = payload.options
                .filter((option: INewOption) => option.activated === true)
                .map((option: INewOption) => option.code);
            const optionsToRemove = payload.options
                .filter((option: INewOption) => option.activated === false)
                .map((option: INewOption) => option.code);

            // newOptions will be modified
            let newOptions = currentlyActiveOptions;

            // Remove options to remove
            if (optionsToRemove.length > 0) {
                newOptions = currentlyActiveOptions.filter(
                    (option) =>
                        optionsToRemove.findIndex(
                            (optionToRemove) => option === optionToRemove,
                        ) === -1,
                );
            }

            // Add options to add
            newOptions = newOptions.concat(optionsToAdd);

            // Remove possible duplicates
            newOptions = newOptions.filter(
                (option, index) => newOptions.indexOf(option) === index,
            );

            // Call API
            updateSubscriptionOptions(currentSub.id, newOptions)
                .then((newSubscriptionData: IRawUserSubscription) => {
                    this.setSubscription(newSubscriptionData);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async cancelSubscription({
            subscriptionId,
            cancelReason,
            cancelExplanation,
        }: {
            subscriptionId: number;
            cancelReason?: string;
            cancelExplanation?: string;
            props: any;
        }) {
            const t = getGlobalT();
            return cancelSubscription(
                subscriptionId,
                cancelReason,
                cancelExplanation,
            )
                .then(() => {
                    return fetchSubscriptionDetails({
                        subscriptionId,
                    }).then((data) => {
                        this.setSubscription(data);
                    });
                })
                .catch(() => {
                    dispatch.notifications.showNotification({
                        type: 'error',
                        content: t('errors:GENERAL'),
                    });
                });
        },
        /* When user wants to pay using an other credit card */
        changeCreditCard(paymentPayload: IProcessPayment) {
            return changeCreditCard(paymentPayload);
        },
        changeBankAccount() {
            return changeBankAccount().then((resp) => {
                redirectToPaymentGate(resp.redirect_url)
            }
            );
        },
        async isEligibleToChangeMean(
            subscriptionId: number,
            models: IRootState,
        ) {
            // API returns 'True' or 'False' (string) depending on eligibility.
            // this function returns true or false (boolean).
            return getIsEligibleToChangeMean().then(
                (resp) => resp === 'True',
            );
        },
        async changeMean() {
            // API returns either an URL to redirect to, or a subscription object (the one modified by change mean)
            return postChangeMean().then((resp) => {
                if (resp.redirect_url) {
                    return redirectToPaymentGate(resp.redirect_url);
                }
                return resp;
            });
        },
    }),
    reducers: {
        setSubscription: (
            state: ISubscriptionModel,
            rawSubscription: IRawUserSubscription,
        ) => {
            const newState = { ...state };
            newState.subscriptionsById[rawSubscription.id] = rawSubscription;
            return newState;
        },
        /**
         * Loop over options of a subscription.
         * Set hasMapOption = true if the Map option of that subscription is activated
         * OR if expiration_date is in the future.
         */
    },
};
