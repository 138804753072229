import { getGlobalT } from '../i18n/setup-translations';

// funct that take minute and convert it to return day, hours , minutes value with translate
export const convertMinutes = (num: number) => {
    const t = getGlobalT();
    const numFormated = Math.abs(num);
    const day = Math.floor(numFormated / 1440);
    const hours = Math.floor((numFormated - day * 1440) / 60);
    const min = Math.round(numFormated % 60);
    const dayTranslated = day ? day + `${t('activity:SUMMARY.DAY_INDEX')}` : '';
    const hoursTranslated = hours
        ? Math.abs(hours) + ` ${t('activity:SUMMARY.HOUR_INDEX')}`
        : '';
    const minuteTranslated =
        min && !hours
            ? min + ` ${t('activityTracking:MIN')}`
            : min && (hours || day)
            ? min + `${t('activity:SUMMARY.MIN_INDEX')}`
            : '';
    if (!dayTranslated && !hoursTranslated && !minuteTranslated) {
        return 0 + `${t('activity:SUMMARY.MIN_INDEX')}`;
    }
    return dayTranslated + ' ' + hoursTranslated + ' ' + minuteTranslated;
};
