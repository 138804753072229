import { makeGetIsTrackerSubExpired } from 'app/trackers/selectors/get-is-tracker-sub-expired';
import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { compose } from 'recompose';

import { IRootState } from '../store/store';

interface IStateProps {
    isExpired: boolean;
}

interface IHOCProps extends IStateProps { }

const withExpiredSubRedirect = (Component: ComponentType<any>) => ({
    isExpired,
    ...props
}: IHOCProps) => {
    const history = useNavigate()
    const { id } = useParams()
    const match = useLocation()
    
    useEffect(() => {
        if (id && isExpired) {
            history(`/trackers/${id}/subscription${match.search}`);
        }
    }, [isExpired, id]);
    return <Component  {...props} />
};

const mapState = (state: IRootState): IStateProps => {
    const idUrl = window.location.pathname.split('/')[2];
    const id = parseInt(idUrl, 10)
    if (!id) {
        return {
            isExpired: false,
        };
    }
    const getIsTrackerSubExpired = makeGetIsTrackerSubExpired(
        id,
    );
    return {
        isExpired: getIsTrackerSubExpired(state),

    }
};

const connectedWithExpiredSubRedirect = compose(
    connect(mapState),
    withExpiredSubRedirect,
);

export { connectedWithExpiredSubRedirect as withExpiredSubRedirect };