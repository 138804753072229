import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import { convertDateUtc, getTimezone } from '../../../../utils/convertDateUtc';

import { Dispatch, IRootState } from '../../../store/store';
import { IEvolution, ISummaryData, Period } from '../../interface';
import { IAggregateData } from '../../interface';
import styles from './period-block.module.scss';

interface IState {
    period: Period;
    translatedPeriodSelected: string;
}
interface IOuterProps {
    trackerId: number;
}
interface IActions {
    getEvolutionData: (
        startDate: string,
        endDate: string,
        trackerId: number,
    ) => IEvolution;
    changeTranslatedDate: (translatedDate: string, savedDate: Date) => void;
    getSummaryData: (
        startDate: string,
        endDate: string,
        trackerId: number,
    ) => ISummaryData;
    changePeriod: any;
    getAggregateData: (
        startDate: string,
        endDate: string,
        trackerId: number,
        periodUnit: string,
    ) => IAggregateData;
}

export interface IMappedProps extends IState, IActions, IOuterProps {}

export const PeriodBlockComponent = ({
    changePeriod,
    getEvolutionData,
    getAggregateData,
    getSummaryData,
    changeTranslatedDate,
    period,
    trackerId,
}: IMappedProps) => {
    // todo replace that with day , weeks ,month
    const { t } = useTranslation('activity');
    const periods = ['DAY', 'WEEK', 'MONTH'];
    const [selectedPeriod, setSelectedperiod] = useState<Period>(period);
    useEffect(() => {
        getStartAndEndDate();
        changePeriod(selectedPeriod);
    }, [selectedPeriod]);

    const getStartAndEndDate = () => {
        let newValue;
        let start = new Date();
        let end = new Date();
        if (selectedPeriod === Period.DAY) {
            start = new Date();
            end = new Date();
            start = new Date(start.setHours(0, 0, 0, 0));
            newValue = convertDateUtc(Period.DAY, start, undefined);
            getAggregateData(
                start.toISOString(),
                end.toISOString(),
                trackerId,
                'hour',
            );
            changeTranslatedDate(newValue?.translatedPeriod!, start);
            getSummaryData(start.toISOString(), end.toISOString(), trackerId);
            end.setDate(end.getDate() + 1);
            end.setHours(0, 0, 0, 0);
        }
        if (selectedPeriod === Period.WEEK) {
            start = new Date();
            end = new Date();
            start = new Date(start.setDate(start.getDate() - 7));
            getAggregateData(
                start.toISOString(),
                end.toISOString(),
                trackerId,
                'day',
            );
            getSummaryData(start.toISOString(), end.toISOString(), trackerId);
            newValue = convertDateUtc(Period.WEEK, start, end);
            changeTranslatedDate(newValue?.translatedPeriod!, start);
        }
        if (selectedPeriod === Period.MONTH) {
            start = new Date();
            start.getMonth();
            end = new Date();
            newValue = convertDateUtc(Period.MONTH, start);
            start = new Date(start.setMonth(start.getMonth() - 1));
            getSummaryData(start.toISOString(), end.toISOString(), trackerId);
            changeTranslatedDate(newValue?.translatedPeriod!, start);
            getAggregateData(
                start.toISOString(),
                end.toISOString(),
                trackerId!,
                'week',
            );
        }
        getEvolutionData(start.toISOString(), end.toISOString(), trackerId);
        return { start, end };
    };

    const renderPeriodBloc = () => {
        return periods.map((value: Period, i: number) => (
            <div key={i}>
                <div
                    className={
                        selectedPeriod === value
                            ? styles.periodBlockIconSelected
                            : styles.periodBlockIcon
                    }
                    onClick={() => setSelectedperiod(value)}
                    style={
                        i === 0
                            ? {
                                  borderTopLeftRadius: '1rem',
                                  borderBottomLeftRadius: '1rem',
                              }
                            : i === 2
                            ? {
                                  borderTopRightRadius: '1rem',
                                  borderBottomRightRadius: '1rem',
                              }
                            : {}
                    }
                >
                    <p className={styles.blockText}>
                        {t(`activityTracking:PERIOD.${value}`)}
                    </p>
                </div>
            </div>
        ));
    };

    return (
        <>
            <div
                className={styles.flexGrid}
                style={{
                    gap: 'unset',
                    justifyContent: 'unset',
                }}
            >
                {renderPeriodBloc()}
            </div>
        </>
    );
};

const mapDispatch = (dispatch: Dispatch) => ({
    changeTranslatedDate: (dateTranslated: string, savedDate: Date) => {
        dispatch.activityTracking.setTranslatedPeriodSelected(
            dateTranslated,
            savedDate,
        );
    },
    changePeriod: (period: Period) => {
        dispatch.activityTracking.setPeriod(period);
    },
    getSummaryData: (startDate: string, endDate: string, trackerId: number) => {
        const data = { startDate, endDate, trackerId };
        dispatch.activityTracking.getSumaryData(data);
    },
    getEvolutionData: (
        startDate: string,
        endDate: string,
        trackerId: number,
    ) => {
        const data = { startDate, endDate, trackerId };
        dispatch.activityTracking.getEvolutionData(data);
    },
    getAggregateData: (
        startDate: string,
        endDate: string,
        trackerId: number,
        periodUnit: string,
    ) => {
        const periodValue = 1;
        const timezone = getTimezone(new Date(startDate));
        const data = {
            startDate,
            endDate,
            trackerId,
            periodUnit,
            periodValue,
            timezone,
        };
        dispatch.activityTracking.getAggregateData(data);
    },
});

const makeMapState = (state: IRootState): IState => {
    const { period, translatedPeriodSelected } = state.activityTracking;
    return {
        translatedPeriodSelected,
        period,
    };
};

export const ConnectedPeriodBlockComponent = compose<IMappedProps, IOuterProps>(
    connect(makeMapState, mapDispatch),
    mapProps(
        (props: IMappedProps): IMappedProps => ({
            ...props,
        }),
    ),
)(PeriodBlockComponent);
