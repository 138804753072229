import { FormikBag, FormikProps, withFormik } from 'formik';
import React, { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { IWithFormUtils, withFormUtils } from '../../../utils/form-helpers';
import { Button } from '../../common/button/button.component';
import { Input } from '../../common/input/input.component';
import { Link } from '../../common/link/link.component';

import { ReactComponent as InfoIcon } from '../../icons/info-2.svg';

import styles from './replace-tracker-form.module.scss';
import { getReplaceTrackerValidationSchema } from './replace-tracker-validation.schema';

export interface IReplaceTrackerFormValues {
    imei: string;
}

export interface IReplaceTrackerFormProps
    extends FormikProps<IReplaceTrackerFormValues>,
    IWithFormUtils {
    children?: ReactNode;
    onImeiInfoClicked: (...args: any[]) => any;
}

interface IOuterProps {
    onSubmit: (val: IReplaceTrackerFormValues) => any;
    onImeiInfoClicked: (...args: any[]) => any;
}

export const ReplaceTrackerForm = ({
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    onImeiInfoClicked,
    children,
    isFieldValid,
    getErrorMessage,
}: IReplaceTrackerFormProps) => {
    const { t } = useTranslation('transferPage');
    const subscriptionProcess = useTranslation('subscriptionProcess').t;
    const mayDisplayButton = () => {
        if (isMobile) {
            return (
                <Link
                    href={'weenect://app/home'}
                    className={styles['return-app-link']}
                    target="_blank"
                >
                    <Trans>{subscriptionProcess('RETURN_TO_APP')}</Trans>
                </Link>
            );
        }
    };
    const label = (
        <div className={styles['info-label']}>
            {t('IMEI_LABEL')}
            <InfoIcon
                data-testid="replace-tracker-form-imei-info"
                onClick={onImeiInfoClicked}
            />
        </div>
    );

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Input
                label={label}
                onBlur={handleBlur}
                name="imei"
                id="replace-tracker-form-imei"
                onChange={handleChange}
                value={values.imei}
                errorMessage={getErrorMessage('imei')}
                isValid={isFieldValid('imei')}
            />
            {children}
            <Button type="submit" primary block>
                {t('REPLACE_BUTTON.LABEL')}
            </Button>
            {mayDisplayButton()}
        </form>
    );
};

export const ControlledReplaceTrackerForm = compose<
    IReplaceTrackerFormProps,
    IOuterProps
>(
    withFormik<IOuterProps, IReplaceTrackerFormValues>({
        mapPropsToValues: () => ({
            imei: '',
        }),
        handleSubmit: (
            values: IReplaceTrackerFormValues,
            formikBag: FormikBag<IOuterProps, IReplaceTrackerFormValues>,
        ) => {
            formikBag.props.onSubmit(values);
        },
        validationSchema: () => getReplaceTrackerValidationSchema(),
    }),
    withFormUtils,
)(ReplaceTrackerForm);
