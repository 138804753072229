import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { compose, lifecycle, mapProps } from 'recompose';

import {
    IWithForgotPasswordKey,
    withForgotPasswordKey,
} from '../../utils/with-forgot-password-key';

import { Button } from '../common/button/button.component';
import { Headline } from '../common/headline/headline.component';
import { withLanguageDetecting } from '../language/language-detecting-resolver';
import { SimpleHeaderWithLanguages } from '../layout/simple-header/simple-header.component';
import { INotification } from '../notifications/interfaces';
import { ConnectedNotifications } from '../notifications/notifications/notifications.component';
import { forgotPasswordRoute, loginRoute } from '../routing/routes';
import { Dispatch, IRootState } from '../store/store';

import {
    ControlledConfirmPasswordForm,
    IFormValues,
} from './confirm-password-form/confirm-password-form.component';
import { ControlledForgotPasswordForm } from './forgot-password-form/forgot-password-form.component';
import styles from './forgot-password-page.module.scss';
import {
    IChangePasswordResponse,
    IForgotPasswordResponse,
    requestChangePassword,
    requestForgotPassword,
} from './forgot-password.service';
import { IForgotPasswordAvailability } from './interfaces-api';

interface IOuterProps { }

interface IActions {
    showNotification: (notification: INotification) => any;
    clearNotifications: () => any;
    fetchValidateKeyFP(forgotPasswordKey: string): Promise<unknown>;
}

interface IStateProps {
    status_code: number;
}

interface IMappedProps {
    redirectToLogin(): unknown;
    redirectToForgotPassword(): unknown;
}

interface IForgotPasswordPageProps
    extends IOuterProps,
    IActions,
    IWithForgotPasswordKey,
    IStateProps,
    IMappedProps { }

export const ForgotPasswordPage = ({
    showNotification,
    clearNotifications,
    status_code,
    forgotPasswordKey,
}: IForgotPasswordPageProps) => {
    const history = useNavigate();
    const { key } = useParams()

    const redirectToLogin = () => {
        history(loginRoute)
    }
    const redirectToForgotPassword = () => {
        history(forgotPasswordRoute)
        window.location.reload(); // TODO : Try to find better
    }

    const { t } = useTranslation('forgotPassword');
    const loginT = useTranslation('login').t;
    const formsT = useTranslation('forms').t;
    const accountT = useTranslation('account').t;
    /* Contact API to send mail to users asking for password reset */
    const onAskForPasswordResetSubmitted = async (email: string) => {
        requestForgotPassword(email)
            .then((resp: IForgotPasswordResponse) => {
                redirectToLogin();
                showNotification({
                    type: 'success',
                    content: t('FORGOT_PASSWORD_SUCCESS', { mail: email }),
                });
            })
            .catch((err) => {
                clearNotifications();
                showNotification({
                    /**
                     * TODO: Add specific errors based on response codes
                     */
                    type: 'error',
                    content: t('FORGOT_PASSWORD_ERRORS.UNKNOWN'),
                });
            });
    };

    /* Contact API to change password with new one */
    const onChangePasswordSubmitted = async (values: IFormValues) => {
        if (key) {
            requestChangePassword(values, key)
                .then((resp: IChangePasswordResponse) => {
                    redirectToLogin();
                    showNotification({
                        type: 'success',
                        content: accountT('PASSWORD.MESSAGES.UPDATE_SUCCESS'),
                    });
                })
                .catch((err) => {
                    showNotification({
                        type: 'error',
                        content: t('FORGOT_PASSWORD_ERRORS.UNKNOWN'),
                    });
                });
        }
    };

    /* 404 : Display Key NOT FOUND FORM */
    if (status_code === IForgotPasswordAvailability.NOT_FOUND) {
        return (
            <div>
                <SimpleHeaderWithLanguages />
                <ConnectedNotifications />
                <div className={styles.container}>
                    <Headline className={styles['primary-headline']} center>
                        {loginT('FORGOT_PASSWORD')}
                    </Headline>

                    <div className={styles.server_error}>
                        {t('FORGOT_PASSWORD_EXPIRED')}
                    </div>

                    <Button primary block onClick={redirectToForgotPassword}>
                        {t('FORGOT_PASSWORD_RETRY')}
                    </Button>

                    <Button secondary block onClick={redirectToLogin}>
                        {t('FORGOT_PASSWORD_CANCEL')}
                    </Button>
                </div>
            </div>
        );
    } else if (status_code === IForgotPasswordAvailability.OK) {
        /* 204 : Display Valid Key From (Double password input for change password) */
        return (
            <div>
                <SimpleHeaderWithLanguages />
                <ConnectedNotifications />
                <div className={styles.container}>
                    <Headline className={styles['primary-headline']} center>
                        {formsT('NEW_PASSWORD.LABEL')}
                    </Headline>

                    <ControlledConfirmPasswordForm
                        onFormSubmitted={onChangePasswordSubmitted}
                    />
                </div>
            </div>
        );
    } else if (status_code === IForgotPasswordAvailability.UNAVAILABLE) {
        /* 503 : Display forgot password form with 50x error message */
        return (
            <div>
                <SimpleHeaderWithLanguages />
                <ConnectedNotifications />
                <div className={styles.container}>
                    <Headline className={styles['primary-headline']} center>
                        {loginT('FORGOT_PASSWORD')}
                    </Headline>

                    <div className={styles.server_error}>
                        {t('FORGOT_PASSWORD_ERROR')}
                    </div>

                    <ControlledForgotPasswordForm
                        onFormSubmitted={onAskForPasswordResetSubmitted}
                    />

                    <Button secondary block onClick={redirectToLogin}>
                        {t('FORGOT_PASSWORD_CANCEL')}
                    </Button>
                </div>
            </div>
        );
    } else {
        /* 0 : Display the base form on which user will enter his mail address */
        return (
            <div>
                <SimpleHeaderWithLanguages />
                <ConnectedNotifications />
                <div className={styles.container}>
                    <Headline className={styles['primary-headline']} center>
                        {loginT('FORGOT_PASSWORD')}
                    </Headline>

                    <ControlledForgotPasswordForm
                        onFormSubmitted={onAskForPasswordResetSubmitted}
                    />

                    <Button secondary block onClick={redirectToLogin}>
                        {t('FORGOT_PASSWORD_CANCEL')}
                    </Button>
                </div>
            </div>
        );
    }
};

const makeMapState = () => {
    return (
        state: IRootState,
        props: IForgotPasswordPageProps,
    ): IStateProps => {
        return {
            status_code: state.forgotPassword.status_code,
        };
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    showNotification: dispatch.notifications.showNotification,
    clearNotifications: dispatch.notifications.clearNotifications,
    fetchValidateKeyFP: async (forgotPasswordKey: string) =>
        dispatch.forgotPassword.fetchValidateKey(forgotPasswordKey),
});

export const ConnectedForgotPasswordPage = compose(
    connect(
        makeMapState,
        mapDispatch,
    ),
    lifecycle<IActions & IForgotPasswordPageProps, {}>({
        componentDidMount() {
            const key = window.location.pathname.split('/')[2];
            if (key) {
                this.props.fetchValidateKeyFP(key);
            }
        },
    }),
    withLanguageDetecting,
)(ForgotPasswordPage);
