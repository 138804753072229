import React from 'react';

import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { Link } from '../../common/link/link.component';
import { Typography } from '../../common/typography/typography.component';
import { ReactComponent as TickIcon } from '../../icons/tick.svg';
import { PageContainer } from '../../layout/page-container/page-container.component';
import { SimpleHeaderWithLogout } from '../../layout/simple-header/simple-header.component';
import styles from './payment-result.module.scss';

import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';

export const PaymentSuccessPage = ({ }) => {
    const { t } = useTranslation('payments');
    const subscriptionT: any = useTranslation('subscriptionProcess').t;
    const commonActionT: any = useTranslation('commonActions').t;

    let button;
    const mayDisplayButton = () => {
        if (isMobile) {
            return (button = (
                <Link className={styles.widthFull} href={'weenect://app/home'} target="_blank">
                    <Button primary block>
                        <Trans>{commonActionT('NEXT')}</Trans>
                    </Button>
                </Link>
            ));
        }
        return (button = (
            <Link href={'/trackers'} target="">
                <Button primary block>
                    {subscriptionT('RETURN_TO_MAP')}
                </Button>
            </Link>
        ));
    };
    const mayDisplaySuccessText = () => {
        if (isMobile) {
            return (
                <Typography center>
                    <Trans> {t('STATUS.PAYMENT_SUCCESS_TEXT_APP')}</Trans>
                </Typography>
            );
        }
        return (
            <Typography center>
                {' '}
                <Trans>{t('STATUS.PAYMENT_SUCCESS_TEXT')}</Trans>
            </Typography>
        );
    };

    return (
        <div>
            <SimpleHeaderWithLogout />
            <PageContainer className={styles.container}>
                <div className={styles.titleContainer}>
                    <Headline center> {t('STATUS.PAYMENT_SUCCESS')}</Headline>
                    <TickIcon className={styles['svg-success']} />
                </div>
                {mayDisplaySuccessText()}
                <div className={styles.buttonPosition}>
                    {mayDisplayButton()}
                </div>
            </PageContainer>
        </div>
    );
};
