import cx from 'classnames';
import React, { HTMLAttributes, ReactNode } from 'react';

import styles from './info-icon.module.scss';

export interface IInfoIconProps extends HTMLAttributes<HTMLDivElement> {
    icon: ReactNode;
    label: string;
}

/**
 * Simple icon with label under it, used for example as battery indicator
 */
export const InfoIcon = ({
    icon,
    label,
    className,
    ...props
}: IInfoIconProps) => (
    <div className={cx(styles.infoIcon, className)} {...props}>
        <div className={styles.icon}>{icon}</div>
        <span className={styles.label}>{label}</span>
    </div>
);
