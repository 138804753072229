import { ReactComponentLike } from 'prop-types';

import { ReactComponent as AlertMessage } from '../../../icons/activity/alert-message.svg';
import { ReactComponent as Battery } from '../../../icons/activity/battery-low.svg';
import { ReactComponent as CallReq } from '../../../icons/activity/call-request.svg';
import { ReactComponent as CallEnd } from '../../../icons/activity/call.svg';
import { ReactComponent as Frequency } from '../../../icons/activity/frequency.svg';
import { ReactComponent as GeofenceIn } from '../../../icons/activity/geofence-enter.svg';
import { ReactComponent as GeofenceOut } from '../../../icons/activity/geofence-left.svg';
import { ReactComponent as Off } from '../../../icons/activity/off.svg';
import { ReactComponent as On } from '../../../icons/activity/on.svg';
import { ReactComponent as SOS } from '../../../icons/activity/sos.svg';
import { ReactComponent as Ring } from '../../../icons/activity/sound.svg';
import { ReactComponent as Vibrate } from '../../../icons/activity/vibrate.svg';

import { ReactComponent as InfoPlaceholder } from '../../../icons/info.svg';
import { TrackerEventType } from '../../../trackers/interfaces-api';

export const iconMapping: Partial<
    Record<TrackerEventType, ReactComponentLike>
> = {
    'ALM-GEO-I': GeofenceIn,
    'ALM-GEO-O': GeofenceOut,
    'CMD-W': Vibrate,
    'ALM-BAT': Battery,
    'CMD-M': Frequency,
    'ALM-F': AlertMessage,
    'ALM-H': AlertMessage,
    'ALM-G': AlertMessage,
    'ALM-A': SOS,
    'ALM-OFF': Off,
    'ALM-ON': On,
    'CMD-R': Ring,
    'CMD-V3': CallReq,
    'CMD-V30': CallReq,
    'CMD-C': CallEnd,
};

export const getEventIcon = (eventType: TrackerEventType) =>
    iconMapping[eventType] || InfoPlaceholder;
