import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { ITrackerEvent } from '../../../trackers/interfaces-api';

import { ActivityIcon } from '../activity-icon/activity-icon.component';
import { createActivityEvent } from '../activity-item/activity-item-factory';

import styles from './activity-list.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {
    events: ITrackerEvent[];
}

export const ActivityList = ({ events, className }: IProps) => {
    return (
        <div className={cx(styles.list, className)}>
            <div className={styles.timeline} />
            {events.map((event) => (
                <div key={event.id} className={styles.row}>
                    {<ActivityIcon icon={event.type} className={styles.icon} />}
                    {createActivityEvent(event, { className: styles.item })}
                </div>
            ))}
        </div>
    );
};
