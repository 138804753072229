import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '../../../common/button/button.component';
import { Link } from '../../../common/link/link.component';
import { Typography } from '../../../common/typography/typography.component';
import styles from './option-card-expired.module.scss';

export interface IOptionCardExpiredProps {
    trackerId: number | null;
    onConfirm: () => any;
    onAbort: () => any;
}

export const OptionCardExpired = ({
    trackerId,
    onConfirm,
    onAbort,
}: IOptionCardExpiredProps) => {
    const { t } = useTranslation('optionCardExpired');
    const ts = useTranslation('subscriptionAlert');

    return (
        <React.Fragment>
            <div className={styles.center}>
                {/* <strong>{t('WARNING')}</strong> */}
                <Typography>
                    <Trans ns="optionCardExpired" i18nKey="WARNING">
                        DOES NOT WORK WITHOUT THIS TEXT
                        <strong>Warning !</strong>
                        You can't subscribe to this option because your{' '}
                        <strong>credit card has expired</strong>
                    </Trans>
                </Typography>
                <div className={styles.proposal}><>{t('PROPOSAL')}</></div>
                <div className={styles.center}>
                    <div className={styles.buttonsredirect}>
                        <Button primary onClick={onConfirm}>
                            <>{t('UPDATE_CARD')}</>
                        </Button>
                    </div>
                    <hr className={styles.line} />

                    <Typography className={styles.text}>
                        <Trans ns="subscriptionAlert" i18nKey="SUPPORT_TEXT">
                            Si vous avez des questions ou que vous pensez que
                            c'est une erreur, contactez notre
                            <Link
                                href="https://weenect.zendesk.com/hc/fr"
                                target="_blank"
                            >
                                service client
                            </Link>
                        </Trans>
                    </Typography>

                    <div className={styles.buttonsredirect}>
                        <Button secondary onClick={onAbort}>
                            <>{ts.t('CONTINUE_BUTTON')}</>
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
