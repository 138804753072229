import { apiService } from '../../api/api.service';
import { ISupportTicketDto } from '../support-ticket-dto';

export const submitSupportTicket = (dto: ISupportTicketDto) => {
    return apiService
        .fetch('/ticket', {
            method: 'POST',
            body: JSON.stringify(dto),
        })
        .then((resp) => {
            if (resp >= 400) {
                return Promise.reject(resp);
            }

            return resp;
        });
};
