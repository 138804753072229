import React, { useEffect, useRef, useState } from 'react';

import { ITrackerOnMap } from '../../../trackers/interfaces';
import { ConnectedTrackerMarker } from '../tracker-marker/tracker-marker.component';

interface ITrackerOnMapProps {
    trackerOnMap: ITrackerOnMap | null;
    lat?: number | null; // Required for google-map-react
    lng?: number | null; // Required for google-map-react
    onTrackerSelected(id: number): any;
}

export const TrackerOnMap = React.memo(
    ({ trackerOnMap, onTrackerSelected }: ITrackerOnMapProps) => {
        const [marker, setMarker] = useState(false);
        const prevCountRef: any = useRef();
        useEffect(() => {
            if (trackerOnMap) {
                prevCountRef.current = trackerOnMap;
            }
        });
        const prevCount = prevCountRef.current;
        useEffect(() => {
            const isMarkerMarkerEqual = trackerOnMap === prevCount;
            if (!isMarkerMarkerEqual) {
                setMarker(true);
            } else {
                setMarker(false);
            }
        }, [trackerOnMap]);
        if (trackerOnMap && marker) {
            return (
                <>
                    <ConnectedTrackerMarker
                        iconUrl={trackerOnMap.urlPicture}
                        onClick={() => onTrackerSelected(trackerOnMap.id)}
                        trackerRadius={trackerOnMap.radius}
                        trackerColor={trackerOnMap.color}
                        key={trackerOnMap.id}
                        iconName={trackerOnMap.icon}
                        trackerType={trackerOnMap.type}
                        validGps={trackerOnMap.validGps}
                        position={trackerOnMap.position}
                    />
                </>
            );
        }
        return null;
    },
);
