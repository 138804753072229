import { Frequency } from '../../tracker-settings/interfaces';

export const mapFrequencyModeToSeconds = (mode: Frequency) => {
    if (mode.includes('S')) {
        return parseInt(mode.replace('S', ''), 10);
    } else if (mode.includes('M')) {
        return parseInt(mode.replace('M', ''), 10) * 60;
    }

    return 30; // default fallback
};
