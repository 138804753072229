import { createSelector } from 'reselect';

import { ITracker } from '../interfaces-api';
import { trackerToIsActivating } from '../mappers/tracker-to-is-activating';
import { makeGetTrackerModel } from './get-tracker-model';

export const makeGetTrackerIsActivating = (trackerId: number) => {
    const getActiveTrackerModel = makeGetTrackerModel(trackerId);

    return createSelector(
        getActiveTrackerModel,
        (tracker: ITracker | undefined): boolean | null => {
            if (!tracker) {
                return null;
            }

            return trackerToIsActivating(tracker);
        },
    );
};
