import { TrackerType } from '../trackers/interfaces';

export enum PositionMode {
    HEATMAP,
    PATH,
}

/**
 * Maps tracker type to default mode - heatmap or activity path
 */
export const positionDefaultModeMapping: Record<TrackerType, PositionMode> = {
    cat: PositionMode.PATH,
    cat2: PositionMode.PATH,
    pet: PositionMode.PATH,
    pet2: PositionMode.PATH,
    default: PositionMode.PATH,
    silver: PositionMode.PATH,
    skiset: PositionMode.PATH,
    sport: PositionMode.PATH,
    kid: PositionMode.PATH,
    animal: PositionMode.PATH,
    animalcat: PositionMode.PATH,
    animaldog: PositionMode.PATH,
    family: PositionMode.PATH,
    familykid: PositionMode.PATH,
    familysilver: PositionMode.PATH,
};

export const getPostionDefaultModeMappingFromTrackerType = (
    type: TrackerType,
) => positionDefaultModeMapping[type];
