/** These are possible responses from API
 *
 * 0 is when no request has been made
 * 200 is when request went well and key exists in db
 * 404 is when request went well and key doesn't exists in db
 * 503 is a fallback when request led to an error
 */
export enum IForgotPasswordAvailability {
    NONE = 0,
    OK = 204,
    NOT_FOUND = 404,
    UNAVAILABLE = 503,
}
