import { apiService } from '../api/api.service';
import {
    IGetTrackersResponse,
    IMakeVibrateCallRingResponse,
    ITracker,
    ITrackerEventsOptimized,
} from './interfaces-api';

export const fetchTrackers = (): Promise<IGetTrackersResponse> => {
    const url = '/mytracker-userspace';

    return apiService.fetch(url, {}).then((resp) => {
        if (resp.status !== 200) {
            throw Error('Request failed');
            // TODO: Handle it
        } else {
            return resp.json();
        }
    });
};
export const postPicture = (trackerID: number, body: any) => {
    const encodedPicture = body.split(',')[1];
    const url = `/mytracker/${trackerID}/picture`;
    return apiService
        .fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                data: encodedPicture,
            }),
        })
        .then((resp) => {
            if (resp.status === 201) {
                return true;
            }
        });
};
export const deletePicture = (trackerID: number) => {
    const url = `/mytracker/${trackerID}/picture`;
    return apiService
        .fetch(url, {
            method: 'DELETE',
        })
        .then((resp) => {
            if (resp.status === 204) {
                return true;
            }
        });
};
export const fetchTracker = (id: number): Promise<ITracker> => {
    const getUrl = () => `/mytracker/${id}`;

    return apiService.fetch(getUrl()).then((resp) => resp.json());
};

export const callTracker = (
    trackerId: number,
    phoneNumber: string,
): Promise<IMakeVibrateCallRingResponse> => {
    const url = `/mytracker/${trackerId}/sos`;

    return apiService
        .fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                phone_number: phoneNumber,
            }),
        })
        .then((resp) => {
            if (resp.status === 204) {
                return resp;
            }

            throw Error('Wrong response');
        });
};

export const vibrateTracker = (
    trackerId: number,
): Promise<IMakeVibrateCallRingResponse> => {
    const url = `/mytracker/${trackerId}/vibrate`;

    return apiService
        .fetch(url, {
            method: 'POST',
        })
        .then((resp) => {
            if (resp.status === 204) {
                return resp;
            }

            throw Error('Wrong response');
        });
};

export const flashTracker = (
    trackerId: number,
    durationMsOn: number,
    durationMsOff: number,
): Promise<IMakeVibrateCallRingResponse> => {
    const url = `/mytracker/${trackerId}/flash`;

    return apiService
        .fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                intermittent_duration_ms_on: durationMsOn,
                intermittent_duration_ms_off: durationMsOff,
                duration_minutes: 1,
            }),
        })
        .then((resp) => {
            if (resp.status === 204) {
                return resp;
            }

            throw Error('Wrong response');
        });
};

export const ringTracker = (
    trackerId: number,
): Promise<IMakeVibrateCallRingResponse> => {
    const url = `/mytracker/${trackerId}/ring`;

    return apiService
        .fetch(url, {
            method: 'POST',
        })
        .then((resp) => {
            if (resp.status === 204) {
                return resp;
            }

            throw Error('Wrong response');
        });
};

/**
 * It doesn't refresh FRONTEND position, but the device itself.
 */
export const forceRequestPositionRefresh = (trackerId: number) => {
    const getUrl = () => `/mytracker/${trackerId}/position/refresh`;

    return apiService.fetch(getUrl(), {
        method: 'POST',
    });
};

export const forceRequestTrackersPositions = (trackersIds: number[]) =>
    Promise.all(trackersIds.map(forceRequestPositionRefresh));

export const fetchTrackersPosition = (): Promise<ITrackerEventsOptimized[]> => {
    const getUrl = () => `/mytracker-userspace/position`;

    return apiService.fetch(getUrl()).then((r) => r.json());
};
