import { get } from 'lodash';
import { createSelector } from 'reselect';
import { ITrackerOnMap } from '../interfaces';

import { makeGetSelectedTrackerToMapModel } from './get-selected-tracker-model';

export const makeGetSelectedTrackerPosition = () => {
    const getActiveTrackerModel = makeGetSelectedTrackerToMapModel();

    return createSelector(
        getActiveTrackerModel,
        (
            tracker: ITrackerOnMap | null,
        ): { lat: number; lng: number } | null => {
            if (tracker) {
                const lng = get(tracker, 'position[0].latitude');
                const lat = get(tracker, 'position[0].latitude');

                if (typeof lng === 'number' && typeof lat === 'number') {
                    return { lng, lat };
                }
            }

            return null;
        },
    );
};
