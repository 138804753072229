import cx from 'classnames';
import React, { FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useNavigate } from 'react-router';
import styles from './choose-options-step.module.scss';

import { BackButton } from '../../../common/button/back-button.component';
import { NextButton } from '../../../common/button/next-button.component';
import { CheckSection } from '../../../common/check-section/check-section.component';
import { Headline } from '../../../common/headline/headline.component';
import { Grid } from '../../../layout/grid/grid.component';
import { PageContainer } from '../../../layout/page-container/page-container.component';
import { ConnectedSummaryTable } from '../../components/summary-table/summary-table.component';

import { ReactComponent as Premiumlogo } from 'app/icons//premium-logo.svg';
import { IAccountOptionOffers, IAccountOptions } from 'app/user/interfaces-api';
import {
    makeGetAccountOptions,
    makeGetAccountOptionsOffers,
} from 'app/user/selectors/account-option-selector';
import { formatPrice } from 'utils/normalize-price-amount';
import { Dispatch } from '../../../store/store';
import {
    IOptionOffer,
    SubscriptionVariantCode,
} from '../../../subscription-offers/interfaces';
import { SubscriptionOptionType } from '../../../user-subscriptions/interfaces-api';
import { OptionContent } from '../../components/option-content/option-content.component';
import { hasUserChoicesGuard } from '../../guards/has-user-choices-guard';
import { useParams } from 'react-router';

import { makeGetSubscriptionOfferOptions } from '../../../subscription-offers/selectors/subscription-offers-selectors';
import { makeGetSubscriptionProcessChoices } from '../../selectors/subscription-process-selectors';
interface IStateProps {
    accountOption: IAccountOptions[];
    accountOptionsOffers: IAccountOptionOffers[] | undefined;
    optionsOffers: IOptionOffer[];
    selectedOptionsCodes: SubscriptionOptionType[];
    selectedAccountOptionsCodes: SubscriptionOptionType[];
    trackerId: number;
}
interface IActions {
    setSelectedOptions(newOptions: SubscriptionOptionType[]): unknown;
    setSelectedAccountOptions(newOptions: SubscriptionOptionType[]): unknown;
}

interface IOwnProps {
    onCheckboxChanged: (code: SubscriptionOptionType) => any;
    renderConfirmationModal: () => any;
    subscriptionCode: SubscriptionVariantCode;
    onSubmit: (options: SubscriptionOptionType[]) => any;
}

interface IMappedProps {
    onGoBack(): unknown;
}

export interface ISubscriptionOptionsStepProps
    extends IStateProps,
        IActions,
        IOwnProps,
        IMappedProps {}

export const ChooseOptionsStep = ({
    accountOption,
    setSelectedAccountOptions,
    setSelectedOptions,
    optionsOffers,
    accountOptionsOffers,
    onSubmit,
    onCheckboxChanged,
    renderConfirmationModal,
    selectedOptionsCodes,
    selectedAccountOptionsCodes,
    onGoBack,
}: ISubscriptionOptionsStepProps) => {
    const optionTrad = useTranslation('subOptions').t;
    const billingTrad = useTranslation('registerSummaryTable').t;
    const cancelTrad = useTranslation('subscriptionSelection').t;
    const { t } = useTranslation('subscriptionOptionsSelection');
    const subscriptionTrad = useTranslation('subscription').t;
    const tperiod = useTranslation('periods').t;
    const onFormSubmitted = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(selectedOptionsCodes);
    };
    const history = useNavigate();
    const { id } = useParams();
    const formatedId = parseInt(id!, 10);
    useEffect(() => {
        setSelectedOptions([SubscriptionOptionType.WARRANTY]);
        setSelectedAccountOptions([SubscriptionOptionType.PREMIUMPACK]);
    }, []);

    return (
        <PageContainer className={styles.container}>
            <form onSubmit={onFormSubmitted}>
                <div className={styles.flex}>
                    <div className={styles.optionsGrid}>
                        <Headline className={styles.headline} center>
                            {t('MAIN_HEADLINE')}
                        </Headline>
                        <div style={{ marginBottom: '3rem' }}>
                            <span
                                style={{
                                    color: '#ed6e18',
                                    lineHeight: '2rem',
                                }}
                            >
                                {t('EXPLANATION')}
                                <br />
                            </span>
                            {t('EXPLANATION2')}
                        </div>
                        <div className={styles['checklist-container']}>
                            {optionsOffers.map((opt, i) => (
                                <CheckSection
                                    onChange={() => onCheckboxChanged(opt.code)}
                                    key={opt.name}
                                    type="checkbox"
                                    info={[
                                        billingTrad('BILLED_MONTH'),
                                        cancelTrad('CANCEL_ANYTIME'),
                                    ]}
                                    checked={selectedOptionsCodes.includes(
                                        opt.code,
                                    )}
                                >
                                    <OptionContent
                                        features={opt.features}
                                        name={opt.name}
                                        price={opt.price}
                                        period={tperiod('BASE.MONTH')}
                                    />
                                </CheckSection>
                            ))}
                        </div>
                        <div className={styles['checklist-container']}>
                            {accountOption.length === 0 ? (
                                <div className={styles['checklist-container']}>
                                    {accountOptionsOffers?.map((accOpt, i) => (
                                        <CheckSection
                                            className={styles['top-row']}
                                            onChange={() =>
                                                onCheckboxChanged(accOpt.code)
                                            }
                                            key={accOpt.code}
                                            type="checkbox"
                                            info={[
                                                billingTrad('BILLED_MONTH'),
                                                cancelTrad('CANCEL_ANYTIME'),
                                            ]}
                                            checked={selectedAccountOptionsCodes.includes(
                                                accOpt.code,
                                            )}
                                        >
                                            <OptionContent
                                                features={[
                                                    optionTrad(
                                                        'PREMIUM.FEATURE.CHAT.TEXT2',
                                                    ),
                                                ]}
                                                name={optionTrad(
                                                    'PREMIUM.BASE',
                                                )}
                                                logo={<Premiumlogo />}
                                                pricePeriodOffert={optionTrad(
                                                    'PREMIUM.FREEMONTH.BASE',
                                                )}
                                                price={formatPrice(
                                                    accOpt.price_offer.fr
                                                        .amount / 100,
                                                    accOpt.price_offer.fr
                                                        .currency,
                                                )}
                                                period={tperiod('BASE.MONTH')}
                                            />
                                        </CheckSection>
                                    ))}
                                </div>
                            ) : (
                                accountOption &&
                                accountOptionsOffers?.map((opt, i) => (
                                    <CheckSection
                                        onChange={() =>
                                            onCheckboxChanged(opt.code)
                                        }
                                        key={opt.id}
                                        type="checkbox"
                                        info={[
                                            billingTrad('BILLED_MONTH'),
                                            cancelTrad('CANCEL_ANYTIME'),
                                        ]}
                                        checked={selectedAccountOptionsCodes.includes(
                                            opt.code,
                                        )}
                                    >
                                        <OptionContent
                                            features={[
                                                optionTrad(
                                                    'PREMIUM.FEATURE.CHAT.TEXT2',
                                                ),
                                            ]}
                                            name={optionTrad('PREMIUM.BASE')}
                                            logo={<Premiumlogo />}
                                            pricePeriodOffert={''}
                                            price={formatPrice(
                                                opt.price_offer.fr.amount / 100,
                                                opt.price_offer.fr.currency,
                                            )}
                                            period={tperiod('BASE.MONTH')}
                                        />
                                    </CheckSection>
                                ))
                            )}
                        </div>
                        <div className={styles.infoTextGrey}>
                            {subscriptionTrad('INFO_OPTION')}
                        </div>
                        <Grid
                            className={cx([
                                styles.buttons,
                                styles.submitButtonsDesktop,
                            ])}
                        >
                            <div>
                                <NextButton type="submit" primary block>
                                    {t('SUBMIT_BUTTON')}
                                </NextButton>
                            </div>
                            <div>
                                <BackButton
                                    block
                                    secondary
                                    onClick={() =>
                                        history(
                                            `/add/subscription/${formatedId}`,
                                        )
                                    }
                                >
                                    {t('commonActions:BACK')}
                                </BackButton>
                            </div>
                        </Grid>
                    </div>
                    <div className={styles.summaryBlock}>
                        <Headline level={3}>
                            {subscriptionTrad('SUMMARY')}
                        </Headline>
                        <ConnectedSummaryTable
                            onDeleteOption={onCheckboxChanged}
                        />
                        <Grid
                            className={cx([
                                styles.buttons,
                                styles.submitButtonsMobile,
                            ])}
                        >
                            <NextButton
                                type="submit"
                                primary
                                block
                                className={styles.button}
                            >
                                {t('SUBMIT_BUTTON')}
                            </NextButton>
                            <BackButton
                                block
                                secondary
                                onClick={() =>
                                    history(`/add/subscription/${formatedId}`)
                                }
                                className={styles.button}
                            >
                                {t('commonActions:BACK')}
                            </BackButton>
                        </Grid>
                    </div>
                </div>
            </form>
            {renderConfirmationModal()}
        </PageContainer>
    );
};

const makeMapState = () => {
    const getUserChoices = makeGetSubscriptionProcessChoices();
    return (state: any, props: IOwnProps): IStateProps => {
        const getAccountOption = makeGetAccountOptions();
        const getOffers = makeGetSubscriptionOfferOptions(
            props.subscriptionCode,
        );
        const getAccountOptionOffers = makeGetAccountOptionsOffers();
        return {
            accountOption: getAccountOption(state),
            optionsOffers: getOffers(state),
            accountOptionsOffers: getAccountOptionOffers(state),
            selectedOptionsCodes: getUserChoices(state).selectedOptions,
            selectedAccountOptionsCodes:
                getUserChoices(state).selectedAccountOptions,
            trackerId: getUserChoices(state).trackerId!,
        };
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    setSelectedOptions: dispatch.subscriptionProcess.setSelectedOptions,
    setSelectedAccountOptions:
        dispatch.subscriptionProcess.setSelectedAccountOptions,
});

export const ConnectedChooseOptionsStep = compose<
    ISubscriptionOptionsStepProps,
    IOwnProps
>(
    connect(makeMapState, mapDispatch),
    hasUserChoicesGuard,
)(ChooseOptionsStep);
