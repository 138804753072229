import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AnimalCatIcon } from '../../../images/animalcat.svg';
import { ReactComponent as AnimalDogIcon } from '../../../images/animaldog.svg';
import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { Input } from '../../common/input/input.component';
import styles from '../animal-info.module.scss';
import {
    AnimalFormStep,
    IAnimal,
    IFormValidationInterface,
    Type,
} from '../interfaces';

interface IOuterProps {
    animalInfo: IAnimal;
    formValidation: IFormValidationInterface;
    setFormValidation: any;
    nextStep: any;
    handleSubmit: any;
}

export const AnimalTypeNameForm = ({
    animalInfo,
    formValidation,
    setFormValidation,
    nextStep,
    handleSubmit,
}: IOuterProps) => {
    const { t } = useTranslation(['activityOnboarding', 'commonActions']);
    return (
        <React.Fragment key={'name'}>
            <div className={styles.container}>
                <div>
                    <div>
                        <Headline level={2} center className={styles.title}>
                            {t('PET_INFO.SECOND_PETS_DETAILS')}
                        </Headline>
                    </div>
                    <div>
                        <p className={styles.commonText}>
                            {t('PET_NAME.COMMON_TEXT')}
                        </p>
                        <p className={cx(styles.commonText, styles.marginTop2)}>
                            {t('PET_INFO.PET_GENDER_COMMON')}
                        </p>
                    </div>
                </div>
                <div className={cx(styles.grid, styles.marginTop2)}>
                    <div
                        onClick={() =>
                            setFormValidation(
                                (form: IFormValidationInterface) => ({
                                    ...form,
                                    species: Type.CAT,
                                }),
                            )
                        }
                        className={
                            formValidation.species === Type.CAT
                                ? styles.imgContainerSelected
                                : styles.imgContainer
                        }
                    >
                        <div className={styles.magrinAuto}>
                            <AnimalCatIcon className={styles.icon} />
                            <p className={styles.textBlockIcon}>
                                {t('PET_TYPE.CAT')}
                            </p>
                        </div>
                    </div>
                    <div
                        onClick={() =>
                            setFormValidation(
                                (form: IFormValidationInterface) => ({
                                    ...form,
                                    species: Type.DOG,
                                }),
                            )
                        }
                        className={
                            formValidation.species === Type.DOG
                                ? styles.imgContainerSelected
                                : styles.imgContainer
                        }
                    >
                        <div className={styles.magrinAuto}>
                            <AnimalDogIcon className={styles.icon} />
                            <p className={styles.textBlockIcon}>
                                {t('PET_TYPE.DOG')}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        formValidation.species
                            ? cx(
                                  styles.containerBlockVisible,
                                  styles.marginTop2,
                              )
                            : cx(styles.containerBlockHidedn, styles.marginTop2)
                    }
                >
                    <p className={styles.commonText}>{t('PET_NAME.TITLE')}</p>
                    <Input
                        className={styles.nameInput}
                        placeholder={t('PET_NAME.PLACEHOLDER')}
                        nativeFieldClass={styles.textarea}
                        value={formValidation.name!}
                        onChange={(
                            event: React.FormEvent<HTMLInputElement>,
                        ) => {
                            const { value } = event.currentTarget;
                            setFormValidation(
                                (form: IFormValidationInterface) => ({
                                    ...form,
                                    name: value,
                                }),
                            );
                        }}
                    />
                </div>
                <div className={styles.buttonBlock}>
                    {animalInfo ? (
                        <Button
                            disabled={
                                formValidation.species && formValidation.name
                                    ? false
                                    : true
                            }
                            className={styles.buttonWidth}
                            onClick={() => handleSubmit()}
                        >
                            {t('SUMMARY.VALIDATION')}
                        </Button>
                    ) : (
                        <Button
                            disabled={
                                formValidation.species && formValidation.name
                                    ? false
                                    : true
                            }
                            className={styles.buttonWidth}
                            onClick={() => nextStep(AnimalFormStep.BIRTH_SEXE)}
                        >
                            {t('commonActions:NEXT')}
                        </Button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
