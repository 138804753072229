import { createSelector } from 'reselect';

import { ModelConfig } from '@rematch/core';

import { IRootState } from '../store/store';
import { URLPictureAttribute } from '../trackers/interfaces';
import { ITracker } from '../trackers/interfaces-api';
import { TrackerIcon } from '../trackers/tracker-icons';
import { INotificationSettingsDTO, sosMode } from './interfaces';
import {
    putTracker,
    updateNotificationsSettings,
    updateSosSettings,
    updateTrackerButtons,
    updateTrackerContacts,
    updateTrackerNameAndIcon,
    updateTrackerRefreshMode,
} from './services/tracker-settings.service';

export interface ITrackersSettingsStore {
    frequencySettingsOpen: boolean;
}

export const trackersSettingsStore: ModelConfig<ITrackersSettingsStore> = {
    state: {
        frequencySettingsOpen: false,
    },
    effects: (dispatch: any) => ({
        updateTrackerButtons(payload: {
            trackerId: number;
            button1: string;
            button2: string;
            button3: string;
        }) {
            return updateTrackerButtons(
                payload.trackerId,
                payload.button1,
                payload.button2,
                payload.button3,
            ).then((r) => {
                return r;
            });
        },
        updateTrackerRefreshMode(payload: {
            trackerId: number;
            secondsPeriod: number;
        }) {
            return updateTrackerRefreshMode(
                payload.trackerId,
                payload.secondsPeriod,
            ).then((r) => {
                dispatch.userTrackers.updateFrequencyInterval({
                    trackerId: payload.trackerId,
                    newMode: payload.secondsPeriod,
                });
                return r;
            });
        },
        updateTrackerNameAndIcon(payload: {
            trackerId: number;
            icon: TrackerIcon | string;
            color: string;
            name: string;
        }) {
            if (payload.icon === URLPictureAttribute) {
                return;
            }
            return updateTrackerNameAndIcon(payload.trackerId, {
                // @ts-ignore
                icon: payload.icon,
                color: payload.color,
                name: payload.name,
            }).then((trackerData) => {
                dispatch.userTrackers.updateTracker(trackerData);
                return trackerData;
            });
        },
        async putSingleTracker({
            trackerId,
            newType,
        }: {
            trackerId: number;
            newType: string;
        }) {
            return putTracker(trackerId, newType);
        },
        async updateTrackerContacts(payload: {
            trackerId: number;
            mails: string[];
            numbers: string[];
        }) {
            return updateTrackerContacts(payload.trackerId, {
                numbers: payload.numbers,
                mails: payload.mails,
            }).then(() => {
                return dispatch.userTrackers.fetchSingleTracker({
                    trackerId: payload.trackerId,
                });
            });
        },
        updateSosSettings(payload: {
            trackerId: number;
            mode?: sosMode;
            newNumber: string;
        }) {
            return updateSosSettings(payload.trackerId, {
                newNumber: payload.newNumber,
                mode: payload.mode,
            }).then((trackerData) => {
                dispatch.userTrackers.updateTracker(trackerData);

                return trackerData;
            });
        },
        updateNotificationSettings(payload: {
            trackerId: number;
            settings: INotificationSettingsDTO;
        }) {
            return updateNotificationsSettings(
                payload.trackerId,
                payload.settings,
            ).then((resp: ITracker) => {
                dispatch.userTrackers.updateTracker(resp);

                return resp;
            });
        },
    }),
    reducers: {
        openFrequencySettings: (state) => ({
            ...state,
            frequencySettingsOpen: true,
        }),
        closeFrequencySettings: (state) => ({
            ...state,
            frequencySettingsOpen: false,
        }),
    },
};

const settingsModel = (state: IRootState) => state.trackersSettings;

export const makeGetFrequencySettingsOpen = () =>
    createSelector(
        settingsModel,
        (model): boolean => model.frequencySettingsOpen,
    );
