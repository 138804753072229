import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { pure } from 'recompose';

import { ReactComponent as BackIcon } from '../../icons/dash.svg';
import styles from './back-link.module.scss';

export interface IBackLinkProps extends HTMLAttributes<HTMLSpanElement> {
    black?: boolean;
    /**
     * Hide content on mobile, leaving only icon
     */
    collapseOnMobile?: boolean;
}

export const BackLink = pure(
    ({
        children,
        className,
        black,
        collapseOnMobile,
        ...props
    }: IBackLinkProps) => {
        return (
            <span
                className={cx(
                    styles.link,
                    {
                        [styles.black]: black,
                        [styles.collapseOnMobile]: collapseOnMobile,
                    },
                    className,
                )}
                {...props}
            >
                <BackIcon className={styles.icon} />
                <div className={styles.children}>{children}</div>
            </span>
        );
    },
);
