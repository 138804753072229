import { ModelConfig } from '@rematch/core';
import { get, set } from 'lodash';
import { createSelector } from 'reselect';

import { IRootState } from '../store/store';
import { makeGetAllTrackersIds } from '../trackers/selectors/get-all-tracker-ids';
import { makeGetTrackerSupportsLiveMode } from '../trackers/selectors/get-supports-live-mode';
import { makeGetTrackerIsDuringLiveMode } from '../trackers/selectors/get-tracker-is-during-live-mode';

import {
    enableLiveMode,
    getPassedLiveModeSeconds,
    saveTimestamp,
} from './services/tracker-live-mode.service';

interface ITrackerLiveModeStore {
    liveModeByTrackerId: {
        [trackerId: number]: {
            /**
             * If server returns that live mode is enabled (freq mode 10S)
             */
            enabled: boolean;
            /**
             * This field represents time of live mode passed in case of restoring it
             * from local storage.
             * If live mode is turned ON on web app, it will save timestamp in LS
             * and start counting locally. If app is closed, it will restore time
             * by checking timestamp.
             * If not timestamp saved and live mode is enabled, this will be null
             * and it means that live mode was turned ON on another device
             */
            restoredTimePassed: number | null;
        };
    };
    displayModalOpen: boolean;
}

const liveModeMaxTime = 1000 * 60 * 5;

let liveModeTimeOutsById: { [id: number]: number } = {};
let liveModeIntervalsById: { [id: number]: number } = {};

export const trackerLiveModeStore: ModelConfig<ITrackerLiveModeStore> = {
    state: {
        liveModeByTrackerId: {},
        displayModalOpen: false,
    },
    reducers: {
        setDisplayModalOpen: (state, open: boolean) => ({
            ...state,
            displayModalOpen: open,
        }),
        toggleDisplayModalOpen: (state) => ({
            ...state,
            displayModalOpen: !state.displayModalOpen,
        }),
        setRestoredTime: (
            state,
            payload: { trackerId: number; timeInSeconds: number | null },
        ) => {
            const s = { ...state };
            set(
                s,
                `liveModeByTrackerId.${payload.trackerId}.restoredTimePassed`,
                payload.timeInSeconds,
            );

            return s;
        },
        setLiveModeEnabled: (
            state,
            payload: { trackerId: number; isEnabled: boolean },
        ) => {
            const s = { ...state };
            set(
                s,
                `liveModeByTrackerId.${payload.trackerId}.enabled`,
                payload.isEnabled,
            );

            return s;
        },
    },
    effects: (dispatch) => ({
        async startLiveMode(trackerIdToStart: number) {
            return enableLiveMode(trackerIdToStart).then((data) => {
                saveTimestamp(trackerIdToStart);
                this.setLiveModeEnabled({
                    trackerId: trackerIdToStart,
                    isEnabled: true,
                });

                liveModeTimeOutsById[trackerIdToStart] = (setTimeout(() => {
                    this.setLiveModeEnabled({
                        trackerId: trackerIdToStart,
                        isEnabled: false,
                    });
                    clearInterval(liveModeIntervalsById[trackerIdToStart]);
                }, liveModeMaxTime) as unknown) as number;

                liveModeIntervalsById[trackerIdToStart] = (setInterval(() => {
                    const secondsPassed = getPassedLiveModeSeconds(
                        trackerIdToStart,
                    );

                    if (secondsPassed && secondsPassed < 300) {
                        this.setRestoredTime({
                            trackerId: trackerIdToStart,
                            timeInSeconds: secondsPassed,
                        });
                    } else {
                        this.setRestoredTime({
                            trackerId: trackerIdToStart,
                            timeInSeconds: null,
                        });
                    }
                }, 1000) as unknown) as number;

                return data;
            });
        },
        /**
         * This has to be called when all trackers are fetched!
         */
        async checkLiveModeStatus(payload, models) {
            const getAllTrackersIds = makeGetAllTrackersIds();
            const trackerIds = getAllTrackersIds(models);

            liveModeTimeOutsById = {};
            liveModeIntervalsById = {};

            trackerIds.forEach((id) => {
                const getDoesSupportLiveMode = makeGetTrackerSupportsLiveMode(
                    id,
                );

                /**
                 * Do nothing if live mode is not supported
                 */
                if (!getDoesSupportLiveMode(models)) {
                    return;
                }

                const getIsLiveModeOnServer = makeGetTrackerIsDuringLiveMode(
                    id,
                );

                /**
                 * Set enabled/disabled live mode status in store
                 */
                if (getIsLiveModeOnServer(models)) {
                    this.setLiveModeEnabled({
                        trackerId: id,
                        isEnabled: true,
                    });
                } else {
                    this.setLiveModeEnabled({
                        trackerId: id,
                        isEnabled: false,
                    });
                }

                const secondsPassed = getPassedLiveModeSeconds(id);

                if (secondsPassed && secondsPassed < 300) {
                    this.setRestoredTime({
                        trackerId: id,
                        timeInSeconds: secondsPassed,
                    });
                } else {
                    this.setRestoredTime({
                        trackerId: id,
                        timeInSeconds: null,
                    });
                }
            });
        },
    }),
};

const getLiveModeStore = (state: IRootState) => state.trackerLiveMode;

export const makeGetIsLiveModeEnabled = (trackerId: number) => {
    return createSelector(getLiveModeStore, (state) =>
        get(state, `liveModeByTrackerId.${trackerId}.enabled`, false),
    );
};

export const getIsLiveModeModalOpen = createSelector(
    getLiveModeStore,
    (state): boolean => state.displayModalOpen,
);
