import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { compose } from 'recompose';

import { IRootState } from '../../store/store';
import { makeGetAllTrackersIds } from '../../trackers/selectors/get-all-tracker-ids';
import { makeGetHasBeenFirstFetched } from '../../trackers/selectors/get-have-trackers-been-fetched';

interface IStateProps {
    firstFetch: boolean;
    trackersLength: number;
}

interface IHOCProps extends IStateProps { }

/**
 * This HOC is responsible of checking if user has NO trackers registered
 * so it moved to register step immediately
 */
const zeroTrackersGuard = (Component: ComponentType<any>) => ({
    firstFetch,
    trackersLength,
    ...props
}: IHOCProps) => {
    const history = useNavigate()
    useEffect(() => {
        if (firstFetch && trackersLength === 0) {
            history('/add/register');
        }
    }, [firstFetch, trackersLength, history]);
    return <Component  {...props} />
};

const mapState = (state: IRootState): IStateProps => {
    const getTrackersIds = makeGetAllTrackersIds();
    const getHasBeenFirstFetched = makeGetHasBeenFirstFetched();

    return {
        firstFetch: getHasBeenFirstFetched(state),
        trackersLength: getTrackersIds(state).length,
    };
};

const connectedZeroTrackersGuard = compose(
    connect(mapState),
    zeroTrackersGuard,
);

export { connectedZeroTrackersGuard as zeroTrackersGuard };
