export enum StepToShow {
    ANIMALNAME = 'animalName',
    ANIMALGENDER = 'animalGender',
    ANIMALAGE = 'animalAge',
    ANIMALRACE = 'animalRace',
    ANIMALWEIGHT = 'animalWeight',
    ANIMALACTIVITY = 'animalActivity',
}
export interface IFormValidationInterface {
    tracker_id: number;
    species: string | null;
    name: string | null;
    sex: string | null;
    month?: string | null;
    year?: string | null;
    weight?: number | null;
    birth_date: Date | null;
    is_sterilized: boolean | null;
    is_activated: boolean;
    breed: string | null;
    identification: string | null;
    activity_level: number | null;
    last_vet_visit_date: Date | null;
    last_vaccination_date: Date | null;
}
export enum Type {
    DOG = 'dog',
    CAT = 'cat',
}
export enum Period {
    MONTH = 'month',
    YEAR = 'year',
}
export enum KeyDate {
    VET = 'last_vet_visit_date',
    VACCINATE = 'last_vaccination_date',
}
export enum Activity {
    LAZY = 'lazy',
    AVERAGE = 'average',
    ENERGETIC = 'energetic',
    HYPERACTIVE = 'hyperactive',
}
export enum AnimalFormStep {
    TYPE_NAME = 0,
    BIRTH_SEXE = 1,
    RACE = 2,
    ACTIVITY = 3,
    HEALTH = 4,
    SUMMARY = 5,
}
export enum Gender {
    MALE = 'male',
    FEMALE = 'female',
}

export enum Sterilized {
    STERILIZED = 'STERILIZED',
    NOT_STERILIZED = 'NOT_STERILIZED',
}
export enum Identified {
    CHIPPED = 'CHIP',
    TATOOED = 'TATTOO',
    NOT_IDENTIFIED = 'NONE',
}
export enum Weight {
    SLIM = 'skinny',
    AVERAGE = 'standard',
    FAT = 'overweight',
    OBESE = 'obese',
}
export const ActivityText = [
    {
        text: 'PET_ACTIVITY.FIRST_BUTTON_',
        label: Activity.LAZY,
        value: 0,
    },
    {
        text: 'PET_ACTIVITY.SECOND_BUTTON_',
        label: Activity.AVERAGE,
        value: 33,
    },
    {
        text: 'PET_ACTIVITY.THIRD_BUTTON_',
        label: Activity.ENERGETIC,
        value: 66,
    },
    {
        text: 'PET_ACTIVITY.FOURTH_BUTTON_',
        label: Activity.HYPERACTIVE,
        value: 100,
    },
];
export interface IAnimal {
    id: number;
    created_at: string;
    updated_at: string;
    is_activated: boolean;
    tracker_id: number;
    species: string;
    breed: string;
    sex: string;
    birth_date: Date;
    is_sterilized: boolean;
    identification: string;
    habitual_environment: string | null;
    activity_level: number;
    name: string;
    morphology: string;
    weight: number | null;
    last_vet_visit_date: Date;
    last_vaccination_date: Date;
}
export interface IAnimalObject {
    items: IAnimal[];
    total: number;
}
export const MonthTranslated = [
    'MONTH.JANUARY',
    'MONTH.FEBRUARY',
    'MONTH.MARCH',
    'MONTH.APRIL',
    'MONTH.MAY',
    'MONTH.JUNE',
    'MONTH.JULY',
    'MONTH.AUGUST',
    'MONTH.SEPTEMBER',
    'MONTH.OCTOBER',
    'MONTH.NOVEMBER',
    'MONTH.DECEMBER',
];
// Trads enums, mostly here to simplify the html enum in activity-onboarding.component

export const sterilizedTrads = new Map<Sterilized, string>([
    [Sterilized.STERILIZED, 'commonActions:YES'],
    [Sterilized.NOT_STERILIZED, 'commonActions:NO'],
]);
export const genderTrad = new Map<Gender, string>([
    [Gender.MALE, 'activityOnboarding:ANIMAL_GENDER.MALE'],
    [Gender.FEMALE, 'activityOnboarding:ANIMAL_GENDER.FEMALE'],
]);

export const weightTrad = new Map<Weight, string>([
    [Weight.SLIM, 'activityOnboarding:ANIMAL_MORPH.FIRST_BUTTON'],
    [Weight.AVERAGE, 'activityOnboarding:ANIMAL_MORPH.SECOND_BUTTON'],
    [Weight.FAT, 'activityOnboarding:ANIMAL_MORPH.THIRD_BUTTON'],
]);

export const activityTrad = new Map<Activity, string>([
    [Activity.LAZY, 'activityOnboarding:ANIMAL_ACTIVITY.FIRST_BUTTON'],
    [Activity.AVERAGE, 'activityOnboarding:ANIMAL_ACTIVITY.SECOND_BUTTON'],
    [Activity.ENERGETIC, 'activityOnboarding:ANIMAL_ACTIVITY.THIRD_BUTTON'],
    [Activity.HYPERACTIVE, 'activityOnboarding:ANIMAL_ACTIVITY.FOURTH_BUTTON'],
]);
