import { SubscriptionOptionType } from 'app/user-subscriptions/interfaces-api';
import { IAccountOptions } from 'app/user/interfaces-api';
import { formatPrice } from '../../../utils/normalize-price-amount';
import {
    IOptionOffer,
    ISubscriptionOffer,
} from '../../subscription-offers/interfaces';
import { ISubscriptionSummaryData } from '../interfaces';
import { IUserChoices } from '../subscription-process.store';

const getEnabledOptions = (options: IOptionOffer[], choices: IUserChoices) => {
    if (!choices.selectedOptions) {
        return [];
    }
    return options.filter((opt) => choices.selectedOptions.includes(opt.code));
};
const getEnabledAccountOptions = (
    optionsOffers: (IOptionOffer | undefined)[],
    choices: IUserChoices,
) => {
    if (!choices.selectedAccountOptions) {
        return [];
    }
    return optionsOffers.filter((accOpt) => {
        if (accOpt) {
            return choices.selectedAccountOptions.includes(accOpt.code);
        }
    });
};
const getDisabledOptions = (options: IOptionOffer[], choices: IUserChoices) =>
    options.filter((opt) => !choices.selectedOptions.includes(opt.code));
const getSavingMoneyOnUpgrade = (
    selectedOffer: ISubscriptionOffer,
    offers: ISubscriptionOffer[],
    site: string,
): string => {
    const threeYearsPlan = offers.find(
        (offer) => offer.code === '36_month_1_tracker',
    );

    if (!threeYearsPlan) {
        return '';
    }

    const threeYearsPlanYearCost = threeYearsPlan.pricePerYear;

    return formatPrice(
        selectedOffer.pricePerYear - threeYearsPlanYearCost,
        selectedOffer.currency,
    );
};

const getSummaryPrice = (
    options: IOptionOffer[],
    accountOptions: IAccountOptions[],
    optionsOffers: (IOptionOffer | undefined)[],
    choices: IUserChoices,
    selectedOffer: ISubscriptionOffer,
    site: string,
) => {
    const isPremiumPackActive = accountOptions.find(
        (accOpt) => accOpt.code === SubscriptionOptionType.PREMIUMPACK,
    );
    const optionsValue = getEnabledOptions(options, choices).reduce(
        (val, option) => val + option.rawPrice,
        0,
    );
    const offersValue = isPremiumPackActive
        ? getEnabledAccountOptions(optionsOffers, choices).reduce(
              (val, opt) => (val + opt?.rawPrice! ? opt?.rawPrice : 0),
              0,
          )
        : 0;

    let totalOptionsValue = offersValue;

    offersValue
        ? (totalOptionsValue = offersValue + optionsValue)
        : (totalOptionsValue = optionsValue);
    if (choices.promoCodeDiscount) {
        return formatPrice(
            totalOptionsValue +
                selectedOffer.rawPrice -
                choices.promoCodeDiscount / 100,
            selectedOffer.currency,
        );
    }
    return formatPrice(
        totalOptionsValue + selectedOffer.rawPrice,
        selectedOffer.currency,
    );
};

export const mapToOrderSummary = (
    choices: IUserChoices,
    offers: ISubscriptionOffer[],
    options: IOptionOffer[],
    site: string,
    accountOption: IAccountOptions[],
    accountOptionOffers: (IOptionOffer | undefined)[],
): ISubscriptionSummaryData | null => {
    const selectedOffer = offers.find(
        (offer) => offer.code === choices.subscriptionCode,
    );

    if (!selectedOffer) {
        return null;
    }
    let formatedPromoCodeDiscount = null;

    if (choices.promoCodeDiscount) {
        formatedPromoCodeDiscount = formatPrice(
            -choices.promoCodeDiscount / 100,
            selectedOffer.currency,
        );
    }
    return {
        subscriptionName: selectedOffer.planName,
        subscriptionPrice: selectedOffer.formattedPrice,
        enabledOptions: getEnabledOptions(options, choices),
        enabledAccountOptions: getEnabledAccountOptions(
            accountOptionOffers,
            choices,
        ),
        promoCodeDiscount: formatedPromoCodeDiscount,
        optionsAvailableToAdd: getDisabledOptions(options, choices),
        subscriptionCanBeUpgraded: selectedOffer.code !== '36_month_1_tracker',
        subUpgradedSavingAmount: getSavingMoneyOnUpgrade(
            selectedOffer,
            offers,
            site,
        ),
        summaryPrice: getSummaryPrice(
            options,
            accountOption,
            accountOptionOffers,
            choices,
            selectedOffer,
            site,
        ),
    };
};
