import { ModelConfig } from '@rematch/core';
import { makeGetAllTrackersRetailerIds } from '../trackers/selectors/get-all-tracker-retailer-ids';
import { IRetailerApplyFree } from './interfaces-api';
import { fetchRetailerApplyFree } from './retailer.service';

export interface IRetailerStore {
    hasApplyFree: boolean;
}

export const retailerStore: ModelConfig<IRetailerStore> = {
    state: {
        hasApplyFree: false,
    },
    reducers: {
        setHasApplyFree: (
            state: IRetailerStore,
            applyFreeResponse: boolean,
        ): IRetailerStore => ({ ...state, hasApplyFree: applyFreeResponse }),
    },
    effects: (dispatch: any) => ({
        async fetchApplyFree(payload, models) {
            const getAllTrackersRetailerIds = makeGetAllTrackersRetailerIds();
            const allTrackersRetailerIds = getAllTrackersRetailerIds(models);
            // Reduce list to limit API calls
            const uniqueRetailerIds = new Set(allTrackersRetailerIds);

            uniqueRetailerIds.forEach((retailerId: number) => {
                fetchRetailerApplyFree(retailerId).then(
                    (response: IRetailerApplyFree) => {
                        if (response.apply_free === true) {
                            return this.setHasApplyFree(true);
                        }
                    },
                );
            });
            return;
        },
    }),
};
