import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { Typography } from '../../common/typography/typography.component';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { PageContainer } from '../../layout/page-container/page-container.component';
import { SimpleHeaderWithLogout } from '../../layout/simple-header/simple-header.component';
import styles from './payment-result.module.scss';
import { useLocation, useNavigate } from 'react-router';

export const PaymentErrorPage = ({ ...props }) => {
    const history = useNavigate()
    const trackerId = useLocation().pathname.split('/')[2];
    const { t } = useTranslation('payments');

    const redirectRetry = () => {
        history('/login');
    };
    return (
        <div>
            <SimpleHeaderWithLogout />
            <PageContainer className={styles.container}>
                <div className={styles.titleContainer}>
                    <Headline center> {t('STATUS.PAYMENT_ERROR')}</Headline>
                    <CrossIcon className={styles['svg-error']} />
                </div>
                <Typography center>
                    {t('STATUS.PAYMENT_ERROR_EXPLAIN_TEXT', ' ')}
                    <> </>

                    <a
                        href={'https://weenect.zendesk.com/hc/fr'}
                        className={styles.color}
                        target={'_blank'}
                    >
                        {t('STATUS.PAYMENT_SAV')}
                    </a>
                </Typography>
                <div className={styles.buttonPosition}>
                    <Button onClick={() => redirectRetry()} primary block>
                        {t('STATUS.PAYMENT_RETRY')}
                    </Button>
                </div>
            </PageContainer>
        </div>
    );
};
