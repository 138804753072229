import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import styles from '../animal-info.module.scss';
import {
    ActivityText,
    AnimalFormStep,
    IAnimal,
    IFormValidationInterface,
} from '../interfaces';

interface IOuterProps {
    animalInfo: IAnimal;
    formValidation: IFormValidationInterface;
    setFormValidation: (value: any) => void;
    nextStep: (value: number) => void;
    handleSubmit: any;
}

export const ActivityComponent = ({
    animalInfo,
    formValidation,
    setFormValidation,
    nextStep,
    handleSubmit,
}: IOuterProps) => {
    const { t } = useTranslation([
        'activityOnboarding',
        'activityTracking',
        'commonActions',
    ]);
    const renderActivities = () => {
        return ActivityText.map((animalActivity, i) => {
            return (
                <div
                    key={i}
                    onClick={() =>
                        setFormValidation((form: IFormValidationInterface) => ({
                            ...form,
                            activity_level: animalActivity.value,
                        }))
                    }
                    className={
                        formValidation.activity_level === animalActivity.value
                            ? styles.textContainerSelectedWider
                            : styles.textContainerWider
                    }
                >
                    <p className={styles.textBlockIcon}>
                        {t(
                            animalActivity.text +
                                formValidation.species?.toLocaleUpperCase(),
                        )}
                    </p>
                </div>
            );
        });
    };
    return (
        <React.Fragment>
            <div className={styles.container}>
                <Headline level={2} center className={styles.title}>
                    {t('PET_INFO.SECOND_PETS_DETAILS')}
                </Headline>
                <div style={{ height: 'inherit' }}>
                    <p className={styles.commonText}>
                        {t('PET_ACTIVITY.SELECT_ACTIVITY')}
                    </p>
                    <div className={styles.column}>{renderActivities()}</div>
                </div>
                <div className={styles.buttonBlock}>
                    {animalInfo ? (
                        <Button
                            disabled={
                                formValidation.activity_level !== null
                                    ? false
                                    : true
                            }
                            className={styles.buttonWidth}
                            onClick={() => handleSubmit()}
                        >
                            {t('SUMMARY.VALIDATION')}
                        </Button>
                    ) : (
                        <Button
                            disabled={
                                formValidation.activity_level !== null
                                    ? false
                                    : true
                            }
                            className={styles.buttonWidth}
                            onClick={() => nextStep(AnimalFormStep.HEALTH)}
                        >
                            {t('commonActions:NEXT')}
                        </Button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
