import React from 'react';
import { GetHandleProps, SliderItem } from 'react-compound-slider';
import styles from './slider.module.scss';

export const Handle = ({
    handle: { id, value, percent },
    getHandleProps,
}: {
    handle: SliderItem;
    getHandleProps: GetHandleProps;
}) => {
    const { onKeyDown, onMouseDown, onTouchStart } = getHandleProps(id);

    return (
        <div
            style={{
                left: `${percent}%`,
            }}
            className={styles.handle}
            onKeyDown={onKeyDown}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
        />
    );
};
