import React from 'react';
import { TileLayer } from 'react-leaflet';
import { config } from '../../../../config';
import { MapLayerType } from '../../interfaces';

const streetTilesSource = config.MAP_TILES_STREET;
const satelliteTilesSource = config.MAP_TILES_SATELLITE;
const grayTilesSource = config.MAP_TILES_GRAY;

/**
 * Provides Layer with configured tiles
 */
export const getTilesLayer = (selectedLayer: MapLayerType) => {
    switch (selectedLayer) {
        case MapLayerType.GRAY:
            return <TileLayer url={grayTilesSource} />;
        case MapLayerType.SATELLITE:
            return <TileLayer url={satelliteTilesSource} />;
        case MapLayerType.STREET:
        default:
            return <TileLayer url={streetTilesSource} />;
    }
};
