import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { NextButton } from '../../common/button/next-button.component';
import { Headline } from '../../common/headline/headline.component';
import { Typography } from '../../common/typography/typography.component';
import { ConnectedNavHeader } from '../../layout/nav-header/nav-header.component';
import { PageContainer } from '../../layout/page-container/page-container.component';
import { INotification } from '../../notifications/interfaces';
import { Dispatch, IRootState } from '../../store/store';
import { createGetUserLanguage } from '../../user/user-selectors';

import styles from './support-page.module.scss';

interface IOuterProps { }
interface IStateProps {
    language: string;
}
interface IActions {
    showNotification(notification: INotification): unknown;
}

interface IProps extends IOuterProps, IStateProps, IActions { }

export const SupportPage = ({ language }: IProps) => {
    const { t } = useTranslation('support');
    const getLanguage = new Map<string, string>();
    getLanguage.set('FR', 'https://help.weenect.com/hc/fr');
    getLanguage.set('DE', 'https://help.weenect.com/hc/de');

    const onRedirect = () => {
        window.open(
            getLanguage.get(language) || 'https://help.weenect.com/hc/en-gb',
            '_blank',
        );
    };

    return (
        <div>
            <ConnectedNavHeader />
            <PageContainer className={styles.container}>
                <Headline center>{t('TITLE')}</Headline>
                <Typography className={styles.mainText}>
                    {t('MAIN_TEXT')}
                </Typography>
                <Typography className={styles.subText}>
                    {t('SUB_TEXT')}
                </Typography>
                <NextButton
                    className={styles.helpCenter}
                    onClick={() => onRedirect()}
                    block
                    small
                >
                    <a className={styles.helpCenterLink}>{t('HELP_CENTER')}</a>
                </NextButton>
            </PageContainer>
        </div>
    );
};

const mapState = (state: IRootState): IStateProps => {
    const getLanguage = createGetUserLanguage();
    return {
        language: getLanguage(state).toUpperCase(),
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    showNotification: dispatch.notifications.showNotification,
});

export const ConnectedSupportPage = compose<IProps, IOuterProps>(
    connect(mapState, mapDispatch),
)(SupportPage);
