/* Import css from flag-icon node module */
// import 'flag-icon-css/css/flag-icon.min.css';

import cx from 'classnames';
import React, { ChangeEvent, HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import { compose, mapProps, withProps } from 'recompose';
import {
    getAvailableLanguages,
    ILanguage,
} from '../../../../i18n/available-languages';

import { ReactComponent as DashIcon } from '../../../icons/dash.svg';
import { Dispatch, IRootState } from '../../../store/store';
import { getSelectedLanguage } from '../../language.store';

import styles from './language-switcher.module.scss';

interface IOuterProps extends HTMLAttributes<HTMLSelectElement> { }

interface IStateProps {
    selected: string;
}

interface IMappedProps {
    languages: ILanguage[];
}

interface IActions {
    updateLanguage(val: string): unknown;
    matchCountry(country: string): string;
}

interface IProps extends IOuterProps, IMappedProps, IStateProps, IActions { }

export const LanguageSwitcher = ({
    className,
    languages,
    selected,
    onChange,
    matchCountry,
}: IProps) => (
    <div className={cx(styles.wrapper, className)}>
        <span
            className={cx(
                'flag-icon flag-icon-' + matchCountry(selected),
                styles.flagIcon,
            )}
        />
        <select
            className={styles.select}
            value={selected.toUpperCase()}
            onChange={onChange}
        >
            {languages.map((l) => (
                <option value={l.code} key={l.code}>
                    {l.shortDisplay}
                </option>
            ))}
        </select>
        <DashIcon className={styles.icon} />
    </div>
);

export const ConnectedLanguageSwitcher = compose<IProps, IOuterProps>(
    withProps({
        languages: getAvailableLanguages(),
    }),
    connect(
        (state: IRootState): IStateProps => ({
            selected: getSelectedLanguage(state),
        }),
        (dispatch: Dispatch): IActions => ({
            updateLanguage(val: string) {
                dispatch.language.updateLanguage({
                    languageCode: val,
                });
            },
            matchCountry(language: string): string {
                if (language.toLowerCase() === 'en') {
                    return 'gb';
                }
                return language.toLowerCase();
            },
        }),
    ),
    mapProps((props: IActions) => ({
        ...props,
        onChange: (e: ChangeEvent<HTMLSelectElement>) =>
            props.updateLanguage(e.target.value.toLowerCase()),
    })),
)(LanguageSwitcher);
