import { apiService } from '../api/api.service';
import { IFormValues } from './confirm-password-form/confirm-password-form.component';
import { IForgotPasswordAvailability } from './interfaces-api';

const forgotPasswordEndpoint = '/user/forgotpassword';

/* Every responses are empty. We only use response status_code to change behavior */
export interface IForgotPasswordResponse {}
export interface IValidateForgotPasswordKey {}
export interface IChangePasswordResponse {}

export const requestForgotPassword = async (
    mail: string,
): Promise<IForgotPasswordResponse> => {
    try {
        const response = await apiService.fetch(forgotPasswordEndpoint, {
            method: 'POST',
            body: JSON.stringify({ mail }),
        });

        if (response.status !== 204) {
            throw Error('Error. Trouble contacting API');
        }
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
};

export const validateForgotPasswordKey = (
    forgotPasswordKey: string,
): Promise<number> => {
    const validPasswordKeyEndpoint = `/user/forgotpassword/${forgotPasswordKey}`;

    const doValidate = async () => {
        try {
            const response = await apiService.fetch(validPasswordKeyEndpoint, {
                method: 'GET',
            });
            const neededResponse = await response;
            return neededResponse.status;
        } catch (err) {
            console.log(err);
            return IForgotPasswordAvailability.UNAVAILABLE;
        }
    };
    return doValidate();
};

export const requestChangePassword = async (
    values: IFormValues,
    forgotPasswordKey: string,
) => {
    const changePasswordEndpoint = `/user/forgotpassword/${forgotPasswordKey}`;

    try {
        const response = await apiService.fetch(changePasswordEndpoint, {
            method: 'PUT',
            body: JSON.stringify({
                password: values.newPassword,
            }),
        });

        const neededResponse = await response;

        if (neededResponse.status !== 204) {
            throw Error('Error. Trouble contacting API');
        }

        return neededResponse.status;
    } catch (err) {
        return Promise.reject(err);
    }
};
