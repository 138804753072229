import trackerCatImage from '../../images/tracker-cats.png';
import trackerDogImage from '../../images/tracker-dogs.png';
import trackerKidImage from '../../images/tracker-kid.png';
import trackerSilverImage from '../../images/tracker-silver.png';

import { SubscriptionStatus } from '../user-subscriptions/interfaces-api';
import { AccuracyRange } from './accuracy-map';
import { TrackerIcon } from './tracker-icons';

/**
 * Minimal data required on CLIENT to display trackers properly on map
 */
export interface ITrackerOnMap {
    radius: number;
    position: [number, number];
    id: number;
    name: string;
    color: string;
    icon: TrackerIcon;
    urlPicture: string;
    type: TrackerType;
    validGps: boolean;
}

/**
 * Minimal data required on CLIENT to display trackers properly on sidebar
 */
export interface ITrackerOnSidebar {
    accuracy: number | null;
    address: string;
    batteryLevel: number;
    callTimeRemaining: number;
    color: string;
    date: string;
    gps: boolean;
    hasCall: boolean;
    hasRing: boolean;
    hasVibrate: boolean;
    hasFlash: boolean;
    icon: TrackerIcon | null;
    id: number;
    lastSeen: string;
    lastConnected: string;
    name: string;
    online: boolean;
    signalStrength: number;
    type: TrackerType;
    willExpireIn: number;
    hasSubscription: boolean;
    hasSubscriptionAlert: boolean;
    urlPicture: string;
    hasSos: boolean;
    subscriptionStatus: SubscriptionStatus | null;
    isActivating: boolean;
    isInDeepSleep: boolean;
    isInWifiZone: number;
    subscriptionId: number | null;
}

export enum TrackerType {
    CAT = 'cat',
    CAT2 = 'cat2',
    PET = 'pet',
    PET2 = 'pet2',
    SKISET = 'skiset',
    DEFAULT = 'default',
    SPORT = 'sport',
    SILVER = 'silver',
    KID = 'kid',
    ANIMAL = 'animal',
    ANIMALCAT = 'animalcat',
    ANIMALDOG = 'animaldog',
    FAMILY = 'family',
    FAMILYKID = 'familykid',
    FAMILYSILVER = 'familysilver',
}

export const URLPictureAttribute = 'url_picture';

export const mapTrackerTypeToImage = new Map<TrackerType, string>([
    [TrackerType.CAT, trackerCatImage],
    [TrackerType.CAT2, trackerCatImage],
    [TrackerType.PET, trackerDogImage],
    [TrackerType.PET2, trackerDogImage],
    [TrackerType.SKISET, trackerKidImage],
    [TrackerType.DEFAULT, trackerKidImage],
    [TrackerType.SPORT, trackerKidImage],
    [TrackerType.SILVER, trackerSilverImage],
    [TrackerType.KID, trackerKidImage],
]);

export enum Theme {
    WEENECT = 'weenect',
    BIOGARAN = 'biogaran',
}

export interface IAccuracyData {
    accuracyRadius: number;
    /**
     * Range with unit
     * e.g. < 5m
     */
    accuracyFormatted: string;
    /**
     * Full sentence:
     * Good GPS accuracy: < 5m
     */
    accuracyDescriptive: string;
    /**
     * Level of range - good, poor, average
     */
    accuracyRange: AccuracyRange;
}
