import { ModelConfig } from '@rematch/core';

export interface IOnboardingModel {
    modalOpen: boolean;
}

export const onboardingStore: ModelConfig<IOnboardingModel> = {
    state: {
        modalOpen: false,
    },
    effects: {
        showOnboardingModal() {
            this.toggleOnboardingModal(true);
        },
        hideOnboardingModal() {
            this.toggleOnboardingModal(false);
        },
    },
    reducers: {
        toggleOnboardingModal: (state, payload: boolean) => ({
            ...state,
            modalOpen: payload,
        }),
    },
};
