import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { Dispatch } from '../store/store';
import { Theme } from '../trackers/interfaces';

export interface IWithUserLocation {
    activeTheme: Theme | null;
    innerRouteOpen: boolean;
    checkLocation(): unknown;
}

export const withUserLocation = (Component: any, props: any) =>
    compose(
        connect(null, (dispatch: Dispatch) => ({
            checkLocation: dispatch.userLocation.checkLocation,
            activeTheme: dispatch.userTrackers.theme,
        })),
        lifecycle<IWithUserLocation, {}>({
            componentDidMount() {
                if (
                    this.props.activeTheme === Theme.BIOGARAN ||
                    (isMobile && this.props.innerRouteOpen)
                ) {
                    return null;
                }
                this.props.checkLocation();
            },
        }),
    )(Component);
