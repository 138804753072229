import React from 'react';
import { IOuterMapProps, LeafletLayers, MapLayerType } from '../../interfaces';
import { ConnectedGoogleMap } from '../map-google/google-map-react.component';
import { ConnectedLeafletMap } from '../map-leaflet/map-leaflet.component';

interface IOuterProps extends IOuterMapProps {
    layer: MapLayerType;
}
export const MapComponent = ({
    events,
    layer,
    trackersOnMap,
    shouldDisplayHeatMap,
    displayEventTooltip,
    onTrackerSelected,
}: IOuterProps) => {
    const renderLeafletOrGoogleMap = () => {
        // Haven't found a better solution than cast in string.
        const LeafletLayersValues = Object.values(LeafletLayers) as string[];
        if (LeafletLayersValues.includes(layer as string)) {
            return (
                <ConnectedLeafletMap
                    events={events}
                    trackersOnMap={trackersOnMap}
                    shouldDisplayHeatMap={shouldDisplayHeatMap}
                    onTrackerSelected={onTrackerSelected}
                    displayEventTooltip={displayEventTooltip}
                />
            );
        }
        return (
            <ConnectedGoogleMap
                events={events}
                trackersOnMap={trackersOnMap}
                shouldDisplayHeatMap={shouldDisplayHeatMap}
                displayEventTooltip={displayEventTooltip}
                onTrackerSelected={onTrackerSelected}
            />
        );
    };

    return renderLeafletOrGoogleMap();
};
