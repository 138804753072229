import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import { ReactComponent as DashIcon } from '../icons/dash.svg';

import { ReactComponent as Fire } from '../../images/activityTracking/fire.svg';
import { ReactComponent as Moon } from '../../images/activityTracking/moon.svg';
import { ReactComponent as SettingsIcon } from '../../images/activityTracking/settings.svg';
import { ReactComponent as Sun } from '../../images/activityTracking/sun.svg';
import { ReactComponent as Trophy } from '../../images/activityTracking/trophyIcon.svg';
import { ReactComponent as TrophyCompleted } from '../../images/activityTracking/trophyIconCompleted.svg';
import { ReactComponent as CalendarIcon } from '../../images/activityTracking/calendar-icon.svg';
import { ReactComponent as NotificationWarningIcon } from '../../images/warning_icon.svg';

import { convertMinutes } from '../../utils/convert-minute-in-days-hours-minutes';
import { setDateToday } from '../../utils/convertDateUtc';
import { Modal } from '../common/modal/modal.component';
import { INotification } from '../notifications/interfaces';
import { Dispatch, IRootState } from '../store/store';
import { ITrackerOnSidebar } from '../trackers/interfaces';
import { ITracker } from '../trackers/interfaces-api';
import { makeGetTrackerModel } from '../trackers/selectors/get-tracker-model';
import { makeGetTrackersOnSidebar } from '../trackers/selectors/get-trackers-on-sidebar';
import { resolveTrackerIcon } from '../trackers/tracker-icons';
import styles from './activity-tracking.module.scss';
import {
    areEmpty,
    ConnectedDataGraphComponent,
} from './component/data-graph-block';
import { ObectivesComponent } from './component/objectives-page';
import { ConnectedPeriodBlockComponent } from './component/period-block';
import { ProgressBar } from './component/progress-bar';
import { SumaryBlock } from './component/sumary-block';
import { ISummaryData, Period } from './interface';
import { useLocation, useNavigate } from 'react-router';
import { ConnectedDatePickerComponent } from './component/date-picker';

interface IState {
    rawTrackers: ITracker[];
    tracker: ITracker;
    trackersOnSidebar: ITrackerOnSidebar[];
    period: Period;
    summaryToday: ISummaryData;
    summaryData: ISummaryData;
    translatedPeriodSelected: string;
}

interface IActions {
    getSummaryDataToday: (
        startDate: string,
        endDate: string,
        trackerId: number,
    ) => ISummaryData;
    showNotification(n: INotification): unknown;
}
interface IMappedProps {}

export interface INewTrackerPageProps extends IActions, IState {}

export const ActivityTrackingPage = ({
    tracker,
    trackersOnSidebar,
    summaryToday,
    translatedPeriodSelected,
    showNotification,
    getSummaryDataToday,
}: INewTrackerPageProps) => {
    const history = useNavigate();
    const param = useLocation();

    const [currentObjectiveCompletion, setCurrentObjectiveCompletion] =
        useState<number>(0);
    const [objectivesView, setObjectivesView] = useState(false);
    const [openObjectiveModal, setOpenObjectiveModal] = useState(false);
    const [disableNavigation, setDisableNavigation] = useState(false);
    const [datePicker, setDatePicker] = useState<boolean>(false);

    const { t } = useTranslation(['activity', 'commonActions']);
    const selectedTracker = trackersOnSidebar.find(
        (trackerOnSidebar: ITrackerOnSidebar) =>
            trackerOnSidebar.id === tracker.id,
    );

    useEffect(() => {
        if (param.search.includes('source=app')) {
            setDisableNavigation(true);
        }
    }, []);

    const getActivityText = () => {
        areEmpty(summaryToday);
        if (getObjectivesPercentage() === 100) {
            return t('activity:SUMMARY.TEXT_OBJECTIVE_COMPLETED', {
                name: tracker.name,
            });
        }
        if (areEmpty(summaryToday)) {
            return t('activity:SUMMARY.TEXT_OBJECTIVE_NONE_VERIFY_TRACKER', {
                name: tracker.name,
            });
        }
        if (!summaryToday.activity && !summaryToday.awakening) {
            return t('activity:SUMMARY.TEXT_OBJECTIVE_NONE', {
                name: tracker.name,
            });
        }
        return t('activity:SUMMARY.SUBTEXT', {
            name: tracker.name,
            time: convertMinutes(
                summaryToday.objective - summaryToday.activity,
            ),
        });
    };
    const getSummaryToday = () => {
        const dates = setDateToday();
        getSummaryDataToday(dates[0], dates[1], tracker.id!);
    };
    useEffect(() => {
        // load all data for present day
        getSummaryToday();
    }, []);

    const getObjectivesPercentage = () => {
        if (summaryToday.activity > summaryToday.objective) {
            return 100;
        }
        if (!summaryToday.activity) {
            return 0;
        }
        return (summaryToday.activity / summaryToday.objective) * 100;
    };

    const renderIconBadge = () => {
        if (selectedTracker) {
            return (
                <div
                    className={styles.iconContainer}
                    style={
                        tracker.url_picture
                            ? { backgroundColor: '#ED6E18' }
                            : { backgroundColor: selectedTracker.color }
                    }
                >
                    <img
                        alt="Tracker icon"
                        src={resolveTrackerIcon(
                            tracker.url_picture,
                            selectedTracker.icon,
                            selectedTracker.type,
                        )}
                        className={
                            tracker.url_picture
                                ? styles.iconUrl
                                : styles.trackerIcon
                        }
                    />
                </div>
            );
        }
    };
    // const renderObjectiveModal = () => {
    //     if (openObjectiveModal) {
    //         return (
    //             <Modal onClosed={() => setOpenObjectiveModal(false)}>
    //                 <div className={styles.textModal}>{getActivityText()}</div>
    //                 <div className={styles.doughnutContainer}>
    //                     <DoughnutComponent
    //                         icon={
    //                             getObjectivesPercentage() === 100 ? (
    //                                 <TrophyCompleted />
    //                             ) : (
    //                                 <Trophy />
    //                             )
    //                         }
    //                         activityPercentage={getObjectivesPercentage()}
    //                         awakeningPercentage={Math.abs(
    //                             getObjectivesPercentage() - 100,
    //                         )}
    //                         restPercentage={0}
    //                         helperText={convertMinutes(summaryToday.activity)}
    //                     />
    //                 </div>
    //             </Modal>
    //         );
    //     }
    // };

    // const mayRenderDatePicker = () => {
    //     if (datePicker) {
    //         return (
    //             <>
    //                 <ConnectedDatePickerComponent
    //                     onClose={() => setDatePicker(false)}
    //                     trackerId={tracker?.id!}
    //                 />
    //             </>
    //         );
    //     }

    // };

    const mayRenderDatePicker = () => {
        if (datePicker) {
            return (
                <>
                    <div
                        style={{
                            margin: 'auto',
                            fontWeight: 'bold',
                            padding: '2.5rem 0.5rem 4rem 2.3rem',
                            width: 'fit-content',
                        }}
                    >
                        {translatedPeriodSelected}
                    </div>{' '}
                    <ConnectedDatePickerComponent
                        onClose={() => setDatePicker(false)}
                        trackerId={tracker?.id!}
                    />
                </>
            );
        }
    };
    const renderTrophyIcon = () => {
        if (getObjectivesPercentage() === 100) {
            return (
                <div
                    onClick={() => setOpenObjectiveModal(true)}
                    className={styles.trophyIconContainerCompleted}
                >
                    <TrophyCompleted className={styles.svg2} />
                </div>
            );
        }
        return (
            <div
                onClick={() => setOpenObjectiveModal(true)}
                className={styles.trophyIconContainer}
            >
                <Trophy className={styles.svg2} />
            </div>
        );
    };

    const changeActivity = () => {
        if (currentObjectiveCompletion === 100) {
        }
        if (currentObjectiveCompletion >= 100) {
            setCurrentObjectiveCompletion(0);
        } else {
            setCurrentObjectiveCompletion(currentObjectiveCompletion + 10);
        }
    };

    const mayRenderActivity = () => {
        if (!objectivesView) {
            return (
                <React.Fragment>
                    <>
                        <div className={styles.container}>
                            <div className={styles.activityContainer}>
                                <div
                                    className={styles.flexGrid}
                                    style={{ marginTop: '1rem' }}
                                >
                                    {disableNavigation ? null : (
                                        <DashIcon
                                            onClick={() =>
                                                history(
                                                    `/trackers/${tracker.id}`,
                                                )
                                            }
                                            className={styles.dashIcon}
                                        />
                                    )}
                                    <p className={styles.titleToday}>
                                        {t('activity:SUMMARY.TODAY')}
                                    </p>
                                    {/* <h3 className={styles.title}>
                                    {t('activity:SUMMARY.TITTLE', {
                                        name: tracker.name,
                                    })}
                                </h3> */}
                                    <div style={{ position: 'relative' }}>
                                        {' '}
                                        {summaryToday.objective === 0 ? (
                                            <NotificationWarningIcon
                                                style={{
                                                    position: 'absolute',
                                                    top: '-5px',
                                                    right: '-10px',
                                                    zIndex: 99,
                                                }}
                                            />
                                        ) : null}
                                        <SettingsIcon
                                            onClick={() =>
                                                setObjectivesView(true)
                                            }
                                            className={
                                                styles.settingIconContainer
                                            }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <p className={styles.resumeText}>
                                        {getActivityText()}
                                    </p>
                                </div>
                                <div
                                    className={styles.flexGrid}
                                    onClick={() => {
                                        changeActivity();
                                    }}
                                >
                                    {selectedTracker ? renderIconBadge() : null}
                                    <ProgressBar
                                        width="100%"
                                        completionValueInPercent={getObjectivesPercentage()}
                                        completionValue={convertMinutes(
                                            summaryToday.activity,
                                        )}
                                        title={t('activity:PROGRESSBAR.TITLE')}
                                    />
                                    {/* {renderObjectiveModal()} */}
                                    {/* {renderTrophyIcon()} */}
                                </div>

                                <div
                                    className={styles.flexGrid}
                                    style={{ margin: '0.4rem unset 0.4rem' }}
                                >
                                    <SumaryBlock
                                        blockProperties={[
                                            {
                                                icon: <Fire />,
                                                timeValue: convertMinutes(
                                                    summaryToday.activity,
                                                ),
                                                sumaryText: t(
                                                    'activity:SUMMARY.ACTIVITY',
                                                ),
                                            },
                                            {
                                                icon: <Sun />,
                                                timeValue: convertMinutes(
                                                    summaryToday.awakening,
                                                ),
                                                sumaryText: t(
                                                    'activity:SUMMARY.AWAKENING',
                                                ),
                                            },
                                            {
                                                icon: <Moon />,
                                                timeValue: convertMinutes(
                                                    summaryToday.rest,
                                                ),
                                                sumaryText: t(
                                                    'activity:SUMMARY.REST',
                                                ),
                                            },
                                        ]}
                                    />
                                </div>
                                {/* define how to state period time */}
                                <div>
                                    <p className={styles.titlePeriod}>
                                        {t('SUMMARY.DISTRIBUTIONTEXT')}
                                    </p>
                                </div>
                                <div
                                    className={styles.flexGrid}
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <ConnectedPeriodBlockComponent
                                        trackerId={tracker.id}
                                    />
                                    <div
                                        className={styles.calendarBlock}
                                        onClick={() =>
                                            setDatePicker(!datePicker)
                                        }
                                    >
                                        <p
                                            className={styles.textDatePicker}
                                            style={{ paddingRight: '0.5rem' }}
                                        >
                                            {t('SUMMARY.MODIFY_PERIOD')}
                                        </p>
                                        <CalendarIcon />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.graphBlockContainer}>
                                {!datePicker ? (
                                    <ConnectedDataGraphComponent
                                        tracker={tracker}
                                        datePicker={datePicker}
                                    />
                                ) : null}
                                {mayRenderDatePicker()}
                            </div>
                        </div>
                    </>
                </React.Fragment>
            );
        }
    };
    const mayRenderObjectivesView = () => {
        if (objectivesView) {
            return (
                <ObectivesComponent
                    objective={summaryToday.objective}
                    trackerId={tracker.id}
                    showNotification={showNotification}
                    refreshStore={getSummaryToday}
                    onClose={() => setObjectivesView(false)}
                />
            );
        }
    };

    return (
        <>
            {mayRenderActivity()}
            {mayRenderObjectivesView()}
        </>
    );
};

const mapDispatch = (dispatch: any) => ({
    showNotification: dispatch.notifications.showNotification,
    getSummaryDataToday: (
        startDate: string,
        endDate: string,
        trackerId: number,
    ) => {
        const data = { startDate, endDate, trackerId };
        dispatch.activityTracking.getSumaryDataToday(data);
    },
});

const makeMapState = (state: IRootState, props: any): IState => {
    const idUrl = window.location.pathname.split('/')[2];
    const id = parseInt(idUrl, 10);
    const getTrackerModel = makeGetTrackerModel(id);
    const tracker = getTrackerModel(state) as ITracker;
    const getTrackersOnSidebar = makeGetTrackersOnSidebar();
    const { period, summaryData, summaryToday, translatedPeriodSelected } =
        state.activityTracking;
    return {
        rawTrackers: state.userTrackers.rawTrackers,
        tracker,
        trackersOnSidebar: getTrackersOnSidebar(state),
        summaryToday,
        summaryData,
        translatedPeriodSelected,
        period,
    };
};

export const ConnectedActivityTrackingPage = compose<INewTrackerPageProps, {}>(
    connect(makeMapState, mapDispatch),
    mapProps(
        (props: IState): IMappedProps => ({
            ...props,
        }),
    ),
)(ActivityTrackingPage);
