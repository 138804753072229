import { config as appConfig } from '../../config';

export type IFetch = (
    input: RequestInfo,
    init?: RequestInit,
) => Promise<Response>;

interface IRequestMappers {
    mapHeaders?: (headers: Headers) => Headers;
}

/**
 * TODO: Add generic types
 */
export class EmailPrefApiService {
    private host = appConfig.EMAIL_PREF_API_HOST;

    constructor(
        private fetchApi: IFetch,
    ) { }

    fetch(
        endpoint: string,
        config: RequestInit = {},
        mappers: IRequestMappers = {},
    ): Promise<any> {

        let headers: Headers;

        if (config.headers) {
            headers = new Headers(config.headers);
        } else {
            headers = new Headers();
        }

        if (config.method === 'POST' || config.method === 'PUT') {
            headers.append('Content-Type', 'application/json');
        }

        headers.append('Accept', 'application/json');
        headers.append('X-App-Version', appConfig.APP_VERSION);
        headers.append('X-App-User-Id', appConfig.USER_ID);
        headers.append('X-App-Type', 'userspace');

        if (mappers.mapHeaders) {
            headers = mappers.mapHeaders(headers);
        }

        return this.fetchApi(this.host + endpoint, {
            ...config,
            headers,
        });
    }
}

export const emailPrefApiService = new EmailPrefApiService(fetch.bind(window));

