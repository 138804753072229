// @ts-ignore
import pairwise from 'pairwise';
import { ITrackerEvent } from '../../trackers/interfaces-api';

export const getMarkerOpacity = (index: number, events: ITrackerEvent[]) => {
    const max = events.length;
    return (index + 1) / max;
};

export const getVectors = (events: ITrackerEvent[]) => {
    const positions = events.map((e) => [e.latitude, e.longitude]);

    return pairwise(positions);
};

export const getVectorOpacity = (index: number, vectors: any) => {
    const max = vectors.length;

    return (index + 1) / max;
};
