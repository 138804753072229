/**
 * See /.env
 */
export interface IConfig {
    API_HOST: string;
    EMAIL_PREF_API_HOST: string;
    APP_VERSION: string;
    REACT_APP_GTM: string;
    BIOGGY_RETAILER_IDS: number[];
    BIOGGY_API_CALL_PARAM: string;
    WEENECT_API_CALL_PARAM: string;
    WEENECT_USA_API_CALL_PARAM: string;
    WEENECT_SITE: string;
    WEENECT_USA_SITE: string;
    MOBILE_APP_UNIVERSAL_LINK: string;
    DEFAULT_TILES: string;
    FALLBACK_TRACKER_COLOR: string;
    GOOGLE_ANALYTICS_ID: string;
    GRAPHHOPPER_API_KEY: string;
    HIPAY_ENVIRONMENT: string;
    HIPAY_USERNAME: string;
    HIPAY_PASSWORD: string;
    HIPAY_USA_USERNAME: string;
    HIPAY_USA_PASSWORD: string;
    HIPAY_BIOGGY_USERNAME: string;
    HIPAY_BIOGGY_PASSWORD: string;
    INSURANCE_LINK: string;
    MAP_TILES_GRAY: string;
    MAP_TILES_SATELLITE: string;
    MAP_TILES_STREET: string;
    PRODUCT_PAGE_CATS: string;
    PRODUCT_PAGE_DEFAULT: string;
    PRODUCT_PAGE_DOGS: string;
    PRODUCT_PAGE_ELDERS: string;
    PRODUCT_PAGE_KIDS: string;
    PUBLIC_URL: string;
    SUPPORT_LINK: string;
    TERMS_AND_CONDITIONS_LINK: string;
    USER_ID: string;
    WEBSITE_NAME: string;
}

export const config: IConfig = {
    API_HOST: process.env.REACT_APP_API_HOST || '',
    EMAIL_PREF_API_HOST: process.env.REACT_APP_EMAIL_PREF_API_HOST || '',
    APP_VERSION: process.env.REACT_APP_VERSION || '',
    BIOGGY_RETAILER_IDS: [1315, 1354, 1461, 1611],
    BIOGGY_API_CALL_PARAM: 'site=Biogaran',
    WEENECT_API_CALL_PARAM: 'site=weenect',
    WEENECT_USA_API_CALL_PARAM: 'site=weenect_us',
    WEENECT_SITE: 'weenect',
    WEENECT_USA_SITE: 'weenect_us',
    REACT_APP_GTM: process.env.REACT_APP_GTM_ID || '',
    MOBILE_APP_UNIVERSAL_LINK: 'weenect://test', // A suffix is required. While we don't handle specific redirection, it's 'test'.
    DEFAULT_TILES: process.env.REACT_APP_DEFAULT_TILES || '',
    FALLBACK_TRACKER_COLOR:
        process.env.REACT_APP_FALLBACK_TRACKER_COLOR || '#000',
    GOOGLE_ANALYTICS_ID: process.env.REACT_APP_GOOGLE_ANALYTICS || '',
    GRAPHHOPPER_API_KEY: process.env.REACT_APP_GRAPHHOPPER_API_KEY || '',
    HIPAY_ENVIRONMENT: process.env.REACT_APP_HIPAY_ENVIRONMENT || '',
    HIPAY_USERNAME: process.env.REACT_APP_HIPAY_USERNAME || '',
    HIPAY_PASSWORD: process.env.REACT_APP_HIPAY_PASSWORD || '',
    HIPAY_USA_USERNAME: process.env.REACT_APP_HIPAY_USERNAME_USA || '',
    HIPAY_USA_PASSWORD: process.env.REACT_APP_HIPAY_PASSWORD_USA || '',
    HIPAY_BIOGGY_USERNAME: process.env.REACT_APP_HIPAY_BIOGGY_USERNAME || '',
    HIPAY_BIOGGY_PASSWORD: process.env.REACT_APP_HIPAY_BIOGGY_PASSWORD || '',
    MAP_TILES_GRAY: process.env.REACT_APP_MAP_TILES_GRAY || '',
    MAP_TILES_SATELLITE: process.env.REACT_APP_MAP_TILES_SATELLITE || '',
    MAP_TILES_STREET: process.env.REACT_APP_MAP_TILES_STREET || '',
    PRODUCT_PAGE_CATS: process.env.REACT_APP_PRODUCT_PAGE_CATS || '',
    PRODUCT_PAGE_DEFAULT: process.env.REACT_APP_PRODUCT_PAGE_DEFAULT || '',
    PRODUCT_PAGE_DOGS: process.env.REACT_APP_PRODUCT_PAGE_DOGS || '',
    PRODUCT_PAGE_ELDERS: process.env.REACT_APP_PRODUCT_PAGE_ELDERS || '',
    PRODUCT_PAGE_KIDS: process.env.REACT_APP_PRODUCT_PAGE_KIDS || '',
    PUBLIC_URL: process.env.PUBLIC_URL,
    SUPPORT_LINK: process.env.REACT_APP_SUPPORT_LINK || '',
    TERMS_AND_CONDITIONS_LINK:
        process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK || '',
    INSURANCE_LINK: process.env.REACT_APP_INSURANCE_LINK || '',
    USER_ID: '',
    WEBSITE_NAME: process.env.REACT_APP_WEBSITE_NAME || 'Weenect',
};
