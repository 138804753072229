import { availableLanguagesCodes } from '../../i18n/available-languages';
import { countries } from './countries';

/**
 * Returns best matching language code which is AVAILABLE in the app and fallbacks to english
 */
export const getLanguageFromCountryCode = (code: string) => {
    const upperCode = code.toUpperCase();

    const countryModel = countries[upperCode];

    if (!countryModel) {
        return 'en';
    }

    const languages = countryModel.languages;

    const availableLanguages = languages.filter((lang) =>
        availableLanguagesCodes.includes(lang.toUpperCase()),
    );

    if (availableLanguages.length === 0) {
        return 'en';
    }

    return availableLanguages[0].toLowerCase();
};
