import L, { LatLng } from 'leaflet';
import { isEqual } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { ContextProps, withLeaflet } from 'react-leaflet';
// @ts-ignore
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { IRootState } from '../../store/store';
import { ITrackerEvent } from '../../trackers/interfaces-api';
import { makeGetEventsOnMap } from '../selectors';

interface IStateProps {
    points: ITrackerEvent[];
}

interface IProps extends IOuterProps, IStateProps, ContextProps { }

interface IOuterProps {
    trackerId: number;
}

export const HeatMap = ({ points = [], leaflet }: IProps) => {
    const previousPoints = useRef<ITrackerEvent[]>();

    /**
     * Checks points provided and sets map bounds during first render.
     * Checks equality with previous points to make sure it happens only once
     */
    useEffect(() => {
        if (
            leaflet &&
            leaflet.map &&
            points &&
            !isEqual(previousPoints.current, points)
        ) {
            const pointsForBounds = points.map(
                (p) => new LatLng(p.latitude, p.longitude),
            );
            if (pointsForBounds.length < 2) {
                return;
            }

            const bounds = L.latLngBounds(pointsForBounds);
            // https://github.com/Leaflet/Leaflet/issues/5153#issuecomment-264146911
            bounds.getNorthEast().lat += 0.000000000001;
            bounds.getNorthEast().lng += 0.000000000001;

            leaflet.map.fitBounds(bounds, {
                padding: [30, 30],
            });

            previousPoints.current = points;
        }
    }, [leaflet, points]);

    if (!points.length) {
        return null;
    }
    return (
        <HeatmapLayer
            points={points}
            longitudeExtractor={(p: ITrackerEvent) => p.longitude}
            latitudeExtractor={(p: ITrackerEvent) => p.latitude}
            intensityExtractor={(p: ITrackerEvent) => 1}
            radius={50}
            minOpacity={0.5}
        />
    );
};

const mapState = (state: IRootState, props: IOuterProps): IStateProps => {
    const getPoints = makeGetEventsOnMap(props.trackerId);

    return {
        points: getPoints(state),
    };
};

/**
 * Used directly inside map
 */
export const ConnectedHeatMap = compose<IProps, IOuterProps>(
    withLeaflet,
    connect(mapState),
)(HeatMap);
