import { IAccountOptions } from 'app/user/interfaces-api';
import { makeGetAccountOptions } from 'app/user/selectors/account-option-selector';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
    formatAmountWithCurrency,
    formatPrice,
    normalizePriceAmount,
} from 'utils/normalize-price-amount';

import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { Dispatch, IRootState } from '../../../store/store';
import { IOptionOffer } from '../../../subscription-offers/interfaces';
import { mapCodeToBilledText } from '../../../subscription-offers/mappers/map-subscription-offers-variants';
import { SubscriptionOptionType } from '../../../user-subscriptions/interfaces-api';
import { ISubscriptionSummaryData } from '../../interfaces';
import {
    getRawUserChoices,
    makeGetSubscriptionSummaryData,
} from '../../selectors/subscription-process-selectors';
import { IUserChoices } from '../../subscription-process.store';
import styles from './summary-table.module.scss';

interface IActions {
    onOptionRemove: (code: string) => any;
    onOptionAdded: (code: string) => any;
    onSubscriptionUpgradedRequest: () => any;
    onPromoCodeRemove: () => void;
}
interface IOuterProps {
    onDeleteOption: (code: SubscriptionOptionType) => any;
}
interface IStateProps {
    accountOption: IAccountOptions[];
    subscriptionCode: string;
    subscriptionData: ISubscriptionSummaryData;
    promoCodeIsGiftCard: boolean | null;
}

export interface ISummaryTableProps
    extends IActions,
        IOuterProps,
        IStateProps {}

const sumUpOptionsPrices = (options: IOptionOffer[]) =>
    options.reduce((cost, opt) => cost + opt.rawPrice, 0).toFixed(2);

export const SummaryTable = ({
    accountOption,
    subscriptionCode,
    subscriptionData: {
        enabledOptions,
        enabledAccountOptions,
        subscriptionPrice,
        summaryPrice,
        promoCodeDiscount,
    },
    promoCodeIsGiftCard,
    onDeleteOption,
    onOptionAdded,
    onOptionRemove,
    onPromoCodeRemove,
    onSubscriptionUpgradedRequest,
}: ISummaryTableProps) => {
    const optionTrad = useTranslation('subOptions').t;
    const { t } = useTranslation('registerSummaryTable');
    const subT = useTranslation('subscriptions').t;
    const subPaymentT = useTranslation('subscriptionPayment').t;
    let subscriptionNameFormated;
    if (subscriptionCode === '12_month_1_tracker') {
        subscriptionNameFormated = subT('OFFERS_TEXT.ONE_YEAR.TITLE');
    }
    if (subscriptionCode === '1_month_1_tracker') {
        subscriptionNameFormated = subT('OFFERS_TEXT.ONE_MONTH.TITLE');
    }
    if (subscriptionCode === '36_month_1_tracker') {
        subscriptionNameFormated = subT('OFFERS_TEXT.THREE_YEAR.TITLE');
    }

    const maybeDisplayPromoCodeDiscount = () => {
        if (promoCodeDiscount) {
            return (
                <div className={styles['table-row']}>
                    <span
                        className={cx([
                            styles['shopping-item'],
                            styles['promo-code'],
                        ])}
                    >
                        {promoCodeIsGiftCard
                            ? subPaymentT('PROMO_CODE_GIFT_CARD')
                            : subPaymentT('PROMO_CODE')}
                    </span>
                    <span
                        className={cx([
                            styles['shopping-item'],
                            styles['promo-code-discount'],
                        ])}
                    >
                        {promoCodeDiscount}
                    </span>
                    <button
                        type="button"
                        className={styles['remove-button']}
                        onClick={() => onPromoCodeRemove()}
                    >
                        <CrossIcon />
                    </button>
                </div>
            );
        }
    };

    const maybeDisplayOptionPrices = () => {
        if (enabledOptions.length > 0) {
            return (
                <div className={styles['table-group']}>
                    <ul>
                        {enabledOptions.map((opt) => (
                            <li key={opt.code}>
                                <div
                                    className={styles['table-row']}
                                    key={opt.code}
                                >
                                    <div>
                                        <span
                                            className={cx([
                                                styles['shopping-item'],
                                                styles['option-title'],
                                            ])}
                                        >
                                            {opt.name}
                                        </span>
                                        <span
                                            className={
                                                styles['option-description']
                                            }
                                        >
                                            {t('BILLED_MONTH')}
                                        </span>
                                    </div>
                                    <span
                                        className={cx(
                                            styles['shopping-item'],
                                            styles.price,
                                        )}
                                    >
                                        {opt.price}
                                    </span>
                                    <button
                                        className={styles['remove-button']}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onDeleteOption(opt.code);
                                        }}
                                    >
                                        <CrossIcon />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
    };

    const maybeDisplayAccountOptionPrices = () => {
        if (enabledAccountOptions && enabledAccountOptions.length > 0) {
            return (
                <div className={styles['table-group']}>
                    <ul>
                        {enabledAccountOptions.map((accOpt) => {
                            if (accOpt) {
                                return (
                                    <li key={accOpt.code}>
                                        <div
                                            className={styles['table-row']}
                                            key={accOpt.code}
                                        >
                                            <div>
                                                <span
                                                    className={cx([
                                                        styles['shopping-item'],
                                                        styles['option-title'],
                                                    ])}
                                                >
                                                    {optionTrad('PREMIUM.BASE')}
                                                </span>
                                                <span
                                                    className={
                                                        styles[
                                                            'option-description'
                                                        ]
                                                    }
                                                >
                                                    {t('BILLED_MONTH')}
                                                </span>
                                            </div>
                                            <span
                                                className={cx(
                                                    styles['shopping-item'],
                                                    styles.price,
                                                )}
                                            >
                                                {accountOption.length === 0
                                                    ? optionTrad(
                                                          'PREMIUM.FREEMONTH.BASE2',
                                                      )
                                                    : accOpt.price}
                                                <br />
                                            </span>
                                            <button
                                                className={
                                                    styles['remove-button']
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onDeleteOption(accOpt.code);
                                                }}
                                            >
                                                <CrossIcon />
                                            </button>
                                        </div>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            );
        }
    };

    return (
        <div className={styles['summary-table']}>
            <div className={styles['table-group']}>
                <div className={styles['table-row']}>
                    <span className={styles['shopping-item']}>
                        {subscriptionNameFormated}
                    </span>
                    <span className={cx(styles['shopping-item'], styles.price)}>
                        {subscriptionPrice}
                    </span>
                </div>
                {maybeDisplayPromoCodeDiscount()}
            </div>
            {maybeDisplayOptionPrices()}
            {maybeDisplayAccountOptionPrices()}
            <div className={cx(styles['table-row'], styles['final-price'])}>
                <span className={styles['shopping-item']}>{t('TOTAL')}</span>
                <span className={styles['final-price-value']}>
                    {summaryPrice}
                </span>
            </div>
        </div>
    );
};

const makeMapState = () => {
    return (state: IRootState): IStateProps => {
        const choices = getRawUserChoices(state) as IUserChoices;
        const getSubscriptionSummaryData = makeGetSubscriptionSummaryData(
            choices,
            state,
        );
        const getAccountOption = makeGetAccountOptions();
        return {
            accountOption: getAccountOption(state),
            subscriptionCode: choices.subscriptionCode,
            subscriptionData: getSubscriptionSummaryData(
                state,
            ) as ISubscriptionSummaryData,
            promoCodeIsGiftCard: choices.promoCodeIsGiftCard,
        };
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    onOptionAdded: dispatch.subscriptionProcess.appendNewOption as any,
    onOptionRemove: dispatch.subscriptionProcess.removeOption as any,
    onSubscriptionUpgradedRequest: dispatch.subscriptionProcess
        .set3yearPlan as any,
    onPromoCodeRemove: () => dispatch.subscriptionProcess.resetPromoCode(),
});

export const ConnectedSummaryTable = compose<ISummaryTableProps, IOuterProps>(
    connect(makeMapState, mapDispatch),
)(SummaryTable);
