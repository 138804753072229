import { IUserEmailPrefData } from '../interfaces';

export const mapUserPrefToEmailPrefFields = (
    emailpref: IUserEmailPrefData,
): IUserEmailPrefData => ({
    current_lang: emailpref.current_lang.toUpperCase(),
    list_company_news: emailpref.list_company_news,
    list_surveys_and_tests: emailpref.list_surveys_and_tests,
    list_new_features: emailpref.list_new_features,
    list_tutorials: emailpref.list_tutorials,
    list_offers: emailpref.list_offers,
    list_service_status: emailpref.list_service_status,
});
