import get from 'lodash/get';

import { ModelConfig } from '@rematch/core';

import { config as appConfig } from '../../config';
import { getGlobalT } from '../../i18n/setup-translations';
import { IRawGeofence } from '../geofences/interfaces-api';
import { IRootState, store } from '../store/store';
import { assignNewDevice } from '../tracker-replacement/services/tracker-replacement.service';
import { formatTimeInterval } from '../tracker-settings/format-time-interval';
import { Theme } from './interfaces';
import {
    IGetTrackersResponse,
    ITracker,
    ITrackerEvent,
    ITrackerEventsOptimized,
} from './interfaces-api';
import { makeGetAllTrackersIds } from './selectors/get-all-tracker-ids';
import { makeGetSelectedTrackerId } from './selectors/get-selected-tracker-id';
import { makeGetTrackerModel } from './selectors/get-tracker-model';
import {
    callTracker,
    fetchTracker,
    fetchTrackers,
    fetchTrackersPosition,
    flashTracker,
    forceRequestTrackersPositions,
    ringTracker,
    vibrateTracker,
} from './trackers.service';

export interface ITrackerStore {
    fetched: boolean;
    trackerSearchQuery: string;
    rawTrackers: ITracker[];
    activeTracker: number | null;
    theme: Theme | null;
    firstFetch: boolean;
    watchedPositions: { id: number; position: ITrackerEvent }[];
    // Save last 10 positions to display a path between each newly fetched position
    newPositions: { trackerId: number; positions: ITrackerEvent[] }[];
}

/**
 * setInterval ID
 */
let continuouslyFetchAllTrackerPositionInterval: NodeJS.Timeout | null = null;
let watchedPositionsTimeout: NodeJS.Timeout;

const fetchInterval = 10 * 1000;
const watchTimeout = 45 * 1000;
const POSITIONS_TO_SAVE = 10;
const t = getGlobalT();

export const trackersStore: ModelConfig<ITrackerStore> = {
    state: {
        fetched: false,
        trackerSearchQuery: '',
        rawTrackers: [],
        activeTracker: null,
        theme: null,
        firstFetch: false,
        watchedPositions: [],
        newPositions: [],
    },
    effects: (dispatch: any) => ({
        async fetchTrackers(
            { deepFetch }: { deepFetch: boolean } = {
                deepFetch: false,
            },
        ) {
            return fetchTrackers().then(
                async (responseData: IGetTrackersResponse) => {
                    this.setTrackers(responseData.items);
                    this.setFirstFetch();
                    // Set Biogaran theme (CSS) when tracker from URL params is a Bioggy one.
                    const trackerId = store.getState().subscriptionProcess
                        .choices.trackerId;
                    responseData.items.forEach((tracker) => {
                        if (
                            trackerId &&
                            tracker.id === trackerId &&
                            appConfig.BIOGGY_RETAILER_IDS.includes(
                                tracker.retailer_id,
                            )
                        ) {
                            document.documentElement.setAttribute(
                                'data-theme',
                                'biogaran',
                            );
                        }
                        dispatch.geofences.saveGeofences(tracker.id);
                        dispatch.wifiZones.saveWifiZones(tracker.id);
                    });

                    const promises: Promise<any>[] = [];
                    if (responseData.items.length > 0) {
                        appConfig.USER_ID = responseData.items[0].user_id.toString();
                    }
                    if (!deepFetch) {
                        return responseData;
                    }
                    responseData.items.forEach((tracker) => {
                        if (tracker.subscription) {
                            dispatch.userSubscriptions.setSubscriptionBasedOnStore(
                                tracker.subscription,
                            );
                        }
                        // add loading value depending if the store is filled  with trackers
                        dispatch.login.updateLoading(true);
                    });

                    return Promise.all(promises);
                },
            );
        },
        async fetchSingleTracker({ trackerId }: { trackerId: number }) {
            return fetchTracker(trackerId).then((data: ITracker) => {
                this.updateTracker(data);
            });
        },
        async updateTrackerWithDeepSleep({ trackerId, deepSleep }) {
            return fetchTracker(trackerId).then((data: ITracker) => {
                data.enable_deep_sleep_wifi = deepSleep;
                this.updateTracker(data);
            });
        },
        async selectActiveTracker(
            trackerId: number | null,
            rootState: IRootState,
        ) {

            this.setActiveTracker(trackerId);

            const activeTrackerData = rootState.userTrackers.rawTrackers.filter(
                (tracker: ITracker) => tracker.id === trackerId,
            )[0];

            if (!activeTrackerData) {
                return;
            }

            const position = get(activeTrackerData, 'position[0]');
            if (position && position.latitude && position.longitude) {
                const latLng = [position.latitude, position.longitude];
                dispatch.map.focusOnTracker(latLng);
            }
        },
        async selectTheme(trackerId: number | null, rootState: IRootState) {
            dispatch.login.updateLoading(false);
            const trackers = rootState.userTrackers.rawTrackers;
            const fetched = rootState.userTrackers.fetched;
            if (!fetched) {
                return;
            }
            return this.setTheme(Theme.WEENECT);
        },
        async makeTrackerVibrate(trackerId: number) {
            return vibrateTracker(trackerId);
        },
        async makeTrackerFlash(payload: any) {
            const trackerId = payload.tracker_Id;
            const durationMsOn = payload.duration_ms_on;
            const durationMsOff = payload.duration_ms_off;
            return flashTracker(trackerId, durationMsOn, durationMsOff);
        },
        async makeTrackerRing(trackerId: number) {
            return ringTracker(trackerId);
        },
        async makeTrackerCall(trackerId: number, rootState: IRootState) {
            try {
                const sosPhone = rootState.userTrackers.rawTrackers
                    .filter((tracker) => tracker.id === trackerId)
                    .map((tracker) => tracker.sos_phone)
                    .reduce((phone) => phone);

                return callTracker(trackerId, sosPhone);
            } catch (err) {
                // No phone found, do nothing?
            }
        },
        async setNewTrackerDevice({
            trackerId,
            imei,
        }: {
            trackerId: number;
            imei: number | string;
        }) {
            return assignNewDevice(trackerId, imei);
        },
        /**
         * Display error notification if new positions are not fetched
         * for trackers that have been asked for a refresh of position.
         * Also remove every watchedPositions.
         */
        _stopWatchPositions() {
            clearTimeout(watchedPositionsTimeout);
            this._clearWatchTrackerPositions();
            dispatch.notifications.clearNotifications();
            dispatch.notifications.showNotification({
                content: t('trackersPositionRefresh:MESSAGES.NOT_REFRESHED'),
                type: 'error',
            });
        },
        async forceRefreshPositions(payload: any, models: IRootState) {
            const getAllTrackersIds = makeGetAllTrackersIds();
            const getActiveTrackerId = makeGetSelectedTrackerId();
            const activeTrackerId = getActiveTrackerId(models);

            /* Clear timeout after watchTimeout interval */
            watchedPositionsTimeout = setTimeout(
                dispatch.userTrackers._stopWatchPositions,
                watchTimeout,
            );
            /**
             * If tracker is selected, fetch only this one.
             * Otherwise, fetch all online trackers
             */
            if (activeTrackerId) {
                return forceRequestTrackersPositions([activeTrackerId]).then(
                    () => {
                        dispatch.userTrackers._watchTrackersPositions([
                            activeTrackerId,
                        ]);
                        dispatch.userTrackers.fetchTrackersPosition(
                            activeTrackerId,
                        );
                    },
                );
            } else {
                const onlineTrackerIds = getAllTrackersIds(models).filter(
                    (id) => {
                        const getTrackerModel = makeGetTrackerModel(id);

                        return get(
                            getTrackerModel(models),
                            'position[0].is_online',
                            false,
                        );
                    },
                );

                dispatch.userTrackers._watchTrackersPositions(onlineTrackerIds);

                return forceRequestTrackersPositions(
                    onlineTrackerIds,
                ).then(() => dispatch.userTrackers.fetchTrackersPosition());
            }
        },
        async fetchTrackersPosition(payload, models) {
            return (
                !document.hidden &&
                fetchTrackersPosition().then(
                    (lastPositionObjects: ITrackerEventsOptimized[]) => {
                        if (lastPositionObjects.length) {
                            // Loop over the last position (fetched from API) for each tracker.
                            lastPositionObjects.forEach(
                                (lastPositionObject) => {
                                    const trackerId =
                                        lastPositionObject.tracker_id;
                                    const position =
                                        lastPositionObject.position;

                                    // When tracker had no position or last one is older than 30 days (thus erased from DB)
                                    if (position.length) {
                                        const lastPosition = position[0];

                                        // Determines if customer called the refresh API endpoint
                                        // and compare new position to the one saved
                                        this._isWatchedTrackerPosition({
                                            trackerId,
                                            newPosition: lastPosition,
                                        });

                                        const stateNewPosition = models
                                            .userTrackers.newPositions as {
                                                trackerId: number;
                                                positions: ITrackerEvent[];
                                            }[];

                                        const trackerIndex = stateNewPosition.findIndex(
                                            (pos: {
                                                trackerId: number;
                                                positions: ITrackerEvent[];
                                            }) => pos.trackerId === trackerId,
                                        );

                                        const stateTracker = models.userTrackers.rawTrackers.find(
                                            (tracker: ITracker) =>
                                                tracker.id === trackerId,
                                        ) as ITracker;

                                        if (
                                            // Save new position if this tracker is not yet saved in userTrackers.newPositions object.
                                            trackerIndex === -1 ||
                                            // Or if last fetched position is not yet present in state newPositions.
                                            stateNewPosition[
                                                trackerIndex
                                            ].positions.slice(-1)[0].id !==
                                            lastPosition.id
                                        ) {
                                            this._saveNewPosition({
                                                trackerId,
                                                trackerIndex,
                                                newPosition: lastPosition,
                                            });
                                        }
                                        // Only call reducer (and change state) if real new position.
                                        // Which means last fetched position is not yet present in saved positions of the tracker.
                                        if (
                                            stateTracker.position.slice(-1)[0] === undefined ||
                                            stateTracker.position.slice(-1)[0]
                                                .id !== lastPosition.id
                                        ) {
                                            return this._updateTrackerPosition({
                                                trackerId,
                                                newPosition: lastPosition,
                                            });
                                        }
                                    }
                                },
                            );
                        }
                    },
                )
            );
        },
        /**
         * Determines if positions of a tracker are watched or not.
         * We watch positions after a refresh call made to API.
         *
         * Display success notification when new cooordinates are fetch.
         * Display error notification after x seconds without new cooordinates.
         */
        _isWatchedTrackerPosition(
            payload: { trackerId: number; newPosition: ITrackerEvent },
            models,
        ) {
            const modelWatchedPositions = models.userTrackers
                .watchedPositions as {
                    id: number;
                    position: ITrackerEvent;
                }[];
            const watchedPositionIndex = modelWatchedPositions.findIndex(
                (watchedPosition) => {
                    return watchedPosition.id === payload.trackerId;
                },
            );
            if (watchedPositionIndex !== -1) {
                this._updateWatchTrackerPosition(
                    watchedPositionIndex,
                    payload.newPosition,
                );
                const postUpdatewatchedPositionIndex = modelWatchedPositions.findIndex(
                    (watchedPosition) => {
                        return watchedPosition.id === payload.trackerId;
                    },
                );
                // If watchedPosition is not in modelWatchedPositions anymore,
                // it means it got removed in _updateWatchTrackerPosition
                // after a new position has been detected.
                if (postUpdatewatchedPositionIndex === -1) {
                    clearTimeout(watchedPositionsTimeout);
                    this._clearWatchTrackerPositions();
                    dispatch.notifications.clearNotifications();
                    dispatch.notifications.showNotification({
                        content: t('trackersPositionRefresh:MESSAGES.SUCCESS'),
                        type: 'success',
                    });
                }
            }
        },
        async updateQuery(payload, models) {
            this.settrackerSearchQuery(payload);
        },
        async continuouslyFetchTrackersPositions(payload, models) {
            if (continuouslyFetchAllTrackerPositionInterval) {
                clearInterval(continuouslyFetchAllTrackerPositionInterval);
            }

            continuouslyFetchAllTrackerPositionInterval = setInterval(() => {
                dispatch.userTrackers.fetchTrackersPosition();
            }, fetchInterval);
        },
    }),
    reducers: {
        setTrackers: (
            state: ITrackerStore,
            trackers: ITracker[],
        ): ITrackerStore => ({
            ...state,
            rawTrackers: trackers,
            fetched: true,
        }),

        settrackerSearchQuery: (
            state: ITrackerStore,
            query: string,
        ): ITrackerStore => ({ ...state, trackerSearchQuery: query }),

        updateTracker: (state: ITrackerStore, tracker: ITracker) => {
            // Append new tracker in case of registration
            const savedTrackers = state.rawTrackers;
            const trackerAlreadyExist = state.rawTrackers.find(
                (rawTracker) => rawTracker.id === tracker.id,
            );
            if (trackerAlreadyExist === undefined) {
                savedTrackers.push(tracker);
                return {
                    ...state,
                    rawTrackers: savedTrackers,
                };
            }
            // Modify tracker in case of update
            return {
                ...state,
                rawTrackers: state.rawTrackers.map((rawTracker) => {
                    if (tracker.id === rawTracker.id) {
                        return tracker;
                    }

                    return rawTracker;
                }),
            };
        },
        setActiveTracker: (
            state: ITrackerStore,
            newActiveTracker: number | null,
        ): ITrackerStore => ({
            ...state,
            activeTracker: newActiveTracker,
        }),
        updateGeofence: (
            state: ITrackerStore,
            payload: {
                trackerId: number;
                method: string;
                geofence: IRawGeofence;
            },
        ) => {
            const newState = { ...state };
            newState.rawTrackers.map((rawTracker) => {
                if (rawTracker.id === payload.trackerId) {
                    if (payload.method === 'add') {
                        rawTracker.zones?.push(payload.geofence);
                    } else if (
                        payload.method === 'remove' &&
                        rawTracker.zones
                    ) {
                        const index = rawTracker.zones.findIndex((o) => {
                            return o.id === payload.geofence.id;
                        });
                        if (index !== -1) {
                            rawTracker.zones.splice(index, 1);
                        }
                    }
                }
            });
            return newState;
        },

        setTheme: (state: ITrackerStore, newTheme: Theme): ITrackerStore => ({
            ...state,
            theme: newTheme,
        }),
        updateFrequencyInterval: (
            state: ITrackerStore,
            payload: { trackerId: number; newMode: number },
        ) => {
            const newState = { ...state };
            newState.rawTrackers = newState.rawTrackers.map((rawTracker) => {
                if (rawTracker.id === payload.trackerId) {
                    rawTracker.last_freq_mode = formatTimeInterval(
                        payload.newMode,
                    );
                }
                return rawTracker;
            });

            return newState;
        },
        setFirstFetch: (state: ITrackerStore) => ({
            ...state,
            firstFetch: true,
        }),
        _updateTrackerPosition: (
            state: ITrackerStore,
            {
                trackerId,
                newPosition,
            }: { trackerId: number; newPosition: ITrackerEvent },
        ) => {
            // Todo, handle it from backend
            if (newPosition.type === 'ALM-OFF') {
                newPosition.is_online = false;
            }

            return {
                ...state,
                rawTrackers: state.rawTrackers.map((tracker) => {
                    if (tracker.id === trackerId) {
                        const modifiedTracker = { ...tracker };
                        modifiedTracker.position = [newPosition];

                        return modifiedTracker;
                    }

                    return tracker;
                }),
            };
        },
        /**
         * Hydrate watchedPosition state var depending on list of IDs parameter.
         * Used when customer clicks on the refresh button to ask for a new position to the tracker
         */
        _watchTrackersPositions: (
            state: ITrackerStore,
            trackerIds: number[],
        ): ITrackerStore => {
            /**
             * Create an empty list and push needed object of type {id, position} to this list.
             * This list become the watchedPositions.
             */
            const getWatchedPositions = () => {
                const watchedPositionsList: {
                    id: number;
                    position: ITrackerEvent;
                }[] = [];
                trackerIds.forEach((trackerId) => {
                    const trackerToWatch = state.rawTrackers.find((tracker) => {
                        return tracker.id === trackerId;
                    });
                    if (trackerToWatch !== undefined) {
                        watchedPositionsList.push({
                            id: trackerToWatch.id,
                            position: trackerToWatch.position[0], // Watch only last position
                        });
                    }
                });
                return watchedPositionsList;
            };
            return {
                ...state,
                watchedPositions: getWatchedPositions(),
            };
        },
        /**
         * Compare Lat and Lng from API call to the one from the watchedPositions state var.
         * If the position retrieved is indeed a NEW position :
         * We remove the watched position from the state variable.
         */
        _updateWatchTrackerPosition: (
            state: ITrackerStore,
            watchedIndex: number,
            newPosition: ITrackerEvent,
        ): ITrackerStore => {
            const updatedWatchedPositions = state.watchedPositions;
            if (
                updatedWatchedPositions[watchedIndex].position.latitude !==
                newPosition.latitude ||
                updatedWatchedPositions[watchedIndex].position.longitude !==
                newPosition.longitude
            ) {
                updatedWatchedPositions.splice(watchedIndex, 1);
            }
            return {
                ...state,
                watchedPositions: updatedWatchedPositions,
            };
        },
        _clearWatchTrackerPositions: (state: ITrackerStore): ITrackerStore => {
            return {
                ...state,
                watchedPositions: [],
            };
        },
        /**
         * Each time API fetch a new position :
         *   - Save new position linked to a trackerId in the newPositions list.
         *   - Only keep the last 10th positions fetched for each tracker.
         *
         *  @param payload.trackerId : The id of the tracker of the newly fetched position
         *  @param payload.trackerIndex : The index of the object {trackerId: number, positions: ITrackerEvent} of the tracker newly fetched
         * in the state.newPositions list. Can be undefined or a number
         *  @param newPosition : The newly fetched position of the tracker of id @param payload.trackerId
         */
        _saveNewPosition: (
            state: ITrackerStore,
            payload: {
                trackerId: number;
                trackerIndex: number;
                newPosition: ITrackerEvent;
            },
        ) => {
            let statePositions = state.newPositions;

            // If lat and long are null, don't update newPositions
            if (
                payload.newPosition.latitude === null ||
                payload.newPosition.longitude === null
            ) {
                return {
                    ...state,
                    newPositions: state.newPositions,
                };
            }

            // When reveiving a position from API for a tracker that does not have saved potions :
            if (payload.trackerIndex === -1) {
                // Simply push the new tracker id and its new positions composed of a list of one position.
                statePositions.push({
                    trackerId: payload.trackerId,
                    positions: [payload.newPosition],
                });
            } else {
                // When receiving a position from API for a tracker that already have saved positions :
                // Push the new position for that tracker.
                statePositions[payload.trackerIndex].positions.push(
                    payload.newPosition,
                );
            }

            // Only keep last 10th positions
            statePositions = statePositions.map((savedPos) => {
                if (savedPos.trackerId === payload.trackerId) {
                    return {
                        trackerId: payload.trackerId,
                        positions: savedPos.positions.slice(
                            Math.max(
                                savedPos.positions.length -
                                (POSITIONS_TO_SAVE - 1), // X - 1 = keep last Xth positions
                                0,
                            ),
                        ),
                    };
                }
                return savedPos;
            });

            return {
                ...state,
                newPositions: statePositions,
            };
        },
    },
};
