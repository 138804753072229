import cx from 'classnames';
import React, { HTMLAttributes } from 'react';

import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as DashIcon } from '../../icons/dash.svg';

import styles from './card-section.module.scss';

export interface ICardSectionProps extends HTMLAttributes<HTMLDivElement> {
    onClose: () => void;
    breadcrumbs: string[];
    stretch?: boolean;
    isMobileRedirect?: boolean;
}

const maybeRenderLongTrackerName = (name: string) => {
    if (name.length > 40) {
        const split = Math.floor(name.length / 2);
        return (
            <div>
                <span className={styles.breadcrumb}>
                    {name.substr(0, split)}
                </span>
                <br />
                <span className={styles.breadcrumb}>
                    {name.substr(split + 1)}
                </span>
            </div>
        );
    }
    return <span className={styles.breadcrumb}>{name}</span>;
};
/**
 * Wrapper component with heading bar with breadcrumbs and close button
 */
export const CardSection = ({
    className,
    children,
    onClose,
    breadcrumbs,
    stretch,
    isMobileRedirect,
    ...props
}: ICardSectionProps) => {
    return (
        <div
            className={cx(
                styles['card-section'],
                {
                    [styles.stretch]: stretch,
                },
                className,
            )}
            {...props}
        >
            <div className={styles['title-bar']}>
                {isMobileRedirect ? null : (
                    <DashIcon
                        className={styles['mobile-back-button']}
                        onClick={onClose}
                        role="button"
                    />
                )}
                {breadcrumbs.map((breadcrumb, i) => (
                    <React.Fragment key={breadcrumb + i}>
                        {maybeRenderLongTrackerName(breadcrumb)}
                        <DashIcon
                            className={cx(styles['dash-icon'], {
                                [styles.last]: i === breadcrumbs.length - 1,
                            })}
                        />
                    </React.Fragment>
                ))}
                <CrossIcon
                    data-testid="card-section-close-icon"
                    onClick={onClose}
                    className={styles['close-icon']}
                    role="button"
                />
            </div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};
