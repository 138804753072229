import React, { HTMLAttributes } from 'react';

import { InfoIcon } from '../../../common/info-icon/info-icon.component';

import iconBadSignal from '../../../icons/signal-bad.svg';
import iconGoodSignal from '../../../icons/signal-good.svg';
import iconMediumSignal from '../../../icons/signal-medium.svg';

// No extra props, just what div takes
export interface ITrackerAttributesSignalProps
    extends HTMLAttributes<HTMLDivElement> {
    signalStrength: number;
}

const maxStrength = 30;
const strengthGood = 16;
const strengthMedium = 10;

const resolveSignalIcon = (signalStrength: number) => {
    if (signalStrength > strengthGood) {
        return iconGoodSignal;
    } else if (signalStrength > strengthMedium) {
        return iconMediumSignal;
    }

    return iconBadSignal;
};

/**
 * https://redmine.weenect.com/issues/5648
 * Handle incoherent data retrieved from tracker raw message concerning the gsm field
 */
const getPercent = (strength: number) => {
    const signalStrengthPercent = (strength / maxStrength) * 100;
    if (signalStrengthPercent > 100) {
        return 100;
    }
    return signalStrengthPercent;
};

export const TrackerAttributeSignal = ({
    signalStrength,
    ...props
}: ITrackerAttributesSignalProps) => (
    <InfoIcon
        icon={<img alt="Signal" src={resolveSignalIcon(signalStrength)} />}
        label={getPercent(signalStrength).toFixed(0) + '%'}
        {...props}
    />
);
