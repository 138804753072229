import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { ReactComponent as AnimalCatIcon } from '../../../images/animalcat.svg';
import { ReactComponent as AnimalDogIcon } from '../../../images/animaldog.svg';
import { ReactComponent as FamilyKidIcon } from '../../../images/familykid.svg';
import { ReactComponent as FamilySilverIcon } from '../../../images/familysilver.svg';
import { NextButton } from '../../common/button/next-button.component';
import { Headline } from '../../common/headline/headline.component';
import { PageContainer } from '../../layout/page-container/page-container.component';
import { Dispatch } from '../../store/store';
import { TrackerType } from '../../trackers/interfaces';
import styles from '../fluid-type-switch.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {
    trackerToUpdate: number | undefined;
    currentTrackerType: TrackerType;
    typeSwitchOutcome: () => any;
}
interface IOuterProps { }

interface IActions {
    refreshTrackerList: () => any;
    doUpdateSingleTracker: (
        trackerId: number | undefined,
        newType: string,
    ) => Promise<any>;
}

enum translationsToUse {
    ANIMAL_CAT = 'CAT_TYPE',
    ANIMAL_DOG = 'DOG_TYPE',
    ANIMAL_SUBTITLE = 'ANIMAL_SUBTITLE',
    FAMILY_KID = 'KID_TYPE',
    FAMILY_SILVER = 'SILVER_TYPE',
    FAMILY_SUBTITLE = 'FAMILY_SUBTITLE',
}

export interface IFluidTypeSwitch extends IOuterProps, IActions, IProps { }

export const FluidTypeSwitch = ({
    refreshTrackerList,
    trackerToUpdate,
    currentTrackerType,
    doUpdateSingleTracker,
    typeSwitchOutcome,
}: IFluidTypeSwitch) => {
    enum FamilyParametersFirstChoice {
        AVAILABLETYPE = TrackerType.FAMILYKID,
        TYPETEXT = translationsToUse.FAMILY_KID,
        TYPETOUSE = TrackerType.FAMILY,
    }
    enum FamilyParametersSecondChoice {
        AVAILABLETYPE = TrackerType.FAMILYSILVER,
        TYPETEXT = translationsToUse.FAMILY_SILVER,
        TYPETOUSE = TrackerType.FAMILY,
    }
    enum AnimalParametersFirstChoice {
        AVAILABLETYPE = TrackerType.ANIMALCAT,
        TYPETEXT = translationsToUse.ANIMAL_CAT,
        TYPETOUSE = TrackerType.ANIMAL,
    }
    enum AnimalParametersSecondChoice {
        AVAILABLETYPE = TrackerType.ANIMALDOG,
        TYPETEXT = translationsToUse.ANIMAL_DOG,
        TYPETOUSE = TrackerType.ANIMAL,
    }

    const famillyChoices = [
        FamilyParametersFirstChoice,
        FamilyParametersSecondChoice,
    ];

    const animalChoices = [
        AnimalParametersFirstChoice,
        AnimalParametersSecondChoice,
    ];
    const trackerTypeParameters = new Map<TrackerType, any[]>([
        [TrackerType.FAMILY, famillyChoices],
        [TrackerType.ANIMAL, animalChoices],
    ]);
    const subTitlesMapper = new Map<TrackerType, string>([
        [TrackerType.FAMILY, translationsToUse.FAMILY_SUBTITLE],
        [TrackerType.ANIMAL, translationsToUse.ANIMAL_SUBTITLE],
    ]);

    const typeChangeT = useTranslation('TypeSwitch').t;
    const commonActionT = useTranslation('commonActions').t;
    const [typeSelection, setTypeSelection] = useState<TrackerType>(
        currentTrackerType,
    );
    const [strongTyper] = useState<TrackerType>(typeSelection);

    const getImage = (type: TrackerType) => {
        switch (type) {
            case TrackerType.ANIMALCAT:
                return <AnimalCatIcon className={styles.icon} />;
            case TrackerType.ANIMALDOG:
                return <AnimalDogIcon className={styles.icon} />;
            case TrackerType.FAMILYKID:
                return <FamilyKidIcon className={styles.iconFamily} />;
            case TrackerType.FAMILYSILVER:
                return <FamilySilverIcon className={styles.iconFamily} />;
        }
    };
    const updateType = (newType: string) => {
        doUpdateSingleTracker(trackerToUpdate, newType).then(() => {
            refreshTrackerList().then(() => {
                typeSwitchOutcome();
            });
        });
    };

    return (
        <div className={styles.heightSetter}>
            <PageContainer className={styles.container}>
                <Headline className={styles.headline} center>
                    {typeChangeT('TITLE')}
                </Headline>
                <Headline level={4} className={styles['main-subtitle']} center>
                    {typeChangeT(subTitlesMapper.get(strongTyper) || '')}
                </Headline>
                <div className={styles.typeFormContainer}>
                    <div className={styles.animalChoicesContainer}>
                        {trackerTypeParameters
                            .get(strongTyper)!
                            .map((choice) => (
                                <div
                                    key={choice.AVAILABLETYPE}
                                    className={
                                        typeSelection === choice.AVAILABLETYPE
                                            ? styles.iconTitleContainerSelected
                                            : styles.iconTitleContainer
                                    }
                                    onClick={() =>
                                        setTypeSelection(choice.AVAILABLETYPE)
                                    }
                                >
                                    {getImage(choice.AVAILABLETYPE)}
                                    <p
                                        className={
                                            typeSelection ===
                                                choice.AVAILABLETYPE
                                                ? styles[
                                                'animal-type-subtitle-selected'
                                                ]
                                                : styles['animal-type-subtitle']
                                        }
                                    >
                                        {typeChangeT(choice.TYPETEXT)}
                                    </p>
                                </div>
                            ))}
                    </div>
                    <NextButton
                        className={styles.animalTypeSubmitButton}
                        disabled={
                            typeSelection === TrackerType.ANIMAL ||
                            typeSelection === TrackerType.FAMILY
                        }
                        onClick={() => updateType(typeSelection)}
                    >
                        {commonActionT('VALIDATE')}
                    </NextButton>
                </div>
            </PageContainer>
        </div>
    );
};

const mapDispatch = (dispatch: Dispatch) => ({
    doUpdateSingleTracker: async (trackerId: number, newType: string) =>
        dispatch.trackersSettings.putSingleTracker({ trackerId, newType }),
    refreshTrackerList: () =>
        dispatch.userTrackers.fetchTrackers({ deepFetch: true }),
});

export const ConnectedFluidTypeSwitch = compose<IFluidTypeSwitch, IProps>(
    connect(null, mapDispatch),
)(FluidTypeSwitch);
