// Get the list of Hipay transaction statuses here
// https://developer.hipay.com/getting-started/platform-hipay-enterprise/appendix/#transaction-statuses
const HIPAY_AUTHORIZED_STATUS = 116;
const HIPAY_OPERATION_CANCEL = 'cancel';

interface IHipayBrowserInfo {
    java_enabled: boolean;
    javascript_enabled: boolean;
    language: string;
    color_depth: boolean;
    screen_height: number;
    screen_width: number;
    timezone: string;
    http_user_agent: string;
    ipaddr: string;
    http_accept: string;
}

export interface IHipayTokenizeResponse {
    payment_product: string;
    token: string;
    request_id: string;
    card_id: string;
    multi_use: number;
    brand: string;
    pan: string;
    card_holder: string;
    card_expiry_month: string;
    card_expiry_year: string;
    issuer: string;
    country: string;
    card_type: string;
    forbidden_issuer_country: boolean;
    device_fingerprint: string;
    browser_info: IHipayBrowserInfo;
}

export interface IProcessPayment {
    language: string;
    browserInfo: IHipayBrowserInfo;
    cardToken: string;
    reference: string;
    cdata1: string;
    status: number;
    cardExpiry: string; // Format is : yyyyMM
    pp: string; // Payment Product
    subscriptionId: number;
    card_pan: string;
}

export const buildProcessPayment = (
    response: IHipayTokenizeResponse,
    userLanguage: string,
    currentSubscriptionId: number,
): IProcessPayment => {
    return {
        language: userLanguage,
        browserInfo: response.browser_info,
        cardToken: response.token,
        reference: response.card_id,
        cdata1: HIPAY_OPERATION_CANCEL, // <-- Correspond to the operation to make ('capture' or 'cancel')
        status: HIPAY_AUTHORIZED_STATUS,
        cardExpiry: response.card_expiry_year + response.card_expiry_month,
        pp: response.payment_product,
        subscriptionId: currentSubscriptionId,
        card_pan: response.pan,
    };
};
