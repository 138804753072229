import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './active-indicator.module.scss';

export interface IActiveIndicatorProps extends HTMLAttributes<HTMLDivElement> {
    active: boolean;
    pending?: boolean;
}

export const ActiveIndicator = ({
    active,
    pending,
    className,
    ...props
}: IActiveIndicatorProps) => {
    const { t } = useTranslation('subscriptions');

    const getLabelText = () => {
        if (pending) {
            return t('STATUS.PENDING');
        } else {
            if (active) {
                return t('STATUS.ACTIVE');
            } else {
                return t('STATUS.INACTIVE');
            }
        }
    };

    return (
        <div
            className={cx(
                styles.activeIndicator,
                {
                    [styles.active]: active && !pending,
                    [styles.inactive]: !active && !pending,
                    [styles.pending]: pending,
                },
                className,
            )}
            {...props}
        >
            {getLabelText()}
        </div>
    );
};
