import { FormikProps, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getAvailableCountries } from '../../../i18n/available-countries';
import { getGlobalT } from '../../../i18n/setup-translations';
import { IWithFormUtils, withFormUtils } from '../../../utils/form-helpers';
import { Button } from '../../common/button/button.component';
import { SaveButton } from '../../common/button/save-button.component';
import { Checkbox } from '../../common/checkbox/checkbox.component';
import { Headline } from '../../common/headline/headline.component';
import { Input } from '../../common/input/input.component';
import { Radio } from '../../common/radio/radio.component';
import { Select } from '../../common/select/select.component';
import { Typography } from '../../common/typography/typography.component';
import { MetricSystem } from '../../history/interfaces';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { Grid } from '../../layout/grid/grid.component';
import { INotification } from '../../notifications/interfaces';
import { Dispatch, IRootState } from '../../store/store';
import { makeGetUserEditableFields } from '../../user/user-selectors';
import { getUserProfileValidationSchema } from './user-profile-validation.schema';
import styles from './user-profile.module.scss';

export interface IUserProfileFormValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    street: string;
    postalCode: string;
    city: string;
    country: string;
    marketingAccepted: boolean;
    preferredMetricSystem: string;
}

interface IStateProps {
    /**
     * Values user account already has set
     */
    existingValues: IUserProfileFormValues;
}

interface IActions {
    submit(data: IUserProfileFormValues): Promise<unknown>;
    showNotification(n: INotification): unknown;
    clearNotifications(): unknown;
}

interface IOuterProps { }

interface IProps
    extends IOuterProps,
    IActions,
    IStateProps,
    FormikProps<IUserProfileFormValues>,
    IWithFormUtils { }

const countries = getAvailableCountries();

export const UserProfile = ({
    handleSubmit,
    handleReset,
    values,
    isValid,
    initialValues,
    getErrorMessage,
    resetForm,
    isFieldValid,
    handleChange,
    handleBlur,
    clearNotifications,
    setFieldValue,
}: IProps) => {
    const { t } = useTranslation(['account', 'forms', 'commonActions']);
    useEffect(() => () => void clearNotifications(), [clearNotifications]);
    const [shouldBeTypo, setShouldBeTypo] = useState<boolean>(true);
    const [updatedForm, setUpdatedForm] = useState<boolean>(false);
    const [updatedRadio, setUpdatedRadio] = useState<boolean>(false);

    const preventSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const onModification = () => {
        setShouldBeTypo(false);
    };
    const submitChange = () => {
        handleSubmit();
        setShouldBeTypo(true);
        setUpdatedForm(true);
        setTimeout(() => {
            setUpdatedForm(false);
        }, 2000);
    };

    const submitChangeRadioCheck = () => {
        handleSubmit();
        setUpdatedRadio(true);
        setTimeout(() => {
            setUpdatedRadio(false);
        }, 2000);
    };

    const cancelModification = () => {
        //@ts-ignore
        resetForm(initialValues);
        setShouldBeTypo(true);
    };

    const renderCountrySelect = () => {
        if (shouldBeTypo === true) {
            return (
                <div>
                    <Typography className={styles['control-title']}>
                        {t('forms:COUNTRY.LABEL')}
                    </Typography>
                    <Typography className={styles['control-text']}>
                        {values.country || countries[0].code}
                    </Typography>
                </div>
            );
        }
        return (
            <Select
                className={styles.control}
                label={t('forms:COUNTRY.LABEL')}
                name="country"
                onChange={handleChange}
                value={values.country || countries[0].code}
                small
            >
                {countries.map((c) => (
                    <option key={c.code} value={c.code}>
                        {c.name}
                    </option>
                ))}
            </Select>
        );
    };

    const maybeRenderSuccessMessageRadio = () => {
        if (updatedRadio === true) {
            return (
                <Typography className={styles.radioSuccessMessage}>
                    {t('trackerSettings:DISPLAY.MESSAGES.UPDATE_SUCCESS')}
                </Typography>
            );
        }
    };

    const maybeRenderSuccessMessageForm = () => {
        if (updatedForm === true) {
            return (
                <Typography className={styles.formSuccessMessage}>
                    {t('trackerSettings:DISPLAY.MESSAGES.UPDATE_SUCCESS')}
                </Typography>
            );
        }
    };

    const maybeRenderEditButton = () => {
        if (shouldBeTypo === true) {
            return (
                <Button
                    onClick={() => onModification()}
                    className={styles['edit-button']}
                    title="Edit"
                >
                    <EditIcon className={styles['form-svg']} />
                </Button>
            );
        }
    };

    const renderValidSaveButton = () => {
        if (isValid) {
            return (
                <SaveButton onClick={() => submitChange()}>
                    {t('commonActions:SAVE')}
                </SaveButton>
            );
        }
        return <Button disabled>{t('commonActions:SAVE')}</Button>;
    };

    const maybeRenderSaveButtons = () => {
        if (shouldBeTypo === false) {
            return (
                <Grid>
                    <Grid className={styles.buttons}>
                        {renderValidSaveButton()}
                        <Button onClick={() => cancelModification()} secondary>
                            {t('commonActions:CANCEL')}
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    };

    const renderRadioCheckbox = () => {
        return (
            <div>
                <div className={styles['control-title']}>
                    {t('USER_PROFILE.PREFERRED_METRIC')}
                </div>
                {maybeRenderSuccessMessageRadio()}
                <div className={styles['control-radio']}>
                    <Radio
                        className={styles.preferredMetric}
                        name="preferredMetricSystem"
                        key="km"
                        checked={
                            values.preferredMetricSystem === MetricSystem.KM
                        }
                        value={MetricSystem.KM}
                        onChange={(e) => {
                            // https://github.com/formium/formik/issues/1218
                            handleChange(e);
                            setTimeout(submitChangeRadioCheck, 0);
                        }}
                    >
                        <label className={styles.values}>
                            {t('USER_PROFILE.METRIC_KM')}
                        </label>
                    </Radio>
                    <Radio
                        className={styles.preferredMetric}
                        name="preferredMetricSystem"
                        key="miles"
                        checked={
                            values.preferredMetricSystem === MetricSystem.MILES
                        }
                        value={MetricSystem.MILES}
                        onChange={(e) => {
                            // https://github.com/formium/formik/issues/1218
                            handleChange(e);
                            setTimeout(submitChangeRadioCheck, 0);
                        }}
                    >
                        <label className={styles.values}>Miles</label>
                    </Radio>
                </div>
                <div className={styles.control}>
                    <Checkbox
                        onChange={(e) => {
                            // https://github.com/formium/formik/issues/1218
                            handleChange(e);
                            setTimeout(submitChangeRadioCheck, 0);
                        }}
                        name="marketingAccepted"
                        checked={values.marketingAccepted}
                    >
                        {t('USER_PROFILE.MARKETING_AGREEMENT_TEXT')}
                    </Checkbox>
                </div>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <div>
                <div className={styles['title-container']}>
                    <Headline className={styles.headline}>
                        {t('USER_PROFILE.MAIN_HEADLINE')}
                    </Headline>
                    {maybeRenderEditButton()}
                </div>
                {maybeRenderSuccessMessageForm()}
                <form onSubmit={preventSubmit} onReset={handleReset}>
                    <Grid className={styles['info-container']}>
                        <div className={styles['basic-info-container-input']}>
                            <Headline className={styles.subHeadline} level={2}>
                                {t('USER_PROFILE.BASIC_INFO_HEADLINE')}
                            </Headline>
                            <Input
                                value={values.firstName}
                                small
                                label={t('forms:FIRST_NAME.LABEL')}
                                placeholder={t('forms:FIRST_NAME.PLACEHOLDER')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="acc-profile-first-name"
                                name="firstName"
                                isValid={isFieldValid('firstName')}
                                errorMessage={getErrorMessage('firstName')}
                                className={styles.control}
                                shouldBeTypo={shouldBeTypo}
                                inputTitle={t('forms:FIRST_NAME.LABEL')}
                                inputText={values.firstName}
                            />
                            <Input
                                small
                                value={values.lastName}
                                label={t('forms:LAST_NAME.LABEL')}
                                placeholder={t('forms:LAST_NAME.PLACEHOLDER')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="acc-profile-last-name"
                                name="lastName"
                                isValid={isFieldValid('lastName')}
                                errorMessage={getErrorMessage('lastName')}
                                className={styles.control}
                                shouldBeTypo={shouldBeTypo}
                                inputTitle={t('forms:LAST_NAME.LABEL')}
                                inputText={values.lastName}
                            />
                            <Input
                                small
                                value={values.email}
                                label={t('forms:EMAIL.LABEL')}
                                placeholder={t('forms:EMAIL.PLACEHOLDER')}
                                type="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="acc-profile-email"
                                name="email"
                                isValid={isFieldValid('email')}
                                errorMessage={getErrorMessage('email')}
                                className={styles.control}
                                shouldBeTypo={shouldBeTypo}
                                inputTitle={t('forms:EMAIL.LABEL')}
                                inputText={values.email}
                            />
                            <Input
                                small
                                value={values.phone || ''}
                                label={t('forms:PHONE.LABEL')}
                                placeholder={t('forms:PHONE.PLACEHOLDER')}
                                onBlur={handleBlur}
                                onChange={(phoneValue) =>
                                    setFieldValue('phone', phoneValue)
                                }
                                id="acc-profile-phone"
                                name="phone"
                                isValid={isFieldValid('phone')}
                                errorMessage={getErrorMessage('phone')}
                                className={styles.control}
                                isPhone
                                shouldBeTypo={shouldBeTypo}
                                inputTitle={t('forms:PHONE.LABEL')}
                                inputText={values.phone || ''}
                            />
                        </div>
                        <div className={styles['billing-info-container-input']}>
                            <Headline className={styles.subHeadline} level={2}>
                                {t('USER_PROFILE.BILLING_HEADLINE')}
                            </Headline>
                            <Input
                                small
                                value={values.street || ''}
                                label={t('forms:STREET.LABEL')}
                                placeholder={t('forms:STREET.PLACEHOLDER')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="acc-profile-street"
                                name="street"
                                isValid={isFieldValid('street')}
                                errorMessage={getErrorMessage('street')}
                                className={styles.control}
                                shouldBeTypo={shouldBeTypo}
                                inputTitle={t('forms:STREET.LABEL')}
                                inputText={values.street || ''}
                            />
                            <Input
                                small
                                value={values.postalCode || ''}
                                label={t('forms:POSTAL_CODE.LABEL')}
                                placeholder={t('forms:POSTAL_CODE.PLACEHOLDER')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="acc-profile-postal-code"
                                name="postalCode"
                                isValid={isFieldValid('postalCode')}
                                errorMessage={getErrorMessage('postalCode')}
                                className={styles.control}
                                shouldBeTypo={shouldBeTypo}
                                inputTitle={t('forms:POSTAL_CODE.LABEL')}
                                inputText={values.postalCode || ''}
                            />
                            <Input
                                small
                                label={t('forms:CITY.LABEL')}
                                placeholder={t('forms:CITY.PLACEHOLDER')}
                                value={values.city || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="acc-profile-city"
                                name="city"
                                isValid={isFieldValid('city')}
                                errorMessage={getErrorMessage('city')}
                                className={styles.control}
                                shouldBeTypo={shouldBeTypo}
                                inputTitle={t('forms:CITY.LABEL')}
                                inputText={values.city || ''}
                            />
                            {renderCountrySelect()}
                        </div>
                    </Grid>
                    {maybeRenderSaveButtons()}
                </form>
            </div>
        );
    };
    return renderForm();
};

const mapState = (state: IRootState): IStateProps => {
    const getEditableUserData = makeGetUserEditableFields();
    return {
        existingValues: getEditableUserData(state)!,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    submit: async (data) => dispatch.account.updateUserData(data),
    showNotification: dispatch.notifications.showNotification,
    clearNotifications: dispatch.notifications.clearNotifications,
});

export const ConnectedUserProfile = compose<IProps, IOuterProps>(
    connect(mapState, mapDispatch),
    withFormik<IOuterProps & IStateProps & IActions, IUserProfileFormValues>({
        enableReinitialize: true,
        mapPropsToValues: (props) => props.existingValues,
        handleSubmit: (values, formikBag) => {
            formikBag.props.clearNotifications();

            const t = getGlobalT();

            formikBag.props.submit(values).catch((err) => {
                formikBag.props.showNotification({
                    type: 'error',
                    content: t(
                        'account:USER_PROFILE.MESSAGES.UPDATE_ERROR_UNKNOWN',
                    ),
                });
            });
        },
        validationSchema: () => getUserProfileValidationSchema(),
    }),
    withFormUtils,
)(UserProfile);
