/**
 * Despite its popularity, Moment isn't a good choice,
 * however date-fns doesn't have relative time in v1.x
 * I recommend to switch to date-fns after v2 leaves alpha
 */
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';

export const DATE_FORMAT = 'D MMM YYYY H:mm:ss';

export const setLocale = (userLanguage: string) => {
    moment.locale(userLanguage.toLowerCase());
};

export const formatTimeAgo = (date: string) => {
    return moment(date).fromNow();
};

export const formatDate = (date: string) => {
    return moment(date).format(DATE_FORMAT);
};
