import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Typography } from '../../common/typography/typography.component';
import { INotification } from '../../notifications/interfaces';
import { Dispatch, IRootState } from '../../store/store';
import { IMailContact, IPhoneContact } from '../../trackers/interfaces-api';
import { makeGetSubscriptionIdFromTracker } from '../../trackers/selectors/get-subscription-id-from-tracker';
import { makeGetTrackerModel } from '../../trackers/selectors/get-tracker-model';
import { SubscriptionOptionType } from '../../user-subscriptions/interfaces-api';
import { makeGetSubscriptionOptions } from '../../user-subscriptions/selectors/user-subscriptions.selectors';
import { ControlledNotificationContactsMails } from './mails-form.component';
import styles from './notification-contacts.module.scss';
import { ControlledSmsPhonesForm } from './sms-phones-form.component';

interface IStateProps {
    existingMails: string[];
    existingSmsNumbers: string[];
    hasSmsOption: boolean;
    subscriptionId: number | null;
}

interface IOuterProps {
    trackerID: number;
}

interface IActions {
    clearNotifications(): unknown;
    showNotification(notification: INotification): Promise<unknown>;
    updateTrackerContacts(
        trackerId: number,
        mails: string[],
        numbers: string[],
    ): Promise<unknown>;
}

interface IProps extends IOuterProps, IStateProps, IActions { }

export const NotificationContacts = ({
    trackerID,
    existingMails,
    existingSmsNumbers,
    hasSmsOption,
    subscriptionId,
    updateTrackerContacts,
    clearNotifications,
    showNotification,
}: IProps) => {
    const { t } = useTranslation('trackerSettings');

    const handleSuccess = () => {
        showNotification({
            type: 'success',
            content: t('CONTACTS.MESSAGES.UPDATE_SUCCESS'),
        });
    };

    const handleMailSuccess = () => {
        if (document.getElementsByClassName(styles.mailActive).length === 0) {
            document
                .getElementsByClassName(styles.mailSuccessMessage)[0]
                .classList.add(styles.mailActive);
            setTimeout(() => {
                document
                    .getElementsByClassName(styles.mailActive)[0]
                    .classList.remove(styles.mailActive);
            }, 2000);
        }
    };
    const handlePhoneSuccess = () => {
        if (document.getElementsByClassName(styles.mailActive).length === 0) {
            document
                .getElementsByClassName(styles.phoneSuccessMessage)[0]
                .classList.add(styles.phoneActive);
            setTimeout(() => {
                document
                    .getElementsByClassName(styles.phoneActive)[0]
                    .classList.remove(styles.phoneActive);
            }, 2000);
        }
    };

    const handleErrors = () => {
        showNotification({
            type: 'error',
            content: t('CONTACTS.MESSAGES.UPDATE_ERROR_UNKNOWN'),
        });
    };

    const onNewPhoneSaved = (phones: string[]) => {
        clearNotifications();
        updateTrackerContacts(trackerID, existingMails, phones)
            .then(handlePhoneSuccess)
            .catch(handleErrors);
    };

    const onNewMailSaved = (mails: string[]) => {
        clearNotifications();
        updateTrackerContacts(trackerID, mails, existingSmsNumbers)
            .then(handleMailSuccess)
            .catch(handleErrors);
    };
    const onEmailDelete = (mail: string) => {
        clearNotifications();
        updateTrackerContacts(
            trackerID,
            existingMails.filter((m) => m !== mail),
            existingSmsNumbers,
        )
            .then(handleMailSuccess)
            .catch(handleErrors);
    };
    const onPhoneDelete = (phone: string) => {
        clearNotifications();
        updateTrackerContacts(
            trackerID,
            existingMails,
            existingSmsNumbers.filter((num) => num !== phone),
        )
            .then(handlePhoneSuccess)
            .catch(handleErrors);
    };

    return (
        <div>
            <div className={styles.section}>
                <Typography withMarginBottom>
                    {t('CONTACTS.EMAILS_LABEL')}
                </Typography>
                <Typography className={styles.mailSuccessMessage}>
                    {t('CONTACTS.MESSAGES.UPDATE_SUCCESS')}
                </Typography>
                <ControlledNotificationContactsMails
                    onDeleted={onEmailDelete}
                    onAdded={onNewMailSaved}
                    existingEmails={existingMails}
                />
            </div>
            <div>
                <Typography withMarginBottom>
                    {t('CONTACTS.NUMBERS_LABEL')}
                </Typography>
                <Typography className={styles.phoneSuccessMessage}>
                    {t('CONTACTS.MESSAGES.UPDATE_SUCCESS')}
                </Typography>
                <ControlledSmsPhonesForm
                    //////
                    activeSubscriptionId={subscriptionId}
                    onDeleted={onPhoneDelete}
                    onAdded={onNewPhoneSaved}
                    existingSmsPhones={existingSmsNumbers}
                    hasSmsOptionActivated={hasSmsOption}
                />
            </div>
        </div>
    );
};

const mapState = (state: IRootState, props: IOuterProps): IStateProps => {
    const getTrackerModel = makeGetTrackerModel(props.trackerID);
    const getSubscriptionId = makeGetSubscriptionIdFromTracker(props.trackerID);
    const activeSubscriptionId = getSubscriptionId(state);

    let hasSmsActivated = false;
    if (activeSubscriptionId) {
        const getOptionsDetails = makeGetSubscriptionOptions(
            activeSubscriptionId,
        );
        const optionsDetails = getOptionsDetails(state);

        if (optionsDetails) {
            hasSmsActivated =
                optionsDetails.findIndex(
                    (option) =>
                        option.code === SubscriptionOptionType.SMS &&
                        option.activated === true,
                ) !== -1;
        }
    }

    return {
        existingMails: getTrackerModel(state)!.mail_contacts.map(
            (c: IMailContact) => c.mail,
        ),
        existingSmsNumbers: getTrackerModel(state)!.sms_contacts.map(
            (c: IPhoneContact) => c.phone,
        ),
        hasSmsOption: hasSmsActivated,
        subscriptionId: activeSubscriptionId,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    clearNotifications: dispatch.notifications.clearNotifications,
    showNotification: async (n: INotification) =>
        dispatch.notifications.showNotification(n),
    updateTrackerContacts: async (trackerId, mails, numbers) =>
        dispatch.trackersSettings.updateTrackerContacts({
            trackerId,
            mails,
            numbers,
        }),
});

export const ConnectedNotificationContacts = compose<IProps, IOuterProps>(
    connect(mapState, mapDispatch),
)(NotificationContacts);
