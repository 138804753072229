import { apiService } from '../api/api.service';
import { IProcessPayment } from '../subscription-process/steps/hipay-step/interfaces';
import {
    ISubscriptionRedirection,
    SubscriptionOptionType,
} from './interfaces-api';

const getSubscriptionEndpoint = (subId: string | number) =>
    `/mysubscription/${subId}`;

export const fetchSubscriptionDetails = ({
    subscriptionId,
}: {
    subscriptionId: string | number;
}) => {
    return apiService
        .fetch(getSubscriptionEndpoint(subscriptionId))
        .then((data) => data.json());
};

export const getIsEligibleToChangeMean = () =>
    apiService
        .fetch(
            '/mypaymentmean/change-mean/eligible',
        )
        .then((resp) => resp.json());

export const postChangeMean = () =>
    apiService
        .fetch('/mypaymentmean/change-mean', {
            method: 'POST',
        })
        .then((resp) => {
            if (resp.status === 200) {
                return resp.json();
            }
            throw resp;
        });

export const updateSubscriptionOptions = (
    subscriptionId: string | number,
    options: SubscriptionOptionType[],
) => {
    return apiService
        .fetch(getSubscriptionEndpoint(subscriptionId), {
            method: 'PUT',
            body: JSON.stringify({ options }),
        })
        .then((resp) => {
            if (resp.status !== 200) {
                return Promise.reject(resp);
            }
            return resp.json();
        });
};

export const cancelSubscription = (
    subscriptionId: string | number,
    cancelReason: string = '',
    cancelExplanation: string = '',
) =>
    apiService.fetch(getSubscriptionEndpoint(subscriptionId) + '/cancel', {
        method: 'POST',
        body: JSON.stringify({
            cancel_reason: cancelReason,
            cancel_explanation: cancelExplanation,
        }),
    });

export const uncancelSubscription = (subscriptionId: string | number) =>
    apiService.fetch(getSubscriptionEndpoint(subscriptionId) + '/cancel', {
        method: 'DELETE',
    });

/**
 * Since server doesn't handle counting of passed time,
 * this has to be saved locally
 */
export const saveSubscriptionAlertTimestamp = (subscriptionId: number) => {
    localStorage.setItem(
        `subscription-alert-timestamp-${subscriptionId}`,
        `${Date.now()}`,
    );
};

export const getSubscriptionAlertTimestamp = (
    subscriptionId: number,
): number | null => {
    const timestamp = localStorage.getItem(
        `subscription-alert-timestamp-${subscriptionId}`,
    );

    if (!timestamp) {
        return null;
    }

    return parseInt(timestamp, 10);
};

export const getSubscriptionAlertPassedDays = (
    subscriptionId: number,
): number | null => {
    const savedAt = getSubscriptionAlertTimestamp(subscriptionId);

    if (!savedAt) {
        return null;
    }

    const diffInMs = Date.now() - savedAt;

    return diffInMs / (1000 * 60 * 60 * 24);
};


export const changeCreditCard = (paymentPayload: IProcessPayment) => {
    const url = `/mypaymentmean/change-card`;

    return apiService
        .fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                java_enabled: paymentPayload.browserInfo.java_enabled,
                javascript_enabled:
                    paymentPayload.browserInfo.javascript_enabled,
                language: paymentPayload.browserInfo.language,
                color_depth: paymentPayload.browserInfo.color_depth,
                screen_height: paymentPayload.browserInfo.screen_height,
                screen_width: paymentPayload.browserInfo.screen_width,
                timezone: paymentPayload.browserInfo.timezone,
                http_user_agent: paymentPayload.browserInfo.http_user_agent,
                ipaddr: paymentPayload.browserInfo.ipaddr,
                http_accept: paymentPayload.browserInfo.http_accept,
                payment_product: paymentPayload.pp,
                card_token: paymentPayload.cardToken,
                card_expiry: paymentPayload.cardExpiry,
                card_pan: paymentPayload.card_pan,
            }),
        })
        .then((resp) => {
            if (resp.status === 200) {
                return resp.json();
            }

            throw resp;
        });
};

export const getCardExpiresSoon = (subscriptionId: number): Promise<boolean> => {
    return apiService
        .fetch('/mysubscription/'
            + subscriptionId.toString()
            + '/card-expires-soon'
        ).then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                if (resp.status === 204) {
                    return false;
                }
                return resp.json();
            }
            throw resp;
        });
};

export const changeBankAccount = (
): Promise<ISubscriptionRedirection> => {
    return apiService
        .fetch('/mypaymentmean/bank_account', {
            method: 'POST',
        })
        .then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                return resp.json();
            }

            throw resp;
        });
};
