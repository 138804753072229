// TODO Move to some routing

export interface IRedirectParams {
    redirect?: boolean;
    error?: boolean;
    success?: boolean;
    onboarding?: boolean;
}

/**
 * This is redirected from backend
 */

export const PaymentRedirectionUrls = {
    BIOGARAN_SUBSCRIPTION_SUCCESS: '?redirect=true&success=true&biogaran=true',
    BIOGARAN_SUBSCRIPTION_ERROR: '?redirect=true&error=true&biogaran=true',
    SUBSCRIPTION_SUCCESS: '?redirect=true&success=true',
    SUBSCRIPTION_ERROR: '?redirect=true&error=true',
    ONBOARDING_SUCCESS: '?redirect=true&onboarding=true',
    CHANGE_CARD_SUCCESS: '?redirect=true&success=true&change_card=true',
    CHANGE_CARD_ERROR: '?redirect=true&success=false&change_card=true',
    CHANGE_BANK_ACCOUNT_SUCCESS:
        '?redirect=true&success=true&change_bank_account=true',
    CHANGE_BANK_ACCOUNT_ERROR:
        '?redirect=true&success=false&change_bank_account=true',
};
