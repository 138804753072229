import cx from 'classnames';
import React, { HTMLAttributes, ReactNode } from 'react';

import styles from './icon-badge.module.scss';

export interface IIconBadgeProps extends HTMLAttributes<HTMLDivElement> {
    icon: ReactNode;
    color: string;
    cornerIndicator?: 'green' | 'red';
}

export const IconBadge = ({
    icon,
    cornerIndicator,
    className,
    color,
    ...props
}: IIconBadgeProps) => {
    const maybeRenderCornerIndicator = () => {
        if (cornerIndicator) {
            return (
                <div
                    className={cx(
                        styles.cornerIndicator,
                        styles[cornerIndicator],
                    )}
                />
            );
        }
    };

    return (
        <div
            style={{ backgroundColor: color }}
            className={cx(styles.iconBadge, className)}
            {...props}
        >
            <div className={styles.iconContainer}>{icon}</div>
            {maybeRenderCornerIndicator()}
        </div>
    );
};
