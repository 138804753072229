import React from 'react';
import { LeafletContext } from 'react-leaflet';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { MapLayerType } from '../../map/interfaces';
import { IRootState } from '../../store/store';
import { ITrackerOnMap } from '../../trackers/interfaces';
import { ITrackerEvent } from '../../trackers/interfaces-api';
import { makeGetSelectedTrackerToMapModel } from '../../trackers/selectors/get-selected-tracker-model';
import { LeafletActivityPath } from './components/activity-path-leaflet.component';
import { getVectors } from './utils';

interface IOuterProps {
    events: ITrackerEvent[];
    isHeatMapActivated: boolean;
    leaflet: LeafletContext | undefined;
    displayEventTooltip(
        event: ITrackerEvent,
        tracker: ITrackerOnMap | null,
    ): JSX.Element | undefined;
}

interface IStateProps {
    activeTracker: ITrackerOnMap | null;
    selectedLayer: MapLayerType;
}

interface IProps extends IOuterProps, IStateProps {}

export const ActivityPath = ({
    activeTracker,
    events,
    isHeatMapActivated,
    leaflet,
    selectedLayer,
    displayEventTooltip,
}: IProps) => {
    if (events.length === 0) {
        return null;
    }

    const vectors = getVectors(events);

    const lastEvent = events[events.length - 1];

    // Only render this component when using Leaflet.
    // google-map-react is using a particular way to handle custom components which oblige to display activity path
    // in an other way.
    const renderActivityPath = () => {
        if (
            !isHeatMapActivated &&
            (selectedLayer === MapLayerType.GRAY ||
                selectedLayer === MapLayerType.SATELLITE ||
                selectedLayer === MapLayerType.STREET)
        ) {
            return (
                <LeafletActivityPath
                    events={events}
                    lastEvent={lastEvent}
                    leaflet={leaflet}
                    activeTracker={activeTracker}
                    vectors={vectors}
                    displayEventTooltip={displayEventTooltip}
                />
            );
        }
        return null;
    };

    return renderActivityPath();
};

const makeMapState = (state: IRootState): IStateProps => {
    const getActiveTracker = makeGetSelectedTrackerToMapModel();
    return {
        activeTracker: getActiveTracker(state),
        selectedLayer: state.map.selectedLayer,
    };
};

export const ConnectedActivityPath = compose<IProps, IOuterProps>(
    connect(makeMapState, null),
)(ActivityPath);
