import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import defaultImage from '../../../images/tracker.png';

import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { Typography } from '../../common/typography/typography.component';

import styles from './tracker-activating-modal.module.scss';

export interface ITrackerActivatingModalProps
    extends HTMLAttributes<HTMLDivElement> {
    trackerImage: string | null;
    onClose: () => any;
}

export const TrackerActivatingModal = ({
    trackerImage,
    onClose,
    ...props
}: ITrackerActivatingModalProps) => {
    const { t } = useTranslation('onboarding');

    const renderTrackerImageSource = () => {
        if (trackerImage) {
            return trackerImage;
        }
        return defaultImage;
    };

    return (
        <div {...props}>
            <Headline level={3} className={styles.headline}>
                {t('MODAL.HEADLINE')}
            </Headline>
            <Typography center size14 bold>
                {t('MODAL.BUTTON_HINT')}
            </Typography>
            <img
                alt={t('MODAL.IMAGE_ALT')}
                src={renderTrackerImageSource()}
                className={styles.image}
            />
            <Typography center>{t('MODAL.MAP_HINT')}</Typography>
            <Button className={styles.button} onClick={onClose}>
                {t('commonActions:OK')}
            </Button>
        </div>
    );
};
