import cx from 'classnames';
import React, { HTMLAttributes, ReactNode } from 'react';

import styles from './param-block.module.scss';

export interface IParamBlockProps extends HTMLAttributes<HTMLDivElement> {
    label: string | null | ReactNode;
    value: ReactNode;
}

export const ParamBlock = ({ value, label, className }: IParamBlockProps) => {
    return (
        <div className={cx(styles.paramBlock, className)}>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>{value}</div>
        </div>
    );
};
