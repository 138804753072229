import React from 'react';

import { Grid } from '../../layout/grid/grid.component';
import { BackButton } from '../button/back-button.component';
import { NextButton } from '../button/next-button.component';
import { Headline } from '../headline/headline.component';
import { Typography } from '../typography/typography.component';
import styles from './cgu.module.scss';
import { cguContent } from './cguContent';

interface IProps {
    displayCGU: (value: boolean) => any;
    onValidate?: any | null;
    setDisableValidateButton?: any | null;
    options?: any | null;
    content?: string;
}

export const CGU = ({
    displayCGU,
    onValidate,
    setDisableValidateButton,
    options,
    content,
}: IProps) => {
    window.scrollTo(0, 0);
    let buttonComponent;
    const validateCGU = (code: any) => {
        if (code) {
            onValidate(code);
            displayCGU(false);
        } else {
            displayCGU(false);
        }
    };
    const setValidateCGU = () => {
        displayCGU(false);
        if (setDisableValidateButton) {
            setDisableValidateButton(true);
        }
    };
    if (onValidate) {
        buttonComponent = (
            <Grid className={styles.buttons}>
                <NextButton
                    type="submit"
                    className={styles.marginBottom}
                    onClick={() => validateCGU(options[0].code)}
                >
                    Ajouter au panier
                </NextButton>
                <BackButton onClick={() => displayCGU(false)} />
            </Grid>
        );
    } else {
        buttonComponent = (
            <Grid className={styles.buttons}>
                <NextButton
                    type="submit"
                    className={styles.marginBottom}
                    onClick={() => {
                        setValidateCGU();
                    }}
                >
                    Lu et accepté
                </NextButton>
                <BackButton onClick={() => displayCGU(false)} />
            </Grid>
        );
    }
    return (
        <div className={styles.container}>
            <Headline className={styles.text}>
                Conditions de souscription à l’option multi-risque
            </Headline>
            <Typography className={styles.text}>{cguContent[0]}</Typography>
            {buttonComponent}
        </div>
    );
};
