import { get } from 'lodash';
import { createSelector } from 'reselect';

import { IRootState } from '../store/store';
import { IEditableUserData, IUserEmailPrefData } from './interfaces';
import { mapUserToEditableFields } from './mappers/map-user-to-editable-fields';
import { mapUserPrefToEmailPrefFields } from './mappers/map-user-to-preference-fields';
import { IUserStore } from './user.store';

const getUserState = (state: IRootState) => state.user;
const getUserPrefState = (state: IRootState) => state.userPref;

export const createUserNameSelector = () =>
    createSelector(
        getUserState,
        (state) => {
            if (state.userData) {
                return `${state.userData.firstname} ${state.userData.lastname}`;
            } else {
                return null;
            }
        },
    );

export const createUserCountrySelector = () =>
    createSelector(
        getUserState,
        (state: IUserStore): string => {
            if (!state.userData) {
                return '';
            }
            return state.userData.country;
        },
    );

export const createGetUserLanguage = () =>
    createSelector(
        getUserState,
        (user) => get(user, 'userData.language', 'fr'),
    );

export const makeGetUserEditableFields = () =>
    createSelector(
        getUserState,
        (user): IEditableUserData | null => {
            if (!user) {
                return null;
            }

            return mapUserToEditableFields(user.userData!);
        },
    );

export const makeGetUserPreferencesFields = () =>
    createSelector(
        getUserPrefState,
        (userpref): IUserEmailPrefData => {
            if (!userpref || !userpref.emailPrefData) {
                return {
                    'current_lang': 'FR',
                    'list_company_news': false,
                    'list_surveys_and_tests': false,
                    'list_new_features': false,
                    'list_tutorials': false,
                    'list_offers': false,
                    'list_service_status': false,
                };
            }

            return mapUserPrefToEmailPrefFields(userpref.emailPrefData!);
        },
    );
