import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { compose } from 'recompose';

import { config as appConfig } from '../../../config';
import { formatAmountWithCurrency } from '../../../utils/normalize-price-amount';
import { DATE_FORMAT } from '../../../utils/time-formatters';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../utils/with-selected-tracker';
import { CancelSubscriptionBlock } from '../../cancel-subscription/components/cancel-subscription-block/cancel-subscription-block.component';
import { ActiveIndicator } from '../../common/active-indicator/active-indicator.component';
import { BackLink } from '../../common/back-link/back-link.component';
import { Button } from '../../common/button/button.component';
import { CGU } from '../../common/cgu/cgu-multi-risk-biogaran.component';
import { CheckSection } from '../../common/check-section/check-section.component';
import { Headline } from '../../common/headline/headline.component';
import { Typography } from '../../common/typography/typography.component';
import { ReactComponent as PowerIcon } from '../../icons/power.svg';
import { ReactComponent as EditIcon } from '../../icons/subscrption.svg';
import { PageContainer } from '../../layout/page-container/page-container.component';
import { SimpleHeaderWithLogout } from '../../layout/simple-header/simple-header.component';
import { withActiveTrackerResolving } from '../../map/resolvers/active-tracker-resolver';
import { INotification } from '../../notifications/interfaces';
import { trackersRoute } from '../../routing/routes';
import { Dispatch, IRootState } from '../../store/store';
import {
    IOptionOffer,
    ISubscriptionOffer,
    SubscriptionVariantCode,
} from '../../subscription-offers/interfaces';
import {
    makeGetSubscriptionOfferOptions,
    makeGetSubscriptionsOffersVariants,
} from '../../subscription-offers/selectors/subscription-offers-selectors';
import { OptionContent } from '../../subscription-process/components/option-content/option-content.component';
import { SubscriptionPeriodOptionBiogaran } from '../../subscription-process/components/subscription-period-option/subscription-period-biogaran-manage-component';
import { PaymentCode } from '../../subscription-process/interfaces';
import { checkIfUrlIdExistOnTrackers } from '../../subscription-process/selectors/subscription-process-selectors';
import { Theme } from '../../trackers/interfaces';
import {
    ISubscriptionDetails,
    ISubscriptionOptionData,
} from '../../user-subscriptions/interfaces';
import {
    IRawUserSubscription,
    SubscriptionOptionType,
} from '../../user-subscriptions/interfaces-api';
import {
    makeGetSubscriptionDetailsFromTracker,
    makeGetSubscriptionOptions,
} from '../../user-subscriptions/selectors/user-subscriptions.selectors';
import { uncancelSubscription } from '../../user-subscriptions/subscription.service';
import { IRawUserData } from '../../user/interfaces';
import {
    IAccountOptionOffers,
    IAccountOptions,
} from '../../user/interfaces-api';
import { makeGetAccountOptions } from '../../user/selectors/account-option-selector';
import { putAccountOption } from '../../user/user.service';
import { INewOption } from '../manage-subscription.store';
import {
    getCanChangePlan,
    getNewOptions,
    getNewSubscriptionOffer,
} from '../selectors/manage-subscription-selector';
import styles from './manage-subscription-page.module.scss';

interface IStateProps {
    activeTracker: number | null;
    urlIdExistOnTrackers: boolean;
    trackerId: number | null;
    currentSubscription: ISubscriptionDetails | null;
    activeTheme: Theme | null;
    offers: ISubscriptionOffer[];
    user: IRawUserData | null;
    options: IOptionOffer[];
    activeOptions: ISubscriptionOptionData[];
    newSubscriptionOffer: ISubscriptionOffer | null;
    newOptions: INewOption[];
    canChangePlan: boolean;
    oneYearPrice: string | null;
    currentUserSite: string | undefined;
    isMobileRedirect: boolean;
}

interface IActions {
    setUserData: (user: IRawUserData) => void;
    getOfferts: (site: string) => Promise<any>;
    selectTrackerInSubscriptionProcess: (trackerId: number) => Promise<any>;
    setSelectedTracker: (id: number) => unknown;
    doChangePlanReactivation: () => Promise<any>;
    changeSubscriptionOffer: (newOffer: ISubscriptionOffer | null) => any;
    doChangePlan: (
        currentSubscriptionId: number,
        currentSubscriptionCode: string,
        newOffer: ISubscriptionOffer | null,
        newOptions: INewOption[],
    ) => Promise<any>;
    doSetPaymentMean: (paymentMean: PaymentCode) => Promise<any>;
    doSetTrackerId: (trackerId: number) => Promise<any>;
    doSetOptions: (options: SubscriptionOptionType[]) => Promise<any>;
    doSetSubscriptionCode: (
        subscriptionCode: SubscriptionVariantCode,
    ) => Promise<any>;
    fetchCanChangePlan: (subscriptionId: number) => Promise<any>;
    fetchSubscriptionDetails: (subscriptionId: number) => Promise<any>;
    resetNewOptions: () => any;
    selectTheme: (id: number | null) => any;
    showNotification: (notification: INotification) => unknown;
    toggleOption: (
        optionCodeToToggle: SubscriptionOptionType,
        activeOptions: ISubscriptionOptionData[],
    ) => any;
}

export interface IManageSubscriptionPage
    extends IStateProps,
        IActions,
        IWithSelectedTracker {}

export const ManageSubscriptionPage = ({
    currentUserSite,
    activeTracker,
    isMobileRedirect,
    trackerId,
    doSetTrackerId,
    urlIdExistOnTrackers,
    doSetPaymentMean,
    doSetOptions,
    doSetSubscriptionCode,
    doChangePlanReactivation,
    getOfferts,
    activeOptions,
    currentSubscription,
    activeTheme,
    canChangePlan,
    changeSubscriptionOffer,
    doChangePlan,
    fetchCanChangePlan,
    fetchSubscriptionDetails,
    setSelectedTracker,
    newOptions,
    newSubscriptionOffer,
    offers,
    options,
    resetNewOptions,
    selectTheme,
    showNotification,
    toggleOption,
    user,
    setUserData,
    oneYearPrice,
}: IManageSubscriptionPage) => {
    useEffect(() => {
        if (currentSubscription) {
            fetchCanChangePlan(currentSubscription.id);
        }
    }, [fetchCanChangePlan]);
    const [displayCGU, setDisplayCGU] = useState(false);
    const [premiumPack, setPremiumPack] = useState<boolean>();

    const match = useLocation();
    const history = useNavigate();
    const { t } = useTranslation('manageSubscription');
    const subscriptionT = useTranslation('subscriptions').t;
    const commonT = useTranslation('commonActions').t;
    const periodT = useTranslation('periods').t;
    const subscriptionAlertT = useTranslation('subscriptionAlert').t;
    const manageSubscriptionT = useTranslation('manageSubscription').t;
    const subscriptionSelectionT = useTranslation('subscriptionSelection').t;

    useEffect(() => {
        if (!activeTracker && trackerId) {
            setSelectedTracker(trackerId!);
        }
        if (!activeTheme) {
            selectTheme(trackerId);
        }
        selectTheme(trackerId);
        if (!urlIdExistOnTrackers) {
            history('/');
        }
    }, []);
    useEffect(() => {
        // theme logic using store
        const getOffertsCallback = async (config: string) => {
            await getOfferts(config);
        };
        if (!activeTheme) {
            return;
        }
        // activeTheme logic using store
        if (activeTheme === Theme.BIOGARAN) {
            document.documentElement.setAttribute('data-theme', 'biogaran');
            getOfferts(appConfig.BIOGGY_API_CALL_PARAM);
            return;
        }

        if (activeTheme === Theme.WEENECT) {
            if (currentUserSite === appConfig.WEENECT_USA_SITE) {
                getOfferts(appConfig.WEENECT_USA_API_CALL_PARAM);
                return;
            }
            getOfferts(appConfig.WEENECT_API_CALL_PARAM);
        }
    }, [activeTheme]);
    const getSubscriptionLink = () =>
        match.pathname.replace('manage', 'subscription');

    /* Redirect to subscription page when current subscription is not active */
    if (!currentSubscription) {
        return null;
        getSubscriptionLink();
    }

    /**
     * By default, the plan selected is the one of the active subscription.
     * If customer changes the plan, the plan selected will be the one chosen.
     *
     * @param currentSubscriptionCode The code of the active subscription
     * @param offerCode The code of the current row between each plan
     */
    const isOfferChecked = (
        currentSubscriptionCode: string,
        offerCode: string,
    ) => {
        if (newSubscriptionOffer) {
            return newSubscriptionOffer.code === offerCode;
        }
        return currentSubscriptionCode === offerCode;
    };

    /**
     * By default, options selected are the one of the active subscription
     * If customer changes the options, options selected will be the ones chosen.
     *
     * @param optionCode The code of the current option row
     */
    const isOptionChecked = (optionCode: string) => {
        const matchedNewOption = newOptions.find(
            (newOption) => newOption.code === optionCode,
        );
        if (matchedNewOption) {
            return matchedNewOption.activated;
        }
        if (activeOptions) {
            return (
                activeOptions.findIndex(
                    (activeOption) => activeOption.code === optionCode,
                ) !== -1
            );
        }
        return false;
    };
    const onChangePaymentMeanClicked = () => {
        history(
            match.pathname.replace(
                '/manage',
                '/subscription/changePaymentMean',
            ),
        );
    };

    /**
     * If subscription is expired but Customer needs to change plan
     * We redirect them to subscription tunnel on 2nd step (choose a plan)
     * /!\ in this process, only credit card payment should be available
     */
    const onNewSubClicked = () => {
        history(`/add/subscription/${trackerId}`);
    };

    const reactivateNewPlan = () => {
        if (newSubscriptionOffer) {
            doSetSubscriptionCode(newSubscriptionOffer.code);
            doSetTrackerId(trackerId as number);
            doSetPaymentMean(currentSubscription!.payment_mean as PaymentCode);
            const selectedOptions: SubscriptionOptionType[] = [];
            newOptions.forEach((option) => {
                selectedOptions.push(option.code);
            });
            doSetOptions(selectedOptions);
        }
        return doChangePlanReactivation();
    };

    /**
     * Determines the 'disabled' state of the change-plan submit button.
     */
    const shouldDisableValidate = (): boolean => {
        if (newOptions.length === 0 && newSubscriptionOffer === null) {
            return true;
        }
        if (newOptions.length === 0 && newSubscriptionOffer) {
            return newSubscriptionOffer.code === currentSubscription.offer_code;
        }
        return false;
    };

    const onUncancelSubscriptionSuccess = () => {
        showNotification({
            content: t('MESSAGES.UPDATE_SUCCESS'),
            type: 'success',
        });
        fetchSubscriptionDetails(currentSubscription.id);
    };

    const renderOffer = (offer: ISubscriptionOffer) => {
        let bestOffer = null;

        if (offer.isBestChoice) {
            bestOffer = subscriptionSelectionT('BEST_OFFER');
        }
        return (
            <SubscriptionPeriodOptionBiogaran
                className={styles['check-section']}
                key={offer.id}
                period={offer.formattedPeriod}
                priceCost={offer.formattedPrice}
                pricePeriod={offer.formattedPeriod}
                amountMonth={offer.amountMonth}
                subtext={bestOffer}
                promoText={offer.saving}
                component="manage"
                onChange={() => changeSubscriptionOffer(offer)}
                value={offer.code}
                info={offer.info}
                checked={isOfferChecked(
                    currentSubscription.offer_code,
                    offer.code,
                )}
            />
        );
    };

    const renderOption = (option: IOptionOffer) => {
        return (
            <CheckSection
                className={styles['check-section']}
                onChange={() => toggleOption(option.code, activeOptions)}
                key={option.name}
                type="checkbox"
                checked={isOptionChecked(option.code)}
            >
                <OptionContent
                    component="manage"
                    features={option.features}
                    name={option.name}
                    price={option.price}
                    period={periodT('BASE.MONTH')}
                    subText={option.explainer}
                />
            </CheckSection>
        );
    };

    /**
     * This button allow to 'uncancel' a subscription which is still ongoing (not expired yet)
     */
    const maybeRenderReinstateSubscription = () => {
        if (
            currentSubscription.willExpireIn &&
            currentSubscription.willExpireIn >= 0
        ) {
            return (
                <Button
                    primary
                    iconLeft={<PowerIcon />}
                    onClick={() =>
                        uncancelSubscription(currentSubscription.id).then(
                            onUncancelSubscriptionSuccess,
                        )
                    }
                >
                    {subscriptionAlertT('ACTIVATE_AUTO_RENEWAL')}
                </Button>
            );
        }
    };

    /**
     * This button allow to re-subscribe to a plan
     * (redirect to choose-variant step of subscription tunnel)
     */
    const maybeRenderNewSubButton = () => {
        if (
            currentSubscription.willExpireIn &&
            currentSubscription.willExpireIn < 0
        ) {
            return (
                <Button
                    primary
                    iconLeft={<EditIcon />}
                    onClick={onNewSubClicked}
                >
                    {manageSubscriptionT('RENEW_SUBSCRIPTION')}
                </Button>
            );
        }
    };

    const renderSubscriptionDates = () => {
        if (currentSubscription.isCancelled) {
            return (
                <div className={styles['sub-dates']}>
                    <Typography size14>
                        {subscriptionT('DETAILS.CANCELED.WILL_EXPIRE_MAIN', {
                            date: currentSubscription.expiryDate,
                        })}
                    </Typography>

                    {maybeRenderReinstateSubscription()}
                    {maybeRenderNewSubButton()}
                </div>
            );
        } else if (!currentSubscription.isCancelScheduled) {
            return (
                <Typography size14>
                    {subscriptionT('DETAILS.ACTIVE_CASE.RENEWAL_INFO', {
                        date: currentSubscription.renewalDate,
                        price:
                            oneYearPrice || currentSubscription.priceFormatted,
                    })}
                </Typography>
            );
        }
    };

    const maybeRenderCommitmentText = () => {
        if (
            currentSubscription.isUnderCommitmentPeriod &&
            !currentSubscription.isCancelScheduled
        ) {
            const possibleResignDate = moment(
                currentSubscription.createdAt,
                DATE_FORMAT,
            )
                .add(90, 'days')
                .format(DATE_FORMAT)
                .toLocaleString();

            return (
                <Typography className={styles['not-cancelable']}>
                    {manageSubscriptionT('CANT_RESIGN_WHILE_COMMITED', {
                        date: possibleResignDate,
                    })}
                </Typography>
            );
        }
    };

    const maybeRenderCommitmentTextResignationPlanned = () => {
        if (
            currentSubscription.isUnderCommitmentPeriod &&
            currentSubscription.isCancelScheduled
        ) {
            const possibleResignDate = moment(
                currentSubscription.createdAt,
                DATE_FORMAT,
            )
                .add(90, 'days')
                .format(DATE_FORMAT)
                .toLocaleString();

            return (
                <div>
                    <Typography className={styles['not-cancelable']}>
                        {manageSubscriptionT('CANT_RESIGN_WHILE_COMMITED', {
                            date: possibleResignDate,
                        })}
                    </Typography>
                    {maybeRenderReinstateSubscription()}
                </div>
            );
        }
    };
    const maybeRenderCancelScheduledText = () => {
        if (
            currentSubscription.isUnderCommitmentPeriod &&
            currentSubscription.isCancelScheduled
        ) {
            return (
                <Typography className={styles.resiliationAsked}>
                    {subscriptionT('DETAILS.RESILLIATION_ASKED')}
                </Typography>
            );
        }
    };

    /**
     * Don't render offers if subscription is canceled in order to
     * avoid customer confusion.
     */
    const maybeRenderOffer = () => {
        if (!activeTheme) {
            return null;
        }
        if (currentSubscription.isActive) {
            return (
                <div className={styles['middle-row']}>
                    <Headline
                        className={
                            activeTheme === Theme.BIOGARAN
                                ? styles['headline-1']
                                : styles.headline
                        }
                        level={2}
                    >
                        {subscriptionT('CHANGE_PLAN.HEADLINE')}
                    </Headline>

                    <Typography size14>
                        {subscriptionT('CHANGE_PLAN.EXPLANATION')}
                    </Typography>

                    {offers.map(renderOffer)}
                </div>
            );
        }
    };

    const maybeRenderChangePlan = () => {
        if (
            (canChangePlan === true &&
                currentSubscription.willExpireIn &&
                currentSubscription.willExpireIn >= 0) ||
            activeTheme === Theme.BIOGARAN
        ) {
            return (
                <>
                    <React.Fragment>
                        {maybeRenderOffer()}

                        <div className={styles['middle-row']}>
                            <Headline className={styles.headline} level={2}>
                                {subscriptionT('CHANGE_PLAN.ADD_OPTIONS')}
                            </Headline>
                            {options.map(renderOption)}
                        </div>

                        <div className={styles['bottom-row']}>
                            <Button
                                primary
                                large
                                onClick={() =>
                                    doChangePlan(
                                        currentSubscription.id,
                                        currentSubscription.offer_code,
                                        newSubscriptionOffer,
                                        newOptions,
                                    )
                                        .then(() => {
                                            changeSubscriptionOffer(
                                                newSubscriptionOffer,
                                            );
                                            fetchSubscriptionDetails(
                                                currentSubscription.id,
                                            );
                                            resetNewOptions();
                                            showNotification({
                                                content: t(
                                                    'MESSAGES.UPDATE_SUCCESS',
                                                ),
                                                type: 'success',
                                            });
                                        })
                                        .catch(() => {
                                            showNotification({
                                                content: commonT(
                                                    'MESSAGES.UPDATE_ERROR_UNKNOWN',
                                                ),
                                                type: 'error',
                                            });
                                        })
                                }
                                disabled={shouldDisableValidate()}
                                className={styles.validate}
                            >
                                {commonT('VALIDATE')}
                            </Button>
                        </div>
                    </React.Fragment>
                </>
            );
        }
    };

    const maybeRenderChangePlanWhileExpired = () => {
        if (
            canChangePlan === true &&
            currentSubscription.willExpireIn &&
            currentSubscription.willExpireIn < 0
        ) {
            return (
                <React.Fragment>
                    {maybeRenderOffer()}

                    <div className={styles['middle-row']}>
                        <Headline className={styles.headline} level={2}>
                            {subscriptionT('CHANGE_PLAN.ADD_OPTIONS')}
                        </Headline>
                        {options.map(renderOption)}
                    </div>

                    <div className={styles['bottom-row']}>
                        <Button
                            primary
                            large
                            onClick={() => {
                                if (premiumPack && user) {
                                    putAccountOption({
                                        account_options: [
                                            SubscriptionOptionType.PREMIUMPACK,
                                        ],
                                        site: user.site,
                                    }).then((res: IAccountOptions[]) => {
                                        Object.assign(
                                            user.account_options,
                                            res,
                                        );
                                        return setUserData(user);
                                    });
                                } else if (!premiumPack && user) {
                                    putAccountOption({
                                        account_options: [],
                                        site: user.site,
                                    }).then((res: IAccountOptions[]) => {
                                        Object.assign(
                                            user.account_options,
                                            res,
                                        );
                                        return setUserData(user);
                                    });
                                }
                                reactivateNewPlan()
                                    .then(() => {
                                        changeSubscriptionOffer(
                                            newSubscriptionOffer,
                                        );
                                        fetchSubscriptionDetails(
                                            currentSubscription.id,
                                        );
                                        resetNewOptions();
                                        showNotification({
                                            content: t(
                                                'MESSAGES.UPDATE_SUCCESS',
                                            ),
                                            type: 'success',
                                        });
                                    })
                                    .catch(() => {
                                        showNotification({
                                            content: commonT(
                                                'MESSAGES.UPDATE_ERROR_UNKNOWN',
                                            ),
                                            type: 'error',
                                        });
                                    });
                            }}
                            disabled={shouldDisableValidate()}
                            className={styles.validate}
                        >
                            {commonT('VALIDATE')}
                        </Button>
                    </div>
                </React.Fragment>
            );
        }
    };
    return (
        <div>
            {
                isMobileRedirect ? null : (
                    <SimpleHeaderWithLogout
                        theme={activeTheme}
                        leftSlot={
                            <RouterLink to={trackersRoute + '/' + trackerId}>
                                <BackLink black collapseOnMobile>
                                    {t('RETURN_TO_TRACKERS')}
                                </BackLink>
                            </RouterLink>
                        }
                        className={styles.header}
                    />
                )
            }
            <PageContainer>
                {displayCGU && isMobileRedirect ? (
                    <CGU displayCGU={setDisplayCGU} content={'multiRisque'} />
                ) : (
                    <>
                        <Headline className={styles.headline} center>
                            {t('HEADLINE')}
                        </Headline>
                        <div className={styles['top-row']}>
                            <div className={styles['plan-name']}>
                                {currentSubscription.name}
                            </div>
                            <ActiveIndicator
                                className={styles['active-indicator']}
                                active={currentSubscription.isActive}
                            />
                        </div>
                        <div>
                            {renderSubscriptionDates()}
                            <CancelSubscriptionBlock
                                theme={activeTheme}
                                subscription={currentSubscription}
                                onCancelClicked={() =>
                                    history(
                                        match.pathname.replace(
                                            '/manage',
                                            '/cancel',
                                        ),
                                    )
                                }
                            />

                            {maybeRenderCancelScheduledText()}
                            {maybeRenderCommitmentTextResignationPlanned()}

                            {maybeRenderCommitmentText()}
                        </div>
                        {maybeRenderChangePlanWhileExpired()}
                        {maybeRenderChangePlan()}
                    </>
                )}
            </PageContainer>
        </div>
    );
};

const makeMapState = (
    state: IRootState,
    props: IWithSelectedTracker,
): IStateProps => {
    const getSubscriptionDetailsFromTracker =
        makeGetSubscriptionDetailsFromTracker(props.trackerId!);
    const subscriptionDetails = getSubscriptionDetailsFromTracker(
        state,
    ) as ISubscriptionDetails;

    const getRawSubscriptionDetails = makeGetSubscriptionDetailsFromTracker(
        props.trackerId!,
        false,
    );
    const rawSubscription = getRawSubscriptionDetails(
        state,
    ) as IRawUserSubscription;

    let oneYearPrice = null;

    const getSubscriptionOffersVariants = makeGetSubscriptionsOffersVariants();

    /* Early return allows to avoid crash if app didn't load subscriptionDetails */
    if (!subscriptionDetails) {
        return {
            activeTracker: state.userTrackers.activeTracker,
            activeTheme: state.userTrackers.theme,
            urlIdExistOnTrackers: checkIfUrlIdExistOnTrackers(state),
            trackerId: props.trackerId,
            currentSubscription: null,
            offers: getSubscriptionOffersVariants(state),
            options: [],
            activeOptions: [],
            newSubscriptionOffer: getNewSubscriptionOffer(state),
            newOptions: getNewOptions(state),
            canChangePlan: getCanChangePlan(state),
            oneYearPrice,
            currentUserSite: state.user.userData?.site,
            user: state.user.userData,
            isMobileRedirect: state.layout.isMobileRedirect,
        };
    }

    const getSelectedOptions = makeGetSubscriptionOptions(
        subscriptionDetails.id,
    );
    const currentSubscriptionOptions = getSelectedOptions(state);
    const getActiveOptions = () => {
        if (currentSubscriptionOptions) {
            const activeOptions = currentSubscriptionOptions.filter(
                (option) => option.activated === true,
            );
            return activeOptions;
        }
        return [];
    };

    const getOptions = () => {
        const doGet = makeGetSubscriptionOfferOptions(
            subscriptionDetails.offer_code,
            subscriptionDetails.isActive,
        );
        return doGet(state) as IOptionOffer[];
    };
    if (rawSubscription.period === 24) {
        const oneYearPlan = state.subscriptionOffers.variants.find(
            (offer) => offer.code === '12_month_1_tracker',
        );
        oneYearPrice = formatAmountWithCurrency(
            oneYearPlan?.amount || 0,
            oneYearPlan?.currency || 'EUR',
        );
    }

    return {
        activeTracker: state.userTrackers.activeTracker,
        urlIdExistOnTrackers: checkIfUrlIdExistOnTrackers(state),
        trackerId: props.trackerId,
        currentSubscription: subscriptionDetails,
        offers: getSubscriptionOffersVariants(state),
        options: getOptions(),
        activeOptions: getActiveOptions(),
        newSubscriptionOffer: getNewSubscriptionOffer(state),
        newOptions: getNewOptions(state),
        canChangePlan: getCanChangePlan(state),
        activeTheme: state.userTrackers.theme,
        currentUserSite: state.user.userData?.site,
        oneYearPrice,
        user: state.user.userData,
        isMobileRedirect: state.layout.isMobileRedirect
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    setUserData: async (data: IRawUserData) => {
        dispatch.user.setUserData(data);
        return data;
    },
    selectTrackerInSubscriptionProcess: async (trackerId) =>
        dispatch.subscriptionProcess.setSelectedTracker(trackerId),
    setSelectedTracker: dispatch.userTrackers.setActiveTracker,
    getOfferts: async (site) =>
        dispatch.subscriptionOffers.fetchSubscriptionVariants(site),
    changeSubscriptionOffer: (newOffer: ISubscriptionOffer | null) => {
        return dispatch.manageSubscription.updateNewSubscriptionOffer(newOffer);
    },
    selectTheme: async (id: number | null) =>
        dispatch.userTrackers.selectTheme(id as any),

    /**
     * Submit a call to change-plan endpoint & a call to PUT subscription endpoint (to update options)
     */
    doChangePlanReactivation: async () => {
        return dispatch.manageSubscription.submitSubscriptionRequestOnReactivation();
    },
    doChangePlan: async (
        currentSubscriptionId: number,
        currentSubscriptionCode: string,
        newOffer: ISubscriptionOffer | null,
        newOptions: INewOption[],
    ) => {
        const promises: any[] = [];

        // Call change-plan endpoint
        if (newOffer && newOffer.code !== currentSubscriptionCode) {
            const offerCode = newOffer.code;
            promises.push(
                dispatch.manageSubscription.changePlan({
                    subscriptionId: currentSubscriptionId,
                    newOfferCode: offerCode,
                }),
            );
        }
        // Call options endpoint
        if (newOptions.length > 0) {
            promises.push(
                dispatch.userSubscriptions.modifySubscriptionOptions({
                    options: newOptions,
                    subscriptionId: currentSubscriptionId,
                }),
            );
        }
        return Promise.all(promises);
    },
    fetchSubscriptionDetails: async (subscriptionId: number) => {
        return dispatch.userSubscriptions.fetchSubscriptionDetails(
            subscriptionId,
        );
    },
    doSetSubscriptionCode: async (
        subscriptionCode: SubscriptionVariantCode,
    ) => {
        return dispatch.subscriptionProcess.setSelectedSubscription(
            subscriptionCode,
        );
    },
    doSetTrackerId: async (trackerId: number) => {
        return dispatch.subscriptionProcess.setSelectedTracker(trackerId);
    },
    doSetPaymentMean: async (paymentMean: PaymentCode) => {
        return dispatch.subscriptionProcess.setSelectedPayment(paymentMean);
    },
    doSetOptions: async (options: SubscriptionOptionType[]) => {
        return dispatch.subscriptionProcess.setSelectedOptions(options);
    },
    /* Check if subscription is eligible to change-plan */
    fetchCanChangePlan: async (subscriptionId: number) => {
        return dispatch.manageSubscription.fetchCanChangePlan(subscriptionId);
    },
    resetNewOptions: () => dispatch.manageSubscription.resetOptions(),
    showNotification: dispatch.notifications.showNotification,
    toggleOption: (
        optionCodeToToggle: SubscriptionOptionType,
        activeOptions: ISubscriptionOptionData[],
    ) => {
        return dispatch.manageSubscription.updateOption({
            optionCodeToToggle,
            activeOptions,
        });
    },
});

export const ConnectedManageSubscriptionPage = compose<
    IManageSubscriptionPage,
    {}
>(
    withSelectedTracker,
    withActiveTrackerResolving,
    connect(makeMapState, mapDispatch),
)(ManageSubscriptionPage);
