import { Button } from 'app/common/button/button.component'
import { useNavigate } from 'react-router'
import cx from 'classnames'
import styles from './error-page.module.scss'
import { useTranslation } from 'react-i18next';
import { Headline } from 'app/common/headline/headline.component';

interface IOuterProps {
    errorMessage: string
    resetErrorBoundary: () => void
}




export const ErrorPage = ({ errorMessage, resetErrorBoundary }: IOuterProps) => {
    const history = useNavigate()
    const { t } = useTranslation(['error', 'commonActions', 'signup']);

    const goBackToTrackers = () => {
        if (location.search.includes('source=app')) {
            window.location.href = 'weenect://app/back_error';
        } else {
            history('/trackers')
            resetErrorBoundary()
        }
    }
    return (
        <div className={styles.container}>
            <div className={styles.text}>
                <Headline style={{ color: 'white' }} className={styles.textCenter} level={1}>{t('TITLE')}</Headline>
                <div className={cx(styles.textCenter, styles.buttonBlock)}>
                    <Button secondary onClick={goBackToTrackers}>{t('BACK')}</Button>
                    <Button secondary onClick={resetErrorBoundary}>{t('RETRY')}</Button>
                </div>
            </div>
        </div>
    )
}