import { meters2ScreenPixels } from 'google-map-react';
import { Icon, LatLngLiteral, LeafletMouseEvent } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { Circle, Marker } from 'react-leaflet';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { colors } from '../../../styles/colors';
import geofenceIcon from '../../icons/geofence-icon.svg';
import { MapLayerType } from '../../map/interfaces';
import { IRootState } from '../../store/store';
import styles from './geofence-map-marker.module.scss';

interface IStateProps {
    trackerId: number | null;
    selectedLayer: MapLayerType;
    zoom: number;
}
interface IGeofenceMapMarkerProps {
    editable: boolean;
    position: {
        lat: number;
        lng: number;
    };
    radius: number;
    onPositionUpdated?: (pos: LatLngLiteral) => any;
}
/**
 * Fields required for google-map-react integration
 */
interface IGoogleMapReactProps {
    lat?: number | undefined;
    lng?: number | undefined;
}

interface IProps
    extends IGeofenceMapMarkerProps,
    IGoogleMapReactProps,
    IStateProps { }

const icon = new Icon({
    iconSize: [16, 16],
    iconUrl: geofenceIcon,
});

const color = colors.colorFlamingo;

/**
 * Later make this with draggable radius editing
 */
export const GeofenceMapMarker = ({
    editable,
    trackerId,
    position,
    radius,
    selectedLayer,
    zoom,
    onPositionUpdated,
}: IProps) => {
    const [localPosition, setPosition] = useState(position);

    const { lat, lng } = position;

    useEffect(() => {
        setPosition({
            lat,
            lng,
        });
    }, [lat, lng]);

    const renderLeafletGeofenceMarker = () => {
        return (
            <Marker
                ondrag={(e: LeafletMouseEvent) => {
                    setPosition(e.latlng);
                    if (onPositionUpdated) {
                        onPositionUpdated(e.latlng);
                    }
                }}
                position={localPosition}
                icon={icon}
                draggable={editable}
                style={{ zIndex: 1200 }}
                pane="geofences-icons"
            >
                <Circle
                    fillColor={color}
                    fillOpacity={0.2}
                    color={color}
                    center={localPosition}
                    radius={radius}
                    weight={1}
                    pane="geofences-radius"
                />
            </Marker>
        );
    };

    const renderGoogleMapGeofenceMarker = () => {
        const { w, h } = meters2ScreenPixels(
            radius * 2, // size in meters. Google requires a diameter to work (as opposed to leaflet) so we have to multiply it by 2
            { lat, lng }, // marker coords
            zoom, // map zoom
        );
        return (
            <div
                style={{
                    width: w,
                    height: h,
                    // https://caniuse.com/css-rrggbbaa
                    // 33 correspond to the hexadecimal notation of a 0.2 opacity.
                    backgroundColor: color + '33',
                    zIndex: 0,
                }}
                className={styles.geofence}
            >
                <div className={styles['geofence-center']} />
            </div>
        );
    };

    // When displaying leaflet layers
    if (
        selectedLayer === MapLayerType.GRAY ||
        selectedLayer === MapLayerType.SATELLITE ||
        selectedLayer === MapLayerType.STREET
    ) {
        return trackerId ? renderLeafletGeofenceMarker() : null;
    }

    // When displaying google-map layer.
    return trackerId ? renderGoogleMapGeofenceMarker() : null;
};

const mapState = (state: IRootState): IStateProps => ({
    trackerId: state.userTrackers.activeTracker,
    selectedLayer: state.map.selectedLayer,
    zoom: state.map.zoom,
});

export const ConnectedGeofenceMapMarker = compose<
    IProps,
    IGeofenceMapMarkerProps & IGoogleMapReactProps
>(connect(mapState, null))(GeofenceMapMarker);
