import { LatLngTuple } from 'leaflet';

import { ModelConfig } from '@rematch/core';

import { config } from '../../config';
import { getLanguageFromCountryCode } from '../language/get-language-from-country-code';
import { getUserCountryCode } from '../user/get-user-country';
import { IViewport, MapLayerType } from './interfaces';

export interface IMapStore extends IViewport {
    selectedLayer: MapLayerType;
    sideBarScroll: number;
}

export interface IDefaultPositionStore {
    [country: string]: Array<number>;
}
const defaultTrackerZoom = 18;
const availableDefaultPosition = ['FR', 'EN', 'DE', 'ES', 'IT', 'NL'];
const defaultViewportLanguage: IDefaultPositionStore = {
    FR: [48.8583607, 2.2926513],
    EN: [51.500729, -0.124625],
    DE: [52.520051, 13.377753],
    ES: [40.4203, -3.69141],
    IT: [41.89021, 12.492231],
    NL: [52.370216, 4.895168],
};
const getUserLanguage = (userLanguage: string) => {
    const checkedLanguage = userLanguage.substring(0, 2).toUpperCase();
    if (availableDefaultPosition.includes(checkedLanguage)) {
        return checkedLanguage;
    }
    return 'EN';
};
/**
 * Before any user-localization or other map action occurs, default map position can be set from config.
 */
export const defaultViewport: IViewport = {
    center: [
        defaultViewportLanguage[getUserLanguage(navigator.language)][0],
        defaultViewportLanguage[getUserLanguage(navigator.language)][1],
    ],
    zoom: defaultTrackerZoom,
};

export const mapStore: ModelConfig<IMapStore> = {
    state: {
        /**
         * Look if we have cached layer from previous layer changes, and use it if so
         * Else default to Sreet default layer
         */

        selectedLayer:
            (localStorage.getItem('favourite-layer') as MapLayerType) ||
            MapLayerType.STREET,
        ...defaultViewport,

        sideBarScroll: 0,
    },
    reducers: {
        setSideBarScroll: (state: IMapStore, payload: number) => ({
            ...state,
            sideBarScroll: payload,
        }),
        setZoom: (state: IMapStore, payload: number) => ({
            ...state,
            zoom: payload,
        }),
        incrementZoom: (state: IMapStore, payload) => ({
            ...state,
            zoom: state.zoom + 1,
        }),
        decrementZoom: (state: IMapStore, payload) => ({
            ...state,
            zoom: state.zoom - 1,
        }),
        setPosition: (state: IMapStore, payload: LatLngTuple) => ({
            ...state,
            center: payload,
        }),
        setViewport: (state: IMapStore, payload: IViewport) => ({
            ...state,
            ...payload,
        }),
        setSelectedLayer: (state, layer: MapLayerType) => ({
            ...state,
            selectedLayer: layer,
        }),
    },
    effects: (dispatch) => ({
        async focusOnTracker(position: [number, number]) {
            this.setViewport({
                center: position,
                zoom: defaultTrackerZoom,
            });
        },
        async setScrollOnLoad(scroll: [number]) {
            this.setSideBarScroll(scroll);
        },
        async setViewportByUserAction(viewport: IViewport) {
            this.setViewport(viewport);
        },
        async setLayerAndSaveIt(layer: MapLayerType) {
            localStorage.setItem('favourite-layer', layer);
            this.setSelectedLayer(layer);
        },
    }),
};
