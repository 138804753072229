import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import styles from '../animal-info.module.scss';
import {
    AnimalFormStep,
    IAnimal,
    Identified,
    IFormValidationInterface,
    Type,
} from '../interfaces';
import { getCatBreedList, getDogBreedList } from './animal-breed';

const DEFAULT_DOG_BREED = 'Croisé';
const DEFAULT_CAT_BREED = 'Européen';

interface IOuterProps {
    animalInfo: IAnimal;
    formValidation: IFormValidationInterface;
    setFormValidation: (value: any) => void;
    nextStep: (value: number) => void;
    handleSubmit: any;
}

export const RaceIdentificationComponent = ({
    animalInfo,
    formValidation,
    setFormValidation,
    nextStep,
    handleSubmit,
}: IOuterProps) => {
    const { t } = useTranslation([
        'activityOnboarding',
        'activityTracking',
        'commonActions',
    ]);
    const [breedList, setBreedList] = useState<any[]>([]);
    const [isDisabled, setIsdisabled] = useState<boolean>(false);
    const [disabledRace, setDisabledRace] = useState(false);
    const handleChange = (value: any, key: any) => {
        setFormValidation((form: IFormValidationInterface) => ({
            ...form,
            [key]: value,
        }));
    };
    useEffect(() => {
        if (formValidation?.species === Type.CAT) {
            setBreedList(getCatBreedList());
        } else {
            setBreedList(getDogBreedList());
        }
    }, [formValidation]);
    useEffect(() => {
        if (
            !formValidation?.breed &&
            formValidation.species === Type.CAT &&
            breedList
        ) {
            breedList.findIndex((object, index) => {
                if (object.value === DEFAULT_CAT_BREED) {
                    setFormValidation((form: IFormValidationInterface) => ({
                        ...form,
                        breed: breedList[index].value,
                    }));
                }
            });
        }
        if (
            !formValidation?.breed &&
            formValidation.species === Type.DOG &&
            breedList
        ) {
            breedList.findIndex((object, index) => {
                if (object.value === DEFAULT_DOG_BREED) {
                    setFormValidation((form: IFormValidationInterface) => ({
                        ...form,
                        breed: breedList[index].value,
                    }));
                }
            });
        }
    }, [formValidation?.breed, breedList]);

    useEffect(() => {
        if (disabledRace) {
            setFormValidation((form: IFormValidationInterface) => ({
                ...form,
                breed: '',
            }));
        }
    }, [disabledRace]);
    useEffect(() => {
        if (formValidation) {
            const { breed, identification, species } = formValidation;
            if (species === Type.DOG && breed && identification) {
                return setIsdisabled(false);
            }
            if (breed !== '' || (disabledRace && identification)) {
                return setIsdisabled(false);
            } else {
                return setIsdisabled(true);
            }
        }
    }, [formValidation]);

    return (
        <React.Fragment>
            <div className={styles.container}>
                <Headline level={2} center className={styles.title}>
                    {t('PET_INFO.SECOND_PETS_DETAILS')}
                </Headline>

                <div className={styles.raceContainer}>
                    <p
                        className={styles.commonText}
                        style={{ marginBottom: '1rem!mportant' }}
                    >
                        {t('PET_RACE.TITLE')}
                    </p>
                    <div className={styles.width45}>
                        <Select
                            value={breedList.filter(
                                (option) =>
                                    option.value === formValidation.breed,
                            )}
                            placeholder={t('PET_RACE.SELECT_BREED')}
                            isDisabled={disabledRace}
                            options={breedList}
                            onChange={(e) => {
                                const { value } = e;
                                handleChange(value, 'breed');
                            }}
                        />
                    </div>
                    {formValidation.species === Type.DOG ? null : (
                        <div className={styles.checkboxIdentification}>
                            <label>
                                <input
                                    type="checkbox"
                                    className={styles.checkboxColor}
                                    onChange={() => {
                                        setFormValidation(
                                            (
                                                form: IFormValidationInterface,
                                            ) => ({
                                                ...form,
                                                breed: '',
                                            }),
                                        );
                                        setDisabledRace(!disabledRace);
                                    }}
                                />
                                {t('PET_RACE.COMMON_TEXT')}
                            </label>
                        </div>
                    )}
                </div>
                <div
                    className={
                        formValidation.breed !== null
                            ? styles.containerBlockVisible
                            : styles.containerBlockHided
                    }
                >
                    <p className={styles.commonText}>
                        {t('PET_IDENTIFICATION.SELECT_IDENTIFICATION')}
                    </p>
                    <div className={styles.gridIdentification}>
                        <div
                            onClick={() =>
                                setFormValidation(
                                    (form: IFormValidationInterface) => ({
                                        ...form,
                                        identification: Identified.CHIPPED,
                                    }),
                                )
                            }
                            className={
                                formValidation?.identification ===
                                Identified.CHIPPED
                                    ? styles.textContainerSelected
                                    : styles.textContainer
                            }
                        >
                            <p className={styles.textBlockIcon}>
                                {t('PET_IDENTIFICATION.CHIP')}
                            </p>
                        </div>
                        <div
                            onClick={() =>
                                setFormValidation(
                                    (form: IFormValidationInterface) => ({
                                        ...form,
                                        identification: Identified.TATOOED,
                                    }),
                                )
                            }
                            className={
                                formValidation?.identification ===
                                Identified.TATOOED
                                    ? styles.textContainerSelected
                                    : styles.textContainer
                            }
                        >
                            <p className={styles.textBlockIcon}>
                                {t('PET_IDENTIFICATION.TATTOO')}
                            </p>
                        </div>
                    </div>
                    <div
                        onClick={() =>
                            setFormValidation(
                                (form: IFormValidationInterface) => ({
                                    ...form,
                                    identification: Identified.NOT_IDENTIFIED,
                                }),
                            )
                        }
                        className={
                            formValidation?.identification ===
                            Identified.NOT_IDENTIFIED
                                ? styles.textContainerSelectedWider
                                : styles.textContainerWider
                        }
                    >
                        <p className={styles.textBlockIcon}>
                            {t('PET_IDENTIFICATION.NONE')}
                        </p>
                    </div>
                </div>
                <div className={styles.buttonBlock}>
                    {animalInfo ? (
                        <Button
                            disabled={isDisabled}
                            className={styles.buttonWidth}
                            onClick={() => handleSubmit()}
                        >
                            {t('SUMMARY.VALIDATION')}
                        </Button>
                    ) : (
                        <Button
                            disabled={isDisabled}
                            className={styles.buttonWidth}
                            onClick={() => nextStep(AnimalFormStep.ACTIVITY)}
                        >
                            {t('commonActions:NEXT')}
                        </Button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
