import cx from 'classnames';
import { FormikBag, FormikProps, withFormik } from 'formik';
import React, { HTMLAttributes } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { compose, withProps } from 'recompose';

import {
    getAvailableCountries,
    ICountry,
} from '../../../i18n/available-countries';
import {
    availableLanguagesCodes,
    getAvailableLanguages,
    ILanguage,
} from '../../../i18n/available-languages';
import { IWithFormUtils, withFormUtils } from '../../../utils/form-helpers';
import { Button } from '../../common/button/button.component';
import { Checkbox } from '../../common/checkbox/checkbox.component';
import { Input } from '../../common/input/input.component';
import { Link } from '../../common/link/link.component';
import { PasswordInput } from '../../common/password-input/password-input.component';
import { Select } from '../../common/select/select.component';
import { Typography } from '../../common/typography/typography.component';
import { loginRoute } from '../../routing/routes';
import {
    getUserCountryCode,
    getUserCountryObject,
} from '../../user/get-user-country';

import styles from './signup-form.module.scss';
import { getSignupValidationSchema } from './signup-validation.schema';

export interface ISignupFormValues {
    city: string;
    postal_code: string;
    address: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    country: string;
    language: string;
    termsAccepted: boolean;
    marketingAccepted: boolean;
    isMailUsed: boolean;
}

export interface IOuterProps extends HTMLAttributes<HTMLFormElement> {
    onFormSubmit: (values: ISignupFormValues) => any;
    isMailUsed: boolean;
}

export interface ICountriesProps {
    languages: ILanguage[];
    countries: ICountry[];
}

export interface ISignupFormProps
    extends IOuterProps,
    FormikProps<ISignupFormValues>,
    ICountriesProps,
    IWithFormUtils { }

export const SignupForm = ({
    isMailUsed,
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    languages,
    countries,
    className,
    getErrorMessage,
    isFieldValid,
    setFieldValue,
    ...props
}: ISignupFormProps) => {
    const { t } = useTranslation(['signup', 'forms', 'login']);
    const onPasswordFocus = () => {
        const warningDiv = document.querySelector('#passwordWarning');
        if (warningDiv) {
            if (warningDiv.className === styles['password-warning']) {
                warningDiv.className = styles['password-warning-displayed'];
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className={cx(styles.form, className)}>
            <Input
                className={styles.control}
                onBlur={handleBlur}
                label={t('forms:FIRST_NAME.LABEL')}
                placeholder={t('forms:FIRST_NAME.PLACEHOLDER')}
                name="firstName"
                onChange={handleChange}
                errorMessage={getErrorMessage('firstName')}
                isValid={isFieldValid('firstName')}
            />
            <Input
                className={styles.control}
                onBlur={handleBlur}
                label={t('forms:LAST_NAME.LABEL')}
                placeholder={t('forms:LAST_NAME.PLACEHOLDER')}
                name="lastName"
                onChange={handleChange}
                errorMessage={getErrorMessage('lastName')}
                isValid={isFieldValid('lastName')}
            />

            <Input
                className={isMailUsed ? styles.alreadyUsedMail : styles.control}
                onBlur={handleBlur}
                label={t('forms:EMAIL.LABEL')}
                placeholder={t('forms:EMAIL.PLACEHOLDER')}
                name="email"
                type="email" // required as it defines field format (autocapitalize off, etc.)
                onChange={handleChange}
                errorMessage={getErrorMessage('email')}
                isValid={isFieldValid('email')}
            />
            <Input
                className={styles.control}
                label={t('forms:PHONE.LABEL')}
                onBlur={handleBlur}
                name="phone"
                value={values.phone}
                isPhone
                // We need to setFieldValue this way to handle DOM re-render
                onChange={(updatedPhone) => {
                    setFieldValue('phone', updatedPhone);
                }}
                errorMessage={getErrorMessage('phone')}
                isValid={isFieldValid('phone')}
            />
            <PasswordInput
                className={styles.control}
                onBlur={handleBlur}
                label={t('forms:PASSWORD.LABEL')}
                placeholder={t('forms:PASSWORD.PLACEHOLDER')}
                name="password"
                onChange={handleChange}
                onFocus={onPasswordFocus}
                leftIcon={null}
                errorMessage={getErrorMessage('password')}
                isValid={isFieldValid('password')}
            />
            <div id="passwordWarning" className={styles['password-warning']}>
                <span>
                    <Trans i18nKey="forms:PASSWORD.WARNING">
                        Warning, "azertyuiop" and "123456789" are NOT strong
                        security passwords. Check the level of security of your
                        password here :
                        <Link
                            href="https://howsecureismypassword.net"
                            target="_blank"
                        >
                            https://howsecureismypassword.net
                        </Link>
                    </Trans>
                </span>
            </div>
            <div className={styles['selects-grid']}>
                <Select
                    className={styles.control}
                    label={t('forms:COUNTRY.LABEL')}
                    name="country"
                    onChange={handleChange}
                    value={values.country}
                >
                    {countries.map((c) => (
                        <option key={c.code} value={c.code}>
                            {c.name}
                        </option>
                    ))}
                </Select>
                <Select
                    className={styles.control}
                    label={t('forms:LANGUAGE.LABEL')}
                    name="language"
                    onChange={handleChange}
                    value={values.language}
                >
                    {languages.map((lang) => (
                        <option key={lang.code} value={lang.code}>
                            {lang.shortDisplay}
                        </option>
                    ))}
                </Select>
            </div>
            <Input
                className={styles.control}
                onBlur={handleBlur}
                label={t('forms:POSTAL_CODE.STREET_NUMBER')}
                placeholder={t('forms:POSTAL_CODE.STREET_NUMBER')}
                name="address"
                onChange={handleChange}
                errorMessage={getErrorMessage('address')}
                isValid={isFieldValid('address')}
            />
            <div className={styles['address-grid']}>
            <Input
                    className={styles.control}
                    onBlur={handleBlur}
                    label={t('forms:POSTAL_CODE.LABEL')}
                    placeholder={t('forms:POSTAL_CODE.LABEL')}
                    name="postal_code"
                    onChange={handleChange}
                    errorMessage={getErrorMessage('postal_code')}
                    isValid={isFieldValid('postal_code')}
                />
                <Input
                    className={styles.control}
                    onBlur={handleBlur}
                    label={t('forms:CITY.LABEL')}
                    placeholder={t('forms:CITY.LABEL')}
                    name="city"
                    onChange={handleChange}
                    errorMessage={getErrorMessage('city')}
                    isValid={isFieldValid('city')}
                />
            </div>
            <div className={styles.checkboxes}>
                <Checkbox
                    onChange={handleChange}
                    name="marketingAccepted"
                    className={styles['checkbox-control']}
                >
                    {t('MARKETING_AGREEMENT_TEXT')}
                </Checkbox>
                <Checkbox
                    name="termsAccepted"
                    onChange={handleChange}
                    error={Boolean(errors.termsAccepted)}
                    className={styles['checkbox-control']}
                >
                    <Trans ns="signup" i18nKey="TERMS_AGREEMENT_TEXT">
                        I accept the
                        <Link href={t('login:TERMS_LINK_URL')} target="_blank">
                            Terms & Conditions
                        </Link>
                    </Trans>
                </Checkbox>
            </div>
            <Button type="submit" block>
                {t('SUBMIT_BUTTON')}
            </Button>
            <Typography center className={styles['login-info']}>
                {t('ALREADY_MEMBER_TEXT')}{' '}
                <RouterLink to={loginRoute}>
                    <Typography tag="span" orange>
                        {t('SIGN_IN_TEXT')}
                    </Typography>
                </RouterLink>
            </Typography>
        </form>
    );
};

export const ControlledSignupForm = compose<ISignupFormProps, IOuterProps>(
    withProps<ICountriesProps, ISignupFormProps>(() => {
        const languages: ILanguage[] = getAvailableLanguages();
        const countries: ICountry[] = getAvailableCountries();

        return {
            countries,
            languages,
        };
    }),
    withFormik<IOuterProps & ICountriesProps, ISignupFormValues>({
        mapPropsToValues: () => {
            const userCountry = getUserCountryObject();
            const userLang =
                availableLanguagesCodes.find(
                    (c) => c === getUserCountryCode(),
                ) || navigator.language.substring(0, 2).toUpperCase();
            // toUpperCase is necessary here because we compare the language value to the one stored on API, which is in uppercase.

            return {
                city: '',
                postal_code: '',
                address: '',
                country: userCountry.code,
                email: '',
                phone: '',
                firstName: '',
                language: userLang,
                lastName: '',
                marketingAccepted: false,
                password: '',
                termsAccepted: false,
                isMailUsed: false,
            };
        },
        handleSubmit: (
            values: ISignupFormValues,
            formikBag: FormikBag<IOuterProps, ISignupFormValues>,
        ) => {
            formikBag.props.onFormSubmit(values);
        },
        validationSchema: () => getSignupValidationSchema(),
    }),
    withFormUtils,
)(SignupForm);
