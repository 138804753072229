import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import { getGlobalT } from '../../../i18n/setup-translations';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../utils/with-selected-tracker';
import { Button, IButtonProps } from '../../common/button/button.component';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { MapLayerType } from '../../map/interfaces';
import { Dispatch, IRootState } from '../../store/store';
import { ConnectedCreateGeofence } from '../create-geofence/create-geofence.component';
import { ConnectedGeofencesList } from '../geofences-list/geofences-list.component';
import { makeGetIsNewGeofenceActive } from '../selectors/geofences-edit.selectors';
import styles from './geofences-page.module.scss';

export interface IStateProps {
    newGeofenceOpen: boolean;
    selectedLayer: MapLayerType;
}

export interface IActions {
    openNewGeofence(): unknown;
    resetState(): unknown;
}

export interface IOuterProps { }

export interface IGeofencesPageProps
    extends IOuterProps,
    IStateProps,
    IActions,
    IWithSelectedTracker { }

/**
 * Simple button component having a '+' icon.
 */
const PlusButton = compose<IButtonProps, IButtonProps>(
    withProps<IButtonProps, IButtonProps>({
        iconLeft: <PlusIcon />,
        small: true,
        secondary: true,
    }),
)(Button);

export const GeofencesPage = ({
    newGeofenceOpen,
    openNewGeofence,
    trackerId,
    resetState,
    ...props
}: IGeofencesPageProps) => {
    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);
    const t = getGlobalT();
    const maybeRenderNewGeofence = () => {
        if (newGeofenceOpen) {
            return (
                <ConnectedCreateGeofence
                    trackerId={trackerId!}
                    className={styles['create-geofence']}
                />
            );
        }
    };

    const maybeRenderNewGeofenceButton = () => {
        if (!newGeofenceOpen) {
            return (
                <PlusButton onClick={openNewGeofence}>
                    {t('geofences:CREATE.HEADLINE')}
                </PlusButton>
            );
        }
    };

    return (
        <div>
            <ConnectedGeofencesList trackerId={trackerId!} />
            {maybeRenderNewGeofence()}
            {maybeRenderNewGeofenceButton()}
        </div>
    );
};

const makeMapState = () => {
    const getIsNewGeofenceActive = makeGetIsNewGeofenceActive();

    return (state: IRootState): IStateProps => {
        return {
            newGeofenceOpen: getIsNewGeofenceActive(state),
            selectedLayer: state.map.selectedLayer,
        };
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    openNewGeofence: () => dispatch.geofenceEdit.startCreatingNewGeofence(),
    resetState: () => {
        dispatch.geofenceEdit.resetState();
    },
});

export const ConnectedGeofencesPage = compose<IGeofencesPageProps, IOuterProps>(
    withSelectedTracker,
    connect(makeMapState, mapDispatch),
)(GeofencesPage);
