import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useNavigate } from "react-router-dom";

import { formatAmountWithCurrency } from '../../../utils/normalize-price-amount';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../utils/with-selected-tracker';
import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { ReactComponent as TransferIcon } from '../../icons/transfer.svg';
import { Dispatch, IRootState } from '../../store/store';
import { makeGetTrackerRegistrationDetails } from '../../trackers/selectors/get-tracker-registration-details';
import {
    IRegistrationDetails,
    ISubscriptionDetails,
    ISubscriptionOptionData,
} from '../interfaces';
import { IRawUserSubscription } from '../interfaces-api';
import { RegistrationDetails } from '../registration-details/registration-details.component';
import {
    makeGetSubscriptionDetailsFromTracker,
    makeGetSubscriptionOptions,
} from '../selectors/user-subscriptions.selectors';
import { SubscriptionDetails } from '../subscription-details/subscription-details.component';
import { ConnectedSubscriptionOptions } from '../subscription-options/subscription-options.component';
import { uncancelSubscription } from '../subscription.service';
import styles from './subscription-settings.module.scss';
import { IOuterProps } from '../../app-bootstrap.component';
import { useLocation } from 'react-router';
import { IDefaultPaymentMean } from 'app/user/interfaces';

/**
 * Currently component receives subscription details in both RAW and Mapped format.
 * This is because some effect require different set of data.
 * TODO: Unify subscription effects to use same data interface
 */
interface IComponentState {
    isMobileRedirect: boolean,
    defaultPaymentMean: IDefaultPaymentMean | undefined;
    options: ISubscriptionOptionData[] | null;
    registrationDetails: IRegistrationDetails | null;
    subscriptionDetails: ISubscriptionDetails | null;
    rawSubscriptionDetails: IRawUserSubscription | null;
    rawSubscription: IRawUserSubscription | null;
    oneYearPrice: string | null;
}

interface IActions {
    fetchSubscriptionDetails: (subscriptionId: number) => Promise<any>;
    selectTrackerInSubscriptionProcess: (trackerId: number) => Promise<any>;
    prefillSubscriptionChoices: (sub: IRawUserSubscription) => Promise<unknown>;
}


export interface ISubscriptionSettingsProps
    extends
    IComponentState,
    IWithSelectedTracker,
    IActions { }

export const SubscriptionSettings = ({
    fetchSubscriptionDetails,
    defaultPaymentMean,
    oneYearPrice,
    options,
    prefillSubscriptionChoices,
    rawSubscription,
    rawSubscriptionDetails,
    registrationDetails,
    selectTrackerInSubscriptionProcess,
    subscriptionDetails,
    trackerId,
    isMobileRedirect,
}: ISubscriptionSettingsProps) => {
    const { t } = useTranslation('subscriptions');
    const location = useLocation()
    const history = useNavigate()
    const onTransferClicked = () => {
        history(`/transfer${location.search}`);
    };

    /**
     * If subscription was never submitted (is null), button will show "activate"
     * text. This will redirect to the process, but with tracker ID already pre-filled
     */
    const onActivateClicked = () => {
        selectTrackerInSubscriptionProcess(trackerId!).then(() => {
            history(`/add/subscription/${trackerId}`);
        });
    };

    /**
     * If subscription automatic renewal was canceled
     */
    const onUncancelClicked = () => {
        if (subscriptionDetails) {
            return uncancelSubscription(subscriptionDetails.id).then(() => {
                // Update subscription object
                fetchSubscriptionDetails(subscriptionDetails.id);
                if (rawSubscriptionDetails) {
                    rawSubscriptionDetails.status = 'active';
                    return prefillSubscriptionChoices(rawSubscriptionDetails);
                }
            });
        }
    };

    /**
     * If subscription was submitted, but not paid, it has status "new".
     * Button will show "Pay and Activate" status, which will pre-fill whole process
     * and redirect user to payment step
     */
    const onPayAndActivateClicked = () => {
        if (rawSubscriptionDetails) {
            prefillSubscriptionChoices(rawSubscriptionDetails).then(() => {
                return history(`/add/payment/${trackerId}`);
            });
        }
    };

    const onManageSubscriptionTriggered = () => { 
        history(location.pathname!.replace('/subscription', '/manage'));
    };

    const onChangePaymentMeanClicked = () => {
        history(
            location.pathname!.replace(
                '/subscription',
                '/subscription/changePaymentMean',
            ),
        );
    };
    const maybeRenderSubscriptionOptions = () => {
        if (options && subscriptionDetails && !subscriptionDetails.isNew) {
            return (
                <div className={styles.section}>
                    <Headline className={styles.headline} level={3}>
                        <>{t('OVERVIEW.OPTIONS')}</>
                    </Headline>

                    <ConnectedSubscriptionOptions
                        subscriptionId={subscriptionDetails.id}
                        options={options}
                    />
                </div>
            );
        }
    };

    const maybeRenderRegistrationDetails = () => {
        if (registrationDetails) {
            return (
                <div className={styles.section}>
                    <Headline className={styles.headline} level={3}>
                        <>{t('OVERVIEW.REG_DETAILS')}</>
                    </Headline>

                    <RegistrationDetails {...registrationDetails} />
                </div>
            );
        }
    };

    const renderSubscriptionDetails = () => {
        if (!subscriptionDetails) {
            return (
                <div className={styles.section}>
                    <Headline className={styles.headline} level={3}>
                        <>{t('OVERVIEW.SUB_DETAILS')}</>
                    </Headline>
                    <SubscriptionDetails
                        data={null}
                        paymentError={
                            rawSubscription?.is_cancelled_because_of_payment_error
                        }
                        onActivateClicked={onActivateClicked}
                        onUncancelClicked={onUncancelClicked}
                        onManageSubscriptionClicked={
                            onManageSubscriptionTriggered
                        }
                        onChangePaymentMeanClicked={onChangePaymentMeanClicked}
                        onPayAndActivateClicked={onPayAndActivateClicked}
                    />
                </div>
            );
        }

        return (
            <div className={styles.section}>
                <Headline className={styles.headline} level={3}>
                    <>{t('OVERVIEW.SUB_DETAILS')}</>
                </Headline>
                <SubscriptionDetails
                    paymentError={
                        rawSubscription?.is_cancelled_because_of_payment_error
                    }
                    data={{
                        expireIn: subscriptionDetails.willExpireIn,
                        price: subscriptionDetails.priceFormatted,
                        isActive: subscriptionDetails.isActive,
                        isCancelled: subscriptionDetails.isCancelled,
                        isCancelScheduled:
                            subscriptionDetails.isCancelScheduled,
                        isNew: subscriptionDetails.isNew,
                        isRefunded: subscriptionDetails.isRefunded,
                        isExpired: subscriptionDetails.isExpired,
                        planName: subscriptionDetails.name,
                        pricePeriod: subscriptionDetails.pricePeriod,
                        renewalDate: subscriptionDetails.renewalDate,
                        renewalPrice: oneYearPrice,
                        expiryDate: subscriptionDetails.expiryDate,
                        payment_mean: defaultPaymentMean?.payment_mean,
                    }}
                    onActivateClicked={onActivateClicked}
                    onUncancelClicked={onUncancelClicked}
                    onManageSubscriptionClicked={onManageSubscriptionTriggered}
                    onChangePaymentMeanClicked={onChangePaymentMeanClicked}
                    onPayAndActivateClicked={onPayAndActivateClicked}
                />
            </div>
        );
    };

    const renderButtons = () => (
        <div className={styles['buttons-container']}>
            <Button
                iconLeft={<TransferIcon />}
                small
                secondary
                onClick={onTransferClicked}
            >
                {t('OVERVIEW.REPLACE_TRACKER_BUTTON.LABEL')}
            </Button>
        </div>
    );

    return (
        <div>
            {renderSubscriptionDetails()}
            {maybeRenderSubscriptionOptions()}
            {maybeRenderRegistrationDetails()}
            {renderButtons()}
        </div>
    );
};

const makeMapState = () => {
    const getRegistrationDetails = makeGetTrackerRegistrationDetails();

    return (
        state: IRootState,
        props: IWithSelectedTracker,
    ): IComponentState => {
        const getSubscriptionDetails = makeGetSubscriptionDetailsFromTracker(
            props.trackerId!,
        );
        const subscriptionDetails = getSubscriptionDetails(
            state,
        ) as ISubscriptionDetails;

        const getRawSubscriptionDetails = makeGetSubscriptionDetailsFromTracker(
            props.trackerId!,
            false,
        );
        const rawSubscription = getRawSubscriptionDetails(
            state,
        ) as IRawUserSubscription;

        let oneYearPrice = null;

        if (!subscriptionDetails) {
            return {
                defaultPaymentMean: state.user.userData?.default_payment_mean,
                options: null,
                registrationDetails: getRegistrationDetails(state),
                subscriptionDetails: null,
                rawSubscriptionDetails: null,
                isMobileRedirect: state.layout.isMobileRedirect,
                rawSubscription,
                oneYearPrice,
            };
        }

        const getSubOptions = makeGetSubscriptionOptions(
            subscriptionDetails.id,
        );

        if (rawSubscription.period === 24) {
            const oneYearPlan = state.subscriptionOffers.variants.find(
                (offer) => offer.code === '12_month_1_tracker',
            )
            oneYearPrice = formatAmountWithCurrency(
                oneYearPlan?.amount || 0,
                oneYearPlan?.currency || 'EUR'
            );
        }


        return {
            isMobileRedirect: state.layout.isMobileRedirect,
            defaultPaymentMean: state.user.userData?.default_payment_mean,
            options: getSubOptions(state),
            registrationDetails: getRegistrationDetails(state),
            subscriptionDetails,
            rawSubscriptionDetails: getRawSubscriptionDetails(
                state,
            ) as IRawUserSubscription,
            rawSubscription,
            oneYearPrice,
        };
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    fetchSubscriptionDetails: async (subscriptionId: number) =>
        dispatch.userSubscriptions.fetchSubscriptionDetails(subscriptionId),
    selectTrackerInSubscriptionProcess: async (trackerId) =>
        dispatch.subscriptionProcess.setSelectedTracker(trackerId),
    prefillSubscriptionChoices: async (sub) =>
        dispatch.subscriptionProcess.setChoicesFromSubscription(sub),
});

export const ConnectedSubscriptionSettings = compose(
    withSelectedTracker,
    connect(makeMapState, mapDispatch),
)(SubscriptionSettings);
