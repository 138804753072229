import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { compose, mapProps } from 'recompose';

import { ReactComponent as CloseIcon } from '../icons/menu-x.svg';
import { INotification } from '../notifications/interfaces';
import { trackersRoute } from '../routing/routes';
import { Dispatch, IRootState } from '../store/store';
import { TrackerType } from '../trackers/interfaces';
import { ITracker } from '../trackers/interfaces-api';
import { makeGetTrackerModel } from '../trackers/selectors/get-tracker-model';
import styles from './animal-info.module.scss';
import { postAnimalInfo, putAnimalInfo } from './animal-info.service';
import { ActivityComponent } from './component/activity-component';
import { BirthdateSexeInfoComponent } from './component/birthdate-sexe-info-component';
import { HealthComponent } from './component/health-component';
import { RaceIdentificationComponent } from './component/race-identification-component';
import { AnimalInfoStep } from './component/step';
import { SummaryComponent } from './component/summary-component';
import { AnimalTypeNameForm } from './component/type-name-form';
import {
    AnimalFormStep,
    IAnimal,
    IFormValidationInterface,
    Type,
} from './interfaces';

interface IMappedProps { }

interface IState {
    rawTrackers: ITracker[];
    tracker: ITracker;
    animalInfo: IAnimal;
    isMobileRedirect: boolean;
}
interface ILocationStateProps {
    state: number;
}

export interface INewTrackerPageProps
    extends
    IState,
    IActions,
    IFormValidationInterface { }

interface IActions {
    fetchAnimalInfo: (imei: number) => IAnimal;
    showNotification(n: INotification): unknown;
}

export const AnimalInfoPage = ({
    animalInfo,
    fetchAnimalInfo,
    showNotification,
    tracker,
    isMobileRedirect,
}: INewTrackerPageProps) => {
    const location = useLocation();
    const history = useNavigate()
    const myState: ILocationStateProps = location as ILocationStateProps;
    const [submitError, setSubmitError] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(myState.state || 0);
    const [formValidation, setFormValidation] = useState<
        IFormValidationInterface
    >({
        tracker_id: tracker.id,
        name: tracker.name || null,
        species: null,
        sex: null,
        is_activated: true,
        birth_date: null,
        is_sterilized: null,
        breed: null,
        weight: 0,
        identification: null,
        activity_level: null,
        last_vet_visit_date: null,
        last_vaccination_date: null,
    });
    const { t } = useTranslation('subscriptionAlert');
    const commont = useTranslation('commonActions').t;

    useEffect(() => {
        fetchAnimalInfo(tracker.imei);
    }, []);

    useEffect(() => {
        if (animalInfo) {
            setFormValidation((form: IFormValidationInterface) => ({
                ...form,
                tracker_id: tracker.id,
                name: animalInfo?.name || '',
                species: animalInfo?.species,
                sex: animalInfo?.sex || '',
                is_activated: true,
                birth_date: new Date(animalInfo?.birth_date),
                is_sterilized: animalInfo?.is_sterilized,
                morphology: animalInfo?.morphology,
                breed: animalInfo?.breed || null,
                weight: animalInfo?.weight || null,
                identification: animalInfo?.identification.toUpperCase(),
                activity_level: animalInfo?.activity_level,
                last_vaccination_date: new Date(
                    animalInfo?.last_vaccination_date,
                ),
                last_vet_visit_date: new Date(animalInfo?.last_vet_visit_date),
            }));
        }
    }, [animalInfo]);

    useEffect(() => {
        if (
            tracker.type.includes(TrackerType.ANIMALCAT) ||
            tracker.type.includes(TrackerType.CAT) ||
            tracker.type.includes(TrackerType.CAT2)
        ) {
            return setFormValidation((form) => ({
                ...form,
                species: Type.CAT,
            }));
        } else {
            return setFormValidation((form) => ({
                ...form,
                species: Type.DOG,
            }));
        }
    }, []);
    // reseting selected breed if user change species
    useEffect(() => {
        setFormValidation((form) => ({
            ...form,
            breed: '',
        }));
    }, [formValidation.species]);
    
    if(submitError) {
        throw Error("failed to submit");
    }
    const handleSubmit = () => {
        const body = {
            tracker_id: formValidation.tracker_id,
            name: formValidation.name,
            sex: formValidation.sex,
            birth_date: formValidation.birth_date,
            is_sterilized: formValidation.is_sterilized,
            identification: formValidation.identification?.toLowerCase(),
            breed: formValidation.breed,
            species: formValidation.species,
            weight: formValidation.weight,
            activity_level: formValidation?.activity_level,
            last_vaccination_date: formValidation.last_vaccination_date,
            last_vet_visit_date: formValidation.last_vet_visit_date,
        };
        if (animalInfo) {
            // if we have animal info for this tracker use put logic
            putAnimalInfo(body, animalInfo.id).then((res: any) => {
                if (res.status === 201) {
                    showNotification({
                        type: 'success',
                        content: t('MESSAGES.UPDATE_SUCCESS'),
                    });
                } else {
                    showNotification({
                        type: 'error',
                        content: commont('MESSAGES.UPDATE_ERROR_UNKNOWN'),
                    });
                }
                history(
                    `${trackersRoute}/${formValidation.tracker_id}/settings`,
                );
            });
        } else {
            // else post logic
            postAnimalInfo(body).then((res: any) => {
                if (res.status === 201) {
                    showNotification({
                        type: 'success',
                        content: t('MESSAGES.UPDATE_SUCCESS'),
                    });
                    if (isMobileRedirect) {
                        window.location.href = 'weenect://app/ok_animal_info';
                    }
                } 
                else {
                    showNotification({
                        type: 'error',
                        content: commont('MESSAGES.UPDATE_ERROR_UNKNOWN'),
                    });
                }
                history(
                    `${trackersRoute}/${formValidation.tracker_id}/settings`,
                );
            })
            .catch((err) => {
                    setSubmitError(true);
            });
        }
    };

    const renderComponentDependingOnCurrentStep = () => {
        switch (currentStep) {
            case AnimalFormStep.TYPE_NAME:
                return (
                    <AnimalTypeNameForm
                        animalInfo={animalInfo}
                        formValidation={formValidation}
                        setFormValidation={setFormValidation}
                        nextStep={setCurrentStep}
                        handleSubmit={handleSubmit}
                    />
                );
            case AnimalFormStep.BIRTH_SEXE:
                return (
                    <BirthdateSexeInfoComponent
                        animalInfo={animalInfo}
                        formValidation={formValidation}
                        setFormValidation={setFormValidation}
                        nextStep={setCurrentStep}
                        handleSubmit={handleSubmit}
                    />
                );
            case AnimalFormStep.RACE:
                return (
                    <RaceIdentificationComponent
                        animalInfo={animalInfo}
                        formValidation={formValidation}
                        setFormValidation={setFormValidation}
                        nextStep={setCurrentStep}
                        handleSubmit={handleSubmit}
                    />
                );
            case AnimalFormStep.ACTIVITY:
                return (
                    <ActivityComponent
                        animalInfo={animalInfo}
                        formValidation={formValidation}
                        setFormValidation={setFormValidation}
                        handleSubmit={handleSubmit}
                        nextStep={setCurrentStep}
                    />
                );
            case AnimalFormStep.HEALTH:
                return (
                    <HealthComponent
                        animalInfo={animalInfo}
                        formValidation={formValidation}
                        setFormValidation={setFormValidation}
                        handleSubmit={handleSubmit}
                        nextStep={setCurrentStep}
                    />
                );
            case AnimalFormStep.SUMMARY:
                return (
                    <SummaryComponent
                        animalInfo={animalInfo}
                        formValidation={formValidation}
                        setFormValidation={setFormValidation}
                        nextStep={setCurrentStep}
                        handleSubmit={handleSubmit}
                    />
                );
        }
    };

    return (

        <React.Fragment key={'name'}>
            <div className={styles.flex}>
                {currentStep === AnimalFormStep.SUMMARY || animalInfo ? (
                    <div />
                ) 
                : (
                    <AnimalInfoStep
                        activeIndex={currentStep}
                        setCurrentStep={setCurrentStep}
                    />
                )}
                <div
                    className={styles.closeIcon}
                    onClick={() => {
                        if (isMobileRedirect) {
                            window.location.href = 'weenect://app/back_animal_info';
                        } else {
                            return history(
                                `${trackersRoute}/${formValidation.tracker_id}/settings`,
                            )
                        }
                    }
                    }
                >
                    <CloseIcon />
                </div>
            </div>
            {renderComponentDependingOnCurrentStep()}
        </React.Fragment>
    );
};

const mapDispatch = (dispatch: Dispatch) => ({
    showNotification: dispatch.notifications.showNotification,
    fetchAnimalInfo: (imei: number) => {
        dispatch.animalInfo.getAnimalInfo(imei);
    },
});

const makeMapState = (state: IRootState): IState => {
    const idUrl = window.location.pathname.split('/')[2];
    const parsedId = parseInt(idUrl, 10);
    let isIdAvailable = state.userTrackers.activeTracker
    const getTrackerModel = makeGetTrackerModel(isIdAvailable ? isIdAvailable : parsedId)
    const tracker = getTrackerModel(state) as ITracker;
    return {
        animalInfo: state.animalInfo.rawPetInfos!,
        rawTrackers: state.userTrackers.rawTrackers,
        isMobileRedirect: state.layout.isMobileRedirect,
        tracker,
    };
};

export const ConnectedAnimalInfoPage = compose<INewTrackerPageProps, {}>(
    connect(makeMapState, mapDispatch),
    mapProps(
        (props: IState): IMappedProps => ({
            ...props,
        }),
    ),
)(AnimalInfoPage);
