import {
    IOptionOffer,
    SubscriptionVariantCode,
} from '../subscription-offers/interfaces';

/**
 * gocardless = Bank transfer
 * hipay = credit card gate
 */
export enum PaymentCode {
    GOCARDLESS = 'gocardless',
    HIPAY = 'hipay',
}

export interface IPaymentType {
    code: PaymentCode;
    name: string;
    description: string;
    accepted: string;
}

export interface ISubscriptionSummaryData {
    subscriptionName: string;
    subscriptionPrice: string;
    promoCodeDiscount: string | null;
    subscriptionCanBeUpgraded: boolean;
    subUpgradedSavingAmount: string;
    enabledOptions: IOptionOffer[];
    enabledAccountOptions: (IOptionOffer | undefined)[];
    optionsAvailableToAdd: IOptionOffer[];
    summaryPrice: string;
}
export interface ISubscriptionVariantChoice {
    subscriptionOfferCode: SubscriptionVariantCode;
    subscriptionOfferId: number;
}
export interface IVerifyPromoCode {
    promoCode: string;
    subscriptionOfferId: number;
    is_gift_card_code?: boolean | null;
    trackerId?: number | null;
}
export interface IVerifyPromoCodeResponse {
    discount: number;
    promo_code: any;
    promo_code_rule_offer: any;
}
