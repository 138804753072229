import * as yup from 'yup';

export const getMailsFormValidationSchema = () =>
    yup.object({
        mails: yup.array(
            yup
                .string()
                .email()
                .required(),
        ),
    });
