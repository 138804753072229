import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import { getGlobalT } from '../../../i18n/setup-translations';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../utils/with-selected-tracker';
import { Button, IButtonProps } from '../../common/button/button.component';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { MapLayerType } from '../../map/interfaces';
import { Dispatch, IRootState } from '../../store/store';
import { ConnectedWifiZonesList } from '../wifi-zones-list/wifi-zones-list.component';
import styles from './wifi-zones-page.module.scss';

export interface IStateProps {
    selectedLayer: MapLayerType;
}

export interface IActions {
    resetState(): unknown;
}


export interface IWifiZonePageProps
    extends
    IStateProps,
    IActions,
    IWithSelectedTracker { }

/**
 * Simple button component having a '+' icon.
 */
const PlusButton = compose<IButtonProps, IButtonProps>(
    withProps<IButtonProps, IButtonProps>({
        iconLeft: <PlusIcon />,
        small: true,
        secondary: true,
    }),
)(Button);

export const WifiZonePage = ({
    trackerId,
    resetState,
}: IWifiZonePageProps) => {
    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);
    const t = getGlobalT();

    return (
        <div>
            <ConnectedWifiZonesList trackerId={trackerId!} />
        </div>
    );
};

const makeMapState = () => {
    return (state: IRootState): IStateProps => {
        return {
            selectedLayer: state.map.selectedLayer,
        };
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    resetState: () => {
        dispatch.geofenceEdit.resetState();
    },
});

export const ConnectedWifiZonePage = compose(
    withSelectedTracker,
    connect(makeMapState, mapDispatch),
)(WifiZonePage);
