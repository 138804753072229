import L, { PointExpression } from 'leaflet';
import shadowAsset from '../../../icons/trackers/tracker-icon-shadow.svg';
import { TrackerType } from '../../../trackers/interfaces';
import {
    resolveTrackerIcon,
    TrackerIcon,
} from '../../../trackers/tracker-icons';

import styles from './tracker-marker.module.scss';

const iconSize = 32;
const iconOffset: PointExpression = [iconSize / 2, iconSize / 2];
const shadowSize = 60;
const shadowOffset: PointExpression = [shadowSize / 2, shadowSize / 2];
export const createTrackerIcon = (
    icon: TrackerIcon | null,
    type: TrackerType,
    iconUrl: string | null,
    color?: string,
) => {
    return L.divIcon({
        html: `<img style="border-radius: 50%; background: ${color}; ${
            iconUrl ? null : 'padding: 5px;'
        }" src="${resolveTrackerIcon(iconUrl, icon, type)} ">`,
        iconAnchor: iconOffset,
        iconSize: [iconSize, iconSize],
        iconUrl: resolveTrackerIcon(iconUrl, icon, type),
        shadowUrl: shadowAsset,
        shadowRetinaUrl: shadowAsset,
        shadowAnchor: shadowOffset,
        shadowSize: [shadowSize, shadowSize],
        className: styles.icon,
    });
};
