import { IRootState } from 'app/store/store';
import { useParams } from 'react-router';
import {
    getRawSubscriptionVariants,
    makeGetSubscriptionOfferOptions,
    makeGetSubscriptionsOffersVariants,
} from 'app/subscription-offers/selectors/subscription-offers-selectors';
import { ITracker } from 'app/trackers/interfaces-api';
import { makeGetTrackersOnSidebar } from 'app/trackers/selectors/get-trackers-on-sidebar';
import { trackersFromApi } from 'app/trackers/selectors/selectors';
import {
    makeGetAccountOptions,
    makeGetAccountOptionsOffersForView,
} from 'app/user/selectors/account-option-selector';
import { createSelector } from 'reselect';

import { ISubscriptionSummaryData, PaymentCode } from '../interfaces';
import { mapToOrderSummary } from '../mappers/map-to-order-summary';
import { IUserChoices } from '../subscription-process.store';
import { config } from 'config';

const areTrackersFetched = (store: IRootState) => store.userTrackers.firstFetch;

export const getRawUserChoices = (store: IRootState) =>
    store.subscriptionProcess.choices;

export const makeGetSubscriptionProcessChoices = () =>
    createSelector(getRawUserChoices, (choices: IUserChoices) => choices);


export const checkIfUrlIdExistOnTrackers = (state: IRootState) => {
    const idFromUrl = window.location.pathname.split('/')[2];
    if (state.userTrackers.rawTrackers.length === 0) {
        // Don't do more logic if trackers are not fetched yet (from app.bootstrap)
        return true;
    }
    let id = state.userTrackers.activeTracker;
    if (!id) {
        id = parseInt(idFromUrl, 10)
    }
    const trackers = state.userTrackers.rawTrackers;

    const hasId = trackers.findIndex((tracker: ITracker) => tracker.id === id);
    if (hasId === -1) {
        return false;
    }
    return true;
};
export const makeCanGoToSubscriptionStep = (id: number) => {
    const getProcessChoices = makeGetSubscriptionProcessChoices();
    return createSelector(getProcessChoices, (choices): boolean => {
        choices.trackerId = id;
        return typeof choices.trackerId === 'number';
    });
};

export const makeCanGoToOptionsStep = () => {
    return createSelector(getRawSubscriptionVariants, (variants): boolean => {
        return variants.length > 0;
    });
};

export const makeCanGoToPaymentsStep = () => {
    return createSelector(getRawSubscriptionVariants, (variants): boolean => {
        return variants.length > 0;
    });
};

export const makeCanGoToHipay = () => {
    const getProcessChoices = makeGetSubscriptionProcessChoices();
    return createSelector(
        getProcessChoices,
        (choices: IUserChoices): boolean => {
            return choices.selectedPaymentCode === PaymentCode.HIPAY;
        },
    );
};

/**
 * Map state of subscription process to summary table,
 * so basically this calculates the order
 */
export const makeGetSubscriptionSummaryData = (
    choices: IUserChoices,
    state: IRootState,
) => {
    const getOffers = makeGetSubscriptionsOffersVariants();
    const getOptions = makeGetSubscriptionOfferOptions(
        choices.subscriptionCode as string,
    );
    const getAccountOptions = makeGetAccountOptions();
    const getAccountOptionsOffersForView = makeGetAccountOptionsOffersForView();

    let currentSite: string = config.WEENECT_SITE;
    if (state.user.userData) {
        currentSite = state.user.userData.site;
    }
    return createSelector(
        getOffers,
        getOptions,
        getAccountOptions,
        getAccountOptionsOffersForView,
        (
            offers,
            options,
            getAccountOptions,
            accountOptionOffers,
        ): ISubscriptionSummaryData | null =>
            mapToOrderSummary(
                choices,
                offers,
                options,
                currentSite,
                getAccountOptions,
                accountOptionOffers,
            ),
    );
};

/**
 * If user has fetched trackers and their length is 0, it means first register must follow
 */
export const makeGetIsFirstRegistration = () => {
    const getTrackers = makeGetTrackersOnSidebar(); // change to all trackers

    return createSelector(
        areTrackersFetched,
        getTrackers,
        (firstFetch, trackers) => firstFetch && trackers.length === 0,
    );
};

export const makeGetRegisteredTracker = () => {
    return createSelector(
        getRawUserChoices,
        trackersFromApi,
        (choices: IUserChoices, trackers: ITracker[]) => {
            const { id } = useParams();
            let idFormated = choices.trackerId || parseInt(id!, 10);
            if (idFormated) {
                const registeredTracker = trackers.find(
                    (tracker) => tracker.id === idFormated,
                );
                return registeredTracker || null;
            }
            return null;
        },
    );
};
