import React, { HTMLAttributes } from 'react';

import { getGlobalT } from '../../../i18n/setup-translations';
import { ParamBlock } from '../../common/param-block/param-block.component';
import { IRegistrationDetails } from '../interfaces';

import styles from './registration-details.module.scss';

export interface IRegistrationDetailsProps
    extends HTMLAttributes<HTMLDivElement>,
    IRegistrationDetails { }

export const RegistrationDetails = ({
    imei,
    registrationDate,
    sim,
    warranty,
}: IRegistrationDetailsProps) => {
    const t = getGlobalT();
    return (
        <div className={styles['registration-details']}>

            <ParamBlock label={t('imeiInfo:TITLE')} value={imei} />

            <ParamBlock label={t('simInfo:TITLE')} value={sim} />
            <ParamBlock
                label={t('subscriptions:TRACKER_DETAILS.REGISTRATION_DATE')}
                value={registrationDate}
            />
            <ParamBlock
                label={t('subscriptions:TRACKER_DETAILS.WARRANTY')}
                value={warranty}
            />
        </div>
    );
};
