import { Formik, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getAvailableLanguages } from '../../../i18n/available-languages';
import { Button } from '../../common/button/button.component';
import { SaveButton } from '../../common/button/save-button.component';
import { Headline } from '../../common/headline/headline.component';
import { Radio } from '../../common/radio/radio.component';
import { INotification } from '../../notifications/interfaces';
import { Dispatch, IRootState } from '../../store/store';
import { createGetUserLanguage } from '../../user/user-selectors';

import styles from './language-change.module.scss';

const languages = getAvailableLanguages();

interface IFormValues {
    language: string;
}

interface IStateProps {
    language: string;
}

interface IActions {
    updateLanguage: (lang: string) => Promise<unknown>;
    showNotification: (n: INotification) => unknown;
    clearNotifications: () => unknown;
}

interface IProps extends IStateProps, IActions {}

export const LanguageChange = ({
    language,
    updateLanguage,
    clearNotifications,
    showNotification,
}: IProps) => {
    useEffect(() => () => void clearNotifications());

    const { t } = useTranslation(['account', 'commonActions', 'forms']);

    const maybeRenderCancel = (dirty: boolean) => {
        if (dirty) {
            return (
                <Button className={styles.reset} type="reset" secondary>
                    {t('commonActions:CANCEL')}
                </Button>
            );
        }
    };

    return (
        <div>
            <Headline className={styles.headline}>
                {t('forms:LANGUAGE.LABEL')}
            </Headline>
            <Formik<IFormValues>
                onSubmit={(v) => {
                    clearNotifications();

                    updateLanguage(v.language)
                        .then((resp) => {
                            showNotification({
                                content: t('LANGUAGE.MESSAGES.UPDATE_SUCCESS'),
                                type: 'success',
                            });
                        })
                        .catch((err) => {
                            showNotification({
                                content: t(
                                    'LANGUAGE.MESSAGES.UPDATE_ERROR_UNKNOWN',
                                ),
                                type: 'error',
                            });
                        });
                }}
                initialValues={{
                    language,
                }}
                render={({
                    values,
                    handleSubmit,
                    dirty,
                    handleChange,
                    handleReset,
                    ...props
                }: FormikProps<IFormValues>) => (
                    <form onSubmit={handleSubmit} onReset={handleReset}>
                        <div>
                            {languages.map((lang) => (
                                <Radio
                                    className={styles.radio}
                                    name="language"
                                    key={lang.code}
                                    checked={values.language === lang.code}
                                    value={lang.code}
                                    onChange={handleChange}
                                >
                                    {lang.shortDisplay}
                                </Radio>
                            ))}
                        </div>
                        <SaveButton type="submit" className={styles.submit}>
                            {t('commonActions:SAVE')}
                        </SaveButton>
                        {maybeRenderCancel(dirty)}
                    </form>
                )}
            />
        </div>
    );
};

const mapState = (state: IRootState): IStateProps => {
    const getLanguage = createGetUserLanguage();
    return {
        language: getLanguage(state).toUpperCase(),
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    updateLanguage: async (lang) => {
        dispatch.account.updateLanguage(lang);
        dispatch.language.updateLanguage({
            languageCode: lang,
        });
    },
    showNotification: dispatch.notifications.showNotification,
    clearNotifications: dispatch.notifications.clearNotifications,
});

export const ConnectedLanguageChange = compose<IProps, {}>(
    connect(mapState, mapDispatch),
)(LanguageChange);
