import cx from 'classnames';
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../../utils/with-selected-tracker';

import { ReactComponent as PowerIcon } from '../../../icons/thunder.svg';
import { IRootState } from '../../../store/store';
import { getPassedLiveModeSeconds } from '../../services/tracker-live-mode.service';
import { makeGetIsLiveModeEnabled } from '../../tracker-live-mode.store';
import styles from './live-mode-progress.module.scss';

interface IOuterProps {
    enabled: boolean;
    /**
     * If percent not provided, it will show undefined progress
     */
    percent?: number;
}


interface IProps extends IOuterProps { }

const progressLoaderClasses = {
    root: '',
    trail: styles['progress-trail'],
    path: styles['progress-fill'],
    text: '',
    background: '',
};

export const LiveModeProgress = ({ enabled, percent }: IProps) => {
    const getInternalPercentage = () => {
        if (percent && enabled) {
            return percent;
        }

        if (enabled && !percent) {
            return 100; // Fill the border
        }

        return 0; // Hide loader
    };

    return (
        <div className={styles.wrapper}>
            <CircularProgressbar
                strokeWidth={enabled ? 6 : 0}
                classes={progressLoaderClasses}
                className={styles.progress}
                value={getInternalPercentage()}
            />
            <PowerIcon
                className={cx(styles.icon, {
                    [styles.blinking]: enabled && !percent,
                    [styles.active]: enabled,
                })}
            />
        </div>
    );
};

export const ConnectedLiveModeProgress = compose<
    IProps & IWithSelectedTracker,
    {}
>(
    withSelectedTracker,
    connect((state: IRootState, props: IWithSelectedTracker) => {
        const getLiveModeEnabled = makeGetIsLiveModeEnabled(props.trackerId!);
        const secondsPassed = getPassedLiveModeSeconds(props.trackerId!) || 0;
        const ttl = 60 * 5;
        return {
            enabled: getLiveModeEnabled(state),
            percent: ((ttl - secondsPassed) * 100) / ttl,
        };
    }),
)(LiveModeProgress);
