import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../user-payments.module.scss';
import { Typography } from 'app/common/typography/typography.component';
import { ReactComponent as DashIcon } from 'app/icons/dash.svg'
import { ConnectedChangePaymentMeanPage } from 'app/user-subscriptions/change-payment-mean/change-payment-mean.component';

interface IOuterProps {
    goBack: () => void
}

export const ChangePaymentMean = ({
    goBack
}: IOuterProps) => {
    const { t } = useTranslation('commonActions');
    return (
        <>
            <div className={styles['webkit-inline']} onClick={goBack} style={{ cursor: 'pointer' }}>
                <DashIcon className={styles.dashIcon} />
                <Typography bold noMargin>{t('BACK')}</Typography>
            </div>
            <ConnectedChangePaymentMeanPage isFromAccountPage={true} />
        </>
    )

};


