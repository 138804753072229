import { apiService } from '../api/api.service';
import { IAccountBody, IAccountOptions, IUser } from './interfaces-api';

const url = '/myuser';

export const fetchUser = (): Promise<IUser> => {
    return apiService.fetch(url, {}).then((resp) => {
        if (resp.status !== 200) {
            console.error('myuser request failed');
            throw Error('Request failed');
            // TODO: Handle it
        } else {
            return resp.json();
        }
    });
};

export const putAccountOption = (
    body: IAccountBody,
): Promise<IAccountOptions[]> => {
    return apiService
        .fetch('/myaccountoptions', {
            method: 'PUT',
            body: JSON.stringify(body),
        })
        .then((resp) => {
            return resp.json();
        });
};
