import { getGlobalT } from '../i18n/setup-translations';

export const getTimePeriodFromMonths = (numberOfMonths: number): string => {
    const t = getGlobalT();

    switch (numberOfMonths) {
        case 1:
            return t('periods:BASE.MONTH');
        case 12:
            return t('periods:BASE.YEAR');
        case 24:
            return t('periods:ALT.YEAR', { count: 2 });
        case 36:
            return t('periods:ALT.YEAR', { count: 3 });
        default:
            return '-';
    }
};
