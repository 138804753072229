import { PremiumPopup } from 'app/common/popup-premium';
import { makeGetTrackerNotificationsSettings } from 'app/trackers/selectors/get-tracker-notifications-settings';
import { IRawUserData } from 'app/user/interfaces';
import { IAccountOptionOffers, IAccountOptions, IUser } from 'app/user/interfaces-api';
import { getUserData, makeGetAccountOptions, makeGetAccountOptionsOffers } from 'app/user/selectors/account-option-selector';
import { putAccountOption } from 'app/user/user.service';
import { FormikProps, withFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { compose } from 'recompose';
import { formatPrice, normalizePriceAmount } from 'utils/normalize-price-amount';

import { IWithFormUtils, withFormUtils } from '../../../utils/form-helpers';
import { Button } from '../../common/button/button.component';
import { Input } from '../../common/input/input.component';
import { Typography } from '../../common/typography/typography.component';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as TickIcon } from '../../icons/tick.svg';
import { Dispatch, IRootState } from '../../store/store';
import { SubscriptionOptionType } from '../../user-subscriptions/interfaces-api';
import { INotificationSettingsDTO } from '../interfaces';
import styles from './notification-contacts.module.scss';
import { getSmsNumbersFormValidationSchema } from './sms-numbers-form-validation.schema';

interface IOuterProps {
    existingSmsPhones: string[];
    hasSmsOptionActivated: boolean;
    activeSubscriptionId: number | null;
    onDeleted(num: string): unknown;
    onAdded(num: string[]): unknown;
}

interface IStateProps {
    accountOption: IAccountOptions[] | undefined;
    accountOffert: IAccountOptionOffers[] | undefined;
    user: IRawUserData | null;
    currentUserSite: string | undefined;
}

interface IFormValues {
    numbers: string[];
    isBeingModified: boolean[];
}

interface IActions {
    setUserData: (user: IRawUserData) => void;
    toggleOption: (
        option: SubscriptionOptionType,
        subscriptionId: number | null,
    ) => any;
}

interface IProps
    extends IOuterProps,
    IActions,
    FormikProps<IFormValues>,
    IWithFormUtils { }

/**
 * This component shares functionality with mails form.
 * It can possibly reuse logic with it.
 */
const SmsPhonesForm = ({
    activeSubscriptionId,
    getErrorMessage,
    setUserData,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    user,
    accountOffert,
    accountOption,
    hasSmsOptionActivated,
    initialValues,
    isFieldValid,
    onDeleted,
    setFieldValue,
    toggleOption,
    values,
}: IProps & IStateProps) => {
    const { t } = useTranslation('trackerSettings') as any;
    const [openPopup, setOpenPopup] = useState(false)
    const [modified, setModified] = useState<boolean[]>(values.isBeingModified);
    const onNewPhoneRequested = () => {
        const numberOfPhoneNumbers = values.numbers.length;

        setFieldValue(`numbers[${numberOfPhoneNumbers}]`, '');
        onPhoneModification(values.numbers.length);
    };
    const preventSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };
    const submitChange = (index: number) => {
        handleSubmit();
        if (getErrorMessage(`numbers[${index}]`) === undefined) {
            values.isBeingModified[index] = false;
            setModified([...values.isBeingModified]);
        }
    };
    const cancelPhoneModification = (index: number) => {
        if (!initialValues.numbers[index]) {
            const v = [...values.numbers];
            v.pop();

            setFieldValue('numbers', v);
        } else {
            values.isBeingModified[index] = false;
            setModified([...values.isBeingModified]);
        }
    };
    const onPhoneModification = (index: number) => {
        values.isBeingModified[index] = true;
        setModified([...values.isBeingModified]);
    };

    const onPhoneDeleteRequested = (index: number) => {
        /**
         * Check if deleting locally created phone without saving
         */
        if (!initialValues.numbers[index]) {
            const v = [...values.numbers];
            v.pop();

            setFieldValue('numbers', v);
        } else {
            onDeleted(initialValues.numbers[index]);
        }

    };
    const resolveActivateSmsOption = () => {
        if (
            accountOption &&
            accountOption[0].is_running) {
            return toggleOption(
                SubscriptionOptionType.SMS,
                activeSubscriptionId,
            )
        }
        setOpenPopup(true)
    }

    const mayRenderPremiumPopup = () => {
        if (openPopup && accountOffert) {
            return (
                <PremiumPopup
                    price={formatPrice(
                        normalizePriceAmount(
                            accountOffert[0].price_offer.fr.amount,
                        ),
                        accountOffert[0].price_offer.fr.currency,
                    )}
                    onClosed={() => setOpenPopup(false)}
                    onClick={() => {
                        if (user) {
                            putAccountOption({
                                account_options: [
                                    SubscriptionOptionType.PREMIUMPACK,
                                ],
                                site: user.site,
                            }).then((res: IAccountOptions[]) => {
                                Object.assign(user.account_options, res);
                                setOpenPopup(false);
                                return setUserData(user);
                            });
                        }
                    }}
                />
            );
        }
    };

    /**
     * Render only if previously open one is valid and saved
     */
    const maybeRenderNewPhoneButton = () => {
        if (initialValues.numbers.length === values.numbers.length) {
            return (
                <Button
                    iconLeft={<PlusIcon />}
                    onClick={onNewPhoneRequested}
                    secondary
                    small
                    className={styles.button}
                >
                    {t('CONTACTS.ADD_PHONE_BUTTON')}
                </Button>
            );
        }
    };
    const maybeRenderModified = (index: number) => {
        if (modified[index] === true) {
            return (
                <div className={styles['add-container']}>
                    <Input
                        placeholder={t('CONTACTS.PHONE.PLACEHOLDER')}
                        isModified
                        small
                        value={values.numbers[index]}
                        name={`numbers[${index}]`}
                        onChange={(phoneValue) =>
                            setFieldValue(`numbers[${index}]`, phoneValue)
                        }
                        onBlur={handleBlur}
                        // @ts-ignore
                        isPhone
                        isValid={isFieldValid(`numbers[${index}]`)}
                    />
                    <div className={styles['add-buttons-container']}>
                        <Button
                            onClick={() => submitChange(index)}
                            className={styles['submit-button']}
                            title="Submit"
                        >
                            {t('commonActions:VALIDATE')}
                            <TickIcon className={styles['form-svg']} />
                        </Button>
                        <Button
                            onClick={() => cancelPhoneModification(index)}
                            className={styles['cancel-button']}
                            title="cancel"
                        >
                            <CrossIcon className={styles['form-svg']} />
                        </Button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles['form-option-container']}>
                    <Typography className={styles['registered-info']} noMargin>
                        <PhoneIcon className={styles['phone-picto']} />
                        {values.numbers[index]}
                    </Typography>
                    <div className={styles['form-buttons-container']}>
                        <Button
                            onClick={() => onPhoneModification(index)}
                            className={styles['edit-button']}
                            title="Edit"
                        >
                            <EditIcon className={styles['form-svg']} />
                        </Button>
                        <Button
                            onClick={() => onPhoneDeleteRequested(index)}
                            className={styles['delete-button']}
                            title="Delete"
                        >
                            <DeleteIcon className={styles['form-svg']} />
                        </Button>
                    </div>
                </div>
            );
        }
    };
    const maybeRenderOptionWarning = () => {
        if (hasSmsOptionActivated === false) {
            return (
                <div className={styles.warning}>
                    <span>{t('NOTIFICATION_SETTINGS.WARNING.SMS.TEXT')}</span>
                    <span
                        className={styles.link}
                        onClick={resolveActivateSmsOption}
                    >
                        {t('NOTIFICATION_SETTINGS.WARNING.SMS.LINK')}
                    </span>
                </div >
            );
        }
    };

    return (
        <form onSubmit={preventSubmit} onReset={handleReset}>
            {values.numbers.map((num: string, index) =>
                maybeRenderModified(index),
            )}
            {maybeRenderNewPhoneButton()}
            {mayRenderPremiumPopup()}
            {maybeRenderOptionWarning()}
        </form>
    );
};
const mapState = (state: IRootState, props: IOuterProps): IStateProps => {
    const getAccountOptionOffer = makeGetAccountOptionsOffers();
    const getAccountOption = makeGetAccountOptions();
    const user = getUserData(state);
    return {
        currentUserSite: state.user?.userData?.site,
        accountOption: getAccountOption(state),
        accountOffert: getAccountOptionOffer(state),
        user,
    };
};

const mapDispatch = (dispatch: Dispatch, props: IProps): IActions => ({
    setUserData: async (data: IRawUserData) => {
        dispatch.user.setUserData(data);
        return data;
    },
    toggleOption: (
        option: SubscriptionOptionType,
        activeSubscriptionId: number | null,
    ) => {
        if (activeSubscriptionId) {
            return dispatch.userSubscriptions.toggleSubscriptionOption({
                option,
                subscriptionId: activeSubscriptionId,
            });
        }
    },
});

export const ControlledSmsPhonesForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IFormValues>({
        enableReinitialize: true,
        handleSubmit(values, formikBag) {
            formikBag.props.onAdded(values.numbers);
        },
        mapPropsToValues(props) {
            return {
                numbers: props.existingSmsPhones,
                isBeingModified: props.existingSmsPhones.map(() => false),
            };
        },
        validationSchema: () => getSmsNumbersFormValidationSchema(),
    }),
    withFormUtils,
    connect(mapState, mapDispatch),
)(SmsPhonesForm);
