import { LatLng } from 'leaflet';
import get from 'lodash/get';

import { ModelConfig } from '@rematch/core';

import { makeGetMapViewportCenter } from '../map/selectors';
import { makeGetSelectedTrackerModel } from '../trackers/selectors/get-selected-tracker-model';
import { IGraphhoperProps } from './geofence-geocode/geofence-geocode.component';
import { makeGetAllUserGeofences } from './selectors/geofences.selectors';
import { IGeofenceDTO, updateGeofence } from './services/geofences.service';

export interface IGeofenceEditStore {
    position: {
        lat: number;
        lng: number;
    };
    radius: number;
    geofenceId: number | null;
    duringNewCreating: boolean;
    addressesChoice: IGraphhoperProps[];
}

const defaultState: IGeofenceEditStore = {
    position: {
        lat: 0,
        lng: 0,
    },
    radius: 100,
    duringNewCreating: false,
    geofenceId: null,
    addressesChoice: [],
};

export const geofenceEditStore: ModelConfig<IGeofenceEditStore> = {
    state: defaultState,
    effects: (dispatch: any) => ({
        async centerOnActiveTracker(payload, models) {
            const getActiveTracker = makeGetSelectedTrackerModel();

            const activeTracker = getActiveTracker(models);
            const lat = get(activeTracker, 'position[0].latitude');
            const lng = get(activeTracker, 'position[0].longitude');

            this.updatePosition({
                lat,
                lng,
            });
        },
        async centerOnMapViewport(payload, models) {
            const getMapCenter = makeGetMapViewportCenter();
            const center = getMapCenter(models);

            if (Array.isArray(center)) {
                this.updatePosition({
                    lat: center[0],
                    lng: center[1],
                });

                return;
            }

            this.updatePosition({
                lat: center.lat,
                lng: center.lng,
            });
        },
        async centerOnMapClick(payload: LatLng, models) {
            this.updatePosition({
                lat: payload.lat,
                lng: payload.lng,
            });
        },
        async startCreatingNewGeofence() {
            this.resetState();
            await dispatch.geofenceEdit.centerOnMapViewport();
            this.setDuringEdit(true);
        },
        cancelCreatingNewGeofence() {
            this.resetState();
            this.setDuringEdit(false);
        },
        async setGeofenceDuringEdit(id: number | null, models) {
            this.resetState();

            if (id === null) {
                this.setEditableItd(null);
                return;
            }

            const getAllGeofences = makeGetAllUserGeofences();
            const allGeofences = getAllGeofences(models);
            const editableGeofence = allGeofences.find(
                (g) => g.geofenceId === id,
            )!;

            this.setEditableItd(id);
            this.updatePosition(editableGeofence.position);
            this.updateRadius(editableGeofence.radius);
            dispatch.map.setPosition(editableGeofence.position);
        },
        async updateGeofenceSettings(payload: {
            dto: IGeofenceDTO;
            trackerId: number;
            geofenceId: number;
        }) {
            return updateGeofence(
                payload.trackerId,
                payload.geofenceId,
                payload.dto,
            ).then((newData) => {
                dispatch.geofences._setGeofence({
                    trackerId: payload.trackerId,
                    geofence: newData,
                });
                dispatch.geofenceEdit.setGeofenceDuringEdit(null);
            });
        },
    }),
    reducers: {
        updateRadius: (state, radius) => ({ ...state, radius }),
        updatePosition: (state, position: { lat: number; lng: number }) => ({
            ...state,
            position,
        }),
        updateAddressesChoice: (
            state,
            addressesChoice: IGraphhoperProps[],
        ) => ({
            ...state,
            addressesChoice,
        }),
        setDuringEdit: (state, isEditing: boolean) => ({
            ...state,
            duringNewCreating: isEditing,
        }),
        setEditableItd: (state, geofenceId: number | null) => ({
            ...state,
            geofenceId,
        }),
        resetState: (state) => ({ ...defaultState }),
    },
};
