import { ModelConfig } from '@rematch/core';

interface IHipayStepStore {
    canSubmit: boolean;
    isLoading: boolean;
}
export const hipayStepStore: ModelConfig<IHipayStepStore> = {
    state: {
        canSubmit: false,
        isLoading: false,
    },
    effects: (dispatch) => ({
        async setIsValid(isValid: boolean) {
            this.setCanSubmit(isValid);
        },
        async setIsLoading(isLoading: boolean) {
            this.setLoading(isLoading);
        },
    }),
    reducers: {
        setCanSubmit: (
            state: IHipayStepStore,
            isValid: boolean,
        ): IHipayStepStore => ({
            ...state,
            canSubmit: isValid,
        }),
        setLoading: (
            state: IHipayStepStore,
            loading: boolean,
        ): IHipayStepStore => ({
            ...state,
            isLoading: loading,
        }),
    },
};
