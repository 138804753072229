export enum GeofenceMode {
    ENTER_NOTIFICATIONS = 1,
    EXIT_NOTIFICATIONS = 2,
    ALL_NOTIFICATIONS = 3,
}

export interface IRawGeofence {
    id: number;
    number: number;
    name: string;
    address: string;
    active: boolean;
    tracker_id: number;
    latitude: number;
    longitude: number;
    mode: GeofenceMode;
    distance: number;
    is_outside: boolean;
}

export interface IRawGeofenceResponse {
    items: IRawGeofence[];
    total: number;
}
