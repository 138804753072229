import React, { useEffect, useState } from 'react';

import { Grid } from '../../layout/grid/grid.component';
import { BackButton } from '../button/back-button.component';
import { NextButton } from '../button/next-button.component';
import { Headline } from '../headline/headline.component';
import { Typography } from '../typography/typography.component';
import styles from './cgu.module.scss';

interface IProps {
    displayCGU: (value: boolean) => any;
    onValidate?: any | null;
    setDisableValidateButton?: any | null;
    content?: string;
}

export const BiogaranCGU = ({
    displayCGU,
    setDisableValidateButton,
}: IProps) => {
    const [canGoForward, setCanGoForward] = useState(true);

    const setValidateCGU = () => {
        displayCGU(false);
        if (setDisableValidateButton) {
            setDisableValidateButton(true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.onscroll = () => {
            // logic we use to detect if the user scrolled to the bottom and reached the last element to let him go forward
            const box = document.getElementById('elemToReachToGoForward');
            if (!box) {
                return;
            }
            const rect = box!.getBoundingClientRect();
            const isInViewport =
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <=
                    (window.innerHeight ||
                        document.documentElement.clientHeight) &&
                rect.right <=
                    (window.innerWidth || document.documentElement.clientWidth);
            if (isInViewport) {
                setCanGoForward(false);
            }
        };
    });

    const buttonComponent = (
        <Grid className={styles.buttons}>
            <NextButton
                type="submit"
                disabled={canGoForward}
                className={styles.marginBottom}
                onClick={() => {
                    setValidateCGU();
                }}
            >
                Lu et accepté
            </NextButton>
            <BackButton onClick={() => displayCGU(false)} />
        </Grid>
    );

    return (
        <>
            <div className={styles.container}>
                <Headline className={styles.text}>
                    Conditions générales d’utilisation du service
                </Headline>
                <div className={styles.text}>
                    <Typography>
                        Les présentes conditions générales de vente (« CGV »)
                        sont conclues entre la société BIOGARAN, société par
                        actions simplifiée (SAS) au capital de 4.000.000,00
                        euros, inscrite au Registre du Commerce et des Sociétés
                        de Nanterre sous le numéro 405 113 598 dont le siège
                        social est situé 15 boulevard Charles de Gaulle, 92700
                        Colombes (« BIOGARAN ») et toute personne physique
                        civilement majeure, non commerçante souhaitant utiliser
                        le service Bioggy ((le(s) « Client(s) »). BIOGARAN
                        commercialise le service Bioggy (le « Service BIOGGY »
                        ou les « Services »), permettant aux parents de suivre
                        la bonne observance de prise de traitement de leurs
                        enfants et de les géolocaliser. Le service BIOGGY est
                        composé de deux dispositifs : Pour l’enfant, un boitier
                        muni d’un boitier GPS, lui permettant de recevoir des
                        rappels de la prise de son traitement et de pouvoir
                        rester en contact avec ses parents lors de ses
                        déplacements (le « Boitier BIOGGY ») ; Pour l’adulte,
                        une application mobile – téléchargeable depuis une place
                        de marché virtuelle – lui permettant de programmer des
                        rappels de prise de traitement, de suivre les
                        notifications envoyées par l’enfant et de le
                        géolocaliser (« l’Application ») Par extension, le
                        Service BIOGGY (ou les « Services ») désigne tout
                        logiciel, outil, programme informatique ou, de façon
                        générale, tout service de la société de l’information au
                        sens de la directive 2000/31/CE (« directive sur le
                        commerce électronique ») auxquels l’Application ou le
                        Boitier BIOGGY accède pour fournir des informations au
                        Client.
                    </Typography>
                    <Typography bold>IMPORTANT</Typography>

                    <Typography className={styles.text}>
                        Afin d’utiliser le Service BIOGGY, le Client doit
                        obligatoirement :
                    </Typography>
                    <ul>
                        <li>
                            Télécharger l’Application, dont les conditions
                            générales d’utilisation sont accessibles ici ;
                        </li>
                        <li>
                            Se créer un compte personnel sur l’Application ;{' '}
                        </li>
                        <li>
                            Acheter un Boitier BIOGGY dans les pharmacies
                            notamment référencées sur le site www.bioggy.fr et
                            dont les conditions générales de ventes sont
                            accessibles ici ;{' '}
                        </li>
                        <li>Activer le Boitier BIOGGY sur l’Application ;</li>
                        <li>
                            Souscrire une formule d’abonnement au Service Bioggy
                            (le ou les « Abonnement(s) ») via l’Application dont
                            les conditions générales sont décrites dans le
                            présent document.
                        </li>
                    </ul>
                    <Typography>
                        Les CGV sont accessibles à titre informatif sur le site
                        www.bioggy.fr (le « Site »). Elles sont susceptibles
                        d’être mises à jour à tout moment, notamment afin de
                        prendre en compte toute évolution légale, réglementaire,
                        jurisprudentielle et/ou technique. La version qui
                        prévaut est celle qui est accessible en ligne à la date
                        de l’achat. Tout achat d’un Abonnement implique
                        l’adhésion sans réserve aux présentes CGV qui prévalent
                        sur toutes les autres conditions, à l’exception de
                        celles qui ont été acceptées expressément par BIOGARAN.
                        Le Site est édité par la société HAREAU, SAS inscrite au
                        RCS de Paris sous le numéro 538 863 937 et dont le siège
                        est sis 101 rue de Sèvres 75272 Paris cedex 06,
                        représentée par Ferdinand Rousseau, pris en sa qualité
                        de Président.
                    </Typography>
                    <Typography bold>
                        La SAS Hareau directrice de la publication peut être
                        contactée aux coordonnées suivantes :
                    </Typography>
                    <Typography bold>
                        Téléphone : 01 84 80 77 74 <br />
                        E-mail : ferdinand@weenect.com <br />
                        Courrier : 53 boulevard du Général Martial Valin, 75015
                        Paris
                    </Typography>
                    <Typography bold>
                        Le Site est hébergé par l’hébergeur suivant :
                    </Typography>
                    <Typography>
                        Amazon Web Services, Inc.
                        <br />
                        AMAZON E.U SARL
                        <br />5 rue Plaetis L 2338 Luxembourg
                        <br />
                        aws.amazon.com
                        <br />
                        eu-privacy@amazon.fr
                        <br />0 805 10 14 20
                    </Typography>
                    <Typography bold>ACCESSIBILITE DU SITE</Typography>
                    <Typography>
                        Le Site est par principe accessible 24h/24, 7j/7, sauf
                        interruption, programmée ou non, par BIOGARAN ou ses
                        prestataires, pour les besoins de sa maintenance ou cas
                        de force majeure. BIOGARAN ne saurait être tenue
                        responsable de tout dommage, quelle qu'en soit la
                        nature, résultant d'une indisponibilité du Site.
                        BIOGARAN ne garantit pas que le Site sera exempt
                        d’anomalies, erreurs ou bugs, ni que le Site
                        fonctionnera sans panne ni interruption. Elle peut à cet
                        égard déterminer librement toute période
                        d’indisponibilité du Site ou de son contenu. Par
                        ailleurs, le Site peut contenir des liens et des visuels
                        publicitaires, promotionnels, commerciaux ou de
                        parrainage vers des sites Internet de tiers ou
                        prestataires de services indépendants. BIOGARAN n’est
                        pas responsable des contenus desdits tiers ou sites
                        tiers, n’en garantit ni l’exactitude, ni la validité, ni
                        la qualité, ni l’intégrité, en particulier dans le cadre
                        des transactions effectuées directement entre le Client
                        même et un tiers revendeur ou prestataire indépendant de
                        la Société. De même, BIOGARAN ne saurait assumer les
                        coûts de connexion, et de manière générale, tout coût de
                        communication induit par l’accès au Site et par son
                        utilisation par le Client.
                    </Typography>
                    <Typography bold>ABONNEMENTS</Typography>
                    <Typography bold>
                        Il y a trois formules d’Abonnement aux Services BIOGGY :
                    </Typography>
                    <ul>
                        <li>Abonnement d’une durée d’un (1) mois</li>
                        <li>Abonnement d’une durée d’un (1) an </li>
                        <li>Abonnement d’une durée de deux (2) ans</li>
                    </ul>
                    <Typography>
                        Chaque Abonnement est renouvelable par tacite
                        reconduction pour une durée identique sauf résiliation
                        conformément aux termes des présentes CGV, et est
                        payable en début de période en un seul paiement pour
                        l’ensemble de la période d’abonnement jusqu’à son
                        expiration ou renouvellement.
                    </Typography>
                    <Typography bold>
                        Chaque formule d’Abonnement comprend :
                    </Typography>
                    <ul>
                        <li>
                            {' '}
                            l’accès à l’espace Client permettant le paramétrage
                            et la gestion des alertes et du compte Client ;
                        </li>
                        <li>
                            trois méga octets de transfert de données par mois.
                            Au-delà, la carte SIM sera suspendue pour le restant
                            du mois et réactivée le mois suivant ;
                        </li>
                        <li>
                            un forfait d’appel d’urgence de dix (10) minutes par
                            mois.
                        </li>
                    </ul>

                    <Typography bold>CONDITIONS FINANCIERES </Typography>
                    <Typography>
                        Les Abonnements sont fournis aux tarifs en vigueur sur
                        le Site lors de la souscription. Ces prix ne comprennent
                        pas les coûts liés aux équipements et aux communications
                        électroniques, nécessaires à la souscription et à
                        l'utilisation du Service BIOGGY, lesdits coûts restant à
                        la charge du Client.{' '}
                    </Typography>
                    <Typography>
                        BIOGARAN se réserve la possibilité de modifier le prix
                        des Abonnements, frais et taxes ou d’ajouter de
                        nouvelles formules. En cas de changement des conditions
                        financières, BIOGARAN s’engage à notifier le Client par
                        email suffisamment à l’avance avant la date d’entrée en
                        vigueur prévue afin qu’il puisse décider de renouveler
                        ou non son Abonnement au nouveau tarif.{' '}
                    </Typography>
                    <Typography>
                        Le Client accepte de recevoir ses factures par voie
                        dématérialisée dans son espace personnel sur
                        l’Application. Il pourra recevoir ses factures en format
                        papier sur demande.{' '}
                    </Typography>
                    <Typography>
                        Les modes de paiements autorisés pour le règlement des
                        Abonnements sont les suivants :{' '}
                    </Typography>
                    <ul>
                        <li>
                            {' '}
                            Carte bancaire : Carte bleue, Visa, MasterCard,
                            American Express, Eurocard et Visa Internationale{' '}
                        </li>
                        <li>Prélèvement automatique SEPA </li>
                    </ul>
                    <Typography>
                        En cas de paiement infructueux, et sauf cas de vol ou
                        d’opposition, le prestataire de service de paiement
                        pourra être amené à représenter le paiement jusqu’à sa
                        régularisation, à défaut de régularisation l’abonnement
                        sera suspendu.{' '}
                    </Typography>
                    <Typography>
                        En tout état de cause, BIOGARAN se réserve le droit de
                        refuser l’accès au Service BIOGGY ou de résilier le
                        Service en cours, en cas de paiement non honoré.{' '}
                    </Typography>
                    <Typography>
                        Il est précisé que lorsque le Client communique sur le
                        Site ses coordonnées bancaires ou de carte bancaire,
                        celles-ci ne sont pas traitées ou conservées par
                        BIOGARAN mais par le prestataire de paiement.
                    </Typography>
                    <Typography bold>
                        ENTREE EN VIGUEUR - RETRACTATION{' '}
                    </Typography>
                    <Typography bold>Entrée en vigueur</Typography>
                    <Typography>
                        L’Abonnement prend effet à compter de la validation du
                        paiement effectué par le Client.{' '}
                    </Typography>
                    <Typography bold>Satisfait ou remboursé</Typography>
                    <Typography>
                        Le Client bénéficie - à compter de l’entrée en vigueur
                        de son Abonnement - d’une période de trente (30) jours
                        pour utiliser le Service BIOGGY et demander le
                        remboursement de son Abonnement s’il n’est pas
                        satisfait, sous réserve des dispositions suivantes.
                        Aucun remboursement ne pourra être sollicité si la
                        demande est faite au-delà de cette période de trente
                        (30) jours, ou le Client a utilisé le Service BIOGGY
                        pendant une durée de trois (3) jours cumulés ou plus. Le
                        Service BIOGGY est considéré comme étant utilisé à
                        partir du moment où le Boitier BIOGGY est allumé et
                        connecté au Service BIOGGY.
                    </Typography>
                    <Typography>
                        Absence du droit de rétractation de 14 jours pour les
                        contrats conclus à distance.{' '}
                    </Typography>
                    <Typography>
                        Conformément aux dispositions des articles L.221-9,
                        L.221-13, L.221-26 et L.221-28, 13° du Code de la
                        consommation, le Client reconnait et accepte
                        expressément :
                    </Typography>
                    <ul>
                        <li>
                            Que l’Abonnement consiste en un contenu numérique
                            indépendant de tout support matériel et que sa
                            fourniture commencera immédiatement après le
                            paiement de l’Abonnement par l’Utilisateur ;
                        </li>
                        <li>
                            Renoncer à exercer son droit de rétractation lié à
                            la souscription de l’Abonnement tel que découlant de
                            l’article L. 221-18 du Code de la consommation.
                        </li>
                    </ul>
                    <Typography>
                        {' '}
                        En conséquence, aucune demande de rétractation ne sera
                        recevable pour la durée souscrite de l’Abonnement.
                    </Typography>
                    <Typography bold>RESPONSABILITE DE BIOGARAN</Typography>
                    <Typography>
                        BIOGARAN fournit le Service et les Abonnements dans le
                        cadre d’une obligation de moyens et sa responsabilité ne
                        pourra être engagée qu’en cas de manquement causant un
                        préjudice direct et certain au Client.
                    </Typography>
                    <Typography>
                        En particulier, BIOGARAN n’est en aucun cas responsable,
                        ce que le Client accepte sans réserve :
                    </Typography>
                    <ul>
                        <li>des défaillances des équipements du Client, </li>
                        <li>
                            des interruptions, lenteurs et inaccessibilités à
                            Internet ou de tout autre problème affectant les
                            transmissions sur les réseaux de télécommunications
                            et perturbant le Service,
                        </li>
                        <li>
                            de l’inexactitude des données des Clients
                            communiquées à BIOGARAN au moment de la souscription
                            de l’Abonnement (les « Données ») ou de
                            l’incomplétude de certaines qui seraient pourtant
                            indispensables à la fourniture de l’Abonnement ;{' '}
                        </li>
                        <li>
                            de la perte, de la détérioration ou de la
                            destruction accidentelle des Données.
                        </li>
                    </ul>
                    <Typography>
                        En tout état de cause, la responsabilité de BIOGARAN au
                        titre de l’ensemble des Abonnements ne saurait excéder
                        le montant des sommes (HT) effectivement versées par le
                        Client au cours des 12 derniers mois.
                    </Typography>
                    <Typography bold>DONNEES PERSONNELLES</Typography>
                    <Typography>
                        BIOGARAN est soucieux de la protection des données
                        personnelles des Clients et s’engage à assurer le
                        meilleur niveau de protection aux Données Personnelles
                        en conformité avec la règlementation applicable en la
                        matière notamment le règlement de l'UE 2016/679
                        Règlement général sur la protection des données ("RGPD")
                        et la loi n°78-17 du 6 janvier 1978 modifiée (ensemble,
                        la "Réglementation Applicable").
                        <br />
                        Pour en savoir plus sur la façon dont les données
                        personnelles des Clients sont traitées dans le cadre de
                        la fourniture du Service BIOGGY, cliquez ici.
                    </Typography>
                    <Typography bold>PROPRIÉTÉ INTELLECTUELLE</Typography>
                    <Typography>
                        BIOGARAN est titulaire des droits lui permettant de
                        vendre les Abonnements. Toute reproduction,
                        modification, adaptation, traduction, utilisation
                        commerciale, totale ou partielle, publication concession
                        sous licence, transfert ou vente, quel que soit le
                        moyen, de tout ou partie du Site est strictement
                        interdit. BIOGARAN consent au Client une simple licence
                        temporaire d’utilisation strictement limitée aux actes
                        nécessaires pour souscrire une formule d’Abonnement.{' '}
                    </Typography>

                    <Typography bold>
                        SUSPENSION/RÉSILIATION DES ABONNEMENTS
                    </Typography>
                    <Typography>
                        Le Client peut résilier son Abonnement à l’échéance sur
                        son compte personnel dans l’Application en respectant un
                        délai de préavis d'au moins trente (30) jours avant
                        l'échéance en cours. S’agissant de l’abonnement d’un (1)
                        mois, ce délai est abaissé à quarante-huit (48) heures.
                        La résiliation sera effective à la fin de la période de
                        souscription en cours choisie par le Client. Il n’y a
                        aucun remboursement des frais engagés pour la période en
                        cours. Il est de la responsabilité du Client de résilier
                        le service payant en temps utile.
                    </Typography>
                    <Typography>
                        BIOGARAN dispose également de la faculté de résilier
                        et/ou suspendre l’Abonnement, à tout moment et de plein
                        droit dans les cas suivants :
                    </Typography>
                    <ul>
                        <li>
                            En cas de non-paiement par l’Utilisateur des sommes
                            dues au titre de l’Abonnement ;
                        </li>
                        <li>
                            En cas de perte de validité du moyen de paiement
                            utilisé dans le cadre des présentes ;{' '}
                        </li>
                        <li>
                            En cas où les Données communiquées par l’Utilisateur
                            dans le cadre de la souscription au Service sont,
                            inexactes ou invalides ;{' '}
                        </li>
                        <li>
                            Plus généralement, en cas de violation par
                            l’Utilisateur des présentes Conditions Générales de
                            Vente.{' '}
                        </li>
                    </ul>
                    <Typography>
                        {' '}
                        La résiliation entrainera la désactivation immédiate des
                        droits accordés au Client dans le cadre du Service
                        BIOGGY sans droit à indemnité ni à remboursement.
                    </Typography>
                    <Typography>
                        Cette résiliation sera faite sans préjudice de toute
                        action en réparation que BIOGARAN pourra engager à
                        l’encontre du Client ou de tous tiers sous sa
                        responsabilité.
                    </Typography>
                    <Typography bold>REGLEMENT AMIABLE DES LITIGES</Typography>
                    <Typography>
                        En vertu de l’article L. 612-1 du Code de la
                        consommation « tout consommateur a le droit de recourir
                        gratuitement à un médiateur de la consommation en vue de
                        la résolution amiable du litige qui l'oppose à un
                        professionnel ».
                    </Typography>
                    <Typography>
                        Les litiges entrant dans le champ d’application de
                        l’article L. 612-1 du Code de la consommation sont les
                        litiges définis à l’article L. 611-1 du Code de la
                        consommation à savoir les litiges de nature
                        contractuelle, portant sur l’exécution d’un contrat de
                        vente ou de fournitures de services, opposant un
                        consommateur à un professionnel. Le texte couvre les
                        litiges nationaux et les litiges transfrontaliers.
                    </Typography>
                    <Typography>
                        Pour toute difficulté, nous vous invitons à nous
                        contacter préalablement ou à contacter notre service
                        après-vente : support@bioggy.fr
                    </Typography>
                    <Typography>
                        Pour plus de renseignements sur la médiation, le Client
                        est invité à consulter le site internet dédié à la
                        médiation et disponible à l’adresse suivante :
                        http://www.economie.gouv.fr/mediation-conso.
                    </Typography>
                    <Typography bold>
                        LOI APPLICABLE ET JURIDICTION COMPETENTE{' '}
                    </Typography>
                    <Typography>
                        Les CGV sont régies par la loi française.
                    </Typography>
                    <Typography>
                        Tous les litiges auxquels le présent contrat pourrait
                        donner lieu, concernant tant sa validité, son
                        interprétation, son exécution, sa résiliation, leurs
                        conséquences et leurs suites seront soumis aux tribunaux
                        compétents dans les conditions de droit commun.
                    </Typography>
                    <Typography bold>DISPOSITIONS DIVERSES </Typography>
                    <Typography>
                        Le fait pour l'une des Parties de ne pas se prévaloir
                        d'un engagement par l'autre Partie à l'une des
                        obligations visées par les présentes (ou de son
                        manquement), ne saurait être interprété pour l'avenir
                        comme une renonciation à l'obligation en cause (ou à se
                        prévaloir du manquement en cause).
                    </Typography>
                    <Typography id={'elemToReachToGoForward'}>
                        Si une partie quelconque des présentes CGV s'avère
                        inapplicable au regard de la loi, toutes les autres
                        parties des CGV ne seront pas affectées et resteront en
                        vigueur.
                    </Typography>
                    <Typography>
                        BIOGARAN se réserve le droit de sous-traiter tout ou
                        partie des Services ou de céder son activité à un tiers
                        sans que cette sous-traitance ou cession nécessite
                        l’accord préalable du Client, ce que le Client reconnait
                        expressément.
                    </Typography>
                    <Typography className={styles.paddingBottom}>
                        CGV mises à jour le 1er juillet 2021.
                    </Typography>
                </div>
            </div>
            <div className={styles.buttonFixed}>{buttonComponent}</div>
        </>
    );
};
