import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate, Route, useLocation, Routes } from 'react-router';
import { compose, mapProps } from 'recompose';
import { Link } from 'react-router-dom';

import { config as appConfig } from '../../../config';
import { BackLink } from '../../common/back-link/back-link.component';
import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { Spinner } from '../../common/loading-spinner/loadingSpinner.component';
import { Modal } from '../../common/modal/modal.component';
import { Steps } from '../../common/steps/steps.component';
import { Typography } from '../../common/typography/typography.component';
import { Grid } from '../../layout/grid/grid.component';
import { SimpleHeaderWithLogout } from '../../layout/simple-header/simple-header.component';
import { INotification } from '../../notifications/interfaces';
import { trackersRoute } from '../../routing/routes';
import { Dispatch, IRootState } from '../../store/store';
import { SubscriptionVariantCode } from '../../subscription-offers/interfaces';
import { ITracker } from '../../trackers/interfaces-api';
import { makeGetIsSingleTrackerWithDroppedSubscription } from '../../trackers/selectors/get-is-single-tracker-with-dropped-subscription';
import { makeGetIsSingleTrackerWithoutSubscription } from '../../trackers/selectors/get-is-single-tracker-without-subscription';
import { makeGetSubscriptionIdFromTracker } from '../../trackers/selectors/get-subscription-id-from-tracker';
import { ISubscriptionDetails } from '../../user-subscriptions/interfaces';
import {
    IRawUserSubscription,
    SubscriptionOptionType,
} from '../../user-subscriptions/interfaces-api';
import { makeGetSubscriptionDetailsFromTracker } from '../../user-subscriptions/selectors/user-subscriptions.selectors';
import { IFormValues } from '../components/register-tracker-form/register-tracker-form.component';
import { PaymentRedirectionUrls } from '../payment-redirection-routes';
import {
    checkIfUrlIdExistOnTrackers,
    makeCanGoToHipay,
    makeCanGoToOptionsStep,
    makeCanGoToPaymentsStep,
    makeCanGoToSubscriptionStep,
    makeGetIsFirstRegistration,
    makeGetSubscriptionProcessChoices,
} from '../selectors/subscription-process-selectors';
import { redirectToPaymentGate } from '../services/order-subscription.service';
import { ConnectedChooseOptionsStep } from '../steps/choose-options-step/choose-options-step.component';
import { ConnectedChooseVariantPage } from '../steps/choose-variant-step/choose-variant-step.component';
import { ConnectedGiftCardStep } from '../steps/gift-card-step/gift-card-step.component';
import { HipayStep } from '../steps/hipay-step/hipay-step.component';
import { IProcessPayment } from '../steps/hipay-step/interfaces';
import { ConnectedPaymentsStep } from '../steps/payment-step/payments-step.component';
import { RegisterTrackerStep } from '../steps/register-tracker-step/register-tracker-step.component';
import { IUserChoices } from '../subscription-process.store';

import styles from './subscription-process-page.module.scss';

interface IActions {
    showNotification: (n: INotification) => unknown;
    clearNotifications: () => unknown;
    fetchSingleTracker: (trackerId: number) => Promise<any>;
    fetchSubscriptionDetails: (subscriptionId: number) => Promise<any>;
    getOfferts: (site: string) => unknown;
    onPromoCodeRemove: () => void;
    requestRegisterTracker: (name: string, imei: string) => Promise<any>;
    processPayNow: (paymentPayload: IProcessPayment) => Promise<any>;
    setSelectedOptions: (options: SubscriptionOptionType[]) => Promise<unknown>;
    setSelectedAccountOptions: (
        options: SubscriptionOptionType[],
    ) => Promise<unknown>;
    setSelectedSubscriptionCode: (
        code: SubscriptionVariantCode,
    ) => Promise<unknown>;
    submitSubscriptionRequest: () => Promise<unknown>;
    submitSubscriptionRequestHipay: () => any;
    setSelectedTracker: (id: number) => unknown;
}

interface IMappedProps {
    onChange(code: SubscriptionOptionType): unknown;
}
interface IState {
    selectedOptionsCodes: SubscriptionOptionType[];
    selectedAccountOptionsCodes: SubscriptionOptionType[];
    rawSubscription: IRawUserSubscription;
    activeSubscription: ISubscriptionDetails | null;
    activeTracker: number | null;
    canGoToHipayStep: boolean;
    canGoToOptionsStep: boolean;
    canGoToPaymentsStep: boolean;
    canGoToSubscriptionStep: boolean;
    isFirstRegistration: boolean;
    isResolvingSubscription: boolean;
    isSingleTrackerWithDroppedSub: boolean;
    isSingleTrackerWithoutSub: boolean;
    rawTrackers: ITracker[];
    subscriptionId: number;
    trackerId: number | null;
    urlIdExistOnTrackers: boolean;
    userChoices: IUserChoices;
    currentUserSite: string;
    customerHasGiftCard: boolean;
    isMobileRedirect: boolean;
}

export interface INewTrackerPageProps extends IActions, IState, IMappedProps {}

export const RoutesSteps = {
    REGISTER: '/add/register',
    OFFERS: `/add/subscription/`,
    GIFTCARD: `/add/gift-card/`,
    OPTIONS: `/add/options/`,
    PAYMENT: `/add/payment/`,
    HIPAY: `/add/credit-card/`,
};
const hipayFormID = 'hipay-hostedfields-form';

enum SubscriptionSteps {
    REGISTER = 0,
    OFFERS = 0,
    OPTIONS = 1,
    PAYMENT = 2,
}

const resolveStepFromRoute = () => {
    const param = useParams();
    const location = useLocation();
    switch (location.pathname) {
        case RoutesSteps.REGISTER:
            return SubscriptionSteps.REGISTER;
        case RoutesSteps.OFFERS + param.id:
            return SubscriptionSteps.OFFERS;
        case RoutesSteps.OPTIONS + param.id:
            return SubscriptionSteps.OPTIONS;
        case RoutesSteps.PAYMENT + param.id:
            return SubscriptionSteps.PAYMENT;
        default:
            return SubscriptionSteps.REGISTER;
    }
};

export const SubscriptionProcessPage = ({
    activeSubscription,
    activeTracker,
    canGoToHipayStep,
    canGoToOptionsStep,
    canGoToPaymentsStep,
    canGoToSubscriptionStep,
    onChange,
    isFirstRegistration,
    isResolvingSubscription,
    isSingleTrackerWithDroppedSub,
    isSingleTrackerWithoutSub,
    subscriptionId,
    trackerId,
    urlIdExistOnTrackers,
    userChoices,
    customerHasGiftCard,
    isMobileRedirect,
    selectedOptionsCodes,
    selectedAccountOptionsCodes,
    currentUserSite,
    requestRegisterTracker,
    clearNotifications,
    getOfferts,
    setSelectedTracker,
    processPayNow,
    setSelectedOptions,
    setSelectedSubscriptionCode,
    onPromoCodeRemove,
    showNotification,
    submitSubscriptionRequestHipay,
    submitSubscriptionRequest,
    fetchSingleTracker,
    fetchSubscriptionDetails,
}: INewTrackerPageProps) => {
    const { t } = useTranslation([
        'subscriptionProcess',
        'subscriptionPayment',
        'registerTracker',
    ]);
    const tOptionModal = useTranslation('subscriptionOptionsSelection').t;
    const [displayCGU, setDisplayCGU] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [confirmationModalOpenForId, setConfirmationModalOpen] =
        useState<SubscriptionOptionType | null>(null);
    const match = useLocation();
    const param = useParams();
    const history = useNavigate();
    const id = param.id;
    const formatedId = parseInt(id!, 10);
    useEffect(() => {
        // set the tracker id in the store while we have it on url to keep it if something wrong happend (ex:payment error)
        if (formatedId) {
            setSelectedTracker(formatedId);
        }
        if (
            (!isResolvingSubscription && // When resolving subscription, keep customer on this component.
                activeSubscription &&
                activeSubscription.isActive !== false) ||
            activeSubscription?.isCancelScheduled
        ) {
            history(`/trackers/${formatedId}/manage`);
        }
    }, [formatedId]);

    useEffect(() => {
        // remove added promocode when user return on previous steps
        if (match.pathname.includes('add/subscription')) {
            onPromoCodeRemove();
        }
    }, [match.pathname]);

    useEffect(() => {
        // scrolling top each time the nav step change
        window.scrollTo(0, 0);
    }, [location.pathname]);
    useEffect(() => {
        if (match.pathname === '/add/register') {
            if (activeTracker) {
                history(`/add/subscription/${formatedId}`);
            }
        }
        if (currentUserSite === appConfig.WEENECT_USA_SITE) {
            getOfferts(appConfig.WEENECT_USA_API_CALL_PARAM);
            return;
        }
        getOfferts(appConfig.WEENECT_API_CALL_PARAM);
    }, [urlIdExistOnTrackers]);

    const onTrackerRegistered = (formData: IFormValues) => {
        requestRegisterTracker(formData.name, formData.imei)
            .then((data: ITracker) => {
                clearNotifications();
                setSelectedTracker(data.id);
                fetchSingleTracker(data.id).then(() => {
                    if (data.subscription && data.subscription.status === "active") {
                        if (isMobileRedirect) {
                           window.location.href = 'weenect://app/home';
                        } else {
                            fetchSubscriptionDetails(data.subscription.id).then(() => {
                                history(`/trackers/${data.id}`);
                            });
                        }
                    } else {
                        history(`/add/subscription/${data.id}`);
                    }
                });
            })
            .catch((err) => {
                /**
                 * Currently display error from server
                 */
                clearNotifications();
                showNotification({
                    content: t('registerTracker:ERRORS.UNKNOWN'),
                    type: 'error',
                });
            });
    };

    const onSubscriptionChosen = (code: SubscriptionVariantCode) => {
        clearNotifications();

        setSelectedSubscriptionCode(code).then(() => {
            if (customerHasGiftCard) {
                return history(RoutesSteps.GIFTCARD + formatedId);
            }
            history(RoutesSteps.OPTIONS + formatedId);
        });
    };
    const resolveRoute = () => {
        switch (match.pathname) {
            case RoutesSteps.OFFERS + param.id:
                if (canGoToSubscriptionStep && urlIdExistOnTrackers) {
                    return (
                        <ConnectedChooseVariantPage
                            onCheckboxChanged={onCheckboxChanged}
                            renderConfirmationModal={
                                maybeRenderConfirmationModal
                            }
                            onSubmit={onSubscriptionChosen}
                        />
                    );
                }
            case RoutesSteps.GIFTCARD + param.id:
                if (customerHasGiftCard && urlIdExistOnTrackers)
                    return <ConnectedGiftCardStep />;
            case RoutesSteps.HIPAY + param.id:
                if (canGoToHipayStep && urlIdExistOnTrackers) {
                    return (
                        <HipayStep
                            hipayFormID={hipayFormID}
                            subscriptionId={subscriptionId}
                            trackerId={trackerId}
                            title={t('subscriptionPayment:SUBMIT_BUTTON')}
                            onSubmit={processPayNow}
                            onSuccess={(onSubmitResponse: any) => {
                                // Redirect to external web page.
                                if (onSubmitResponse.redirect_url) {
                                    return redirectToPaymentGate(
                                        onSubmitResponse.redirect_url,
                                    );
                                }
                                // Redirect to onboarding
                                return history(
                                    trackersRoute +
                                        '/' +
                                        userChoices.trackerId +
                                        PaymentRedirectionUrls.SUBSCRIPTION_SUCCESS,
                                );
                            }}
                            onFail={() => {
                                // For Weenect trackers, only display an error notification
                                showNotification({
                                    content: t('ERRORS.UNKNOWN'),
                                    type: 'error',
                                });
                            }}
                        />
                    );
                }
            case RoutesSteps.OPTIONS + param.id:
                if (
                    canGoToOptionsStep &&
                    urlIdExistOnTrackers &&
                    userChoices.subscriptionCode
                ) {
                    return (
                        <ConnectedChooseOptionsStep
                            renderConfirmationModal={
                                maybeRenderConfirmationModal
                            }
                            onCheckboxChanged={onCheckboxChanged}
                            subscriptionCode={userChoices.subscriptionCode}
                            onSubmit={onOptionsChosen}
                        />
                    );
                }
            case RoutesSteps.PAYMENT + param.id:
                if (canGoToPaymentsStep) {
                    return (
                        <ConnectedPaymentsStep
                            hipayFormID={hipayFormID}
                            userChoices={userChoices}
                            subscriptionId={subscriptionId}
                            renderConfirmationModal={
                                maybeRenderConfirmationModal
                            }
                            onCheckboxChanged={onCheckboxChanged}
                            overideSelected={true}
                            displayCGU={displayCGU}
                            setDisplayCGU={setDisplayCGU}
                            createSubscription={submitSubscriptionRequestHipay}
                            onSubmit={onPaymentSubmitted}
                        />
                    );
                }
            case RoutesSteps.REGISTER:
                return <RegisterTrackerStep onSubmit={onTrackerRegistered} />;
            default:
                <ConnectedChooseVariantPage
                    onCheckboxChanged={onCheckboxChanged}
                    renderConfirmationModal={maybeRenderConfirmationModal}
                    onSubmit={onSubscriptionChosen}
                />;
        }
    };

    const onOptionsChosen = (options: SubscriptionOptionType[]) => {
        clearNotifications();
        setSelectedOptions(options).then(() => {
            history(RoutesSteps.PAYMENT + formatedId);
        });
    };

    const onPaymentSubmitted = () => {
        clearNotifications();
        submitSubscriptionRequest();
    };

    const onGoBack = () => {
        clearNotifications();
    };

    const maybeRenderGoBackToTrackers = () => {
        if (
            isFirstRegistration ||
            isSingleTrackerWithoutSub ||
            isSingleTrackerWithDroppedSub
        ) {
            return null;
        }

        return (
            <Link to={id ? trackersRoute + `/${id}` : trackersRoute}>
                <BackLink collapseOnMobile black onClick={onGoBack}>
                    {t('RETURN_TO_TRACKERS')}
                </BackLink>
            </Link>
        );
    };

    const onCheckboxChanged = (code: SubscriptionOptionType) => {
        if (
            selectedOptionsCodes.includes(code) ||
            selectedAccountOptionsCodes.includes(code)
        ) {
            setConfirmationModalOpen(code);
        } else {
            onChange(code);
        }
    };
    const maybeRenderConfirmationModal = () => {
        const renderOptionText = () => {
            if (confirmationModalOpenForId === 'warranty') {
                return (
                    <Typography>
                        {tOptionModal('CANCEL_WARNING_WARRANTY')}
                    </Typography>
                );
            }
            return null;
        };

        if (confirmationModalOpenForId) {
            return (
                <Modal onClosed={() => setConfirmationModalOpen(null)}>
                    <Headline level={2}>
                        {tOptionModal('CANCEL_WARNING_MODAL_HEADLINE')}
                    </Headline>
                    {renderOptionText()}
                    <Grid className={styles.gridModal}>
                        <Button
                            className={styles.button}
                            onClick={() => setConfirmationModalOpen(null)}
                            secondary
                        >
                            {t('commonActions:NO')}
                        </Button>
                        <Button
                            className={styles.button}
                            onClick={() => {
                                onChange(confirmationModalOpenForId);
                                setConfirmationModalOpen(null);
                            }}
                        >
                            {t('commonActions:YES')}
                        </Button>
                    </Grid>
                </Modal>
            );
        }
    };
    return (
        <>
            <div className={styles['page-container']}>
                {isMobileRedirect ? null : (
                    <SimpleHeaderWithLogout
                        leftSlot={maybeRenderGoBackToTrackers()}
                        className={styles.header}
                    />
                )}

                {!displayCGU &&
                match.pathname !== `/add/credit-card/${param.id}` &&
                match.pathname !== RoutesSteps.REGISTER ? (
                    <Steps
                        className={styles.steps}
                        activeIndex={resolveStepFromRoute()}
                        steps={t('STEPS', { returnObjects: true })}
                        hidden={
                            match.pathname === RoutesSteps.HIPAY ||
                            match.pathname.includes(RoutesSteps.GIFTCARD)
                        }
                    />
                ) : null}
                {resolveRoute()}
            </div>
        </>
    );
};

const makeMapState = (state: IRootState): IState => {
    const idUrl = window.location.pathname.split('/')[3];
    const parsedId = parseInt(idUrl, 10);
    const getSubscriptionDetailsFromTracker =
        makeGetSubscriptionDetailsFromTracker(parsedId);
    const subscriptionDetails = getSubscriptionDetailsFromTracker(
        state,
    ) as ISubscriptionDetails;
    const canGoToSubscriptionStep = makeCanGoToSubscriptionStep(parsedId);
    const canGoToOptionsStep = makeCanGoToOptionsStep();
    const canGoToPaymentsStep = makeCanGoToPaymentsStep();
    const canGoToHipayStep = makeCanGoToHipay();
    const getUserChoices = makeGetSubscriptionProcessChoices();
    const getIsFirstRegistration = makeGetIsFirstRegistration();
    const getIsSingleWithoutSub = makeGetIsSingleTrackerWithoutSubscription();
    const getIsSingleAndDropped =
        makeGetIsSingleTrackerWithDroppedSubscription();
    const userChoices = getUserChoices(state);

    const getSubscription = makeGetSubscriptionIdFromTracker(parsedId);
    const getSubscriptionByTrackerId = makeGetSubscriptionDetailsFromTracker(
        parsedId,
        false,
    );
    let currentSite = appConfig.WEENECT_SITE;
    if (state.user.userData !== null) {
        currentSite = state.user.userData.site;
    }

    return {
        isMobileRedirect: state.layout.isMobileRedirect,
        selectedOptionsCodes: getUserChoices(state).selectedOptions,
        selectedAccountOptionsCodes:
            getUserChoices(state).selectedAccountOptions,
        activeSubscription: subscriptionDetails,
        activeTracker: state.userTrackers.activeTracker,
        canGoToHipayStep: canGoToHipayStep(state),
        canGoToOptionsStep: canGoToOptionsStep(state),
        canGoToPaymentsStep: canGoToPaymentsStep(state),
        canGoToSubscriptionStep: canGoToSubscriptionStep(state),
        isFirstRegistration: getIsFirstRegistration(state),
        isResolvingSubscription:
            state.subscriptionProcess.isResolvingSubscription,
        isSingleTrackerWithDroppedSub: getIsSingleAndDropped(state),
        isSingleTrackerWithoutSub: getIsSingleWithoutSub(state),
        rawTrackers: state.userTrackers.rawTrackers,
        subscriptionId: getSubscription(state),

        trackerId: parsedId,
        urlIdExistOnTrackers: checkIfUrlIdExistOnTrackers(state),
        currentUserSite: currentSite,
        userChoices: getUserChoices(state),
        rawSubscription: getSubscriptionByTrackerId(
            state,
        ) as IRawUserSubscription,
        customerHasGiftCard: state.subscriptionProcess.customerHasGiftCard,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    onPromoCodeRemove: dispatch.subscriptionProcess.resetPromoCode as any,
    getOfferts: (site) =>
        dispatch.subscriptionOffers.fetchSubscriptionVariants(site),
    clearNotifications: dispatch.notifications.clearNotifications,
    requestRegisterTracker: async (name, imei) =>
        dispatch.newTracker.registerTracker({ name, imei }),
    showNotification: dispatch.notifications.showNotification,
    setSelectedOptions: dispatch.subscriptionProcess.setSelectedOptions as any,
    processPayNow: async (payload) =>
        dispatch.subscriptionProcess.processPayNow(payload),
    setSelectedAccountOptions: dispatch.subscriptionProcess
        .setSelectedAccountOptions as any,
    setSelectedSubscriptionCode: dispatch.subscriptionProcess
        .setSelectedSubscription as any,
    submitSubscriptionRequest: dispatch.subscriptionProcess
        .submitSubscriptionRequest as any,
    submitSubscriptionRequestHipay: dispatch.subscriptionProcess
        .submitSubscriptionRequestHipay as any,
    setSelectedTracker: dispatch.userTrackers.setActiveTracker,
    fetchSingleTracker: async (trackerId: number) =>
        dispatch.userTrackers.fetchSingleTracker({ trackerId }),
    fetchSubscriptionDetails: async (subscriptionId: number) =>
        dispatch.userSubscriptions.fetchSubscriptionDetails(subscriptionId),
});

export const ConnectedSubscriptionProcessPage = compose<
    INewTrackerPageProps,
    {}
>(
    connect(makeMapState, mapDispatch),
    mapProps(
        (props: IState & IActions): IMappedProps => ({
            ...props,
            onChange(code: SubscriptionOptionType) {
                const {
                    selectedOptionsCodes,
                    selectedAccountOptionsCodes,
                    setSelectedOptions,
                    setSelectedAccountOptions,
                } = props;

                // TODO Maybe create a stronger type for SubscriptionOptions
                if (code === SubscriptionOptionType.WARRANTY) {
                    let newOptions: SubscriptionOptionType[];
                    if (selectedOptionsCodes.includes(code)) {
                        newOptions = selectedOptionsCodes.filter(
                            (c) => c !== code,
                        );
                    } else {
                        newOptions = [...selectedOptionsCodes, code];
                    }
                    setSelectedOptions(newOptions);
                }

                // TODO Maybe create a stronger type for AccountOptions
                if (code === SubscriptionOptionType.PREMIUMPACK) {
                    let newAccountOptions: SubscriptionOptionType[];

                    if (selectedAccountOptionsCodes.includes(code)) {
                        newAccountOptions = selectedAccountOptionsCodes.filter(
                            (c) => c !== code,
                        );
                    } else {
                        newAccountOptions = [
                            ...selectedAccountOptionsCodes,
                            code,
                        ];
                    }
                    setSelectedAccountOptions(newAccountOptions);
                }
            },
        }),
    ),
)(SubscriptionProcessPage);
