import { ModelConfig } from '@rematch/core';

import { makeGetEventsOnMap } from './selectors';

/**
 * Responsible only on "playing" feature, which is showing position paths
 * in time
 */
interface IHistoryPositionPlayingStore {
    running: boolean;
    /**
     * Iterate over events, keep track on current one.
     * Events are stored in historyStore and their length will
     * change depending on tracker and time period.
     */
    currentEventIndex: number;
    /**
     * If playing mode is on, but was paused
     */
    paused: boolean;
}

let interval: any; // number

const timeInterval = 1000; // Milliseconds

/**
 * How long will be "the snake tail". Includes the tracker icon
 * as the last element
 */
export const numberOfElementsToShow = 11;

export const historyPositionPlayingStore: ModelConfig<
    IHistoryPositionPlayingStore
> = {
    state: {
        currentEventIndex: 0,
        running: false,
        paused: false,
    },
    effects: (dispatch: any) => {
        const setPositionOfMap = dispatch.map.setPosition;

        return {
            start(payload: { trackerId: number }) {
                this.setPlayingOn();
                this.setPaused(false);

                dispatch.history.turnOffMapEvents();

                this._incrementPositionIndex({
                    trackerId: payload.trackerId,
                });

                interval = setInterval(() => {
                    this._incrementPositionIndex({
                        trackerId: payload.trackerId,
                    });
                }, timeInterval);
            },
            _incrementPositionIndex(payload: { trackerId: number }, model) {
                const getMapEvents = makeGetEventsOnMap(payload.trackerId);
                const mapEvents = getMapEvents(model);
                const maxLength = mapEvents.length;

                const currentIndex =
                    model.historyPositionPlaying.currentEventIndex;

                if (
                    maxLength < numberOfElementsToShow &&
                    currentIndex < maxLength
                ) {
                    this.incrementIndex();
                    return;
                }

                // Stop animation when we reach last position fetched from activity endpoint.
                if (currentIndex >= maxLength) {
                    this.stop();

                    return;
                }

                setPositionOfMap([
                    mapEvents[currentIndex].latitude,
                    mapEvents[currentIndex].longitude,
                ]);

                this.incrementIndex();
            },
            pause() {
                clearInterval(interval);
                this.setPaused(true);
            },
            stop() {
                clearInterval(interval);

                // Reset state
                this.setPlayingStop();
                this.setEventIndex(0);
                this.setPaused(false);
            },
        };
    },
    reducers: {
        setPlayingOn: (state) => ({ ...state, running: true }),
        setPlayingStop: (state) => ({ ...state, running: false }),
        setEventIndex: (state, index: number) => ({
            ...state,
            currentEventIndex: index,
        }),
        incrementIndex: (state) => ({
            ...state,
            currentEventIndex: state.currentEventIndex + 1,
        }),
        setPaused: (state, paused: boolean) => ({ ...state, paused }),
    },
};
