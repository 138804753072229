import { IAccountOptionOffers } from 'app/user/interfaces-api';
import { getGlobalT } from '../../../i18n/setup-translations';
import {
    formatAmountWithCurrency,
    normalizePriceAmount,
} from '../../../utils/normalize-price-amount';
import { SubscriptionOptionType } from '../../user-subscriptions/interfaces-api';
import { IOptionOffer } from '../interfaces';
import { IRawOptionOffer } from '../interfaces-api';

/**
 * Maps hard-coded API to view.
 *
 * N.B. For now, prices are only based on the FR price (and currency is €),
 * but all is ready to select price and currency depending on customer language
 * (in the list : fr - nl- de - en - es - it).
 */
export const mapRawOptionToView = (
    offer: IRawOptionOffer,
): IOptionOffer | undefined => {
    const t = getGlobalT();
    if (offer.code === SubscriptionOptionType.WARRANTY) {
        return {
            name: t('subOptions:WARRANTY.BASE'),
            features: t('subOptions:WARRANTY.ALT_FEATURES.two', {
                returnObjects: true,
            }),
            paymentPeriod: t('periods:BASE.MONTH'),
            price: formatAmountWithCurrency(
                offer.price_offer.fr.amount,
                offer.price_offer.fr.currency,
            ),
            explainer: t('subOptions:WARRANTY.EXPLAINER'),
            code: offer.code,
            rawPrice: normalizePriceAmount(offer.price_offer.fr.amount),
        };
    }
    if (offer.code === SubscriptionOptionType.PREMIUMPACK) {
        return {
            name: t('subOptions:PREMIUM.BASE'),
            features: t('subOptions:PREMIUM.FEATURES', {
                returnObjects: true,
            }),
            paymentPeriod: t('periods:BASE.MONTH'),
            price: formatAmountWithCurrency(
                offer.price_offer.fr.amount,
                offer.price_offer.fr.currency,
            ),
            explainer: '',
            code: offer.code,
            rawPrice: normalizePriceAmount(offer.price_offer.fr.amount),
        };
    }
};
