import { IRootState } from '../../store/store';

export const getNewSubscriptionOffer = (store: IRootState) =>
    store.manageSubscription.newSubscriptionOffer;

export const getNewOptions = (store: IRootState) =>
    store.manageSubscription.newOptions;

export const getCanChangePlan = (store: IRootState) =>
    store.manageSubscription.canChangePlan;
