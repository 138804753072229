import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { AccuracyRange } from '../../accuracy-map';
import { IAccuracyData } from '../../interfaces';
import { TrackerAttributeBattery } from '../tracker-attributes/tracker-attribute-battery.component';
import { TrackerAttributeGps } from '../tracker-attributes/tracker-attribute-gps.component';
import { TrackerAttributeSignal } from '../tracker-attributes/tracker-attribute-signal.component';

import styles from './tracker-attributes-list.module.scss';

export interface ITrackerAttributesListProps
    extends HTMLAttributes<HTMLDivElement> {
    gpsSignal: number;
    gpsOn: boolean;
    batteryLevel: number;
    accuracyData: IAccuracyData | null;
}

export const TrackerAttributesList = ({
    gpsOn,
    gpsSignal,
    batteryLevel,
    accuracyData,
    className,
}: ITrackerAttributesListProps) => {
    const getAccuracyWithFallback = (): IAccuracyData => {
        if (accuracyData) {
            return accuracyData;
        } else {
            return {
                accuracyRadius: 0,
                accuracyDescriptive: '-',
                accuracyFormatted: 'Invalid',
                accuracyRange: AccuracyRange.POOR,
            };
        }
    };

    return (
        <div className={cx(styles.list, className)}>
            <TrackerAttributeSignal signalStrength={gpsSignal} />
            <TrackerAttributeGps
                disabled={!gpsOn}
                level={getAccuracyWithFallback().accuracyRange}
                accuracy={getAccuracyWithFallback().accuracyRadius}
                formattedAccuracy={getAccuracyWithFallback().accuracyFormatted}
            />
            <TrackerAttributeBattery batteryLevel={batteryLevel} />
        </div>
    );
};
