import React, { HTMLAttributes } from 'react';

import { InfoIcon } from '../../../common/info-icon/info-icon.component';

import { ReactComponent as GPSIcon } from '../../../icons/gps.svg';
import { AccuracyRange } from '../../accuracy-map';

import styles from './tracker-attributes.module.scss';

export interface ITrackerAttributesSignalProps
    extends HTMLAttributes<HTMLDivElement> {
    accuracy: number;
    formattedAccuracy: string;
    level: AccuracyRange;
    /**
     * Override if GPS if ofline
     */
    disabled?: boolean;
}

export const TrackerAttributeGps = ({
    accuracy,
    formattedAccuracy,
    level,
    disabled,
    ...props
}: ITrackerAttributesSignalProps) => {
    const resolveColorClass = () => {
        if (disabled) {
            return styles.bad;
        }

        switch (level) {
            case AccuracyRange.GOOD:
                return styles.good;
            case AccuracyRange.MEDIUM:
                return styles.medium;
            default:
            case AccuracyRange.POOR:
                return styles.bad;
        }
    };

    let label = formattedAccuracy;

    if (disabled) {
        label = '-';
    }

    return (
        <InfoIcon
            icon={<GPSIcon className={resolveColorClass()} />}
            label={label}
            {...props}
        />
    );
};
