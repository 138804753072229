import { ModelConfig } from '@rematch/core';

import { IRootState } from '../store/store';
import { getAnimalInfo } from './animal-info.service';
import {
    Activity,
    Gender,
    IAnimal,
    IAnimalObject,
    StepToShow,
    Sterilized,
    Weight,
} from './interfaces';

export interface IPetInfo {
    name: string;
    sex: string;
    sterilized: boolean;
    birthDate: Date;
    race: string;
    morphology: string;
    weight: number;
    behavior: string;
}

export interface IPetTrackingInfos {
    petGender: Gender | null;
    petName: string;
    petWeight: number;
    petRace: string;
    petAgeYears: number;
    petAgeMonths: number;
    petMorph: Weight | null;
    petSterilized: Sterilized | null;
    petActivity: Activity | null;
    currentStepToShow: StepToShow;
    currentPetInfo: IPetInfo | null;
    trackerId: number | null;
}

export interface IRawPetInfos {
    rawPetInfos: IAnimal | null;
}

export const animalInfoStore: ModelConfig<IRawPetInfos> = {
    state: {
        rawPetInfos: null,
    },
    effects: (dispatch: any) => ({
        async getAnimalInfo(imei: number, models: IRootState) {
            return getAnimalInfo(imei).then(async (data: IAnimalObject) => {
                dispatch.animalInfo.setAnimalInfo(data.items[0]);
            });
        },
    }),

    reducers: {
        setAnimalInfo: (
            state: IRawPetInfos,
            animalInfo: IAnimal,
        ): IRawPetInfos => ({
            ...state,
            rawPetInfos: animalInfo,
        }),
    },
};
