import moment from 'moment';
import { getGlobalT } from '../../../i18n/setup-translations';

import { formatAmountWithCurrency } from '../../../utils/normalize-price-amount';
import { ISubscriptionOptionData } from '../interfaces';
import {
    IRawUserSubscription,
    IRawUserSubscriptionOption,
    SubscriptionOptionType,
} from '../interfaces-api';

export const mapSubscriptionToOptions = (
    subscription: IRawUserSubscription,
): ISubscriptionOptionData[] => {
    const t = getGlobalT();

    const resolveOptionName = (option: IRawUserSubscriptionOption) => {
        switch (option.code) {
            case SubscriptionOptionType.PREMIUMPACK: {
                return t('subOptions:MAP.BASE');
            }
            case SubscriptionOptionType.WARRANTY: {
                return t('subOptions:WARRANTY.BASE');
            }
            case SubscriptionOptionType.SMS: {
                return t('subOptions:SMS_PACK.BASE');
            }
        }
    };

    return subscription.options.map((option) => ({
        activated: option.activated,
        code: option.code,
        id: option.id,
        price: formatAmountWithCurrency(option.amount, option.currency),
        name: resolveOptionName(option),
        expirationDate: moment(option.next_charge_at).format('DD MMMM YYYY'),
        renewalDate: moment(option.next_charge_at).format('DD MMMM YYYY'),
    }));
};
