import { ModelConfig } from '@rematch/core';
import { IPopinAlert } from './interface';
import { fetchPopinAlerts } from './popin-alert.service';

export interface IPopinAlertStore {
    PopinAlerts: IPopinAlert[];
}

export const popinAlertStore: ModelConfig<IPopinAlertStore> = {
    state: {
        PopinAlerts: [],
    },
    reducers: {
        setPopinAlerts: (
            state: IPopinAlertStore,
            popinAlerts: IPopinAlert[],
        ): IPopinAlertStore => ({ ...state, PopinAlerts: popinAlerts }),
    },
    effects: (dispatch: any) => ({
        async fetchPopinAlerts(payload, models) {
            const response = await fetchPopinAlerts();

            // resp can be null
            if (response) {
                return this.setPopinAlerts(response.items);
            }
        },
        async emptyPopinAlerts(payload, models) {
            this.setPopinAlerts([]);
        },
    }),
};
