import { IRootState } from 'app/store/store';
import { verifyIfUserHasPaymentIssue } from 'app/user/selectors/account-option-selector';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    NavLink,
    Route,
    Routes,
    useLocation,
} from 'react-router-dom';
import { compose } from 'recompose';
import { ReactComponent as NotificationWarningIcon } from '../../../../images/warning_icon.svg';


import { useIsPhone } from '../../../../utils/media-queries';

import { InnerNavigation } from '../../../common/inner-navigation/inner-navigation.component';
import { MenuItem } from '../../../common/menu/menu-item.component';

import { ReactComponent as TriangleArrowIcon } from '../../../icons/triangle-arrow.svg';
import { getLinks } from './account-links';

import styles from './account-navigation.module.scss';

interface IStateProp {
    hasPaymentIssue?: boolean
}



export const AccountNavigation = ({
    hasPaymentIssue,
}: IStateProp) => {
    let location = useLocation();
    const phone = useIsPhone();
    const [mobileOpen, setMobileOpen] = useState(false);
    useEffect(() => {
        setMobileOpen(false);
    }, [location.pathname]);
    if (!phone) {
        return (
            <InnerNavigation>
                {getLinks().map((link, i) => (
                    <NavLink
                        key={link.label + i}
                        to={link.path}
                        className={cx(styles.link, link.path === useLocation().pathname ? styles.active : '')}
                    >
                        <MenuItem
                            className={styles.menuItem}
                            icon={<link.icon className={styles.icon} />}
                            label={link.label}
                        />
                        {link.path === '/account/payments' && hasPaymentIssue ? <NotificationWarningIcon className={styles.IconWarning} /> : null}
                    </NavLink>
                ))}
            </InnerNavigation>
        );
    }

    const maybeRenderMobileContent = () => {
        if (mobileOpen) {
            return (
                <div className={styles.mobileContentWrapper}>
                    <InnerNavigation>
                        {getLinks().map((link, i) => (
                            <NavLink
                                key={link.label + i}
                                to={link.path}
                                className={styles.link}
                            >
                                <MenuItem
                                    className={styles.menuItem}
                                    icon={<link.icon className={styles.icon} />}
                                    label={link.label}
                                />
                                {link.path === '/account/payments' && hasPaymentIssue ? <NotificationWarningIcon className={styles.IconWarning} /> : null}
                            </NavLink>
                        ))}
                    </InnerNavigation>
                </div>
            );
        }
    };

    return (
        <div className={styles.mobileWrapper}>
            <div
                className={styles.mobilePanelWrapper}
                onClick={() => setMobileOpen((state) => !state)}
                role="button"
            >
                <Routes>
                    {getLinks().map((link) => (
                        <Route
                            key={link.path}
                            path={link.path}
                            element={
                                <MenuItem
                                    className={cx(
                                        styles.menuitem,
                                        styles.active,
                                    )}
                                    icon={<link.icon className={styles.icon} />}
                                    label={link.label}
                                />

                            }
                        />
                    ))}
                </Routes>
                <TriangleArrowIcon
                    className={cx(styles.mobileDashIcon, {
                        [styles.iconRotated]: mobileOpen,
                    })}
                />
            </div>
            {maybeRenderMobileContent()}
        </div>
    );
};

const makeMapState = (
    state: IRootState,
): any => {

    const hasPaymentIssue = verifyIfUserHasPaymentIssue()
    return {
        hasPaymentIssue: hasPaymentIssue(state),
    };
};


export const AccountNavigationWithRouter = compose<IStateProp, any>(
    connect(makeMapState, null),
)(AccountNavigation);
