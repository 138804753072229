import { meters2ScreenPixels } from 'google-map-react';
import { Icon, LatLngLiteral, LeafletMouseEvent } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { Circle, Marker } from 'react-leaflet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import wifiZoneIcon from '../../icons/wifi-marker-icon.svg';
import { MapLayerType } from '../../map/interfaces';
import { IRootState } from '../../store/store';
import { ITracker } from '../../trackers/interfaces-api';
import styles from './wifi-zones-map-marker.module.scss';

interface IStateProps {
    selectedLayer: MapLayerType;
    zoom: number;
    trackerId: number | null;
    rawTrackers: ITracker[];
}
interface IWifiZoneMapMarkerProps {
    id: number;
    editable: boolean;
    display: boolean;
    position: {
        lat: number;
        lng: number;
    };
    radius: number;
    onPositionUpdated?: (pos: LatLngLiteral) => any;
}
/**
 * Fields required for google-map-react integration
 */
interface IGoogleMapReactProps {
    lat?: number | undefined;
    lng?: number | undefined;
}

interface IProps
    extends IWifiZoneMapMarkerProps,
    IGoogleMapReactProps,
    IStateProps { }

const icon = new Icon({
    iconSize: [18, 18],
    iconUrl: wifiZoneIcon,
});

/**
 * Later make this with draggable radius editing
 */
export const WifiZoneMapMarker = ({
    editable,
    trackerId,
    position,
    radius,
    display,
    selectedLayer,
    zoom,
    onPositionUpdated,
}: IProps) => {
    const color = '#77B9DF';
    const [localPosition, setPosition] = useState(position);
    const { lat, lng } = position;
    useEffect(() => {
        setPosition({
            lat,
            lng,
        });
    }, [lat, lng]);
    const renderLeafletWifiZoneMarker = () => {
        if (!display) {
            return <></>;
        }
        return (
            <Marker
                //@ts-ignore
                onDrag={(e: LeafletMouseEvent) => {
                    setPosition(e.latlng);
                    if (onPositionUpdated) {
                        onPositionUpdated(e.latlng);
                    }
                }}
                position={localPosition}
                icon={icon}
                zIndex={10}
                draggable={editable}
                pane="wifiZones-icons"
            >
                <Circle
                    fillColor={color}
                    fillOpacity={0.4}
                    color={color}
                    center={localPosition}
                    radius={radius}
                    weight={2}
                    pane="wifiZones-radius"
                />
            </Marker>
        );
    };

    const renderGoogleMapWifiZoneMarker = () => {
        if (!display) {
            return <></>;
        }
        const { w, h } = meters2ScreenPixels(
            radius * 2, // size in meters. Google requires a diameter to work (as opposed to leaflet) so we have to multiply it by 2
            { lat, lng }, // marker coords
            zoom, // map zoom
        );
        return (
            <div
                style={{
                    width: w,
                    height: h,
                    // https://caniuse.com/css-rrggbbaa
                    // 33 correspond to the hexadecimal notation of a 0.2 opacity.
                    backgroundColor: color,
                    opacity: 0.4,
                    border: '3px solid #77B9DF100',
                    zIndex: 0,
                }}
                className={styles.wifiZone}
            >
                {' '}
                <div className={styles['wifiZone-center']}>
                    <img src={wifiZoneIcon} />
                </div>
            </div>
        );
    };

    // When displaying leaflet layers
    if (
        selectedLayer === MapLayerType.GRAY ||
        selectedLayer === MapLayerType.SATELLITE ||
        selectedLayer === MapLayerType.STREET
    ) {
        return trackerId ? renderLeafletWifiZoneMarker() : null;
    }

    // When displaying google-map layer.
    return trackerId ? renderGoogleMapWifiZoneMarker() : null;
};

const mapState = (state: IRootState): IStateProps => ({
    trackerId: state.userTrackers.activeTracker,
    rawTrackers: state.userTrackers.rawTrackers,
    selectedLayer: state.map.selectedLayer,
    zoom: state.map.zoom,
});

export const ConnectedWifiZoneMapMarker = compose<
    IProps,
    IWifiZoneMapMarkerProps & IGoogleMapReactProps
>(connect(mapState, null))(WifiZoneMapMarker);
