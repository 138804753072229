import { getGlobalT } from '../../../i18n/setup-translations';
import { SubscriptionStatus } from '../interfaces-api';

export const getFormattedSubscriptionStatus = (status: SubscriptionStatus) => {
    const t = getGlobalT();

    switch (status) {
        case 'active':
            return t('subscriptions:STATUS:ACTIVE');
        case 'canceled':
            return t('subscriptions:STATUS:CANCELED');
        case 'new':
            return t('subscriptions:STATUS:NEW');
        default:
            return '-';
    }
};
