import { createSelector } from 'reselect';
import { trackersFromApi } from './selectors';

/**
 * Return the list of retailer_ids extracted from the customer trackers.
 */
export const makeGetAllTrackersRetailerIds = () =>
    createSelector(
        trackersFromApi,
        (trackers): number[] => trackers.map((t) => t.retailer_id),
    );
