import { createSelector } from 'reselect';

import { ITracker } from '../interfaces-api';
import { trackersFromApi } from './selectors';

export const makeGetTrackerModel = (trackerId: number) => {
    return createSelector(
        trackersFromApi,
        (trackers: ITracker[]): ITracker | undefined =>
            trackers.find((tracker) => trackerId === tracker.id),
    );
};
