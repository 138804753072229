import {getGlobalT} from "../../../i18n/setup-translations";

export const getCatBreedList = () => {
    const t = getGlobalT();
    return [
        {
            label: t("breed:CAT:ABYSSINIAN"),
            value: "Abyssinian"
        },
        {
            label: t("breed:CAT:AMERICAN_WIREHAIR"),
            value: "American Wirehair"
        },
        {
            label: t("breed:CAT:AMERICAN_BOBTAIL"),
            value: "American Bobtail"
        },
        {
            label: t("breed:CAT:AMERICAN_CURL"),
            value: "American Curl"
        },
        {
            label: t("breed:CAT:AMERICAN_SHORTHAIR"),
            value: "American Shorthair"
        },
        {
            label: t("breed:CAT:TURKISH_ANGORA"),
            value: "Turkish Angora"
        },
        {
            label: t("breed:CAT:BALINESE"),
            value: "Balinese"
        },
        {
            label: t("breed:CAT:BENGAL"),
            value: "Bengal"
        },
        {
            label: t("breed:CAT:RUSSIAN_BLUE"),
            value: "Russian Blue"
        },
        {
            label: t("breed:CAT:JAPANESE_BOBTAIL"),
            value: "Japanese Bobtail"
        },
        {
            label: t("breed:CAT:BOMBAY"),
            value: "Bombay"
        },
        {
            label: t("breed:CAT:BURMESE"),
            value: "Burmese"
        },
        {
            label: t("breed:CAT:BURMILLA"),
            value: "Burmilla"
        },
        {
            label: t("breed:CAT:CALIFORNIA_SPANGLED_CAT"),
            value: "California Spangled Cat"
        },
        {
            label: t("breed:CAT:CHARTREUX"),
            value: "Chartreux"
        },
        {
            label: t("breed:CAT:NORWEGIAN_FOREST_CAT"),
            value: "Norwegian Forest Cat"
        },
        {
            label: t("breed:CAT:BIRMAN"),
            value: "Birman"
        },
        {
            label: t("breed:CAT:SAFARI_CAT"),
            value: "Safari Cat"
        },
        {
            label: t("breed:CAT:CORNISH_REX"),
            value: "Cornish Rex"
        },
        {
            label: t("breed:CAT:CYMRIC"),
            value: "Cymric"
        },
        {
            label: t("breed:CAT:DEVON_REX"),
            value: "Devon Rex"
        },
        {
            label: t("breed:CAT:EUROPEAN"),
            value: "European"
        },
        {
            label: t("breed:CAT:EXOTIC_SHORTHAIR"),
            value: "Exotic Shorthair"
        },
        {
            label: t("breed:CAT:HAVANA"),
            value: "Havana"
        },
        {
            label: t("breed:CAT:JAVANESE"),
            value: "Javanese"
        },
        {
            label: t("breed:CAT:KORAT"),
            value: "Korat"
        },
        {
            label: t("breed:CAT:MAINE_COON"),
            value: "Maine Coon"
        },
        {
            label: t("breed:CAT:MALAYAN"),
            value: "Malayan"
        },
        {
            label: t("breed:CAT:ORIENTAL_LONGHAIR"),
            value: "Oriental Longhair"
        },
        {
            label: t("breed:CAT:MANX"),
            value: "Manx"
        },
        {
            label: t("breed:CAT:EGYPTIAN_MAU"),
            value: "Egyptian Mau"
        },
        {
            label: t("breed:CAT:MUNCHKIN"),
            value: "Munchkin"
        },
        {
            label: t("breed:CAT:NEBELUNG"),
            value: "Nebelung"
        },
        {
            label: t("breed:CAT:NORWEGIAN"),
            value: "Norwegian"
        },
        {
            label: t("breed:CAT:OCICAT"),
            value: "Ocicat"
        },
        {
            label: t("breed:CAT:ORIENTAL_SHORTHAIR"),
            value: "Oriental Shorthair"
        },
        {
            label: t("breed:CAT:PERSIAN"),
            value: "Persian"
        },
        {
            label: t("breed:CAT:RAGDOLL"),
            value: "Ragdoll"
        },
        {
            label: t("breed:CAT:BIRMAN"),
            value: "Birman"
        },
        {
            label: t("breed:CAT:SCOTTISH_FOLD"),
            value: "Scottish Fold"
        },
        {
            label: t("breed:CAT:SELKIRK"),
            value: "Selkirk"
        },
        {
            label: t("breed:CAT:SIAMESE"),
            value: "Siamese"
        },
        {
            label: t("breed:CAT:SIBERIAN"),
            value: "Siberian"
        },
        {
            label: t("breed:CAT:SINGAPURA"),
            value: "Singapura"
        },
        {
            label: t("breed:CAT:SNOWSHOE"),
            value: "Snowshoe"
        },
        {
            label: t("breed:CAT:SOMALI"),
            value: "Somali"
        },
        {
            label: t("breed:CAT:SPHYNX"),
            value: "Sphynx"
        },
        {
            label: t("breed:CAT:THAI"),
            value: "Thai"
        },
        {
            label: t("breed:CAT:CHANTILLYTIFFANY"),
            value: "Chantilly-Tiffany"
        },
        {
            label: t("breed:CAT:TONKINESE"),
            value: "Tonkinese"
        },
        {
            label: t("breed:CAT:TURKISH_VAN"),
            value: "Turkish Van"
        }
    ];
}

export const getDogBreedList = () => {
    const t = getGlobalT();
    return [
        {
            label: t("breed:DOG:AFFENPINSCHER"),
            value: "Affenpinscher"
        },
        {
            label: t("breed:DOG:AIREDALE_TERRIER"),
            value: "Airedale Terrier"
        },
        {
            label: t("breed:DOG:AKITA"),
            value: "Akita"
        },
        {
            label: t("breed:DOG:AMERICAN_STAFFORDSHIRE_TERRIER"),
            value: "American Staffordshire Terrier"
        },
        {
            label: t("breed:DOG:OLD_DANISH_POINTER"),
            value: "Old Danish Pointer"
        },
        {
            label: t("breed:DOG:PETIT_BASSET_GRIFFON_VENDEEN"),
            value: "Petit Basset Griffon Vend\u00e9en"
        },
        {
            label: t("breed:DOG:ARIEGEOIS"),
            value: "Ari\u00e9geois"
        },
        {
            label: t("breed:DOG:AZAWAKH"),
            value: "Azawakh"
        },
        {
            label: t("breed:DOG:BARBET"),
            value: "Barbet"
        },
        {
            label: t("breed:DOG:BORZOI"),
            value: "Borzoi"
        },
        {
            label: t("breed:DOG:BASENJI"),
            value: "Basenji"
        },
        {
            label: t("breed:DOG:BASSET_ARTESIEN_NORMAND"),
            value: "Basset Art\u00e9sien Normand"
        },
        {
            label: t("breed:DOG:BASSET_BLEU_DE_GASCOGNE"),
            value: "Basset Bleu de Gascogne"
        },
        {
            label: t("breed:DOG:WESTPHALIAN_DACHSBRACKE"),
            value: "Westphalian Dachsbracke"
        },
        {
            label: t("breed:DOG:ALPINE_DACHSBRACKE"),
            value: "Alpine Dachsbracke"
        },
        {
            label: t("breed:DOG:BASSET_FAUVE_DE_BRETAGNE"),
            value: "Basset Fauve de Bretagne"
        },
        {
            label: t("breed:DOG:BASSET_HOUND"),
            value: "Basset Hound"
        },
        {
            label: t("breed:DOG:BEAGLE"),
            value: "Beagle"
        },
        {
            label: t("breed:DOG:HARRIER"),
            value: "Harrier"
        },
        {
            label: t("breed:DOG:BEDLINGTON_TERRIER"),
            value: "Bedlington Terrier"
        },
        {
            label: t("breed:DOG:GERMAN_SHEPHERD"),
            value: "German Shepherd"
        },
        {
            label: t("breed:DOG:AUSTRALIAN_SHEPHERD"),
            value: "Australian Shepherd"
        },
        {
            label: t("breed:DOG:AUSTRALIAN_KELPIE"),
            value: "Australian Kelpie"
        },
        {
            label: t("breed:DOG:BELGIAN_SHEPHERD_DOG_GROENENDAEL"),
            value: "Belgian Shepherd Dog (Groenendael)"
        },
        {
            label: t("breed:DOG:BELGIAN_SHEPHERD_DOG_LAEKENOIS"),
            value: "Belgian Shepherd Dog (Laekenois)"
        },
        {
            label: t("breed:DOG:BELGIAN_SHEPHERD_DOG_MALINOIS"),
            value: "Belgian Shepherd Dog (Malinois)"
        },
        {
            label: t("breed:DOG:BELGIAN_SHEPHERD_DOG_TERVUREN"),
            value: "Belgian Shepherd Dog (Tervuren)"
        },
        {
            label: t("breed:DOG:WHITE_SWISS_SHEPHERD_DOG"),
            value: "White Swiss Shepherd Dog"
        },
        {
            label: t("breed:DOG:CATALAN_SHEEPDOG"),
            value: "Catalan Sheepdog"
        },
        {
            label: t("breed:DOG:ANATOLIAN_SHEPHERD_DOG"),
            value: "Anatolian Shepherd Dog"
        },
        {
            label: t("breed:DOG:CENTRAL_ASIAN_SHEPHERD_DOG"),
            value: "Central Asian Shepherd Dog"
        },
        {
            label: t("breed:DOG:ICELANDIC_SHEEPDOG"),
            value: "Icelandic Sheepdog"
        },
        {
            label: t("breed:DOG:BEAUCERON"),
            value: "Beauceron"
        },
        {
            label: t("breed:DOG:BERGAMASCO_SHEPHERD"),
            value: "Bergamasco Shepherd"
        },
        {
            label: t("breed:DOG:BRIARD"),
            value: "Briard"
        },
        {
            label: t("breed:DOG:CROATIAN_SHEEPDOG"),
            value: "Croatian Sheepdog"
        },
        {
            label: t("breed:DOG:KARST_SHEPHERD"),
            value: "Karst Shepherd"
        },
        {
            label: t("breed:DOG:MAREMMA_SHEEPDOG"),
            value: "Maremma Sheepdog"
        },
        {
            label: t("breed:DOG:SOUTH_RUSSIAN_OVCHARKA"),
            value: "South Russian Ovcharka"
        },
        {
            label: t("breed:DOG:PYRENEAN_SHEEPDOG_SMOOTHFACED_VARIETY"),
            value: "Pyrenean Sheepdog, Smooth-Faced Variety"
        },
        {
            label: t("breed:DOG:PYRENEAN_SHEEPDOG_LONGFACED_VARIETY"),
            value: "Pyrenean Sheepdog, Long-Faced Variety"
        },
        {
            label: t("breed:DOG:SHETLAND_SHEEPDOG"),
            value: "Shetland Sheepdog"
        },
        {
            label: t("breed:DOG:CAUCASIAN_SHEPHERD_DOG"),
            value: "Caucasian Shepherd Dog"
        },
        {
            label: t("breed:DOG:MAJORCA_SHEPHERD_DOG"),
            value: "Majorca Shepherd Dog"
        },
        {
            label: t("breed:DOG:LAPPONIAN_HERDER"),
            value: "Lapponian Herder"
        },
        {
            label: t("breed:DOG:DUTCH_SHEPHERD_SHORTHAIRED_VARIETY"),
            value: "Dutch Shepherd, Short-Haired Variety"
        },
        {
            label: t("breed:DOG:DUTCH_SHEPHERD_ROUGHHAIRED_VARIETY"),
            value: "Dutch Shepherd, Rough-Haired Variety"
        },
        {
            label: t("breed:DOG:DUTCH_SHEPHERD_LONGHAIRED_VARIETY"),
            value: "Dutch Shepherd, Long-Haired Variety"
        },
        {
            label: t("breed:DOG:PICARDY_SHEPHERD"),
            value: "Picardy Shepherd"
        },
        {
            label: t("breed:DOG:POLISH_LOWLAND_SHEEPDOG"),
            value: "Polish Lowland Sheepdog"
        },
        {
            label: t("breed:DOG:TATRA_MOUNTAIN_SHEEPDOG"),
            value: "Tatra Mountain Sheepdog"
        },
        {
            label: t("breed:DOG:PORTUGUESE_SHEEPDOG"),
            value: "Portuguese Sheepdog"
        },
        {
            label: t("breed:DOG:SLOVAK_CUVAC"),
            value: "Slovak Cuvac"
        },
        {
            label: t("breed:DOG:YUGOSLAVIAN_SHEPHERD_DOG"),
            value: "Yugoslavian Shepherd Dog"
        },
        {
            label: t("breed:DOG:BICHON_FRISE"),
            value: "Bichon Frise"
        },
        {
            label: t("breed:DOG:BOLOGNESE_DOG"),
            value: "Bolognese (dog)"
        },
        {
            label: t("breed:DOG:HAVANESE_DOG"),
            value: "Havanese (dog)"
        },
        {
            label: t("breed:DOG:MALTESE_DOG"),
            value: "Maltese (dog)"
        },
        {
            label: t("breed:DOG:BILLY_DOG"),
            value: "Billy (dog)"
        },
        {
            label: t("breed:DOG:OLD_ENGLISH_SHEEPDOG"),
            value: "Old English Sheepdog"
        },
        {
            label: t("breed:DOG:BORDER_COLLIE"),
            value: "Border Collie"
        },
        {
            label: t("breed:DOG:BORDER_TERRIER"),
            value: "Border Terrier"
        },
        {
            label: t("breed:DOG:AMERICAN_BULLDOG"),
            value: "American Bulldog"
        },
        {
            label: t("breed:DOG:ENGLISH_BULLDOG"),
            value: "English Bulldog"
        },
        {
            label: t("breed:DOG:FRENCH_BULLDOG"),
            value: "French Bulldog"
        },
        {
            label: t("breed:DOG:AUSTRALIAN_CATTLE_DOG"),
            value: "Australian Cattle Dog"
        },
        {
            label: t("breed:DOG:BERNESE_MOUNTAIN_DOG"),
            value: "Bernese Mountain Dog"
        },
        {
            label: t("breed:DOG:APPENZELLER_SENNENHUND"),
            value: "Appenzeller Sennenhund"
        },
        {
            label: t("breed:DOG:ENTLEBUCHER_MOUNTAIN_DOG"),
            value: "Entlebucher Mountain Dog"
        },
        {
            label: t("breed:DOG:ARDENNES_CATTLE_DOG"),
            value: "Ardennes Cattle Dog"
        },
        {
            label: t("breed:DOG:BOUVIER_DES_FLANDRES"),
            value: "Bouvier des Flandres"
        },
        {
            label: t("breed:DOG:BOXER_DOG"),
            value: "Boxer (dog)"
        },
        {
            label: t("breed:DOG:HANOVER_HOUND"),
            value: "Hanover Hound"
        },
        {
            label: t("breed:DOG:AUSTRIAN_BLACK_AND_TAN_HOUND"),
            value: "Austrian Black and Tan Hound"
        },
        {
            label: t("breed:DOG:STYRIAN_COARSEHAIRED_HOUND"),
            value: "Styrian Coarse-haired Hound"
        },
        {
            label: t("breed:DOG:POLISH_HOUND"),
            value: "Polish Hound"
        },
        {
            label: t("breed:DOG:TYROLEAN_HOUND"),
            value: "Tyrolean Hound"
        },
        {
            label: t("breed:DOG:GERMAN_SHORTHAIRED_POINTER"),
            value: "German Shorthaired Pointer"
        },
        {
            label: t("breed:DOG:GERMAN_WIREHAIRED_POINTER"),
            value: "German Wirehaired Pointer"
        },
        {
            label: t("breed:DOG:BRAQUE_D_AUVERGNE"),
            value: "Braque d'Auvergne"
        },
        {
            label: t("breed:DOG:BURGOS_POINTER"),
            value: "Burgos Pointer"
        },
        {
            label: t("breed:DOG:ARIEGE_POINTER"),
            value: "Ariege Pointer"
        },
        {
            label: t("breed:DOG:WEIMARANER_SHORTHAIRED"),
            value: "Weimaraner Short-haired"
        },
        {
            label: t("breed:DOG:WEIMARANER_LONGHAIRED"),
            value: "Weimaraner Long-haired"
        },
        {
            label: t("breed:DOG:BOURBONNAIS_POINTER"),
            value: "Bourbonnais Pointer"
        },
        {
            label: t("breed:DOG:DUPUY_POINTER"),
            value: "Dupuy Pointer"
        },
        {
            label: t("breed:DOG:GASCONY_POINTER"),
            value: "Gascony Pointer"
        },
        {
            label: t("breed:DOG:PYRENEAN_POINTER"),
            value: "Pyrenean Pointer"
        },
        {
            label: t("breed:DOG:SHORTHAIRED_HUNGARIAN_POINTER"),
            value: "Short-haired Hungarian Pointer"
        },
        {
            label: t("breed:DOG:WIREHAIRED_HUNGARIAN_POINTER"),
            value: "Wire-haired Hungarian Pointer"
        },
        {
            label: t("breed:DOG:ITALIAN_POINTING_DOG"),
            value: "Italian Pointing Dog"
        },
        {
            label: t("breed:DOG:PORTUGUESE_POINTER"),
            value: "Portuguese Pointer"
        },
        {
            label: t("breed:DOG:ST_GERMAIN_POINTING_DOG"),
            value: "St. Germain Pointing Dog"
        },
        {
            label: t("breed:DOG:SLOVAKIAN_WIREHAIRED_POINTER"),
            value: "Slovakian Wirehaired Pointer"
        },
        {
            label: t("breed:DOG:BRIQUET_GRIFFON_VENDEEN"),
            value: "Briquet Griffon Vend\u00e9en"
        },
        {
            label: t("breed:DOG:BROHOLMER"),
            value: "Broholmer"
        },
        {
            label: t("breed:DOG:BRUNO_JURA_HOUND_BRUNO_TYPE"),
            value: "Bruno Jura Hound (Bruno type)"
        },
        {
            label: t("breed:DOG:BRUNO_JURA_HOUND_ST_HUBERT_TYPE"),
            value: "Bruno Jura Hound (St. Hubert type)"
        },
        {
            label: t("breed:DOG:NORWEGIAN_BUHUND"),
            value: "Norwegian Buhund"
        },
        {
            label: t("breed:DOG:BULL_TERRIER_ENGLISH_BULL_TERRIER"),
            value: "Bull Terrier (English Bull Terrier)"
        },
        {
            label: t("breed:DOG:MINIATURE_BULL_TERRIER"),
            value: "Miniature Bull Terrier"
        },
        {
            label: t("breed:DOG:BULLMASTIFF"),
            value: "Bullmastiff"
        },
        {
            label: t("breed:DOG:CAIRN_TERRIER"),
            value: "Cairn Terrier"
        },
        {
            label: t("breed:DOG:CANE_CORSO"),
            value: "Cane Corso"
        },
        {
            label: t("breed:DOG:STANDARD_POODLE"),
            value: "Standard Poodle"
        },
        {
            label: t("breed:DOG:MEDIUM_POODLE"),
            value: "Medium Poodle"
        },
        {
            label: t("breed:DOG:MINIATURE_POODLE"),
            value: "Miniature Poodle"
        },
        {
            label: t("breed:DOG:TOY_POODLE"),
            value: "Toy Poodle"
        },
        {
            label: t("breed:DOG:CAO_DE_CASTRO_LABOREIRO"),
            value: "C\u00e3o de Castro Laboreiro"
        },
        {
            label: t("breed:DOG:CAO_FILA_DE_SAO_MIGUEL"),
            value: "C\u00e3o Fila de S\u00e3o Miguel"
        },
        {
            label: t("breed:DOG:PUG"),
            value: "Pug"
        },
        {
            label: t("breed:DOG:CAVALIER_KING_CHARLES_SPANIEL"),
            value: "Cavalier King Charles Spaniel"
        },
        {
            label: t("breed:DOG:CHINESE_CRESTED_DOG"),
            value: "Chinese Crested Dog"
        },
        {
            label: t("breed:DOG:CORSICAN_DOG"),
            value: "Corsican Dog"
        },
        {
            label: t("breed:DOG:BERNESE_HOUND"),
            value: "Bernese Hound"
        },
        {
            label: t("breed:DOG:ISTRIAN_COARSEHAIRED_HOUND"),
            value: "Istrian Coarse-haired Hound"
        },
        {
            label: t("breed:DOG:ISTRIAN_SHORTHAIRED_HOUND"),
            value: "Istrian Shorthaired Hound"
        },
        {
            label: t("breed:DOG:BOSNIAN_COARSEHAIRED_HOUND"),
            value: "Bosnian Coarse-haired Hound"
        },
        {
            label: t("breed:DOG:HALDEN_HOUND"),
            value: "Halden Hound"
        },
        {
            label: t("breed:DOG:HYGEN_HOUND"),
            value: "Hygen Hound"
        },
        {
            label: t("breed:DOG:POSAVAC_HOUND"),
            value: "Posavac Hound"
        },
        {
            label: t("breed:DOG:SCHWEIZER_LAUFHUND"),
            value: "Schweizer Laufhund"
        },
        {
            label: t("breed:DOG:TECKEL_DE_CHASSE_AU_LAPIN_POIL_DUR"),
            value: "Teckel de Chasse au lapin (poil dur)"
        },
        {
            label: t("breed:DOG:BALKAN_HOUND"),
            value: "Balkan Hound"
        },
        {
            label: t("breed:DOG:GRAND_SPANISH_HOUND"),
            value: "Grand Spanish Hound"
        },
        {
            label: t("breed:DOG:SMALL_SPANISH_HOUND"),
            value: "Small Spanish Hound"
        },
        {
            label: t("breed:DOG:FINNISH_HOUND"),
            value: "Finnish Hound"
        },
        {
            label: t("breed:DOG:HELLENIC_HOUND"),
            value: "Hellenic Hound"
        },
        {
            label: t("breed:DOG:ITALIAN_SHORTHAIRED_HOUND"),
            value: "Italian Short-haired Hound"
        },
        {
            label: t("breed:DOG:ITALIAN_WIREHAIRED_HOUND"),
            value: "Italian Wire-haired Hound"
        },
        {
            label: t("breed:DOG:LUCERNE_HOUND"),
            value: "Lucerne Hound"
        },
        {
            label: t("breed:DOG:SLOVAK_HOUND"),
            value: "Slovak Hound"
        },
        {
            label: t("breed:DOG:YUGOSLAVIAN_MOUNTAIN_HOUND"),
            value: "Yugoslavian Mountain Hound"
        },
        {
            label: t("breed:DOG:YUGOSLAVIAN_TRICOLOUR_HOUND"),
            value: "Yugoslavian Tricolour Hound"
        },
        {
            label: t("breed:DOG:GERMAN_LONGHAIRED_POINTER"),
            value: "German Longhaired Pointer"
        },
        {
            label: t("breed:DOG:ARTOIS_HOUND"),
            value: "Artois Hound"
        },
        {
            label: t("breed:DOG:AMERICAN_WATER_SPANIEL"),
            value: "American Water Spaniel"
        },
        {
            label: t("breed:DOG:SPANISH_WATER_DOG"),
            value: "Spanish Water Dog"
        },
        {
            label: t("breed:DOG:FRISIAN_WATER_DOG"),
            value: "Frisian Water Dog"
        },
        {
            label: t("breed:DOG:IRISH_WATER_SPANIEL"),
            value: "Irish Water Spaniel"
        },
        {
            label: t("breed:DOG:PORTUGUESE_WATER_DOG"),
            value: "Portuguese Water Dog"
        },
        {
            label: t("breed:DOG:ROMAGNA_WATER_DOG"),
            value: "Romagna Water Dog"
        },
        {
            label: t("breed:DOG:NORWEGIAN_ELKHOUND_GRAY"),
            value: "Norwegian Elkhound Gray"
        },
        {
            label: t("breed:DOG:NORWEGIAN_ELKHOUND_BLACK"),
            value: "Norwegian Elkhound Black"
        },
        {
            label: t("breed:DOG:SWEDISH_ELKHOUND"),
            value: "Swedish Elkhound"
        },
        {
            label: t("breed:DOG:KARELIAN_BEAR_DOG"),
            value: "Karelian Bear Dog"
        },
        {
            label: t("breed:DOG:WACHTELHUND"),
            value: "Wachtelhund"
        },
        {
            label: t("breed:DOG:CANAAN_DOG"),
            value: "Canaan Dog"
        },
        {
            label: t("breed:DOG:ATLAS_SHEPHERD_DOG_AIDI"),
            value: "Atlas Shepherd Dog (Aidi)"
        },
        {
            label: t("breed:DOG:OTTERHOUND"),
            value: "Otterhound"
        },
        {
            label: t("breed:DOG:PORTUGUESE_SHEEPDOG_SHORTHAIRED"),
            value: "Portuguese Sheepdog (Short-haired)"
        },
        {
            label: t("breed:DOG:PORTUGUESE_SHEEPDOG_LONGHAIRED"),
            value: "Portuguese Sheepdog (Long-haired)"
        },
        {
            label: t("breed:DOG:GOTH_SHEPHERD_DOG"),
            value: "Goth Shepherd Dog"
        },
        {
            label: t("breed:DOG:MEXICAN_HAIRLESS_DOG"),
            value: "Mexican Hairless Dog"
        },
        {
            label: t("breed:DOG:PERUVIAN_HAIRLESS_DOG_LARGE"),
            value: "Peruvian Hairless Dog (Large)"
        },
        {
            label: t("breed:DOG:PERUVIAN_HAIRLESS_DOG_MEDIUM"),
            value: "Peruvian Hairless Dog (Medium)"
        },
        {
            label: t("breed:DOG:PERUVIAN_HAIRLESS_DOG_SMALL"),
            value: "Peruvian Hairless Dog (Small)"
        },
        {
            label: t("breed:DOG:PHARAOH_HOUND"),
            value: "Pharaoh Hound"
        },
        {
            label: t("breed:DOG:FINNISH_LAPPHUND"),
            value: "Finnish Lapphund"
        },
        {
            label: t("breed:DOG:CATAHOULA_LEOPARD_DOG"),
            value: "Catahoula Leopard Dog"
        },
        {
            label: t("breed:DOG:SAARLOOSWOLFHOND"),
            value: "Saarlooswolfhond"
        },
        {
            label: t("breed:DOG:CZECHOSLOVAKIAN_WOLFDOG"),
            value: "Czechoslovakian Wolfdog"
        },
        {
            label: t("breed:DOG:NORWEGIAN_LUNDEHUND"),
            value: "Norwegian Lundehund"
        },
        {
            label: t("breed:DOG:BAVARIAN_MOUNTAIN_SCENTHOUND"),
            value: "Bavarian Mountain Scenthound"
        },
        {
            label: t("breed:DOG:HANOVERIAN_SCENTHOUND"),
            value: "Hanoverian Scenthound"
        },
        {
            label: t("breed:DOG:SWEDISH_LAPPHUND"),
            value: "Swedish Lapphund"
        },
        {
            label: t("breed:DOG:CHIHUAHUA"),
            value: "Chihuahua"
        },
        {
            label: t("breed:DOG:CHOW_CHOW"),
            value: "Chow Chow"
        },
        {
            label: t("breed:DOG:CIRNECO_DELL_ETNA"),
            value: "Cirneco dell'Etna"
        },
        {
            label: t("breed:DOG:CLUMBER_SPANIEL"),
            value: "Clumber Spaniel"
        },
        {
            label: t("breed:DOG:AMERICAN_COCKER_SPANIEL"),
            value: "American Cocker Spaniel"
        },
        {
            label: t("breed:DOG:ENGLISH_COCKER_SPANIEL"),
            value: "English Cocker Spaniel"
        },
        {
            label: t("breed:DOG:SMOOTH_COLLIE"),
            value: "Smooth Collie"
        },
        {
            label: t("breed:DOG:ROUGH_COLLIE"),
            value: "Rough Collie"
        },
        {
            label: t("breed:DOG:BEARDED_COLLIE"),
            value: "Bearded Collie"
        },
        {
            label: t("breed:DOG:BLACK_AND_TAN_COONHOUND"),
            value: "Black and Tan Coonhound"
        },
        {
            label: t("breed:DOG:COTON_DE_TULEAR"),
            value: "Coton de Tulear"
        },
        {
            label: t("breed:DOG:MIXED_BREED_CROSSBREED"),
            value: "Mixed Breed (Crossbreed)"
        },
        {
            label: t("breed:DOG:DALMATIAN"),
            value: "Dalmatian"
        },
        {
            label: t("breed:DOG:DOBERMAN_PINSCHER"),
            value: "Doberman Pinscher"
        },
        {
            label: t("breed:DOG:DOGO_CANARIO"),
            value: "Dogo Canario"
        },
        {
            label: t("breed:DOG:GREAT_DANE"),
            value: "Great Dane"
        },
        {
            label: t("breed:DOG:ARGENTINE_DOGO"),
            value: "Argentine Dogo"
        },
        {
            label: t("breed:DOG:BORDEAUX_MASTIFF_FRENCH_MASTIFF"),
            value: "Bordeaux Mastiff (French Mastiff)"
        },
        {
            label: t("breed:DOG:TIBETAN_MASTIFF"),
            value: "Tibetan Mastiff"
        },
        {
            label: t("breed:DOG:DREVER"),
            value: "Drever"
        },
        {
            label: t("breed:DOG:DUNKER"),
            value: "Dunker"
        },
        {
            label: t("breed:DOG:DRENTSE_PARTRIDGE_DOG"),
            value: "Drentse Partridge Dog"
        },
        {
            label: t("breed:DOG:BLUE_PICARDY_SPANIEL"),
            value: "Blue Picardy Spaniel"
        },
        {
            label: t("breed:DOG:BRITTANY_SPANIEL"),
            value: "Brittany Spaniel"
        },
        {
            label: t("breed:DOG:SAINTUSUGE_SPANIEL"),
            value: "Saint-Usuge Spaniel"
        },
        {
            label: t("breed:DOG:TIBETAN_SPANIEL"),
            value: "Tibetan Spaniel"
        },
        {
            label: t("breed:DOG:FRENCH_SPANIEL"),
            value: "French Spaniel"
        },
        {
            label: t("breed:DOG:JAPANESE_SPANIEL_CHIN"),
            value: "Japanese Spaniel (Chin)"
        },
        {
            label: t("breed:DOG:CONTINENTAL_TOY_SPANIEL"),
            value: "Continental Toy Spaniel"
        },
        {
            label: t("breed:DOG:PICARDY_SPANIEL"),
            value: "Picardy Spaniel"
        },
        {
            label: t("breed:DOG:PONTAUDEMER_SPANIEL"),
            value: "Pont-Audemer Spaniel"
        },
        {
            label: t("breed:DOG:GREENLAND_DOG"),
            value: "Greenland Dog"
        },
        {
            label: t("breed:DOG:EURASIER"),
            value: "Eurasier"
        },
        {
            label: t("breed:DOG:FIELD_SPANIEL"),
            value: "Field Spaniel"
        },
        {
            label: t("breed:DOG:FILA_BRASILEIRO"),
            value: "Fila Brasileiro"
        },
        {
            label: t("breed:DOG:WIRE_FOX_TERRIER"),
            value: "Wire Fox Terrier"
        },
        {
            label: t("breed:DOG:SMOOTH_FOX_TERRIER_ENGLISH_FOX_TERRIER"),
            value: "Smooth Fox Terrier (English Fox Terrier)"
        },
        {
            label: t("breed:DOG:FOXHOUND_ENGLISH_FOXHOUND"),
            value: "Foxhound (English Foxhound)"
        },
        {
            label: t("breed:DOG:AMERICAN_FOXHOUND"),
            value: "American Foxhound"
        },
        {
            label: t("breed:DOG:FRENCH_WHITE_AND_BLACK_HOUND"),
            value: "French White and Black Hound"
        },
        {
            label: t("breed:DOG:FRENCH_WHITE_AND_ORANGE_HOUND"),
            value: "French White and Orange Hound"
        },
        {
            label: t("breed:DOG:TRICOLOUR_HOUND"),
            value: "Tricolour Hound"
        },
        {
            label: t("breed:DOG:SPANISH_GREYHOUND_GALGO_ESPANOL"),
            value: "Spanish Greyhound (Galgo Espa\u00f1ol)"
        },
        {
            label: t("breed:DOG:GOLDEN_RETRIEVER"),
            value: "Golden Retriever"
        },
        {
            label: t("breed:DOG:GRAND_ANGLOFRANCAIS_BLANC_ET_NOIR"),
            value: "Grand Anglo-Fran\u00e7ais Blanc et Noir"
        },
        {
            label: t("breed:DOG:GRAND_ANGLOFRANCAIS_BLANC_ET_ORANGE"),
            value: "Grand Anglo-Fran\u00e7ais Blanc et Orange"
        },
        {
            label: t("breed:DOG:GRAND_ANGLOFRANCAIS_TRICOLORE"),
            value: "Grand Anglo-Fran\u00e7ais Tricolore"
        },
        {
            label: t("breed:DOG:GRAND_BASSET_GRIFFON_VENDEEN"),
            value: "Grand Basset Griffon Vend\u00e9en"
        },
        {
            label: t("breed:DOG:GRAND_BLEU_DE_GASCOGNE"),
            value: "Grand Bleu de Gascogne"
        },
        {
            label: t("breed:DOG:GREATER_SWISS_MOUNTAIN_DOG"),
            value: "Greater Swiss Mountain Dog"
        },
        {
            label: t("breed:DOG:AKITA_INU_GREAT_JAPANESE_DOG"),
            value: "Akita Inu (Great Japanese Dog)"
        },
        {
            label: t("breed:DOG:GRAND_GASCONSAINTONGEOIS_HOUND"),
            value: "Grand Gascon-Saintongeois Hound"
        },
        {
            label: t("breed:DOG:GRAND_GRIFFON_VENDEEN"),
            value: "Grand Griffon Vend\u00e9en"
        },
        {
            label: t("breed:DOG:LARGE_MUNSTERLANDER"),
            value: "Large M\u00fcnsterl\u00e4nder"
        },
        {
            label: t("breed:DOG:GREYHOUND"),
            value: "Greyhound"
        },
        {
            label: t("breed:DOG:WIREHAIRED_POINTING_GRIFFON"),
            value: "Wirehaired Pointing Griffon"
        },
        {
            label: t("breed:DOG:BELGIAN_GRIFFON"),
            value: "Belgian Griffon"
        },
        {
            label: t("breed:DOG:GRAND_BASSET_GRIFFON_VENDEEN"),
            value: "Grand Basset Griffon Vend\u00e9en"
        },
        {
            label: t("breed:DOG:BRUSSELS_GRIFFON"),
            value: "Brussels Griffon"
        },
        {
            label: t("breed:DOG:WIREHAIRED_POINTING_GRIFFON"),
            value: "Wirehaired Pointing Griffon"
        },
        {
            label: t("breed:DOG:BOHEMIAN_WIREHAIRED_POINTING_GRIFFON"),
            value: "Bohemian Wire-haired Pointing Griffon"
        },
        {
            label: t("breed:DOG:FAWN_BRITTANY_BASSET"),
            value: "Fawn Brittany Basset"
        },
        {
            label: t("breed:DOG:GRAND_GRIFFON_VENDEEN"),
            value: "Grand Griffon Vend\u00e9en"
        },
        {
            label: t("breed:DOG:HAMILTONSTOVARE"),
            value: "Hamiltonst\u00f6vare"
        },
        {
            label: t("breed:DOG:HARRIER"),
            value: "Harrier"
        },
        {
            label: t("breed:DOG:HOKKAIDO"),
            value: "Hokkaido"
        },
        {
            label: t("breed:DOG:HOVAWART"),
            value: "Hovawart"
        },
        {
            label: t("breed:DOG:SIBERIAN_HUSKY"),
            value: "Siberian Husky"
        },
        {
            label: t("breed:DOG:JACK_RUSSELL_TERRIER"),
            value: "Jack Russell Terrier"
        },
        {
            label: t("breed:DOG:JAGDTERRIER"),
            value: "Jagdterrier"
        },
        {
            label: t("breed:DOG:KAI_KEN"),
            value: "Kai Ken"
        },
        {
            label: t("breed:DOG:KERRY_BLUE_TERRIER"),
            value: "Kerry Blue Terrier"
        },
        {
            label: t("breed:DOG:CAVALIER_KING_CHARLES_SPANIEL"),
            value: "Cavalier King Charles Spaniel"
        },
        {
            label: t("breed:DOG:KISHU_KEN"),
            value: "Kishu Ken"
        },
        {
            label: t("breed:DOG:KOMONDOR"),
            value: "Komondor"
        },
        {
            label: t("breed:DOG:KOREAN_JINDO_DOG"),
            value: "Korean Jindo Dog"
        },
        {
            label: t("breed:DOG:KROMFOHRLANDER"),
            value: "Kromfohrl\u00e4nder"
        },
        {
            label: t("breed:DOG:HUNGARIAN_KUVASZ"),
            value: "Hungarian Kuvasz"
        },
        {
            label: t("breed:DOG:KYILEO"),
            value: "Kyi-Leo"
        },
        {
            label: t("breed:DOG:LABRADOR"),
            value: "Labrador"
        },
        {
            label: t("breed:DOG:WEST_SIBERIAN_LAIKA"),
            value: "West Siberian Laika"
        },
        {
            label: t("breed:DOG:EAST_SIBERIAN_LAIKA"),
            value: "East Siberian Laika"
        },
        {
            label: t("breed:DOG:RUSSOEUROPEAN_LAIKA"),
            value: "Russo-European Laika"
        },
        {
            label: t("breed:DOG:LAKELAND_TERRIER"),
            value: "Lakeland Terrier"
        },
        {
            label: t("breed:DOG:LANDSEER"),
            value: "Landseer"
        },
        {
            label: t("breed:DOG:LEONBERGER"),
            value: "Leonberger"
        },
        {
            label: t("breed:DOG:AFGHAN_HOUND"),
            value: "Afghan Hound"
        },
        {
            label: t("breed:DOG:SCOTTISH_DEERHOUND"),
            value: "Scottish Deerhound"
        },
        {
            label: t("breed:DOG:HUNGARIAN_GREYHOUND"),
            value: "Hungarian Greyhound"
        },
        {
            label: t("breed:DOG:IRISH_WOLFHOUND"),
            value: "Irish Wolfhound"
        },
        {
            label: t("breed:DOG:LHASA_APSO"),
            value: "Lhasa Apso"
        },
        {
            label: t("breed:DOG:DOGUE_DE_BORDEAUX"),
            value: "Dogue de Bordeaux"
        },
        {
            label: t("breed:DOG:ALASKAN_MALAMUTE"),
            value: "Alaskan Malamute"
        },
        {
            label: t("breed:DOG:MASTIFF_FRENCH_MASTIFF"),
            value: "Mastiff (French Mastiff)"
        },
        {
            label: t("breed:DOG:NEAPOLITAN_MASTIFF"),
            value: "Neapolitan Mastiff"
        },
        {
            label: t("breed:DOG:PYRENEAN_MOUNTAIN_DOG"),
            value: "Pyrenean Mountain Dog"
        },
        {
            label: t("breed:DOG:SPANISH_MASTIFF"),
            value: "Spanish Mastiff"
        },
        {
            label: t("breed:DOG:GREAT_PYRENEES"),
            value: "Great Pyrenees"
        },
        {
            label: t("breed:DOG:MUDI"),
            value: "Mudi"
        },
        {
            label: t("breed:DOG:NORFOLK_TERRIER"),
            value: "Norfolk Terrier"
        },
        {
            label: t("breed:DOG:NORWICH_TERRIER"),
            value: "Norwich Terrier"
        },
        {
            label: t("breed:DOG:PARSON_RUSSELL_TERRIER"),
            value: "Parson Russell Terrier"
        },
        {
            label: t("breed:DOG:PEKINGESE"),
            value: "Pekingese"
        },
        {
            label: t("breed:DOG:CA_DE_BOU_PERRO_DE_PRESA"),
            value: "Ca de Bou (Perro de Presa)"
        },
        {
            label: t("breed:DOG:PETIT_BASSET_GRIFFON_VENDEEN"),
            value: "Petit Basset Griffon Vendeen"
        },
        {
            label: t("breed:DOG:GRAND_BLEU_DE_GASCOGNE"),
            value: "Grand Bleu de Gascogne"
        },
        {
            label: t("breed:DOG:PETIT_BRABANCON"),
            value: "Petit Brabancon"
        },
        {
            label: t("breed:DOG:BERNER_NIEDERLAUFHUND"),
            value: "Berner Niederlaufhund"
        },
        {
            label: t("breed:DOG:SCHWEIZER_LAUFHUND"),
            value: "Schweizer Laufhund"
        },
        {
            label: t("breed:DOG:BRUNO_JURA_HOUND"),
            value: "Bruno Jura Hound"
        },
        {
            label: t("breed:DOG:LUZERNER_LAUFHUND"),
            value: "Luzerner Laufhund"
        },
        {
            label: t("breed:DOG:DUTCH_SMOUSHOND"),
            value: "Dutch Smoushond"
        },
        {
            label: t("breed:DOG:LOWCHEN_LITTLE_LION_DOG"),
            value: "Lowchen (Little Lion Dog)"
        },
        {
            label: t("breed:DOG:PETIT_GASCON_SAINTONGEOIS"),
            value: "Petit Gascon Saintongeois"
        },
        {
            label: t("breed:DOG:ITALIAN_GREYHOUND"),
            value: "Italian Greyhound"
        },
        {
            label: t("breed:DOG:SMALL_MUNSTERLANDER"),
            value: "Small Munsterlander"
        },
        {
            label: t("breed:DOG:AUSTRIAN_SHORTHAIRED_PINSCHER"),
            value: "Austrian Shorthaired Pinscher"
        },
        {
            label: t("breed:DOG:GERMAN_PINSCHER"),
            value: "German Pinscher"
        },
        {
            label: t("breed:DOG:MINIATURE_PINSCHER"),
            value: "Miniature Pinscher"
        },
        {
            label: t("breed:DOG:CANARY_ISLAND_PODENCO"),
            value: "Canary Island Podenco"
        },
        {
            label: t("breed:DOG:IBIZAN_HOUND_SHORT_HAIRED"),
            value: "Ibizan Hound (short haired)"
        },
        {
            label: t("breed:DOG:IBIZAN_HOUND_WIRE_HAIRED"),
            value: "Ibizan Hound (wire haired)"
        },
        {
            label: t("breed:DOG:PORTUGUESE_PODENGO_GRANDE"),
            value: "Portuguese Podengo Grande"
        },
        {
            label: t("breed:DOG:PORTUGUESE_PODENGO_MEDIO"),
            value: "Portuguese Podengo Medio"
        },
        {
            label: t("breed:DOG:PORTUGUESE_PODENGO_PEQUENO"),
            value: "Portuguese Podengo Pequeno"
        },
        {
            label: t("breed:DOG:ENGLISH_POINTER"),
            value: "English Pointer"
        },
        {
            label: t("breed:DOG:POITEVIN"),
            value: "Poitevin"
        },
        {
            label: t("breed:DOG:PORCELAINE"),
            value: "Porcelaine"
        },
        {
            label: t("breed:DOG:PUDEL_POINTER"),
            value: "Pudel Pointer"
        },
        {
            label: t("breed:DOG:PULI_HUNGARIAN_SHEPHERD"),
            value: "Puli (Hungarian Shepherd)"
        },
        {
            label: t("breed:DOG:PUMI_HUNGARIAN_SHEPHERD"),
            value: "Pumi (Hungarian Shepherd)"
        },
        {
            label: t("breed:DOG:RAFEIRO_DE_ALENTEJO"),
            value: "Rafeiro de Alentejo"
        },
        {
            label: t("breed:DOG:PRAGUE_RATTER"),
            value: "Prague Ratter"
        },
        {
            label: t("breed:DOG:REDBONE_COONHOUND"),
            value: "Redbone Coonhound"
        },
        {
            label: t("breed:DOG:REDTICK_COONHOUND"),
            value: "Redtick Coonhound"
        },
        {
            label: t("breed:DOG:CURLYCOATED_RETRIEVER"),
            value: "Curly-Coated Retriever"
        },
        {
            label: t("breed:DOG:FLATCOATED_RETRIEVER"),
            value: "Flat-Coated Retriever"
        },
        {
            label: t("breed:DOG:CHESAPEAKE_BAY_RETRIEVER"),
            value: "Chesapeake Bay Retriever"
        },
        {
            label: t("breed:DOG:NOVA_SCOTIA_DUCK_TOLLING_RETRIEVER"),
            value: "Nova Scotia Duck Tolling Retriever"
        },
        {
            label: t("breed:DOG:RHODESIAN_RIDGEBACK"),
            value: "Rhodesian Ridgeback"
        },
        {
            label: t("breed:DOG:ROTTWEILER"),
            value: "Rottweiler"
        },
        {
            label: t("breed:DOG:SAINT_BERNARD"),
            value: "Saint Bernard"
        },
        {
            label: t("breed:DOG:BLOODHOUND_SAINTHUBERT_DOG"),
            value: "Bloodhound (Saint-Hubert Dog)"
        },
        {
            label: t("breed:DOG:SALUKI"),
            value: "Saluki"
        },
        {
            label: t("breed:DOG:SAMOYED"),
            value: "Samoyed"
        },
        {
            label: t("breed:DOG:SANSHU"),
            value: "Sanshu"
        },
        {
            label: t("breed:DOG:SCHAPENDOES"),
            value: "Schapendoes"
        },
        {
            label: t("breed:DOG:SCHILLERSTOVARE"),
            value: "Schillerst\u00f6vare"
        },
        {
            label: t("breed:DOG:SCHIPPERKE"),
            value: "Schipperke"
        },
        {
            label: t("breed:DOG:GIANT_SCHNAUZER"),
            value: "Giant Schnauzer"
        },
        {
            label: t("breed:DOG:STANDARD_SCHNAUZER"),
            value: "Standard Schnauzer"
        },
        {
            label: t("breed:DOG:MINIATURE_SCHNAUZER"),
            value: "Miniature Schnauzer"
        },
        {
            label: t("breed:DOG:SEALYHAM_TERRIER"),
            value: "Sealyham Terrier"
        },
        {
            label: t("breed:DOG:ENGLISH_SETTER"),
            value: "English Setter"
        },
        {
            label: t("breed:DOG:GORDON_SETTER"),
            value: "Gordon Setter"
        },
        {
            label: t("breed:DOG:IRISH_RED_AND_WHITE_SETTER"),
            value: "Irish Red and White Setter"
        },
        {
            label: t("breed:DOG:IRISH_SETTER"),
            value: "Irish Setter"
        },
        {
            label: t("breed:DOG:SHAR_PEI"),
            value: "Shar Pei"
        },
        {
            label: t("breed:DOG:SHIBA_INU"),
            value: "Shiba Inu"
        },
        {
            label: t("breed:DOG:SHIH_TZU"),
            value: "Shih Tzu"
        },
        {
            label: t("breed:DOG:SHIKOKU"),
            value: "Shikoku"
        },
        {
            label: t("breed:DOG:SILKY_TERRIER"),
            value: "Silky Terrier"
        },
        {
            label: t("breed:DOG:SKYE_TERRIER"),
            value: "Skye Terrier"
        },
        {
            label: t("breed:DOG:SLOUGHI"),
            value: "Sloughi"
        },
        {
            label: t("breed:DOG:SMALAND_HOUND"),
            value: "Smaland Hound"
        },
        {
            label: t("breed:DOG:SPINONE_ITALIANO"),
            value: "Spinone Italiano"
        },
        {
            label: t("breed:DOG:GERMAN_SPITZ"),
            value: "German Spitz"
        },
        {
            label: t("breed:DOG:GERMAN_SPITZ"),
            value: "German Spitz"
        },
        {
            label: t("breed:DOG:GERMAN_SPITZ"),
            value: "German Spitz"
        },
        {
            label: t("breed:DOG:GERMAN_SPITZ"),
            value: "German Spitz"
        },
        {
            label: t("breed:DOG:GERMAN_SPITZ"),
            value: "German Spitz"
        },
        {
            label: t("breed:DOG:NORRBOTTENSPETS"),
            value: "Norrbottenspets"
        },
        {
            label: t("breed:DOG:FINNISH_SPITZ"),
            value: "Finnish Spitz"
        },
        {
            label: t("breed:DOG:ITALIAN_SPITZ"),
            value: "Italian Spitz"
        },
        {
            label: t("breed:DOG:JAPANESE_SPITZ"),
            value: "Japanese Spitz"
        },
        {
            label: t("breed:DOG:ENGLISH_SPRINGER_SPANIEL"),
            value: "English Springer Spaniel"
        },
        {
            label: t("breed:DOG:WELSH_SPRINGER_SPANIEL"),
            value: "Welsh Springer Spaniel"
        },
        {
            label: t("breed:DOG:STABYHOUN"),
            value: "Stabyhoun"
        },
        {
            label: t("breed:DOG:STAFFORDSHIRE_BULL_TERRIER"),
            value: "Staffordshire Bull Terrier"
        },
        {
            label: t("breed:DOG:STICHELHAAR"),
            value: "Stichelhaar"
        },
        {
            label: t("breed:DOG:SUSSEX_SPANIEL"),
            value: "Sussex Spaniel"
        },
        {
            label: t("breed:DOG:DACHSHUND_WIREHAIRED"),
            value: "Dachshund (Wire-haired)"
        },
        {
            label: t("breed:DOG:DACHSHUND_LONGHAIRED"),
            value: "Dachshund (Long-haired)"
        },
        {
            label: t("breed:DOG:DACHSHUND_SMOOTHHAIRED"),
            value: "Dachshund (Smooth-haired)"
        },
        {
            label: t("breed:DOG:DACHSHUND_WIREHAIRED_RABBIT"),
            value: "Dachshund (Wire-haired rabbit)"
        },
        {
            label: t("breed:DOG:DACHSHUND_LONGHAIRED_RABBIT"),
            value: "Dachshund (Long-haired rabbit)"
        },
        {
            label: t("breed:DOG:DACHSHUND_SMOOTHHAIRED_RABBIT"),
            value: "Dachshund (Smooth-haired rabbit)"
        },
        {
            label: t("breed:DOG:MINIATURE_DACHSHUND_WIREHAIRED"),
            value: "Miniature Dachshund (Wire-haired)"
        },
        {
            label: t("breed:DOG:MINIATURE_DACHSHUND_LONGHAIRED"),
            value: "Miniature Dachshund (Long-haired)"
        },
        {
            label: t("breed:DOG:MINIATURE_DACHSHUND_SMOOTHHAIRED"),
            value: "Miniature Dachshund (Smooth-haired)"
        },
        {
            label: t("breed:DOG:NEWFOUNDLAND"),
            value: "Newfoundland"
        },
        {
            label: t("breed:DOG:AUSTRALIAN_TERRIER"),
            value: "Australian Terrier"
        },
        {
            label: t("breed:DOG:BRAZILIAN_TERRIER"),
            value: "Brazilian Terrier"
        },
        {
            label: t("breed:DOG:DANDIE_DINMONT_TERRIER"),
            value: "Dandie Dinmont Terrier"
        },
        {
            label: t("breed:DOG:BOSTON_TERRIER"),
            value: "Boston Terrier"
        },
        {
            label: t("breed:DOG:MANCHESTER_TERRIER"),
            value: "Manchester Terrier"
        },
        {
            label: t("breed:DOG:TIBETAN_TERRIER"),
            value: "Tibetan Terrier"
        },
        {
            label: t("breed:DOG:SCOTTISH_TERRIER"),
            value: "Scottish Terrier"
        },
        {
            label: t("breed:DOG:IRISH_TERRIER"),
            value: "Irish Terrier"
        },
        {
            label: t("breed:DOG:SOFTCOATED_WHEATEN_TERRIER"),
            value: "Soft-coated Wheaten Terrier"
        },
        {
            label: t("breed:DOG:GLEN_OF_IMAAL_TERRIER"),
            value: "Glen of Imaal Terrier"
        },
        {
            label: t("breed:DOG:JAPANESE_TERRIER"),
            value: "Japanese Terrier"
        },
        {
            label: t("breed:DOG:RUSSIAN_BLACK_TERRIER"),
            value: "Russian Black Terrier"
        },
        {
            label: t("breed:DOG:CESKY_TERRIER"),
            value: "Cesky Terrier"
        },
        {
            label: t("breed:DOG:TOSA"),
            value: "Tosa"
        },
        {
            label: t("breed:DOG:ENGLISH_TOY_TERRIER"),
            value: "English Toy Terrier"
        },
        {
            label: t("breed:DOG:WELSH_CORGI_CARDIGAN"),
            value: "Welsh Corgi (Cardigan)"
        },
        {
            label: t("breed:DOG:WELSH_CORGI_PEMBROKE"),
            value: "Welsh Corgi (Pembroke)"
        },
        {
            label: t("breed:DOG:WELSH_TERRIER"),
            value: "Welsh Terrier"
        },
        {
            label: t("breed:DOG:WEST_HIGHLAND_WHITE_TERRIER"),
            value: "West Highland White Terrier"
        },
        {
            label: t("breed:DOG:WHIPPET"),
            value: "Whippet"
        },
        {
            label: t("breed:DOG:YORKSHIRE_TERRIER"),
            value: "Yorkshire Terrier"
        }
    ];
}
