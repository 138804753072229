import moment from 'moment';
import { createSelector } from 'reselect';

import { makeGetTrackerModel } from './get-tracker-model';

export const makeGetIsTrackerSubExpired = (trackerId: number) => {
    const getTrackerModel = makeGetTrackerModel(trackerId);

    return createSelector(getTrackerModel, (model) => {
        if (model === undefined) {
            // We used to throw an error here, when tracker id doesn't match user's trackers
            // but this should not be done here.
            return false;
        }

        const sub = model.subscription;

        if (!sub) {
            return false;
        }

        if (sub.status !== 'canceled' || !sub.next_charge_at) {
            return false;
        }

        const nextChargeIsoString = sub.next_charge_at;
        const nextChargeDate = moment(nextChargeIsoString);

        return nextChargeDate.isBefore(moment(new Date()));
    });
};
