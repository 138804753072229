import { createSelector } from 'reselect';
import { IRootState } from '../../store/store';
import { IGeofenceEditStore } from '../geofence-edit.store';
import { IGraphhoperProps } from '../geofence-geocode/geofence-geocode.component';

const geofenceEditStore = (state: IRootState) => state.geofenceEdit;

export const makeGetIsNewGeofenceActive = () => {
    return createSelector(
        geofenceEditStore,
        (state: IGeofenceEditStore): boolean => state.duringNewCreating,
    );
};

export const makeGetEditableGeofenceId = () => {
    return createSelector(
        geofenceEditStore,
        (state: IGeofenceEditStore): number | null => state.geofenceId,
    );
};

export const makeGetEditableGeofencePosition = () => {
    return createSelector(
        geofenceEditStore,
        (state: IGeofenceEditStore): { lat: number; lng: number } =>
            state.position,
    );
};

export const makeGetEditableGeofenceRadius = () => {
    return createSelector(
        geofenceEditStore,
        (state: IGeofenceEditStore): number => state.radius,
    );
};

export const makeGetEditableGeofenceAddressesChoice = () => {
    return createSelector(
        geofenceEditStore,
        (state: IGeofenceEditStore): IGraphhoperProps[] =>
            state.addressesChoice,
    );
};
