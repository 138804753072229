import React from 'react';
import { ISumaryProperties } from '../../interface';
import styles from './sumary-block.module.scss';

interface IOuterProps {
    blockProperties: ISumaryProperties[];
}

export const SumaryBlock = ({ blockProperties }: IOuterProps) => {
    const renderBlocks = () => {
        return (
            <>
                {blockProperties.map((value, i) => {
                    return (
                        <div key={i} className={styles.sumaryBlock}>
                            <div>{value.icon}</div>
                            <div className={styles.sumaryTextBlock}>
                                <div style={{ fontWeight: 'bold' }}>
                                    {value.timeValue}
                                </div>
                                <div className={styles.SubText}>
                                    {value.sumaryText}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    return <>{renderBlocks()}</>;
};
