import { ModelConfig } from '@rematch/core';
import { useNavigate } from 'react-router';

import { config as appConfig } from '../../config';

import { subscriptionProcessRoute } from '../routing/routes';
import { IRootState } from '../store/store';
import { ISubscriptionOffer } from '../subscription-offers/interfaces';
import { PaymentCode } from '../subscription-process/interfaces';

import {
    redirectToPaymentGate,
    requestSubscriptionOrder,
} from '../subscription-process/services/order-subscription.service';
import { IUserChoices } from '../subscription-process/subscription-process.store';
import { ISubscriptionOptionData } from '../user-subscriptions/interfaces';
import { SubscriptionOptionType } from '../user-subscriptions/interfaces-api';
import { IRawUserData } from '../user/interfaces';
import {
    fetchSubscriptionCanChangePlan,
    postSubscriptionChangePlan,
} from './services/manage-subscription.service';

interface IUpdateOption {
    optionCodeToToggle: string;
    activeOptions: ISubscriptionOptionData[];
}

export interface INewOption {
    activated: boolean;
    code: SubscriptionOptionType;
}

export interface IManageSubscriptionStore {
    newSubscriptionOffer: ISubscriptionOffer | null;
    newOptions: INewOption[];
    canChangePlan: boolean;
}

export const manageSubscriptionStore: ModelConfig<IManageSubscriptionStore> = {
    state: {
        newSubscriptionOffer: null,
        newOptions: [],
        canChangePlan: true, // Required to be true by default
    },
    effects: (dispatch: any) => ({
        changePlan(
            payload: {
                subscriptionId: number;
                newOfferCode: string;
            },
            models: IRootState,
        ) {
            const currentUser = models.user.userData as IRawUserData;
            const userSite = currentUser.site;

            return postSubscriptionChangePlan(
                payload.subscriptionId,
                userSite,
                payload.newOfferCode,
            );
        },
        fetchCanChangePlan(subscriptionId: number) {
            fetchSubscriptionCanChangePlan(subscriptionId)
                .then(() => this.setCanChangePlan(true))
                .catch(() => this.setCanChangePlan(false));
        },
        updateNewSubscriptionOffer(newSubscriptionOffer: ISubscriptionOffer) {
            this.setNewSubscriptionOffer(newSubscriptionOffer);
        },
        updateOption({
            optionCodeToToggle,
            activeOptions,
        }: {
            optionCodeToToggle: SubscriptionOptionType;
            activeOptions: ISubscriptionOptionData[];
        }) {
            const activeOptionsCode = activeOptions.map(
                (activeOption) => activeOption.code,
            );
            this.toggleOption(optionCodeToToggle, activeOptionsCode);
        },

        async submitSubscriptionRequestOnReactivation(
            payload: any,
            model: any,
        ) {
            let retailerId;
            let theme: string | null;
            const { rawTrackers } = model.userTrackers;
            const trackerId = model.subscriptionProcess.choices.trackerId;
            rawTrackers.map((trackerInfo: any) => {
                if (trackerId === trackerInfo.id) {
                    retailerId = trackerInfo.retailer_id;
                    if (appConfig.BIOGGY_RETAILER_IDS.includes(retailerId)) {
                        theme = 'biogaran';
                    }
                }
            });
            const data = model.subscriptionProcess.choices as IUserChoices;
            const currentSite = model.user.userData.site;

            return requestSubscriptionOrder(data, currentSite).then((resp) => {
                // Update tracker model allows to be aware of the newly created subscription
                const promises: Promise<any>[] = [];
                promises.push(
                    dispatch.userTrackers.fetchSingleTracker({
                        trackerId: data.trackerId,
                    }) as Promise<any>,
                );
                Promise.all(promises).then(() => {
                    // Don't redirect to payment gate for Hipay
                    // as we use hosted fields directly on our page.
                    if (data.selectedPaymentCode === PaymentCode.HIPAY) {
                        return useNavigate()(
                            subscriptionProcessRoute +
                            `/credit-card/${data.trackerId}`,
                        );
                    }
                    return redirectToPaymentGate(resp.redirect_url);
                });
            });
        },

        resetOptions() {
            this.eraseOptions();
        },
    }),
    reducers: {
        eraseOptions: (
            state: IManageSubscriptionStore,
        ): IManageSubscriptionStore => ({
            ...state,
            newOptions: [],
        }),
        setCanChangePlan: (
            state: IManageSubscriptionStore,
            isPossible: boolean,
        ): IManageSubscriptionStore => ({
            ...state,
            canChangePlan: isPossible,
        }),
        setNewSubscriptionOffer: (
            state: IManageSubscriptionStore,
            payload: ISubscriptionOffer,
        ): IManageSubscriptionStore => ({
            ...state,
            newSubscriptionOffer: payload,
        }),

        /**
         * If option is in state.newOptions, we remove it from state,
         * else we add it.
         */
        toggleOption: (
            state: IManageSubscriptionStore,
            optionCode: SubscriptionOptionType,
            activeOptionsCode: SubscriptionOptionType[],
        ): IManageSubscriptionStore => {
            const newOptionsUpdated = state.newOptions;
            const newOptionIndex = newOptionsUpdated.findIndex(
                (newOption) => newOption.code === optionCode,
            );
            if (newOptionIndex !== -1) {
                newOptionsUpdated.splice(newOptionIndex, 1);
            } else {
                const shouldActivate =
                    activeOptionsCode.findIndex(
                        (activeCode) => activeCode === optionCode,
                    ) === -1;
                newOptionsUpdated.push({
                    code: optionCode,
                    activated: shouldActivate,
                });
            }
            return {
                ...state,
                newOptions: newOptionsUpdated,
            };
        },
    },
};
