import { ModelConfig } from '@rematch/core';
import { registerTracker } from './services/new-tracker.service';

export const newTrackerStore: ModelConfig<{}> = {
    state: {},
    effects: {
        registerTracker(payload: { name: string; imei: string }) {
            return registerTracker(payload);
        },
    },
};
