import { config } from '../config';
import { getGlobalT } from '../i18n/setup-translations';

/**
 * Server returns in cents
 */
export const normalizePriceAmount = (price: number) => price / 100;
const t = getGlobalT();

export const formatPrice = (price: number, currency: string) => {
    const language = t('misc:locale');
    if (
        language &&
        language.split('-')[0] === 'en'
    ){
        if (currency === "USD") {
            return `${price.toFixed(2)} $`;
        }
        return `€${price.toFixed(2)}`;
    } else {
        if (currency === "USD") {
            return `${price.toFixed(2)} $`;
        }
        return `${price.toFixed(2)} €`;
    }
};

export const formatAmountWithCurrency = (
    price: number,
    currency: string,
): string => formatPrice(normalizePriceAmount(price), currency);
