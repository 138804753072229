import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { pure } from 'recompose';

import { ReactComponent as LogoutIcon } from '../../icons/logout.svg';
import { Dispatch } from '../../store/store';
import styles from './user-area.module.scss';



interface IActions {
    onLogoutClicked: () => any
}

export type Props = IActions & HTMLAttributes<HTMLDivElement>;

export const LogoutButton = ({ onLogoutClicked, className }: Props) => {
    const { t } = useTranslation('header');

    return (
        <button
            data-testid="logout-button"
            onClick={onLogoutClicked}
            className={cx(styles['logout-button'], className)}
        >
            <LogoutIcon />
            <span>{t('NAV.LOGOUT')}</span>
        </button>
    );
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    // @ts-ignores
    onLogoutClicked: dispatch.login.requestLogout,
});

export const ConnectedLogoutButton = connect<null, IActions>(
    null,
    mapDispatch,
)(pure(LogoutButton));
