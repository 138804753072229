import cx from 'classnames';
import React, { InputHTMLAttributes } from 'react';

import { ReactComponent as TickIcon } from '../../icons/tick.svg';

import styles from './checkbox.module.scss';

export interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: boolean;
    stealth?: boolean;
}

export const Checkbox = ({
    checked,
    onChange,
    className,
    children,
    error,
    stealth,
    ...props
}: ICheckboxProps) => {
    return (
        <label className={cx(styles.container, className)}>
            <div className={styles.wrapper}>
                <input
                    checked={checked}
                    onChange={onChange}
                    className={styles.checkbox}
                    type="checkbox"
                    {...props}
                />
                <div
                    className={cx(styles.mask, {
                        [styles.error]: error,
                        [styles.stealth]: stealth,
                    })}
                >
                    <TickIcon />
                </div>
                <span className={styles.labelText}>{children}</span>
            </div>
        </label>
    );
};
