import { ModelConfig } from '@rematch/core';

import {
    getSession,
    ILoginResponse,
    killSession,
    requestLogin,
    requestMailTokenLogin,
    saveSession,
} from './login.service';

export interface ILoginState {
    logged: boolean;
    loaded: boolean;
    loggedByForm: boolean;
    loggedByToken: boolean;
}

export const loginStore: ModelConfig<ILoginState> = {
    state: {
        loaded: false, // loading value depending if the store is filled with trackers
        logged: false,
        loggedByForm: false,
        loggedByToken: false,
    },
    effects: (dispatch: any) => ({

        async requestLogin({
            email,
            password,
        }: {
            email: string;
            password: string;
        }): Promise<ILoginResponse> {
            return requestLogin(email, password).then((resp) => {
                saveSession(resp, true);
                this.setUserLogged(true);
                this.setUserLoggedByForm(true);
                dispatch.user.fetchUserData();
                return resp;
            });
        },
        async updateLoading(loaded: boolean): Promise<any> {
            this.updateLoadingState(loaded);
        },

        async tokenLogin(token: string): Promise<any> {
            const data = {
                access_token: token,
                expires_in: 'null',
                refresh_token: 'null',
            };
            saveSession(data, false);
            // while connection via url we set the value loaded at false
            this.updateLoadingState(false);
            this.setUserLogged(true);

            dispatch.user.fetchUserData();
            return data;
        },
        async mailTokenLogin(token: string): Promise<any> {
            return requestMailTokenLogin(token).then((resp) => {
                saveSession(resp, false);
                // while connection via url we set the value loaded at false
                this.updateLoadingState(false);
                this.setUserLogged(true);
                this.setUserLoggedByToken(true);
                dispatch.user.fetchUserData();
                return resp;
            });
        },
        async requestLogout() {
            killSession();

            /**
             * Instead of wiping redux, just redirect to home page with reload
             */
            window.location.href = '/';
        },
        async loginFromSession() {
            /**
             * Will just recover session from session storage.
             * If tokens are stale, app will log out user, so no need to check
             * it with API on this level
             */
            const session = getSession();

            if (session) {
                this.setUserLogged(true);

                return dispatch.user.fetchUserData();
            } else {
                return Promise.reject();
            }
        },
    }),
    reducers: {
        updateLoadingState: (state: ILoginState, isLoaded: boolean) => ({
            ...state,
            loaded: isLoaded,
        }),

        setUserLogged: (
            state: ILoginState,
            isLogged: boolean,
        ): ILoginState => ({ ...state, logged: isLogged }),
        setUserLoggedByForm: (
            state: ILoginState,
            loggedByForm: boolean
        ): ILoginState => ({ ...state, loggedByForm: loggedByForm }),
        setUserLoggedByToken: (
            state: ILoginState,
            loggedByToken: boolean
        ): ILoginState => ({ ...state, loggedByToken: loggedByToken }),
    },
};
