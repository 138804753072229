import { fetchTrackers } from 'app/trackers/trackers.service';
import React, { Children } from 'react';
import { Route, RouteProps, Router, Routes, useLocation, useParams } from 'react-router';
import { Link, useRoutes, createBrowserRouter, useLoaderData } from 'react-router-dom';
import { config as appConfig } from '../../config';

import { ConnectedAccountPage } from '../account/account-page/account-page.component';
import { ConnectedActivityTrackingPage } from '../activity-tracking';
import { ConnectedOnboardingActivityTrackingPage } from '../activity-tracking/component/onboarding/activity-tracking-onBoarding';
import { ConnectedAnimalInfoPage } from '../animal-info/animal-info.component';
import { ConnectedCancelSubscriptionPage } from '../cancel-subscription/cancel-subscription-page/cancel-subscription-page.component';
import { ConnectedFluidTypeSwitchPage } from '../fluid-type-switch/fluid-type-switch-page.component';
import { ConnectedForgotPasswordPage } from '../forgot-password/forgot-password-page.component';
import { ConnectedLoginPage } from '../login/login-page/login-page.component';
import { ConnectedManageSubscriptionPage } from '../manage-subscription/manage-subscription-page/manage-subscription-page.component';
import { MapPage } from '../map/map-page/map-page.component';
import { ConnectedonBoardingPage } from '../onBoardingTest/onBoarding.component';
import { ConnectedSignupPage } from '../signup/signup-page/signup-page.component';
import { newTrackerRoutes } from '../subscription-process/new-tracker-routes';
import { PaymentErrorPageBiogaran } from '../subscription-process/payment-result-step/payment-error-biogaran.component';
import { PaymentErrorPage } from '../subscription-process/payment-result-step/payment-error.component';
import { PaymentSuccessPageBiogaran } from '../subscription-process/payment-result-step/payment-success-biogaran.component';
import { PaymentSuccessPage } from '../subscription-process/payment-result-step/payment-success.component';
import { ConnectedPaymentsStep } from '../subscription-process/steps/payment-step/payments-step.component';
import { ConnectedSubscriptionProcessPage } from '../subscription-process/subscription-process-page/subscription-process-page.component';
import { ConnectedSupportPage } from '../support/support-page/support-page.component';
import { ConnectedTransferPage } from '../tracker-replacement/transfer-page/transfer-page.component';
import { ConnectedChangePaymentMeanPage } from '../user-subscriptions/change-payment-mean/change-payment-mean.component';
import { ProtectedRoute } from './protected-route';

export const defaultRoute = '/';
export const onBoardingRoute = '/trackers/onBoarding';
export const forgotPasswordRoute = '/forgotPassword';
export const forgotPasswordWithKeyRoute = '/forgotPassword/:key';
export const loginRoute = '/login';
export const signupRoute = '/signup';
export const subscriptionProcessRoute = '/add';
export const trackersRoute = '/trackers';
export const trackersRouteSelected = '/trackers/:id';
export const trackersCancelRoute = '/trackers/:id/cancel';
export const trackersManageRoute = '/trackers/:id/manage';
export const trackersRouteSelectedWithSubscription =
    '/trackers/:id/subscription';
export const resolveSubscriptionRoute = '/trackers/:id/subscription/resolve';
export const changePaymentMeanRoute =
    '/trackers/:id/subscription/changePaymentMean';
export const trackersRouteSelectedWithGeofences = '/trackers/:id/geofences';
export const trackersRouteSelectedWithWifiZones = '/trackers/:id/wifi-zones';
export const trackersRouteSelectedWithSettings = '/trackers/:id/settings';
export const trackersRouteSelectedWithSettingsAnimalInfo =
    '/trackers/:id/settings/animalInfo';

export const trackersRouteSelectedWithHistory = '/trackers/:id/history';
export const trackersRouteOnboardingTrackingActivity =
    '/trackers/:id/activity-onBoarding';
export const trackersRouteTrackingActivity = '/trackers/:id/activity-tracking';
export const accountRoute = '/account';
export const trackersRouteTransfer = '/transfer';
export const trackersRouteTypeSwitch = '/typeSwitch';
export const paymentSuccessRouteBiogaran = '/trackers/successBiogaran';
export const paymentErrorRouteBiogaran = '/trackers/:id/errorBiogaran';
export const paymentSuccessRoute = '/trackers/success';
export const paymentErrorRoute = '/trackers/:id/error';

export type ExtendedRoute = RouteProps & { private?: boolean };




export const router = createBrowserRouter([
    {
        element: (<ConnectedLoginPage />),
        path: loginRoute
    },
    {
        element: (<ConnectedSignupPage />),
        path: signupRoute,
    },
    {
        element: (
            <ProtectedRoute>
                <ConnectedOnboardingActivityTrackingPage />
            </ProtectedRoute>),
        path: trackersRouteOnboardingTrackingActivity,
    },
    {
        element: (
            <ProtectedRoute>
                <ConnectedActivityTrackingPage />
            </ProtectedRoute>),
        path: trackersRouteTrackingActivity,
    },
    {
        element: (
            <ProtectedRoute>
                <PaymentErrorPage />
            </ProtectedRoute>),
        path: paymentErrorRoute,
    },
    {
        element: (
            <ProtectedRoute>
                <PaymentSuccessPage />
            </ProtectedRoute>
        ),
        path: paymentSuccessRoute,
    },
    {
        element: (
            <ProtectedRoute>
                <ConnectedSupportPage />
            </ProtectedRoute>
        ),
        path: '/support',
    },
    {
        element: (
            <ProtectedRoute>
                <ConnectedonBoardingPage />
            </ProtectedRoute>),
        path: onBoardingRoute,
    },

    {
        path: trackersCancelRoute,
        element: <ProtectedRoute>
            <ConnectedCancelSubscriptionPage />
        </ProtectedRoute>,
    },
    {
        element: (<ProtectedRoute>
            <ConnectedAnimalInfoPage />
        </ProtectedRoute>),
        path: trackersRouteSelectedWithSettingsAnimalInfo,
    },
    {
        element: (
            <ProtectedRoute>
                <ConnectedManageSubscriptionPage />
            </ProtectedRoute>
        ),
        path: trackersManageRoute,
    },

    {
        element: (
            <ProtectedRoute>
                <ConnectedAccountPage />
            </ProtectedRoute>),
        children: [
            {
                element: <ConnectedAccountPage />,
                path: accountRoute + '/profile'
            },
            {
                element: <ConnectedAccountPage />,
                path: accountRoute + '/preferences'
            },
            {
                element: <ConnectedAccountPage />,
                path: accountRoute + '/password'
            },
            {
                element: <ConnectedAccountPage />,
                path: accountRoute + '/language'
            },
            {
                element: <ConnectedAccountPage />,
                path: accountRoute + '/payments'
            }
        ]
    },
    {
        element: (<ConnectedForgotPasswordPage />),
        children: [
            {
                element: <ConnectedForgotPasswordPage />,
                path: forgotPasswordRoute
            },
            {
                element: <ConnectedForgotPasswordPage />,
                path: forgotPasswordWithKeyRoute
            },
        ]
    },
    {
        element: (
            <ProtectedRoute>
                <MapPage />
            </ProtectedRoute>),
        children: [
            {
                element: <MapPage />,
                path: trackersRoute,
            },
            {
                element: <MapPage />,
                path: trackersRouteSelected,
            },
            {
                element: <MapPage />,
                path: trackersRouteSelectedWithGeofences
            }, {
                element: <MapPage />,
                path: trackersRouteSelectedWithWifiZones
            }, {
                element: <MapPage />,
                path: trackersRouteSelectedWithHistory
            }, {
                element: <MapPage />,
                path: trackersRouteSelectedWithSettings
            }, {
                element: <MapPage />,
                path: trackersRouteSelectedWithSubscription
            },
        ]
    },
    {
        element: (
            <ProtectedRoute>
                <ConnectedTransferPage />
            </ProtectedRoute>),
        path: trackersRouteTransfer,
    },
    {
        element: (
            <ProtectedRoute>
                <ConnectedFluidTypeSwitchPage />
            </ProtectedRoute>),
        path: trackersRouteTypeSwitch,
    },
    {
        path: subscriptionProcessRoute,
        element: (
            <ProtectedRoute>
                <Link
                    to={`subscriptionProcessRoute${newTrackerRoutes.REGISTER}`} />
            </ProtectedRoute>
        )
    },
    {
        element: (<ProtectedRoute>
            <ConnectedSubscriptionProcessPage />
        </ProtectedRoute>),
        children: [
            {
                path: subscriptionProcessRoute + newTrackerRoutes.REGISTER,
                element: <ConnectedSubscriptionProcessPage />,
            },
            {
                path: subscriptionProcessRoute + newTrackerRoutes.GIFTCARD
                ,
                element: <ConnectedSubscriptionProcessPage />,
            }, {
                path: subscriptionProcessRoute + newTrackerRoutes.OPTIONS
                ,
                element: <ConnectedSubscriptionProcessPage />,
            }, {
                path: subscriptionProcessRoute + newTrackerRoutes.PAYMENT
                ,
                element: <ConnectedSubscriptionProcessPage />,
            }, {
                path: subscriptionProcessRoute + newTrackerRoutes.SUBSCRIPTION
                ,
                element: <ConnectedSubscriptionProcessPage />,
            }, {
                path: subscriptionProcessRoute + newTrackerRoutes.HIPAY
                ,
                element: <ConnectedSubscriptionProcessPage />,
            }, {
                path: subscriptionProcessRoute,
                element: <ConnectedSubscriptionProcessPage />,
            },
        ]
    },
    {
        element: (<ProtectedRoute>
            <ConnectedChangePaymentMeanPage />
        </ProtectedRoute>),
        path: changePaymentMeanRoute,
    },
    {
        element: (<ProtectedRoute>
            <ConnectedPaymentsStep />
        </ProtectedRoute>),
        path: resolveSubscriptionRoute,
    },
    {
        element: (<ProtectedRoute>
            <MapPage />
        </ProtectedRoute>),
        path: '*',
    },

])
