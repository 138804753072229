import moment from 'moment';
import { createSelector } from 'reselect';

import { trackersFromApi } from './selectors';

/**
 * Special case when tracker is the only one AND user has dropped subscription during payment
 */
export const makeGetIsSingleTrackerWithDroppedSubscription = () => {
    return createSelector(
        trackersFromApi,
        (trackers) => {
            if (trackers.length !== 1) {
                return false;
            }

            const theTracker = trackers[0];

            if (!theTracker.subscription) {
                return false;
            }

            /* https://redmine.weenect.com/issues/5906 */
            if (
                theTracker.had_subscription === true &&
                (theTracker.expiration_date === null ||
                    moment(theTracker.expiration_date).isAfter(moment.now()))
            ) {
                return false;
            }

            /**
             * Null means that no subscription was ever created, so user registered tracker
             * and dropped process without submitting.
             */
            return theTracker.subscription.status === 'new';
        },
    );
};
