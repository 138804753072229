import { apiService } from '../../api/api.service';
import { IRootState } from '../../store/store';
import {
    ISubscriptionRedirection,
    SubscriptionOptionType,
} from '../../user-subscriptions/interfaces-api';
import { IVerifyPromoCode } from '../interfaces';
import { IProcessPayment } from '../steps/hipay-step/interfaces';
import { IUserChoices } from '../subscription-process.store';

/**
 * Function used on subscription process when frontend needs to redirect
 * to external site (ex: Gocardless, 3DSecure page)
 * @param url The URL to redirect to
 */
export const redirectToPaymentGate = (url: string) => {
    setTimeout((document.location.href = url), 250);
};

export const requestSubscriptionOrder = (
    choices: IUserChoices,
    site: string,
): Promise<ISubscriptionRedirection> => {
    return apiService
        .fetch('/subscription/subscribe', {
            method: 'POST',
            body: JSON.stringify({
                code: choices.subscriptionCode,
                site,
                payment_mean: choices.selectedPaymentCode,
                tracker_id: choices.trackerId,
                options: choices.selectedOptions,
                promo_code_id: choices.promoCodeId,
                is_gift_card_code: choices.promoCodeIsGiftCard,
            }),
        })
        .then((resp) => {
            if (resp.status >= 200 && resp.status < 400) {
                return resp.json();
            }

            throw resp;
        });
};

export const requestPayNow = (paymentPayload: IProcessPayment) => {
    const url = `/mysubscription/${paymentPayload.subscriptionId}/paynow`;
    return apiService
        .fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                java_enabled: paymentPayload.browserInfo.java_enabled,
                javascript_enabled:
                    paymentPayload.browserInfo.javascript_enabled,
                language: paymentPayload.browserInfo.language,
                color_depth: paymentPayload.browserInfo.color_depth,
                screen_height: paymentPayload.browserInfo.screen_height,
                screen_width: paymentPayload.browserInfo.screen_width,
                timezone: paymentPayload.browserInfo.timezone,
                http_user_agent: paymentPayload.browserInfo.http_user_agent,
                ipaddr: paymentPayload.browserInfo.ipaddr,
                http_accept: paymentPayload.browserInfo.http_accept,
                payment_product: paymentPayload.pp,
                card_token: paymentPayload.cardToken,
                card_expiry: paymentPayload.cardExpiry,
                card_pan: paymentPayload.card_pan,
            }),
        })
        .then((resp) => {
            // 204 = success. Subscription is active.
            if (resp.status === 204) {
                return resp;
            }
            // 200 = response contains a body, always json formatted.
            // (ex: an external url to redirect to)
            if (resp.status === 200) {
                return resp.json();
            }

            throw resp;
        });
};

export const requestPromoCodeActivation = (
    payload: IVerifyPromoCode,
    state: IRootState,
) => {
    interface IPostBody {
        promo_code: string;
        subscription_offer_id: number;
        site: string | undefined;
        is_gift_card_code?: boolean;
        tracker_id?: number | null;
    }
    const siteParam = state.user.userData?.site;
    const url = `/promo-code-activation`;

    const postBody: IPostBody = {
        promo_code: payload.promoCode,
        subscription_offer_id: payload.subscriptionOfferId,
        site: siteParam,
    };

    if (payload.is_gift_card_code) {
        postBody.is_gift_card_code = true;
    }
    if (payload.trackerId) {
        postBody.tracker_id = payload.trackerId;
    }

    return apiService
        .fetch(url, {
            method: 'POST',
            body: JSON.stringify(postBody),
        })
        .then((resp) => {
            if (resp.status === 200) {
                return resp.json();
            }

            throw resp;
        });
};
