import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose, mapProps, pure } from 'recompose';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../utils/with-selected-tracker';

import { getGlobalT } from '../../../i18n/setup-translations';

import { Collapse } from '../../common/collapse/collapse.component';
import { Typography } from '../../common/typography/typography.component';
import { Dispatch, IRootState } from '../../store/store';
import { ITrackerEvent } from '../../trackers/interfaces-api';

import { ConnectedActivityDetails } from '../activity-details/activity-details.component';

import { ActivityList } from '../activity/activity-list/activity-list.component';
import { Calendar } from '../calendar/calendar.component';
import { withDefaultPositionModeResolver } from '../default-position-mode-resolver';
import { HourSelectSlider } from '../hour-select-slider/hour-select-slider.component';
import { TimeInterval } from '../interfaces';
import { ConnectedPlayingProgress } from '../playing-progress/playing-progress.component';
import { PositionControls } from '../position-controls/position-controls.component';
import {
    getCurrentlyPlayedEventTime,
    getIsActivityVisible,
    getIsDateToday,
    getIsHeatmapVisible,
    getIsPaused,
    getIsPlayingRunning,
    getSelectedDate,
    getTodayPeriodValue,
    makeGetEventsOnList,
    makeGetEventsOnMap,
} from '../selectors';
import { TimeframeSelect } from '../timeframe-select/timeframe-select.component';

import styles from './history-route.module.scss';

interface IOuterProps { }

interface IStateProps {
    heatmapModeEnabled: boolean;
    pathModeEnabled: boolean;
    events: ITrackerEvent[];
    selectedDate: string;
    isToday: boolean;
    todayTimeframe: TimeInterval;
    pastDayHours: [number, number];
    isPlayingRunning: boolean;
    isPaused: boolean;
    currentEventTime: string;
    mapPositionsAvailable: boolean;
}

interface IActions {
    toggleHeatmapMode(): unknown;
    toggleActivityMode(): unknown;
    fetchEvents(trackerId: number | null): Promise<unknown>;
    setDate(date: string): unknown;
    setNextDay(): unknown;
    setPrevDay(): unknown;
    setTodayPeriod(timeframe: TimeInterval): unknown;
    setPrevDayTime(data: { start: number; end: number }): unknown;
    startPlaying(trackerId: number | null): unknown;
    stopPlaying(): unknown;
    pausePlaying(): unknown;
    turnOffMapEvents(): unknown;
}

interface IMappedProps {
    maybeRenderPositionControls(): ReactNode | null;
}

export interface IProps
    extends IOuterProps,
    IActions,
    IStateProps,
    IWithSelectedTracker,
    IMappedProps { }

const mapState = (
    state: IRootState,
    props: IWithSelectedTracker,
): IStateProps => {
    const getEvents = makeGetEventsOnList(props.trackerId!);
    const getTime = getCurrentlyPlayedEventTime(props.trackerId!);
    const getMapEvents = makeGetEventsOnMap(props.trackerId!);

    return {
        heatmapModeEnabled: getIsHeatmapVisible(state),
        pathModeEnabled: getIsActivityVisible(state),
        events: getEvents(state),
        selectedDate: getSelectedDate(state),
        isToday: getIsDateToday(state),
        todayTimeframe: getTodayPeriodValue(state),
        pastDayHours: [
            state.history.pastDayHourStart,
            state.history.pastDayHourEnd,
        ],
        isPlayingRunning: getIsPlayingRunning(state),
        isPaused: getIsPaused(state),
        currentEventTime: getTime(state),
        mapPositionsAvailable: getMapEvents(state).length > 1,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    toggleActivityMode: () => dispatch.history.toggleMode('pathMode'),
    toggleHeatmapMode: () => dispatch.history.toggleMode('heatmapMode'),
    turnOffMapEvents: dispatch.history.turnOffMapEvents,
    fetchEvents: async (trackerId) =>
        dispatch.history.fetchEvents({
            trackerId,
        }),
    startPlaying: (trackerId) =>
        dispatch.historyPositionPlaying.start({
            trackerId,
        }),
    stopPlaying: dispatch.historyPositionPlaying.stop,
    pausePlaying: dispatch.historyPositionPlaying.pause,
    setDate: dispatch.history.selectDate,
    setPrevDay: dispatch.history.setPrevDay,
    setNextDay: dispatch.history.setNextDay,
    setTodayPeriod: dispatch.history.setTodayPeriod,
    setPrevDayTime: dispatch.history.setPrevDayTime,
});

export const HistoryRoute = ({
    fetchEvents,
    events,
    selectedDate,
    setDate,
    isToday,
    setNextDay,
    setPrevDay,
    todayTimeframe,
    setTodayPeriod,
    pastDayHours,
    setPrevDayTime,
    stopPlaying,
    turnOffMapEvents,
    maybeRenderPositionControls,
    trackerId,
}: IProps) => {
    const [isActivityDetailsOpen, setIsActivityDetailsOpen] = useState(true);
    const [
        isNotificationsHistoryOpen,
        setIsNotificationsHistoryOpen,
    ] = useState(true);

    const t = getGlobalT();

    const fetchData = useCallback(() => {
        fetchEvents(trackerId);
    }, [fetchEvents, trackerId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(
        () => () => {
            turnOffMapEvents();
            stopPlaying();
        },
        [turnOffMapEvents, stopPlaying],
    );

    const renderEmptyList = () => <p>{t('activity:EMPTY_LIST')}</p>;

    const onNewDateSelected = (date: string) => {
        setDate(date);
        fetchData();
    };

    const renderHistoryControls = () => {
        if (isToday) {
            return (
                <React.Fragment>
                    <Typography size12 withMarginBottom>
                        {t('activity:LAST_TIME_PERIOD')}
                    </Typography>
                    <TimeframeSelect
                        className={styles.timeframe}
                        selected={todayTimeframe}
                        onSelected={(p) => {
                            setTodayPeriod(p);
                            fetchData();
                        }}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <div className={styles.hours}>
                    <Typography size12 withMarginBottom>
                        {t('activity:SELECT_TIME')}:
                    </Typography>
                    <HourSelectSlider
                        value={pastDayHours}
                        onChange={([start, end]) => {
                            setPrevDayTime({
                                start,
                                end,
                            });
                            fetchData();
                        }}
                    />
                </div>
            );
        }
    };

    const maybeRenderNotificationsHistory = () => {
        if (events.length > 0) {
            return (
                <div className={styles.list}>
                    <ActivityList events={events} />
                </div>
            );
        } else {
            return renderEmptyList();
        }
    };

    return (
        <div className={styles.root}>
            <div className={styles.main}>
                {/* --- "History controls" panel --- */}

                <Typography size12 withMarginBottom>
                    {t('activity:SELECT_DAY')}
                </Typography>
                <Calendar
                    className={styles.calendar}
                    date={selectedDate}
                    onNewDateSelected={onNewDateSelected}
                    onNextDay={() => {
                        setNextDay();
                        fetchData();
                    }}
                    onPrevDay={() => {
                        setPrevDay();
                        fetchData();
                    }}
                    isToday={isToday}
                />

                {renderHistoryControls()}
                {maybeRenderPositionControls()}

                {/* --- "Activity details" collapsible panel --- */}

                <Collapse
                    className={styles.section}
                    panel={
                        <Typography noMargin bold>
                            {t('activity:DETAIL_TITLE')}
                        </Typography>
                    }
                    content={<ConnectedActivityDetails />}
                    expanded={isActivityDetailsOpen}
                    onExpand={() =>
                        setIsActivityDetailsOpen(!isActivityDetailsOpen)
                    }
                />

                {/* --- "Notifications history" collapsible panel --- */}
                <Collapse
                    className={styles.section}
                    panel={
                        <Typography noMargin bold>
                            {t('activity:HISTORY_TITLE')}
                        </Typography>
                    }
                    content={maybeRenderNotificationsHistory()}
                    expanded={isNotificationsHistoryOpen}
                    onExpand={() =>
                        setIsNotificationsHistoryOpen(
                            !isNotificationsHistoryOpen,
                        )
                    }
                />
            </div>
        </div>
    );
};

export const ConnectedHistoryRoute = compose<IProps, IOuterProps>(
    withSelectedTracker,
    connect(mapState, mapDispatch),
    withDefaultPositionModeResolver,
    mapProps<
        IStateProps & IActions,
        IMappedProps & IStateProps & IActions & IWithSelectedTracker
    >((props) => ({
        ...props,
        maybeRenderPositionControls: () => {
            const {
                mapPositionsAvailable,
                toggleHeatmapMode,
                toggleActivityMode,
                startPlaying,
                stopPlaying,
                pausePlaying,
                isPaused,
                heatmapModeEnabled,
                isPlayingRunning,
                currentEventTime,
                pathModeEnabled,
            } = props;

            if (mapPositionsAvailable) {
                return (
                    <div className={styles.bottom}>
                        <PositionControls
                            toggleHeatmapMode={toggleHeatmapMode}
                            stopPlaying={stopPlaying}
                            startPlaying={() => startPlaying(props.trackerId)}
                            pausePlaying={pausePlaying}
                            isPlayingRunning={isPlayingRunning}
                            isPaused={isPaused}
                            heatmapModeEnabled={heatmapModeEnabled}
                            currentEventTime={currentEventTime}
                            toggleActivityMode={toggleActivityMode}
                            pathModeEnabled={pathModeEnabled}
                        />
                        {isPlayingRunning && (
                            <ConnectedPlayingProgress
                                trackerId={props.trackerId!}
                            />
                        )}
                    </div>
                );
            }

            return null;
        },
    })),
    pure,
)(HistoryRoute);
