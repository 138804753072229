import cx from 'classnames';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Modal } from '../modal/modal.component';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as TickIcon } from '../../icons/tick.svg';
import { Theme } from '../../trackers/interfaces';

import styles from './check-section.module.scss';

export interface IRadioSectionProps
    extends InputHTMLAttributes<HTMLInputElement> {
    info?: Array<string> | null;
    price?: string;
    period?: string;
}

export const CheckSection = ({
    children,
    className,
    checked,
    price,
    period,
    onChange,
    type = 'radio',
    info,
    ...props
}: IRadioSectionProps) => {
    const infoBiogaran = [
        `Prélèvement immédiat, lors de la souscription, de la somme de ${price} puis prélèvement automatique de la somme de ${price} au début de chaque période de deux ans sauf en cas de résiliation de l’abonnement 30 jours avant le renouvellement automatique`,
        'Paiement par prélèvement bancaire ou carte de crédit',
        'En cas de résiliation avant la fin de la période de deux ans, aucun remboursement ne sera effectué',
    ];
    const infoBiogaranMonth = [
        `Facturé ${price} par mois`,
        'Engagement minimum de trois mois',
        'Résiliation possible à l’issue de la période minimale d’engagement de 3 mois puis à tout moment (au moins 48h avant le renouvellement mensuel automatique)',
    ];
    const [open, setOpen] = useState(false);
    const openModal = (e: any) => {
        setOpen(true);
        e.preventDefault();
    };
    const maybeRenderInfos = () => {
        if (info) {
            const theme = document.documentElement.getAttribute('data-theme');
            if (open) {
                return (
                    <Modal
                        className={styles['premium-modal']}
                        onClosed={() => setOpen(false)}
                    >
                        {theme === Theme.BIOGARAN ? (
                            <p>
                                {period !== '1 mois'
                                    ? infoBiogaran.map((i, index) => {
                                        return (
                                            <li
                                                key={i}
                                                className={styles.spacing}
                                            >
                                                {i}
                                            </li>
                                        );
                                    })
                                    : infoBiogaranMonth.map((i, index) => {
                                        return (
                                            <li
                                                key={i}
                                                className={styles.spacing}
                                            >
                                                {i}
                                            </li>
                                        );
                                    })}
                            </p>
                        ) : (
                            <p>
                                {info.map((i, index) => (
                                    <li key={index}>{i}</li>
                                ))}
                            </p>
                        )}
                    </Modal>
                );
            }
            return theme === Theme.BIOGARAN ? (
                <div className={styles.infoPoint}>
                    <InfoIcon
                        onClick={(e) => {
                            openModal(e);
                        }}
                        className={styles.infoSVG}
                    />
                </div>
            ) : null;
        }
    };
    return (
        <label
            className={cx(
                styles.root,
                {
                    [styles.checked]: checked,
                },
                className,
            )}
        >
            <input
                className={styles.nativeCheckbox}
                type={type}
                checked={checked}
                onChange={onChange}
                {...props}
            />
            <div className={styles.container}>
                <div
                    className={cx(styles.marker, {
                        [styles.checkbox]: type === 'checkbox',
                        [styles.radio]: type === 'radio',
                    })}
                >
                    <TickIcon className={styles.tick} />
                </div>
                <div
                    className={cx(styles.children, {
                        [styles.padded]: type === 'checkbox',
                    })}
                >
                    {children}
                </div>
                {maybeRenderInfos()}
            </div>
        </label>
    );
};
