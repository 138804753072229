import { createSelector } from 'reselect';

import { makeGetTrackerModel } from './get-tracker-model';

export const makeGetTrackerSupportsLiveMode = (trackerId: number) => {
    const getTracker = makeGetTrackerModel(trackerId);

    return createSelector(
        getTracker,
        (tracker) =>
            (tracker && tracker.features.includes('super_tracking')) || false,
    );
};


/**
 * Retrieve the frequency at which a super_live can be fetched by frontend
 * @param trackerId 
 * @returns 
 */
export const makeGetTrackerLiveModeInterval = (trackerId: number) => {
    const getTracker = makeGetTrackerModel(trackerId);

    return createSelector(
        getTracker,
        (tracker) => {
            let interval = '10';  // Default frequency is 10 seconds

            if (tracker) {
                if (tracker.features) {
                    const regexp = new RegExp('^super_tracking_interval_(.)$');
                    tracker.features.every((feature) => {
                        const match = feature.match(regexp)
                        if (match) {
                            interval = match[1];
                            return false; // Exit loop
                        }
                        return true;
                    })
                    return interval;
                }
            }
            return interval;
        }
    );
};
