import { config } from '../../../config';
import { TrackerType } from '../../trackers/interfaces';

/**
 * Gets tracker type and return URL to product page for each of the trackers
 */
export const resolveTrackerSellPage = (trackerType: TrackerType) => {
    switch (trackerType) {
        case TrackerType.CAT:
        case TrackerType.CAT2: {
            return config.PRODUCT_PAGE_CATS;
        }
        case TrackerType.PET:
        case TrackerType.PET2:
            return config.PRODUCT_PAGE_DOGS;
        case TrackerType.SILVER:
            return config.PRODUCT_PAGE_ELDERS;
        case TrackerType.SKISET:
        case TrackerType.SPORT:
            return config.PRODUCT_PAGE_KIDS;
        case TrackerType.DEFAULT:
        default: {
            return config.PRODUCT_PAGE_DEFAULT;
        }
    }
};
