import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FemaleIcon } from '../../../images/ACTIVITY_TRACKING/female.svg';
import { ReactComponent as MaleIcon } from '../../../images/ACTIVITY_TRACKING/male.svg';
import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { Select } from '../../common/select/select.component';
import styles from '../animal-info.module.scss';
import {
    AnimalFormStep,
    Gender,
    IAnimal,
    IFormValidationInterface,
    MonthTranslated,
} from '../interfaces';

interface IOuterProps {
    animalInfo: IAnimal;
    formValidation: IFormValidationInterface;
    setFormValidation: any;
    nextStep: any;
    handleSubmit: any;
}

export const BirthdateSexeInfoComponent = ({
    animalInfo,
    formValidation,
    setFormValidation,
    nextStep,
    handleSubmit,
}: IOuterProps) => {
    const { t } = useTranslation([
        'activityOnboarding',
        'activityTracking',
        'commonActions',
    ]);

    const maxOffset = 30;
    const thisYear = new Date().getFullYear();
    const allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x);
    }

    const handleDate = ({
        value = 1,
        isMonth = false,
    }: {
        value: number;
        isMonth?: boolean;
        isYear?: boolean;
    }) => {
        if (!value) {
            return setFormValidation((form: IFormValidationInterface) => ({
                ...form,
                birth_date: null,
            }));
        }
        const currentDate = formValidation.birth_date
            ? formValidation.birth_date
            : new Date();
        if (isMonth) {
            currentDate!.setMonth(value - 1);
            setFormValidation((form: IFormValidationInterface) => ({
                ...form,
                birth_date: currentDate,
            }));
        } else {
            currentDate!.setFullYear(value);
            setFormValidation((form: IFormValidationInterface) => ({
                ...form,
                birth_date: currentDate,
            }));
        }
    };
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div>
                    <Headline level={2} center className={styles.title}>
                        {t('PET_INFO.SECOND_PETS_DETAILS')}
                    </Headline>
                </div>
                <div>
                    <p
                        className={styles.commonText}
                        style={{ marginBottom: '1rem!mportant' }}
                    >
                        {t('PET_AGE.TITLE')}
                    </p>

                    <div className={cx(styles.gridDate, styles.marginBottom10)}>
                        <>
                            <div className={styles.width25}>
                                <p className={styles.commonTextSubTitle}>
                                    {t('activityTracking:PERIOD.MONTH')}
                                </p>
                                <Select
                                    className={styles.control}
                                    style={{ color: 'black' }}
                                    name="month"
                                    onChange={(e) => {
                                        const { value } = e.currentTarget;
                                        handleDate({
                                            value: parseFloat(value),
                                            isMonth: true,
                                            isYear: false,
                                        });
                                    }}
                                    value={
                                        formValidation?.birth_date
                                            ? (
                                                  formValidation.birth_date.getMonth() +
                                                  1
                                              )
                                                  .toString()
                                                  .padStart(2, '0')
                                            : '--'
                                    }
                                >
                                    <option value={0}>--</option>
                                    {MonthTranslated.map((month, i) => (
                                        <option
                                            key={t(month)}
                                            value={(i + 1)
                                                .toString()
                                                .padStart(2, '0')}
                                        >
                                            {t(month)}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                            <div className={styles.width25}>
                                <p className={styles.commonTextSubTitle}>
                                    {t('activityTracking:PERIOD.YEAR')}
                                </p>
                                <Select
                                    style={{ color: 'black' }}
                                    className={styles.control}
                                    name="year"
                                    value={
                                        formValidation?.birth_date
                                            ? formValidation?.birth_date
                                                  .getFullYear()
                                                  .toString()
                                            : '--'
                                    }
                                    onChange={(e) => {
                                        const { value } = e.currentTarget;
                                        handleDate({
                                            value: parseFloat(value),
                                            isMonth: false,
                                            isYear: true,
                                        });
                                    }}
                                >
                                    <option value={0}>--</option>
                                    {allYears.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </>
                    </div>
                </div>
                <div className={styles.marginTop2}>
                    <p className={styles.commonText}>
                        {t('PET_GENDER.FIRST_TITLE')}
                    </p>
                    <div className={styles.grid}>
                        <div
                            onClick={() =>
                                setFormValidation(
                                    (form: IFormValidationInterface) => ({
                                        ...form,
                                        sex: Gender.FEMALE,
                                    }),
                                )
                            }
                            className={
                                formValidation.sex === Gender.FEMALE
                                    ? styles.imgContainerSelected
                                    : styles.imgContainer
                            }
                        >
                            <div className={styles.magrinAuto}>
                                <FemaleIcon className={styles.icon} />
                                <p className={styles.textBlockIcon}>
                                    {t('PET_GENDER.FEMALE')}
                                </p>
                            </div>
                        </div>
                        <div
                            onClick={() =>
                                setFormValidation(
                                    (form: IFormValidationInterface) => ({
                                        ...form,
                                        sex: Gender.MALE,
                                    }),
                                )
                            }
                            className={
                                formValidation.sex === Gender.MALE
                                    ? styles.imgContainerSelected
                                    : styles.imgContainer
                            }
                        >
                            <div className={styles.magrinAuto}>
                                <MaleIcon className={styles.icon} />
                                <p className={styles.textBlockIcon}>
                                    {t('PET_GENDER.MALE')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.marginBottom10}>
                    <p
                        className={styles.commonText}
                        style={{ marginBottom: '1rem!mportant' }}
                    >
                        {t('PET_MORPH.WEIGH_TEXT')}
                    </p>
                    <div className={styles.inputContainer}>
                        <input
                            className={styles.inputBlock}
                            type={'number'}
                            placeholder='0'
                            value={ formValidation.weight != null && formValidation.weight > 0  ? formValidation?.weight?.toString() : ''}
                            onChange={(e) => {
                                const value = e.target.value;
                                setFormValidation(
                                    (form: IFormValidationInterface) => ({
                                        ...form,
                                        weight: value,
                                    }),
                                );
                            }}
                        />
                        <p>kg</p>
                    </div>
                </div>
                <div className={styles.buttonBlockNotFixed}>
                    {animalInfo ? (
                        <Button
                            disabled={
                                formValidation.birth_date && formValidation.sex
                                    ? false
                                    : true
                            }
                            className={styles.buttonWidth}
                            onClick={() => handleSubmit()}
                        >
                            {t('SUMMARY.VALIDATION')}
                        </Button>
                    ) : (
                        <Button
                            disabled={
                                formValidation.birth_date &&
                                formValidation.sex &&
                                formValidation.weight
                                    ? false
                                    : true
                            }
                            className={styles.buttonWidth}
                            onClick={() => nextStep(AnimalFormStep.RACE)}
                        >
                            {t('commonActions:NEXT')}
                        </Button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
