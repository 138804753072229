export enum OnboardingStep {
    FIRST_STEP = 0,
    SECOND_STEP = 1,
}

export interface ISumaryProperties {
    icon: any;
    timeValue: string;
    sumaryText: string;
}
export enum Period {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
}

export interface IActivityAggregated {
    server_date: Date;
    activity: number;
}
export const DateTranslated = {};

export interface IActivityStore {
    period: Period;
    loading: boolean;
    summaryData: ISummaryData;
    summaryToday: ISummaryData;
    aggregateData: IAggregateData | null;
    translatedPeriodSelected: string;
    savedDate: Date | Date[];
    evolution: IEvolution;
}

export interface IEvolution {
    activity: number;
    rest: number;
}
// value returned in minutes
export interface ISummaryData {
    objective: number;
    awakening: number;
    activity: number;
    rest: number;
}

export interface IAggregateData {
    activity: IActivity[];
}

interface IActivity {
    server_date: Date;
    activity: number;
}
