import cx from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

import styles from './map-floating-button.module.scss';

export interface IMapFloatingButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    displayWhiteIcon?: boolean;
    active?: boolean;
    biggerIcon?: boolean;
    description?: string;
    isLockedDuToWifi?: boolean | null;
}

export const MapFloatingButton = ({
    active,
    biggerIcon,
    children,
    className,
    description,
    disabled,
    displayWhiteIcon,
    isLockedDuToWifi,
    ...props
}: IMapFloatingButtonProps) => {
    const selectClasseStyle = () => {
        if (isLockedDuToWifi || disabled) {
            return styles.disabledButton;
        } else if (displayWhiteIcon) {
            return styles.button;
        }
        return styles.actionButton;
    };
    const maybeRenderDescription = () => {
        if (description) {
            return (
                <div className={styles.description}>
                    <span>{description}</span>
                </div>
            );
        }
    };
    return (
        <button
            className={cx(
                selectClasseStyle(),
                {
                    [styles.active]: active,
                },
                {
                    [styles.biggerIcon]: biggerIcon,
                },
                className,
            )}
            disabled={disabled}
            {...props}
        >
            {maybeRenderDescription()}
            {children}
        </button>
    );
};
