import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { Dispatch, IRootState } from '../../store/store';
import { ConnectedGeofenceItem } from '../geofence-item/geofence-item.component';
import styles from '../geofences-page/geofences-page.module.scss';
import { IGeofence } from '../interfaces';
import { makeGetEditableGeofenceId } from '../selectors/geofences-edit.selectors';
import { makeGetUserGeofencesForTracker } from '../selectors/geofences.selectors';

export interface IStateProps {
    geofences: IGeofence[];
    openId: number | null;
}

export interface IActions {
    setGeofenceToEditMode: (geofenceId: number) => any;
    closeGeofenceEditMode: () => any;
}

export interface IOuterProps {
    trackerId: number;
}

export interface IProps extends IStateProps, IActions, IOuterProps {}

export const GeofencesList = ({
    geofences,
    closeGeofenceEditMode,
    openId,
    setGeofenceToEditMode,
}: IProps) => {
    const toggleGeofenceSection = (id: number) => {
        if (openId === id) {
            closeGeofenceEditMode();
        } else {
            setGeofenceToEditMode(id);
        }
    };

    return (
        <div className={styles.geofencesList}>
            {geofences.map((geofence: IGeofence) => (
                <ConnectedGeofenceItem
                    key={geofence.geofenceId}
                    className={styles.geofenceItem}
                    open={openId === geofence.geofenceId}
                    onToggle={() => toggleGeofenceSection(geofence.geofenceId)}
                    {...geofence}
                />
            ))}
        </div>
    );
};

const makeMapState = () => {
    return (state: IRootState, props: IOuterProps): IStateProps => {
        const getGeofencesByTracker = makeGetUserGeofencesForTracker(
            props.trackerId,
        );
        const getCurrentlyEditableGeofence = makeGetEditableGeofenceId();

        return {
            geofences: getGeofencesByTracker(state),
            openId: getCurrentlyEditableGeofence(state),
        };
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    setGeofenceToEditMode: dispatch.geofenceEdit.setGeofenceDuringEdit,
    closeGeofenceEditMode: () =>
        dispatch.geofenceEdit.setGeofenceDuringEdit(null),
});

export const ConnectedGeofencesList = compose<IProps, IOuterProps>(
    connect(makeMapState, mapDispatch),
    lifecycle<IActions & IStateProps, {}, {}>({
        componentDidMount() {
            const { geofences, setGeofenceToEditMode } = this.props;
            if (geofences.length > 0) {
                setGeofenceToEditMode(geofences[0].geofenceId);
            }
        },
    }),
)(GeofencesList);
