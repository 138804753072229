import { Typography } from 'app/common/typography/typography.component';
import { FormikProps, withFormik } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getGlobalT } from '../../../i18n/setup-translations';
import { IWithFormUtils, withFormUtils } from '../../../utils/form-helpers';
import { Button } from '../../common/button/button.component';
import { SaveButton } from '../../common/button/save-button.component';
import { Headline } from '../../common/headline/headline.component';
import { Toggle } from '../../common/toggle/toggle.component';
import { Checkbox } from '../../common/checkbox/checkbox.component';
import { Radio } from '../../common/radio/radio.component'; 
import { Link } from '../../common/link/link.component';
import { INotification } from '../../notifications/interfaces';
import { Dispatch, IRootState, store } from '../../store/store';
import { makeGetUserPreferencesFields } from '../../user/user-selectors';
import { IUserPrefStore, IEmailPrefParams, IEmailPrefPostParams } from '../../user/userpref.store';
import { IUserEmailPrefData, IEditableUserData } from '../../user/interfaces';
import { makeGetUserEditableFields } from '../../user/user-selectors';
import { UserEmailLanguagePreferences } from './user-email-language-preferences/user-email-language-preferences.component';
import { MetricSystem } from '../../history/interfaces';


import styles from './user-preferences.module.scss';
import { object } from 'yup';

interface IUserPrefFormValues extends IUserEmailPrefData {
    marketingAccepted: boolean;
    preferredMetricSystem: string;
}

interface IStateProps {
    userEmail: string;
    token: string;
    validation_key: string;
    existingValues: IUserPrefFormValues;
}

interface IActions {
    fetchEmailPrefData: (payload: IEmailPrefParams) => Promise<unknown>;
    submitEmailPref: (payload: IEmailPrefPostParams) => Promise<unknown>;
    submitUserPref: (data: IEditableUserData) => Promise<unknown>;
    showNotification: (n: INotification) => unknown;
    clearNotifications: () => unknown;
}

interface IOuterProps {}

interface IProps
    extends IOuterProps,
    IActions,
    IStateProps,
    FormikProps<IUserPrefFormValues>,
    IWithFormUtils
    { }

export const UserPreferences = ({
    userEmail,
    token,
    validation_key,
    existingValues,
    submitEmailPref,
    submitUserPref,
    handleSubmit,
    handleReset,
    values,
    setValues,
    isValid,
    initialValues,
    getErrorMessage,
    resetForm,
    isFieldValid,
    handleChange,
    handleBlur,
    dirty,
    showNotification,
    clearNotifications,
    fetchEmailPrefData,
}: IProps) => {
    const { t } = useTranslation([
        'account', 'forms', 'commonActions'
    ]);
    useEffect(() => () => void clearNotifications(), [clearNotifications]);
    useEffect(() => (void fetchEmailPrefData({
        "token": token,
        "validation_key": validation_key,
        "user_email": userEmail,
    })), []);
    const [updatedRadio, setUpdatedRadio] = useState<boolean>(false);
    const enabled = token != '' ? true : false;

    const preventSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const maybeRenderCancel = (dirty: boolean) => {
        if (dirty) {
            return (
                <Button className={styles.reset} type="reset" secondary>
                    {t('commonActions:CANCEL')}
                </Button>
            );
        }
    };

    const all_selected = (values.list_company_news
        && values.list_new_features
        && values.list_offers
        && values.list_surveys_and_tests
        && values.list_tutorials);

    const toggle_all = async () => {
        if (all_selected)
            return setValues((state) => ({
                current_lang: state.current_lang,
                list_company_news: false,
                list_new_features: false,
                list_offers: false,
                list_surveys_and_tests: false,
                list_tutorials: false,
                list_service_status: state.list_service_status,
                marketingAccepted: state.marketingAccepted,
                preferredMetricSystem: state.preferredMetricSystem
            }));
        else
            return setValues((state) => ({
                current_lang: state.current_lang,
                list_company_news: true,
                list_new_features: true,
                list_offers: true,
                list_surveys_and_tests: true,
                list_tutorials: true,
                list_service_status: state.list_service_status,
                marketingAccepted: state.marketingAccepted,
                preferredMetricSystem: state.preferredMetricSystem
            }));
    }

    const renderEmailListBlock = () => {
        return <fieldset className={styles.emailList}>
            <div className={styles.headrow}>
                <Typography>{t("emailPreferences:EMAIL_LISTS_HEADER")}</Typography>
                <Link onClick={toggle_all} muted={all_selected}>
                    {all_selected ?
                        t("commonActions:UNSELECT_ALL") :
                        t("commonActions:SELECT_ALL")}
                </Link>
            </div>
            <Toggle name="list_company_news" checked={values.list_company_news}
                onChange={handleChange} toggleAfter={true} wifiToggle>
                {t("emailPreferences:EMAIL_LISTS.COMPANY_NEWS")}
            </Toggle>
            <Toggle name="list_surveys_and_tests" checked={values.list_surveys_and_tests}
                onChange={handleChange} toggleAfter={true} wifiToggle>
                {t("emailPreferences:EMAIL_LISTS.SURVEYS_AND_TESTS")}
            </Toggle>
            <Toggle name="list_new_features" checked={values.list_new_features}
                onChange={handleChange} toggleAfter={true} wifiToggle>
                {t("emailPreferences:EMAIL_LISTS.NEW_FEATURES")}
            </Toggle>
            <Toggle name="list_tutorials" checked={values.list_tutorials}
                onChange={handleChange} toggleAfter={true} wifiToggle>
                {t("emailPreferences:EMAIL_LISTS.TUTORIALS")}
            </Toggle>
            <Toggle name="list_offers" checked={values.list_offers}
                onChange={handleChange} toggleAfter={true} wifiToggle>
                {t("emailPreferences:EMAIL_LISTS.OFFERS")}
            </Toggle>
            <Toggle name="list_service_status" checked={values.list_service_status}
                onChange={handleChange} toggleAfter={true} disabled>
                {t("emailPreferences:EMAIL_LISTS.SERVICE_STATUS")}<br/>
                <span className={styles.labelExtraInfo}>
                    {t("emailPreferences:EMAIL_LISTS_INFO.SERVICE_STATUS")}
                </span>
            </Toggle>
        </fieldset>
    };

    const maybeRenderSuccessMessageRadio = () => {
        if (updatedRadio === true) {
            return (
                <Typography className={styles.radioSuccessMessage}>
                    {t('trackerSettings:DISPLAY.MESSAGES.UPDATE_SUCCESS')}
                </Typography>
            );
        }
    };

    const renderRadioCheckbox = () => {
        return (
            <div className={styles.spacerBefore}>
                <Headline className={styles.subHeadline} level={2}>
                    {t('USER_PREFERENCES.METRIC_PREFERENCES_HEADLINE')}
                </Headline>
                <Typography className={styles.label}>
                    {t('USER_PREFERENCES.METRIC_PREFERENCES_DESCRIPTION')}
                </Typography>
                {maybeRenderSuccessMessageRadio()}
                <div className={styles['control-radio']}>
                    <Radio
                        className={styles.preferredMetric}
                        name="preferredMetricSystem"
                        key="km"
                        checked={
                            values.preferredMetricSystem === MetricSystem.KM
                        }
                        value={MetricSystem.KM}
                        onChange={handleChange}
                    >
                        {t('USER_PROFILE.METRIC_KM')}
                    </Radio>
                    <Radio
                        className={styles.preferredMetric}
                        name="preferredMetricSystem"
                        key="miles"
                        checked={
                            values.preferredMetricSystem === MetricSystem.MILES
                        }
                        value={MetricSystem.MILES}
                        onChange={handleChange}
                    >
                        {t('USER_PROFILE.METRIC_MILES')}
                    </Radio>
                </div>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <div>
                <Headline className={styles.headline}>
                    {t('USER_PREFERENCES.MAIN_HEADLINE')}
                </Headline>
                <form onSubmit={preventSubmit} onReset={handleReset}>
                    <Headline className={styles.subHeadline} level={2}>
                        {t('USER_PREFERENCES.EMAIL_PREFERENCES_HEADLINE')}
                    </Headline>
                    <Typography className={styles.label}>
                        {t('emailPreferences:PREFERENCES_DECRIPTION')}
                    </Typography>
                    <Typography className={styles.label}>
                        {t('USER_PREFERENCES.EMAIL_PREFERENCES_USER_INFO', {
                            user_email: userEmail,
                        })}
                    </Typography>
                    {renderEmailListBlock()}
                    <UserEmailLanguagePreferences
                        name="current_lang"
                        legend={t("emailPreferences:LANGUAGE_PREFERENCES_DESCRIPTION")}
                        value={values.current_lang.toUpperCase()}
                        onChange={handleChange}
                        className={styles.values}
                    />
                    <div className={styles.control}>
                        <Checkbox
                            onChange={handleChange}
                            name="marketingAccepted"
                            checked={values.marketingAccepted}
                            stealth
                        >
                            {t('USER_PROFILE.MARKETING_AGREEMENT_TEXT')}
                        </Checkbox>
                    </div>
                    {renderRadioCheckbox()}
                    <SaveButton
                        disabled={!dirty}
                        onClick={() => handleSubmit()}>
                        {t('commonActions:SAVE')}
                    </SaveButton>
                    {maybeRenderCancel(dirty)}
                </form>
            </div>
        );
    };

    return renderForm();
};

const mapState = (state: IRootState): IStateProps => {
    const userData = state.user.userData;
    const emailPrefs = state.user.userData?.emailpref;
    const getEmailPrefData = makeGetUserPreferencesFields();
    return {
        userEmail: userData?.mail || "",
        token: emailPrefs?.token || "",
        validation_key: emailPrefs?.validation_key || "",
        existingValues: { "marketingAccepted": userData?.optin || false,
            "preferredMetricSystem": userData?.preferred_metric_system || "km",
            ...getEmailPrefData(state)!},
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    fetchEmailPrefData: async (payload) => dispatch.userPref.fetchUserEmailPrefData(payload),
    submitEmailPref: async (payload) => dispatch.userPref.updateEmailPrefData(payload),
    submitUserPref: async (data) => dispatch.account.updateUserPreferences(data),
    showNotification: dispatch.notifications.showNotification,
    clearNotifications: dispatch.notifications.clearNotifications,
});

export const ConnectedUserPreferences = compose<IProps, IOuterProps>(
    connect(mapState, mapDispatch),
    withFormik<IOuterProps & IStateProps & IActions, IUserPrefFormValues>({
        enableReinitialize: true,
        mapPropsToValues: (props) => props.existingValues,
        handleSubmit: (values, formikBag) => {
            formikBag.props.clearNotifications();

            const t = getGlobalT();

            const handleBranchedSubmit = async (formData: IUserPrefFormValues) => {
                const getEditableUserData = makeGetUserEditableFields();
                const getEmailPrefData = makeGetUserPreferencesFields();
                let newEmailPrefData: IUserEmailPrefData = getEmailPrefData(store.getState())!;
                let newUserPrefData: IEditableUserData = getEditableUserData(store.getState())!;
                let emailpref_changed: boolean = false;
                let userpref_changed: boolean = false;
                let updateError: boolean = false;

                for (const prop in formData) {
                    if (
                        Object.prototype.hasOwnProperty.call(newEmailPrefData, prop)
                        && newEmailPrefData[prop as keyof IUserEmailPrefData]
                        != formData[prop as keyof IUserPrefFormValues]
                    ) {
                        // @ts-ignore
                        newEmailPrefData[prop as keyof IUserEmailPrefData] = (
                            formData[prop as keyof IUserPrefFormValues]);
                        emailpref_changed = true;
                    } else if (
                        Object.prototype.hasOwnProperty.call(newUserPrefData, prop)
                        && newUserPrefData[prop as keyof IEditableUserData]
                        != formData[prop as keyof IUserPrefFormValues]
                    ) {
                        // @ts-ignore
                        newUserPrefData[prop as keyof IEditableUserData] = (
                            formData[prop as keyof IUserPrefFormValues]
                        );
                        userpref_changed = true;
                    }
                }
                try {
                    if (emailpref_changed)
                        await formikBag.props.submitEmailPref({
                            emailpref: newEmailPrefData,
                            token: formikBag.props.token,
                            validation_key: formikBag.props.validation_key,
                            user_email: formikBag.props.userEmail
                        });
                    if (userpref_changed)
                        await formikBag.props.submitUserPref(newUserPrefData);
                } catch (err) {
                    updateError = true;
                    await formikBag.props.showNotification({
                        type: 'error',
                        content: t(
                            'account:USER_PREFERENCES.MESSAGES.UPDATE_ERROR_UNKNOWN',
                        ),
                    });
                }
                if (!updateError) await formikBag.props.showNotification({
                    type: 'success',
                    content: t(
                        'account:USER_PREFERENCES.MESSAGES.UPDATE_SUCCESS',
                    ),
                });
            }
            return handleBranchedSubmit(values)
        },
    }),
    withFormUtils,
)(UserPreferences);
