import * as yup from 'yup';
import { getGlobalT } from '../../../../i18n/setup-translations';

import { IPasswordChangeFormValues } from './password-change-form.component';

export const getPasswordChangeValidationSchema = () => {
    const t = getGlobalT();

    return yup.object<IPasswordChangeFormValues>({
        newPassword: yup
            .string()
            .required()
            .min(8, t('forms:PASSWORD.VALIDATION')),
        oldPassword: yup.string().required(),
    });
};
